import { DeleteOutlined, EditFilled, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, message, Popconfirm, Space, Table, Tooltip } from 'antd';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { addQueryParam, getQueryParam, hasQueryParam } from 'src/utils/url-utils';
import { ArrowRightIcon } from '../../../assets/icons';
import MatrixService from '../../../services/matrix/matrixService';
import CommitieService from '../../../services/pages/commitieServices';
import XmatrixDropDownFilter from '../../shared/components/xmatrixDropdownFilter/xmatrixDropdownFilter';
import TableLayout from '../../shared/tableLayout';
import { notifyMessages } from '../../shared/utils/notifyMessages';
import AddProjectToComitieModal from './addProjectToCommitieModal';

const ComitieProjects = ({ comitieId, onlyView = false }) => {
  const history = useHistory();

  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const [relatedProjects, setRelatedProjects] = useState(null);
  const [loadingRelatedProjects, setLoadingRelatedProjects] = useState(false);
  const [activeXmatrixInfo, setActiveXmatrixInfo] = useState(null);
  const [selectedXmatrixValue, setSelectedXmatrixValue] = useState(null);
  const [projectComitieModal, setProjectComitieModal] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  useEffect(() => {
    getActiveXmatrix();
  }, []);

  useEffect(() => {
    getCommitieRealatedProjects(comitieId);

    if (selectedXmatrixValue) {
      addQueryParam('xMatrix', selectedXmatrixValue);
    }
  }, [activeXmatrixInfo, selectedXmatrixValue]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, dataIndex, confirm)}
            size="small"
            style={{
              width: 90,
            }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    clearFilters();
    setSearchText('');
    handleSearch('', confirm, dataIndex);
  };

  const getActiveXmatrix = async () => {
    if (hasQueryParam('xMatrix')) {
      MatrixService.getSelectedXmatrixInfo(getQueryParam('xMatrix'))
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            setActiveXmatrixInfo(resp.responseObject.value);
            setSelectedXmatrixValue(getQueryParam('xMatrix'));
          }
        });

      return;
    }

    await MatrixService.getActiveXmatrix()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          setActiveXmatrixInfo(respData);
        } else {
        }
      })
      .catch((error) => {});
  };

  const onXmatrixSelectProject = (selectedXmatrix) => {
    setSelectedXmatrixValue(selectedXmatrix.value);
  };

  const getCommitieRealatedProjects = async (id) => {
    if (activeXmatrixInfo) {
      let xmatrix = selectedXmatrixValue ? selectedXmatrixValue : activeXmatrixInfo?.xMatrixID;

      setLoadingRelatedProjects(true);
      await CommitieService.getComitieRelatedProjects(id, xmatrix)
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            let respData = resp.responseObject.value;
            setRelatedProjects(respData);
            setLoadingRelatedProjects(false);
          } else {
            setLoadingRelatedProjects(false);
          }
        })
        .catch((error) => {
          setLoadingRelatedProjects(false);
        });
    } else {
    }
  };

  const saveProjectToComitie = (projectId, comitieId) => {
    let values = {};
    values['projectID'] = projectId;
    values['committeeID'] = comitieId;

    setLoadingSave(true);
    CommitieService.addRemoveProjectToCommitie(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          setLoadingSave(false);
          setProjectComitieModal(false);
          getCommitieRealatedProjects(comitieId);
        } else {
          message.error(notifyMessages.addFailed);
          setLoadingSave(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        setLoadingSave(false);
      });
  };

  const handleProjectComitieModal = () => {
    setProjectComitieModal(false);
  };

  const relatedProjectsColumns = [
    {
      title: `${t('proggetiPage.codice')}`,
      dataIndex: 'projectCode',
      sorter: (a, b) => {
        a = a.projectCode || '';
        b = b.projectCode || '';
        return a.localeCompare(b);
      },
      width: '110px',
      fixed: 'left',
      showSorterTooltip: [true],
      ...getColumnSearchProps('projectCode'),
      defaultSortOrder: 'ascend',
    },
    {
      title: `${t('general.nome')}`,
      dataIndex: 'projectName',
      ellipsis: {
        showTitle: false,
      },
      sorter: (a, b) => {
        a = a.projectName || '';
        b = b.projectName || '';
        return a.localeCompare(b);
      },
      // width: "320px",
      showSorterTooltip: [false],
      ...getColumnSearchProps('projectName'),
      render: (title) => <Tooltip title={title}>{title}</Tooltip>,
    },
    {
      title: `${t('general.teamLeader')}`,
      dataIndex: 'teamLeaderFullName',
      sorter: (a, b) => {
        a = a.teamLeaderFullName || '';
        b = b.teamLeaderFullName || '';
        return a.localeCompare(b);
      },
      showSorterTooltip: [true],
      width: '200px',
      ...getColumnSearchProps('teamLeaderFullName'),
    },
    {
      title: `${t('general.sponsor')}`,
      dataIndex: 'sponsorFullName',
      sorter: (a, b) => {
        a = a.sponsorFullName || '';
        b = b.sponsorFullName || '';
        return a.localeCompare(b);
      },
      width: '200px',
      showSorterTooltip: [true],
      ...getColumnSearchProps('sponsorFullName'),
    },
    {
      title: `${t('general.stato')}`,
      dataIndex: 'statusDescription',
      sorter: (a, b) => {
        a = a.statusDescription || '';
        b = b.statusDescription || '';
        return a.localeCompare(b);
      },
      width: '150px',
      showSorterTooltip: [true],
      ...getColumnSearchProps('statusDescription'),
    },

    {
      key: 'action',
      width: '100px',
      render: (text, record) => {
        return (
          <div className="tw-flex tw-items-center tw-gap-2">
            {!onlyView && (
              <Popconfirm
                title={t('comitatiPage.removeProjectFromComitie')}
                onConfirm={() => saveProjectToComitie(record.projectID, null)}
                okText={t('general.si')}
                cancelText={t('general.no')}
                className="long-term-button remove">
                <Button
                  type="ghost"
                  icon={<DeleteOutlined />}></Button>
              </Popconfirm>
            )}
            <Button
              icon={<EditFilled />}
              href={`/progetti/id/${record.projectID}`}></Button>
          </div>
        );
      },
    },
  ];

  return (
    <TableLayout
      title={
        <div
          onClick={() => onlyView && history.push(`?tab=2`)}
          className={classNames('tw-text-lg tw-font-semibold tw-flex tw-gap-1 tw-items-center', {
            'tw-text-primary tw-cursor-pointer': onlyView,
          })}>
          {t('general.progetti')}
          <ArrowRightIcon className={onlyView ? 'tw-block' : 'tw-hidden'} />
        </div>
      }>
      {activeXmatrixInfo && (
        <TableLayout.Actions>
          {projectComitieModal && (
            <AddProjectToComitieModal
              showModal={projectComitieModal}
              handleModalClose={handleProjectComitieModal}
              addProjectToCommitie={saveProjectToComitie}
              t={t}
              loadingSave={loadingSave}
              comitieId={comitieId}
              selectedXmatrix={selectedXmatrixValue ? selectedXmatrixValue : activeXmatrixInfo?.xMatrixID}
            />
          )}

          <XmatrixDropDownFilter
            onXmatrixSelect={onXmatrixSelectProject}
            activeXmatrixInfo={activeXmatrixInfo}
          />

          {!onlyView ? (
            <Button
              type="primary"
              onClick={() => setProjectComitieModal(true)}
              icon={<PlusOutlined />}>
              {t('buttons.aggiungi')}
            </Button>
          ) : (
            ''
          )}
        </TableLayout.Actions>
      )}

      <TableLayout.Content>
        <Table
          size="small"
          columns={relatedProjectsColumns}
          dataSource={relatedProjects}
          rowKey={(obj) => obj.projectID}
          loading={loadingRelatedProjects}
          pagination={false}
        />
      </TableLayout.Content>
    </TableLayout>
  );
};

export default ComitieProjects;
