import { Configuration, SerializableExceptionError } from 'src/connectors/backend';
import { BaseAPI } from 'src/connectors/backend/base';
import { acquireToken } from './auth/clientAuthProvider';
import deployment from './deploymentConfigs';
import { AxiosError } from 'axios';

export * from 'src/connectors/backend';

interface ApiConstructor<T extends BaseAPI> {
  new(configuration?: Configuration): T;
}

export const api = <T extends BaseAPI>(ctor: ApiConstructor<T>) => {
  return new ctor(
    new Configuration({
      basePath: deployment.backend.href.replace(/\/$/, ''),
      baseOptions: {
        withCredentials: true,
      },
    }),
  );
};

export const authenticatedApi = async <T extends BaseAPI>(ctor: ApiConstructor<T>) => {
  const result = await acquireToken();

  return new ctor(
    new Configuration({
      basePath: deployment.backend.href.replace(/\/$/, ''),
      baseOptions: {
        headers: {
          Authorization: `Bearer ${result?.accessToken}`,
        },
        withCredentials: true,
      },
    }),
  );
};

export const trackApiPromise = async <T>(promise: Promise<T>, area?: string) => {
  try {
    return await promise;
  } catch (e) {
    if (
      e instanceof AxiosError
      && typeof e.response?.data === 'object'
      && 'message' in e.response.data
      && 'errorDescription' in e.response.data
    ) {
      return {
        data: e.response.data,
        status: e.response.status
      } as {
        data: {
          message: string;
          errorDescription: SerializableExceptionError
        }
        status: number;
      }
    }

    throw e;
  }
}