import { PlusOutlined, SwapOutlined } from '@ant-design/icons';
import { Button, Card, Col, message, Row, Space, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProgettiService from '../../../../../services/pages/projectServices/progettiService';
import Loader2 from '../../../../shared/components/loader2/loader2';
import UserBadge from '../../../../shared/userBadge/userBadge';
import { userRolesCodes } from '../../../../shared/utils/constants';
import { updateNotificationBell } from '../../../../shared/utils/functions';
import { notifyMessages } from '../../../../shared/utils/notifyMessages';
import AddMemberModal from './addMemberModal';
import ManageTeamMembers from './manageTeamMembersModal';

interface ProjectTeamMembersProps {
  teamMembers: any[];
  projectId: string;
  isDataLoading: boolean;
  hasUserAccess: boolean;
  isAdminOrProjectTeam: boolean;
  checkProjectEndDateValidity: boolean;
  reloadUsers: () => void;
  isPresentationMode?: boolean;
}

const ProjectTeamMembers: React.FC<ProjectTeamMembersProps> = ({
  teamMembers,
  projectId,
  isDataLoading,
  hasUserAccess,
  isAdminOrProjectTeam,
  checkProjectEndDateValidity,
  reloadUsers,
  isPresentationMode = false,
}) => {
  const { t } = useTranslation();

  const [loadingButton, setLoadingButton] = useState(false);
  const [showTeamMemberModal, setShowTeamMemberModal] = useState(false);
  const [showSwapTeamMemberModal, setShowSwapTeamMemberModal] = useState(false);

  const toggleTeamMemberModal = () => setShowTeamMemberModal(true);
  const toggleManageTeamMemberModal = () => setShowSwapTeamMemberModal(true);
  const handleTeamMemberModalClose = () => {
    setShowTeamMemberModal(false);
    setShowSwapTeamMemberModal(false);
  };

  const addTeamMembers = async (values: any[]) => {
    setLoadingButton(true);

    try {
      const { status, data } = await ProgettiService.updateTeamMembers({
        userIds: values,
        projectID: projectId,
      });

      if (status >= 400 && status <= 499) {
        message.error(t(`projectMembers.errors.${(data as any).errorDescription}`));
        setLoadingButton(false);
        return;
      }

      message.success(notifyMessages.updateSuccess);
      updateNotificationBell();
      handleTeamMemberModalClose();
      reloadUsers();
    } catch {
      message.error(notifyMessages.updateFailed);
    } finally {
      setLoadingButton(false);
    }
  };

  const manageSwapTeamMembers = async (values: any) => {
    values['ProjectID'] = projectId;

    setLoadingButton(true);

    try {
      const response = await ProgettiService.swapProjectTeamMembers(values);
      if (response.data.success) {
        message.success(notifyMessages.updateSuccess);
        handleTeamMemberModalClose();
        reloadUsers();
      } else {
        message.error(notifyMessages.updateFailed);
      }
    } catch {
      message.error(notifyMessages.updateFailed);
    } finally {
      setLoadingButton(false);
    }
  };

  const leaderMembers = teamMembers?.filter(
    (obj) => (obj.roleCode === userRolesCodes.sponsor || obj.roleCode === userRolesCodes.teamLeader) && !obj.isDeleted,
  );

  const otherTeamMembers = teamMembers?.filter(
    (obj) => obj.roleCode !== userRolesCodes.sponsor && obj.roleCode !== userRolesCodes.teamLeader && !obj.isDeleted,
  );

  return (
    <>
      {!isPresentationMode && showTeamMemberModal && (
        <AddMemberModal
          showTeamMemberModal={showTeamMemberModal}
          handleTeamMemberModalClose={handleTeamMemberModalClose}
          handleTeamMemberModalSave={addTeamMembers}
          projectId={projectId}
          teamMembers={teamMembers.filter((x) => !x.isDeleted)}
          loadingButton={loadingButton}
        />
      )}

      {!isPresentationMode && showSwapTeamMemberModal && (
        <ManageTeamMembers
          showModal={showSwapTeamMemberModal}
          handleModalClose={handleTeamMemberModalClose}
          handleModalSave={manageSwapTeamMembers}
          projectTeam={teamMembers}
          loadingSave={loadingButton}
          t={t}
        />
      )}

      <Card
        className="!tw-shadow-none !tw-mb-4"
        bordered={false}
        extra={
          !hasUserAccess && !isAdminOrProjectTeam && !checkProjectEndDateValidity ? (
            <Space direction="horizontal">
              <Tooltip title={t('proggetiPage.changeTeamMembers')}>
                <Button
                  onClick={toggleManageTeamMemberModal}
                  type="dashed"
                  icon={<SwapOutlined />}
                />
              </Tooltip>

              <Tooltip
                placement="topRight"
                title={t('proggetiPage.gestireMembriProgetto')}>
                <Button
                  onClick={toggleTeamMemberModal}
                  type="primary"
                  data-testid="add_member_btn"
                  icon={<PlusOutlined />}>
                  {t('buttons.gestisci')}
                </Button>
              </Tooltip>
            </Space>
          ) : null
        }>
        {isDataLoading && <Loader2 />}

        <Row
          gutter={{ lg: 24 }}
          className="row-margin tw-mt-4">
          {leaderMembers?.map((user, index) => (
            <Col
              key={index}
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 8 }}>
              <UserBadge
                user={user}
                projectId={projectId}
              />
            </Col>
          ))}
        </Row>

        <Row gutter={{ lg: 24 }}>
          {otherTeamMembers?.map((user, index) => (
            <Col
              key={index}
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}>
              <UserBadge
                user={user}
                projectId={projectId}
              />
            </Col>
          ))}
        </Row>
      </Card>
    </>
  );
};

export default ProjectTeamMembers;
