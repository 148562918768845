import { BookOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, message, Popconfirm, Space, Typography } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from 'src/App';
import { LS_XMATRIX_FILTERS_KEY } from 'src/components/xMatrix/xMatrix';
import { useAppSelector } from 'src/redux/store';
import MatrixService from '../../services/matrix/matrixService';
import { isUserAdminUser, userIsAdminOrSteering } from '../shared/utils/authRolesProvilege/authRolesPrivilege';
import { xmatrixStatus } from '../shared/utils/constants';
import { notifyMessages } from '../shared/utils/notifyMessages';

const { Text } = Typography;

const XmatrixHeader = ({
  selectedXmatrixInfo,
  isSecondLevelXmatrix,
  reloadMatrix,
}: {
  selectedXmatrixInfo: any;
  isSecondLevelXmatrix: boolean;
  reloadMatrix: () => void;
}) => {
  const { t } = useTranslation();
  const userData = useAppSelector((state) => state.userData.userData);

  const [loadingButton, setLoadingButton] = useState(false);

  const changeXmatrixStatus = async (xMatrixID: string) => {
    setLoadingButton(true);
    try {
      const response = await MatrixService.updateXmatrixStatus(xMatrixID);
      if (response.data?.success) {
        message.success(notifyMessages.updateSuccess);
        reloadMatrix();
      } else {
        message.error(notifyMessages.updateFailed);
      }
    } catch {
      message.error(notifyMessages.updateFailed);
    } finally {
      setLoadingButton(false);
    }
  };

  const deleteXmatrix = async (xMatrixID: string) => {
    setLoadingButton(true);
    try {
      const response = await MatrixService.deleteXmatrixDraft(xMatrixID);
      if (response.data?.success) {
        message.success(notifyMessages.deleteSuccess);
        localStorage.setItem(LS_XMATRIX_FILTERS_KEY, JSON.stringify({}));
        history.push('/xmatrix');
      } else {
        message.error(notifyMessages.deleteFailed);
      }
    } catch {
      message.error(notifyMessages.deleteFailed);
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <div className="tw-flex tw-items-center tw-gap-2">
      {selectedXmatrixInfo && (
        <>
          {selectedXmatrixInfo.status === xmatrixStatus.active ? (
            <Text className="activeText">{selectedXmatrixInfo.statusDescription}</Text>
          ) : (
            <Text className="inactiveText">{selectedXmatrixInfo.statusDescription}</Text>
          )}
        </>
      )}
      <Text className="menu headerText">{selectedXmatrixInfo?.hkCode || ''}</Text>
      <Text className="menu headerText">{selectedXmatrixInfo?.xMatrixName || ''}</Text>
      <Space
        className="matrixComponentsHeadrPrint"
        direction="vertical"
        style={{ lineHeight: '1' }}>
        {!isSecondLevelXmatrix &&
          selectedXmatrixInfo?.status !== xmatrixStatus.active &&
          userIsAdminOrSteering(userData) && (
            <Popconfirm
              title={`${t('xMatrixPage.attivareXmatrix')}:  ${selectedXmatrixInfo?.hkCode || ''}`}
              onConfirm={() => changeXmatrixStatus(selectedXmatrixInfo.xMatrixID)}
              icon={<BookOutlined style={{ color: '#ca8a04' }} />}
              okText={t('general.si')}
              cancelText={t('general.no')}>
              <Button
                type="link"
                loading={loadingButton}
                icon={<BookOutlined />}>
                {t('xMatrixPage.attivareXmatrix')}
              </Button>
            </Popconfirm>
          )}
        {selectedXmatrixInfo?.status === xmatrixStatus.draft && isUserAdminUser(userData) && (
          <Popconfirm
            title={`${t('xMatrixPage.eliminareXmatrix')}:  ${selectedXmatrixInfo?.hkCode || ''}`}
            onConfirm={() => deleteXmatrix(selectedXmatrixInfo.xMatrixID)}
            icon={<DeleteOutlined style={{ color: '#e94646' }} />}
            okText={t('general.si')}
            cancelText={t('general.no')}>
            <Button
              style={{ color: '#646464' }}
              type="link"
              loading={loadingButton}
              icon={<DeleteOutlined />}>
              {t('xMatrixPage.anullaXmatrix')}
            </Button>
          </Popconfirm>
        )}
      </Space>
    </div>
  );
};

export default XmatrixHeader;
