import {
  DeleteFilled,
  EditFilled,
  PlusOutlined,
  PlusSquareOutlined,
  SearchOutlined,
  SelectOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Button, Input, message, Popconfirm, Space, Table, Typography } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/redux/store';
import { addQueryParam, getQueryParam, hasQueryParam } from 'src/utils/url-utils';
import MatrixService from '../../../../services/matrix/matrixService';
import CommitieService from '../../../../services/pages/commitieServices';
import XmatrixDropDownFilter from '../../../shared/components/xmatrixDropdownFilter/xmatrixDropdownFilter';
import TableLayout from '../../../shared/tableLayout';
import { isUserAdmin } from '../../../shared/utils/authRolesProvilege/authRolesPrivilege';
import { formatOfDate } from '../../../shared/utils/constants';
import { delayFuncCall } from '../../../shared/utils/functions';
import { notifyMessages } from '../../../shared/utils/notifyMessages';
import AddDefaultAgendaModal from '../../addDefaultAgendaModal';
import NewSessionModal from './newSessionModal';
import UpdateSessionModal from './updateSessionModal';

const { Text, Link } = Typography;

const CommitieSessions = ({ comitieId, comitieDetails, userHasAccess }) => {
  const userData = useAppSelector((state) => state.userData.userData);
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [loadingSendMail, setLoadingSendMail] = useState(false);
  const searchInput = useRef(null);

  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingSaveAgenda, setLoadingSaveAgenda] = useState(false);
  const [loadingSaveClose, setLoadingSaveClose] = useState(false);
  const [newSessionModal, setNewSessionModal] = useState(false);
  const [updateSessionModal, setUpdateSessionModal] = useState(false);
  const [agendaModal, setAgendaModal] = useState(false);
  const [standardAgendaModal, setStandardAgendaModal] = useState(false);

  const [sessions, setSessions] = useState(null);
  const [loadingSessions, setLoadingSessions] = useState([]);
  const [selectedSessionData, setSelectedSessionData] = useState(null);

  const [activeXmatrixInfo, setActiveXmatrixInfo] = useState(null);
  const [selectedXmatrixValue, setSelectedXmatrixValue] = useState(null);

  const [agendaDate, setExpiryDate] = useState(null);

  useEffect(() => {
    getActiveXmatrix();
  }, []);

  useEffect(() => {
    getComitieSesions();

    if (selectedXmatrixValue) {
      addQueryParam('xMatrix', selectedXmatrixValue);
    }
  }, [activeXmatrixInfo, selectedXmatrixValue]);

  const getActiveXmatrix = async () => {
    if (hasQueryParam('xMatrix')) {
      MatrixService.getSelectedXmatrixInfo(getQueryParam('xMatrix'))
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            setActiveXmatrixInfo(resp.responseObject.value);
            setSelectedXmatrixValue(getQueryParam('xMatrix'));
          }
        });

      return;
    }

    await MatrixService.getActiveXmatrix()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          setActiveXmatrixInfo(respData);
        }
      })
      .catch((error) => {});
  };

  const getComitieSesions = (createdSessionId) => {
    if (activeXmatrixInfo) {
      let xmatrix = selectedXmatrixValue ? selectedXmatrixValue : activeXmatrixInfo?.xMatrixID;
      setLoadingSessions(true);
      CommitieService.getCommitieSessions(comitieId, xmatrix)
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            let respData = resp.responseObject.value;

            if (createdSessionId) {
              if (respData.length > 0) {
                const createdSeesionData = respData.find((session) => session.sessionID === createdSessionId);
                toggleAgendaModal(createdSeesionData);
                setNewSessionModal(false);
              }
            }
            setSessions(respData);
            setLoadingSessions(false);
          } else {
            setLoadingSessions(false);
          }
        })
        .catch((error) => {
          setLoadingSessions(false);
        });
    } else {
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, dataIndex, confirm)}
            size="small"
            style={{
              width: 90,
            }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    clearFilters();
    setSearchText('');
    handleSearch('', confirm, dataIndex);
  };

  const toggleEditSessionModal = (data) => {
    setSelectedSessionData(data);
    setUpdateSessionModal(true);
  };

  const handleModalClose = () => {
    setNewSessionModal(false);
    setUpdateSessionModal(false);
  };

  const handleSession = (values) => {
    setLoadingSave(true);
    CommitieService.insertUpdateSession(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          // message.success(notifyMessages.addSuccess);
          setLoadingSave(false);
          if (newSessionModal) {
            let createdSessionId = resp.responseObject.value.sessionID;
            getComitieSesions(createdSessionId.toLowerCase());
          } else {
            handleModalClose();
            getComitieSesions();
          }
        } else {
          message.error(notifyMessages.addFailed);
          setLoadingSave(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        setLoadingSave(false);
      });
  };

  function formatDuration(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.round(totalMinutes % 60);

    if (hours === 0) {
      return `${minutes} min`;
    } else if (minutes === 0) {
      return `${hours} h`;
    } else {
      return `${hours} h ${minutes} min`;
    }
  }

  const onRemoveSession = (id) => {
    CommitieService.deleteSession(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.deleteSuccess);
          getComitieSesions();
        } else {
          message.error(notifyMessages.deleteFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.deleteFailed);
      });
  };

  const sessionsColumns = [
    {
      title: `${t('comitatiPage.code')}`,
      dataIndex: 'codice',
      width: '30%',
      sorter: (a, b) => {
        return a.codice.localeCompare(b.codice);
      },
      showSorterTooltip: [false],
      ...getColumnSearchProps('codice'),
    },
    {
      title: `${t('comitatiPage.date')}`,
      agendaStatus: 'date',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => {
        a = a.date || '';
        b = b.date || '';
        return a.localeCompare(b);
      },
      showSorterTooltip: [false],
      render: (text, record) => moment(record.date).format(formatOfDate),
    },
    {
      title: `${t('comitatiPage.startTime')}`,
      dataIndex: 'startTime',
      sorter: (a, b) => {
        a = a.startTime || '';
        b = b.startTime || '';
        return a.localeCompare(b);
      },
      showSorterTooltip: [false],
      render: (text, record) => (record.startTime ? record.startTime.slice(0, 5) : ''),
    },
    {
      title: `${t('comitatiPage.endTime')}`,
      dataIndex: 'endTime',
      sorter: (a, b) => {
        a = a.endTime || '';
        b = b.endTime || '';
        return a.localeCompare(b);
      },
      showSorterTooltip: [false],
      render: (text, record) => {
        if (agendaDate && agendaDate.Output && agendaDate.AgendaHeaderID === record.agendaID) {
          return agendaDate.Output;
        } else {
          return record.endTime ? record.endTime.slice(0, 5) : '';
        }
      },
    },
    {
      title: `${t('proggetiPage.durata')}`,
      dataIndex: 'duration',
      render: (text, record) => formatDuration(record.duration),
    },
    {
      title: `${t('comitatiPage.agenda')}`,
      dataIndex: 'agendaStatus',
      render: (text, record) => {
        return (
          <Text>
            {' '}
            {record.agendaStatus}{' '}
            {userHasAccess ? (
              <PlusSquareOutlined
                onClick={() => toggleAgendaModal(record)}
                style={{ fontSize: '15px', marginLeft: '10px' }}
              />
            ) : (
              ''
            )}
          </Text>
        );
      },
    },
    {
      title: `${t('general.stato')}`,
      sorter: (a, b) => {
        a = a.stateName || '';
        b = b.stateName || '';
        return a.localeCompare(b);
      },
      showSorterTooltip: [false],
      dataIndex: 'stateName',
    },
    {
      key: 'action',
      width: '30px',
      render: (_, record) => {
        if (userHasAccess) {
          return (
            <Popconfirm
              key="remuve"
              title={t('comitatiPage.removeSession')}
              icon={<WarningOutlined />}
              okText={t('general.si')}
              cancelText={t('general.no')}
              onConfirm={() => onRemoveSession(record.sessionID)}>
              <DeleteFilled title={t('comitatiPage.removeSession')} />
            </Popconfirm>
          );
        }
      },
    },
    {
      key: 'action',
      width: '30px',
      render: (text, record) => {
        if (userHasAccess) {
          return (
            <Space>
              <EditFilled onClick={() => toggleEditSessionModal(record)} />
            </Space>
          );
        }
      },
    },
  ];

  const toggleAgendaModal = (data) => {
    setSelectedSessionData(data);
    setAgendaModal(true);
  };

  const addAgenda = (values, closeModal, agendaComitieId, agendaSesionId, agendaId, sendMail) => {
    values['committeeID'] = agendaComitieId;
    values['sessionID'] = agendaSesionId;
    values['agendaID'] = agendaId;

    if (!closeModal) {
      setLoadingSaveAgenda(true);
    }
    if (closeModal) {
      setLoadingSaveClose(true);
    }
    CommitieService.insertUpdateAgenda(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          if (!closeModal) {
            setLoadingSaveAgenda(false);
          }
          if (closeModal) {
            setLoadingSaveClose(false);
          }
          if (closeModal) {
            setAgendaModal(false);
          }

          getComitieSesions();

          if (sendMail) {
            sendAgendaEmail();
          }
        } else {
          message.error(notifyMessages.addFailed);
          setLoadingSaveAgenda(false);
          setLoadingSaveClose(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        setLoadingSaveAgenda(false);
        setLoadingSaveClose(false);
      });
  };

  const sendAgendaEmail = () => {
    setLoadingSendMail(true);
    CommitieService.sendAgendaByMail(comitieId, selectedSessionData.sessionID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.mailSendSuccess);
          reloadSessionListAfterAgendaMailSend();
          setLoadingSendMail(false);
        } else {
          message.error(notifyMessages.mailSendError);
          setLoadingSendMail(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.mailSendError);
        setLoadingSendMail(false);
      });
  };

  const reloadSessionListAfterAgendaMailSend = async () => {
    await delayFuncCall(3000);
    getComitieSesions();
  };

  const onXmatrixSelect = (selectedXmatrix) => {
    setSelectedXmatrixValue(selectedXmatrix.value);
  };

  const getExpiryDate = (data) => {
    setExpiryDate(data);
  };

  const addDefaultAgenda = (values, closeModal) => {
    values['committeeID'] = comitieId;
    values['sessionID'] = null;
    values['agendaID'] = comitieDetails.agendaID;

    if (!closeModal) {
      setLoadingSave(true);
    }
    if (closeModal) {
      setLoadingSaveClose(true);
    }
    CommitieService.insertUpdateAgenda(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          if (!closeModal) {
            setLoadingSave(false);
          }
          if (closeModal) {
            setLoadingSaveClose(false);
          }
          if (closeModal) {
            setStandardAgendaModal(false);
          }
          getComitieSesions();
        } else {
          message.error(notifyMessages.addFailed);
          if (!closeModal) {
            setLoadingSave(false);
          }
          if (closeModal) {
            setLoadingSaveClose(false);
          }
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        if (!closeModal) {
          setLoadingSave(false);
        }
        if (closeModal) {
          setLoadingSaveClose(false);
        }
      });
  };

  return (
    <TableLayout title={t('comitatiPage.comitieSessions')}>
      <TableLayout.Actions>
        {newSessionModal && (
          <NewSessionModal
            showModal={newSessionModal}
            handleModalClose={handleModalClose}
            saveNewSession={handleSession}
            t={t}
            loadingSave={loadingSave}
            committeeID={comitieId}
            comitieDetails={comitieDetails}
            xMatrixID={selectedXmatrixValue ? selectedXmatrixValue : activeXmatrixInfo?.xMatrixID}
            activeXmatrixInfo={activeXmatrixInfo}
            sessions={sessions}
          />
        )}

        {updateSessionModal && selectedSessionData && (
          <UpdateSessionModal
            showModal={updateSessionModal}
            handleModalClose={handleModalClose}
            updateSession={handleSession}
            t={t}
            loadingSave={loadingSave}
            committeeID={comitieId}
            sessionData={selectedSessionData}
          />
        )}

        {/* session agenda modal */}
        {agendaModal && comitieDetails && selectedSessionData && (
          <AddDefaultAgendaModal
            showModal={agendaModal}
            handleModalClose={() => setAgendaModal(false)}
            saveDefaultAgenda={addAgenda}
            t={t}
            committeeID={comitieId}
            loadingSave={loadingSaveAgenda}
            loadingSaveClose={loadingSaveClose}
            commitieData={comitieDetails}
            agendaComitieId={comitieId}
            agendaSessionId={selectedSessionData.sessionID}
            isDefault={false}
            loadingSendMail={loadingSendMail}
            data={selectedSessionData}
            getExpiryDate={getExpiryDate}
            handleSession={handleSession}
          />
        )}

        {/* default agenda modal */}
        {standardAgendaModal && comitieDetails && (
          <AddDefaultAgendaModal
            showModal={standardAgendaModal}
            handleModalClose={() => setStandardAgendaModal(false)}
            saveDefaultAgenda={addDefaultAgenda}
            t={t}
            committeeID={comitieId}
            loadingSave={loadingSave}
            loadingSaveClose={loadingSaveClose}
            commitieData={comitieDetails}
            agendaComitieId={comitieId}
            agendaSessionId={''}
            isDefault={true}
            getExpiryDate={getExpiryDate}
          />
        )}

        {activeXmatrixInfo && (
          <XmatrixDropDownFilter
            onXmatrixSelect={onXmatrixSelect}
            activeXmatrixInfo={activeXmatrixInfo}
            t={t}
          />
        )}

        {isUserAdmin(userData) && (
          <>
            <Button icon={<SelectOutlined />}>
              <Link onClick={() => setStandardAgendaModal(true)}> {t('comitatiPage.defaultAgendda')}</Link>
            </Button>
          </>
        )}

        {comitieDetails.hasDefaultAgenda && userHasAccess && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setNewSessionModal(true)}>
            {t('buttons.aggiungiNuovo')}
          </Button>
        )}
      </TableLayout.Actions>
      <TableLayout.Content>
        <Table
          columns={sessionsColumns}
          dataSource={sessions}
          size="small"
          rowKey={(obj) => obj.sessionID}
          loading={loadingSessions}
          pagination={false}
        />
      </TableLayout.Content>
    </TableLayout>
  );
};

export default CommitieSessions;
