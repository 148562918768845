import { LogoutOutlined } from '@ant-design/icons';
import { Button, Card, Typography } from 'antd';
import React from 'react';
import notFoundImg from '../../../../assets/notFound.png';
import { msalApp } from '../../../../config/auth/clientAuthProvider';

const { Paragraph, Title } = Typography;

const Unauthorized: React.FC = () => {
  const onSignOut = () => {
    msalApp.logout();
  };

  return (
    <div className="tw-h-screen tw-flex tw-items-center tw-justify-center">
      <Card className="tw-max-w-[450px]">
        <div className="tw-flex tw-items-center tw-justify-center tw-flex-col">
          <div>
            <Title level={2}>Non autorizzato</Title>
            <Paragraph>Non sei autorizzato ad accedere alla pagina che stai cercando.</Paragraph>
            <Button
              onClick={onSignOut}
              href="/"
              type="primary"
              icon={<LogoutOutlined />}>
              Esci
            </Button>
          </div>
          <img
            className="tw-mt-8 tw-max-w-[200px]"
            src={notFoundImg}
            alt="Page not found"
          />
        </div>
      </Card>
    </div>
  );
};

export default Unauthorized;
