import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LayoutService from '../../services/layoutService';
import { addQueryParam, getQueryParam } from '../../utils/url-utils.js';
import CompaniesListPage from '../company/companyListPage/companies';
import CompanyProfile from '../company/companyProfile';
import CompanyNotifications from '../companyNotifications/companyNotifications';
import DivisionsListPage from '../divisions/divisions';
import ListCategoryPage from '../listCategoryPage/listCategoryPage';
import UserIndexPage from '../userListPage';
import XmatrixCycleListPage from '../xmatrixCycle/xmatrixCycleListPage';

interface MenuItem {
  key: string;
  label: string;
  children: React.ReactNode;
}

const SettingsPageIndex: React.FC = () => {
  const { t } = useTranslation();
  const [menu, setMenu] = useState<MenuItem[]>([]);
  const [activeTab, setActiveTab] = useState<string>('0');

  const getComponentForMenuItem = (menuItemLink: string): React.ReactNode => {
    switch (menuItemLink) {
      case '/companies':
        return <CompaniesListPage />;
      case '/divisions':
        return <DivisionsListPage />;
      case '/utenti':
        return <UserIndexPage />;
      case '/categorie':
        return <ListCategoryPage />;
      case '/notifications':
        return <CompanyNotifications />;
      case '/strategicCycle':
        return <XmatrixCycleListPage />;
      default:
        return <div>No component found for this link</div>;
    }
  };

  useEffect(() => {
    const tab = getQueryParam('tab') || '0';
    setActiveTab(tab);

    const fetchMenuItems = async () => {
      try {
        const response = await LayoutService.getMenuItem();
        const data = response.data;

        if (data?.success && data.responseObject?.value) {
          const menuItems: MenuItem[] = data.responseObject.value
            .filter((menu) => menu.menuLink === '/configurazione')
            .flatMap((menu) =>
              menu.menuItems?.map((item, index) => {
                if (item.menuItemLink && item.menuItemDisplayName) {
                  return {
                    key: index.toString(),
                    label: item.menuItemDisplayName,
                    children: getComponentForMenuItem(item.menuItemLink),
                  };
                }
                return undefined;
              }),
            )
            .filter((item): item is MenuItem => item !== undefined) // Filtra gli `undefined`
            .concat({
              key: '20',
              label: t('headerMenu.miaSocieta'),
              children: <CompanyProfile />,
            });

          setMenu(menuItems);
        }
      } catch (error) {
        console.error('Error fetching menu items:', error);
      }
    };

    fetchMenuItems();
  }, [t]);

  return (
    <Tabs
      className="tw-px-4"
      onChange={(key) => {
        addQueryParam('tab', key);
        setActiveTab(key);
      }}
      activeKey={activeTab}
      items={menu}
    />
  );
};

export default SettingsPageIndex;
