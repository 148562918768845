/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, Spin, Table, Tooltip } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CommonService from '../../../services/commonService';
import DashboardService from '../../../services/pages/dashboardService';
import {
  BudgetTooltip,
  ProjectBudgetSemaphore,
  ProjectQualitySemaphore,
  ProjectTimeSemaphore,
  QualityTooltip,
  TimeTooltip,
  checkSemaphoreColor,
} from '../../shared/semaphores/semaphores';
import { statusPickListCodes } from '../../shared/utils/constants';
import { alignCurentMonthProjectSintesi, capitalizeFirstLetter } from '../../shared/utils/functions';
import './projectTimeline.scss';

import { CalendarOutlined, CloseOutlined, FilterOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { TableColumnType } from 'antd/lib';
import classNames from 'classnames';
import {
  addMonths,
  endOfMonth,
  format,
  getISOWeek,
  isAfter,
  isBefore,
  isEqual,
  max,
  min,
  parse,
  startOfMonth,
} from 'date-fns';
import { history } from 'src/App';
import {
  GetCommitteePicklistDto,
  GetDivisionPicklistDto,
  GetProjectCodesResponseDto,
  GetSponsorPicklistResponseDto,
  GetTeamLeaderPicklistDto,
  ProjectDto,
  ProjectParentship,
  ProjectStatus,
  QueryProjectFilters,
  StatusPicklistResponseDto,
} from 'src/connectors/backend';
import { useAppSelector } from 'src/redux/store';
import ProjectsTimelineFilters, { FiltersPicklists } from './projectsTimelineFilters';

interface FilterLabel {
  value: keyof QueryProjectFilters;
  label: string;
}

export interface ProjectsTimelineProps {
  selectedXmatrixIds: string[];
}

const ProjectsTimeline: FC<ProjectsTimelineProps> = (props) => {
  const { t } = useTranslation();

  const companyData = useAppSelector((state) => state.companyData.companyData);
  const monthsGraphRef = useRef<HTMLTableCellElement>(null);

  const [tableColumns, setTableColumns] = useState<TableColumnType<ProjectDto>[]>([]);
  const [filters, setFilters] = useState<QueryProjectFilters>({
    selectNotRelatedProjects: true,
    projectStatuses: [ProjectStatus.Active],
    sponsorIds: [],
    commiteeIds: [],
    divisionIds: [],
    projectCodes: [],
    teamLeaderIds: [],
    projectParentship: ProjectParentship.All,
    xMatrixIds: [],
  });
  const [picklists, setPicklists] = useState<FiltersPicklists>({
    teamLeaders: [],
    projectStatuses: [],
    projectCodes: [],
    divisions: [],
    committees: [],
    sponsors: [],
  });
  const [loadingData, setLoadingData] = useState(true);
  const [areFiltersPicklistsLoaded, setAreFiltersPicklistsLoaded] = useState(false);
  const [projects, setProjects] = useState<ProjectDto[]>([]);
  const [selectedFiltersLabel, setSelectedFiltersLabel] = useState<FilterLabel[]>([]);
  const [showFiltersModal, setShowFiltersModal] = useState(false);

  useEffect(() => {
    if (monthsGraphRef.current) {
      alignCurentMonthProjectSintesi();
    }
  }, [tableColumns, projects, props.selectedXmatrixIds, filters]);

  useEffect(() => {
    void fetchProjects(props.selectedXmatrixIds);
  }, [props.selectedXmatrixIds, filters]);

  useEffect(() => {
    let newFiltersLabels: FilterLabel[] = [];

    if (filters.projectStatuses && filters.projectStatuses.length > 0) {
      newFiltersLabels.push({ value: 'projectStatuses', label: t('dashboardProgettiPage.statoProgetto') });
    } else {
      // no status selected
      newFiltersLabels = newFiltersLabels.filter((filter) => filter.value !== 'projectStatuses');
    }

    if (filters.selectNotRelatedProjects) {
      newFiltersLabels.push({ value: 'selectNotRelatedProjects', label: t('proggetiPage.progettiSenzaRelazione') });
    } else {
      newFiltersLabels = newFiltersLabels.filter((filter) => filter.value !== 'selectNotRelatedProjects');
    }

    setSelectedFiltersLabel(newFiltersLabels);
  }, [filters]);

  const toggleFiltersModal = () => {
    setShowFiltersModal(!showFiltersModal);

    if (!areFiltersPicklistsLoaded) {
      retrieveTeamLeaders();
      retrieveSponsor();
      retrieveStatus();
      retrieveDivisions();
      retrieveCommittees();
      retrieveProjectCode();

      setAreFiltersPicklistsLoaded(true);
    }
  };

  const fetchProjects = async (xmatrixIds: string[]) => {
    try {
      setLoadingData(true);
      const { data: projects } = await DashboardService.getDashboardProjects({
        xMatrixIds: xmatrixIds,
        projectParentship: ProjectParentship.All,
        teamLeaderIds: filters.teamLeaderIds,
        projectStatuses: filters.projectStatuses,
        divisionIds: filters.divisionIds,
        sponsorIds: filters.sponsorIds,
        commiteeIds: filters.commiteeIds,
        projectCodes: filters.projectCodes,
        selectNotRelatedProjects: filters.selectNotRelatedProjects,
      });

      const cols = generateYearsAndMonthsColumns(projects);
      setTableColumns(baseColumns.concat(cols));
      setProjects(projects);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingData(false);
      alignCurentMonthProjectSintesi();
    }
  };

  const retrieveTeamLeaders = async () => {
    try {
      const response = await DashboardService.getTeamLaderFilterData();
      const resp = response.data;

      if (resp.success) {
        const teamLeaders = resp?.responseObject?.value as GetTeamLeaderPicklistDto[];
        setPicklists((prevPicklists) => ({ ...prevPicklists, teamLeaders }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const baseColumns: TableColumnType<ProjectDto>[] = [
    {
      title: `${t('proggetiPage.codice')}`,
      dataIndex: 'code',
      fixed: 'left',
      className: 'sticky',
      width: '150px',
      sorter: (a, b) => {
        return a.code.localeCompare(b.code);
      },
      showSorterTooltip: true,
      defaultSortOrder: 'ascend',
    },
    {
      title: `${t('general.nome')}`,
      dataIndex: 'name',
      width: '500px',
      fixed: 'left',
      sorter: (a, b) => {
        return (a.name as string).localeCompare(b.name as string);
      },
      showSorterTooltip: false,
    },
    {
      title: `${t('kpiDashboard.semaphore')}`,
      dataIndex: 'semaforo',
      key: 'semaforo',
      className: 'sticky',
      width: '100px',
      fixed: 'left',
      ellipsis: {
        showTitle: false,
      },
      render: (value, record) => (
        <Flex gap={4}>
          <Tooltip
            placement="top"
            title={
              <TimeTooltip
                data={record}
                t={t}
              />
            }>
            <div className={'gutter-row lower-section header-border b-l ' + checkSemaphoreColor(record.timeSemaphore)}>
              <ProjectTimeSemaphore data={record} />
            </div>
          </Tooltip>

          <Tooltip
            placement="top"
            title={
              <BudgetTooltip
                data={record}
                t={t}
                companyData={companyData}
              />
            }>
            <div className={'gutter-row lower-section header-border ' + checkSemaphoreColor(record.budgetSemaphore)}>
              <ProjectBudgetSemaphore data={record} />
            </div>
          </Tooltip>

          <Tooltip
            placement="top"
            title={
              <QualityTooltip
                data={record}
                t={t}
              />
            }>
            <div className={'gutter-row lower-section b-r ' + checkSemaphoreColor(record.qualitySemaphore)}>
              <ProjectQualitySemaphore data={record} />
            </div>
          </Tooltip>
        </Flex>
      ),
    },
  ];

  const generateYearsBetween = (startDate: Date, endDate: Date) => {
    const years: number[] = [];

    for (let i = startDate.getFullYear(); i <= endDate.getFullYear(); i++) {
      years.push(i);
    }

    return years;
  };

  const generateMonthsBetween = (start: Date, end: Date) => {
    const monthsDates: Date[] = [];
    let current = new Date(start);

    while (isBefore(current, end) || isEqual(current, end)) {
      monthsDates.push(current);
      current = addMonths(current, 1);
    }

    return monthsDates;
  };

  const createMonthColumn = (yearMonth: Date, currentMonthAndYear: string) => {
    const threeLetterMonth = format(yearMonth, 'MMM');
    const year = yearMonth.getFullYear();

    const result: TableColumnType<ProjectDto> = {
      title: capitalizeFirstLetter(threeLetterMonth),
      align: 'center',
      width: '100px',
      dataIndex: threeLetterMonth,
      className: `${threeLetterMonth} ${year}` === currentMonthAndYear ? 'currentMonthLineIndicatorSintesi' : '',
      onCell: (project) => {
        return {
          className: '!tw-p-0 tw-text-transparent tw-select-none',
          ref: monthsGraphRef,
        };
      },
      render(value, project) {
        const startDate = startOfMonth(parse(project.startDate as string, 'yyyy-MM-dd', new Date()));
        const endDate = endOfMonth(parse(project.endDate as string, 'yyyy-MM-dd', new Date()));
        const monthDate = new Date(year, yearMonth.getMonth(), 15);
        const isActive =
          (isAfter(monthDate, startDate) && isBefore(monthDate, endDate)) ||
          isEqual(monthDate, startDate) ||
          isEqual(monthDate, endDate);

        return (
          <div
            className={
              isActive
                ? classNames('tw-h-[30px]', {
                    'tw-bg-green-500': project.statusDescription?.toLowerCase() === ProjectStatus.Active,
                    'tw-bg-yellow-500': project.statusDescription?.toLowerCase() === ProjectStatus.Suspended,
                    'tw-bg-indigo-500': project.statusDescription?.toLowerCase() === ProjectStatus.Completed,
                    'tw-bg-gray-400': project.statusDescription?.toLowerCase() === ProjectStatus.Draft,
                  })
                : ''
            }>
            -
          </div>
        );
      },
    };

    return result;
  };

  const generateYearsAndMonthsColumns = (projects: ProjectDto[]) => {
    const columns: TableColumnType<ProjectDto>[] = [];
    const oldestStartDate = min(projects.map((p) => new Date(p.startDate!)));
    const newestEndDate = max(projects.map((p) => new Date(p.endDate!)));
    const years = generateYearsBetween(oldestStartDate, newestEndDate);
    const currentMonthAndYear = format(new Date(), 'MMM yyyy');

    for (const year of years) {
      const yearColumn: { title: string; children: TableColumnType<ProjectDto>[] } = {
        title: year.toString(),
        children: [],
      };
      const projectsMonths = generateMonthsBetween(oldestStartDate, newestEndDate);
      const yearMonths = projectsMonths.filter((yearMonth) => yearMonth.getFullYear() === year);

      for (const yearMonth of yearMonths) {
        yearColumn.children.push(createMonthColumn(yearMonth, currentMonthAndYear));
      }

      columns.push(yearColumn);
    }

    return columns;
  };

  const retrieveSponsor = async () => {
    try {
      const response = await DashboardService.getSponsorFilterData();
      const resp = response.data;

      if (resp.success) {
        const sponsors = resp?.responseObject?.value as GetSponsorPicklistResponseDto[];
        setPicklists((prevPicklists) => ({ ...prevPicklists, sponsors }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const retrieveProjectCode = async () => {
    try {
      const response = await DashboardService.getProjectCodeData();
      const resp = response.data;

      if (resp.success) {
        const projectCodes = resp?.responseObject?.value as GetProjectCodesResponseDto[];
        setPicklists((prevPicklists) => ({ ...prevPicklists, projectCodes }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const retrieveDivisions = async () => {
    try {
      const response = await DashboardService.getDivisionFilterData();
      const resp = response.data;

      if (resp.success) {
        const divisions = resp?.responseObject?.value as GetDivisionPicklistDto[];
        setPicklists((prevPicklists) => ({ ...prevPicklists, divisions }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const retrieveCommittees = async () => {
    try {
      const response = await DashboardService.getCommittiesFilterData();
      const resp = response.data;

      if (resp.success) {
        const committees = resp?.responseObject?.value as GetCommitteePicklistDto[];

        setPicklists((prevPicklists) => ({ ...prevPicklists, committees }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const retrieveStatus = async () => {
    try {
      const objectCode = statusPickListCodes.statusProjects;
      const response = await CommonService.getStatusData(objectCode);
      const resp = response.data;

      if (resp.success) {
        const statuses = resp?.responseObject?.value as StatusPicklistResponseDto[];
        setPicklists((prevPicklists) => ({ ...prevPicklists, projectStatuses: statuses }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const removeSelectedFilter = (filter: keyof QueryProjectFilters) => {
    switch (filter) {
      case 'selectNotRelatedProjects':
        setFilters((prevFilters) => ({
          ...prevFilters,
          selectNotRelatedProjects: false,
        }));
        break;
      case 'projectStatuses':
        setFilters((prevFilters) => ({
          ...prevFilters,
          projectStatuses: [],
        }));
        break;
      default:
        break;
    }
  };

  const onApplyFilters = (newFilters: QueryProjectFilters) => {
    setShowFiltersModal(false);
    setFilters(newFilters);
  };

  if (loadingData && projects.length === 0) {
    return (
      <div className="tw-flex tw-size-full tw-justify-center tw-items-center">
        <Spin />
      </div>
    );
  }

  return (
    <div className="tw-overflow-hidden tw-flex tw-flex-col tw-h-full dashboard projectSintesi">
      {/* Dialog */}
      {areFiltersPicklistsLoaded && (
        <ProjectsTimelineFilters
          showFiltersModal={showFiltersModal}
          filtersModalClose={() => setShowFiltersModal(false)}
          applyFilters={onApplyFilters}
          picklists={picklists}
          initialValue={filters}
        />
      )}

      <div className="tw-flex tw-flex-row tw-items-center">
        <Button
          type="text"
          className="filter-button tw-shrink-0"
          onClick={() => toggleFiltersModal()}>
          {t('dashboardProgettiPage.dashboardFilter')}
          <FilterOutlined />
        </Button>

        <div className="tw-flex tw-flex-row tw-items-center tw-overflow-x-auto tw-px-2">
          {selectedFiltersLabel.map((item, index) => (
            <Button
              key={index}
              type="text"
              className="fixed-grey-button">
              {item.label} <CloseOutlined onClick={() => removeSelectedFilter(item.value)} />
            </Button>
          ))}
        </div>

        <span className="tw-ml-auto tw-shrink-0">
          <CalendarOutlined style={{ color: 'black' }} />
          <span style={{ color: 'black' }}>
            {t('dashboardProgettiPage.settimanaCorrente')}: {getISOWeek(new Date())}
          </span>
        </span>
      </div>

      <div className="tw-max-h-full tw-overflow-auto tw-grow">
        <Table
          sticky
          loading={loadingData}
          onRow={(r) => ({
            className: 'tw-cursor-pointer',
            onClick: () => history.push(`/progetti/id/${r.id}`),
          })}
          columns={tableColumns}
          dataSource={projects}
          rowKey={(row) => row.id}
          pagination={false}
          size="small"
          scroll={{ x: 'max-content' }}
        />
      </div>
    </div>
  );
};

export default ProjectsTimeline;
