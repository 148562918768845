import { SwapOutlined } from '@ant-design/icons';
import { Button, Card, Col, Modal, Radio, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import { userRolesCodes } from '../../../../shared/utils/constants';

const ManageTeamMembers = ({
  showModal,
  handleModalClose,
  handleModalSave,
  loadingSave,
  t,
  projectTeam,
}) => {
  const [membersListIn, setMembersListIn] = useState([]);
  const [membersListOut, setMembersListOut] = useState([]);
  const [memberIn, setMemberIn] = useState(null);
  const [memberOut, setMemberOut] = useState(null);

  useEffect(() => {
    if (projectTeam && projectTeam.length > 0) {
      let onlyTeamMembers = projectTeam.filter(
        (obj) => obj.roleCode !== userRolesCodes.sponsor && obj.roleCode !== userRolesCodes.teamLeader,
      );
      setMembersListIn(onlyTeamMembers);
      setMembersListOut(onlyTeamMembers);
    }
  }, []);

  const handleChangeTeamMemberOut = (e) => {
    let memberId = e.target.value;
    setMemberOut(memberId);
    setMemberIn(null);
  };

  const handleChangeTeamMemberIn = (e) => {
    let memberId = e.target.value;
    setMemberIn(memberId);
  };

  const swapTeamMembers = () => {
    let membersObj = { TeamMemberIn: memberIn, TeamMemberOut: memberOut };
    handleModalSave(membersObj);
  };

  return (
    <>
      <Modal
        width={800}
        title={t('proggetiPage.changeTeamMembers')}
        open={showModal}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={handleModalClose}
        footer={[
          <Space direction="horizontal">
            <Button
              loading={loadingSave}
              icon={<SwapOutlined />}
              type="primary"
              key="submit"
              disabled={!(memberIn && memberOut)}
              onClick={() => swapTeamMembers()}>
              {t('proggetiPage.swap')}
            </Button>

            <Button
              onClick={handleModalClose}
              key="close">
              {t('buttons.annulla')}
            </Button>
          </Space>,
        ]}>
        <Card>
          <Row
            gutter={{ lg: 24 }}
            className="row-margin membersInOut">
            <Col
              className="gutter-row tw-mb-4"
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}>
              <Card title={t('proggetiPage.memberOut')}>
                <Radio.Group onChange={handleChangeTeamMemberOut}>
                  <Space direction="vertical">
                    {membersListOut &&
                      membersListOut.map((item) => (
                        <Radio
                          value={item.userID}
                          key={item.userID}
                          disabled={item.isDeleted}>
                          {item.fullName}
                        </Radio>
                      ))}
                  </Space>
                </Radio.Group>
              </Card>
            </Col>

            <Col
              className="gutter-row tw-mb-4"
              xs={{ span: 24 }}
              sm={{ span: 11 }}
              md={{ span: 11 }}
              lg={{ span: 11 }}>
              <Card title={t('proggetiPage.memberIn')}>
                <Radio.Group
                  onChange={handleChangeTeamMemberIn}
                  disabled={!memberOut}
                  value={memberIn}>
                  <Space direction="vertical">
                    {membersListIn &&
                      membersListIn.map((item) => (
                        <Radio
                          disabled={item.userID === memberOut ? true : false}
                          value={item.userID}
                          key={item.userID}>
                          {item.fullName}
                        </Radio>
                      ))}
                  </Space>
                </Radio.Group>
              </Card>
            </Col>
          </Row>
        </Card>
      </Modal>
    </>
  );
};
export default ManageTeamMembers;
