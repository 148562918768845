import { Form } from 'antd';
import { Component, useState } from 'react';
import '../../project.scss';
import './projectResource.scss';

import { EditOutlined, SaveOutlined, SearchOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Divider, Input, InputNumber, message, Space, Table, Tooltip, Typography } from 'antd';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import ResourceWorkloadService from '../../../../services/pages/resourceWorkloadServices';
import TableLayout from '../../../shared/tableLayout';
import { isRoleTeamMember } from '../../../shared/utils/authRolesProvilege/authRolesPrivilege';
import {
  projectWorkloadColumNames,
  workloadColumnNamesValues,
  workloadTypeValues,
} from '../../../shared/utils/constants';
import { capitalizeFirstLetter, numberWithCommas } from '../../../shared/utils/functions';
import { notifyMessages } from '../../../shared/utils/notifyMessages';
import {
  getTotalAllocatoProgettoYTD,
  getTotalBudgetProgettoYTD,
  getTotalConsutivoYTD,
  getTotalNonAllocatoProgettoYTD,
} from './ytdCalculations';

const { Text } = Typography;

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
  // const inputNode = inputType === 'number' ? <InputNumber min={0} step="0.001" /> : <InputNumber min={0} step="0.001" />;
  const inputNode = (
    <InputNumber
      min={0}
      decimalSeparator=","
      precision="2"
    />
  );
  return (
    <td {...restProps}>
      {editing ? (
        <>
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              () => ({
                validator(_, value) {
                  if (isNaN(value) && value < 0) {
                    return Promise.reject('No negative numbers');
                  }
                  return Promise.resolve();
                },
              }),
            ]}>
            {inputNode}
          </Form.Item>
        </>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTable = (props) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={node => {
          //   this.searchInput = node;
          // }}
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    setSearchText('');
  };

  const isEditableRow = (record) =>
    record.Name === workloadColumnNamesValues.budgetProject ||
    record.Name === workloadColumnNamesValues.budgetUserProject
      ? true
      : false;

  const isEditing = (record) => record.key === editingKey;

  const hasBottomBorder = (record) =>
    record.Name === projectWorkloadColumNames.disponibile && record.Type === workloadTypeValues.user
      ? '2px solid #6f6f6f'
      : '';

  const edit = (record) => {
    form.setFieldsValue({
      // name: '',
      // age: '',
      // address: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key, record) => {
    try {
      const values = await form.getFieldsValue(null, (meta) => meta.touched);

      props.updateRow(values, record);
    } catch (errInfo) {}
  };

  const renderedColumns = () => {
    let columns = [];

    const tabelData = props.propsData;

    if (tabelData && tabelData.length > 0) {
      let firstRow = tabelData[0];

      for (const col in firstRow) {
        if (col === projectWorkloadColumNames.name) {
          columns.push({
            dataIndex: col,
            title: '',
            fixed: 'left',
            editable: false,
            // width: 40,
            ellipsis: {
              showTitle: false,
            },
            render(text, record) {
              return {
                props: {
                  style: { borderBottom: hasBottomBorder(record) },
                },
                children: (
                  <Tooltip title={props.t(`budgetTypes.${text}`)}>
                    {isEditableRow(record) ? (
                      <Text strong>{props.t(`budgetTypes.${text}`)}</Text>
                    ) : (
                      <Text>{props.t(`budgetTypes.${text}`)}</Text>
                    )}
                  </Tooltip>
                ),
              };
            },
          });
        } else if (col === projectWorkloadColumNames.fullName) {
          columns.push({
            ...(props.isTotal ? '' : getColumnSearchProps(col)),
            dataIndex: col,
            fixed: 'left',
            //width: 40,
            title: '',
            editable: false,
            ellipsis: {
              showTitle: false,
            },
            render: (text, row, index) => {
              let val = <Tooltip title={text}>{text}</Tooltip>;
              const obj = {
                children: val,
                props: {},
              };
              if (index % 4 === 0) {
                obj.props.rowSpan = 4;
              } else {
                obj.props.rowSpan = 0;
              }
              return obj;
            },
          });
        } else if (
          col === projectWorkloadColumNames.projectUserRelationshipID ||
          col === projectWorkloadColumNames.key ||
          col === projectWorkloadColumNames.type
        ) {
          continue;
        } else if (col === projectWorkloadColumNames.total) {
          columns.push({
            align: 'right',
            fixed: 'right',
            dataIndex: col,
            // title: col,
            title: `${props.t('general.totale')}`,
            width: 100,
            editable: false,
            render(text, record) {
              return {
                props: {
                  style: {
                    borderBottom: hasBottomBorder(record),
                    background: parseInt(text) < 0 ? '#ff6b6b' : '',
                  },
                },
                children: isEditableRow(record) ? (
                  <Text strong>{numberWithCommas(text)}</Text>
                ) : (
                  <Text>{numberWithCommas(text)}</Text>
                ),
              };
            },
          });
        } else {
          let month = moment(col).format('MMM YYYY');
          columns.push({
            align: 'right',
            dataIndex: col,
            title: capitalizeFirstLetter(month),
            editable: true,
            width: 100,
            render(text, record) {
              return {
                props: {
                  style: {
                    borderBottom: hasBottomBorder(record),
                    background: parseInt(text) < 0 ? '#ff6b6b' : '',
                  },
                },
                children: isEditableRow(record) ? (
                  <Text strong>{numberWithCommas(text)}</Text>
                ) : (
                  <Text>{numberWithCommas(text)}</Text>
                ),
              };
            },
          });
        }
      }

      if (!isRoleTeamMember(props.userData)) {
        columns.push({
          title: '',
          dataIndex: 'operation',
          //width: 50,
          fixed: 'right',
          render: (_, record) => {
            if (isEditableRow(record)) {
              const editable = isEditing(record);
              return editable ? (
                <span>
                  {props.loadingIcon && (
                    <SyncOutlined
                      style={{ color: '#0f87dc', marginRight: 5 }}
                      spin
                    />
                  )}
                  <Typography.Link onClick={() => save(record.key, record)}>
                    <SaveOutlined style={{ marginRight: 5 }} />
                    {props.t('buttons.salva')}
                  </Typography.Link>
                  <Divider
                    type="vertical"
                    style={{ borderLeft: '1px solid rgb(183 166 166)' }}
                  />
                  <Typography.Link onClick={() => cancel()}>{props.t('buttons.chiudi')}</Typography.Link>
                </span>
              ) : (
                <>
                  <Typography.Link
                    disabled={editingKey !== ''}
                    onClick={() => edit(record)}>
                    <EditOutlined /> {props.t('buttons.modifica')}
                  </Typography.Link>
                </>
              );
            } else {
              return;
            }
          },
        });
      }
      return columns;
    }
    return columns;
  };

  const mergedColumns = renderedColumns().map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'number',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form
      form={form}
      component={false}>
      <Table
        className="caricoRisorse"
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        size="small"
        dataSource={props.propsData}
        columns={
          props.hasUserAccess
            ? mergedColumns.filter((el) => {
                return el.dataIndex !== 'operation';
              })
            : mergedColumns
        }
        pagination={false}
        rowClassName={(record) => (record.Type === workloadTypeValues.project ? 'table-row-project' : '')}
        //scroll={{ x: 'calc(1000px + 50%)' }}
        scroll={props.isTotal ? { x: 'max-content' } : { x: 'max-content', y: 350 }}
        loading={props.loadingData || props.loadingTotal}
      />
    </Form>
  );
};

class ProjectResourceWorkload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
      loadingData: false,
      loadingTotal: false,
      projectWorkloadData: null,
      totalProjectWorkloadData: null,
      editingKey: '',
      budgetProgettoYTD: null,
      allocatoProgettoYTD: null,
      nonAllocatoYTD: null,
      consutivoYTD: null,
    };
  }

  componentWillMount() {
    this.getProjectWorkload();
    this.getTotalProjectWorkload();
  }

  async getProjectWorkload() {
    const { projectId } = this.props;
    this.setState({ loadingData: true });
    await ResourceWorkloadService.getProjectWorkloadData(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          resp.responseObject.value.forEach((item, index) => {
            let total = 0;
            for (const col in item) {
              if (
                col !== projectWorkloadColumNames.fullName &&
                col !== projectWorkloadColumNames.name &&
                col !== projectWorkloadColumNames.projectUserRelationshipID &&
                col !== projectWorkloadColumNames.type
              ) {
                total += item[col];
              }
            }
            item['Totale'] = total;
            item['key'] = index.toString();
          });
          this.setState({ projectWorkloadData: resp.responseObject.value });
          this.setState({ loadingData: false });
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingData: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingData: false });
      });
  }

  async getTotalProjectWorkload() {
    const { projectId } = this.props;
    this.setState({ loadingTotal: true });
    await ResourceWorkloadService.getTotalProjectWorkloadData(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          resp.responseObject.value.forEach((item, index) => {
            let total = 0;
            for (const col in item) {
              if (
                col !== projectWorkloadColumNames.fullName &&
                col !== projectWorkloadColumNames.name &&
                col !== projectWorkloadColumNames.projectUserRelationshipID &&
                col !== projectWorkloadColumNames.type
              ) {
                total += item[col];
              }
            }
            item['Totale'] = total;
            item['key'] = index.toString();
          });
          // this.setState({ totalProjectWorkloadData: resp.responseObject.value });

          this.setState({ totalProjectWorkloadData: resp.responseObject.value }, () => {
            this.calculateBudgetProgettoYTD();
            this.calculateAllocatoProgettoYTD();
            this.calculateNonAllocatoProgettoYTD();
            this.calculateConsutivoYTD();
          });

          this.setState({ loadingTotal: false });
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingTotal: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingData: false });
      });
  }

  updateProjectWorkload = (values, record) => {
    const { projectId } = this.props;
    let payload = {};
    let monthsArray = [];

    payload['id'] = record.ProjectUserRelationshipID;
    payload['type'] = record.Type;

    if (values) {
      for (const item in values) {
        monthsArray.push({ referencePeriod: item, value: values[item] });
      }
    } else {
      monthsArray = [];
    }
    payload['months'] = monthsArray;

    this.setState({ loadingButton: true });
    ResourceWorkloadService.updateProjectResourceWorkloadData(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          this.setState({ loadingButton: false });
          this.setState({ cancelRow: true });
          // window.location.reload();
          //window.location = `/progetti/id/${projectId}/5`;
          this.getProjectWorkload().then(() => {
            this.getTotalProjectWorkload();
            this.props.reloadProject(projectId);
          });
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  calculateBudgetProgettoYTD = () => {
    const { totalProjectWorkloadData } = this.state;
    let valueYTD = getTotalBudgetProgettoYTD(totalProjectWorkloadData);

    this.setState({ budgetProgettoYTD: valueYTD });
  };

  calculateAllocatoProgettoYTD = () => {
    const { totalProjectWorkloadData } = this.state;
    let valueYTD = getTotalAllocatoProgettoYTD(totalProjectWorkloadData);
    this.setState({ allocatoProgettoYTD: valueYTD });
  };

  calculateNonAllocatoProgettoYTD = () => {
    const { totalProjectWorkloadData } = this.state;
    let valueYTD = getTotalNonAllocatoProgettoYTD(totalProjectWorkloadData);
    this.setState({ nonAllocatoYTD: valueYTD });
  };

  calculateConsutivoYTD = () => {
    const { totalProjectWorkloadData } = this.state;
    let valueYTD = getTotalConsutivoYTD(totalProjectWorkloadData);
    this.setState({ consutivoYTD: valueYTD });
  };

  render() {
    const {
      loadingData,
      loadingTotal,
      loadingButton,
      projectWorkloadData,
      totalProjectWorkloadData,
      budgetProgettoYTD,
      allocatoProgettoYTD,
      nonAllocatoYTD,
      consutivoYTD,
    } = this.state;
    const { hasUserAccess, t, userData } = this.props;

    return (
      <TableLayout title={t('caricoRisorsePage.title')}>
        <TableLayout.Content>
          <div className="tw-bg-zinc-100 tw-py-4 tw-rounded-md tw-px-2 tw-grid tw-grid-flow-col">
            <Text strong>{t('proggetiPage.caricoRisorse')}</Text>
            <Text>
              {t('proggetiPage.budgetProgettoYtd')}:
              <Text
                className="ml"
                strong>
                {budgetProgettoYTD}
              </Text>
            </Text>
            <Text>
              {t('proggetiPage.allocatoProgettoYtd')}:
              <Text
                className="ml"
                strong>
                {allocatoProgettoYTD}
              </Text>
            </Text>
            <Text>
              {t('proggetiPage.nonAllocatoYtd')}:
              <Text
                className="ml"
                strong>
                {nonAllocatoYTD}
              </Text>
            </Text>
            <Text>
              {t('proggetiPage.consutivoYtd')}:
              <Text
                className="ml"
                strong>
                {consutivoYTD}
              </Text>
            </Text>
          </div>

          <>
            <h2 className="tw-text-lg tw-my-4">{t('proggetiPage.workloadTotale')}</h2>
            <EditableTable
              userData={userData}
              t={t}
              hasUserAccess={hasUserAccess}
              propsData={totalProjectWorkloadData}
              updateRow={this.updateProjectWorkload}
              loadingIcon={loadingButton}
              isTotal={true}
              loadingTotal={loadingTotal}
            />

            <h2 className="tw-text-lg tw-my-4">{t('proggetiPage.caricoUtente')}</h2>
            <EditableTable
              userData={userData}
              t={t}
              hasUserAccess={hasUserAccess}
              propsData={projectWorkloadData}
              updateRow={this.updateProjectWorkload}
              loadingIcon={loadingButton}
              isTotal={false}
              loadingData={loadingData}
            />
          </>
        </TableLayout.Content>
      </TableLayout>
    );
  }
}

export default withTranslation()(ProjectResourceWorkload);
