import { Form } from 'antd';
import { useEffect, useState } from 'react';

import {
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
  SearchOutlined,
  StarFilled,
  StarOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tabs,
  Tooltip,
  Typography,
} from 'antd';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import CommonService from '../../../services/commonService';
import CategoryService from '../../../services/pages/categoryService';
import ObiettiviLongService from '../../../services/pages/obiettiviLongService';
import UserSelectDropDown from '../../shared/components/userSelect/userSelect';
import XmatrixDropDownFilter from '../../shared/components/xmatrixDropdownFilter/xmatrixDropdownFilter';
import { formItemLayout, longTermGoalStatus, statusPickListCodes } from '../../shared/utils/constants';
import { notifyMessages, requiredFields } from '../../shared/utils/notifyMessages';

const { Text } = Typography;
const { Option } = Select;

const Impostazioni = ({
  longTermData,
  longTermId,
  isRoleTeamMemberOrTeamLeader,
  reloadData,
  activeXmatrixInfo,
  onXmatrixSelect,
  loadingData,
  routeSelectedXmatrix,
}) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const history = useHistory();
  const { t } = useTranslation();

  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [categoriesList, setCategoriesList] = useState(null);
  const [goalTypesList, setGoalTypesList] = useState(null);
  const [statusList, setStatusList] = useState(null);
  const [relatedAnnualGoals, setRelatedAnnualGoals] = useState(null);

  const [anualGoalsCount, setAnualGoalsCount] = useState(0);
  const [projectsCount, setProjectsCount] = useState(0);
  const [kpiCount, setKpiCount] = useState(0);

  useEffect(() => {
    retrieveCategories();
    retrieveTypes();
    retrieveStatus();
  }, []);

  useEffect(() => {
    if (longTermData) {
      let relatedObjectives = longTermData.annualGoalList.filter(function (el) {
        return el.correlationCoefficient === 1 || el.correlationCoefficient === 2;
      });
      setRelatedAnnualGoals(relatedObjectives);

      setAnualGoalsCount(relatedObjectives?.length);
      setProjectsCount(longTermData.kpiList?.length);
      setKpiCount(longTermData.projectList?.length);
    }
  }, [longTermData]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, dataIndex, confirm)}
            size="small"
            style={{
              width: 90,
            }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    clearFilters();
    setSearchText('');
    handleSearch('', confirm, dataIndex);
  };

  const retrieveCategories = async () => {
    await CategoryService.getCategoryList()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          setCategoriesList(respData);
        } else {
        }
      })
      .catch((error) => {});
  };

  const retrieveTypes = async () => {
    await ObiettiviLongService.getGoalTypes()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          setGoalTypesList(respData);
        } else {
        }
      })
      .catch((error) => {});
  };

  const retrieveStatus = async () => {
    const objectCode = statusPickListCodes.statusObAnnoLong;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          setStatusList(respData);
        } else {
        }
      })
      .catch((error) => {});
  };

  const removeLongTermObj = (longTermGoalID) => {
    ObiettiviLongService.deleteLongTermObjective(longTermGoalID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.deleteSuccess);
          history.push('/obiettivialungoperiodo');
        } else {
          message.error(notifyMessages.deleteFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.deleteFailed);
      });
  };

  const updateData = (values) => {
    // let editorContentToHtml = BraftEditor.createEditorState(values["notes"]).toHTML();
    // values["notes"] = editorContentToHtml;
    values['longTermGoalID'] = longTermId;

    setLoadingSave(true);
    ObiettiviLongService.updateLongTerm(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          setLoadingSave(false);
          reloadData();
        } else {
          message.error(notifyMessages.updateFailed);
          setLoadingSave(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        setLoadingSave(false);
      });
  };

  let categories = [];

  if (categoriesList) {
    categoriesList.forEach((item) => {
      const value = item.goalCategoryID;
      categories.push({
        value,
        key: item.goalCategoryID,
        label: item.name,
      });
    });
  } else {
    categories = [];
  }

  const annualGoalColumns = [
    {
      title: `${t('general.nome')}`,
      width: '60%',
      dataIndex: 'description',
      sorter: (a, b) => {
        return a.description.localeCompare(b.description);
      },
      showSorterTooltip: [false],
      ...getColumnSearchProps('description'),
      ellipsis: {
        showTitle: false,
      },
      render: (title) => <Tooltip title={title}>{title}</Tooltip>,
    },
    {
      title: `${t('general.responsabile')}`,
      dataIndex: 'ownerName',
      showSorterTooltip: [false],
      ...getColumnSearchProps('ownerName'),
    },
    {
      title: `${t('general.relazione')}`,
      align: 'center',
      width: '10%',
      dataIndex: 'relationship',
      render: (text, record) => {
        if (record.correlationCoefficient === 1) {
          return (
            <Tooltip title={record.correlationDescription}>
              <div className="tw-w-full tw-flex tw-flex-row tw-justify-center">
                <span className="relationFull margin-center"></span>
              </div>
            </Tooltip>
          );
        } else if (record.correlationCoefficient === 2) {
          return (
            <Tooltip title={record.correlationDescription}>
              <div className="tw-w-full tw-flex tw-flex-row tw-justify-center">
                <span className="relationPartial margin-center"></span>
              </div>
            </Tooltip>
          );
        } else {
        }
      },
    },
    {
      key: 'action',
      width: '50px',
      render: (text, record) => (
        <Space>
          <Link
            to={{
              pathname: `/obiettiviannuali/details/id/${record.annualGoalID}`,
            }}>
            <EditOutlined />
          </Link>
        </Space>
      ),
    },
  ];

  const progettiColumns = [
    {
      title: `${t('general.nome')}`,
      dataIndex: 'name',
      width: '80%',
      sorter: (a, b) => {
        return a.name.localeCompare(b.name);
      },
      showSorterTooltip: [false],
      ...getColumnSearchProps('name'),
      ellipsis: {
        showTitle: false,
      },
      render: (title) => <Tooltip title={title}>{title}</Tooltip>,
    },
    {
      title: `${t('proggetiPage.codice')}`,
      dataIndex: 'projectCode',
      sorter: (a, b) => {
        return a.projectCode.localeCompare(b.projectCode);
      },
      showSorterTooltip: [false],
      ...getColumnSearchProps('projectCode'),
    },
    {
      key: 'action',
      width: '50px',
      render: (text, record) => (
        <Space>
          <Link
            to={{
              pathname: `/progetti/id/${record.projectID}`,
            }}>
            <EditOutlined />
          </Link>
        </Space>
      ),
    },
  ];

  const kpiColumns = [
    {
      dataIndex: 'isFavourite',
      width: '40px',
      showSorterTooltip: [false],
      render: (text, record, index) => {
        if (record.isFavourite) {
          return (
            <StarFilled
              style={{ color: '#f5a645' }}
              title={t('kpiPage.preferita')}
            />
          );
        } else {
          return <StarOutlined title={t('kpiPage.preferita')} />;
        }
      },
    },
    {
      title: `${t('general.nome')}`,
      dataIndex: 'name',
      width: '40%',
      sorter: (a, b) => {
        return a.name.localeCompare(b.name);
      },
      showSorterTooltip: [false],
      ...getColumnSearchProps('name'),
      ellipsis: {
        showTitle: false,
      },
      render: (title) => <Tooltip title={title}>{title}</Tooltip>,
    },
    {
      title: `${t('general.jumpOffPoint')}`,
      dataIndex: 'jumpOffPoint',
      showSorterTooltip: [false],
      sorter: (a, b) => a.jumpOffPoint - b.jumpOffPoint,
    },
    {
      title: `${t('general.target')}`,
      dataIndex: 'target',
      showSorterTooltip: [false],
      sorter: (a, b) => a.target - b.target,
    },
    {
      title: `${t('general.actualYtd')}`,
      dataIndex: 'actualYTD',
      showSorterTooltip: [false],
      sorter: (a, b) => a.actualYTD - b.actualYTD,
    },
    {
      title: `${t('general.relazione')}`,
      align: 'center',
      dataIndex: 'relationship',
      render: (text, record) => {
        if (record.correlationCoefficient === 1) {
          return (
            <Tooltip title={record.correlationDescription}>
              <div className="tw-w-full tw-flex tw-flex-row tw-justify-center">
                <span className="relationFull"></span>
              </div>
            </Tooltip>
          );
        } else if (record.correlationCoefficient === 2) {
          return (
            <Tooltip title={record.correlationDescription}>
              <div className="tw-w-full tw-flex tw-flex-row tw-justify-center">
                <span className="relationPartial"></span>
              </div>
            </Tooltip>
          );
        } else {
        }
      },
    },
    {
      key: 'action',
      width: '50px',
      render: (text, record) => (
        <Button
          icon={<EditOutlined />}
          href={`/kpi/id/${record.kpiDetailID}`}></Button>
      ),
    },
  ];

  return (
    <div className="tw-mt-4">
      <Form
        name="company_Details"
        {...formItemLayout}
        onFinish={(values) => updateData(values, longTermData.longTermGoalID)}
        onValuesChange={() => {
          setFieldsChanged(true);
        }}>
        <Card
          styles={{ header: { padding: 0 }, body: { padding: 0 } }}
          bordered={false}
          extra={
            <div className="tw-flex tw-items-center tw-gap-2">
              {longTermData.status === longTermGoalStatus.draft &&
              anualGoalsCount === 0 &&
              kpiCount === 0 &&
              projectsCount === 0 ? (
                <Form.Item wrapperCol={{ span: 24 }}>
                  <Popconfirm
                    title={t('obiettiviLungoPage.eleminareObjLungo')}
                    onConfirm={() => removeLongTermObj(longTermData.longTermGoalID)}
                    okText={t('general.si')}
                    cancelText={t('general.no')}
                    className="long-term-button remove">
                    <Button
                      icon={<DeleteOutlined />}
                      type="dashed">
                      {t('buttons.elimina')}
                    </Button>
                  </Popconfirm>
                </Form.Item>
              ) : (
                <Form.Item className="tw-mb-0">
                  <Button
                    danger
                    type="dashed"
                    disabled={true}
                    onClick={() => removeLongTermObj(longTermData.longTermGoalID)}>
                    <DeleteOutlined /> {t('buttons.elimina')}
                  </Button>
                </Form.Item>
              )}
              <Form.Item className="tw-mb-0">
                <Button
                  loading={loadingSave}
                  type="primary"
                  icon={<SaveOutlined />}
                  htmlType="submit"
                  disabled={!fieldsChanged}>
                  {t('buttons.salva')}
                </Button>
              </Form.Item>
            </div>
          }
          title={t('general.informazioniGenerali')}>
          {longTermData.xMatrixName && (
            <div className="tw-mt-4">
              <Text>{t('general.xmatrix')}: </Text>
              <Text strong>{longTermData.xMatrixName}</Text>
            </div>
          )}

          <Row>
            <Col>
              <Form.Item
                className="main-container"
                label={t('general.nome')}
                name="title"
                rules={[{ required: true, message: requiredFields.required }]}
                initialValue={longTermData.title}
                hidden={true}>
                <Input disabled={isRoleTeamMemberOrTeamLeader} />
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}>
              <Form.Item
                className="main-container"
                label={t('general.stato')}
                name="status"
                hasFeedback
                rules={[{ required: true, message: requiredFields.required }]}
                initialValue={longTermData.status}>
                <Select
                  disabled={isRoleTeamMemberOrTeamLeader}
                  style={{ width: '100%' }}
                  placeholder={t('general.seleziona')}>
                  {statusList &&
                    statusList.map((item) => (
                      <Option
                        value={item.statusID}
                        key={item.statusID}>
                        {item.statusDescription}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}>
              <Form.Item
                className="main-container"
                label={t('obiettiviLungoPage.categoria')}
                name="goalCategories"
                hasFeedback
                rules={[{ required: true, message: requiredFields.required }]}
                initialValue={longTermData.goalCategories ? longTermData.goalCategories : []}>
                <Select
                  disabled={isRoleTeamMemberOrTeamLeader}
                  options={categories}
                  allowClear
                  showSearch={false}
                  maxTagCount="responsive"
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder={t('general.seleziona')}></Select>
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}>
              <Form.Item
                className="main-container"
                label={t('general.tipo')}
                name="goalTypeID"
                hasFeedback
                initialValue={longTermData.goalTypeID}>
                <Select
                  disabled={isRoleTeamMemberOrTeamLeader}
                  style={{ width: '100%' }}
                  placeholder={t('general.seleziona')}>
                  {goalTypesList &&
                    goalTypesList.map((item) => (
                      <Option
                        value={item.goalTypeID}
                        key={item.goalTypeID}>
                        {item.description}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}>
              <UserSelectDropDown
                disabled={isRoleTeamMemberOrTeamLeader}
                allowClear={false}
                fieldLabel={t('general.sponsor')}
                t={t}
                formName={'sponsorID'}
                required={false}
                initValue={longTermData.sponsorID}
              />

              {/* <Form.Item className="main-container"
                                        label={t('general.sponsor')}
                                        name="sponsorID"
                                        hasFeedback
                                        initialValue={longTermData.sponsorID}
                                    >
                                        <Select
                                            disabled={isRoleTeamMemberOrTeamLeader}
                                            style={{ width: "100%" }}
                                            placeholder={t('general.seleziona')}
                                        >
                                            {usersPickList && usersPickList.map(item =>
                                                <Option value={item.userID} key={item.userID}>{item.fullName}</Option>
                                            )}
                                        </Select>
                                    </Form.Item> */}
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 6 }}>
              <Form.Item
                className="main-container"
                label={t('obiettiviLungoPage.coerenzaEnvisioning')}
                name="isRelatedVMV"
                valuePropName="checked"
                initialValue={longTermData.isRelatedVMV}>
                <Switch disabled={isRoleTeamMemberOrTeamLeader} />
              </Form.Item>
            </Col>
          </Row>
        </Card>

        <Card
          styles={{ header: { padding: 0 }, body: { padding: 0 } }}
          bordered={false}
          extra={
            <>
              {activeXmatrixInfo && (
                <XmatrixDropDownFilter
                  onXmatrixSelect={onXmatrixSelect}
                  activeXmatrixInfo={activeXmatrixInfo}
                  preSelectedXmatrixId={routeSelectedXmatrix}
                  t={t}
                />
              )}
            </>
          }>
          <Tabs
            defaultActiveKey="1"
            size="small"
            items={[
              {
                label: <>{t('headerMenu.obiettiviAnno')}</>,
                key: '1',
                children: (
                  <Table
                    className="table-height"
                    columns={annualGoalColumns}
                    dataSource={relatedAnnualGoals}
                    size="small"
                    rowKey={(obj) => obj.annualGoalID}
                    loading={loadingData}
                    pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
                  />
                ),
              },
              {
                label: <>{t('headerMenu.progetti')}</>,
                key: '2',
                children: (
                  <Table
                    columns={progettiColumns}
                    size="small"
                    dataSource={longTermData.projectList}
                    rowKey={(obj) => obj.projectID}
                    loading={loadingData}
                    pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
                  />
                ),
              },
              {
                label: <>{t('headerMenu.kpi')}</>,
                key: '3',
                children: (
                  <Table
                    className="table-height"
                    columns={kpiColumns}
                    size="small"
                    dataSource={longTermData.kpiList}
                    rowKey={(obj) => obj.kpiid}
                    loading={loadingData}
                    pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
                  />
                ),
              },
            ]}></Tabs>
        </Card>
      </Form>
    </div>
  );
};
export default Impostazioni;
