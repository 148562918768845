import {
  CheckOutlined,
  DeleteFilled,
  EditFilled,
  ExportOutlined,
  FilterOutlined,
  MinusSquareOutlined,
  OrderedListOutlined,
  PlusOutlined,
  SearchOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Button, DatePicker, Input, Popconfirm, Popover, Space, Table, Tooltip, Typography, message } from 'antd';
import moment from 'moment';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CommonService from '../../services/commonService';
import ActivityService from '../../services/pages/activitiesServices';
import TeamLeaderActivities from '../myActivities/teamLeaderActivities';
import '../projects/project.scss';
import TableLayout from '../shared/tableLayout';
import { activityStatus, formatOfDate, statusPickListCodes } from '../shared/utils/constants';
import {
  ResponsiblePopoverContent,
  checkMyActivitiesStatus,
  projectTypeColumnValues,
  updateNotificationBell,
  validateGuid,
} from '../shared/utils/functions';
import { notifyMessages } from '../shared/utils/notifyMessages';
import NewActivity from './newActivity';
import UpdateActivity from './updateActivity';

const { Text } = Typography;

class MyTodo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
      loadingActivities: false,
      myActivityListFilteredFiltered: null,
      showEditModal: false,
      showCreateModal: false,
      modalData: null,
      startDate: null,
      endDate: null,
      clearFilters: false,
      showMyProjectsToDo: false,
      statusListFilter: [],
      filteredInfo: ['Attivo'],
    };
  }

  componentDidMount() {
    this.retrieveStatusList().then(() => {
      this.retrieveMyActivityListFiltered();
    });
  }

  async retrieveStatusList() {
    const objectCode = statusPickListCodes.activityStatus;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let obj = [];
          resp.responseObject.value.map((item) => {
            obj.push({ text: item.statusDescription, value: item.statusDescription });
          });
          this.setState({ statusListFilter: obj });
        } else {
        }
      })
      .catch((error) => {});
  }

  componentWillUnmount() {
    this.setState({ loadingActivities: false });
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}>
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)}>Reset</Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    this.setState({ searchText: '' });
  };

  toggleEditAtivityModal = (rowData) => {
    // this.setState({ showEditModal: true });
    // this.setState({ modalData: rowData });
    this.setState({ modalData: rowData }, () => {
      this.setState({ showEditModal: true });
    });
  };

  handleEditModalClose = () => {
    this.setState({ showEditModal: false });
    this.setState({ loadingButton: false });
  };

  handleUpdateActivity = (values, activityID, deliverableID, projectId) => {
    values['activityID'] = activityID;
    values['deliverableID'] = deliverableID;
    //values["projectId"] = projectId;
    values.startDate = this.handleDateChange(values.startDate);
    values.endDate = this.handleDateChange(values.endDate);

    this.setState({ loadingButton: true });
    ActivityService.updateActivity(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          updateNotificationBell();
          this.handleEditModalClose();
          message.success(notifyMessages.updateSuccess);
          this.retrieveMyActivityListFiltered(true);
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  handleCloseActivity = (record) => {
    let values = {};

    values['activityID'] = record.activityID;
    values['deliverableID'] = record.deliverableID;
    values['description'] = record.description;
    values['endDate'] = this.handleDateChange(record.endDate);
    values['ownerID'] = record.ownerID;
    values['projectID'] = record.projectID;
    values['startDate'] = this.handleDateChange(record.startDate);
    values['status'] = activityStatus.closed;
    values['subject'] = record.subject;

    this.setState({ loadingButton: true });
    ActivityService.updateActivity(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          //this.handleEditModalClose();
          this.setState({ loadingButton: false });
          message.success(notifyMessages.updateSuccess);
          this.retrieveMyActivityListFiltered(true);
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  toggleCreateAtivityModal = () => {
    this.setState({ showCreateModal: true });
  };

  handleCreateModalClose = () => {
    this.setState({ showCreateModal: false });
    this.setState({ loadingButton: false });
  };

  handleSaveActivity = (values) => {
    values.startDate = this.handleDateChange(values.startDate);
    values.endDate = this.handleDateChange(values.endDate);

    this.setState({ loadingButton: true });
    ActivityService.insertActivity(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          updateNotificationBell();
          this.handleCreateModalClose();
          message.success(notifyMessages.addSuccess);
          this.retrieveMyActivityListFiltered(true);
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingButton: false });
      });
  };

  async retrieveMyActivityListFiltered(hideActivityPopUp = false) {
    const { acid } = this.props;
    const { startDate, endDate, clearFilters } = this.state;

    try {
      // Fetch the list of activity statuses
      const objectCode = statusPickListCodes.activityStatus;
      const statusListResponse = await CommonService.getStatusData(objectCode);
      if (statusListResponse.data.success) {
        // Map the statuses to a filterable format for the table
        const statusList = statusListResponse.data.responseObject.value.map((item) => ({
          text: item.statusDescription,
          value: item.statusDescription,
        }));
        this.setState({ statusListFilter: statusList });
      }

      // Format start and end dates if provided
      let formatedStartDate = this.handleDateChange(startDate);
      let formatedEndDate = this.handleDateChange(endDate);

      // If the user requested to clear filters, reset the dates
      if (clearFilters) {
        this.setState({ startDate: null, endDate: null });
        formatedStartDate = null;
        formatedEndDate = null;
      }

      // Set loading state to true while fetching activities
      this.setState({ loadingActivities: true });

      // Call the API to retrieve the activity list without applying default filters
      const activitiesResponse = await ActivityService.getMyActivities(formatedStartDate, formatedEndDate);
      if (activitiesResponse.data.success) {
        const activityList = activitiesResponse.data.responseObject.value;

        // Store the retrieved data and display all activities without any filters initially
        this.setState({
          myActivityListFilteredFiltered: activityList,
          myActivityListFilteredFilteredFiltered: activityList, // Show everything initially
        });

        // If there is an `acid` and it is valid, open the edit modal for the corresponding activity
        if (acid && validateGuid(acid)) {
          const selectedActivity = activityList.find((obj) => obj.activityID === acid);
          if (selectedActivity && !hideActivityPopUp) {
            this.toggleEditAtivityModal(selectedActivity);
          }
        }
      } else {
        // Show an error message if fetching the activities failed
        message.error(notifyMessages.retrieveFailed);
      }
    } catch (error) {
      // Show an error message in case of an exception
      message.error(notifyMessages.retrieveFailed);
    } finally {
      // Always set loading to false after data fetching completes
      this.setState({ loadingActivities: false });
    }
  }

  handleDateChange(date) {
    if (date) {
      let formatedDate = moment(date).format('YYYY-MM-DD');
      return formatedDate;
    }
    return null;
  }

  onStartDateChange = (date) => {
    this.setState({ startDate: date });
  };

  onEndDateChange = (date) => {
    this.setState({ endDate: date });
  };

  onFilter = () => {
    this.setState({ clearFilters: false }, () => {
      this.retrieveMyActivityListFiltered(true);
    });
  };

  onClearFilter = () => {
    this.setState({ clearFilters: true }, () => {
      this.retrieveMyActivityListFiltered(true);
    });
  };

  onRemoveActivity = (id) => {
    ActivityService.removeActivity(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.deleteSuccess);
          this.retrieveMyActivityListFiltered(true);
        } else {
          message.error(notifyMessages.deleteFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.deleteFailed);
      });
  };

  dateFilters = () => {
    const { startDate, endDate } = this.state;
    let { t } = this.props;
    return (
      <div className="tw-flex tw-gap-2 tw-items-center">
        <DatePicker
          placeholder={t('general.start')}
          value={startDate}
          format="DD/MM/YYYY"
          onChange={(date) => this.onStartDateChange(date)}
        />
        <DatePicker
          placeholder={t('general.end')}
          value={endDate}
          format="DD/MM/YYYY"
          onChange={(date) => this.onEndDateChange(date)}
        />
        <Tooltip title={t('general.filter')}>
          <Button
            icon={<FilterOutlined />}
            onClick={this.onFilter}
            disabled={!startDate && !endDate}
          />
        </Tooltip>
        <Tooltip title={t('general.clearFilters')}>
          <Button
            icon={<MinusSquareOutlined />}
            onClick={() => this.onClearFilter()}
          />
        </Tooltip>
      </div>
    );
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters.statusDescription || null,
    });
  };

  filterActivities = (type) => {
    const { myActivityListFilteredFiltered } = this.state;
    if (myActivityListFilteredFiltered) {
      if (type === 'activities') {
        this.setState({
          myActivityListFilteredFilteredFiltered: myActivityListFilteredFiltered.filter(
            (obj) => obj.activityType === 'activity',
          ),
        });
      } else if (type === 'prs') {
        this.setState({
          myActivityListFilteredFilteredFiltered: myActivityListFilteredFiltered.filter(
            (obj) => obj.activityType === 'deliverable' || obj.activityType === 'macroActivity',
          ),
        });
      } else {
        this.setState({ myActivityListFilteredFilteredFiltered: myActivityListFilteredFiltered });
      }
    }
  };

  render() {
    const {
      myActivityListFilteredFiltered,
      loadingActivities,
      loadingButton,
      showCreateModal,
      showEditModal,
      modalData,
      statusListFilter,
      myActivityListFilteredFilteredFiltered,
      showMyProjectsToDo,
    } = this.state;
    let { t } = this.props;

    const ActivityName = ({ record, title }) => {
      return (
        <Text
          style={{ cursor: 'pointer' }}
          onClick={() => this.toggleEditAtivityModal(record)}>
          {title}
        </Text>
      );
    };

    const myActivitiesColumns = [
      {
        title: `${t('general.tipo')}`,
        dataIndex: 'activityType',
        width: '10%',
        showSorterTooltip: [false],
        onFilter: (value, record) => (record.activityType ? record.activityType.includes(value) : ''),
        render(title, record) {
          return {
            props: {
              style: {
                borderLeft: '7px solid' + checkMyActivitiesStatus(record),
                borderRadius: '5px 0 0 5px',
              },
            },
            // children: <TypeColumnValues recordData={record} t={t} />
            children: <Text>{projectTypeColumnValues(record, t)}</Text>,
          };
        },
      },

      {
        title: `${t('general.descrizione')}`,
        dataIndex: 'subject',
        overflowWrap: 'break-word',
        sorter: (a, b) => {
          a = a.subject || '';
          b = b.subject || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('subject'),
        render(title, record) {
          return (
            <ActivityName
              title={title}
              record={record}
            />
          );
        },
        // render(title, record) {
        //     return {
        //         props: {
        //             style: {
        //                 borderLeft: '7px solid' + checkMyActivitiesStatus(record),
        //                 borderRadius: '5px 0 0 5px'
        //             }
        //         },
        //         children: <ActivityName title={title} record={record} />
        //     };
        // }
      },
      {
        title: `${t('general.responsabile')}`,
        dataIndex: 'fullName',
        width: '10%',
        sorter: (a, b) => {
          a = a.fullName || '';
          b = b.fullName || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        render: (title, record) => (
          <Popover
            content={
              <ResponsiblePopoverContent
                data={record}
                t={t}
              />
            }
            title={title}>
            <span style={record.isGenericResponsabile == true ? { color: '#f2883b' } : {}}>{title}</span>
          </Popover>
        ),
      },
      {
        title: `${t('general.stato')}`,
        dataIndex: 'statusDescription',
        width: '14ch',
        key: 'statusDescription',
        sorter: (a, b) => {
          a = a.statusDescription || '';
          b = b.statusDescription || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [true],
        filters: statusListFilter,
        defaultFilteredValue: this.state.filteredInfo || null,
        onFilter: (value, record) => (record.statusDescription ? record.statusDescription.includes(value) : ''),
        render(text, record) {
          return {
            children: <div>{text}</div>,
          };
        },
      },
      // {
      //     title: `${t('general.deliverable')}`,
      //     dataIndex: 'deliverableName',
      //     sorter: (a, b) => {
      //       a = a.deliverableName || '';
      //       b = b.deliverableName || '';
      //       return a.localeCompare(b)
      //     },
      //     showSorterTooltip: [false],
      //     ellipsis: {
      //       showTitle: false,
      //     },
      //     render: (text, record) => (
      //         <Tooltip  title={text}>
      //             <Link to={{ pathname: `/deliverables/id/${record.deliverableID}` }}>{text}</Link>
      //         </Tooltip>
      //       )
      // },
      {
        title: `${t('general.progetto')}`,
        dataIndex: 'projectName',
        sorter: (a, b) => {
          a = a.projectName || '';
          b = b.projectName || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => (
          <Tooltip title={text}>
            <Link to={{ pathname: `/progetti/id/${record.projectID}` }}> {text} </Link>
          </Tooltip>
        ),
      },
      {
        title: `${t('general.start')}`,
        dataIndex: 'startDate',
        width: '150px',
        sorter: (a, b) => {
          a = a.startDate || '';
          b = b.startDate || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        render: (text, record) => moment(text).format(formatOfDate),
      },
      {
        title: `${t('general.end')}`,
        dataIndex: 'endDate',
        width: '150px',
        sorter: (a, b) => {
          a = a.endDate || '';
          b = b.endDate || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        render: (text, record) => moment(text).format(formatOfDate),
      },

      {
        title: `${t('general.dataChiusura')}`,
        dataIndex: 'actualCloseDate',
        width: '150px',
        sorter: (a, b) => {
          a = a.actualCloseDate || '';
          b = b.actualCloseDate || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        render: (text) => (text ? moment(text).format(formatOfDate) : '-'),
      },
      {
        key: 'action',
        width: '50px',
        render: (_, record) => {
          if (record.deliverableID) {
            return (
              <Button
                icon={<ExportOutlined />}
                href={`/deliverables/id/${record.deliverableID}`}></Button>
            );
          } else {
            return (
              <Button
                icon={<EditFilled />}
                onClick={() => this.toggleEditAtivityModal(record)}
                title={t('buttons.modificaAttivita')}
              />
            );
          }
        },
      },
      {
        key: 'action',
        width: '50px',
        render: (_, record) => {
          let isClosed = record.status === activityStatus.closed ? true : false;
          if (isClosed) {
            return;
          } else {
            return (
              <Popconfirm
                key="remuve"
                title={t('buttons.rimuoveAttivita')}
                icon={<WarningOutlined />}
                okText={t('general.si')}
                cancelText={t('general.no')}
                onConfirm={() => this.onRemoveActivity(record.activityID)}>
                <Button
                  danger
                  title={t('buttons.rimuoveAttivita')}
                  icon={<DeleteFilled />}></Button>
              </Popconfirm>
            );
          }
        },
      },
      {
        key: 'action',
        width: '50px',
        render: (_, record) => {
          let quickClose =
            record.status === activityStatus.active || record.status === activityStatus.draft ? true : false;
          if (!quickClose) {
            return;
          } else {
            return (
              <Popconfirm
                key="close"
                placement="topRight"
                title={t('leMieAttivitaPage.chiudiQuestaAttivita')}
                icon={<WarningOutlined />}
                okText={t('general.si')}
                cancelText={t('general.no')}
                onConfirm={() => this.handleCloseActivity(record)}>
                <Button
                  icon={<CheckOutlined />}
                  title={t('buttons.chiusuraRapida')}
                />
              </Popconfirm>
            );
          }
        },
      },
    ];

    return (
      <>
        {showMyProjectsToDo ? (
          <TeamLeaderActivities
            t={t}
            acid={this.props.acid}
            changeVisualizationButton={
              <Button onClick={() => this.setState({ showMyProjectsToDo: false })}>
                <OrderedListOutlined />
                {t('buttons.goToMyToDo')}
              </Button>
            }
          />
        ) : (
          <>
            {showCreateModal && (
              <NewActivity
                showCreateModal={showCreateModal}
                handleCreateModalClose={this.handleCreateModalClose}
                handleSaveActivity={this.handleSaveActivity}
                loadingButton={loadingButton}
                t={t}
              />
            )}
            {showEditModal && (
              <UpdateActivity
                showEditModal={showEditModal}
                handleEditModalClose={this.handleEditModalClose}
                handleUpdateActivity={this.handleUpdateActivity}
                loadingButton={loadingButton}
                modalData={modalData}
                t={t}
              />
            )}
            <TableLayout title={'ToDo'}>
              <TableLayout.Actions>
                {this.dateFilters()}

                <Space direction="horizontal">
                  <Button
                    type="dashed"
                    onClick={() => this.filterActivities('activities')}>
                    {t('proggetiPage.attivitaFilter')}
                  </Button>
                  <Button
                    type="dashed"
                    onClick={() => this.filterActivities('prs')}>
                    {t('proggetiPage.prsFilter')}
                  </Button>
                  <Button
                    type="dashed"
                    onClick={() => this.filterActivities('all')}>
                    {t('proggetiPage.allFilter')}
                  </Button>
                </Space>

                <Button
                  icon={<OrderedListOutlined />}
                  onClick={() => this.setState({ showMyProjectsToDo: true })}>
                  {t('buttons.goToMyProjectsToDo')}
                </Button>
                <Button
                  type="primary"
                  onClick={() => this.toggleCreateAtivityModal()}>
                  <PlusOutlined />
                  {t('buttons.aggiungiNuovo')}
                </Button>
              </TableLayout.Actions>
              <TableLayout.Content>
                <Table
                  size="small"
                  columns={myActivitiesColumns}
                  dataSource={myActivityListFilteredFilteredFiltered}
                  rowKey={(obj) => obj.activityID}
                  loading={loadingActivities}
                  onChange={this.handleChange}
                  pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
                />
              </TableLayout.Content>
            </TableLayout>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(MyTodo));
