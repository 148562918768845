import { EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, message } from 'antd';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { ColumnType } from 'antd/lib/table';
import type { DataIndex } from 'rc-table/lib/interface';
import { FC, Key, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DivisionDto } from 'src/connectors/backend';
import { history } from '../../App';
import DivisionService from '../../services/pages/divisionServices';
import TableLayout from '../shared/tableLayout';
import { notifyMessages } from '../shared/utils/notifyMessages';
import './divisions.scss';

const DivisionsListPage: FC = () => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState<Key | undefined>();
  const [data, setData] = useState<DivisionDto[] | undefined>();

  useEffect(() => {
    retrieveDivisionListData();
  }, []);

  const getColumnSearchProps = (
    dataIndex: DataIndex<DivisionDto>,
  ): Pick<ColumnType<DivisionDto>, 'filterDropdown' | 'filterIcon' | 'onFilter' | 'render'> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),

    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />
    ),

    onFilter: (value: React.Key | boolean, record: DivisionDto) => {
      const path = typeof dataIndex === 'string' ? [dataIndex] : (dataIndex as string[]);
      let target: any = record;
      for (let elem of path) {
        if (!target || !target[elem]) {
          return false;
        }

        target = target[elem];
      }

      return target?.toLowerCase().includes(value.toString().toLowerCase()) || false;
    },
  });

  const handleSearch = ([selectedKeys]: Key[], confirm: FilterDropdownProps['confirm']) => {
    confirm();
    setSearchText(selectedKeys);
  };

  const handleReset = (clearFilters: (() => void) | undefined, confirm: FilterDropdownProps['confirm']) => {
    clearFilters && clearFilters();
    handleSearch([], confirm);
  };

  const retrieveDivisionListData = async () => {
    try {
      const { data } = await DivisionService.list();
      setData(data);
    } catch {
      message.error(notifyMessages.retrieveFailed);
    }
  };

  const onNew = () => {
    history.push('/divisions/new');
  };

  const columns: ColumnType<DivisionDto>[] = [
    {
      title: `${t('general.nome')}`,
      dataIndex: 'name',
      ellipsis: {
        showTitle: true,
      },
      width: '45%',
      sorter: (a: DivisionDto, b: DivisionDto) => {
        var x = a.name || '';
        var y = b.name || '';

        return x.localeCompare(y);
      },
      showSorterTooltip: false,
      ...getColumnSearchProps('name'),
    },
    {
      title: `${t('general.responsabile')}`,
      dataIndex: ['owner', 'fullName'],
      width: '45%',
      sorter: (a: DivisionDto, b: DivisionDto) => {
        var x = a.owner?.fullName || '';
        var y = b.owner?.fullName || '';
        return x.localeCompare(y);
      },
      showSorterTooltip: false,
      ...getColumnSearchProps(['owner', 'fullName']),
    },

    {
      key: 'action',
      // width: "30px",
      width: '10%',
      render: (
        _: any,
        record: DivisionDto, //TODO
      ) => (
        <Button
          icon={<EditOutlined />}
          href={`/division/id/${record.id}`}></Button>
      ),
    },
  ];

  return (
    <TableLayout title={t('divisioniPage.divisioni')}>
      <TableLayout.Actions>
        <Button
          type="primary"
          htmlType="submit"
          onClick={onNew}>
          <PlusOutlined />
          {t('buttons.aggiungiNuovo')}
        </Button>
      </TableLayout.Actions>
      <TableLayout.Content>
        <Table
          className="table-height"
          size="small"
          columns={columns}
          dataSource={data}
          rowKey={(obj) => obj.id!}
          pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
        />
      </TableLayout.Content>
    </TableLayout>
  );
};

export default DivisionsListPage;
