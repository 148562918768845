import {
  CommentOutlined,
  EditOutlined,
  FilterOutlined,
  LeftCircleOutlined,
  MinusSquareOutlined,
  RightCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Avatar, Button, DatePicker, Divider, Input, Select, Space, Table, Tooltip, message } from 'antd';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import MatrixService from '../../../services/matrix/matrixService';
import KpiService from '../../../services/pages/kpiService';
import { addQueryParam, clearQueryParams, getQueryParam } from '../../../utils/url-utils';
import TableLayout from '../../shared/tableLayout';
import { emptyGuid, kpiTypeRealtion } from '../../shared/utils/constants';
import { italianNumberFormat, kpiSemaphoreColor } from '../../shared/utils/functions';
import { notifyMessages } from '../../shared/utils/notifyMessages';
import EditKpiCommentModal from '../kpiDetailCommentModal';

const monthFormat = 'MMMM';
const { Option } = Select;

const KpiSemaphore = ({ kpi }) => {
  return (
    <div className="kpiSemaphore">
      {/* <Avatar className={kpiSemaphoreColor(kpi.semaforo)} /> */}
      <Avatar
        size={'small'}
        style={{ backgroundColor: kpiSemaphoreColor(kpi.semaforo) }}
      />
    </div>
  );
};

const labelRender = (props) => {
  const { label } = props;
  return (
    <Tooltip
      title={label}
      placement="top">
      <span className="tw-shrink tw-max-w-16 tw-truncate">{label?.replace('...', '')}</span>
    </Tooltip>
  );
};

class AnualGoalKpiSintesi extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      searchedColumn: '',
      kpiList: null,
      loadingData: false,
      selectedXmatrixValue: this.props.activeXmatrixInfo?.xMatrixID,
      selectedXmatrixInfo: this.props.activeXmatrixInfo,
      selectedMonth: moment(),
      selectedProject: [],
      selectedAnnualGoal: this.props.annualId && this.props.annualId !== '0' ? this.props.annualId : '',
      selectedYear: moment().format('YYYY'),
      annualGoalPicklist: null,
      annualGoalPicklistLength: 0,
      enableDetailsClick: false,
      showCommentModal: false,
      loadingSaveComment: false,
      selectedRowData: null,
      nextObject: -1,
      previousObject: -1,
      disableNextButton: false,
      disablePreviousButton: true,
      loadingDisabled: false,
    };
  }

  componentDidMount() {
    this.annualGoalPicklist();

    this.setState({
      selectedYear: getQueryParam('selectedYear')
        ? moment(getQueryParam('selectedYear')).format('YYYY')
        : moment().format('YYYY'),
      selectedMonth: getQueryParam('selectedMonth') || moment(),
    });

    this.onAnnualGoalChange(getQueryParam('selectedAnnualGoal'));

    setTimeout(() => {
      this.onFilter();
    }, 1000);
  }

  async annualGoalPicklist() {
    await MatrixService.getAnnualGoals()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;

          const sortedData = respData.sort(function (a, b) {
            return new Date(a.description) - new Date(b.description);
          });

          this.setState({ annualGoalPicklist: sortedData });
          this.setState({ annualGoalPicklistLength: respData.length });
        } else {
        }
      })
      .catch((error) => {});
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    this.setState({ searchText: '' });
  };

  async retrieveKpiList() {
    const { selectedMonth, selectedAnnualGoal, selectedXmatrixValue, selectedYear } = this.state;

    let currentYear = moment().format('YYYY');
    let formatedYear = selectedYear ? moment(selectedYear).format('YYYY') : currentYear;
    let formatedMonth = moment(selectedMonth, 'YYYY/MM/DD').month() + 1;

    // if (selectedAnnualGoal && selectedAnnualGoal.length > 0) {
    //   this.setState({ enableDetailsClick: true })
    // }
    // else {
    //   this.setState({ enableDetailsClick: false })
    // }

    this.setState({ loadingData: true });
    this.setState({ loadingDisabled: true });
    await KpiService.getKpiAnualGoalSintesi(formatedMonth, selectedAnnualGoal, selectedXmatrixValue, formatedYear)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          let parentData = respData.filter((item) => {
            return item.parentID === null;
          });
          this.setState({ kpiList: parentData });
          // this.setState({ kpiList: resp.responseObject.value });
          this.setState({ loadingData: false });
          this.setState({ loadingDisabled: false });
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingData: false });
          this.setState({ loadingDisabled: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingData: false });
        this.setState({ loadingDisabled: false });
      });
  }

  setSelectedXmatrix = (xMatrixId, selectedXmatrix) => {
    let selectedXmatrixYear = this.getSelectedXmatrixYear(selectedXmatrix);
    this.setState({ selectedXmatrixValue: xMatrixId, selectedXmatrixInfo: selectedXmatrix }, () => {
      this.setState({ selectedMonth: moment(selectedXmatrixYear) });
    });
  };

  getSelectedXmatrixYear = (selectedXmatrix) => {
    const { xmatrixRootList } = this.props;
    let secondLevelXmatrixYear = null;

    if (selectedXmatrix.parentXMatrixID && selectedXmatrix.parentXMatrixID !== emptyGuid) {
      let parentXmatrix = xmatrixRootList.filter((obj) => obj.xmatrixID === selectedXmatrix.parentXMatrixID)[0];
      secondLevelXmatrixYear = parentXmatrix.xMatrixYear;
    } else {
      secondLevelXmatrixYear = selectedXmatrix.xMatrixYear;
    }
    return secondLevelXmatrixYear;
  };

  onMonthChange = (month) => {
    this.setState({ selectedMonth: month });
    addQueryParam('selectedMonth', month);
  };

  onAnnualGoalChange = (option) => {
    const { annualGoalPicklist, annualGoalPicklistLength } = this.state;

    if (!option) return;

    this.setState({ selectedAnnualGoal: option });
    addQueryParam('selectedAnnualGoal', option);

    let selectedOptionIndex = annualGoalPicklist?.findIndex(function (item) {
      return item.annualGoalId === option;
    });

    this.setState({ nextObject: selectedOptionIndex, previousObject: selectedOptionIndex - 1 });

    if (selectedOptionIndex > 0) {
      this.setState({ disablePreviousButton: false, disableNextButton: false });
    }

    if (selectedOptionIndex === 0) {
      this.setState({ disablePreviousButton: true, disableNextButton: false });
    }

    if (selectedOptionIndex === annualGoalPicklistLength - 1) {
      this.setState({ disableNextButton: true });
    }
  };

  onFilter = () => {
    this.retrieveKpiList();
  };

  onClearFilter = () => {
    const { activeXmatrixInfo } = this.props;
    this.setState({ disablePreviousButton: true });

    clearQueryParams();

    this.setState(
      {
        selectedMonth: moment(),
        selectedYear: moment().format('YYYY'),
        selectedXmatrixValue: activeXmatrixInfo?.xMatrixID,
        selectedAnnualGoal: [],
        disablePreviousButton: true,
        nextObject: -1,
        previousObject: -1,
      },
      () => {
        this.retrieveKpiList();
      },
    );
  };

  disableMonths = (current, selectedXmatrixInfo) => {
    let selectedXmatrixYear = this.getSelectedXmatrixYear(selectedXmatrixInfo);
    let curr = moment(current).year();
    let xmatrixYear = moment(selectedXmatrixYear).year();
    return curr < xmatrixYear || curr > xmatrixYear;
  };

  onYearChange = (year) => {
    this.setState({ selectedYear: year });
    addQueryParam('selectedYear', year);
  };

  handleKpiCommentClose = () => {
    this.setState({ showCommentModal: false });
    this.setState({ loadingSaveComment: false });
  };

  toggleKpiComments = (rowData) => {
    this.setState({ selectedRowData: rowData }, () => {
      this.setState({ showCommentModal: true });
    });
  };

  handleSaveComments = (values) => {
    const { selectedAnnualGoal } = this.state;
    values['ObjectTypeID'] = selectedAnnualGoal;
    values['Type'] = kpiTypeRealtion.annualGoal;

    this.setState({ loadingSaveComment: true });
    KpiService.onCommentSave(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.handleKpiCommentClose();
          message.success(notifyMessages.updateSuccess);
          this.retrieveKpiList();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingSaveComment: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingSaveComment: false });
      });
  };

  onNextObject = () => {
    const { annualGoalPicklist, selectedAnnualGoal, nextObject, annualGoalPicklistLength } = this.state;

    if (nextObject > -1) {
      let nextElement = annualGoalPicklist[nextObject + 1];
      this.setState({ previousObject: nextObject });
      this.setState({ nextObject: nextObject + 1 });
      this.setState({ disablePreviousButton: false });
      if (nextObject + 1 === annualGoalPicklistLength - 1) {
        this.setState({ disableNextButton: true });
      }
      this.setState({ selectedAnnualGoal: nextElement.annualGoalId }, () => {
        this.onFilter();
      });
    } else {
      let currentIndex = annualGoalPicklist?.findIndex(function (item) {
        return item.annualGoalId === selectedAnnualGoal;
      });

      if (currentIndex === -1) {
        let nextElement = annualGoalPicklist[0];
        this.setState({ nextObject: 0 });
        this.setState({ selectedAnnualGoal: nextElement.annualGoalId }, () => {
          this.onFilter();
        });
      }

      if (currentIndex !== -1) {
        let nextElement = annualGoalPicklist[currentIndex];
        this.setState({ nextObject: currentIndex });
        this.setState({ selectedAnnualGoal: nextElement.annualGoalId }, () => {
          this.onFilter();
        });
      }
    }
  };

  onPreviousObject = () => {
    const { annualGoalPicklist, previousObject } = this.state;

    if (previousObject > -1) {
      let nextElement = annualGoalPicklist[previousObject];
      this.setState({ previousObject: previousObject - 1 });
      this.setState({ nextObject: previousObject });
      this.setState({ disableNextButton: false });
      if (previousObject === 0) {
        this.setState({ disablePreviousButton: true });
      }
      this.setState({ selectedAnnualGoal: nextElement.annualGoalId }, () => {
        this.onFilter();
      });
    }
  };

  render() {
    const {
      kpiList,
      loadingData,
      selectedMonth,
      annualGoalPicklist,
      selectedProject,
      selectedXmatrixInfo,
      selectedXmatrixValue,
      selectedYear,
      selectedAnnualGoal,
      showCommentModal,
      loadingSaveComment,
      selectedRowData,
      disableNextButton,
      disablePreviousButton,
      loadingDisabled,
    } = this.state;
    const { t } = this.props;

    let formatedYear = moment(selectedYear).format('YYYY');
    let formatedMonth = moment(selectedMonth, 'YYYY/MM/DD').month() + 1;

    const kpiColumns = [
      {
        title: `${t('general.nome')}`,
        dataIndex: 'kpiName',
        key: 'kpiName',
        ellipsis: {
          showTitle: true,
        },
        align: 'left',
        width: '30%',
        ...this.getColumnSearchProps('kpiName'),
        render: (text, record) => {
          let kpiDetailsUrlPath = `/kpiDashboard/kdid/${record.kpiDetailID}/`;
          kpiDetailsUrlPath = kpiDetailsUrlPath.concat(selectedProject.length > 0 ? `${selectedProject}/` : '0/');
          kpiDetailsUrlPath = kpiDetailsUrlPath.concat(selectedAnnualGoal.length > 0 ? `${selectedAnnualGoal}/` : '0/');
          kpiDetailsUrlPath = kpiDetailsUrlPath.concat(selectedXmatrixValue ? `${selectedXmatrixValue}/` : '0/');
          kpiDetailsUrlPath = kpiDetailsUrlPath.concat(selectedYear ? `${formatedYear}/` : '0/');
          kpiDetailsUrlPath = kpiDetailsUrlPath.concat(selectedMonth ? `${formatedMonth}/` : '0/');
          kpiDetailsUrlPath = kpiDetailsUrlPath.concat('a/');

          return (
            <Space>
              <Link
                style={{ cursor: 'pointer' }}
                to={{ pathname: kpiDetailsUrlPath }}>
                {text}
              </Link>
            </Space>
          );

          // if (enableDetailsClick) {
          //   return (<Space ><Link style={{ cursor: "pointer" }} to={{ pathname: kpiDetailsUrlPath }}>{text}</Link></Space>)
          // }
          // else {
          //   return (<Text>{text}</Text>)
          // }
        },
      },

      {
        title: `${t('kpiPage.unitaMisura')}`,
        dataIndex: 'measureUnitName',
        key: 'measureUnitName',
        align: 'right',
        ellipsis: {
          showTitle: false,
        },
      },
      {
        title: `${t('general.targetYtd')}`,
        dataIndex: 'targetYTD',
        key: 'targetYTD',
        align: 'right',
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => <>{italianNumberFormat(record.targetYTD)}</>,
      },
      {
        title: `${t('general.actualYtd')}`,
        dataIndex: 'actualYTD',
        key: 'actualYTD',
        align: 'right',
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => <>{italianNumberFormat(record.actualYTD)}</>,
      },
      {
        title: `${t('kpiDashboard.semaphore')}`,
        dataIndex: 'semaforo',
        key: 'semaforo',
        align: 'center',
        ellipsis: {
          showTitle: false,
        },
        render: (title, record) => <KpiSemaphore kpi={record} />,
      },
      {
        title: `${t('general.commenti')}`,
        dataIndex: 'notes',
        key: 'notes',
        align: 'center',
        ellipsis: {
          showTitle: false,
        },
        render: (_, record) => {
          return (
            <Space direction="horizontal">
              <EditOutlined onClick={() => this.toggleKpiComments(record)} />
              {record.notes && record.notes !== '<p></p>' && (
                <>
                  <Divider type="vartical" />
                  <CommentOutlined style={{ color: '#6abf6a', marginLeft: '10px' }} />
                </>
              )}
            </Space>
          );

          // if (enableDetailsClick) {
          //   return (
          //     <Space direction='horizontal'>
          //       <EditOutlined onClick={() => this.toggleKpiComments(record)} />
          //       {(record.notes && record.notes !== "<p></p>") && (
          //         <>
          //           <Divider type='vartical' />
          //           <CommentOutlined style={{ color: "#6abf6a", marginLeft: "10px" }} />
          //         </>
          //       )}
          //     </Space>
          //   )
          // }
          // else {
          //   return ("")
          // }
        },
      },
      {
        title: `${t('kpiPage.annualTarget')}`,
        dataIndex: 'finalTarget',
        key: 'finalTarget',
        align: 'right',
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => <>{italianNumberFormat(record.finalTarget)}</>,
      },
    ];

    return (
      <>
        {showCommentModal && selectedRowData && (
          <EditKpiCommentModal
            showCommentModal={showCommentModal}
            handleCommentModalClose={this.handleKpiCommentClose}
            onCommentUpdate={this.handleSaveComments}
            loadingCommentSave={loadingSaveComment}
            t={t}
            rowData={selectedRowData}
          />
        )}

        <TableLayout className="project-card-wrapper">
          <TableLayout.Actions>
            <DatePicker
              allowClear={false}
              placeholder={t('general.anno')}
              picker="year"
              value={moment(selectedYear, 'YYYY')}
              style={{ width: '100%' }}
              format="YYYY"
              onChange={(date) => this.onYearChange(date)}
            />
            <DatePicker
              style={{ width: '100%' }}
              allowClear={false}
              value={moment(selectedMonth)}
              format={monthFormat}
              placeholder={t('general.mese')}
              picker="month"
              onChange={(date) => this.onMonthChange(date)}
              disabledDate={(current) => this.disableMonths(current, selectedXmatrixInfo)}
            />
            <Select
              value={selectedAnnualGoal === '' ? null : selectedAnnualGoal}
              showSearch
              placeholder={t('general.obiettiviAnno')}
              optionFilterProp="children"
              onChange={(option) => this.onAnnualGoalChange(option)}
              labelRender={labelRender}
              className="tw-shrink-0 tw-w-[500px]"
              optionRender={(option) => (
                <Space>
                  <Tooltip
                    title={option.label}
                    placement="top">
                    <span className="tw-shrink tw-max-w-16 tw-truncate">{option.label}</span>
                  </Tooltip>
                </Space>
              )}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {annualGoalPicklist &&
                annualGoalPicklist.map((item) => (
                  <Option
                    value={item.annualGoalId}
                    key={item.annualGoalId}>
                    {item.description}
                  </Option>
                ))}
            </Select>
            <Tooltip title={t('general.filter')}>
              <Button
                disabled={loadingDisabled}
                className="tw-shrink-0"
                icon={<FilterOutlined />}
                onClick={() => this.onFilter()}
              />
            </Tooltip>
            <Tooltip title={t('general.clearFilters')}>
              <Button
                disabled={loadingDisabled}
                className="tw-shrink-0"
                icon={<MinusSquareOutlined />}
                onClick={() => this.onClearFilter()}
              />
            </Tooltip>
            <div className="tw-ml-8 tw-flex tw-gap-2">
              <Tooltip title={t('general.previous')}>
                <Button
                  disabled={disablePreviousButton || loadingDisabled}
                  className="tw-shrink-0"
                  icon={<LeftCircleOutlined />}
                  onClick={() => this.onPreviousObject()}
                />
              </Tooltip>
              <Tooltip title={t('general.next')}>
                <Button
                  disabled={disableNextButton || loadingDisabled}
                  className="tw-shrink-0"
                  icon={<RightCircleOutlined />}
                  onClick={() => this.onNextObject()}
                />
              </Tooltip>
            </div>
          </TableLayout.Actions>
          <TableLayout.Content>
            <Table
              className="kpiSintesiTable"
              size="small"
              loading={loadingData}
              columns={kpiColumns}
              dataSource={kpiList}
              rowKey={(obj) => obj.kpiDetailID}
              pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
            />
          </TableLayout.Content>
        </TableLayout>
      </>
    );
  }
}

export default AnualGoalKpiSintesi;
