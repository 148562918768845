/* tslint:disable */
/* eslint-disable */
/**
 * Lenovys - HTTP Api
 * The Lenovys HTTP Api
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AddRemoveCommitteeIDProjectRequestDto
 */
export interface AddRemoveCommitteeIDProjectRequestDto {
    /**
     * 
     * @type {string}
     * @memberof AddRemoveCommitteeIDProjectRequestDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddRemoveCommitteeIDProjectRequestDto
     */
    'projectID': string;
}
/**
 * 
 * @export
 * @interface AdminInsertCompanyRequestDto
 */
export interface AdminInsertCompanyRequestDto {
    /**
     * 
     * @type {string}
     * @memberof AdminInsertCompanyRequestDto
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminInsertCompanyRequestDto
     */
    'companyShortName'?: string | null;
}
/**
 * 
 * @export
 * @interface AgendaHeaderDto
 */
export interface AgendaHeaderDto {
    /**
     * 
     * @type {string}
     * @memberof AgendaHeaderDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AgendaHeaderDto
     */
    'scope'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AgendaHeaderDto
     */
    'duration'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AgendaHeaderDto
     */
    'frequency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgendaHeaderDto
     */
    'committeeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgendaHeaderDto
     */
    'sessionId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AgendaHeaderDto
     */
    'state': number;
    /**
     * 
     * @type {string}
     * @memberof AgendaHeaderDto
     */
    'notes'?: string | null;
}
/**
 * 
 * @export
 * @interface AgendaItems
 */
export interface AgendaItems {
    /**
     * 
     * @type {string}
     * @memberof AgendaItems
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AgendaItems
     */
    'agendaHeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgendaItems
     */
    'orderString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgendaItems
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgendaItems
     */
    'frequency'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AgendaItems
     */
    'duration'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AgendaItems
     */
    'input'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgendaItems
     */
    'output'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgendaItems
     */
    'accountable'?: string | null;
}
/**
 * 
 * @export
 * @interface AlertConfigurationResponseDto
 */
export interface AlertConfigurationResponseDto {
    /**
     * 
     * @type {string}
     * @memberof AlertConfigurationResponseDto
     */
    'alertID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AlertConfigurationResponseDto
     */
    'companyID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AlertConfigurationResponseDto
     */
    'allInOne'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof AlertConfigurationResponseDto
     */
    'reminderInDaysFrequence'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AlertConfigurationResponseDto
     */
    'sendViaMail'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AlertConfigurationResponseDto
     */
    'sendViaNotificationBell'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof AlertConfigurationResponseDto
     */
    'periodo'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AlertConfigurationResponseDto
     */
    'lastEmailSent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AlertConfigurationResponseDto
     */
    'lastAlertSent'?: string | null;
}
/**
 * 
 * @export
 * @interface AlertConfigurationResponseDtoControllerResponse
 */
export interface AlertConfigurationResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AlertConfigurationResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof AlertConfigurationResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {AlertConfigurationResponseDtoResponse}
     * @memberof AlertConfigurationResponseDtoControllerResponse
     */
    'responseObject'?: AlertConfigurationResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface AlertConfigurationResponseDtoResponse
 */
export interface AlertConfigurationResponseDtoResponse {
    /**
     * 
     * @type {AlertConfigurationResponseDto}
     * @memberof AlertConfigurationResponseDtoResponse
     */
    'value'?: AlertConfigurationResponseDto;
}
/**
 * 
 * @export
 * @interface AnnualGoal
 */
export interface AnnualGoal {
    /**
     * 
     * @type {string}
     * @memberof AnnualGoal
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoal
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoal
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoal
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoal
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoal
     */
    'orderingValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoal
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoal
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoal
     */
    'qualitySemaphore'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoal
     */
    'responsabile'?: string | null;
}
/**
 * 
 * @export
 * @interface AnnualGoalKPIBowling
 */
export interface AnnualGoalKPIBowling {
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIBowling
     */
    'kpiDetailName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoalKPIBowling
     */
    'type': number;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIBowling
     */
    'typeDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoalKPIBowling
     */
    'parentType': number;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIBowling
     */
    'parentTypeDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoalKPIBowling
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIBowling
     */
    'kpiProjectRelationshipID': string;
    /**
     * 
     * @type {boolean}
     * @memberof AnnualGoalKPIBowling
     */
    'isRollupKPI': boolean;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIBowling
     */
    'rollupKpiName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIBowling
     */
    'parentKPIProjectRelationshipID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIBowling
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIBowling
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIBowling
     */
    'annualGoalName'?: string | null;
    /**
     * 
     * @type {{ [key: string]: number | null; }}
     * @memberof AnnualGoalKPIBowling
     */
    'monthsList'?: { [key: string]: number | null; } | null;
}
/**
 * 
 * @export
 * @interface AnnualGoalKPIBowlingListControllerResponse
 */
export interface AnnualGoalKPIBowlingListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AnnualGoalKPIBowlingListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIBowlingListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {AnnualGoalKPIBowlingListResponse}
     * @memberof AnnualGoalKPIBowlingListControllerResponse
     */
    'responseObject'?: AnnualGoalKPIBowlingListResponse;
}
/**
 * 
 * @export
 * @interface AnnualGoalKPIBowlingListResponse
 */
export interface AnnualGoalKPIBowlingListResponse {
    /**
     * 
     * @type {Array<AnnualGoalKPIBowling>}
     * @memberof AnnualGoalKPIBowlingListResponse
     */
    'value'?: Array<AnnualGoalKPIBowling> | null;
}
/**
 * 
 * @export
 * @interface AnnualGoalKPIRelationship
 */
export interface AnnualGoalKPIRelationship {
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationship
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoalKPIRelationship
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoalKPIRelationship
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationship
     */
    'highestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationship
     */
    'lowestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationship
     */
    'relationshipID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationship
     */
    'highestNameValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationship
     */
    'lowestNameValue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoalKPIRelationship
     */
    'highestNumberValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoalKPIRelationship
     */
    'lowestNumberValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationship
     */
    'highestObjectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationship
     */
    'lowestObjectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface AnnualGoalKPIRelationships
 */
export interface AnnualGoalKPIRelationships {
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationships
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoalKPIRelationships
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoalKPIRelationships
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationships
     */
    'highestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationships
     */
    'lowestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationships
     */
    'relationshipID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationships
     */
    'highestNameValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationships
     */
    'lowestNameValue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoalKPIRelationships
     */
    'highestNumberValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoalKPIRelationships
     */
    'lowestNumberValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationships
     */
    'highestObjectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationships
     */
    'lowestObjectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface AnnualGoalPicklistResponseDto
 */
export interface AnnualGoalPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalPicklistResponseDto
     */
    'annualGoalId': string;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalPicklistResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoalPicklistResponseDto
     */
    'orderingValue'?: number | null;
}
/**
 * 
 * @export
 * @interface AnnualGoalPicklistResponseDtoListControllerResponse
 */
export interface AnnualGoalPicklistResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AnnualGoalPicklistResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalPicklistResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {AnnualGoalPicklistResponseDtoListResponse}
     * @memberof AnnualGoalPicklistResponseDtoListControllerResponse
     */
    'responseObject'?: AnnualGoalPicklistResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface AnnualGoalPicklistResponseDtoListResponse
 */
export interface AnnualGoalPicklistResponseDtoListResponse {
    /**
     * 
     * @type {Array<AnnualGoalPicklistResponseDto>}
     * @memberof AnnualGoalPicklistResponseDtoListResponse
     */
    'value'?: Array<AnnualGoalPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface AnnualGoalResponse
 */
export interface AnnualGoalResponse {
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalResponse
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalResponse
     */
    'annualGoalName'?: string | null;
}
/**
 * 
 * @export
 * @interface AnnualGoals
 */
export interface AnnualGoals {
    /**
     * 
     * @type {string}
     * @memberof AnnualGoals
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoals
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoals
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoals
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoals
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoals
     */
    'orderingValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoals
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoals
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoals
     */
    'qualitySemaphore'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoals
     */
    'responsabile'?: string | null;
}
/**
 * 
 * @export
 * @interface AnnualObjData
 */
export interface AnnualObjData {
    /**
     * 
     * @type {Array<AnnualGoalResponse>}
     * @memberof AnnualObjData
     */
    'annualGoalNotRelated'?: Array<AnnualGoalResponse> | null;
    /**
     * 
     * @type {Array<AnnualGoalResponse>}
     * @memberof AnnualObjData
     */
    'annualGoalRelated'?: Array<AnnualGoalResponse> | null;
}
/**
 * 
 * @export
 * @interface Assembly
 */
export interface Assembly {
    /**
     * 
     * @type {Array<TypeInfo>}
     * @memberof Assembly
     */
    'definedTypes': Array<TypeInfo>;
    /**
     * 
     * @type {Array<Type>}
     * @memberof Assembly
     */
    'exportedTypes': Array<Type>;
    /**
     * 
     * @type {string}
     * @memberof Assembly
     * @deprecated
     */
    'codeBase'?: string | null;
    /**
     * 
     * @type {MethodInfo}
     * @memberof Assembly
     */
    'entryPoint'?: MethodInfo;
    /**
     * 
     * @type {string}
     * @memberof Assembly
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Assembly
     */
    'imageRuntimeVersion': string;
    /**
     * 
     * @type {boolean}
     * @memberof Assembly
     */
    'isDynamic': boolean;
    /**
     * 
     * @type {string}
     * @memberof Assembly
     */
    'location': string;
    /**
     * 
     * @type {boolean}
     * @memberof Assembly
     */
    'reflectionOnly': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Assembly
     */
    'isCollectible': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Assembly
     */
    'isFullyTrusted': boolean;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof Assembly
     */
    'customAttributes': Array<CustomAttributeData>;
    /**
     * 
     * @type {string}
     * @memberof Assembly
     * @deprecated
     */
    'escapedCodeBase': string;
    /**
     * 
     * @type {Module}
     * @memberof Assembly
     */
    'manifestModule': Module;
    /**
     * 
     * @type {Array<Module>}
     * @memberof Assembly
     */
    'modules': Array<Module>;
    /**
     * 
     * @type {boolean}
     * @memberof Assembly
     * @deprecated
     */
    'globalAssemblyCache': boolean;
    /**
     * 
     * @type {number}
     * @memberof Assembly
     */
    'hostContext': number;
    /**
     * 
     * @type {SecurityRuleSet}
     * @memberof Assembly
     */
    'securityRuleSet': SecurityRuleSet;
}


/**
 * 
 * @export
 * @interface BooleanControllerResponse
 */
export interface BooleanControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof BooleanControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof BooleanControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {BooleanResponse}
     * @memberof BooleanControllerResponse
     */
    'responseObject'?: BooleanResponse;
}
/**
 * 
 * @export
 * @interface BooleanResponse
 */
export interface BooleanResponse {
    /**
     * 
     * @type {boolean}
     * @memberof BooleanResponse
     */
    'value': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CallingConventions = {
    Standard: 'standard',
    VarArgs: 'varArgs',
    Any: 'any',
    HasThis: 'hasThis',
    ExplicitThis: 'explicitThis'
} as const;

export type CallingConventions = typeof CallingConventions[keyof typeof CallingConventions];


/**
 * 
 * @export
 * @interface CheckKpiTypePlanningStartDateResponseDto
 */
export interface CheckKpiTypePlanningStartDateResponseDto {
    /**
     * 
     * @type {number}
     * @memberof CheckKpiTypePlanningStartDateResponseDto
     */
    'year'?: number | null;
}
/**
 * 
 * @export
 * @interface CheckKpiTypePlanningStartDateResponseDtoControllerResponse
 */
export interface CheckKpiTypePlanningStartDateResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CheckKpiTypePlanningStartDateResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof CheckKpiTypePlanningStartDateResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {CheckKpiTypePlanningStartDateResponseDtoResponse}
     * @memberof CheckKpiTypePlanningStartDateResponseDtoControllerResponse
     */
    'responseObject'?: CheckKpiTypePlanningStartDateResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface CheckKpiTypePlanningStartDateResponseDtoResponse
 */
export interface CheckKpiTypePlanningStartDateResponseDtoResponse {
    /**
     * 
     * @type {CheckKpiTypePlanningStartDateResponseDto}
     * @memberof CheckKpiTypePlanningStartDateResponseDtoResponse
     */
    'value'?: CheckKpiTypePlanningStartDateResponseDto;
}
/**
 * 
 * @export
 * @interface CheckObjectSecurityUserRequestDto
 */
export interface CheckObjectSecurityUserRequestDto {
    /**
     * 
     * @type {number}
     * @memberof CheckObjectSecurityUserRequestDto
     */
    'type'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CheckObjectSecurityUserRequestDto
     */
    'objectID'?: string | null;
}
/**
 * 
 * @export
 * @interface CheckObjectSecurityUserResponse
 */
export interface CheckObjectSecurityUserResponse {
    /**
     * 
     * @type {number}
     * @memberof CheckObjectSecurityUserResponse
     */
    'hasPrivilege'?: number | null;
}
/**
 * 
 * @export
 * @interface CheckObjectSecurityUserResponseControllerResponse
 */
export interface CheckObjectSecurityUserResponseControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CheckObjectSecurityUserResponseControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof CheckObjectSecurityUserResponseControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {CheckObjectSecurityUserResponseResponse}
     * @memberof CheckObjectSecurityUserResponseControllerResponse
     */
    'responseObject'?: CheckObjectSecurityUserResponseResponse;
}
/**
 * 
 * @export
 * @interface CheckObjectSecurityUserResponseResponse
 */
export interface CheckObjectSecurityUserResponseResponse {
    /**
     * 
     * @type {CheckObjectSecurityUserResponse}
     * @memberof CheckObjectSecurityUserResponseResponse
     */
    'value'?: CheckObjectSecurityUserResponse;
}
/**
 * 
 * @export
 * @interface ChildCommitteeResponseDto
 */
export interface ChildCommitteeResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ChildCommitteeResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ChildCommitteeResponseDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface ChildCommitteeResponseDtoListControllerResponse
 */
export interface ChildCommitteeResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ChildCommitteeResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ChildCommitteeResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ChildCommitteeResponseDtoListResponse}
     * @memberof ChildCommitteeResponseDtoListControllerResponse
     */
    'responseObject'?: ChildCommitteeResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface ChildCommitteeResponseDtoListResponse
 */
export interface ChildCommitteeResponseDtoListResponse {
    /**
     * 
     * @type {Array<ChildCommitteeResponseDto>}
     * @memberof ChildCommitteeResponseDtoListResponse
     */
    'value'?: Array<ChildCommitteeResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ChildList
 */
export interface ChildList {
    /**
     * 
     * @type {string}
     * @memberof ChildList
     */
    'xmatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildList
     */
    'hkCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildList
     */
    'parentXMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildList
     */
    'parentXMatrixHKCode'?: string | null;
}
/**
 * 
 * @export
 * @interface ChildMenuItem
 */
export interface ChildMenuItem {
    /**
     * 
     * @type {string}
     * @memberof ChildMenuItem
     */
    'menuItemId': string;
    /**
     * 
     * @type {string}
     * @memberof ChildMenuItem
     */
    'menuItemDisplayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildMenuItem
     */
    'menuItemLink'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ChildMenuItem
     */
    'menuItemIsDisabled': boolean;
    /**
     * 
     * @type {number}
     * @memberof ChildMenuItem
     */
    'orderingValueMenuItem'?: number | null;
}
/**
 * 
 * @export
 * @interface CommitteMembers
 */
export interface CommitteMembers {
    /**
     * 
     * @type {string}
     * @memberof CommitteMembers
     */
    'userID': string;
    /**
     * 
     * @type {string}
     * @memberof CommitteMembers
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommitteMembers
     */
    'isDeleted': boolean;
}
/**
 * 
 * @export
 * @interface CommitteMembersListControllerResponse
 */
export interface CommitteMembersListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CommitteMembersListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommitteMembersListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {CommitteMembersListResponse}
     * @memberof CommitteMembersListControllerResponse
     */
    'responseObject'?: CommitteMembersListResponse;
}
/**
 * 
 * @export
 * @interface CommitteMembersListResponse
 */
export interface CommitteMembersListResponse {
    /**
     * 
     * @type {Array<CommitteMembers>}
     * @memberof CommitteMembersListResponse
     */
    'value'?: Array<CommitteMembers> | null;
}
/**
 * 
 * @export
 * @interface CommitteeDto
 */
export interface CommitteeDto {
    /**
     * 
     * @type {string}
     * @memberof CommitteeDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CommitteeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CommitteeDto
     */
    'repositoryLink'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CommitteeDto
     */
    'activitiesDuration': number;
    /**
     * 
     * @type {string}
     * @memberof CommitteeDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommitteeDto
     */
    'commentTitle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CommitteeDto
     */
    'projectsCount': number;
    /**
     * 
     * @type {Array<CommitteeMemberDto>}
     * @memberof CommitteeDto
     */
    'members'?: Array<CommitteeMemberDto> | null;
    /**
     * 
     * @type {AgendaHeaderDto}
     * @memberof CommitteeDto
     */
    'agendaHeader'?: AgendaHeaderDto;
}
/**
 * 
 * @export
 * @interface CommitteeMemberDto
 */
export interface CommitteeMemberDto {
    /**
     * 
     * @type {string}
     * @memberof CommitteeMemberDto
     */
    'committeeId': string;
    /**
     * 
     * @type {string}
     * @memberof CommitteeMemberDto
     */
    'userId': string;
    /**
     * 
     * @type {boolean}
     * @memberof CommitteeMemberDto
     */
    'isRevisori': boolean;
    /**
     * 
     * @type {string}
     * @memberof CommitteeMemberDto
     */
    'userFullName': string;
    /**
     * 
     * @type {string}
     * @memberof CommitteeMemberDto
     */
    'userInitials': string;
    /**
     * 
     * @type {string}
     * @memberof CommitteeMemberDto
     */
    'userName': string;
    /**
     * 
     * @type {string}
     * @memberof CommitteeMemberDto
     */
    'userSurname': string;
    /**
     * 
     * @type {boolean}
     * @memberof CommitteeMemberDto
     */
    'userIsDeleted': boolean;
}
/**
 * 
 * @export
 * @interface CompUsersListDto
 */
export interface CompUsersListDto {
    /**
     * 
     * @type {string}
     * @memberof CompUsersListDto
     */
    'roleCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompUsersListDto
     */
    'usersCount'?: number | null;
}
/**
 * 
 * @export
 * @interface CompanyByIdResponseDto
 */
export interface CompanyByIdResponseDto {
    /**
     * 
     * @type {string}
     * @memberof CompanyByIdResponseDto
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyByIdResponseDto
     */
    'logo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyByIdResponseDto
     */
    'mission'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyByIdResponseDto
     */
    'values'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyByIdResponseDto
     */
    'vision'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyByIdResponseDto
     */
    'timeAlertEdge'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyByIdResponseDto
     */
    'budgetAlertEdge'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyByIdResponseDto
     */
    'qualityAlertEdge'?: number | null;
}
/**
 * 
 * @export
 * @interface CompanyByIdResponseDtoControllerResponse
 */
export interface CompanyByIdResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CompanyByIdResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyByIdResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {CompanyByIdResponseDtoResponse}
     * @memberof CompanyByIdResponseDtoControllerResponse
     */
    'responseObject'?: CompanyByIdResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface CompanyByIdResponseDtoResponse
 */
export interface CompanyByIdResponseDtoResponse {
    /**
     * 
     * @type {CompanyByIdResponseDto}
     * @memberof CompanyByIdResponseDtoResponse
     */
    'value'?: CompanyByIdResponseDto;
}
/**
 * 
 * @export
 * @interface CompanyResponseDto
 */
export interface CompanyResponseDto {
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseDto
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseDto
     */
    'logo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseDto
     */
    'mission'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseDto
     */
    'values'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseDto
     */
    'vision'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseDto
     */
    'timeAlertEdge'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseDto
     */
    'budgetAlertEdge'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseDto
     */
    'qualityAlertEdge'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseDto
     */
    'currency'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseDto
     */
    'budgetAlertSelection': number;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyResponseDto
     */
    'sendNotification'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseDto
     */
    'daysOfNotice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseDto
     */
    'noticeStatus'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseDto
     */
    'redirectLink'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseDto
     */
    'supportMail'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseDto
     */
    'implementationDaysOfNotice': number;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseDto
     */
    'nextStepsDaysOfNotice': number;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseDto
     */
    'kpiTolerance': number;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyResponseDto
     */
    'startFromCurrentMonth': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyResponseDto
     */
    'resetGlobalKPI': boolean;
}
/**
 * 
 * @export
 * @interface CompanyResponseDtoControllerResponse
 */
export interface CompanyResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CompanyResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {CompanyResponseDtoResponse}
     * @memberof CompanyResponseDtoControllerResponse
     */
    'responseObject'?: CompanyResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface CompanyResponseDtoResponse
 */
export interface CompanyResponseDtoResponse {
    /**
     * 
     * @type {CompanyResponseDto}
     * @memberof CompanyResponseDtoResponse
     */
    'value'?: CompanyResponseDto;
}
/**
 * 
 * @export
 * @interface ConstructorInfo
 */
export interface ConstructorInfo {
    /**
     * 
     * @type {string}
     * @memberof ConstructorInfo
     */
    'name': string;
    /**
     * 
     * @type {Type}
     * @memberof ConstructorInfo
     */
    'declaringType'?: Type;
    /**
     * 
     * @type {Type}
     * @memberof ConstructorInfo
     */
    'reflectedType'?: Type;
    /**
     * 
     * @type {Module}
     * @memberof ConstructorInfo
     */
    'module': Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof ConstructorInfo
     */
    'customAttributes': Array<CustomAttributeData>;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isCollectible': boolean;
    /**
     * 
     * @type {number}
     * @memberof ConstructorInfo
     */
    'metadataToken': number;
    /**
     * 
     * @type {MethodAttributes}
     * @memberof ConstructorInfo
     */
    'attributes': MethodAttributes;
    /**
     * 
     * @type {MethodImplAttributes}
     * @memberof ConstructorInfo
     */
    'methodImplementationFlags': MethodImplAttributes;
    /**
     * 
     * @type {CallingConventions}
     * @memberof ConstructorInfo
     */
    'callingConvention': CallingConventions;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isAbstract': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isConstructor': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isFinal': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isHideBySig': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isSpecialName': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isStatic': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isVirtual': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isAssembly': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isFamily': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isFamilyAndAssembly': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isFamilyOrAssembly': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isPrivate': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isPublic': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isConstructedGenericMethod': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isGenericMethod': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isGenericMethodDefinition': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'containsGenericParameters': boolean;
    /**
     * 
     * @type {RuntimeMethodHandle}
     * @memberof ConstructorInfo
     */
    'methodHandle': RuntimeMethodHandle;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isSecurityCritical': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isSecuritySafeCritical': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstructorInfo
     */
    'isSecurityTransparent': boolean;
    /**
     * 
     * @type {MemberTypes}
     * @memberof ConstructorInfo
     */
    'memberType': MemberTypes;
}


/**
 * 
 * @export
 * @interface CreateCommitteeRequest
 */
export interface CreateCommitteeRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCommitteeRequest
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCommitteeRequest
     */
    'participants'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCommitteeRequest
     */
    'auditors'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCommitteeRequest
     */
    'repositoryLink'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateCommitteeRequest
     */
    'activitiesDuration': number;
    /**
     * 
     * @type {{ [key: string]: boolean | null; }}
     * @memberof CreateCommitteeRequest
     */
    'members'?: { [key: string]: boolean | null; } | null;
}
/**
 * 
 * @export
 * @interface CreatePrecaution
 */
export interface CreatePrecaution {
    /**
     * 
     * @type {string}
     * @memberof CreatePrecaution
     */
    'precautionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePrecaution
     */
    'precautionDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateProjectRequestDto
 */
export interface CreateProjectRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequestDto
     */
    'parentProjectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateProjectRequestDto
     */
    'status': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProjectRequestDto
     */
    'hasExternalReview': boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateProjectRequestDto
     */
    'budget'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequestDto
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequestDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequestDto
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequestDto
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequestDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequestDto
     */
    'projectGoal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequestDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequestDto
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProjectRequestDto
     */
    'fastCreation'?: boolean | null;
}
/**
 * 
 * @export
 * @interface CustomAttributeData
 */
export interface CustomAttributeData {
    /**
     * 
     * @type {Type}
     * @memberof CustomAttributeData
     */
    'attributeType': Type;
    /**
     * 
     * @type {ConstructorInfo}
     * @memberof CustomAttributeData
     */
    'constructor': ConstructorInfo;
    /**
     * 
     * @type {Array<CustomAttributeTypedArgument>}
     * @memberof CustomAttributeData
     */
    'constructorArguments': Array<CustomAttributeTypedArgument>;
    /**
     * 
     * @type {Array<CustomAttributeNamedArgument>}
     * @memberof CustomAttributeData
     */
    'namedArguments': Array<CustomAttributeNamedArgument>;
}
/**
 * 
 * @export
 * @interface CustomAttributeNamedArgument
 */
export interface CustomAttributeNamedArgument {
    /**
     * 
     * @type {MemberInfo}
     * @memberof CustomAttributeNamedArgument
     */
    'memberInfo': MemberInfo;
    /**
     * 
     * @type {CustomAttributeTypedArgument}
     * @memberof CustomAttributeNamedArgument
     */
    'typedValue': CustomAttributeTypedArgument;
    /**
     * 
     * @type {string}
     * @memberof CustomAttributeNamedArgument
     */
    'memberName': string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomAttributeNamedArgument
     */
    'isField': boolean;
}
/**
 * 
 * @export
 * @interface CustomAttributeTypedArgument
 */
export interface CustomAttributeTypedArgument {
    /**
     * 
     * @type {Type}
     * @memberof CustomAttributeTypedArgument
     */
    'argumentType': Type;
    /**
     * 
     * @type {any}
     * @memberof CustomAttributeTypedArgument
     */
    'value'?: any | null;
}
/**
 * 
 * @export
 * @interface DeliverableDto
 */
export interface DeliverableDto {
    /**
     * 
     * @type {string}
     * @memberof DeliverableDto
     */
    'deliverableID': string;
    /**
     * 
     * @type {string}
     * @memberof DeliverableDto
     */
    'parentDeliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliverableDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeliverableDto
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof DeliverableDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliverableDto
     */
    'actualEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliverableDto
     */
    'rescheduledEndDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DeliverableDto
     */
    'onTime': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeliverableDto
     */
    'late': boolean;
}
/**
 * 
 * @export
 * @interface DeliverableDtoIListControllerResponse
 */
export interface DeliverableDtoIListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DeliverableDtoIListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof DeliverableDtoIListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {DeliverableDtoIListResponse}
     * @memberof DeliverableDtoIListControllerResponse
     */
    'responseObject'?: DeliverableDtoIListResponse;
}
/**
 * 
 * @export
 * @interface DeliverableDtoIListResponse
 */
export interface DeliverableDtoIListResponse {
    /**
     * 
     * @type {Array<DeliverableDto>}
     * @memberof DeliverableDtoIListResponse
     */
    'value'?: Array<DeliverableDto> | null;
}
/**
 * 
 * @export
 * @interface DeliverableListObject
 */
export interface DeliverableListObject {
    /**
     * 
     * @type {string}
     * @memberof DeliverableListObject
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliverableListObject
     */
    'deliverableName'?: string | null;
}
/**
 * 
 * @export
 * @interface DetailsKPIResponseDto
 */
export interface DetailsKPIResponseDto {
    /**
     * 
     * @type {string}
     * @memberof DetailsKPIResponseDto
     */
    'month': string;
    /**
     * 
     * @type {number}
     * @memberof DetailsKPIResponseDto
     */
    'sumActual'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DetailsKPIResponseDto
     */
    'actual'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DetailsKPIResponseDto
     */
    'target'?: number | null;
}
/**
 * 
 * @export
 * @interface DetailsKPIResponseDtoListControllerResponse
 */
export interface DetailsKPIResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DetailsKPIResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof DetailsKPIResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {DetailsKPIResponseDtoListResponse}
     * @memberof DetailsKPIResponseDtoListControllerResponse
     */
    'responseObject'?: DetailsKPIResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface DetailsKPIResponseDtoListResponse
 */
export interface DetailsKPIResponseDtoListResponse {
    /**
     * 
     * @type {Array<DetailsKPIResponseDto>}
     * @memberof DetailsKPIResponseDtoListResponse
     */
    'value'?: Array<DetailsKPIResponseDto> | null;
}
/**
 * 
 * @export
 * @interface DivisionDto
 */
export interface DivisionDto {
    /**
     * 
     * @type {string}
     * @memberof DivisionDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DivisionDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DivisionDto
     */
    'companyId'?: string | null;
    /**
     * 
     * @type {UserDto}
     * @memberof DivisionDto
     */
    'owner'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof DivisionDto
     */
    'imageUri'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EventAttributes = {
    None: 'none',
    SpecialName: 'specialName',
    ReservedMask: 'reservedMask'
} as const;

export type EventAttributes = typeof EventAttributes[keyof typeof EventAttributes];


/**
 * 
 * @export
 * @interface EventInfo
 */
export interface EventInfo {
    /**
     * 
     * @type {string}
     * @memberof EventInfo
     */
    'name': string;
    /**
     * 
     * @type {Type}
     * @memberof EventInfo
     */
    'declaringType'?: Type;
    /**
     * 
     * @type {Type}
     * @memberof EventInfo
     */
    'reflectedType'?: Type;
    /**
     * 
     * @type {Module}
     * @memberof EventInfo
     */
    'module': Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof EventInfo
     */
    'customAttributes': Array<CustomAttributeData>;
    /**
     * 
     * @type {boolean}
     * @memberof EventInfo
     */
    'isCollectible': boolean;
    /**
     * 
     * @type {number}
     * @memberof EventInfo
     */
    'metadataToken': number;
    /**
     * 
     * @type {MemberTypes}
     * @memberof EventInfo
     */
    'memberType': MemberTypes;
    /**
     * 
     * @type {EventAttributes}
     * @memberof EventInfo
     */
    'attributes': EventAttributes;
    /**
     * 
     * @type {boolean}
     * @memberof EventInfo
     */
    'isSpecialName': boolean;
    /**
     * 
     * @type {MethodInfo}
     * @memberof EventInfo
     */
    'addMethod'?: MethodInfo;
    /**
     * 
     * @type {MethodInfo}
     * @memberof EventInfo
     */
    'removeMethod'?: MethodInfo;
    /**
     * 
     * @type {MethodInfo}
     * @memberof EventInfo
     */
    'raiseMethod'?: MethodInfo;
    /**
     * 
     * @type {boolean}
     * @memberof EventInfo
     */
    'isMulticast': boolean;
    /**
     * 
     * @type {Type}
     * @memberof EventInfo
     */
    'eventHandlerType'?: Type;
}


/**
 * 
 * @export
 * @interface Exception
 */
export interface Exception {
    /**
     * 
     * @type {MethodBase}
     * @memberof Exception
     */
    'targetSite'?: MethodBase;
    /**
     * 
     * @type {string}
     * @memberof Exception
     */
    'message': string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof Exception
     */
    'data': { [key: string]: any; };
    /**
     * 
     * @type {Exception}
     * @memberof Exception
     */
    'innerException'?: Exception;
    /**
     * 
     * @type {string}
     * @memberof Exception
     */
    'helpLink'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Exception
     */
    'source'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Exception
     */
    'hResult': number;
    /**
     * 
     * @type {string}
     * @memberof Exception
     */
    'stackTrace'?: string | null;
}
/**
 * 
 * @export
 * @interface ExpandoObjectListControllerResponse
 */
export interface ExpandoObjectListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ExpandoObjectListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ExpandoObjectListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ExpandoObjectListResponse}
     * @memberof ExpandoObjectListControllerResponse
     */
    'responseObject'?: ExpandoObjectListResponse;
}
/**
 * 
 * @export
 * @interface ExpandoObjectListResponse
 */
export interface ExpandoObjectListResponse {
    /**
     * 
     * @type {Array<{ [key: string]: any; }>}
     * @memberof ExpandoObjectListResponse
     */
    'value'?: Array<{ [key: string]: any; }> | null;
}
/**
 * 
 * @export
 * @interface FastCloseDeliverableRequestDto
 */
export interface FastCloseDeliverableRequestDto {
    /**
     * 
     * @type {string}
     * @memberof FastCloseDeliverableRequestDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FastCloseDeliverableRequestDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FastCloseDeliverableRequestDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FastCloseDeliverableRequestDto
     */
    'ownerID'?: string | null;
}
/**
 * 
 * @export
 * @interface FastCloseDeliverableResponse
 */
export interface FastCloseDeliverableResponse {
    /**
     * 
     * @type {string}
     * @memberof FastCloseDeliverableResponse
     */
    'fullName'?: string | null;
}
/**
 * 
 * @export
 * @interface FastCloseDeliverableResponseControllerResponse
 */
export interface FastCloseDeliverableResponseControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FastCloseDeliverableResponseControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof FastCloseDeliverableResponseControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {FastCloseDeliverableResponseResponse}
     * @memberof FastCloseDeliverableResponseControllerResponse
     */
    'responseObject'?: FastCloseDeliverableResponseResponse;
}
/**
 * 
 * @export
 * @interface FastCloseDeliverableResponseResponse
 */
export interface FastCloseDeliverableResponseResponse {
    /**
     * 
     * @type {FastCloseDeliverableResponse}
     * @memberof FastCloseDeliverableResponseResponse
     */
    'value'?: FastCloseDeliverableResponse;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FieldAttributes = {
    PrivateScope: 'privateScope',
    Private: 'private',
    FamAndAssem: 'famANDAssem',
    Assembly: 'assembly',
    Family: 'family',
    FamOrAssem: 'famORAssem',
    Public: 'public',
    FieldAccessMask: 'fieldAccessMask',
    Static: 'static',
    InitOnly: 'initOnly',
    Literal: 'literal',
    NotSerialized: 'notSerialized',
    HasFieldRva: 'hasFieldRVA',
    SpecialName: 'specialName',
    RtSpecialName: 'rtSpecialName',
    HasFieldMarshal: 'hasFieldMarshal',
    PinvokeImpl: 'pinvokeImpl',
    HasDefault: 'hasDefault',
    ReservedMask: 'reservedMask'
} as const;

export type FieldAttributes = typeof FieldAttributes[keyof typeof FieldAttributes];


/**
 * 
 * @export
 * @interface FieldInfo
 */
export interface FieldInfo {
    /**
     * 
     * @type {string}
     * @memberof FieldInfo
     */
    'name': string;
    /**
     * 
     * @type {Type}
     * @memberof FieldInfo
     */
    'declaringType'?: Type;
    /**
     * 
     * @type {Type}
     * @memberof FieldInfo
     */
    'reflectedType'?: Type;
    /**
     * 
     * @type {Module}
     * @memberof FieldInfo
     */
    'module': Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof FieldInfo
     */
    'customAttributes': Array<CustomAttributeData>;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    'isCollectible': boolean;
    /**
     * 
     * @type {number}
     * @memberof FieldInfo
     */
    'metadataToken': number;
    /**
     * 
     * @type {MemberTypes}
     * @memberof FieldInfo
     */
    'memberType': MemberTypes;
    /**
     * 
     * @type {FieldAttributes}
     * @memberof FieldInfo
     */
    'attributes': FieldAttributes;
    /**
     * 
     * @type {Type}
     * @memberof FieldInfo
     */
    'fieldType': Type;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    'isInitOnly': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    'isLiteral': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     * @deprecated
     */
    'isNotSerialized': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    'isPinvokeImpl': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    'isSpecialName': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    'isStatic': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    'isAssembly': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    'isFamily': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    'isFamilyAndAssembly': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    'isFamilyOrAssembly': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    'isPrivate': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    'isPublic': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    'isSecurityCritical': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    'isSecuritySafeCritical': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldInfo
     */
    'isSecurityTransparent': boolean;
    /**
     * 
     * @type {RuntimeFieldHandle}
     * @memberof FieldInfo
     */
    'fieldHandle': RuntimeFieldHandle;
}


/**
 * 
 * @export
 * @interface FilterbyXMatrixResponse
 */
export interface FilterbyXMatrixResponse {
    /**
     * 
     * @type {string}
     * @memberof FilterbyXMatrixResponse
     */
    'userID': string;
    /**
     * 
     * @type {string}
     * @memberof FilterbyXMatrixResponse
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {Array<StatusResponnse>}
     * @memberof FilterbyXMatrixResponse
     */
    'statusList'?: Array<StatusResponnse> | null;
}
/**
 * 
 * @export
 * @interface FilterbyXMatrixResponseListControllerResponse
 */
export interface FilterbyXMatrixResponseListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FilterbyXMatrixResponseListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof FilterbyXMatrixResponseListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {FilterbyXMatrixResponseListResponse}
     * @memberof FilterbyXMatrixResponseListControllerResponse
     */
    'responseObject'?: FilterbyXMatrixResponseListResponse;
}
/**
 * 
 * @export
 * @interface FilterbyXMatrixResponseListResponse
 */
export interface FilterbyXMatrixResponseListResponse {
    /**
     * 
     * @type {Array<FilterbyXMatrixResponse>}
     * @memberof FilterbyXMatrixResponseListResponse
     */
    'value'?: Array<FilterbyXMatrixResponse> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const GenericParameterAttributes = {
    None: 'none',
    Covariant: 'covariant',
    Contravariant: 'contravariant',
    VarianceMask: 'varianceMask',
    ReferenceTypeConstraint: 'referenceTypeConstraint',
    NotNullableValueTypeConstraint: 'notNullableValueTypeConstraint',
    DefaultConstructorConstraint: 'defaultConstructorConstraint',
    SpecialConstraintMask: 'specialConstraintMask'
} as const;

export type GenericParameterAttributes = typeof GenericParameterAttributes[keyof typeof GenericParameterAttributes];


/**
 * 
 * @export
 * @interface GetActivitySummaryperStatusResponseDto
 */
export interface GetActivitySummaryperStatusResponseDto {
    /**
     * 
     * @type {number}
     * @memberof GetActivitySummaryperStatusResponseDto
     */
    'activityNumber'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetActivitySummaryperStatusResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetActivitySummaryperStatusResponseDto
     */
    'statusDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetActivitySummaryperStatusResponseDtoListControllerResponse
 */
export interface GetActivitySummaryperStatusResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetActivitySummaryperStatusResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetActivitySummaryperStatusResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetActivitySummaryperStatusResponseDtoListResponse}
     * @memberof GetActivitySummaryperStatusResponseDtoListControllerResponse
     */
    'responseObject'?: GetActivitySummaryperStatusResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetActivitySummaryperStatusResponseDtoListResponse
 */
export interface GetActivitySummaryperStatusResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetActivitySummaryperStatusResponseDto>}
     * @memberof GetActivitySummaryperStatusResponseDtoListResponse
     */
    'value'?: Array<GetActivitySummaryperStatusResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetActivitySummaryperTimeResponse
 */
export interface GetActivitySummaryperTimeResponse {
    /**
     * 
     * @type {number}
     * @memberof GetActivitySummaryperTimeResponse
     */
    'activityCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetActivitySummaryperTimeResponse
     */
    'activityStatus'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetActivitySummaryperTimeResponse
     */
    'activityStatusDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetActivitySummaryperTimeResponseListControllerResponse
 */
export interface GetActivitySummaryperTimeResponseListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetActivitySummaryperTimeResponseListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetActivitySummaryperTimeResponseListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetActivitySummaryperTimeResponseListResponse}
     * @memberof GetActivitySummaryperTimeResponseListControllerResponse
     */
    'responseObject'?: GetActivitySummaryperTimeResponseListResponse;
}
/**
 * 
 * @export
 * @interface GetActivitySummaryperTimeResponseListResponse
 */
export interface GetActivitySummaryperTimeResponseListResponse {
    /**
     * 
     * @type {Array<GetActivitySummaryperTimeResponse>}
     * @memberof GetActivitySummaryperTimeResponseListResponse
     */
    'value'?: Array<GetActivitySummaryperTimeResponse> | null;
}
/**
 * 
 * @export
 * @interface GetAgendaCalendarResponseDto
 */
export interface GetAgendaCalendarResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetAgendaCalendarResponseDto
     */
    'projectID': string;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaCalendarResponseDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaCalendarResponseDto
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaCalendarResponseDto
     */
    'sessions'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaCalendarResponseDto
     */
    'committeeName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetAgendaCalendarResponseDto
     */
    'isSubCommittee'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaCalendarResponseDto
     */
    'divisionName'?: string | null;
}
/**
 * 
 * @export
 * @interface GetAgendaCalendarResponseDtoListControllerResponse
 */
export interface GetAgendaCalendarResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetAgendaCalendarResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaCalendarResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetAgendaCalendarResponseDtoListResponse}
     * @memberof GetAgendaCalendarResponseDtoListControllerResponse
     */
    'responseObject'?: GetAgendaCalendarResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetAgendaCalendarResponseDtoListResponse
 */
export interface GetAgendaCalendarResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetAgendaCalendarResponseDto>}
     * @memberof GetAgendaCalendarResponseDtoListResponse
     */
    'value'?: Array<GetAgendaCalendarResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetAgendaResponseDto
 */
export interface GetAgendaResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetAgendaResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaResponseDto
     */
    'scope'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetAgendaResponseDto
     */
    'duration'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaResponseDto
     */
    'frequency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaResponseDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaResponseDto
     */
    'committeeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaResponseDto
     */
    'sessionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaResponseDto
     */
    'sessionDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaResponseDto
     */
    'agendaItems'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaResponseDto
     */
    'committeeProjects'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaResponseDto
     */
    'subCommitteeProjects'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaResponseDto
     */
    'notes'?: string | null;
}
/**
 * 
 * @export
 * @interface GetAgendaResponseDtoControllerResponse
 */
export interface GetAgendaResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetAgendaResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetAgendaResponseDtoResponse}
     * @memberof GetAgendaResponseDtoControllerResponse
     */
    'responseObject'?: GetAgendaResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface GetAgendaResponseDtoResponse
 */
export interface GetAgendaResponseDtoResponse {
    /**
     * 
     * @type {GetAgendaResponseDto}
     * @memberof GetAgendaResponseDtoResponse
     */
    'value'?: GetAgendaResponseDto;
}
/**
 * 
 * @export
 * @interface GetAnnualGoalByKPIResponseDto
 */
export interface GetAnnualGoalByKPIResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalByKPIResponseDto
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalByKPIResponseDto
     */
    'annualGoalDesription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetAnnualGoalByKPIResponseDtoListControllerResponse
 */
export interface GetAnnualGoalByKPIResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetAnnualGoalByKPIResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalByKPIResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetAnnualGoalByKPIResponseDtoListResponse}
     * @memberof GetAnnualGoalByKPIResponseDtoListControllerResponse
     */
    'responseObject'?: GetAnnualGoalByKPIResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetAnnualGoalByKPIResponseDtoListResponse
 */
export interface GetAnnualGoalByKPIResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetAnnualGoalByKPIResponseDto>}
     * @memberof GetAnnualGoalByKPIResponseDtoListResponse
     */
    'value'?: Array<GetAnnualGoalByKPIResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetAnnualGoalByLongTermGoalDto
 */
export interface GetAnnualGoalByLongTermGoalDto {
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalByLongTermGoalDto
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalByLongTermGoalDto
     */
    'longTermGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalByLongTermGoalDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetAnnualGoalByLongTermGoalDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalByLongTermGoalDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalByLongTermGoalDto
     */
    'referencePeriod'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetAnnualGoalByLongTermGoalDto
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalByLongTermGoalDto
     */
    'correlationDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalByLongTermGoalDto
     */
    'owner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalByLongTermGoalDto
     */
    'ownerName'?: string | null;
}
/**
 * 
 * @export
 * @interface GetAnnualGoalDto
 */
export interface GetAnnualGoalDto {
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalDto
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetAnnualGoalDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalDto
     */
    'referencePeriod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalDto
     */
    'owner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalDto
     */
    'ownerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalDto
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalDto
     */
    'xMatrixName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetAnnualGoalDto
     */
    'qualitySemaphore'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetAnnualGoalDto
     */
    'canBeDeleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetAnnualGoalDtoControllerResponse
 */
export interface GetAnnualGoalDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetAnnualGoalDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetAnnualGoalDtoResponse}
     * @memberof GetAnnualGoalDtoControllerResponse
     */
    'responseObject'?: GetAnnualGoalDtoResponse;
}
/**
 * 
 * @export
 * @interface GetAnnualGoalDtoListControllerResponse
 */
export interface GetAnnualGoalDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetAnnualGoalDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetAnnualGoalDtoListResponse}
     * @memberof GetAnnualGoalDtoListControllerResponse
     */
    'responseObject'?: GetAnnualGoalDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetAnnualGoalDtoListResponse
 */
export interface GetAnnualGoalDtoListResponse {
    /**
     * 
     * @type {Array<GetAnnualGoalDto>}
     * @memberof GetAnnualGoalDtoListResponse
     */
    'value'?: Array<GetAnnualGoalDto> | null;
}
/**
 * 
 * @export
 * @interface GetAnnualGoalDtoResponse
 */
export interface GetAnnualGoalDtoResponse {
    /**
     * 
     * @type {GetAnnualGoalDto}
     * @memberof GetAnnualGoalDtoResponse
     */
    'value'?: GetAnnualGoalDto;
}
/**
 * 
 * @export
 * @interface GetAnualGoalDetailsDto
 */
export interface GetAnualGoalDetailsDto {
    /**
     * 
     * @type {Array<GetProjectByAnnualGoalDto>}
     * @memberof GetAnualGoalDetailsDto
     */
    'projectByAnnualGoal'?: Array<GetProjectByAnnualGoalDto> | null;
    /**
     * 
     * @type {Array<LongTermGoalByAnnualGoalResponseDto>}
     * @memberof GetAnualGoalDetailsDto
     */
    'longTermGoalByAnnualGoal'?: Array<LongTermGoalByAnnualGoalResponseDto> | null;
    /**
     * 
     * @type {GetAnnualGoalDto}
     * @memberof GetAnualGoalDetailsDto
     */
    'getAnnualGoalDto'?: GetAnnualGoalDto;
}
/**
 * 
 * @export
 * @interface GetAnualGoalDetailsDtoControllerResponse
 */
export interface GetAnualGoalDetailsDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetAnualGoalDetailsDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAnualGoalDetailsDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetAnualGoalDetailsDtoResponse}
     * @memberof GetAnualGoalDetailsDtoControllerResponse
     */
    'responseObject'?: GetAnualGoalDetailsDtoResponse;
}
/**
 * 
 * @export
 * @interface GetAnualGoalDetailsDtoResponse
 */
export interface GetAnualGoalDetailsDtoResponse {
    /**
     * 
     * @type {GetAnualGoalDetailsDto}
     * @memberof GetAnualGoalDetailsDtoResponse
     */
    'value'?: GetAnualGoalDetailsDto;
}
/**
 * 
 * @export
 * @interface GetAvailableChildCommitteesResponseDto
 */
export interface GetAvailableChildCommitteesResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetAvailableChildCommitteesResponseDto
     */
    'committeeID': string;
    /**
     * 
     * @type {string}
     * @memberof GetAvailableChildCommitteesResponseDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface GetAvailableChildCommitteesResponseDtoListControllerResponse
 */
export interface GetAvailableChildCommitteesResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetAvailableChildCommitteesResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAvailableChildCommitteesResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetAvailableChildCommitteesResponseDtoListResponse}
     * @memberof GetAvailableChildCommitteesResponseDtoListControllerResponse
     */
    'responseObject'?: GetAvailableChildCommitteesResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetAvailableChildCommitteesResponseDtoListResponse
 */
export interface GetAvailableChildCommitteesResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetAvailableChildCommitteesResponseDto>}
     * @memberof GetAvailableChildCommitteesResponseDtoListResponse
     */
    'value'?: Array<GetAvailableChildCommitteesResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetCalculationTypesByLanguageCodeResponseDto
 */
export interface GetCalculationTypesByLanguageCodeResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetCalculationTypesByLanguageCodeResponseDto
     */
    'calculationTypeID': string;
    /**
     * 
     * @type {number}
     * @memberof GetCalculationTypesByLanguageCodeResponseDto
     */
    'calculationType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetCalculationTypesByLanguageCodeResponseDto
     */
    'calculationType_Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCalculationTypesByLanguageCodeResponseDto
     */
    'language_Code'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetCalculationTypesByLanguageCodeResponseDto
     */
    'isDeleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetCalculationTypesByLanguageCodeResponseDtoListControllerResponse
 */
export interface GetCalculationTypesByLanguageCodeResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetCalculationTypesByLanguageCodeResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetCalculationTypesByLanguageCodeResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetCalculationTypesByLanguageCodeResponseDtoListResponse}
     * @memberof GetCalculationTypesByLanguageCodeResponseDtoListControllerResponse
     */
    'responseObject'?: GetCalculationTypesByLanguageCodeResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetCalculationTypesByLanguageCodeResponseDtoListResponse
 */
export interface GetCalculationTypesByLanguageCodeResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetCalculationTypesByLanguageCodeResponseDto>}
     * @memberof GetCalculationTypesByLanguageCodeResponseDtoListResponse
     */
    'value'?: Array<GetCalculationTypesByLanguageCodeResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetChildDeliverableResponseDto
 */
export interface GetChildDeliverableResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetChildDeliverableResponseDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetChildDeliverableResponseDto
     */
    'deliverableName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetChildDeliverableResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetChildDeliverableResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetChildDeliverableResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetChildDeliverableResponseDto
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetChildDeliverableResponseDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetChildDeliverableResponseDto
     */
    'ownerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetChildDeliverableResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetChildDeliverableResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetChildDeliverableResponseDto
     */
    'tag'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetChildDeliverableResponseDto
     */
    'budget'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetChildDeliverableResponseDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetChildDeliverableResponseDto
     */
    'priority'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetChildDeliverableResponseDto
     */
    'priorityDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetChildDeliverableResponseDto
     */
    'comment'?: string | null;
}
/**
 * 
 * @export
 * @interface GetChildDeliverableResponseDtoListControllerResponse
 */
export interface GetChildDeliverableResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetChildDeliverableResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetChildDeliverableResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetChildDeliverableResponseDtoListResponse}
     * @memberof GetChildDeliverableResponseDtoListControllerResponse
     */
    'responseObject'?: GetChildDeliverableResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetChildDeliverableResponseDtoListResponse
 */
export interface GetChildDeliverableResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetChildDeliverableResponseDto>}
     * @memberof GetChildDeliverableResponseDtoListResponse
     */
    'value'?: Array<GetChildDeliverableResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetCommitteeActivitiesResponseDto
 */
export interface GetCommitteeActivitiesResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeActivitiesResponseDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeActivitiesResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeActivitiesResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeActivitiesResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetCommitteeActivitiesResponseDto
     */
    'isMirrorActivity': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeActivitiesResponseDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommitteeActivitiesResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeActivitiesResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeActivitiesResponseDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeActivitiesResponseDto
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeActivitiesResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeActivitiesResponseDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeActivitiesResponseDto
     */
    'ownerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeActivitiesResponseDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeActivitiesResponseDto
     */
    'committeeName'?: string | null;
}
/**
 * 
 * @export
 * @interface GetCommitteeActivitiesResponseDtoListControllerResponse
 */
export interface GetCommitteeActivitiesResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetCommitteeActivitiesResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeActivitiesResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetCommitteeActivitiesResponseDtoListResponse}
     * @memberof GetCommitteeActivitiesResponseDtoListControllerResponse
     */
    'responseObject'?: GetCommitteeActivitiesResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetCommitteeActivitiesResponseDtoListResponse
 */
export interface GetCommitteeActivitiesResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetCommitteeActivitiesResponseDto>}
     * @memberof GetCommitteeActivitiesResponseDtoListResponse
     */
    'value'?: Array<GetCommitteeActivitiesResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetCommitteeAvailableProjectsResponseDto
 */
export interface GetCommitteeAvailableProjectsResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeAvailableProjectsResponseDto
     */
    'projectID': string;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeAvailableProjectsResponseDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeAvailableProjectsResponseDto
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeAvailableProjectsResponseDto
     */
    'committeeName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetCommitteeAvailableProjectsResponseDto
     */
    'subCommitteeProject': boolean;
}
/**
 * 
 * @export
 * @interface GetCommitteeAvailableProjectsResponseDtoListControllerResponse
 */
export interface GetCommitteeAvailableProjectsResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetCommitteeAvailableProjectsResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeAvailableProjectsResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetCommitteeAvailableProjectsResponseDtoListResponse}
     * @memberof GetCommitteeAvailableProjectsResponseDtoListControllerResponse
     */
    'responseObject'?: GetCommitteeAvailableProjectsResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetCommitteeAvailableProjectsResponseDtoListResponse
 */
export interface GetCommitteeAvailableProjectsResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetCommitteeAvailableProjectsResponseDto>}
     * @memberof GetCommitteeAvailableProjectsResponseDtoListResponse
     */
    'value'?: Array<GetCommitteeAvailableProjectsResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetCommitteePicklistDto
 */
export interface GetCommitteePicklistDto {
    /**
     * 
     * @type {string}
     * @memberof GetCommitteePicklistDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteePicklistDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommitteePicklistDto
     */
    'hasAccess': number;
}
/**
 * 
 * @export
 * @interface GetCommitteePicklistDtoListControllerResponse
 */
export interface GetCommitteePicklistDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetCommitteePicklistDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteePicklistDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetCommitteePicklistDtoListResponse}
     * @memberof GetCommitteePicklistDtoListControllerResponse
     */
    'responseObject'?: GetCommitteePicklistDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetCommitteePicklistDtoListResponse
 */
export interface GetCommitteePicklistDtoListResponse {
    /**
     * 
     * @type {Array<GetCommitteePicklistDto>}
     * @memberof GetCommitteePicklistDtoListResponse
     */
    'value'?: Array<GetCommitteePicklistDto> | null;
}
/**
 * 
 * @export
 * @interface GetCommitteeProjectsResponseDto
 */
export interface GetCommitteeProjectsResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeProjectsResponseDto
     */
    'projectID': string;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeProjectsResponseDto
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeProjectsResponseDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeProjectsResponseDto
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeProjectsResponseDto
     */
    'teamLeaderFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeProjectsResponseDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeProjectsResponseDto
     */
    'sponsorFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeProjectsResponseDto
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeProjectsResponseDto
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommitteeProjectsResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeProjectsResponseDto
     */
    'statusDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetCommitteeProjectsResponseDtoListControllerResponse
 */
export interface GetCommitteeProjectsResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetCommitteeProjectsResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeProjectsResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetCommitteeProjectsResponseDtoListResponse}
     * @memberof GetCommitteeProjectsResponseDtoListControllerResponse
     */
    'responseObject'?: GetCommitteeProjectsResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetCommitteeProjectsResponseDtoListResponse
 */
export interface GetCommitteeProjectsResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetCommitteeProjectsResponseDto>}
     * @memberof GetCommitteeProjectsResponseDtoListResponse
     */
    'value'?: Array<GetCommitteeProjectsResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetCommitteeSessionsResponseDto
 */
export interface GetCommitteeSessionsResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSessionsResponseDto
     */
    'sessionID': string;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSessionsResponseDto
     */
    'codice'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSessionsResponseDto
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSessionsResponseDto
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSessionsResponseDto
     */
    'endTime': string;
    /**
     * 
     * @type {number}
     * @memberof GetCommitteeSessionsResponseDto
     */
    'duration': number;
    /**
     * 
     * @type {number}
     * @memberof GetCommitteeSessionsResponseDto
     */
    'stateID': number;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSessionsResponseDto
     */
    'stateName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSessionsResponseDto
     */
    'agendaID': string;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSessionsResponseDto
     */
    'agendaStatus'?: string | null;
}
/**
 * 
 * @export
 * @interface GetCommitteeSessionsResponseDtoListControllerResponse
 */
export interface GetCommitteeSessionsResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetCommitteeSessionsResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSessionsResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetCommitteeSessionsResponseDtoListResponse}
     * @memberof GetCommitteeSessionsResponseDtoListControllerResponse
     */
    'responseObject'?: GetCommitteeSessionsResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetCommitteeSessionsResponseDtoListResponse
 */
export interface GetCommitteeSessionsResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetCommitteeSessionsResponseDto>}
     * @memberof GetCommitteeSessionsResponseDtoListResponse
     */
    'value'?: Array<GetCommitteeSessionsResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetCommitteeSintesiListRequest
 */
export interface GetCommitteeSintesiListRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCommitteeSintesiListRequest
     */
    'committeeIDList'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSintesiListRequest
     */
    'xmatrixID': string;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSintesiListRequest
     */
    'dateFrom': string;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSintesiListRequest
     */
    'dateTo': string;
    /**
     * 
     * @type {number}
     * @memberof GetCommitteeSintesiListRequest
     */
    'periodType': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCommitteeSintesiListRequest
     */
    'statusList'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface GetCommitteeSummaryPerStatusResponse
 */
export interface GetCommitteeSummaryPerStatusResponse {
    /**
     * 
     * @type {number}
     * @memberof GetCommitteeSummaryPerStatusResponse
     */
    'activityNumber': number;
    /**
     * 
     * @type {number}
     * @memberof GetCommitteeSummaryPerStatusResponse
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSummaryPerStatusResponse
     */
    'statusDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetCommitteeSummaryPerStatusResponseListControllerResponse
 */
export interface GetCommitteeSummaryPerStatusResponseListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetCommitteeSummaryPerStatusResponseListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSummaryPerStatusResponseListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetCommitteeSummaryPerStatusResponseListResponse}
     * @memberof GetCommitteeSummaryPerStatusResponseListControllerResponse
     */
    'responseObject'?: GetCommitteeSummaryPerStatusResponseListResponse;
}
/**
 * 
 * @export
 * @interface GetCommitteeSummaryPerStatusResponseListResponse
 */
export interface GetCommitteeSummaryPerStatusResponseListResponse {
    /**
     * 
     * @type {Array<GetCommitteeSummaryPerStatusResponse>}
     * @memberof GetCommitteeSummaryPerStatusResponseListResponse
     */
    'value'?: Array<GetCommitteeSummaryPerStatusResponse> | null;
}
/**
 * 
 * @export
 * @interface GetCommitteeSummaryPerTimeResponse
 */
export interface GetCommitteeSummaryPerTimeResponse {
    /**
     * 
     * @type {number}
     * @memberof GetCommitteeSummaryPerTimeResponse
     */
    'activityCount': number;
    /**
     * 
     * @type {number}
     * @memberof GetCommitteeSummaryPerTimeResponse
     */
    'activityStatus': number;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSummaryPerTimeResponse
     */
    'activityStatusDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetCommitteeSummaryPerTimeResponseListControllerResponse
 */
export interface GetCommitteeSummaryPerTimeResponseListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetCommitteeSummaryPerTimeResponseListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSummaryPerTimeResponseListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetCommitteeSummaryPerTimeResponseListResponse}
     * @memberof GetCommitteeSummaryPerTimeResponseListControllerResponse
     */
    'responseObject'?: GetCommitteeSummaryPerTimeResponseListResponse;
}
/**
 * 
 * @export
 * @interface GetCommitteeSummaryPerTimeResponseListResponse
 */
export interface GetCommitteeSummaryPerTimeResponseListResponse {
    /**
     * 
     * @type {Array<GetCommitteeSummaryPerTimeResponse>}
     * @memberof GetCommitteeSummaryPerTimeResponseListResponse
     */
    'value'?: Array<GetCommitteeSummaryPerTimeResponse> | null;
}
/**
 * 
 * @export
 * @interface GetCommitteeSummaryResponseDto
 */
export interface GetCommitteeSummaryResponseDto {
    /**
     * 
     * @type {number}
     * @memberof GetCommitteeSummaryResponseDto
     */
    'plannedCommitee'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommitteeSummaryResponseDto
     */
    'closedCommittee'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommitteeSummaryResponseDto
     */
    'openActivities'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSummaryResponseDto
     */
    'nextCommittee'?: string | null;
}
/**
 * 
 * @export
 * @interface GetCommitteeSummaryResponseDtoControllerResponse
 */
export interface GetCommitteeSummaryResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetCommitteeSummaryResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSummaryResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetCommitteeSummaryResponseDtoResponse}
     * @memberof GetCommitteeSummaryResponseDtoControllerResponse
     */
    'responseObject'?: GetCommitteeSummaryResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface GetCommitteeSummaryResponseDtoResponse
 */
export interface GetCommitteeSummaryResponseDtoResponse {
    /**
     * 
     * @type {GetCommitteeSummaryResponseDto}
     * @memberof GetCommitteeSummaryResponseDtoResponse
     */
    'value'?: GetCommitteeSummaryResponseDto;
}
/**
 * 
 * @export
 * @interface GetCompanyListResponseDto
 */
export interface GetCompanyListResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetCompanyListResponseDto
     */
    'companyID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyListResponseDto
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyListResponseDto
     */
    'databaseName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyListResponseDto
     */
    'activationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyListResponseDto
     */
    'deactivationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyListResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyListResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetCompanyListResponseDto
     */
    'licenseType'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCompanyListResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyListResponseDto
     */
    'licenseTypeDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyListResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyListResponseDto
     */
    'redirectLink'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyListResponseDto
     */
    'supportMail'?: string | null;
    /**
     * 
     * @type {Array<CompUsersListDto>}
     * @memberof GetCompanyListResponseDto
     */
    'companyCountUsers'?: Array<CompUsersListDto> | null;
}
/**
 * 
 * @export
 * @interface GetCompanyListResponseDtoListControllerResponse
 */
export interface GetCompanyListResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetCompanyListResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyListResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetCompanyListResponseDtoListResponse}
     * @memberof GetCompanyListResponseDtoListControllerResponse
     */
    'responseObject'?: GetCompanyListResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetCompanyListResponseDtoListResponse
 */
export interface GetCompanyListResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetCompanyListResponseDto>}
     * @memberof GetCompanyListResponseDtoListResponse
     */
    'value'?: Array<GetCompanyListResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetDeliverableListPageResponseDto
 */
export interface GetDeliverableListPageResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableListPageResponseDto
     */
    'deliverableID': string;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableListPageResponseDto
     */
    'deliverableName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetDeliverableListPageResponseDto
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableListPageResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableListPageResponseDto
     */
    'projectID': string;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableListPageResponseDto
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableListPageResponseDto
     */
    'ownerID': string;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableListPageResponseDto
     */
    'ownerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableListPageResponseDto
     */
    'rescheduledEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableListPageResponseDto
     */
    'actualEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableListPageResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableListPageResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableListPageResponseDto
     */
    'genericResponsible'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetDeliverableListPageResponseDto
     */
    'isGenericResponsabile'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetDeliverableListPageResponseDtoListControllerResponse
 */
export interface GetDeliverableListPageResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetDeliverableListPageResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableListPageResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetDeliverableListPageResponseDtoListResponse}
     * @memberof GetDeliverableListPageResponseDtoListControllerResponse
     */
    'responseObject'?: GetDeliverableListPageResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetDeliverableListPageResponseDtoListResponse
 */
export interface GetDeliverableListPageResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetDeliverableListPageResponseDto>}
     * @memberof GetDeliverableListPageResponseDtoListResponse
     */
    'value'?: Array<GetDeliverableListPageResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetDeliverablePicklistResponseDto
 */
export interface GetDeliverablePicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetDeliverablePicklistResponseDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverablePicklistResponseDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface GetDeliverablePicklistResponseDtoListControllerResponse
 */
export interface GetDeliverablePicklistResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetDeliverablePicklistResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverablePicklistResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetDeliverablePicklistResponseDtoListResponse}
     * @memberof GetDeliverablePicklistResponseDtoListControllerResponse
     */
    'responseObject'?: GetDeliverablePicklistResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetDeliverablePicklistResponseDtoListResponse
 */
export interface GetDeliverablePicklistResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetDeliverablePicklistResponseDto>}
     * @memberof GetDeliverablePicklistResponseDtoListResponse
     */
    'value'?: Array<GetDeliverablePicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetDeliverableProjectPicklistResponseDto
 */
export interface GetDeliverableProjectPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableProjectPicklistResponseDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableProjectPicklistResponseDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface GetDeliverableProjectPicklistResponseDtoListControllerResponse
 */
export interface GetDeliverableProjectPicklistResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetDeliverableProjectPicklistResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableProjectPicklistResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetDeliverableProjectPicklistResponseDtoListResponse}
     * @memberof GetDeliverableProjectPicklistResponseDtoListControllerResponse
     */
    'responseObject'?: GetDeliverableProjectPicklistResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetDeliverableProjectPicklistResponseDtoListResponse
 */
export interface GetDeliverableProjectPicklistResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetDeliverableProjectPicklistResponseDto>}
     * @memberof GetDeliverableProjectPicklistResponseDtoListResponse
     */
    'value'?: Array<GetDeliverableProjectPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetDeliverableResponseDto
 */
export interface GetDeliverableResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'deliverableName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetDeliverableResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'projectID': string;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'parentDeliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'ownerID': string;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'ownerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'tag'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetDeliverableResponseDto
     */
    'budget'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetDeliverableResponseDto
     */
    'priority'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'priorityDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'rescheduledEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'actualEndDate'?: string | null;
    /**
     * 
     * @type {Array<GetChildDeliverableResponseDto>}
     * @memberof GetDeliverableResponseDto
     */
    'childs'?: Array<GetChildDeliverableResponseDto> | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'projectStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'projectEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'parentDeliverableStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'parentDeliverableEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'genericResponsible'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetDeliverableResponseDto
     */
    'isGenericResponsabile'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetDeliverableResponseDtoControllerResponse
 */
export interface GetDeliverableResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetDeliverableResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetDeliverableResponseDtoResponse}
     * @memberof GetDeliverableResponseDtoControllerResponse
     */
    'responseObject'?: GetDeliverableResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface GetDeliverableResponseDtoResponse
 */
export interface GetDeliverableResponseDtoResponse {
    /**
     * 
     * @type {GetDeliverableResponseDto}
     * @memberof GetDeliverableResponseDtoResponse
     */
    'value'?: GetDeliverableResponseDto;
}
/**
 * 
 * @export
 * @interface GetDeliverableSummaryperStatusResponseDto
 */
export interface GetDeliverableSummaryperStatusResponseDto {
    /**
     * 
     * @type {number}
     * @memberof GetDeliverableSummaryperStatusResponseDto
     */
    'deliverableNumber'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetDeliverableSummaryperStatusResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableSummaryperStatusResponseDto
     */
    'statusDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetDeliverableSummaryperStatusResponseDtoListControllerResponse
 */
export interface GetDeliverableSummaryperStatusResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetDeliverableSummaryperStatusResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableSummaryperStatusResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetDeliverableSummaryperStatusResponseDtoListResponse}
     * @memberof GetDeliverableSummaryperStatusResponseDtoListControllerResponse
     */
    'responseObject'?: GetDeliverableSummaryperStatusResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetDeliverableSummaryperStatusResponseDtoListResponse
 */
export interface GetDeliverableSummaryperStatusResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetDeliverableSummaryperStatusResponseDto>}
     * @memberof GetDeliverableSummaryperStatusResponseDtoListResponse
     */
    'value'?: Array<GetDeliverableSummaryperStatusResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetDeliverableSummaryperTimeResponseDto
 */
export interface GetDeliverableSummaryperTimeResponseDto {
    /**
     * 
     * @type {number}
     * @memberof GetDeliverableSummaryperTimeResponseDto
     */
    'deliverableStatus'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetDeliverableSummaryperTimeResponseDto
     */
    'deliverableCount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableSummaryperTimeResponseDto
     */
    'deliverableStatusDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetDeliverableSummaryperTimeResponseDtoListControllerResponse
 */
export interface GetDeliverableSummaryperTimeResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetDeliverableSummaryperTimeResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableSummaryperTimeResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetDeliverableSummaryperTimeResponseDtoListResponse}
     * @memberof GetDeliverableSummaryperTimeResponseDtoListControllerResponse
     */
    'responseObject'?: GetDeliverableSummaryperTimeResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetDeliverableSummaryperTimeResponseDtoListResponse
 */
export interface GetDeliverableSummaryperTimeResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetDeliverableSummaryperTimeResponseDto>}
     * @memberof GetDeliverableSummaryperTimeResponseDtoListResponse
     */
    'value'?: Array<GetDeliverableSummaryperTimeResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetDivisionPicklistDto
 */
export interface GetDivisionPicklistDto {
    /**
     * 
     * @type {string}
     * @memberof GetDivisionPicklistDto
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDivisionPicklistDto
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetDivisionPicklistDto
     */
    'hasAccess': number;
}
/**
 * 
 * @export
 * @interface GetDivisionPicklistDtoListControllerResponse
 */
export interface GetDivisionPicklistDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetDivisionPicklistDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetDivisionPicklistDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetDivisionPicklistDtoListResponse}
     * @memberof GetDivisionPicklistDtoListControllerResponse
     */
    'responseObject'?: GetDivisionPicklistDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetDivisionPicklistDtoListResponse
 */
export interface GetDivisionPicklistDtoListResponse {
    /**
     * 
     * @type {Array<GetDivisionPicklistDto>}
     * @memberof GetDivisionPicklistDtoListResponse
     */
    'value'?: Array<GetDivisionPicklistDto> | null;
}
/**
 * 
 * @export
 * @interface GetDivisionProjectCreationPicklistResponseDto
 */
export interface GetDivisionProjectCreationPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetDivisionProjectCreationPicklistResponseDto
     */
    'projectID': string;
    /**
     * 
     * @type {string}
     * @memberof GetDivisionProjectCreationPicklistResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDivisionProjectCreationPicklistResponseDto
     */
    'projectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface GetDivisionProjectCreationPicklistResponseDtoListControllerResponse
 */
export interface GetDivisionProjectCreationPicklistResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetDivisionProjectCreationPicklistResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetDivisionProjectCreationPicklistResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetDivisionProjectCreationPicklistResponseDtoListResponse}
     * @memberof GetDivisionProjectCreationPicklistResponseDtoListControllerResponse
     */
    'responseObject'?: GetDivisionProjectCreationPicklistResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetDivisionProjectCreationPicklistResponseDtoListResponse
 */
export interface GetDivisionProjectCreationPicklistResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetDivisionProjectCreationPicklistResponseDto>}
     * @memberof GetDivisionProjectCreationPicklistResponseDtoListResponse
     */
    'value'?: Array<GetDivisionProjectCreationPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetFeedActivityListResponseDto
 */
export interface GetFeedActivityListResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'regardingObjectID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetFeedActivityListResponseDto
     */
    'regardingObjectTypeCode'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'createdOn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetFeedActivityListResponseDto
     */
    'activityType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'activityTypeDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'documentURL'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'scheduledStart'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'scheduledEnd'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetFeedActivityListResponseDto
     */
    'priority'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetFeedActivityListResponseDto
     */
    'totLikes'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetFeedActivityListResponseDto
     */
    'isLikedByMe': number;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'initials'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetFeedActivityListResponseDto
     */
    'isGenerated'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'startTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'endTime'?: string | null;
    /**
     * 
     * @type {Array<UsersObject>}
     * @memberof GetFeedActivityListResponseDto
     */
    'users'?: Array<UsersObject> | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetFeedActivityListResponseDto
     */
    'participationTypeMask'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetFeedActivityListResponseDto
     */
    'duration'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetFeedActivityListResponseDto
     */
    'integrateInOutlook'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'outlookEventId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetFeedActivityListResponseDto
     */
    'integrateInTeams'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'monthlyAlerts'?: string | null;
}
/**
 * 
 * @export
 * @interface GetFeedActivityListResponseDtoListControllerResponse
 */
export interface GetFeedActivityListResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetFeedActivityListResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetFeedActivityListResponseDtoListResponse}
     * @memberof GetFeedActivityListResponseDtoListControllerResponse
     */
    'responseObject'?: GetFeedActivityListResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetFeedActivityListResponseDtoListResponse
 */
export interface GetFeedActivityListResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetFeedActivityListResponseDto>}
     * @memberof GetFeedActivityListResponseDtoListResponse
     */
    'value'?: Array<GetFeedActivityListResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetFeedActivityResponseDto
 */
export interface GetFeedActivityResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityResponseDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityResponseDto
     */
    'createdOn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityResponseDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetFeedActivityResponseDto
     */
    'activityType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityResponseDto
     */
    'activityTypeDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityResponseDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityResponseDto
     */
    'initials'?: string | null;
}
/**
 * 
 * @export
 * @interface GetFeedActivityResponseDtoListControllerResponse
 */
export interface GetFeedActivityResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetFeedActivityResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetFeedActivityResponseDtoListResponse}
     * @memberof GetFeedActivityResponseDtoListControllerResponse
     */
    'responseObject'?: GetFeedActivityResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetFeedActivityResponseDtoListResponse
 */
export interface GetFeedActivityResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetFeedActivityResponseDto>}
     * @memberof GetFeedActivityResponseDtoListResponse
     */
    'value'?: Array<GetFeedActivityResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetFeedMonthlyAlertByUserResponseDto
 */
export interface GetFeedMonthlyAlertByUserResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetFeedMonthlyAlertByUserResponseDto
     */
    'monthlyAlertID': string;
    /**
     * 
     * @type {string}
     * @memberof GetFeedMonthlyAlertByUserResponseDto
     */
    'userID': string;
    /**
     * 
     * @type {string}
     * @memberof GetFeedMonthlyAlertByUserResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedMonthlyAlertByUserResponseDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedMonthlyAlertByUserResponseDto
     */
    'createdOn': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetFeedMonthlyAlertByUserResponseDto
     */
    'isReadNotification': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetFeedMonthlyAlertByUserResponseDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetFeedMonthlyAlertByUserResponseDto
     */
    'hasExpired'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedMonthlyAlertByUserResponseDto
     */
    'regardingObjectID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetFeedMonthlyAlertByUserResponseDto
     */
    'regardinObjectTypeCode'?: number | null;
}
/**
 * 
 * @export
 * @interface GetFeedMonthlyAlertByUserResponseDtoListControllerResponse
 */
export interface GetFeedMonthlyAlertByUserResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetFeedMonthlyAlertByUserResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetFeedMonthlyAlertByUserResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetFeedMonthlyAlertByUserResponseDtoListResponse}
     * @memberof GetFeedMonthlyAlertByUserResponseDtoListControllerResponse
     */
    'responseObject'?: GetFeedMonthlyAlertByUserResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetFeedMonthlyAlertByUserResponseDtoListResponse
 */
export interface GetFeedMonthlyAlertByUserResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetFeedMonthlyAlertByUserResponseDto>}
     * @memberof GetFeedMonthlyAlertByUserResponseDtoListResponse
     */
    'value'?: Array<GetFeedMonthlyAlertByUserResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetFeedSummaryResponseDto
 */
export interface GetFeedSummaryResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetFeedSummaryResponseDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedSummaryResponseDto
     */
    'regardingObjectID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetFeedSummaryResponseDto
     */
    'regardingObjectTypeCode'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedSummaryResponseDto
     */
    'createdOn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedSummaryResponseDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetFeedSummaryResponseDto
     */
    'activityType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedSummaryResponseDto
     */
    'activityTypeDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedSummaryResponseDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedSummaryResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedSummaryResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedSummaryResponseDto
     */
    'documentURL'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedSummaryResponseDto
     */
    'scheduledStart'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedSummaryResponseDto
     */
    'scheduledEnd'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetFeedSummaryResponseDto
     */
    'priority'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetFeedSummaryResponseDto
     */
    'totLikes'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetFeedSummaryResponseDto
     */
    'isLikedByMe': number;
    /**
     * 
     * @type {string}
     * @memberof GetFeedSummaryResponseDto
     */
    'initials'?: string | null;
}
/**
 * 
 * @export
 * @interface GetFeedSummaryResponseDtoListControllerResponse
 */
export interface GetFeedSummaryResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetFeedSummaryResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetFeedSummaryResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetFeedSummaryResponseDtoListResponse}
     * @memberof GetFeedSummaryResponseDtoListControllerResponse
     */
    'responseObject'?: GetFeedSummaryResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetFeedSummaryResponseDtoListResponse
 */
export interface GetFeedSummaryResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetFeedSummaryResponseDto>}
     * @memberof GetFeedSummaryResponseDtoListResponse
     */
    'value'?: Array<GetFeedSummaryResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetFilterbyXMatrixRequest
 */
export interface GetFilterbyXMatrixRequest {
    /**
     * 
     * @type {Array<string | null>}
     * @memberof GetFilterbyXMatrixRequest
     */
    'userID'?: Array<string | null> | null;
    /**
     * 
     * @type {Array<string | null>}
     * @memberof GetFilterbyXMatrixRequest
     */
    'divisionID'?: Array<string | null> | null;
    /**
     * 
     * @type {Array<string | null>}
     * @memberof GetFilterbyXMatrixRequest
     */
    'committeeID'?: Array<string | null> | null;
    /**
     * 
     * @type {string}
     * @memberof GetFilterbyXMatrixRequest
     */
    'languageCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFilterbyXMatrixRequest
     */
    'userRole'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFilterbyXMatrixRequest
     */
    'xMatrixID'?: string | null;
}
/**
 * 
 * @export
 * @interface GetFollowUpSectionA3ResponseDto
 */
export interface GetFollowUpSectionA3ResponseDto {
    /**
     * 
     * @type {number}
     * @memberof GetFollowUpSectionA3ResponseDto
     */
    'actual'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetFollowUpSectionA3ResponseDto
     */
    'monthReference'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetFollowUpSectionA3ResponseDto
     */
    'targetYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetFollowUpSectionA3ResponseDto
     */
    'ytd'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetFollowUpSectionA3ResponseDto
     */
    'calculationType': number;
    /**
     * 
     * @type {number}
     * @memberof GetFollowUpSectionA3ResponseDto
     */
    'parentType': number;
}
/**
 * 
 * @export
 * @interface GetFollowUpSectionA3ResponseDtoListControllerResponse
 */
export interface GetFollowUpSectionA3ResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetFollowUpSectionA3ResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetFollowUpSectionA3ResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetFollowUpSectionA3ResponseDtoListResponse}
     * @memberof GetFollowUpSectionA3ResponseDtoListControllerResponse
     */
    'responseObject'?: GetFollowUpSectionA3ResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetFollowUpSectionA3ResponseDtoListResponse
 */
export interface GetFollowUpSectionA3ResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetFollowUpSectionA3ResponseDto>}
     * @memberof GetFollowUpSectionA3ResponseDtoListResponse
     */
    'value'?: Array<GetFollowUpSectionA3ResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetFullProfileDto
 */
export interface GetFullProfileDto {
    /**
     * 
     * @type {UserProfileResponseDto}
     * @memberof GetFullProfileDto
     */
    'userProfile'?: UserProfileResponseDto;
}
/**
 * 
 * @export
 * @interface GetFullProfileDtoControllerResponse
 */
export interface GetFullProfileDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetFullProfileDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetFullProfileDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetFullProfileDtoResponse}
     * @memberof GetFullProfileDtoControllerResponse
     */
    'responseObject'?: GetFullProfileDtoResponse;
}
/**
 * 
 * @export
 * @interface GetFullProfileDtoResponse
 */
export interface GetFullProfileDtoResponse {
    /**
     * 
     * @type {GetFullProfileDto}
     * @memberof GetFullProfileDtoResponse
     */
    'value'?: GetFullProfileDto;
}
/**
 * 
 * @export
 * @interface GetGoalCategoryByIDResponseDto
 */
export interface GetGoalCategoryByIDResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetGoalCategoryByIDResponseDto
     */
    'goalCategoryID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetGoalCategoryByIDResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetGoalCategoryByIDResponseDto
     */
    'goalCategoryIconID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetGoalCategoryByIDResponseDto
     */
    'iconSource'?: string | null;
}
/**
 * 
 * @export
 * @interface GetGoalCategoryByIDResponseDtoControllerResponse
 */
export interface GetGoalCategoryByIDResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetGoalCategoryByIDResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetGoalCategoryByIDResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetGoalCategoryByIDResponseDtoResponse}
     * @memberof GetGoalCategoryByIDResponseDtoControllerResponse
     */
    'responseObject'?: GetGoalCategoryByIDResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface GetGoalCategoryByIDResponseDtoResponse
 */
export interface GetGoalCategoryByIDResponseDtoResponse {
    /**
     * 
     * @type {GetGoalCategoryByIDResponseDto}
     * @memberof GetGoalCategoryByIDResponseDtoResponse
     */
    'value'?: GetGoalCategoryByIDResponseDto;
}
/**
 * 
 * @export
 * @interface GetGoalCategoryResponseDto
 */
export interface GetGoalCategoryResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetGoalCategoryResponseDto
     */
    'goalCategoryID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetGoalCategoryResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetGoalCategoryResponseDto
     */
    'goalCategoryIconID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetGoalCategoryResponseDto
     */
    'iconSource'?: string | null;
}
/**
 * 
 * @export
 * @interface GetGoalCategoryResponseDtoListControllerResponse
 */
export interface GetGoalCategoryResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetGoalCategoryResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetGoalCategoryResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetGoalCategoryResponseDtoListResponse}
     * @memberof GetGoalCategoryResponseDtoListControllerResponse
     */
    'responseObject'?: GetGoalCategoryResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetGoalCategoryResponseDtoListResponse
 */
export interface GetGoalCategoryResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetGoalCategoryResponseDto>}
     * @memberof GetGoalCategoryResponseDtoListResponse
     */
    'value'?: Array<GetGoalCategoryResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetGoalGroundtoActA3ResponseDto
 */
export interface GetGoalGroundtoActA3ResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetGoalGroundtoActA3ResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetGoalGroundtoActA3ResponseDto
     */
    'projectGoal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetGoalGroundtoActA3ResponseDto
     */
    'groundtoAct'?: string | null;
}
/**
 * 
 * @export
 * @interface GetGoalGroundtoActA3ResponseDtoControllerResponse
 */
export interface GetGoalGroundtoActA3ResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetGoalGroundtoActA3ResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetGoalGroundtoActA3ResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetGoalGroundtoActA3ResponseDtoResponse}
     * @memberof GetGoalGroundtoActA3ResponseDtoControllerResponse
     */
    'responseObject'?: GetGoalGroundtoActA3ResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface GetGoalGroundtoActA3ResponseDtoResponse
 */
export interface GetGoalGroundtoActA3ResponseDtoResponse {
    /**
     * 
     * @type {GetGoalGroundtoActA3ResponseDto}
     * @memberof GetGoalGroundtoActA3ResponseDtoResponse
     */
    'value'?: GetGoalGroundtoActA3ResponseDto;
}
/**
 * 
 * @export
 * @interface GetGoalTypePicklistDto
 */
export interface GetGoalTypePicklistDto {
    /**
     * 
     * @type {string}
     * @memberof GetGoalTypePicklistDto
     */
    'goalTypeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetGoalTypePicklistDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface GetGoalTypePicklistDtoListControllerResponse
 */
export interface GetGoalTypePicklistDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetGoalTypePicklistDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetGoalTypePicklistDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetGoalTypePicklistDtoListResponse}
     * @memberof GetGoalTypePicklistDtoListControllerResponse
     */
    'responseObject'?: GetGoalTypePicklistDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetGoalTypePicklistDtoListResponse
 */
export interface GetGoalTypePicklistDtoListResponse {
    /**
     * 
     * @type {Array<GetGoalTypePicklistDto>}
     * @memberof GetGoalTypePicklistDtoListResponse
     */
    'value'?: Array<GetGoalTypePicklistDto> | null;
}
/**
 * 
 * @export
 * @interface GetImplementationSectionA3ResponseDto
 */
export interface GetImplementationSectionA3ResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetImplementationSectionA3ResponseDto
     */
    'obtainedBenefit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetImplementationSectionA3ResponseDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetImplementationSectionA3ResponseDto
     */
    'finishedActivitiesNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetImplementationSectionA3ResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetImplementationSectionA3ResponseDto
     */
    'timeSemaphore'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetImplementationSectionA3ResponseDto
     */
    'budgetSemaphore'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetImplementationSectionA3ResponseDto
     */
    'qualitySemaphore'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetImplementationSectionA3ResponseDto
     */
    'budgetSemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetImplementationSectionA3ResponseDto
     */
    'budgetSemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetImplementationSectionA3ResponseDto
     */
    'qualitySemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetImplementationSectionA3ResponseDto
     */
    'qualitySemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetImplementationSectionA3ResponseDto
     */
    'timeSemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetImplementationSectionA3ResponseDto
     */
    'timeSemaphoreDenominator'?: number | null;
}
/**
 * 
 * @export
 * @interface GetImplementationSectionA3ResponseDtoControllerResponse
 */
export interface GetImplementationSectionA3ResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetImplementationSectionA3ResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetImplementationSectionA3ResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetImplementationSectionA3ResponseDtoResponse}
     * @memberof GetImplementationSectionA3ResponseDtoControllerResponse
     */
    'responseObject'?: GetImplementationSectionA3ResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface GetImplementationSectionA3ResponseDtoResponse
 */
export interface GetImplementationSectionA3ResponseDtoResponse {
    /**
     * 
     * @type {GetImplementationSectionA3ResponseDto}
     * @memberof GetImplementationSectionA3ResponseDtoResponse
     */
    'value'?: GetImplementationSectionA3ResponseDto;
}
/**
 * 
 * @export
 * @interface GetInsertKPIResponseDto
 */
export interface GetInsertKPIResponseDto {
    /**
     * 
     * @type {number}
     * @memberof GetInsertKPIResponseDto
     */
    'parentType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetInsertKPIResponseDto
     */
    'parentTypeDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetInsertKPIResponseDto
     */
    'jumpOffPoint'?: number | null;
}
/**
 * 
 * @export
 * @interface GetInsertKPIResponseDtoControllerResponse
 */
export interface GetInsertKPIResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetInsertKPIResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetInsertKPIResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetInsertKPIResponseDtoResponse}
     * @memberof GetInsertKPIResponseDtoControllerResponse
     */
    'responseObject'?: GetInsertKPIResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface GetInsertKPIResponseDtoResponse
 */
export interface GetInsertKPIResponseDtoResponse {
    /**
     * 
     * @type {GetInsertKPIResponseDto}
     * @memberof GetInsertKPIResponseDtoResponse
     */
    'value'?: GetInsertKPIResponseDto;
}
/**
 * 
 * @export
 * @interface GetKPIAnnualGoalPicklistResponseDto
 */
export interface GetKPIAnnualGoalPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetKPIAnnualGoalPicklistResponseDto
     */
    'kpiDetailID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIAnnualGoalPicklistResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIAnnualGoalPicklistResponseDto
     */
    'globaleType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIAnnualGoalPicklistResponseDto
     */
    'puntualeType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIAnnualGoalPicklistResponseDto
     */
    'isFavourite': boolean;
}
/**
 * 
 * @export
 * @interface GetKPIAnnualGoalPicklistResponseDtoListControllerResponse
 */
export interface GetKPIAnnualGoalPicklistResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIAnnualGoalPicklistResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetKPIAnnualGoalPicklistResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetKPIAnnualGoalPicklistResponseDtoListResponse}
     * @memberof GetKPIAnnualGoalPicklistResponseDtoListControllerResponse
     */
    'responseObject'?: GetKPIAnnualGoalPicklistResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetKPIAnnualGoalPicklistResponseDtoListResponse
 */
export interface GetKPIAnnualGoalPicklistResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetKPIAnnualGoalPicklistResponseDto>}
     * @memberof GetKPIAnnualGoalPicklistResponseDtoListResponse
     */
    'value'?: Array<GetKPIAnnualGoalPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetKPIByAnnualGoalResponseDto
 */
export interface GetKPIByAnnualGoalResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'target'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'annualGoalKPIRelationShipID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'parentType': number;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'parentTypeDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'calculationType': number;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'calculationTypeDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'kpiType': number;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'kpiTypeDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'updateYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'targetYTD'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'showInA3'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'isRollupKPI'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'parentKPIDetailID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'kpiDetailID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'toDisplay': number;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'isFavourite': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'freezeTargetValues': boolean;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'firstYearOfPlanning'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'responsibleID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'responsibleName'?: string | null;
}
/**
 * 
 * @export
 * @interface GetKPIByAnnualGoalResponseDtoListControllerResponse
 */
export interface GetKPIByAnnualGoalResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIByAnnualGoalResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByAnnualGoalResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetKPIByAnnualGoalResponseDtoListResponse}
     * @memberof GetKPIByAnnualGoalResponseDtoListControllerResponse
     */
    'responseObject'?: GetKPIByAnnualGoalResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetKPIByAnnualGoalResponseDtoListResponse
 */
export interface GetKPIByAnnualGoalResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetKPIByAnnualGoalResponseDto>}
     * @memberof GetKPIByAnnualGoalResponseDtoListResponse
     */
    'value'?: Array<GetKPIByAnnualGoalResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetKPIByLongTermGoalResponseDto
 */
export interface GetKPIByLongTermGoalResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetKPIByLongTermGoalResponseDto
     */
    'kpiid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByLongTermGoalResponseDto
     */
    'kpiDetailID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByLongTermGoalResponseDto
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByLongTermGoalResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByLongTermGoalResponseDto
     */
    'target'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByLongTermGoalResponseDto
     */
    'actualYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByLongTermGoalResponseDto
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByLongTermGoalResponseDto
     */
    'correlationDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIByLongTermGoalResponseDto
     */
    'isFavourite': boolean;
}
/**
 * 
 * @export
 * @interface GetKPIByProjectResponseDto
 */
export interface GetKPIByProjectResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetKPIByProjectResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByProjectResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByProjectResponseDto
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByProjectResponseDto
     */
    'target'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByProjectResponseDto
     */
    'targetYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByProjectResponseDto
     */
    'updateYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByProjectResponseDto
     */
    'actual'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByProjectResponseDto
     */
    'kpiProjectRelationShipID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByProjectResponseDto
     */
    'parentType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByProjectResponseDto
     */
    'parentTypeDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByProjectResponseDto
     */
    'calculationTypeDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByProjectResponseDto
     */
    'calculationType'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByProjectResponseDto
     */
    'kpiType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByProjectResponseDto
     */
    'kpiTypeDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIByProjectResponseDto
     */
    'showInA3'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIByProjectResponseDto
     */
    'isRollupKPI'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByProjectResponseDto
     */
    'parentKPIDetailId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByProjectResponseDto
     */
    'kpiDetailID': string;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByProjectResponseDto
     */
    'toDisplay'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIByProjectResponseDto
     */
    'isFavourite'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByProjectResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByProjectResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIByProjectResponseDto
     */
    'freezeTargetValues'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByProjectResponseDto
     */
    'semaforo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByProjectResponseDto
     */
    'responsibleID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByProjectResponseDto
     */
    'responsibleName'?: string | null;
}
/**
 * 
 * @export
 * @interface GetKPIByProjectResponseDtoListControllerResponse
 */
export interface GetKPIByProjectResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIByProjectResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByProjectResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetKPIByProjectResponseDtoListResponse}
     * @memberof GetKPIByProjectResponseDtoListControllerResponse
     */
    'responseObject'?: GetKPIByProjectResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetKPIByProjectResponseDtoListResponse
 */
export interface GetKPIByProjectResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetKPIByProjectResponseDto>}
     * @memberof GetKPIByProjectResponseDtoListResponse
     */
    'value'?: Array<GetKPIByProjectResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetKPIChildPicklistResponse
 */
export interface GetKPIChildPicklistResponse {
    /**
     * 
     * @type {string}
     * @memberof GetKPIChildPicklistResponse
     */
    'kpiDetailID': string;
    /**
     * 
     * @type {string}
     * @memberof GetKPIChildPicklistResponse
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface GetKPIChildPicklistResponseListControllerResponse
 */
export interface GetKPIChildPicklistResponseListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIChildPicklistResponseListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetKPIChildPicklistResponseListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetKPIChildPicklistResponseListResponse}
     * @memberof GetKPIChildPicklistResponseListControllerResponse
     */
    'responseObject'?: GetKPIChildPicklistResponseListResponse;
}
/**
 * 
 * @export
 * @interface GetKPIChildPicklistResponseListResponse
 */
export interface GetKPIChildPicklistResponseListResponse {
    /**
     * 
     * @type {Array<GetKPIChildPicklistResponse>}
     * @memberof GetKPIChildPicklistResponseListResponse
     */
    'value'?: Array<GetKPIChildPicklistResponse> | null;
}
/**
 * 
 * @export
 * @interface GetKPICommentResponse
 */
export interface GetKPICommentResponse {
    /**
     * 
     * @type {string}
     * @memberof GetKPICommentResponse
     */
    'comment'?: string | null;
}
/**
 * 
 * @export
 * @interface GetKPICommentResponseControllerResponse
 */
export interface GetKPICommentResponseControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetKPICommentResponseControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetKPICommentResponseControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetKPICommentResponseResponse}
     * @memberof GetKPICommentResponseControllerResponse
     */
    'responseObject'?: GetKPICommentResponseResponse;
}
/**
 * 
 * @export
 * @interface GetKPICommentResponseResponse
 */
export interface GetKPICommentResponseResponse {
    /**
     * 
     * @type {GetKPICommentResponse}
     * @memberof GetKPICommentResponseResponse
     */
    'value'?: GetKPICommentResponse;
}
/**
 * 
 * @export
 * @interface GetKPIDashboardSintesiByAnnualGoalResponseDto
 */
export interface GetKPIDashboardSintesiByAnnualGoalResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetKPIDashboardSintesiByAnnualGoalResponseDto
     */
    'kpiDetailID': string;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDashboardSintesiByAnnualGoalResponseDto
     */
    'kpiName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDashboardSintesiByAnnualGoalResponseDto
     */
    'measureUnitName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDashboardSintesiByAnnualGoalResponseDto
     */
    'targetYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDashboardSintesiByAnnualGoalResponseDto
     */
    'actualYTD'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDashboardSintesiByAnnualGoalResponseDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDashboardSintesiByAnnualGoalResponseDto
     */
    'finalTarget'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDashboardSintesiByAnnualGoalResponseDto
     */
    'semaforo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDashboardSintesiByAnnualGoalResponseDto
     */
    'parentID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDashboardSintesiByAnnualGoalResponseDto
     */
    'toDisplay': number;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDashboardSintesiByAnnualGoalResponseDto
     */
    'kpiType': number;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDashboardSintesiByAnnualGoalResponseDto
     */
    'kpiTypeDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetKPIDashboardSintesiByAnnualGoalResponseDtoListControllerResponse
 */
export interface GetKPIDashboardSintesiByAnnualGoalResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIDashboardSintesiByAnnualGoalResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDashboardSintesiByAnnualGoalResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetKPIDashboardSintesiByAnnualGoalResponseDtoListResponse}
     * @memberof GetKPIDashboardSintesiByAnnualGoalResponseDtoListControllerResponse
     */
    'responseObject'?: GetKPIDashboardSintesiByAnnualGoalResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetKPIDashboardSintesiByAnnualGoalResponseDtoListResponse
 */
export interface GetKPIDashboardSintesiByAnnualGoalResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetKPIDashboardSintesiByAnnualGoalResponseDto>}
     * @memberof GetKPIDashboardSintesiByAnnualGoalResponseDtoListResponse
     */
    'value'?: Array<GetKPIDashboardSintesiByAnnualGoalResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetKPIDashboardSintesiByProjectResponseDto
 */
export interface GetKPIDashboardSintesiByProjectResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetKPIDashboardSintesiByProjectResponseDto
     */
    'kpiDetailID': string;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDashboardSintesiByProjectResponseDto
     */
    'kpiName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDashboardSintesiByProjectResponseDto
     */
    'measureUnitName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDashboardSintesiByProjectResponseDto
     */
    'targetYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDashboardSintesiByProjectResponseDto
     */
    'actualYTD'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDashboardSintesiByProjectResponseDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDashboardSintesiByProjectResponseDto
     */
    'finalTarget'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDashboardSintesiByProjectResponseDto
     */
    'semaforo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDashboardSintesiByProjectResponseDto
     */
    'parentID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDashboardSintesiByProjectResponseDto
     */
    'toDisplay': number;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDashboardSintesiByProjectResponseDto
     */
    'kpiType': number;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDashboardSintesiByProjectResponseDto
     */
    'kpiTypeDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetKPIDashboardSintesiByProjectResponseDtoListControllerResponse
 */
export interface GetKPIDashboardSintesiByProjectResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIDashboardSintesiByProjectResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDashboardSintesiByProjectResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetKPIDashboardSintesiByProjectResponseDtoListResponse}
     * @memberof GetKPIDashboardSintesiByProjectResponseDtoListControllerResponse
     */
    'responseObject'?: GetKPIDashboardSintesiByProjectResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetKPIDashboardSintesiByProjectResponseDtoListResponse
 */
export interface GetKPIDashboardSintesiByProjectResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetKPIDashboardSintesiByProjectResponseDto>}
     * @memberof GetKPIDashboardSintesiByProjectResponseDtoListResponse
     */
    'value'?: Array<GetKPIDashboardSintesiByProjectResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetKPIDetailDto
 */
export interface GetKPIDetailDto {
    /**
     * 
     * @type {number}
     * @memberof GetKPIDetailDto
     */
    'calculationType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDetailDto
     */
    'additionalNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDetailDto
     */
    'unitOfMeasure'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDetailDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDetailDto
     */
    'kpiDetailID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDetailDto
     */
    'calculationTypeDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDetailDto
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDetailDto
     */
    'actualYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDetailDto
     */
    'type'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDetailDto
     */
    'typeDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDetailDto
     */
    'toBeDeleted'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDetailDto
     */
    'parentType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDetailDto
     */
    'parentTypeDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIDetailDto
     */
    'showInA3'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIDetailDto
     */
    'isRollupKPI'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIDetailDto
     */
    'freezeTargetValues'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDetailDto
     */
    'firstYearOfPlanning'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDetailDto
     */
    'responsibleID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDetailDto
     */
    'responsibleName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIDetailDto
     */
    'isVisible': boolean;
}
/**
 * 
 * @export
 * @interface GetKPIDetailDtoControllerResponse
 */
export interface GetKPIDetailDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIDetailDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDetailDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetKPIDetailDtoResponse}
     * @memberof GetKPIDetailDtoControllerResponse
     */
    'responseObject'?: GetKPIDetailDtoResponse;
}
/**
 * 
 * @export
 * @interface GetKPIDetailDtoResponse
 */
export interface GetKPIDetailDtoResponse {
    /**
     * 
     * @type {GetKPIDetailDto}
     * @memberof GetKPIDetailDtoResponse
     */
    'value'?: GetKPIDetailDto;
}
/**
 * 
 * @export
 * @interface GetKPIGerarchicoInfoDTO
 */
export interface GetKPIGerarchicoInfoDTO {
    /**
     * 
     * @type {string}
     * @memberof GetKPIGerarchicoInfoDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetKPIGerarchicoInfoDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIGerarchicoInfoDTO
     */
    'code'?: string | null;
}
/**
 * 
 * @export
 * @interface GetKPIGerarchicoInfoDTOListControllerResponse
 */
export interface GetKPIGerarchicoInfoDTOListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIGerarchicoInfoDTOListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetKPIGerarchicoInfoDTOListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetKPIGerarchicoInfoDTOListResponse}
     * @memberof GetKPIGerarchicoInfoDTOListControllerResponse
     */
    'responseObject'?: GetKPIGerarchicoInfoDTOListResponse;
}
/**
 * 
 * @export
 * @interface GetKPIGerarchicoInfoDTOListResponse
 */
export interface GetKPIGerarchicoInfoDTOListResponse {
    /**
     * 
     * @type {Array<GetKPIGerarchicoInfoDTO>}
     * @memberof GetKPIGerarchicoInfoDTOListResponse
     */
    'value'?: Array<GetKPIGerarchicoInfoDTO> | null;
}
/**
 * 
 * @export
 * @interface GetKPIGraphicResponseDto
 */
export interface GetKPIGraphicResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetKPIGraphicResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetKPIGraphicResponseDto
     */
    'kpiDetailID': string;
    /**
     * 
     * @type {string}
     * @memberof GetKPIGraphicResponseDto
     */
    'kpiName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIGraphicResponseDto
     */
    'measureUnitName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIGraphicResponseDto
     */
    'targetYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIGraphicResponseDto
     */
    'actualYTD'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIGraphicResponseDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIGraphicResponseDto
     */
    'finalTarget'?: number | null;
}
/**
 * 
 * @export
 * @interface GetKPIGraphicResponseDtoListControllerResponse
 */
export interface GetKPIGraphicResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIGraphicResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetKPIGraphicResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetKPIGraphicResponseDtoListResponse}
     * @memberof GetKPIGraphicResponseDtoListControllerResponse
     */
    'responseObject'?: GetKPIGraphicResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetKPIGraphicResponseDtoListResponse
 */
export interface GetKPIGraphicResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetKPIGraphicResponseDto>}
     * @memberof GetKPIGraphicResponseDtoListResponse
     */
    'value'?: Array<GetKPIGraphicResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetKPIListDto
 */
export interface GetKPIListDto {
    /**
     * 
     * @type {string}
     * @memberof GetKPIListDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIListDto
     */
    'kpiDetailID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIListDto
     */
    'parentTypeDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIListDto
     */
    'calculationTypeDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIListDto
     */
    'typeDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIListDto
     */
    'isRollupKPI'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIListDto
     */
    'isFavourite': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIListDto
     */
    'isVisible': boolean;
}
/**
 * 
 * @export
 * @interface GetKPIListDtoListControllerResponse
 */
export interface GetKPIListDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIListDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetKPIListDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetKPIListDtoListResponse}
     * @memberof GetKPIListDtoListControllerResponse
     */
    'responseObject'?: GetKPIListDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetKPIListDtoListResponse
 */
export interface GetKPIListDtoListResponse {
    /**
     * 
     * @type {Array<GetKPIListDto>}
     * @memberof GetKPIListDtoListResponse
     */
    'value'?: Array<GetKPIListDto> | null;
}
/**
 * 
 * @export
 * @interface GetKPIListPageDto
 */
export interface GetKPIListPageDto {
    /**
     * 
     * @type {number}
     * @memberof GetKPIListPageDto
     */
    'calculationType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIListPageDto
     */
    'additionalNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIListPageDto
     */
    'unitOfMeasure'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIListPageDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIListPageDto
     */
    'targetYTD'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIListPageDto
     */
    'parentKPIID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIListPageDto
     */
    'parentKPIName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIListPageDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIListPageDto
     */
    'actualYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIListPageDto
     */
    'target'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIListPageDto
     */
    'kpiDetailID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIListPageDto
     */
    'jumpOffPoint'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIListPageDto
     */
    'kpiid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIListPageDto
     */
    'statusDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetKPIListPageDtoControllerResponse
 */
export interface GetKPIListPageDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIListPageDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetKPIListPageDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetKPIListPageDtoResponse}
     * @memberof GetKPIListPageDtoControllerResponse
     */
    'responseObject'?: GetKPIListPageDtoResponse;
}
/**
 * 
 * @export
 * @interface GetKPIListPageDtoResponse
 */
export interface GetKPIListPageDtoResponse {
    /**
     * 
     * @type {GetKPIListPageDto}
     * @memberof GetKPIListPageDtoResponse
     */
    'value'?: GetKPIListPageDto;
}
/**
 * 
 * @export
 * @interface GetKPIPicklistA3ResponseDto
 */
export interface GetKPIPicklistA3ResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetKPIPicklistA3ResponseDto
     */
    'kpiDetailID': string;
    /**
     * 
     * @type {string}
     * @memberof GetKPIPicklistA3ResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIPicklistA3ResponseDto
     */
    'isFavourite': boolean;
}
/**
 * 
 * @export
 * @interface GetKPIPicklistA3ResponseDtoListControllerResponse
 */
export interface GetKPIPicklistA3ResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIPicklistA3ResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetKPIPicklistA3ResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetKPIPicklistA3ResponseDtoListResponse}
     * @memberof GetKPIPicklistA3ResponseDtoListControllerResponse
     */
    'responseObject'?: GetKPIPicklistA3ResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetKPIPicklistA3ResponseDtoListResponse
 */
export interface GetKPIPicklistA3ResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetKPIPicklistA3ResponseDto>}
     * @memberof GetKPIPicklistA3ResponseDtoListResponse
     */
    'value'?: Array<GetKPIPicklistA3ResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetKPIProjectPicklistResponseDto
 */
export interface GetKPIProjectPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetKPIProjectPicklistResponseDto
     */
    'kpiDetailID': string;
    /**
     * 
     * @type {string}
     * @memberof GetKPIProjectPicklistResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIProjectPicklistResponseDto
     */
    'globaleType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIProjectPicklistResponseDto
     */
    'puntualeType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIProjectPicklistResponseDto
     */
    'isRollupKPI'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIProjectPicklistResponseDto
     */
    'isFavourite': boolean;
}
/**
 * 
 * @export
 * @interface GetKPIProjectPicklistResponseDtoListControllerResponse
 */
export interface GetKPIProjectPicklistResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIProjectPicklistResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetKPIProjectPicklistResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetKPIProjectPicklistResponseDtoListResponse}
     * @memberof GetKPIProjectPicklistResponseDtoListControllerResponse
     */
    'responseObject'?: GetKPIProjectPicklistResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetKPIProjectPicklistResponseDtoListResponse
 */
export interface GetKPIProjectPicklistResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetKPIProjectPicklistResponseDto>}
     * @memberof GetKPIProjectPicklistResponseDtoListResponse
     */
    'value'?: Array<GetKPIProjectPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetKPISectionA3ResponseDto
 */
export interface GetKPISectionA3ResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetKPISectionA3ResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPISectionA3ResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPISectionA3ResponseDto
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPISectionA3ResponseDto
     */
    'target'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPISectionA3ResponseDto
     */
    'targetYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPISectionA3ResponseDto
     */
    'updateYTD'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPISectionA3ResponseDto
     */
    'kpiProjectRelationShipID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPISectionA3ResponseDto
     */
    'showInA3': number;
    /**
     * 
     * @type {string}
     * @memberof GetKPISectionA3ResponseDto
     */
    'parentKPIDetailID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPISectionA3ResponseDto
     */
    'kpiDetailID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPISectionA3ResponseDto
     */
    'toDisplay': number;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPISectionA3ResponseDto
     */
    'isRollupKPI'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPISectionA3ResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPISectionA3ResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPISectionA3ResponseDto
     */
    'parentType'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPISectionA3ResponseDto
     */
    'type'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPISectionA3ResponseDto
     */
    'actual'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPISectionA3ResponseDto
     */
    'calculationType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPISectionA3ResponseDto
     */
    'semaforo'?: string | null;
}
/**
 * 
 * @export
 * @interface GetKPISectionA3ResponseDtoListControllerResponse
 */
export interface GetKPISectionA3ResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetKPISectionA3ResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetKPISectionA3ResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetKPISectionA3ResponseDtoListResponse}
     * @memberof GetKPISectionA3ResponseDtoListControllerResponse
     */
    'responseObject'?: GetKPISectionA3ResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetKPISectionA3ResponseDtoListResponse
 */
export interface GetKPISectionA3ResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetKPISectionA3ResponseDto>}
     * @memberof GetKPISectionA3ResponseDtoListResponse
     */
    'value'?: Array<GetKPISectionA3ResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetKPISummaryResponseDto
 */
export interface GetKPISummaryResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetKPISummaryResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPISummaryResponseDto
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPISummaryResponseDto
     */
    'target'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPISummaryResponseDto
     */
    'actualYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPISummaryResponseDto
     */
    'targetYTD'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPISummaryResponseDto
     */
    'kpiid'?: string | null;
}
/**
 * 
 * @export
 * @interface GetKPISummaryResponseDtoListControllerResponse
 */
export interface GetKPISummaryResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetKPISummaryResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetKPISummaryResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetKPISummaryResponseDtoListResponse}
     * @memberof GetKPISummaryResponseDtoListControllerResponse
     */
    'responseObject'?: GetKPISummaryResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetKPISummaryResponseDtoListResponse
 */
export interface GetKPISummaryResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetKPISummaryResponseDto>}
     * @memberof GetKPISummaryResponseDtoListResponse
     */
    'value'?: Array<GetKPISummaryResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetKPIXMatrixPicklistResponseDto
 */
export interface GetKPIXMatrixPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetKPIXMatrixPicklistResponseDto
     */
    'kpiDetailID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIXMatrixPicklistResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIXMatrixPicklistResponseDto
     */
    'globaleType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIXMatrixPicklistResponseDto
     */
    'puntualeType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIXMatrixPicklistResponseDto
     */
    'isFavourite': boolean;
}
/**
 * 
 * @export
 * @interface GetKPIXMatrixPicklistResponseDtoListControllerResponse
 */
export interface GetKPIXMatrixPicklistResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIXMatrixPicklistResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetKPIXMatrixPicklistResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetKPIXMatrixPicklistResponseDtoListResponse}
     * @memberof GetKPIXMatrixPicklistResponseDtoListControllerResponse
     */
    'responseObject'?: GetKPIXMatrixPicklistResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetKPIXMatrixPicklistResponseDtoListResponse
 */
export interface GetKPIXMatrixPicklistResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetKPIXMatrixPicklistResponseDto>}
     * @memberof GetKPIXMatrixPicklistResponseDtoListResponse
     */
    'value'?: Array<GetKPIXMatrixPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetKPIsDetailDto
 */
export interface GetKPIsDetailDto {
    /**
     * 
     * @type {Array<GetProjectByKPIResponseDto>}
     * @memberof GetKPIsDetailDto
     */
    'getProjectByKPI'?: Array<GetProjectByKPIResponseDto> | null;
    /**
     * 
     * @type {Array<GetLongTermGoalByKPIResponseDto>}
     * @memberof GetKPIsDetailDto
     */
    'getLongTermGoalByKPI'?: Array<GetLongTermGoalByKPIResponseDto> | null;
    /**
     * 
     * @type {GetKPIDetailDto}
     * @memberof GetKPIsDetailDto
     */
    'getKPIDetail'?: GetKPIDetailDto;
}
/**
 * 
 * @export
 * @interface GetKPIsDetailDtoControllerResponse
 */
export interface GetKPIsDetailDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIsDetailDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetKPIsDetailDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetKPIsDetailDtoResponse}
     * @memberof GetKPIsDetailDtoControllerResponse
     */
    'responseObject'?: GetKPIsDetailDtoResponse;
}
/**
 * 
 * @export
 * @interface GetKPIsDetailDtoResponse
 */
export interface GetKPIsDetailDtoResponse {
    /**
     * 
     * @type {GetKPIsDetailDto}
     * @memberof GetKPIsDetailDtoResponse
     */
    'value'?: GetKPIsDetailDto;
}
/**
 * 
 * @export
 * @interface GetLicenseTypesByLanguageCodeResponseDto
 */
export interface GetLicenseTypesByLanguageCodeResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetLicenseTypesByLanguageCodeResponseDto
     */
    'licenseID': string;
    /**
     * 
     * @type {number}
     * @memberof GetLicenseTypesByLanguageCodeResponseDto
     */
    'licenseType': number;
    /**
     * 
     * @type {string}
     * @memberof GetLicenseTypesByLanguageCodeResponseDto
     */
    'licenseName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLicenseTypesByLanguageCodeResponseDto
     */
    'language_Code'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetLicenseTypesByLanguageCodeResponseDto
     */
    'isDeleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetLicenseTypesByLanguageCodeResponseDtoListControllerResponse
 */
export interface GetLicenseTypesByLanguageCodeResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetLicenseTypesByLanguageCodeResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetLicenseTypesByLanguageCodeResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetLicenseTypesByLanguageCodeResponseDtoListResponse}
     * @memberof GetLicenseTypesByLanguageCodeResponseDtoListControllerResponse
     */
    'responseObject'?: GetLicenseTypesByLanguageCodeResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetLicenseTypesByLanguageCodeResponseDtoListResponse
 */
export interface GetLicenseTypesByLanguageCodeResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetLicenseTypesByLanguageCodeResponseDto>}
     * @memberof GetLicenseTypesByLanguageCodeResponseDtoListResponse
     */
    'value'?: Array<GetLicenseTypesByLanguageCodeResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetLongTermGoalByKPIResponseDto
 */
export interface GetLongTermGoalByKPIResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetLongTermGoalByKPIResponseDto
     */
    'longTermGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLongTermGoalByKPIResponseDto
     */
    'longTermGoalDesription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLongTermGoalByKPIResponseDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLongTermGoalByKPIResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetLongTermGoalByKPIResponseDto
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetLongTermGoalByKPIResponseDto
     */
    'correlationDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetLongTermGoalByKPIResponseDtoListControllerResponse
 */
export interface GetLongTermGoalByKPIResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetLongTermGoalByKPIResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetLongTermGoalByKPIResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetLongTermGoalByKPIResponseDtoListResponse}
     * @memberof GetLongTermGoalByKPIResponseDtoListControllerResponse
     */
    'responseObject'?: GetLongTermGoalByKPIResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetLongTermGoalByKPIResponseDtoListResponse
 */
export interface GetLongTermGoalByKPIResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetLongTermGoalByKPIResponseDto>}
     * @memberof GetLongTermGoalByKPIResponseDtoListResponse
     */
    'value'?: Array<GetLongTermGoalByKPIResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetLongTermGoalChecklistResponseDto
 */
export interface GetLongTermGoalChecklistResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetLongTermGoalChecklistResponseDto
     */
    'miglioramentoSignificativo'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetLongTermGoalChecklistResponseDto
     */
    'raggiungimentoVision'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetLongTermGoalChecklistResponseDto
     */
    'strategiaSostenibile'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetLongTermGoalChecklistResponseDto
     */
    'innovazioneBusiness'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetLongTermGoalChecklistResponseDto
     */
    'vincoliNormative'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetLongTermGoalChecklistResponseDtoControllerResponse
 */
export interface GetLongTermGoalChecklistResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetLongTermGoalChecklistResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetLongTermGoalChecklistResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetLongTermGoalChecklistResponseDtoResponse}
     * @memberof GetLongTermGoalChecklistResponseDtoControllerResponse
     */
    'responseObject'?: GetLongTermGoalChecklistResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface GetLongTermGoalChecklistResponseDtoResponse
 */
export interface GetLongTermGoalChecklistResponseDtoResponse {
    /**
     * 
     * @type {GetLongTermGoalChecklistResponseDto}
     * @memberof GetLongTermGoalChecklistResponseDtoResponse
     */
    'value'?: GetLongTermGoalChecklistResponseDto;
}
/**
 * 
 * @export
 * @interface GetLongTermGoalTypeByLanguageCodeResponseDto
 */
export interface GetLongTermGoalTypeByLanguageCodeResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetLongTermGoalTypeByLanguageCodeResponseDto
     */
    'longTermGoalTypeID': string;
    /**
     * 
     * @type {string}
     * @memberof GetLongTermGoalTypeByLanguageCodeResponseDto
     */
    'longTermGoalID': string;
    /**
     * 
     * @type {string}
     * @memberof GetLongTermGoalTypeByLanguageCodeResponseDto
     */
    'longTermGoal_Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLongTermGoalTypeByLanguageCodeResponseDto
     */
    'language_Code'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetLongTermGoalTypeByLanguageCodeResponseDto
     */
    'isDeleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetLongTermGoalTypeByLanguageCodeResponseDtoListControllerResponse
 */
export interface GetLongTermGoalTypeByLanguageCodeResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetLongTermGoalTypeByLanguageCodeResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetLongTermGoalTypeByLanguageCodeResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetLongTermGoalTypeByLanguageCodeResponseDtoListResponse}
     * @memberof GetLongTermGoalTypeByLanguageCodeResponseDtoListControllerResponse
     */
    'responseObject'?: GetLongTermGoalTypeByLanguageCodeResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetLongTermGoalTypeByLanguageCodeResponseDtoListResponse
 */
export interface GetLongTermGoalTypeByLanguageCodeResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetLongTermGoalTypeByLanguageCodeResponseDto>}
     * @memberof GetLongTermGoalTypeByLanguageCodeResponseDtoListResponse
     */
    'value'?: Array<GetLongTermGoalTypeByLanguageCodeResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetMacroProjectResponseDto
 */
export interface GetMacroProjectResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetMacroProjectResponseDto
     */
    'macroProjectID': string;
    /**
     * 
     * @type {string}
     * @memberof GetMacroProjectResponseDto
     */
    'macroProjectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMacroProjectResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetMacroProjectResponseDto
     */
    'isDeleted': boolean;
}
/**
 * 
 * @export
 * @interface GetMacroProjectResponseDtoControllerResponse
 */
export interface GetMacroProjectResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetMacroProjectResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMacroProjectResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetMacroProjectResponseDtoResponse}
     * @memberof GetMacroProjectResponseDtoControllerResponse
     */
    'responseObject'?: GetMacroProjectResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface GetMacroProjectResponseDtoResponse
 */
export interface GetMacroProjectResponseDtoResponse {
    /**
     * 
     * @type {GetMacroProjectResponseDto}
     * @memberof GetMacroProjectResponseDtoResponse
     */
    'value'?: GetMacroProjectResponseDto;
}
/**
 * 
 * @export
 * @interface GetMasterPlanDeliverableResponseDto
 */
export interface GetMasterPlanDeliverableResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetMasterPlanDeliverableResponseDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMasterPlanDeliverableResponseDto
     */
    'deliverableTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMasterPlanDeliverableResponseDto
     */
    'deliverableSubtitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMasterPlanDeliverableResponseDto
     */
    'monthValue': string;
    /**
     * 
     * @type {number}
     * @memberof GetMasterPlanDeliverableResponseDto
     */
    'deliverableStatus': number;
    /**
     * 
     * @type {string}
     * @memberof GetMasterPlanDeliverableResponseDto
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof GetMasterPlanDeliverableResponseDto
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof GetMasterPlanDeliverableResponseDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMasterPlanDeliverableResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMasterPlanDeliverableResponseDto
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof GetMasterPlanDeliverableResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMasterPlanDeliverableResponseDto
     */
    'projectStartDate': string;
    /**
     * 
     * @type {string}
     * @memberof GetMasterPlanDeliverableResponseDto
     */
    'projectEndDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetMasterPlanDeliverableResponseDto
     */
    'hasAccess'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetMasterPlanDeliverableResponseDtoListControllerResponse
 */
export interface GetMasterPlanDeliverableResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetMasterPlanDeliverableResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMasterPlanDeliverableResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetMasterPlanDeliverableResponseDtoListResponse}
     * @memberof GetMasterPlanDeliverableResponseDtoListControllerResponse
     */
    'responseObject'?: GetMasterPlanDeliverableResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetMasterPlanDeliverableResponseDtoListResponse
 */
export interface GetMasterPlanDeliverableResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetMasterPlanDeliverableResponseDto>}
     * @memberof GetMasterPlanDeliverableResponseDtoListResponse
     */
    'value'?: Array<GetMasterPlanDeliverableResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetMasterplanParentDeliverableResponseDto
 */
export interface GetMasterplanParentDeliverableResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableResponseDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {{ [key: string]: any | null; }}
     * @memberof GetMasterplanParentDeliverableResponseDto
     */
    'months'?: { [key: string]: any | null; } | null;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableResponseDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableResponseDto
     */
    'deliverableStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMasterplanParentDeliverableResponseDto
     */
    'statusID'?: number | null;
}
/**
 * 
 * @export
 * @interface GetMasterplanParentDeliverableResponseDtoListControllerResponse
 */
export interface GetMasterplanParentDeliverableResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetMasterplanParentDeliverableResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetMasterplanParentDeliverableResponseDtoListResponse}
     * @memberof GetMasterplanParentDeliverableResponseDtoListControllerResponse
     */
    'responseObject'?: GetMasterplanParentDeliverableResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetMasterplanParentDeliverableResponseDtoListResponse
 */
export interface GetMasterplanParentDeliverableResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetMasterplanParentDeliverableResponseDto>}
     * @memberof GetMasterplanParentDeliverableResponseDtoListResponse
     */
    'value'?: Array<GetMasterplanParentDeliverableResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetMasterplanParentDeliverableTestResponseDto
 */
export interface GetMasterplanParentDeliverableTestResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableTestResponseDto
     */
    'deliverableID': string;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableTestResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableTestResponseDto
     */
    'monthValue': string;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableTestResponseDto
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableTestResponseDto
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableTestResponseDto
     */
    'ownerID': string;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableTestResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMasterplanParentDeliverableTestResponseDto
     */
    'statusID': number;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableTestResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableTestResponseDto
     */
    'deliverableMonthList'?: string | null;
}
/**
 * 
 * @export
 * @interface GetMasterplanParentDeliverableTestResponseDtoListControllerResponse
 */
export interface GetMasterplanParentDeliverableTestResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetMasterplanParentDeliverableTestResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableTestResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetMasterplanParentDeliverableTestResponseDtoListResponse}
     * @memberof GetMasterplanParentDeliverableTestResponseDtoListControllerResponse
     */
    'responseObject'?: GetMasterplanParentDeliverableTestResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetMasterplanParentDeliverableTestResponseDtoListResponse
 */
export interface GetMasterplanParentDeliverableTestResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetMasterplanParentDeliverableTestResponseDto>}
     * @memberof GetMasterplanParentDeliverableTestResponseDtoListResponse
     */
    'value'?: Array<GetMasterplanParentDeliverableTestResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetMembersListA3ResponseDto
 */
export interface GetMembersListA3ResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetMembersListA3ResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMembersListA3ResponseDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMembersListA3ResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMembersListA3ResponseDto
     */
    'onCall'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetMembersListA3ResponseDto
     */
    'userInitials'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetMembersListA3ResponseDto
     */
    'isDeleted': boolean;
}
/**
 * 
 * @export
 * @interface GetMembersListA3ResponseDtoListControllerResponse
 */
export interface GetMembersListA3ResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetMembersListA3ResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMembersListA3ResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetMembersListA3ResponseDtoListResponse}
     * @memberof GetMembersListA3ResponseDtoListControllerResponse
     */
    'responseObject'?: GetMembersListA3ResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetMembersListA3ResponseDtoListResponse
 */
export interface GetMembersListA3ResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetMembersListA3ResponseDto>}
     * @memberof GetMembersListA3ResponseDtoListResponse
     */
    'value'?: Array<GetMembersListA3ResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetMenuItemResponse
 */
export interface GetMenuItemResponse {
    /**
     * 
     * @type {string}
     * @memberof GetMenuItemResponse
     */
    'menuID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMenuItemResponse
     */
    'menuDisplayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMenuItemResponse
     */
    'menuLink'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMenuItemResponse
     */
    'orderingValueMenu'?: number | null;
    /**
     * 
     * @type {Array<ChildMenuItem>}
     * @memberof GetMenuItemResponse
     */
    'menuItems'?: Array<ChildMenuItem> | null;
}
/**
 * 
 * @export
 * @interface GetMenuItemResponseListControllerResponse
 */
export interface GetMenuItemResponseListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetMenuItemResponseListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMenuItemResponseListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetMenuItemResponseListResponse}
     * @memberof GetMenuItemResponseListControllerResponse
     */
    'responseObject'?: GetMenuItemResponseListResponse;
}
/**
 * 
 * @export
 * @interface GetMenuItemResponseListResponse
 */
export interface GetMenuItemResponseListResponse {
    /**
     * 
     * @type {Array<GetMenuItemResponse>}
     * @memberof GetMenuItemResponseListResponse
     */
    'value'?: Array<GetMenuItemResponse> | null;
}
/**
 * 
 * @export
 * @interface GetMonthlyAlertByUserResponseDto
 */
export interface GetMonthlyAlertByUserResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetMonthlyAlertByUserResponseDto
     */
    'monthlyAlertID': string;
    /**
     * 
     * @type {string}
     * @memberof GetMonthlyAlertByUserResponseDto
     */
    'userID': string;
    /**
     * 
     * @type {string}
     * @memberof GetMonthlyAlertByUserResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMonthlyAlertByUserResponseDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMonthlyAlertByUserResponseDto
     */
    'createdOn': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetMonthlyAlertByUserResponseDto
     */
    'isReadNotification': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMonthlyAlertByUserResponseDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetMonthlyAlertByUserResponseDto
     */
    'hasExpired'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetMonthlyAlertByUserResponseDto
     */
    'regardingObjectID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMonthlyAlertByUserResponseDto
     */
    'regardinObjectTypeCode'?: number | null;
}
/**
 * 
 * @export
 * @interface GetMonthlyAlertByUserResponseDtoListControllerResponse
 */
export interface GetMonthlyAlertByUserResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetMonthlyAlertByUserResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMonthlyAlertByUserResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetMonthlyAlertByUserResponseDtoListResponse}
     * @memberof GetMonthlyAlertByUserResponseDtoListControllerResponse
     */
    'responseObject'?: GetMonthlyAlertByUserResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetMonthlyAlertByUserResponseDtoListResponse
 */
export interface GetMonthlyAlertByUserResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetMonthlyAlertByUserResponseDto>}
     * @memberof GetMonthlyAlertByUserResponseDtoListResponse
     */
    'value'?: Array<GetMonthlyAlertByUserResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetMyAcivityResponseDto
 */
export interface GetMyAcivityResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetMyAcivityResponseDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyAcivityResponseDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyAcivityResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyAcivityResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyAcivityResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyAcivityResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyAcivityResponseDto
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyAcivityResponseDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyAcivityResponseDto
     */
    'deliverableName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMyAcivityResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyAcivityResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyAcivityResponseDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyAcivityResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyAcivityResponseDto
     */
    'actualCloseDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetMyAcivityResponseDto
     */
    'isGenerated'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetMyAcivityResponseDtoControllerResponse
 */
export interface GetMyAcivityResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetMyAcivityResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMyAcivityResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetMyAcivityResponseDtoResponse}
     * @memberof GetMyAcivityResponseDtoControllerResponse
     */
    'responseObject'?: GetMyAcivityResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface GetMyAcivityResponseDtoResponse
 */
export interface GetMyAcivityResponseDtoResponse {
    /**
     * 
     * @type {GetMyAcivityResponseDto}
     * @memberof GetMyAcivityResponseDtoResponse
     */
    'value'?: GetMyAcivityResponseDto;
}
/**
 * 
 * @export
 * @interface GetMyActivityDeliverableFormResponseDto
 */
export interface GetMyActivityDeliverableFormResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'isRelatedToProject'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'actualCloseDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'isGenerated'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'genericResponsible'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'isGenericResponsabile'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'activityType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'parentDeliverableID'?: string | null;
}
/**
 * 
 * @export
 * @interface GetMyActivityDeliverableFormResponseDtoListControllerResponse
 */
export interface GetMyActivityDeliverableFormResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetMyActivityDeliverableFormResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityDeliverableFormResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetMyActivityDeliverableFormResponseDtoListResponse}
     * @memberof GetMyActivityDeliverableFormResponseDtoListControllerResponse
     */
    'responseObject'?: GetMyActivityDeliverableFormResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetMyActivityDeliverableFormResponseDtoListResponse
 */
export interface GetMyActivityDeliverableFormResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetMyActivityDeliverableFormResponseDto>}
     * @memberof GetMyActivityDeliverableFormResponseDtoListResponse
     */
    'value'?: Array<GetMyActivityDeliverableFormResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetMyActivityMenuFormPerUserResponseDto
 */
export interface GetMyActivityMenuFormPerUserResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'isRelatedToProject'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'actualCloseDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'deliverableName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'isGenerated'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'deliverableStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'deliverableEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'projectStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'projectEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'parentDeliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'parentDeliverableStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'parentDeliverableEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'genericResponsible'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'isGenericResponsabile'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'projectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface GetMyActivityMenuFormPerUserResponseDtoListControllerResponse
 */
export interface GetMyActivityMenuFormPerUserResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetMyActivityMenuFormPerUserResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetMyActivityMenuFormPerUserResponseDtoListResponse}
     * @memberof GetMyActivityMenuFormPerUserResponseDtoListControllerResponse
     */
    'responseObject'?: GetMyActivityMenuFormPerUserResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetMyActivityMenuFormPerUserResponseDtoListResponse
 */
export interface GetMyActivityMenuFormPerUserResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetMyActivityMenuFormPerUserResponseDto>}
     * @memberof GetMyActivityMenuFormPerUserResponseDtoListResponse
     */
    'value'?: Array<GetMyActivityMenuFormPerUserResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetMyActivityMenuFormResponseDto
 */
export interface GetMyActivityMenuFormResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'isRelatedToProject'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'actualCloseDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'deliverableName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'isGenerated'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'deliverableStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'deliverableEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'projectStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'projectEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'parentDeliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'parentDeliverableStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'parentDeliverableEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'genericResponsible'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'isGenericResponsabile'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'committeeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'activityType'?: string | null;
}
/**
 * 
 * @export
 * @interface GetMyActivityMenuFormResponseDtoListControllerResponse
 */
export interface GetMyActivityMenuFormResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetMyActivityMenuFormResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetMyActivityMenuFormResponseDtoListResponse}
     * @memberof GetMyActivityMenuFormResponseDtoListControllerResponse
     */
    'responseObject'?: GetMyActivityMenuFormResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetMyActivityMenuFormResponseDtoListResponse
 */
export interface GetMyActivityMenuFormResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetMyActivityMenuFormResponseDto>}
     * @memberof GetMyActivityMenuFormResponseDtoListResponse
     */
    'value'?: Array<GetMyActivityMenuFormResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetMyActivityProjectFormrResponseDto
 */
export interface GetMyActivityProjectFormrResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'isRelatedToProject'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'actualCloseDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'deliverableName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'isGenerated'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'deliverableStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'deliverableEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'parentDeliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'parentDeliverableStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'parentDeliverableEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'genericResponsible'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'isGenericResponsabile'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'activityType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'hasAccess'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetMyActivityProjectFormrResponseDtoListControllerResponse
 */
export interface GetMyActivityProjectFormrResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetMyActivityProjectFormrResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetMyActivityProjectFormrResponseDtoListResponse}
     * @memberof GetMyActivityProjectFormrResponseDtoListControllerResponse
     */
    'responseObject'?: GetMyActivityProjectFormrResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetMyActivityProjectFormrResponseDtoListResponse
 */
export interface GetMyActivityProjectFormrResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetMyActivityProjectFormrResponseDto>}
     * @memberof GetMyActivityProjectFormrResponseDtoListResponse
     */
    'value'?: Array<GetMyActivityProjectFormrResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetNewDivisionSponsorPicklistResponseDto
 */
export interface GetNewDivisionSponsorPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetNewDivisionSponsorPicklistResponseDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNewDivisionSponsorPicklistResponseDto
     */
    'fullName'?: string | null;
}
/**
 * 
 * @export
 * @interface GetNewDivisionSponsorPicklistResponseDtoListControllerResponse
 */
export interface GetNewDivisionSponsorPicklistResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetNewDivisionSponsorPicklistResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetNewDivisionSponsorPicklistResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetNewDivisionSponsorPicklistResponseDtoListResponse}
     * @memberof GetNewDivisionSponsorPicklistResponseDtoListControllerResponse
     */
    'responseObject'?: GetNewDivisionSponsorPicklistResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetNewDivisionSponsorPicklistResponseDtoListResponse
 */
export interface GetNewDivisionSponsorPicklistResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetNewDivisionSponsorPicklistResponseDto>}
     * @memberof GetNewDivisionSponsorPicklistResponseDtoListResponse
     */
    'value'?: Array<GetNewDivisionSponsorPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetNewDivisionTeamLeaderPicklistResponseDto
 */
export interface GetNewDivisionTeamLeaderPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetNewDivisionTeamLeaderPicklistResponseDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNewDivisionTeamLeaderPicklistResponseDto
     */
    'fullName'?: string | null;
}
/**
 * 
 * @export
 * @interface GetNewDivisionTeamLeaderPicklistResponseDtoListControllerResponse
 */
export interface GetNewDivisionTeamLeaderPicklistResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetNewDivisionTeamLeaderPicklistResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetNewDivisionTeamLeaderPicklistResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetNewDivisionTeamLeaderPicklistResponseDtoListResponse}
     * @memberof GetNewDivisionTeamLeaderPicklistResponseDtoListControllerResponse
     */
    'responseObject'?: GetNewDivisionTeamLeaderPicklistResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetNewDivisionTeamLeaderPicklistResponseDtoListResponse
 */
export interface GetNewDivisionTeamLeaderPicklistResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetNewDivisionTeamLeaderPicklistResponseDto>}
     * @memberof GetNewDivisionTeamLeaderPicklistResponseDtoListResponse
     */
    'value'?: Array<GetNewDivisionTeamLeaderPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetNewDivisionXMatrixAnnualGoalPicklistResponseDto
 */
export interface GetNewDivisionXMatrixAnnualGoalPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetNewDivisionXMatrixAnnualGoalPicklistResponseDto
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNewDivisionXMatrixAnnualGoalPicklistResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetNewDivisionXMatrixAnnualGoalPicklistResponseDto
     */
    'orderingValue': number;
}
/**
 * 
 * @export
 * @interface GetNewDivisionXMatrixAnnualGoalPicklistResponseDtoListControllerResponse
 */
export interface GetNewDivisionXMatrixAnnualGoalPicklistResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetNewDivisionXMatrixAnnualGoalPicklistResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetNewDivisionXMatrixAnnualGoalPicklistResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetNewDivisionXMatrixAnnualGoalPicklistResponseDtoListResponse}
     * @memberof GetNewDivisionXMatrixAnnualGoalPicklistResponseDtoListControllerResponse
     */
    'responseObject'?: GetNewDivisionXMatrixAnnualGoalPicklistResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetNewDivisionXMatrixAnnualGoalPicklistResponseDtoListResponse
 */
export interface GetNewDivisionXMatrixAnnualGoalPicklistResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetNewDivisionXMatrixAnnualGoalPicklistResponseDto>}
     * @memberof GetNewDivisionXMatrixAnnualGoalPicklistResponseDtoListResponse
     */
    'value'?: Array<GetNewDivisionXMatrixAnnualGoalPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetNewDivisionXMatrixProjectPicklistResponseDto
 */
export interface GetNewDivisionXMatrixProjectPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetNewDivisionXMatrixProjectPicklistResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNewDivisionXMatrixProjectPicklistResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNewDivisionXMatrixProjectPicklistResponseDto
     */
    'projectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface GetNewDivisionXMatrixProjectPicklistResponseDtoListControllerResponse
 */
export interface GetNewDivisionXMatrixProjectPicklistResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetNewDivisionXMatrixProjectPicklistResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetNewDivisionXMatrixProjectPicklistResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetNewDivisionXMatrixProjectPicklistResponseDtoListResponse}
     * @memberof GetNewDivisionXMatrixProjectPicklistResponseDtoListControllerResponse
     */
    'responseObject'?: GetNewDivisionXMatrixProjectPicklistResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetNewDivisionXMatrixProjectPicklistResponseDtoListResponse
 */
export interface GetNewDivisionXMatrixProjectPicklistResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetNewDivisionXMatrixProjectPicklistResponseDto>}
     * @memberof GetNewDivisionXMatrixProjectPicklistResponseDtoListResponse
     */
    'value'?: Array<GetNewDivisionXMatrixProjectPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetNewDivisionXMatrixSecondaryProjectPicklistResponseDto
 */
export interface GetNewDivisionXMatrixSecondaryProjectPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetNewDivisionXMatrixSecondaryProjectPicklistResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNewDivisionXMatrixSecondaryProjectPicklistResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNewDivisionXMatrixSecondaryProjectPicklistResponseDto
     */
    'projectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface GetNewDivisionXMatrixSecondaryProjectPicklistResponseDtoListControllerResponse
 */
export interface GetNewDivisionXMatrixSecondaryProjectPicklistResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetNewDivisionXMatrixSecondaryProjectPicklistResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetNewDivisionXMatrixSecondaryProjectPicklistResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetNewDivisionXMatrixSecondaryProjectPicklistResponseDtoListResponse}
     * @memberof GetNewDivisionXMatrixSecondaryProjectPicklistResponseDtoListControllerResponse
     */
    'responseObject'?: GetNewDivisionXMatrixSecondaryProjectPicklistResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetNewDivisionXMatrixSecondaryProjectPicklistResponseDtoListResponse
 */
export interface GetNewDivisionXMatrixSecondaryProjectPicklistResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetNewDivisionXMatrixSecondaryProjectPicklistResponseDto>}
     * @memberof GetNewDivisionXMatrixSecondaryProjectPicklistResponseDtoListResponse
     */
    'value'?: Array<GetNewDivisionXMatrixSecondaryProjectPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetNewXmatrixAnnualGoalPicklistResponseDto
 */
export interface GetNewXmatrixAnnualGoalPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetNewXmatrixAnnualGoalPicklistResponseDto
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNewXmatrixAnnualGoalPicklistResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetNewXmatrixAnnualGoalPicklistResponseDto
     */
    'orderingValue': number;
}
/**
 * 
 * @export
 * @interface GetNewXmatrixAnnualGoalPicklistResponseDtoListControllerResponse
 */
export interface GetNewXmatrixAnnualGoalPicklistResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetNewXmatrixAnnualGoalPicklistResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetNewXmatrixAnnualGoalPicklistResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetNewXmatrixAnnualGoalPicklistResponseDtoListResponse}
     * @memberof GetNewXmatrixAnnualGoalPicklistResponseDtoListControllerResponse
     */
    'responseObject'?: GetNewXmatrixAnnualGoalPicklistResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetNewXmatrixAnnualGoalPicklistResponseDtoListResponse
 */
export interface GetNewXmatrixAnnualGoalPicklistResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetNewXmatrixAnnualGoalPicklistResponseDto>}
     * @memberof GetNewXmatrixAnnualGoalPicklistResponseDtoListResponse
     */
    'value'?: Array<GetNewXmatrixAnnualGoalPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetNewXmatrixProjectPicklistResponseDto
 */
export interface GetNewXmatrixProjectPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetNewXmatrixProjectPicklistResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNewXmatrixProjectPicklistResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNewXmatrixProjectPicklistResponseDto
     */
    'projectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface GetNewXmatrixProjectPicklistResponseDtoListControllerResponse
 */
export interface GetNewXmatrixProjectPicklistResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetNewXmatrixProjectPicklistResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetNewXmatrixProjectPicklistResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetNewXmatrixProjectPicklistResponseDtoListResponse}
     * @memberof GetNewXmatrixProjectPicklistResponseDtoListControllerResponse
     */
    'responseObject'?: GetNewXmatrixProjectPicklistResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetNewXmatrixProjectPicklistResponseDtoListResponse
 */
export interface GetNewXmatrixProjectPicklistResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetNewXmatrixProjectPicklistResponseDto>}
     * @memberof GetNewXmatrixProjectPicklistResponseDtoListResponse
     */
    'value'?: Array<GetNewXmatrixProjectPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetNextStepsSectionA3ResponseDto
 */
export interface GetNextStepsSectionA3ResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetNextStepsSectionA3ResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNextStepsSectionA3ResponseDto
     */
    'hanseiLearnedLessons'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNextStepsSectionA3ResponseDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNextStepsSectionA3ResponseDto
     */
    'nextStepsNote'?: string | null;
}
/**
 * 
 * @export
 * @interface GetNextStepsSectionA3ResponseDtoControllerResponse
 */
export interface GetNextStepsSectionA3ResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetNextStepsSectionA3ResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetNextStepsSectionA3ResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetNextStepsSectionA3ResponseDtoResponse}
     * @memberof GetNextStepsSectionA3ResponseDtoControllerResponse
     */
    'responseObject'?: GetNextStepsSectionA3ResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface GetNextStepsSectionA3ResponseDtoResponse
 */
export interface GetNextStepsSectionA3ResponseDtoResponse {
    /**
     * 
     * @type {GetNextStepsSectionA3ResponseDto}
     * @memberof GetNextStepsSectionA3ResponseDtoResponse
     */
    'value'?: GetNextStepsSectionA3ResponseDto;
}
/**
 * 
 * @export
 * @interface GetNoCommitteeProjectsResponseDto
 */
export interface GetNoCommitteeProjectsResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetNoCommitteeProjectsResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNoCommitteeProjectsResponseDto
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNoCommitteeProjectsResponseDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNoCommitteeProjectsResponseDto
     */
    'teamLeaderId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNoCommitteeProjectsResponseDto
     */
    'teamLeaderName'?: string | null;
}
/**
 * 
 * @export
 * @interface GetNoCommitteeProjectsResponseDtoListControllerResponse
 */
export interface GetNoCommitteeProjectsResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetNoCommitteeProjectsResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetNoCommitteeProjectsResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetNoCommitteeProjectsResponseDtoListResponse}
     * @memberof GetNoCommitteeProjectsResponseDtoListControllerResponse
     */
    'responseObject'?: GetNoCommitteeProjectsResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetNoCommitteeProjectsResponseDtoListResponse
 */
export interface GetNoCommitteeProjectsResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetNoCommitteeProjectsResponseDto>}
     * @memberof GetNoCommitteeProjectsResponseDtoListResponse
     */
    'value'?: Array<GetNoCommitteeProjectsResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetParentProjectPicklistDto
 */
export interface GetParentProjectPicklistDto {
    /**
     * 
     * @type {string}
     * @memberof GetParentProjectPicklistDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetParentProjectPicklistDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface GetParentProjectPicklistDtoListControllerResponse
 */
export interface GetParentProjectPicklistDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetParentProjectPicklistDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetParentProjectPicklistDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetParentProjectPicklistDtoListResponse}
     * @memberof GetParentProjectPicklistDtoListControllerResponse
     */
    'responseObject'?: GetParentProjectPicklistDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetParentProjectPicklistDtoListResponse
 */
export interface GetParentProjectPicklistDtoListResponse {
    /**
     * 
     * @type {Array<GetParentProjectPicklistDto>}
     * @memberof GetParentProjectPicklistDtoListResponse
     */
    'value'?: Array<GetParentProjectPicklistDto> | null;
}
/**
 * 
 * @export
 * @interface GetProjectByAnnualGoalDto
 */
export interface GetProjectByAnnualGoalDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'parentProjectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'parentProjectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectByAnnualGoalDto
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectByAnnualGoalDto
     */
    'hasExternalReview'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectByAnnualGoalDto
     */
    'budget'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'teamLeaderFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'sponsorFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'committeeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'projectGoal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'groundtoAct'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'hanseiLearnedLessons'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'nextStepsNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'obtainedBenefit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'finishedActivitiesNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectByAnnualGoalDto
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'correlationDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetProjectByKPIResponseDto
 */
export interface GetProjectByKPIResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'parentProjectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'parentProjectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectByKPIResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectByKPIResponseDto
     */
    'hasExternalReview'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectByKPIResponseDto
     */
    'budget'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'teamLeaderFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'sponsorFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'committeeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'projectGoal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'groundtoAct'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'hanseiLearnedLessons'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'nextStepsNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'obtainedBenefit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'finishedActivitiesNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'kpiid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectByKPIResponseDto
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'correlationDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetProjectByLongTermGoalDto
 */
export interface GetProjectByLongTermGoalDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'parentProjectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'parentProjectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectByLongTermGoalDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectByLongTermGoalDto
     */
    'hasExternalReview'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectByLongTermGoalDto
     */
    'budget'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'teamLeaderFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'sponsorFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'committeeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'projectGoal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'groundtoAct'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'hanseiLearnedLessons'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'nextStepsNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'obtainedBenefit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'finishedActivitiesNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'longTermGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'projectID'?: string | null;
}
/**
 * 
 * @export
 * @interface GetProjectChildsResponseDto
 */
export interface GetProjectChildsResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectChildsResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectChildsResponseDto
     */
    'projectID': string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectChildsResponseDto
     */
    'projectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface GetProjectChildsResponseDtoListControllerResponse
 */
export interface GetProjectChildsResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectChildsResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetProjectChildsResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetProjectChildsResponseDtoListResponse}
     * @memberof GetProjectChildsResponseDtoListControllerResponse
     */
    'responseObject'?: GetProjectChildsResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetProjectChildsResponseDtoListResponse
 */
export interface GetProjectChildsResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetProjectChildsResponseDto>}
     * @memberof GetProjectChildsResponseDtoListResponse
     */
    'value'?: Array<GetProjectChildsResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetProjectCodesResponseDto
 */
export interface GetProjectCodesResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectCodesResponseDto
     */
    'projectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface GetProjectCodesResponseDtoListControllerResponse
 */
export interface GetProjectCodesResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectCodesResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCodesResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetProjectCodesResponseDtoListResponse}
     * @memberof GetProjectCodesResponseDtoListControllerResponse
     */
    'responseObject'?: GetProjectCodesResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetProjectCodesResponseDtoListResponse
 */
export interface GetProjectCodesResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetProjectCodesResponseDto>}
     * @memberof GetProjectCodesResponseDtoListResponse
     */
    'value'?: Array<GetProjectCodesResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetProjectCompletedActivitiesA3ResponseDto
 */
export interface GetProjectCompletedActivitiesA3ResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectCompletedActivitiesA3ResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCompletedActivitiesA3ResponseDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCompletedActivitiesA3ResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCompletedActivitiesA3ResponseDto
     */
    'owner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCompletedActivitiesA3ResponseDto
     */
    'ownerFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCompletedActivitiesA3ResponseDto
     */
    'ownerFullNameInitials'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCompletedActivitiesA3ResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCompletedActivitiesA3ResponseDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectCompletedActivitiesA3ResponseDto
     */
    'deliverableStatus'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCompletedActivitiesA3ResponseDto
     */
    'genericResponsible'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectCompletedActivitiesA3ResponseDto
     */
    'isGenericResponsabile'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetProjectCompletedActivitiesA3ResponseDtoListControllerResponse
 */
export interface GetProjectCompletedActivitiesA3ResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectCompletedActivitiesA3ResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCompletedActivitiesA3ResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetProjectCompletedActivitiesA3ResponseDtoListResponse}
     * @memberof GetProjectCompletedActivitiesA3ResponseDtoListControllerResponse
     */
    'responseObject'?: GetProjectCompletedActivitiesA3ResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetProjectCompletedActivitiesA3ResponseDtoListResponse
 */
export interface GetProjectCompletedActivitiesA3ResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetProjectCompletedActivitiesA3ResponseDto>}
     * @memberof GetProjectCompletedActivitiesA3ResponseDtoListResponse
     */
    'value'?: Array<GetProjectCompletedActivitiesA3ResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetProjectCostDetailResponseDto
 */
export interface GetProjectCostDetailResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectCostDetailResponseDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCostDetailResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectCostDetailResponseDto
     */
    'type'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCostDetailResponseDto
     */
    'typeDescription'?: string | null;
    /**
     * 
     * @type {Array<DeliverableListObject>}
     * @memberof GetProjectCostDetailResponseDto
     */
    'deliverables'?: Array<DeliverableListObject> | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCostDetailResponseDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCostDetailResponseDto
     */
    'deliverableName'?: string | null;
}
/**
 * 
 * @export
 * @interface GetProjectCostDetailResponseDtoControllerResponse
 */
export interface GetProjectCostDetailResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectCostDetailResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCostDetailResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetProjectCostDetailResponseDtoResponse}
     * @memberof GetProjectCostDetailResponseDtoControllerResponse
     */
    'responseObject'?: GetProjectCostDetailResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface GetProjectCostDetailResponseDtoResponse
 */
export interface GetProjectCostDetailResponseDtoResponse {
    /**
     * 
     * @type {GetProjectCostDetailResponseDto}
     * @memberof GetProjectCostDetailResponseDtoResponse
     */
    'value'?: GetProjectCostDetailResponseDto;
}
/**
 * 
 * @export
 * @interface GetProjectCreationPicklistResponseDto
 */
export interface GetProjectCreationPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectCreationPicklistResponseDto
     */
    'projectID': string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCreationPicklistResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCreationPicklistResponseDto
     */
    'projectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface GetProjectCreationPicklistResponseDtoListControllerResponse
 */
export interface GetProjectCreationPicklistResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectCreationPicklistResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCreationPicklistResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetProjectCreationPicklistResponseDtoListResponse}
     * @memberof GetProjectCreationPicklistResponseDtoListControllerResponse
     */
    'responseObject'?: GetProjectCreationPicklistResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetProjectCreationPicklistResponseDtoListResponse
 */
export interface GetProjectCreationPicklistResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetProjectCreationPicklistResponseDto>}
     * @memberof GetProjectCreationPicklistResponseDtoListResponse
     */
    'value'?: Array<GetProjectCreationPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetProjectDeliverableNextStepA3ResponseDto
 */
export interface GetProjectDeliverableNextStepA3ResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableNextStepA3ResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableNextStepA3ResponseDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableNextStepA3ResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableNextStepA3ResponseDto
     */
    'owner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableNextStepA3ResponseDto
     */
    'ownerFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableNextStepA3ResponseDto
     */
    'ownerFullNameInitials'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableNextStepA3ResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableNextStepA3ResponseDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectDeliverableNextStepA3ResponseDto
     */
    'deliverableStatus'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableNextStepA3ResponseDto
     */
    'genericResponsible'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectDeliverableNextStepA3ResponseDto
     */
    'isGenericResponsabile'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetProjectDeliverableNextStepA3ResponseDtoListControllerResponse
 */
export interface GetProjectDeliverableNextStepA3ResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectDeliverableNextStepA3ResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableNextStepA3ResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetProjectDeliverableNextStepA3ResponseDtoListResponse}
     * @memberof GetProjectDeliverableNextStepA3ResponseDtoListControllerResponse
     */
    'responseObject'?: GetProjectDeliverableNextStepA3ResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetProjectDeliverableNextStepA3ResponseDtoListResponse
 */
export interface GetProjectDeliverableNextStepA3ResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetProjectDeliverableNextStepA3ResponseDto>}
     * @memberof GetProjectDeliverableNextStepA3ResponseDtoListResponse
     */
    'value'?: Array<GetProjectDeliverableNextStepA3ResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetProjectDeliverablePicklistResponseDto
 */
export interface GetProjectDeliverablePicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverablePicklistResponseDto
     */
    'projectID': string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverablePicklistResponseDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface GetProjectDeliverablePicklistResponseDtoListControllerResponse
 */
export interface GetProjectDeliverablePicklistResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectDeliverablePicklistResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverablePicklistResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetProjectDeliverablePicklistResponseDtoListResponse}
     * @memberof GetProjectDeliverablePicklistResponseDtoListControllerResponse
     */
    'responseObject'?: GetProjectDeliverablePicklistResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetProjectDeliverablePicklistResponseDtoListResponse
 */
export interface GetProjectDeliverablePicklistResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetProjectDeliverablePicklistResponseDto>}
     * @memberof GetProjectDeliverablePicklistResponseDtoListResponse
     */
    'value'?: Array<GetProjectDeliverablePicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetProjectDeliverableResponseDto
 */
export interface GetProjectDeliverableResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableResponseDto
     */
    'deliverableID': string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableResponseDto
     */
    'parentDeliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectDeliverableResponseDto
     */
    'budget'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectDeliverableResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectDeliverableResponseDto
     */
    'childrenCount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableResponseDto
     */
    'owner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableResponseDto
     */
    'ownerFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectDeliverableResponseDto
     */
    'priority'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableResponseDto
     */
    'rescheduledEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableResponseDto
     */
    'actualEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableResponseDto
     */
    'genericResponsible'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectDeliverableResponseDto
     */
    'isGenericResponsabile'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectDeliverableResponseDto
     */
    'hasAccess'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetProjectDeliverableTeamResponseDto
 */
export interface GetProjectDeliverableTeamResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableTeamResponseDto
     */
    'userID': string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableTeamResponseDto
     */
    'fullName'?: string | null;
}
/**
 * 
 * @export
 * @interface GetProjectDeliverableTeamResponseDtoListControllerResponse
 */
export interface GetProjectDeliverableTeamResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectDeliverableTeamResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableTeamResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetProjectDeliverableTeamResponseDtoListResponse}
     * @memberof GetProjectDeliverableTeamResponseDtoListControllerResponse
     */
    'responseObject'?: GetProjectDeliverableTeamResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetProjectDeliverableTeamResponseDtoListResponse
 */
export interface GetProjectDeliverableTeamResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetProjectDeliverableTeamResponseDto>}
     * @memberof GetProjectDeliverableTeamResponseDtoListResponse
     */
    'value'?: Array<GetProjectDeliverableTeamResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetProjectFeedActivityListResponseDto
 */
export interface GetProjectFeedActivityListResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'regardingObjectID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'regardingObjectTypeCode'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'createdOn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'activityType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'activityTypeDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'documentURL'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'scheduledStart'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'scheduledEnd'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'priority'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'totLikes'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'isLikedByMe': number;
    /**
     * 
     * @type {string}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'initials'?: string | null;
}
/**
 * 
 * @export
 * @interface GetProjectFeedActivityListResponseDtoListControllerResponse
 */
export interface GetProjectFeedActivityListResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectFeedActivityListResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetProjectFeedActivityListResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetProjectFeedActivityListResponseDtoListResponse}
     * @memberof GetProjectFeedActivityListResponseDtoListControllerResponse
     */
    'responseObject'?: GetProjectFeedActivityListResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetProjectFeedActivityListResponseDtoListResponse
 */
export interface GetProjectFeedActivityListResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetProjectFeedActivityListResponseDto>}
     * @memberof GetProjectFeedActivityListResponseDtoListResponse
     */
    'value'?: Array<GetProjectFeedActivityListResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetProjectHeaderA3ResponseDto
 */
export interface GetProjectHeaderA3ResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectHeaderA3ResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectHeaderA3ResponseDto
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectHeaderA3ResponseDto
     */
    'teamLeaderFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectHeaderA3ResponseDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectHeaderA3ResponseDto
     */
    'sponsorFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectHeaderA3ResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectHeaderA3ResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectHeaderA3ResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectHeaderA3ResponseDto
     */
    'lastUpdated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectHeaderA3ResponseDto
     */
    'profilePhoto'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectHeaderA3ResponseDto
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof GetProjectHeaderA3ResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectHeaderA3ResponseDto
     */
    'projectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface GetProjectHeaderA3ResponseDtoControllerResponse
 */
export interface GetProjectHeaderA3ResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectHeaderA3ResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetProjectHeaderA3ResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetProjectHeaderA3ResponseDtoResponse}
     * @memberof GetProjectHeaderA3ResponseDtoControllerResponse
     */
    'responseObject'?: GetProjectHeaderA3ResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface GetProjectHeaderA3ResponseDtoResponse
 */
export interface GetProjectHeaderA3ResponseDtoResponse {
    /**
     * 
     * @type {GetProjectHeaderA3ResponseDto}
     * @memberof GetProjectHeaderA3ResponseDtoResponse
     */
    'value'?: GetProjectHeaderA3ResponseDto;
}
/**
 * 
 * @export
 * @interface GetProjectListPageResponseDto
 */
export interface GetProjectListPageResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'teamLeaderFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'sponsorFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectListPageResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'committeeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'projectRepository'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'parentProjectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'parentName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'teamMembersName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'teamMembersEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'teamLeaderEmailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'sponsorEmailAddress'?: string | null;
}
/**
 * 
 * @export
 * @interface GetProjectListPageResponseDtoListControllerResponse
 */
export interface GetProjectListPageResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectListPageResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetProjectListPageResponseDtoListResponse}
     * @memberof GetProjectListPageResponseDtoListControllerResponse
     */
    'responseObject'?: GetProjectListPageResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetProjectListPageResponseDtoListResponse
 */
export interface GetProjectListPageResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetProjectListPageResponseDto>}
     * @memberof GetProjectListPageResponseDtoListResponse
     */
    'value'?: Array<GetProjectListPageResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetProjectMembersListResponseDto
 */
export interface GetProjectMembersListResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectMembersListResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectMembersListResponseDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectMembersListResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectMembersListResponseDto
     */
    'isDeleted': boolean;
}
/**
 * 
 * @export
 * @interface GetProjectMembersListResponseDtoListControllerResponse
 */
export interface GetProjectMembersListResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectMembersListResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetProjectMembersListResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetProjectMembersListResponseDtoListResponse}
     * @memberof GetProjectMembersListResponseDtoListControllerResponse
     */
    'responseObject'?: GetProjectMembersListResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetProjectMembersListResponseDtoListResponse
 */
export interface GetProjectMembersListResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetProjectMembersListResponseDto>}
     * @memberof GetProjectMembersListResponseDtoListResponse
     */
    'value'?: Array<GetProjectMembersListResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetProjectMembersResponseDto
 */
export interface GetProjectMembersResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectMembersResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectMembersResponseDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectMembersResponseDto
     */
    'type'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectMembersResponseDto
     */
    'role'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectMembersResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectMembersResponseDto
     */
    'department'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectMembersResponseDto
     */
    'emailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectMembersResponseDto
     */
    'jobTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectMembersResponseDto
     */
    'profilePhoto'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectMembersResponseDto
     */
    'initials'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectMembersResponseDto
     */
    'roleCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectMembersResponseDto
     */
    'onCall'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectMembersResponseDto
     */
    'isDeleted': boolean;
}
/**
 * 
 * @export
 * @interface GetProjectMembersResponseDtoListControllerResponse
 */
export interface GetProjectMembersResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectMembersResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetProjectMembersResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetProjectMembersResponseDtoListResponse}
     * @memberof GetProjectMembersResponseDtoListControllerResponse
     */
    'responseObject'?: GetProjectMembersResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetProjectMembersResponseDtoListResponse
 */
export interface GetProjectMembersResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetProjectMembersResponseDto>}
     * @memberof GetProjectMembersResponseDtoListResponse
     */
    'value'?: Array<GetProjectMembersResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetProjectPicklistResponseDto
 */
export interface GetProjectPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectPicklistResponseDto
     */
    'projectID': string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectPicklistResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectPicklistResponseDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectPicklistResponseDto
     */
    'isProjectRelatedXMatrix': number;
}
/**
 * 
 * @export
 * @interface GetProjectPicklistResponseDtoListControllerResponse
 */
export interface GetProjectPicklistResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectPicklistResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetProjectPicklistResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetProjectPicklistResponseDtoListResponse}
     * @memberof GetProjectPicklistResponseDtoListControllerResponse
     */
    'responseObject'?: GetProjectPicklistResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetProjectPicklistResponseDtoListResponse
 */
export interface GetProjectPicklistResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetProjectPicklistResponseDto>}
     * @memberof GetProjectPicklistResponseDtoListResponse
     */
    'value'?: Array<GetProjectPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetProjectRepositoryList
 */
export interface GetProjectRepositoryList {
    /**
     * 
     * @type {string}
     * @memberof GetProjectRepositoryList
     */
    'projectRepositoryID': string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectRepositoryList
     */
    'projectID': string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectRepositoryList
     */
    'link'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectRepositoryList
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface GetProjectRepositoryListControllerResponse
 */
export interface GetProjectRepositoryListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectRepositoryListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetProjectRepositoryListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetProjectRepositoryListResponse}
     * @memberof GetProjectRepositoryListControllerResponse
     */
    'responseObject'?: GetProjectRepositoryListResponse;
}
/**
 * 
 * @export
 * @interface GetProjectRepositoryListResponse
 */
export interface GetProjectRepositoryListResponse {
    /**
     * 
     * @type {GetProjectRepositoryList}
     * @memberof GetProjectRepositoryListResponse
     */
    'value'?: GetProjectRepositoryList;
}
/**
 * 
 * @export
 * @interface GetProjectResponseDto
 */
export interface GetProjectResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectResponseDto
     */
    'hasExternalReview'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectResponseDto
     */
    'budget'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'teamLeaderFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'sponsorFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'committeeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'projectGoal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'groundtoAct'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'hanseiLearnedLessons'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'nextStepsNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'obtainedBenefit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'finishedActivitiesNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'lastUpdated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectResponseDto
     */
    'timeSemaphore'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectResponseDto
     */
    'budgetSemaphore'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectResponseDto
     */
    'qualitySemaphore'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'parentProjectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'parentProjectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'xMatrixName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectResponseDto
     */
    'budgetSemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectResponseDto
     */
    'budgetSemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectResponseDto
     */
    'qualitySemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectResponseDto
     */
    'qualitySemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectResponseDto
     */
    'timeSemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectResponseDto
     */
    'timeSemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'parentProjectStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'parentProjectEndDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectResponseDto
     */
    'secondaryProject'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectResponseDto
     */
    'fastCreation'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectResponseDto
     */
    'masterplanDuration': number;
}
/**
 * 
 * @export
 * @interface GetProjectResponseDtoControllerResponse
 */
export interface GetProjectResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetProjectResponseDtoResponse}
     * @memberof GetProjectResponseDtoControllerResponse
     */
    'responseObject'?: GetProjectResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface GetProjectResponseDtoResponse
 */
export interface GetProjectResponseDtoResponse {
    /**
     * 
     * @type {GetProjectResponseDto}
     * @memberof GetProjectResponseDtoResponse
     */
    'value'?: GetProjectResponseDto;
}
/**
 * 
 * @export
 * @interface GetProjectStatusPicklistDto
 */
export interface GetProjectStatusPicklistDto {
    /**
     * 
     * @type {number}
     * @memberof GetProjectStatusPicklistDto
     */
    'statusID'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectStatusPicklistDto
     */
    'statusDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetProjectStatusPicklistDtoListControllerResponse
 */
export interface GetProjectStatusPicklistDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectStatusPicklistDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetProjectStatusPicklistDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetProjectStatusPicklistDtoListResponse}
     * @memberof GetProjectStatusPicklistDtoListControllerResponse
     */
    'responseObject'?: GetProjectStatusPicklistDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetProjectStatusPicklistDtoListResponse
 */
export interface GetProjectStatusPicklistDtoListResponse {
    /**
     * 
     * @type {Array<GetProjectStatusPicklistDto>}
     * @memberof GetProjectStatusPicklistDtoListResponse
     */
    'value'?: Array<GetProjectStatusPicklistDto> | null;
}
/**
 * 
 * @export
 * @interface GetResourceWorkloadSummaryRequest
 */
export interface GetResourceWorkloadSummaryRequest {
    /**
     * 
     * @type {Array<string | null>}
     * @memberof GetResourceWorkloadSummaryRequest
     */
    'userID'?: Array<string | null> | null;
    /**
     * 
     * @type {Array<string | null>}
     * @memberof GetResourceWorkloadSummaryRequest
     */
    'divisionID'?: Array<string | null> | null;
    /**
     * 
     * @type {string}
     * @memberof GetResourceWorkloadSummaryRequest
     */
    'year'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetResourceWorkloadSummaryRequest
     */
    'xMatrixID'?: string | null;
}
/**
 * 
 * @export
 * @interface GetResourceWorkloadSummaryResponseDto
 */
export interface GetResourceWorkloadSummaryResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetResourceWorkloadSummaryResponseDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetResourceWorkloadSummaryResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetResourceWorkloadSummaryResponseDto
     */
    'allocated'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetResourceWorkloadSummaryResponseDto
     */
    'budget'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetResourceWorkloadSummaryResponseDto
     */
    'consuntivo'?: number | null;
}
/**
 * 
 * @export
 * @interface GetResourceWorkloadSummaryResponseDtoListControllerResponse
 */
export interface GetResourceWorkloadSummaryResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetResourceWorkloadSummaryResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetResourceWorkloadSummaryResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetResourceWorkloadSummaryResponseDtoListResponse}
     * @memberof GetResourceWorkloadSummaryResponseDtoListControllerResponse
     */
    'responseObject'?: GetResourceWorkloadSummaryResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetResourceWorkloadSummaryResponseDtoListResponse
 */
export interface GetResourceWorkloadSummaryResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetResourceWorkloadSummaryResponseDto>}
     * @memberof GetResourceWorkloadSummaryResponseDtoListResponse
     */
    'value'?: Array<GetResourceWorkloadSummaryResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetRiskPrecautionA3ResponseDto
 */
export interface GetRiskPrecautionA3ResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionA3ResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionA3ResponseDto
     */
    'riskID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionA3ResponseDto
     */
    'riskDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetRiskPrecautionA3ResponseDto
     */
    'severity'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionA3ResponseDto
     */
    'riskSeverityDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetRiskPrecautionA3ResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionA3ResponseDto
     */
    'riskStatusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionA3ResponseDto
     */
    'riskComment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionA3ResponseDto
     */
    'precautionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionA3ResponseDto
     */
    'precautionDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetRiskPrecautionA3ResponseDtoListControllerResponse
 */
export interface GetRiskPrecautionA3ResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetRiskPrecautionA3ResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionA3ResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetRiskPrecautionA3ResponseDtoListResponse}
     * @memberof GetRiskPrecautionA3ResponseDtoListControllerResponse
     */
    'responseObject'?: GetRiskPrecautionA3ResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetRiskPrecautionA3ResponseDtoListResponse
 */
export interface GetRiskPrecautionA3ResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetRiskPrecautionA3ResponseDto>}
     * @memberof GetRiskPrecautionA3ResponseDtoListResponse
     */
    'value'?: Array<GetRiskPrecautionA3ResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetRiskPrecautionResponseDto
 */
export interface GetRiskPrecautionResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionResponseDto
     */
    'riskID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionResponseDto
     */
    'riskDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetRiskPrecautionResponseDto
     */
    'severity'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionResponseDto
     */
    'riskSeverityDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetRiskPrecautionResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionResponseDto
     */
    'riskStatusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionResponseDto
     */
    'riskComment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionResponseDto
     */
    'precautionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionResponseDto
     */
    'precautionDescription'?: string | null;
    /**
     * 
     * @type {Array<CreatePrecaution>}
     * @memberof GetRiskPrecautionResponseDto
     */
    'precautions'?: Array<CreatePrecaution> | null;
}
/**
 * 
 * @export
 * @interface GetRiskPrecautionResponseDtoListControllerResponse
 */
export interface GetRiskPrecautionResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetRiskPrecautionResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetRiskPrecautionResponseDtoListResponse}
     * @memberof GetRiskPrecautionResponseDtoListControllerResponse
     */
    'responseObject'?: GetRiskPrecautionResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetRiskPrecautionResponseDtoListResponse
 */
export interface GetRiskPrecautionResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetRiskPrecautionResponseDto>}
     * @memberof GetRiskPrecautionResponseDtoListResponse
     */
    'value'?: Array<GetRiskPrecautionResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetRolePicklistDto
 */
export interface GetRolePicklistDto {
    /**
     * 
     * @type {string}
     * @memberof GetRolePicklistDto
     */
    'roleID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetRolePicklistDto
     */
    'roleDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetRolePicklistDtoListControllerResponse
 */
export interface GetRolePicklistDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetRolePicklistDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetRolePicklistDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetRolePicklistDtoListResponse}
     * @memberof GetRolePicklistDtoListControllerResponse
     */
    'responseObject'?: GetRolePicklistDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetRolePicklistDtoListResponse
 */
export interface GetRolePicklistDtoListResponse {
    /**
     * 
     * @type {Array<GetRolePicklistDto>}
     * @memberof GetRolePicklistDtoListResponse
     */
    'value'?: Array<GetRolePicklistDto> | null;
}
/**
 * 
 * @export
 * @interface GetRollupKPIChildResponseDto
 */
export interface GetRollupKPIChildResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetRollupKPIChildResponseDto
     */
    'kpiDetailID': string;
    /**
     * 
     * @type {string}
     * @memberof GetRollupKPIChildResponseDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface GetRollupKPIChildResponseDtoListControllerResponse
 */
export interface GetRollupKPIChildResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetRollupKPIChildResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetRollupKPIChildResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetRollupKPIChildResponseDtoListResponse}
     * @memberof GetRollupKPIChildResponseDtoListControllerResponse
     */
    'responseObject'?: GetRollupKPIChildResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetRollupKPIChildResponseDtoListResponse
 */
export interface GetRollupKPIChildResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetRollupKPIChildResponseDto>}
     * @memberof GetRollupKPIChildResponseDtoListResponse
     */
    'value'?: Array<GetRollupKPIChildResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetRootXMatrixDivisionListResponseDto
 */
export interface GetRootXMatrixDivisionListResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetRootXMatrixDivisionListResponseDto
     */
    'xmatrixID': string;
    /**
     * 
     * @type {string}
     * @memberof GetRootXMatrixDivisionListResponseDto
     */
    'hkCode'?: string | null;
    /**
     * 
     * @type {Array<ChildList>}
     * @memberof GetRootXMatrixDivisionListResponseDto
     */
    'childList'?: Array<ChildList> | null;
    /**
     * 
     * @type {string}
     * @memberof GetRootXMatrixDivisionListResponseDto
     */
    'xMatrixYear'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetRootXMatrixDivisionListResponseDto
     */
    'parentXMatrixYear'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetRootXMatrixDivisionListResponseDto
     */
    'parentXMatrixID': string;
}
/**
 * 
 * @export
 * @interface GetRootXMatrixDivisionListResponseDtoListControllerResponse
 */
export interface GetRootXMatrixDivisionListResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetRootXMatrixDivisionListResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetRootXMatrixDivisionListResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetRootXMatrixDivisionListResponseDtoListResponse}
     * @memberof GetRootXMatrixDivisionListResponseDtoListControllerResponse
     */
    'responseObject'?: GetRootXMatrixDivisionListResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetRootXMatrixDivisionListResponseDtoListResponse
 */
export interface GetRootXMatrixDivisionListResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetRootXMatrixDivisionListResponseDto>}
     * @memberof GetRootXMatrixDivisionListResponseDtoListResponse
     */
    'value'?: Array<GetRootXMatrixDivisionListResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetSecondLevelMacroProjectsRelationshipResponseDto
 */
export interface GetSecondLevelMacroProjectsRelationshipResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetSecondLevelMacroProjectsRelationshipResponseDto
     */
    'secondLevelXmatrixID': string;
    /**
     * 
     * @type {string}
     * @memberof GetSecondLevelMacroProjectsRelationshipResponseDto
     */
    'macroProjectID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetSecondLevelMacroProjectsRelationshipResponseDto
     */
    'correlationCoefficient'?: number | null;
}
/**
 * 
 * @export
 * @interface GetSecondLevelMacroProjectsRelationshipResponseDtoListControllerResponse
 */
export interface GetSecondLevelMacroProjectsRelationshipResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetSecondLevelMacroProjectsRelationshipResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetSecondLevelMacroProjectsRelationshipResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetSecondLevelMacroProjectsRelationshipResponseDtoListResponse}
     * @memberof GetSecondLevelMacroProjectsRelationshipResponseDtoListControllerResponse
     */
    'responseObject'?: GetSecondLevelMacroProjectsRelationshipResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetSecondLevelMacroProjectsRelationshipResponseDtoListResponse
 */
export interface GetSecondLevelMacroProjectsRelationshipResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetSecondLevelMacroProjectsRelationshipResponseDto>}
     * @memberof GetSecondLevelMacroProjectsRelationshipResponseDtoListResponse
     */
    'value'?: Array<GetSecondLevelMacroProjectsRelationshipResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetSemaphorePicklistDto
 */
export interface GetSemaphorePicklistDto {
    /**
     * 
     * @type {number}
     * @memberof GetSemaphorePicklistDto
     */
    'objectCodeListID'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetSemaphorePicklistDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface GetSemaphorePicklistDtoListControllerResponse
 */
export interface GetSemaphorePicklistDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetSemaphorePicklistDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetSemaphorePicklistDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetSemaphorePicklistDtoListResponse}
     * @memberof GetSemaphorePicklistDtoListControllerResponse
     */
    'responseObject'?: GetSemaphorePicklistDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetSemaphorePicklistDtoListResponse
 */
export interface GetSemaphorePicklistDtoListResponse {
    /**
     * 
     * @type {Array<GetSemaphorePicklistDto>}
     * @memberof GetSemaphorePicklistDtoListResponse
     */
    'value'?: Array<GetSemaphorePicklistDto> | null;
}
/**
 * 
 * @export
 * @interface GetSintesiComitatiResponse
 */
export interface GetSintesiComitatiResponse {
    /**
     * 
     * @type {string}
     * @memberof GetSintesiComitatiResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetSintesiComitatiResponse
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetSintesiComitatiResponse
     */
    'committeeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetSintesiComitatiResponse
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetSintesiComitatiResponse
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetSintesiComitatiResponse
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetSintesiComitatiResponse
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetSintesiComitatiResponse
     */
    'teamLeaderName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetSintesiComitatiResponse
     */
    'projectSessions'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetSintesiComitatiResponse
     */
    'projectSessionCount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetSintesiComitatiResponse
     */
    'total'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetSintesiComitatiResponse
     */
    'children'?: string | null;
}
/**
 * 
 * @export
 * @interface GetSintesiComitatiResponseListControllerResponse
 */
export interface GetSintesiComitatiResponseListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetSintesiComitatiResponseListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetSintesiComitatiResponseListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetSintesiComitatiResponseListResponse}
     * @memberof GetSintesiComitatiResponseListControllerResponse
     */
    'responseObject'?: GetSintesiComitatiResponseListResponse;
}
/**
 * 
 * @export
 * @interface GetSintesiComitatiResponseListResponse
 */
export interface GetSintesiComitatiResponseListResponse {
    /**
     * 
     * @type {Array<GetSintesiComitatiResponse>}
     * @memberof GetSintesiComitatiResponseListResponse
     */
    'value'?: Array<GetSintesiComitatiResponse> | null;
}
/**
 * 
 * @export
 * @interface GetSponsorPicklistResponseDto
 */
export interface GetSponsorPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetSponsorPicklistResponseDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetSponsorPicklistResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetSponsorPicklistResponseDto
     */
    'isDeleted': boolean;
}
/**
 * 
 * @export
 * @interface GetSponsorPicklistResponseDtoListControllerResponse
 */
export interface GetSponsorPicklistResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetSponsorPicklistResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetSponsorPicklistResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetSponsorPicklistResponseDtoListResponse}
     * @memberof GetSponsorPicklistResponseDtoListControllerResponse
     */
    'responseObject'?: GetSponsorPicklistResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetSponsorPicklistResponseDtoListResponse
 */
export interface GetSponsorPicklistResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetSponsorPicklistResponseDto>}
     * @memberof GetSponsorPicklistResponseDtoListResponse
     */
    'value'?: Array<GetSponsorPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetStatusTypeByLanguageCodeResponseDto
 */
export interface GetStatusTypeByLanguageCodeResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetStatusTypeByLanguageCodeResponseDto
     */
    'statusTypeID': string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusTypeByLanguageCodeResponseDto
     */
    'statusID': string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusTypeByLanguageCodeResponseDto
     */
    'status_Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetStatusTypeByLanguageCodeResponseDto
     */
    'language_Code'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetStatusTypeByLanguageCodeResponseDto
     */
    'isDeleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetStatusTypeByLanguageCodeResponseDtoListControllerResponse
 */
export interface GetStatusTypeByLanguageCodeResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetStatusTypeByLanguageCodeResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetStatusTypeByLanguageCodeResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetStatusTypeByLanguageCodeResponseDtoListResponse}
     * @memberof GetStatusTypeByLanguageCodeResponseDtoListControllerResponse
     */
    'responseObject'?: GetStatusTypeByLanguageCodeResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetStatusTypeByLanguageCodeResponseDtoListResponse
 */
export interface GetStatusTypeByLanguageCodeResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetStatusTypeByLanguageCodeResponseDto>}
     * @memberof GetStatusTypeByLanguageCodeResponseDtoListResponse
     */
    'value'?: Array<GetStatusTypeByLanguageCodeResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetSummaryFeedTypesByLanguageCodeResponseDto
 */
export interface GetSummaryFeedTypesByLanguageCodeResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetSummaryFeedTypesByLanguageCodeResponseDto
     */
    'summaryID': string;
    /**
     * 
     * @type {number}
     * @memberof GetSummaryFeedTypesByLanguageCodeResponseDto
     */
    'summaryFeedType': number;
    /**
     * 
     * @type {string}
     * @memberof GetSummaryFeedTypesByLanguageCodeResponseDto
     */
    'summaryFeedName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetSummaryFeedTypesByLanguageCodeResponseDto
     */
    'language_Code'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetSummaryFeedTypesByLanguageCodeResponseDto
     */
    'isDeleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetSummaryFeedTypesByLanguageCodeResponseDtoListControllerResponse
 */
export interface GetSummaryFeedTypesByLanguageCodeResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetSummaryFeedTypesByLanguageCodeResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetSummaryFeedTypesByLanguageCodeResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetSummaryFeedTypesByLanguageCodeResponseDtoListResponse}
     * @memberof GetSummaryFeedTypesByLanguageCodeResponseDtoListControllerResponse
     */
    'responseObject'?: GetSummaryFeedTypesByLanguageCodeResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetSummaryFeedTypesByLanguageCodeResponseDtoListResponse
 */
export interface GetSummaryFeedTypesByLanguageCodeResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetSummaryFeedTypesByLanguageCodeResponseDto>}
     * @memberof GetSummaryFeedTypesByLanguageCodeResponseDtoListResponse
     */
    'value'?: Array<GetSummaryFeedTypesByLanguageCodeResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetTeamLeaderActivityMenuFormResponseDto
 */
export interface GetTeamLeaderActivityMenuFormResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'isRelatedToProject'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'actualCloseDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'deliverableName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'isGenerated'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'deliverableStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'deliverableEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'projectStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'projectEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'parentDeliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'parentDeliverableStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'parentDeliverableEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'genericResponsible'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'isGenericResponsabile'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'activityType'?: string | null;
}
/**
 * 
 * @export
 * @interface GetTeamLeaderActivityMenuFormResponseDtoListControllerResponse
 */
export interface GetTeamLeaderActivityMenuFormResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetTeamLeaderActivityMenuFormResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetTeamLeaderActivityMenuFormResponseDtoListResponse}
     * @memberof GetTeamLeaderActivityMenuFormResponseDtoListControllerResponse
     */
    'responseObject'?: GetTeamLeaderActivityMenuFormResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetTeamLeaderActivityMenuFormResponseDtoListResponse
 */
export interface GetTeamLeaderActivityMenuFormResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetTeamLeaderActivityMenuFormResponseDto>}
     * @memberof GetTeamLeaderActivityMenuFormResponseDtoListResponse
     */
    'value'?: Array<GetTeamLeaderActivityMenuFormResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetTeamLeaderPicklistDto
 */
export interface GetTeamLeaderPicklistDto {
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderPicklistDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderPicklistDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetTeamLeaderPicklistDto
     */
    'isDeleted': boolean;
}
/**
 * 
 * @export
 * @interface GetTeamLeaderPicklistDtoListControllerResponse
 */
export interface GetTeamLeaderPicklistDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetTeamLeaderPicklistDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderPicklistDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetTeamLeaderPicklistDtoListResponse}
     * @memberof GetTeamLeaderPicklistDtoListControllerResponse
     */
    'responseObject'?: GetTeamLeaderPicklistDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetTeamLeaderPicklistDtoListResponse
 */
export interface GetTeamLeaderPicklistDtoListResponse {
    /**
     * 
     * @type {Array<GetTeamLeaderPicklistDto>}
     * @memberof GetTeamLeaderPicklistDtoListResponse
     */
    'value'?: Array<GetTeamLeaderPicklistDto> | null;
}
/**
 * 
 * @export
 * @interface GetTeamLeaderProjectResponseDto
 */
export interface GetTeamLeaderProjectResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderProjectResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderProjectResponseDto
     */
    'projectID'?: string | null;
}
/**
 * 
 * @export
 * @interface GetTeamLeaderProjectResponseDtoListControllerResponse
 */
export interface GetTeamLeaderProjectResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetTeamLeaderProjectResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderProjectResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetTeamLeaderProjectResponseDtoListResponse}
     * @memberof GetTeamLeaderProjectResponseDtoListControllerResponse
     */
    'responseObject'?: GetTeamLeaderProjectResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetTeamLeaderProjectResponseDtoListResponse
 */
export interface GetTeamLeaderProjectResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetTeamLeaderProjectResponseDto>}
     * @memberof GetTeamLeaderProjectResponseDtoListResponse
     */
    'value'?: Array<GetTeamLeaderProjectResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetTotalActiveUsersByRoleResponseDto
 */
export interface GetTotalActiveUsersByRoleResponseDto {
    /**
     * 
     * @type {number}
     * @memberof GetTotalActiveUsersByRoleResponseDto
     */
    'totalSteeringUsers': number;
    /**
     * 
     * @type {number}
     * @memberof GetTotalActiveUsersByRoleResponseDto
     */
    'totalTeamLeaderUsers': number;
    /**
     * 
     * @type {number}
     * @memberof GetTotalActiveUsersByRoleResponseDto
     */
    'totalTeamMemberUsers': number;
    /**
     * 
     * @type {number}
     * @memberof GetTotalActiveUsersByRoleResponseDto
     */
    'totalCompanyAdminUsers': number;
    /**
     * 
     * @type {number}
     * @memberof GetTotalActiveUsersByRoleResponseDto
     */
    'totalExternalReviewerUsers': number;
    /**
     * 
     * @type {number}
     * @memberof GetTotalActiveUsersByRoleResponseDto
     */
    'totalUsersWithoutTeamMemberEssential': number;
    /**
     * 
     * @type {number}
     * @memberof GetTotalActiveUsersByRoleResponseDto
     */
    'totalTeamMemberEssential': number;
}
/**
 * 
 * @export
 * @interface GetTotalActiveUsersByRoleResponseDtoListControllerResponse
 */
export interface GetTotalActiveUsersByRoleResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetTotalActiveUsersByRoleResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetTotalActiveUsersByRoleResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetTotalActiveUsersByRoleResponseDtoListResponse}
     * @memberof GetTotalActiveUsersByRoleResponseDtoListControllerResponse
     */
    'responseObject'?: GetTotalActiveUsersByRoleResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetTotalActiveUsersByRoleResponseDtoListResponse
 */
export interface GetTotalActiveUsersByRoleResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetTotalActiveUsersByRoleResponseDto>}
     * @memberof GetTotalActiveUsersByRoleResponseDtoListResponse
     */
    'value'?: Array<GetTotalActiveUsersByRoleResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetUserCommitteeDto
 */
export interface GetUserCommitteeDto {
    /**
     * 
     * @type {string}
     * @memberof GetUserCommitteeDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserCommitteeDto
     */
    'committeeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserCommitteeDto
     */
    'repositoryLink'?: string | null;
}
/**
 * 
 * @export
 * @interface GetUserCommitteeDtoListControllerResponse
 */
export interface GetUserCommitteeDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetUserCommitteeDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetUserCommitteeDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetUserCommitteeDtoListResponse}
     * @memberof GetUserCommitteeDtoListControllerResponse
     */
    'responseObject'?: GetUserCommitteeDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetUserCommitteeDtoListResponse
 */
export interface GetUserCommitteeDtoListResponse {
    /**
     * 
     * @type {Array<GetUserCommitteeDto>}
     * @memberof GetUserCommitteeDtoListResponse
     */
    'value'?: Array<GetUserCommitteeDto> | null;
}
/**
 * 
 * @export
 * @interface GetUserProjectCostAccessResponseDto
 */
export interface GetUserProjectCostAccessResponseDto {
    /**
     * 
     * @type {number}
     * @memberof GetUserProjectCostAccessResponseDto
     */
    'hasAccess'?: number | null;
}
/**
 * 
 * @export
 * @interface GetUserProjectCostAccessResponseDtoControllerResponse
 */
export interface GetUserProjectCostAccessResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetUserProjectCostAccessResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetUserProjectCostAccessResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetUserProjectCostAccessResponseDtoResponse}
     * @memberof GetUserProjectCostAccessResponseDtoControllerResponse
     */
    'responseObject'?: GetUserProjectCostAccessResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface GetUserProjectCostAccessResponseDtoResponse
 */
export interface GetUserProjectCostAccessResponseDtoResponse {
    /**
     * 
     * @type {GetUserProjectCostAccessResponseDto}
     * @memberof GetUserProjectCostAccessResponseDtoResponse
     */
    'value'?: GetUserProjectCostAccessResponseDto;
}
/**
 * 
 * @export
 * @interface GetUserProjectResponseDto
 */
export interface GetUserProjectResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetUserProjectResponseDto
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserProjectResponseDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserProjectResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetUserProjectResponseDto
     */
    'timeSemaphore'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetUserProjectResponseDto
     */
    'budgetSemaphore'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetUserProjectResponseDto
     */
    'qualitySemaphore'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetUserProjectResponseDto
     */
    'timeSemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetUserProjectResponseDto
     */
    'timeSemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetUserProjectResponseDto
     */
    'budgetSemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetUserProjectResponseDto
     */
    'budgetSemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetUserProjectResponseDto
     */
    'qualitySemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetUserProjectResponseDto
     */
    'qualitySemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserProjectResponseDto
     */
    'userRole'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetUserProjectResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserProjectResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserProjectResponseDto
     */
    'projectRepository'?: string | null;
}
/**
 * 
 * @export
 * @interface GetUserProjectResponseDtoListControllerResponse
 */
export interface GetUserProjectResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetUserProjectResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetUserProjectResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetUserProjectResponseDtoListResponse}
     * @memberof GetUserProjectResponseDtoListControllerResponse
     */
    'responseObject'?: GetUserProjectResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetUserProjectResponseDtoListResponse
 */
export interface GetUserProjectResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetUserProjectResponseDto>}
     * @memberof GetUserProjectResponseDtoListResponse
     */
    'value'?: Array<GetUserProjectResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetUserSelectResponseDto
 */
export interface GetUserSelectResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetUserSelectResponseDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserSelectResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserSelectResponseDto
     */
    'emailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserSelectResponseDto
     */
    'roleID': string;
    /**
     * 
     * @type {string}
     * @memberof GetUserSelectResponseDto
     */
    'roleDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserSelectResponseDto
     */
    'roleCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserSelectResponseDto
     */
    'isDeleted': boolean;
}
/**
 * 
 * @export
 * @interface GetUserSelectResponseDtoListControllerResponse
 */
export interface GetUserSelectResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetUserSelectResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetUserSelectResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetUserSelectResponseDtoListResponse}
     * @memberof GetUserSelectResponseDtoListControllerResponse
     */
    'responseObject'?: GetUserSelectResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetUserSelectResponseDtoListResponse
 */
export interface GetUserSelectResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetUserSelectResponseDto>}
     * @memberof GetUserSelectResponseDtoListResponse
     */
    'value'?: Array<GetUserSelectResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetXMatrixCycleByIDResponseDto
 */
export interface GetXMatrixCycleByIDResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixCycleByIDResponseDto
     */
    'xMatrixCycleID': string;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixCycleByIDResponseDto
     */
    'companyID': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetXMatrixCycleByIDResponseDto
     */
    'isDeleted': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixCycleByIDResponseDto
     */
    'cycleName'?: string | null;
}
/**
 * 
 * @export
 * @interface GetXMatrixCycleByIDResponseDtoControllerResponse
 */
export interface GetXMatrixCycleByIDResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetXMatrixCycleByIDResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixCycleByIDResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetXMatrixCycleByIDResponseDtoResponse}
     * @memberof GetXMatrixCycleByIDResponseDtoControllerResponse
     */
    'responseObject'?: GetXMatrixCycleByIDResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface GetXMatrixCycleByIDResponseDtoResponse
 */
export interface GetXMatrixCycleByIDResponseDtoResponse {
    /**
     * 
     * @type {GetXMatrixCycleByIDResponseDto}
     * @memberof GetXMatrixCycleByIDResponseDtoResponse
     */
    'value'?: GetXMatrixCycleByIDResponseDto;
}
/**
 * 
 * @export
 * @interface GetXMatrixCycleResponseDto
 */
export interface GetXMatrixCycleResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixCycleResponseDto
     */
    'xMatrixCycleID': string;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixCycleResponseDto
     */
    'companyID': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetXMatrixCycleResponseDto
     */
    'isDeleted': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixCycleResponseDto
     */
    'cycleName'?: string | null;
}
/**
 * 
 * @export
 * @interface GetXMatrixCycleResponseDtoListControllerResponse
 */
export interface GetXMatrixCycleResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetXMatrixCycleResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixCycleResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetXMatrixCycleResponseDtoListResponse}
     * @memberof GetXMatrixCycleResponseDtoListControllerResponse
     */
    'responseObject'?: GetXMatrixCycleResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetXMatrixCycleResponseDtoListResponse
 */
export interface GetXMatrixCycleResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetXMatrixCycleResponseDto>}
     * @memberof GetXMatrixCycleResponseDtoListResponse
     */
    'value'?: Array<GetXMatrixCycleResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetXMatrixInfoResponseDto
 */
export interface GetXMatrixInfoResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixInfoResponseDto
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixInfoResponseDto
     */
    'hkCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixInfoResponseDto
     */
    'parentXMatrixID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetXMatrixInfoResponseDto
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixInfoResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixInfoResponseDto
     */
    'image'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixInfoResponseDto
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixInfoResponseDto
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixInfoResponseDto
     */
    'xMatrixCycleID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixInfoResponseDto
     */
    'xMatrixCycleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixInfoResponseDto
     */
    'xMatrixName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixInfoResponseDto
     */
    'referencePeriod'?: string | null;
}
/**
 * 
 * @export
 * @interface GetXMatrixInfoResponseDtoControllerResponse
 */
export interface GetXMatrixInfoResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetXMatrixInfoResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixInfoResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetXMatrixInfoResponseDtoResponse}
     * @memberof GetXMatrixInfoResponseDtoControllerResponse
     */
    'responseObject'?: GetXMatrixInfoResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface GetXMatrixInfoResponseDtoResponse
 */
export interface GetXMatrixInfoResponseDtoResponse {
    /**
     * 
     * @type {GetXMatrixInfoResponseDto}
     * @memberof GetXMatrixInfoResponseDtoResponse
     */
    'value'?: GetXMatrixInfoResponseDto;
}
/**
 * 
 * @export
 * @interface GetXMatrixLongTermGoalPicklistResponseDto
 */
export interface GetXMatrixLongTermGoalPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixLongTermGoalPicklistResponseDto
     */
    'longTermGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixLongTermGoalPicklistResponseDto
     */
    'title'?: string | null;
}
/**
 * 
 * @export
 * @interface GetXMatrixLongTermGoalPicklistResponseDtoListControllerResponse
 */
export interface GetXMatrixLongTermGoalPicklistResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetXMatrixLongTermGoalPicklistResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixLongTermGoalPicklistResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetXMatrixLongTermGoalPicklistResponseDtoListResponse}
     * @memberof GetXMatrixLongTermGoalPicklistResponseDtoListControllerResponse
     */
    'responseObject'?: GetXMatrixLongTermGoalPicklistResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetXMatrixLongTermGoalPicklistResponseDtoListResponse
 */
export interface GetXMatrixLongTermGoalPicklistResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetXMatrixLongTermGoalPicklistResponseDto>}
     * @memberof GetXMatrixLongTermGoalPicklistResponseDtoListResponse
     */
    'value'?: Array<GetXMatrixLongTermGoalPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetXMatrixPicklistResponseDto
 */
export interface GetXMatrixPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixPicklistResponseDto
     */
    'xMatrixID': string;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixPicklistResponseDto
     */
    'hkCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetXMatrixPicklistResponseDto
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixPicklistResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixPicklistResponseDto
     */
    'lastInsertedYear': string;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixPicklistResponseDto
     */
    'parentXMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixPicklistResponseDto
     */
    'referencePeriod'?: string | null;
}
/**
 * 
 * @export
 * @interface GetXMatrixPicklistResponseDtoListControllerResponse
 */
export interface GetXMatrixPicklistResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetXMatrixPicklistResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixPicklistResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetXMatrixPicklistResponseDtoListResponse}
     * @memberof GetXMatrixPicklistResponseDtoListControllerResponse
     */
    'responseObject'?: GetXMatrixPicklistResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetXMatrixPicklistResponseDtoListResponse
 */
export interface GetXMatrixPicklistResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetXMatrixPicklistResponseDto>}
     * @memberof GetXMatrixPicklistResponseDtoListResponse
     */
    'value'?: Array<GetXMatrixPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetXMatrixSummaryResponseDto
 */
export interface GetXMatrixSummaryResponseDto {
    /**
     * 
     * @type {number}
     * @memberof GetXMatrixSummaryResponseDto
     */
    'totalProjectMembers'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetXMatrixSummaryResponseDto
     */
    'totalDays'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetXMatrixSummaryResponseDto
     */
    'totalActiveProject'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetXMatrixSummaryResponseDto
     */
    'totalActiveLongTermGoal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetXMatrixSummaryResponseDto
     */
    'totalActiveAnnualGoal'?: number | null;
}
/**
 * 
 * @export
 * @interface GetXMatrixSummaryResponseDtoControllerResponse
 */
export interface GetXMatrixSummaryResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetXMatrixSummaryResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixSummaryResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetXMatrixSummaryResponseDtoResponse}
     * @memberof GetXMatrixSummaryResponseDtoControllerResponse
     */
    'responseObject'?: GetXMatrixSummaryResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface GetXMatrixSummaryResponseDtoResponse
 */
export interface GetXMatrixSummaryResponseDtoResponse {
    /**
     * 
     * @type {GetXMatrixSummaryResponseDto}
     * @memberof GetXMatrixSummaryResponseDtoResponse
     */
    'value'?: GetXMatrixSummaryResponseDto;
}
/**
 * 
 * @export
 * @interface GetXMatrixUsersResponseDto
 */
export interface GetXMatrixUsersResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixUsersResponseDto
     */
    'projectID': string;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixUsersResponseDto
     */
    'userID': string;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixUsersResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetXMatrixUsersResponseDto
     */
    'roleCode': number;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixUsersResponseDto
     */
    'roleDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetXMatrixUsersResponseDtoListControllerResponse
 */
export interface GetXMatrixUsersResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetXMatrixUsersResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixUsersResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetXMatrixUsersResponseDtoListResponse}
     * @memberof GetXMatrixUsersResponseDtoListControllerResponse
     */
    'responseObject'?: GetXMatrixUsersResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetXMatrixUsersResponseDtoListResponse
 */
export interface GetXMatrixUsersResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetXMatrixUsersResponseDto>}
     * @memberof GetXMatrixUsersResponseDtoListResponse
     */
    'value'?: Array<GetXMatrixUsersResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetXmatrixDivisionParentProjectPicklistResponseDto
 */
export interface GetXmatrixDivisionParentProjectPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetXmatrixDivisionParentProjectPicklistResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetXmatrixDivisionParentProjectPicklistResponseDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface GetXmatrixDivisionParentProjectPicklistResponseDtoListControllerResponse
 */
export interface GetXmatrixDivisionParentProjectPicklistResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetXmatrixDivisionParentProjectPicklistResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetXmatrixDivisionParentProjectPicklistResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetXmatrixDivisionParentProjectPicklistResponseDtoListResponse}
     * @memberof GetXmatrixDivisionParentProjectPicklistResponseDtoListControllerResponse
     */
    'responseObject'?: GetXmatrixDivisionParentProjectPicklistResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetXmatrixDivisionParentProjectPicklistResponseDtoListResponse
 */
export interface GetXmatrixDivisionParentProjectPicklistResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetXmatrixDivisionParentProjectPicklistResponseDto>}
     * @memberof GetXmatrixDivisionParentProjectPicklistResponseDtoListResponse
     */
    'value'?: Array<GetXmatrixDivisionParentProjectPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GetXmatrixDivisionPicklistResponseDto
 */
export interface GetXmatrixDivisionPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetXmatrixDivisionPicklistResponseDto
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetXmatrixDivisionPicklistResponseDto
     */
    'divisionName'?: string | null;
}
/**
 * 
 * @export
 * @interface GetXmatrixDivisionPicklistResponseDtoListControllerResponse
 */
export interface GetXmatrixDivisionPicklistResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetXmatrixDivisionPicklistResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetXmatrixDivisionPicklistResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GetXmatrixDivisionPicklistResponseDtoListResponse}
     * @memberof GetXmatrixDivisionPicklistResponseDtoListControllerResponse
     */
    'responseObject'?: GetXmatrixDivisionPicklistResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GetXmatrixDivisionPicklistResponseDtoListResponse
 */
export interface GetXmatrixDivisionPicklistResponseDtoListResponse {
    /**
     * 
     * @type {Array<GetXmatrixDivisionPicklistResponseDto>}
     * @memberof GetXmatrixDivisionPicklistResponseDtoListResponse
     */
    'value'?: Array<GetXmatrixDivisionPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GoalByProject
 */
export interface GoalByProject {
    /**
     * 
     * @type {Array<ProjectAnnualGoal>}
     * @memberof GoalByProject
     */
    'annualGoal'?: Array<ProjectAnnualGoal> | null;
    /**
     * 
     * @type {Array<ProjectLongTermGoal>}
     * @memberof GoalByProject
     */
    'longTermGoal'?: Array<ProjectLongTermGoal> | null;
}
/**
 * 
 * @export
 * @interface GoalByProjectControllerResponse
 */
export interface GoalByProjectControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GoalByProjectControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GoalByProjectControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GoalByProjectResponse}
     * @memberof GoalByProjectControllerResponse
     */
    'responseObject'?: GoalByProjectResponse;
}
/**
 * 
 * @export
 * @interface GoalByProjectResponse
 */
export interface GoalByProjectResponse {
    /**
     * 
     * @type {GoalByProject}
     * @memberof GoalByProjectResponse
     */
    'value'?: GoalByProject;
}
/**
 * 
 * @export
 * @interface GoalCategoryIconlistResponseDto
 */
export interface GoalCategoryIconlistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GoalCategoryIconlistResponseDto
     */
    'goalCategoryIconID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalCategoryIconlistResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalCategoryIconlistResponseDto
     */
    'iconSource'?: string | null;
}
/**
 * 
 * @export
 * @interface GoalCategoryIconlistResponseDtoListControllerResponse
 */
export interface GoalCategoryIconlistResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GoalCategoryIconlistResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GoalCategoryIconlistResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {GoalCategoryIconlistResponseDtoListResponse}
     * @memberof GoalCategoryIconlistResponseDtoListControllerResponse
     */
    'responseObject'?: GoalCategoryIconlistResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface GoalCategoryIconlistResponseDtoListResponse
 */
export interface GoalCategoryIconlistResponseDtoListResponse {
    /**
     * 
     * @type {Array<GoalCategoryIconlistResponseDto>}
     * @memberof GoalCategoryIconlistResponseDtoListResponse
     */
    'value'?: Array<GoalCategoryIconlistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface GoalKPIRelationship
 */
export interface GoalKPIRelationship {
    /**
     * 
     * @type {string}
     * @memberof GoalKPIRelationship
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GoalKPIRelationship
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GoalKPIRelationship
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GoalKPIRelationship
     */
    'highestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalKPIRelationship
     */
    'lowestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalKPIRelationship
     */
    'relationshipID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalKPIRelationship
     */
    'highestNameValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalKPIRelationship
     */
    'lowestNameValue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GoalKPIRelationship
     */
    'highestNumberValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GoalKPIRelationship
     */
    'lowestNumberValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GoalKPIRelationship
     */
    'highestObjectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalKPIRelationship
     */
    'lowestObjectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface GoalProjectRelationship
 */
export interface GoalProjectRelationship {
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationship
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GoalProjectRelationship
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GoalProjectRelationship
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationship
     */
    'highestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationship
     */
    'lowestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationship
     */
    'relationshipID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationship
     */
    'highestNameValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationship
     */
    'lowestNameValue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GoalProjectRelationship
     */
    'highestNumberValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GoalProjectRelationship
     */
    'lowestNumberValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationship
     */
    'highestObjectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationship
     */
    'lowestObjectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface GoalProjectRelationships
 */
export interface GoalProjectRelationships {
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationships
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GoalProjectRelationships
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GoalProjectRelationships
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationships
     */
    'highestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationships
     */
    'lowestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationships
     */
    'relationshipID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationships
     */
    'highestNameValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationships
     */
    'lowestNameValue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GoalProjectRelationships
     */
    'highestNumberValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GoalProjectRelationships
     */
    'lowestNumberValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationships
     */
    'highestObjectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationships
     */
    'lowestObjectCode'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const HttpRequestError = {
    Unknown: 'unknown',
    NameResolutionError: 'nameResolutionError',
    ConnectionError: 'connectionError',
    SecureConnectionError: 'secureConnectionError',
    HttpProtocolError: 'httpProtocolError',
    ExtendedConnectNotSupported: 'extendedConnectNotSupported',
    VersionNegotiationError: 'versionNegotiationError',
    UserAuthenticationError: 'userAuthenticationError',
    ProxyTunnelError: 'proxyTunnelError',
    InvalidResponse: 'invalidResponse',
    ResponseEnded: 'responseEnded',
    ConfigurationLimitExceeded: 'configurationLimitExceeded'
} as const;

export type HttpRequestError = typeof HttpRequestError[keyof typeof HttpRequestError];


/**
 * 
 * @export
 * @enum {string}
 */

export const HttpStatusCode = {
    Continue: 'continue',
    SwitchingProtocols: 'switchingProtocols',
    Processing: 'processing',
    EarlyHints: 'earlyHints',
    Ok: 'ok',
    Created: 'created',
    Accepted: 'accepted',
    NonAuthoritativeInformation: 'nonAuthoritativeInformation',
    NoContent: 'noContent',
    ResetContent: 'resetContent',
    PartialContent: 'partialContent',
    MultiStatus: 'multiStatus',
    AlreadyReported: 'alreadyReported',
    ImUsed: 'imUsed',
    Ambiguous: 'ambiguous',
    Moved: 'moved',
    Redirect: 'redirect',
    RedirectMethod: 'redirectMethod',
    NotModified: 'notModified',
    UseProxy: 'useProxy',
    Unused: 'unused',
    RedirectKeepVerb: 'redirectKeepVerb',
    PermanentRedirect: 'permanentRedirect',
    BadRequest: 'badRequest',
    Unauthorized: 'unauthorized',
    PaymentRequired: 'paymentRequired',
    Forbidden: 'forbidden',
    NotFound: 'notFound',
    MethodNotAllowed: 'methodNotAllowed',
    NotAcceptable: 'notAcceptable',
    ProxyAuthenticationRequired: 'proxyAuthenticationRequired',
    RequestTimeout: 'requestTimeout',
    Conflict: 'conflict',
    Gone: 'gone',
    LengthRequired: 'lengthRequired',
    PreconditionFailed: 'preconditionFailed',
    RequestEntityTooLarge: 'requestEntityTooLarge',
    RequestUriTooLong: 'requestUriTooLong',
    UnsupportedMediaType: 'unsupportedMediaType',
    RequestedRangeNotSatisfiable: 'requestedRangeNotSatisfiable',
    ExpectationFailed: 'expectationFailed',
    MisdirectedRequest: 'misdirectedRequest',
    UnprocessableContent: 'unprocessableContent',
    Locked: 'locked',
    FailedDependency: 'failedDependency',
    UpgradeRequired: 'upgradeRequired',
    PreconditionRequired: 'preconditionRequired',
    TooManyRequests: 'tooManyRequests',
    RequestHeaderFieldsTooLarge: 'requestHeaderFieldsTooLarge',
    UnavailableForLegalReasons: 'unavailableForLegalReasons',
    InternalServerError: 'internalServerError',
    NotImplemented: 'notImplemented',
    BadGateway: 'badGateway',
    ServiceUnavailable: 'serviceUnavailable',
    GatewayTimeout: 'gatewayTimeout',
    HttpVersionNotSupported: 'httpVersionNotSupported',
    VariantAlsoNegotiates: 'variantAlsoNegotiates',
    InsufficientStorage: 'insufficientStorage',
    LoopDetected: 'loopDetected',
    NotExtended: 'notExtended',
    NetworkAuthenticationRequired: 'networkAuthenticationRequired'
} as const;

export type HttpStatusCode = typeof HttpStatusCode[keyof typeof HttpStatusCode];


/**
 * 
 * @export
 * @interface InserXMatrixDivisionRequestDto
 */
export interface InserXMatrixDivisionRequestDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof InserXMatrixDivisionRequestDto
     */
    'longtermGoalID'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InserXMatrixDivisionRequestDto
     */
    'annualGoalID'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InserXMatrixDivisionRequestDto
     */
    'projectID'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof InserXMatrixDivisionRequestDto
     */
    'year': string;
    /**
     * 
     * @type {string}
     * @memberof InserXMatrixDivisionRequestDto
     */
    'parentXMatrixID': string;
    /**
     * 
     * @type {string}
     * @memberof InserXMatrixDivisionRequestDto
     */
    'divisionID': string;
}
/**
 * 
 * @export
 * @interface InsertActivityPlanningImportRequestDto
 */
export interface InsertActivityPlanningImportRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertActivityPlanningImportRequestDto
     */
    'userID': string;
    /**
     * 
     * @type {number}
     * @memberof InsertActivityPlanningImportRequestDto
     */
    'monthlyTotal': number;
    /**
     * 
     * @type {string}
     * @memberof InsertActivityPlanningImportRequestDto
     */
    'referencePeriod': string;
    /**
     * 
     * @type {number}
     * @memberof InsertActivityPlanningImportRequestDto
     */
    'annualBudget': number;
}
/**
 * 
 * @export
 * @interface InsertAlertConfigurationRequestDto
 */
export interface InsertAlertConfigurationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertAlertConfigurationRequestDto
     */
    'companyID': string;
    /**
     * 
     * @type {boolean}
     * @memberof InsertAlertConfigurationRequestDto
     */
    'allInOne': boolean;
    /**
     * 
     * @type {number}
     * @memberof InsertAlertConfigurationRequestDto
     */
    'reminderInDaysFrequence': number;
    /**
     * 
     * @type {boolean}
     * @memberof InsertAlertConfigurationRequestDto
     */
    'sendViaMail': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InsertAlertConfigurationRequestDto
     */
    'sendViaNotificationBell': boolean;
    /**
     * 
     * @type {number}
     * @memberof InsertAlertConfigurationRequestDto
     */
    'periodo'?: number | null;
}
/**
 * 
 * @export
 * @interface InsertAlertConfigurationResponseDto
 */
export interface InsertAlertConfigurationResponseDto {
    /**
     * 
     * @type {string}
     * @memberof InsertAlertConfigurationResponseDto
     */
    'alertID': string;
}
/**
 * 
 * @export
 * @interface InsertAlertConfigurationResponseDtoControllerResponse
 */
export interface InsertAlertConfigurationResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof InsertAlertConfigurationResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof InsertAlertConfigurationResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {InsertAlertConfigurationResponseDtoResponse}
     * @memberof InsertAlertConfigurationResponseDtoControllerResponse
     */
    'responseObject'?: InsertAlertConfigurationResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface InsertAlertConfigurationResponseDtoResponse
 */
export interface InsertAlertConfigurationResponseDtoResponse {
    /**
     * 
     * @type {InsertAlertConfigurationResponseDto}
     * @memberof InsertAlertConfigurationResponseDtoResponse
     */
    'value'?: InsertAlertConfigurationResponseDto;
}
/**
 * 
 * @export
 * @interface InsertAnnualGoalRequestDto
 */
export interface InsertAnnualGoalRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertAnnualGoalRequestDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertAnnualGoalRequestDto
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof InsertAnnualGoalRequestDto
     */
    'referencePeriod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertAnnualGoalRequestDto
     */
    'owner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertAnnualGoalRequestDto
     */
    'notes'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertAnnualGoalXRequestDto
 */
export interface InsertAnnualGoalXRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertAnnualGoalXRequestDto
     */
    'descripton'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertAnnualGoalXRequestDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertAnnualGoalXRequestDto
     */
    'xMatrixID': string;
}
/**
 * 
 * @export
 * @interface InsertDeliverableRequestDto
 */
export interface InsertDeliverableRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertDeliverableRequestDto
     */
    'parentDeliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertDeliverableRequestDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertDeliverableRequestDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertDeliverableRequestDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertDeliverableRequestDto
     */
    'tag'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertDeliverableRequestDto
     */
    'budget'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertDeliverableRequestDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertDeliverableRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertDeliverableRequestDto
     */
    'priority'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertDeliverableRequestDto
     */
    'projectID': string;
    /**
     * 
     * @type {string}
     * @memberof InsertDeliverableRequestDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertDeliverableRequestDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertDeliverableRequestDto
     */
    'genericResponsible'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsertDeliverableRequestDto
     */
    'isGenericResponsabile'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsertDeliverableRequestDto
     */
    'updateParentEndDate'?: boolean | null;
}
/**
 * 
 * @export
 * @interface InsertFeedActivityLikeRequestDto
 */
export interface InsertFeedActivityLikeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertFeedActivityLikeRequestDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedActivityLikeRequestDto
     */
    'userID'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertFeedCommentRequestDto
 */
export interface InsertFeedCommentRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertFeedCommentRequestDto
     */
    'regardingObjectID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertFeedCommentRequestDto
     */
    'regardingObjectTypeCode'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedCommentRequestDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedCommentRequestDto
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedCommentRequestDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedCommentRequestDto
     */
    'parentActivityID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsertFeedCommentRequestDto
     */
    'priority'?: boolean | null;
}
/**
 * 
 * @export
 * @interface InsertFeedFileRequestDto
 */
export interface InsertFeedFileRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertFeedFileRequestDto
     */
    'regardingObjectID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertFeedFileRequestDto
     */
    'regardingObjectTypeCode'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedFileRequestDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedFileRequestDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedFileRequestDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedFileRequestDto
     */
    'parentActivityID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsertFeedFileRequestDto
     */
    'priority'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedFileRequestDto
     */
    'documentURL'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertFeedMeetingRequestDto
 */
export interface InsertFeedMeetingRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertFeedMeetingRequestDto
     */
    'regardingObjectID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertFeedMeetingRequestDto
     */
    'regardingObjectTypeCode'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedMeetingRequestDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedMeetingRequestDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedMeetingRequestDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedMeetingRequestDto
     */
    'parentActivityID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsertFeedMeetingRequestDto
     */
    'priority'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedMeetingRequestDto
     */
    'scheduledStart'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedMeetingRequestDto
     */
    'scheduledEnd'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedMeetingRequestDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {Array<UsersObject>}
     * @memberof InsertFeedMeetingRequestDto
     */
    'users'?: Array<UsersObject> | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedMeetingRequestDto
     */
    'startTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedMeetingRequestDto
     */
    'endTime'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertFeedMeetingRequestDto
     */
    'duration'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsertFeedMeetingRequestDto
     */
    'integrateInOutlook'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsertFeedMeetingRequestDto
     */
    'integrateInTeams'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedMeetingRequestDto
     */
    'outlookEventId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedMeetingRequestDto
     */
    'timezone'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertFeedNotificationRequestDto
 */
export interface InsertFeedNotificationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertFeedNotificationRequestDto
     */
    'regardingObjectID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertFeedNotificationRequestDto
     */
    'regardingObjectTypeCode'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedNotificationRequestDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedNotificationRequestDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedNotificationRequestDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertGoalCategoryRequestDto
 */
export interface InsertGoalCategoryRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertGoalCategoryRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertGoalCategoryRequestDto
     */
    'goalCategoryIconID'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertKPIDetailRequestDto
 */
export interface InsertKPIDetailRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertKPIDetailRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertKPIDetailRequestDto
     */
    'unitOfMeasure'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertKPIDetailRequestDto
     */
    'additionalNote'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertKPIDetailRequestDto
     */
    'calculationType'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InsertKPIDetailRequestDto
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InsertKPIDetailRequestDto
     */
    'actualYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InsertKPIDetailRequestDto
     */
    'type'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InsertKPIDetailRequestDto
     */
    'parentType'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsertKPIDetailRequestDto
     */
    'isRollupKPI'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsertKPIDetailRequestDto
     */
    'isVisible'?: boolean | null;
}
/**
 * 
 * @export
 * @interface InsertKPIDetailResponse
 */
export interface InsertKPIDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof InsertKPIDetailResponse
     */
    'kpiDetailID': string;
}
/**
 * 
 * @export
 * @interface InsertKPIDetailResponseControllerResponse
 */
export interface InsertKPIDetailResponseControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof InsertKPIDetailResponseControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof InsertKPIDetailResponseControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {InsertKPIDetailResponseResponse}
     * @memberof InsertKPIDetailResponseControllerResponse
     */
    'responseObject'?: InsertKPIDetailResponseResponse;
}
/**
 * 
 * @export
 * @interface InsertKPIDetailResponseResponse
 */
export interface InsertKPIDetailResponseResponse {
    /**
     * 
     * @type {InsertKPIDetailResponse}
     * @memberof InsertKPIDetailResponseResponse
     */
    'value'?: InsertKPIDetailResponse;
}
/**
 * 
 * @export
 * @interface InsertKPIProjectRequestDto
 */
export interface InsertKPIProjectRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertKPIProjectRequestDto
     */
    'kpiDetailID': string;
    /**
     * 
     * @type {string}
     * @memberof InsertKPIProjectRequestDto
     */
    'projectID': string;
}
/**
 * 
 * @export
 * @interface InsertKPIXMatrixRequestDto
 */
export interface InsertKPIXMatrixRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertKPIXMatrixRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertKPIXMatrixRequestDto
     */
    'kpiDetailID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertKPIXMatrixRequestDto
     */
    'xMatrixID': string;
    /**
     * 
     * @type {number}
     * @memberof InsertKPIXMatrixRequestDto
     */
    'calculationType'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InsertKPIXMatrixRequestDto
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InsertKPIXMatrixRequestDto
     */
    'parentType'?: number | null;
}
/**
 * 
 * @export
 * @interface InsertLongTermGoalRequestDto
 */
export interface InsertLongTermGoalRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertLongTermGoalRequestDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertLongTermGoalRequestDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertLongTermGoalRequestDto
     */
    'goalTypeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertLongTermGoalRequestDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsertLongTermGoalRequestDto
     */
    'isRelatedVMV'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof InsertLongTermGoalRequestDto
     */
    'status': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof InsertLongTermGoalRequestDto
     */
    'goalCategory'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface InsertLongTermGoalResponseDto
 */
export interface InsertLongTermGoalResponseDto {
    /**
     * 
     * @type {string}
     * @memberof InsertLongTermGoalResponseDto
     */
    'longTermGoalID'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertLongTermGoalResponseDtoControllerResponse
 */
export interface InsertLongTermGoalResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof InsertLongTermGoalResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof InsertLongTermGoalResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {InsertLongTermGoalResponseDtoResponse}
     * @memberof InsertLongTermGoalResponseDtoControllerResponse
     */
    'responseObject'?: InsertLongTermGoalResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface InsertLongTermGoalResponseDtoResponse
 */
export interface InsertLongTermGoalResponseDtoResponse {
    /**
     * 
     * @type {InsertLongTermGoalResponseDto}
     * @memberof InsertLongTermGoalResponseDtoResponse
     */
    'value'?: InsertLongTermGoalResponseDto;
}
/**
 * 
 * @export
 * @interface InsertLongTermGoalXRequestDto
 */
export interface InsertLongTermGoalXRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertLongTermGoalXRequestDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertLongTermGoalXRequestDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertLongTermGoalXRequestDto
     */
    'xMatrixID': string;
}
/**
 * 
 * @export
 * @interface InsertMacroProjectRequest
 */
export interface InsertMacroProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof InsertMacroProjectRequest
     */
    'macroProjectCode': string;
    /**
     * 
     * @type {string}
     * @memberof InsertMacroProjectRequest
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsertMacroProjectRequest
     */
    'isDeleted': boolean;
}
/**
 * 
 * @export
 * @interface InsertMeetingParticipantRequestDto
 */
export interface InsertMeetingParticipantRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertMeetingParticipantRequestDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertMeetingParticipantRequestDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertMeetingParticipantRequestDto
     */
    'participiantTypeMask'?: number | null;
}
/**
 * 
 * @export
 * @interface InsertMyActivityRequestDto
 */
export interface InsertMyActivityRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertMyActivityRequestDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertMyActivityRequestDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertMyActivityRequestDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertMyActivityRequestDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertMyActivityRequestDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertMyActivityRequestDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertMyActivityRequestDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertMyActivityRequestDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertMyActivityRequestDto
     */
    'committeeID'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertMyActivityResponseDto
 */
export interface InsertMyActivityResponseDto {
    /**
     * 
     * @type {string}
     * @memberof InsertMyActivityResponseDto
     */
    'activityID'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertMyActivityResponseDtoControllerResponse
 */
export interface InsertMyActivityResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof InsertMyActivityResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof InsertMyActivityResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {InsertMyActivityResponseDtoResponse}
     * @memberof InsertMyActivityResponseDtoControllerResponse
     */
    'responseObject'?: InsertMyActivityResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface InsertMyActivityResponseDtoResponse
 */
export interface InsertMyActivityResponseDtoResponse {
    /**
     * 
     * @type {InsertMyActivityResponseDto}
     * @memberof InsertMyActivityResponseDtoResponse
     */
    'value'?: InsertMyActivityResponseDto;
}
/**
 * 
 * @export
 * @interface InsertNextYearXMatrixRequestDto
 */
export interface InsertNextYearXMatrixRequestDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof InsertNextYearXMatrixRequestDto
     */
    'annualGoalID'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InsertNextYearXMatrixRequestDto
     */
    'projectIDs'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof InsertNextYearXMatrixRequestDto
     */
    'year': string;
}
/**
 * 
 * @export
 * @interface InsertNextYearXMatrixResponseDto
 */
export interface InsertNextYearXMatrixResponseDto {
    /**
     * 
     * @type {string}
     * @memberof InsertNextYearXMatrixResponseDto
     */
    'xMatrixID'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertNextYearXMatrixResponseDtoControllerResponse
 */
export interface InsertNextYearXMatrixResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof InsertNextYearXMatrixResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof InsertNextYearXMatrixResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {InsertNextYearXMatrixResponseDtoResponse}
     * @memberof InsertNextYearXMatrixResponseDtoControllerResponse
     */
    'responseObject'?: InsertNextYearXMatrixResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface InsertNextYearXMatrixResponseDtoResponse
 */
export interface InsertNextYearXMatrixResponseDtoResponse {
    /**
     * 
     * @type {InsertNextYearXMatrixResponseDto}
     * @memberof InsertNextYearXMatrixResponseDtoResponse
     */
    'value'?: InsertNextYearXMatrixResponseDto;
}
/**
 * 
 * @export
 * @interface InsertProjectCostDetailRequestDto
 */
export interface InsertProjectCostDetailRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertProjectCostDetailRequestDto
     */
    'projectID': string;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectCostDetailRequestDto
     */
    'ownerID': string;
    /**
     * 
     * @type {number}
     * @memberof InsertProjectCostDetailRequestDto
     */
    'type': number;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectCostDetailRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InsertProjectCostDetailRequestDto
     */
    'deliverables'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface InsertProjectCostDetailResponseDto
 */
export interface InsertProjectCostDetailResponseDto {
    /**
     * 
     * @type {string}
     * @memberof InsertProjectCostDetailResponseDto
     */
    'projectCostDetailID': string;
}
/**
 * 
 * @export
 * @interface InsertProjectCostDetailResponseDtoControllerResponse
 */
export interface InsertProjectCostDetailResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof InsertProjectCostDetailResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectCostDetailResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {InsertProjectCostDetailResponseDtoResponse}
     * @memberof InsertProjectCostDetailResponseDtoControllerResponse
     */
    'responseObject'?: InsertProjectCostDetailResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface InsertProjectCostDetailResponseDtoResponse
 */
export interface InsertProjectCostDetailResponseDtoResponse {
    /**
     * 
     * @type {InsertProjectCostDetailResponseDto}
     * @memberof InsertProjectCostDetailResponseDtoResponse
     */
    'value'?: InsertProjectCostDetailResponseDto;
}
/**
 * 
 * @export
 * @interface InsertProjectDeliverableRequestDto
 */
export interface InsertProjectDeliverableRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertProjectDeliverableRequestDto
     */
    'projectID': string;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectDeliverableRequestDto
     */
    'parentDeliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectDeliverableRequestDto
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectDeliverableRequestDto
     */
    'startDate': string;
    /**
     * 
     * @type {number}
     * @memberof InsertProjectDeliverableRequestDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectDeliverableRequestDto
     */
    'tag'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertProjectDeliverableRequestDto
     */
    'budget': number;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectDeliverableRequestDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectDeliverableRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertProjectDeliverableRequestDto
     */
    'priority': number;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectDeliverableRequestDto
     */
    'owner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectDeliverableRequestDto
     */
    'comment'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertProjectMemberRequestDto
 */
export interface InsertProjectMemberRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertProjectMemberRequestDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectMemberRequestDto
     */
    'userID'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertProjectMemberResponseDto
 */
export interface InsertProjectMemberResponseDto {
    /**
     * 
     * @type {string}
     * @memberof InsertProjectMemberResponseDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertProjectMemberResponseDtoControllerResponse
 */
export interface InsertProjectMemberResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof InsertProjectMemberResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectMemberResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {InsertProjectMemberResponseDtoResponse}
     * @memberof InsertProjectMemberResponseDtoControllerResponse
     */
    'responseObject'?: InsertProjectMemberResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface InsertProjectMemberResponseDtoResponse
 */
export interface InsertProjectMemberResponseDtoResponse {
    /**
     * 
     * @type {InsertProjectMemberResponseDto}
     * @memberof InsertProjectMemberResponseDtoResponse
     */
    'value'?: InsertProjectMemberResponseDto;
}
/**
 * 
 * @export
 * @interface InsertProjectRepositoryRequestDto
 */
export interface InsertProjectRepositoryRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRepositoryRequestDto
     */
    'projectRepositoryID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRepositoryRequestDto
     */
    'projectID': string;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRepositoryRequestDto
     */
    'link'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRepositoryRequestDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertProjectRoutineMeetingRequestDto
 */
export interface InsertProjectRoutineMeetingRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineMeetingRequestDto
     */
    'projectRoutineID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineMeetingRequestDto
     */
    'regardingObjectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineMeetingRequestDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineMeetingRequestDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineMeetingRequestDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertProjectRoutineRequestDto
 */
export interface InsertProjectRoutineRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineRequestDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineRequestDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertProjectRoutineRequestDto
     */
    'schedule'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineRequestDto
     */
    'projectID': string;
    /**
     * 
     * @type {number}
     * @memberof InsertProjectRoutineRequestDto
     */
    'duration'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InsertProjectRoutineRequestDto
     */
    'who'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineRequestDto
     */
    'startTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineRequestDto
     */
    'endTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsertProjectRoutineRequestDto
     */
    'integrateInOutlook': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InsertProjectRoutineRequestDto
     */
    'integrateInTeams': boolean;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineRequestDto
     */
    'outlookEventId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertProjectRoutineRequestDto
     */
    'frequenceType'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InsertProjectRoutineRequestDto
     */
    'frequence'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InsertProjectRoutineRequestDto
     */
    'dayOfWeek'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineRequestDto
     */
    'timezone'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InsertProjectRoutineRequestDto
     */
    'extraUsersRoutine'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface InsertProjectRoutineResponseDto
 */
export interface InsertProjectRoutineResponseDto {
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineResponseDto
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineResponseDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineResponseDto
     */
    'projectDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertProjectRoutineResponseDtoControllerResponse
 */
export interface InsertProjectRoutineResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof InsertProjectRoutineResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {InsertProjectRoutineResponseDtoResponse}
     * @memberof InsertProjectRoutineResponseDtoControllerResponse
     */
    'responseObject'?: InsertProjectRoutineResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface InsertProjectRoutineResponseDtoResponse
 */
export interface InsertProjectRoutineResponseDtoResponse {
    /**
     * 
     * @type {InsertProjectRoutineResponseDto}
     * @memberof InsertProjectRoutineResponseDtoResponse
     */
    'value'?: InsertProjectRoutineResponseDto;
}
/**
 * 
 * @export
 * @interface InsertProjectXMatrixRequestDto
 */
export interface InsertProjectXMatrixRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertProjectXMatrixRequestDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectXMatrixRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectXMatrixRequestDto
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectXMatrixRequestDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectXMatrixRequestDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsertProjectXMatrixRequestDto
     */
    'hasExternalReview'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectXMatrixRequestDto
     */
    'note'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertProjectXMatrixRequestDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectXMatrixRequestDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectXMatrixRequestDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectXMatrixRequestDto
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectXMatrixRequestDto
     */
    'xMatrixID': string;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectXMatrixRequestDto
     */
    'parentProjectID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsertProjectXMatrixRequestDto
     */
    'secondaryProject': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InsertProjectXMatrixRequestDto
     */
    'fastCreation': boolean;
}
/**
 * 
 * @export
 * @interface InsertProjectXMatrixResponse
 */
export interface InsertProjectXMatrixResponse {
    /**
     * 
     * @type {string}
     * @memberof InsertProjectXMatrixResponse
     */
    'projectID'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertProjectXMatrixResponseControllerResponse
 */
export interface InsertProjectXMatrixResponseControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof InsertProjectXMatrixResponseControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectXMatrixResponseControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {InsertProjectXMatrixResponseResponse}
     * @memberof InsertProjectXMatrixResponseControllerResponse
     */
    'responseObject'?: InsertProjectXMatrixResponseResponse;
}
/**
 * 
 * @export
 * @interface InsertProjectXMatrixResponseResponse
 */
export interface InsertProjectXMatrixResponseResponse {
    /**
     * 
     * @type {InsertProjectXMatrixResponse}
     * @memberof InsertProjectXMatrixResponseResponse
     */
    'value'?: InsertProjectXMatrixResponse;
}
/**
 * 
 * @export
 * @interface InsertRiskRequestDto
 */
export interface InsertRiskRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertRiskRequestDto
     */
    'riskDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertRiskRequestDto
     */
    'riskComment'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertRiskRequestDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InsertRiskRequestDto
     */
    'severity'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertRiskRequestDto
     */
    'projectID': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InsertRiskRequestDto
     */
    'precautions'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface InsertSecondLevelMacroProjectsRelationshipRequestDto
 */
export interface InsertSecondLevelMacroProjectsRelationshipRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertSecondLevelMacroProjectsRelationshipRequestDto
     */
    'secondLevelXmatrixID': string;
    /**
     * 
     * @type {string}
     * @memberof InsertSecondLevelMacroProjectsRelationshipRequestDto
     */
    'macroProjectID': string;
    /**
     * 
     * @type {number}
     * @memberof InsertSecondLevelMacroProjectsRelationshipRequestDto
     */
    'correlationCoefficient': number;
}
/**
 * 
 * @export
 * @interface InsertSystemUserRequestDto
 */
export interface InsertSystemUserRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertSystemUserRequestDto
     */
    'userID': string;
    /**
     * 
     * @type {string}
     * @memberof InsertSystemUserRequestDto
     */
    'committeeMembersID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertSystemUserRequestDto
     */
    'type': number;
    /**
     * 
     * @type {string}
     * @memberof InsertSystemUserRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertSystemUserRequestDto
     */
    'surname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertSystemUserRequestDto
     */
    'jobTitle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertSystemUserRequestDto
     */
    'availability': number;
    /**
     * 
     * @type {string}
     * @memberof InsertSystemUserRequestDto
     */
    'department'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertSystemUserRequestDto
     */
    'dailyRate': number;
    /**
     * 
     * @type {string}
     * @memberof InsertSystemUserRequestDto
     */
    'emailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertSystemUserRequestDto
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertSystemUserRequestDto
     */
    'roleId': string;
}
/**
 * 
 * @export
 * @interface InsertUpdateAgendaRequestDto
 */
export interface InsertUpdateAgendaRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertUpdateAgendaRequestDto
     */
    'agendaID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertUpdateAgendaRequestDto
     */
    'scope'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertUpdateAgendaRequestDto
     */
    'duration': number;
    /**
     * 
     * @type {string}
     * @memberof InsertUpdateAgendaRequestDto
     */
    'frequency'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertUpdateAgendaRequestDto
     */
    'stateID': number;
    /**
     * 
     * @type {string}
     * @memberof InsertUpdateAgendaRequestDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertUpdateAgendaRequestDto
     */
    'committeeID': string;
    /**
     * 
     * @type {string}
     * @memberof InsertUpdateAgendaRequestDto
     */
    'sessionID'?: string | null;
    /**
     * 
     * @type {Array<AgendaItems>}
     * @memberof InsertUpdateAgendaRequestDto
     */
    'agendaItemsType'?: Array<AgendaItems> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InsertUpdateAgendaRequestDto
     */
    'projects'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface InsertUpdateCommitteeSessionRequestDto
 */
export interface InsertUpdateCommitteeSessionRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertUpdateCommitteeSessionRequestDto
     */
    'committeeID': string;
    /**
     * 
     * @type {string}
     * @memberof InsertUpdateCommitteeSessionRequestDto
     */
    'sessionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertUpdateCommitteeSessionRequestDto
     */
    'codice'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertUpdateCommitteeSessionRequestDto
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof InsertUpdateCommitteeSessionRequestDto
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof InsertUpdateCommitteeSessionRequestDto
     */
    'endTime': string;
    /**
     * 
     * @type {number}
     * @memberof InsertUpdateCommitteeSessionRequestDto
     */
    'duration': number;
    /**
     * 
     * @type {number}
     * @memberof InsertUpdateCommitteeSessionRequestDto
     */
    'state': number;
    /**
     * 
     * @type {string}
     * @memberof InsertUpdateCommitteeSessionRequestDto
     */
    'xMatrixID': string;
}
/**
 * 
 * @export
 * @interface InsertUpdateCommitteeSessionResponseDto
 */
export interface InsertUpdateCommitteeSessionResponseDto {
    /**
     * 
     * @type {string}
     * @memberof InsertUpdateCommitteeSessionResponseDto
     */
    'sessionID'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertUpdateCommitteeSessionResponseDtoControllerResponse
 */
export interface InsertUpdateCommitteeSessionResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof InsertUpdateCommitteeSessionResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof InsertUpdateCommitteeSessionResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {InsertUpdateCommitteeSessionResponseDtoResponse}
     * @memberof InsertUpdateCommitteeSessionResponseDtoControllerResponse
     */
    'responseObject'?: InsertUpdateCommitteeSessionResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface InsertUpdateCommitteeSessionResponseDtoResponse
 */
export interface InsertUpdateCommitteeSessionResponseDtoResponse {
    /**
     * 
     * @type {InsertUpdateCommitteeSessionResponseDto}
     * @memberof InsertUpdateCommitteeSessionResponseDtoResponse
     */
    'value'?: InsertUpdateCommitteeSessionResponseDto;
}
/**
 * 
 * @export
 * @interface InsertUserFavouriteKPIRequest
 */
export interface InsertUserFavouriteKPIRequest {
    /**
     * 
     * @type {string}
     * @memberof InsertUserFavouriteKPIRequest
     */
    'kpiDetailID'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertXMatrixDivisionResponseDto
 */
export interface InsertXMatrixDivisionResponseDto {
    /**
     * 
     * @type {string}
     * @memberof InsertXMatrixDivisionResponseDto
     */
    'xMatrixID'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertXMatrixDivisionResponseDtoControllerResponse
 */
export interface InsertXMatrixDivisionResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof InsertXMatrixDivisionResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof InsertXMatrixDivisionResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {InsertXMatrixDivisionResponseDtoResponse}
     * @memberof InsertXMatrixDivisionResponseDtoControllerResponse
     */
    'responseObject'?: InsertXMatrixDivisionResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface InsertXMatrixDivisionResponseDtoResponse
 */
export interface InsertXMatrixDivisionResponseDtoResponse {
    /**
     * 
     * @type {InsertXMatrixDivisionResponseDto}
     * @memberof InsertXMatrixDivisionResponseDtoResponse
     */
    'value'?: InsertXMatrixDivisionResponseDto;
}
/**
 * 
 * @export
 * @interface InsertXMatrixRequestDto
 */
export interface InsertXMatrixRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertXMatrixRequestDto
     */
    'year': string;
    /**
     * 
     * @type {string}
     * @memberof InsertXMatrixRequestDto
     */
    'xMatrixName'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertXMatrixResponseDto
 */
export interface InsertXMatrixResponseDto {
    /**
     * 
     * @type {string}
     * @memberof InsertXMatrixResponseDto
     */
    'xMatrixID'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertXMatrixResponseDtoControllerResponse
 */
export interface InsertXMatrixResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof InsertXMatrixResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof InsertXMatrixResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {InsertXMatrixResponseDtoResponse}
     * @memberof InsertXMatrixResponseDtoControllerResponse
     */
    'responseObject'?: InsertXMatrixResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface InsertXMatrixResponseDtoResponse
 */
export interface InsertXMatrixResponseDtoResponse {
    /**
     * 
     * @type {InsertXMatrixResponseDto}
     * @memberof InsertXMatrixResponseDtoResponse
     */
    'value'?: InsertXMatrixResponseDto;
}
/**
 * 
 * @export
 * @interface InsertXmatrixCycleRequestDto
 */
export interface InsertXmatrixCycleRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertXmatrixCycleRequestDto
     */
    'companyID': string;
    /**
     * 
     * @type {boolean}
     * @memberof InsertXmatrixCycleRequestDto
     */
    'isDeleted': boolean;
    /**
     * 
     * @type {string}
     * @memberof InsertXmatrixCycleRequestDto
     */
    'cycleName'?: string | null;
}
/**
 * 
 * @export
 * @interface KPI
 */
export interface KPI {
    /**
     * 
     * @type {string}
     * @memberof KPI
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPI
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KPI
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPI
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KPI
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KPI
     */
    'orderingValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KPI
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPI
     */
    'kpiid'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KPI
     */
    'target'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPI
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPI
     */
    'actualYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPI
     */
    'targetYTD'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof KPI
     */
    'isFavourite'?: boolean | null;
}
/**
 * 
 * @export
 * @interface KPIBowlingChartResponse
 */
export interface KPIBowlingChartResponse {
    /**
     * 
     * @type {string}
     * @memberof KPIBowlingChartResponse
     */
    'kpiDetailName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KPIBowlingChartResponse
     */
    'type': number;
    /**
     * 
     * @type {string}
     * @memberof KPIBowlingChartResponse
     */
    'typeDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KPIBowlingChartResponse
     */
    'parentType': number;
    /**
     * 
     * @type {string}
     * @memberof KPIBowlingChartResponse
     */
    'parentTypeDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KPIBowlingChartResponse
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KPIBowlingChartResponse
     */
    'kpiProjectRelationshipID': string;
    /**
     * 
     * @type {boolean}
     * @memberof KPIBowlingChartResponse
     */
    'isRollupKPI': boolean;
    /**
     * 
     * @type {string}
     * @memberof KPIBowlingChartResponse
     */
    'rollupKpiName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIBowlingChartResponse
     */
    'parentKPIProjectRelationshipID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIBowlingChartResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIBowlingChartResponse
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIBowlingChartResponse
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {{ [key: string]: number | null; }}
     * @memberof KPIBowlingChartResponse
     */
    'monthsList'?: { [key: string]: number | null; } | null;
}
/**
 * 
 * @export
 * @interface KPIBowlingChartResponseListControllerResponse
 */
export interface KPIBowlingChartResponseListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof KPIBowlingChartResponseListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof KPIBowlingChartResponseListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {KPIBowlingChartResponseListResponse}
     * @memberof KPIBowlingChartResponseListControllerResponse
     */
    'responseObject'?: KPIBowlingChartResponseListResponse;
}
/**
 * 
 * @export
 * @interface KPIBowlingChartResponseListResponse
 */
export interface KPIBowlingChartResponseListResponse {
    /**
     * 
     * @type {Array<KPIBowlingChartResponse>}
     * @memberof KPIBowlingChartResponseListResponse
     */
    'value'?: Array<KPIBowlingChartResponse> | null;
}
/**
 * 
 * @export
 * @interface KPIDetailsDto
 */
export interface KPIDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof KPIDetailsDto
     */
    'longTermGoalID': string;
}
/**
 * 
 * @export
 * @interface KPIDetailsDtoListControllerResponse
 */
export interface KPIDetailsDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof KPIDetailsDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof KPIDetailsDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {KPIDetailsDtoListResponse}
     * @memberof KPIDetailsDtoListControllerResponse
     */
    'responseObject'?: KPIDetailsDtoListResponse;
}
/**
 * 
 * @export
 * @interface KPIDetailsDtoListResponse
 */
export interface KPIDetailsDtoListResponse {
    /**
     * 
     * @type {Array<KPIDetailsDto>}
     * @memberof KPIDetailsDtoListResponse
     */
    'value'?: Array<KPIDetailsDto> | null;
}
/**
 * 
 * @export
 * @interface KPIDetailsGraficoA3ResponseDto
 */
export interface KPIDetailsGraficoA3ResponseDto {
    /**
     * 
     * @type {string}
     * @memberof KPIDetailsGraficoA3ResponseDto
     */
    'kpiProjectRelationShipID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KPIDetailsGraficoA3ResponseDto
     */
    'actual'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KPIDetailsGraficoA3ResponseDto
     */
    'monthReference'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KPIDetailsGraficoA3ResponseDto
     */
    'targetYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPIDetailsGraficoA3ResponseDto
     */
    'ytd'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPIDetailsGraficoA3ResponseDto
     */
    'calculationType'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPIDetailsGraficoA3ResponseDto
     */
    'parentType'?: number | null;
}
/**
 * 
 * @export
 * @interface KPIDetailsGraficoA3ResponseDtoListControllerResponse
 */
export interface KPIDetailsGraficoA3ResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof KPIDetailsGraficoA3ResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof KPIDetailsGraficoA3ResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {KPIDetailsGraficoA3ResponseDtoListResponse}
     * @memberof KPIDetailsGraficoA3ResponseDtoListControllerResponse
     */
    'responseObject'?: KPIDetailsGraficoA3ResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface KPIDetailsGraficoA3ResponseDtoListResponse
 */
export interface KPIDetailsGraficoA3ResponseDtoListResponse {
    /**
     * 
     * @type {Array<KPIDetailsGraficoA3ResponseDto>}
     * @memberof KPIDetailsGraficoA3ResponseDtoListResponse
     */
    'value'?: Array<KPIDetailsGraficoA3ResponseDto> | null;
}
/**
 * 
 * @export
 * @interface KPIDetailsPastYearsResponseDto
 */
export interface KPIDetailsPastYearsResponseDto {
    /**
     * 
     * @type {number}
     * @memberof KPIDetailsPastYearsResponseDto
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof KPIDetailsPastYearsResponseDto
     */
    'actual'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPIDetailsPastYearsResponseDto
     */
    'target'?: number | null;
}
/**
 * 
 * @export
 * @interface KPIDetailsPastYearsResponseDtoListControllerResponse
 */
export interface KPIDetailsPastYearsResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof KPIDetailsPastYearsResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof KPIDetailsPastYearsResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {KPIDetailsPastYearsResponseDtoListResponse}
     * @memberof KPIDetailsPastYearsResponseDtoListControllerResponse
     */
    'responseObject'?: KPIDetailsPastYearsResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface KPIDetailsPastYearsResponseDtoListResponse
 */
export interface KPIDetailsPastYearsResponseDtoListResponse {
    /**
     * 
     * @type {Array<KPIDetailsPastYearsResponseDto>}
     * @memberof KPIDetailsPastYearsResponseDtoListResponse
     */
    'value'?: Array<KPIDetailsPastYearsResponseDto> | null;
}
/**
 * 
 * @export
 * @interface KPIPicklistResponseDto
 */
export interface KPIPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof KPIPicklistResponseDto
     */
    'kpiDetailID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIPicklistResponseDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface KPIPicklistResponseDtoListControllerResponse
 */
export interface KPIPicklistResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof KPIPicklistResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof KPIPicklistResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {KPIPicklistResponseDtoListResponse}
     * @memberof KPIPicklistResponseDtoListControllerResponse
     */
    'responseObject'?: KPIPicklistResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface KPIPicklistResponseDtoListResponse
 */
export interface KPIPicklistResponseDtoListResponse {
    /**
     * 
     * @type {Array<KPIPicklistResponseDto>}
     * @memberof KPIPicklistResponseDtoListResponse
     */
    'value'?: Array<KPIPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface KPIProjectRelationship
 */
export interface KPIProjectRelationship {
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationship
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KPIProjectRelationship
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPIProjectRelationship
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationship
     */
    'highestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationship
     */
    'lowestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationship
     */
    'relationshipID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationship
     */
    'highestNameValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationship
     */
    'lowestNameValue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KPIProjectRelationship
     */
    'highestNumberValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPIProjectRelationship
     */
    'lowestNumberValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationship
     */
    'highestObjectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationship
     */
    'lowestObjectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface KPIProjectRelationships
 */
export interface KPIProjectRelationships {
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationships
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KPIProjectRelationships
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPIProjectRelationships
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationships
     */
    'highestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationships
     */
    'lowestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationships
     */
    'relationshipID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationships
     */
    'highestNameValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationships
     */
    'lowestNameValue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KPIProjectRelationships
     */
    'highestNumberValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPIProjectRelationships
     */
    'lowestNumberValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationships
     */
    'highestObjectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationships
     */
    'lowestObjectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface KPIs
 */
export interface KPIs {
    /**
     * 
     * @type {string}
     * @memberof KPIs
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIs
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KPIs
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPIs
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KPIs
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KPIs
     */
    'orderingValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KPIs
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIs
     */
    'kpiid'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KPIs
     */
    'target'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPIs
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPIs
     */
    'actualYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPIs
     */
    'targetYTD'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LayoutKind = {
    Sequential: 'sequential',
    Explicit: 'explicit',
    Auto: 'auto'
} as const;

export type LayoutKind = typeof LayoutKind[keyof typeof LayoutKind];


/**
 * 
 * @export
 * @interface LongTermAnnualGoalRelationship
 */
export interface LongTermAnnualGoalRelationship {
    /**
     * 
     * @type {string}
     * @memberof LongTermAnnualGoalRelationship
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LongTermAnnualGoalRelationship
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LongTermAnnualGoalRelationship
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermAnnualGoalRelationship
     */
    'highestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermAnnualGoalRelationship
     */
    'lowestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermAnnualGoalRelationship
     */
    'relationshipID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermAnnualGoalRelationship
     */
    'highestNameValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermAnnualGoalRelationship
     */
    'lowestNameValue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LongTermAnnualGoalRelationship
     */
    'highestNumberValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LongTermAnnualGoalRelationship
     */
    'lowestNumberValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermAnnualGoalRelationship
     */
    'highestObjectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermAnnualGoalRelationship
     */
    'lowestObjectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface LongTermGoal
 */
export interface LongTermGoal {
    /**
     * 
     * @type {string}
     * @memberof LongTermGoal
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoal
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LongTermGoal
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LongTermGoal
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoal
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LongTermGoal
     */
    'orderingValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoal
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoal
     */
    'longTermGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoal
     */
    'category'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoal
     */
    'sponsor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LongTermGoal
     */
    'isRelatedVMV'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoal
     */
    'longTermGoalType'?: string | null;
}
/**
 * 
 * @export
 * @interface LongTermGoalByAnnualGoalResponseDto
 */
export interface LongTermGoalByAnnualGoalResponseDto {
    /**
     * 
     * @type {string}
     * @memberof LongTermGoalByAnnualGoalResponseDto
     */
    'longTermGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoalByAnnualGoalResponseDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoalByAnnualGoalResponseDto
     */
    'goalTypeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoalByAnnualGoalResponseDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LongTermGoalByAnnualGoalResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoalByAnnualGoalResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoalByAnnualGoalResponseDto
     */
    'sponsorFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoalByAnnualGoalResponseDto
     */
    'goalTypeDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoalByAnnualGoalResponseDto
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LongTermGoalByAnnualGoalResponseDto
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoalByAnnualGoalResponseDto
     */
    'correlationDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface LongTermGoalData
 */
export interface LongTermGoalData {
    /**
     * 
     * @type {Array<LongTermGoalResponse>}
     * @memberof LongTermGoalData
     */
    'longTermGoalNotRelated'?: Array<LongTermGoalResponse> | null;
    /**
     * 
     * @type {Array<LongTermGoalResponse>}
     * @memberof LongTermGoalData
     */
    'longTermGoalRelated'?: Array<LongTermGoalResponse> | null;
}
/**
 * 
 * @export
 * @interface LongTermGoalPicklistResponseDto
 */
export interface LongTermGoalPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof LongTermGoalPicklistResponseDto
     */
    'longTermGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoalPicklistResponseDto
     */
    'title'?: string | null;
}
/**
 * 
 * @export
 * @interface LongTermGoalPicklistResponseDtoListControllerResponse
 */
export interface LongTermGoalPicklistResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof LongTermGoalPicklistResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoalPicklistResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {LongTermGoalPicklistResponseDtoListResponse}
     * @memberof LongTermGoalPicklistResponseDtoListControllerResponse
     */
    'responseObject'?: LongTermGoalPicklistResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface LongTermGoalPicklistResponseDtoListResponse
 */
export interface LongTermGoalPicklistResponseDtoListResponse {
    /**
     * 
     * @type {Array<LongTermGoalPicklistResponseDto>}
     * @memberof LongTermGoalPicklistResponseDtoListResponse
     */
    'value'?: Array<LongTermGoalPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface LongTermGoalResponse
 */
export interface LongTermGoalResponse {
    /**
     * 
     * @type {string}
     * @memberof LongTermGoalResponse
     */
    'longTermGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoalResponse
     */
    'longTermGoalName'?: string | null;
}
/**
 * 
 * @export
 * @interface LongTermGoals
 */
export interface LongTermGoals {
    /**
     * 
     * @type {string}
     * @memberof LongTermGoals
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoals
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LongTermGoals
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LongTermGoals
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoals
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LongTermGoals
     */
    'orderingValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoals
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoals
     */
    'longTermGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoals
     */
    'category'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoals
     */
    'sponsor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LongTermGoals
     */
    'isRelatedVMV'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoals
     */
    'longTermGoalType'?: string | null;
}
/**
 * 
 * @export
 * @interface LongTermObjectiveByIdResponse
 */
export interface LongTermObjectiveByIdResponse {
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveByIdResponse
     */
    'longTermGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveByIdResponse
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveByIdResponse
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveByIdResponse
     */
    'goalTypeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveByIdResponse
     */
    'goalTypeDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveByIdResponse
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveByIdResponse
     */
    'sponsorFullName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LongTermObjectiveByIdResponse
     */
    'status'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof LongTermObjectiveByIdResponse
     */
    'isRelatedVMV'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveByIdResponse
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveByIdResponse
     */
    'iconSource'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveByIdResponse
     */
    'goalCategoryID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveByIdResponse
     */
    'categoryName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveByIdResponse
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveByIdResponse
     */
    'xMatrixName'?: string | null;
    /**
     * 
     * @type {Array<GetAnnualGoalByLongTermGoalDto>}
     * @memberof LongTermObjectiveByIdResponse
     */
    'annualGoalList'?: Array<GetAnnualGoalByLongTermGoalDto> | null;
    /**
     * 
     * @type {Array<GetProjectByLongTermGoalDto>}
     * @memberof LongTermObjectiveByIdResponse
     */
    'projectList'?: Array<GetProjectByLongTermGoalDto> | null;
    /**
     * 
     * @type {Array<GetKPIByLongTermGoalResponseDto>}
     * @memberof LongTermObjectiveByIdResponse
     */
    'kpiList'?: Array<GetKPIByLongTermGoalResponseDto> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof LongTermObjectiveByIdResponse
     */
    'goalCategories'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface LongTermObjectiveByIdResponseControllerResponse
 */
export interface LongTermObjectiveByIdResponseControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof LongTermObjectiveByIdResponseControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveByIdResponseControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {LongTermObjectiveByIdResponseResponse}
     * @memberof LongTermObjectiveByIdResponseControllerResponse
     */
    'responseObject'?: LongTermObjectiveByIdResponseResponse;
}
/**
 * 
 * @export
 * @interface LongTermObjectiveByIdResponseResponse
 */
export interface LongTermObjectiveByIdResponseResponse {
    /**
     * 
     * @type {LongTermObjectiveByIdResponse}
     * @memberof LongTermObjectiveByIdResponseResponse
     */
    'value'?: LongTermObjectiveByIdResponse;
}
/**
 * 
 * @export
 * @interface LongTermObjectiveDto
 */
export interface LongTermObjectiveDto {
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveDto
     */
    'longTermGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveDto
     */
    'goalTypeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveDto
     */
    'goalTypeDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveDto
     */
    'sponsorFullName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LongTermObjectiveDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof LongTermObjectiveDto
     */
    'isRelatedVMV'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveDto
     */
    'iconSource'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveDto
     */
    'goalCategoryID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveDto
     */
    'categoryName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveDto
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveDto
     */
    'xMatrixName'?: string | null;
    /**
     * 
     * @type {Array<GetAnnualGoalByLongTermGoalDto>}
     * @memberof LongTermObjectiveDto
     */
    'annualGoalList'?: Array<GetAnnualGoalByLongTermGoalDto> | null;
    /**
     * 
     * @type {Array<GetProjectByLongTermGoalDto>}
     * @memberof LongTermObjectiveDto
     */
    'projectList'?: Array<GetProjectByLongTermGoalDto> | null;
    /**
     * 
     * @type {Array<GetKPIByLongTermGoalResponseDto>}
     * @memberof LongTermObjectiveDto
     */
    'kpiList'?: Array<GetKPIByLongTermGoalResponseDto> | null;
    /**
     * 
     * @type {Array<GetGoalCategoryResponseDto>}
     * @memberof LongTermObjectiveDto
     */
    'goalCategories'?: Array<GetGoalCategoryResponseDto> | null;
}
/**
 * 
 * @export
 * @interface LongTermObjectiveDtoListControllerResponse
 */
export interface LongTermObjectiveDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof LongTermObjectiveDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {LongTermObjectiveDtoListResponse}
     * @memberof LongTermObjectiveDtoListControllerResponse
     */
    'responseObject'?: LongTermObjectiveDtoListResponse;
}
/**
 * 
 * @export
 * @interface LongTermObjectiveDtoListResponse
 */
export interface LongTermObjectiveDtoListResponse {
    /**
     * 
     * @type {Array<LongTermObjectiveDto>}
     * @memberof LongTermObjectiveDtoListResponse
     */
    'value'?: Array<LongTermObjectiveDto> | null;
}
/**
 * 
 * @export
 * @interface MemberInfo
 */
export interface MemberInfo {
    /**
     * 
     * @type {MemberTypes}
     * @memberof MemberInfo
     */
    'memberType': MemberTypes;
    /**
     * 
     * @type {string}
     * @memberof MemberInfo
     */
    'name': string;
    /**
     * 
     * @type {Type}
     * @memberof MemberInfo
     */
    'declaringType'?: Type;
    /**
     * 
     * @type {Type}
     * @memberof MemberInfo
     */
    'reflectedType'?: Type;
    /**
     * 
     * @type {Module}
     * @memberof MemberInfo
     */
    'module': Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof MemberInfo
     */
    'customAttributes': Array<CustomAttributeData>;
    /**
     * 
     * @type {boolean}
     * @memberof MemberInfo
     */
    'isCollectible': boolean;
    /**
     * 
     * @type {number}
     * @memberof MemberInfo
     */
    'metadataToken': number;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const MemberTypes = {
    Constructor: 'constructor',
    Event: 'event',
    Field: 'field',
    Method: 'method',
    Property: 'property',
    TypeInfo: 'typeInfo',
    Custom: 'custom',
    NestedType: 'nestedType',
    All: 'all'
} as const;

export type MemberTypes = typeof MemberTypes[keyof typeof MemberTypes];


/**
 * 
 * @export
 * @enum {string}
 */

export const MethodAttributes = {
    ReuseSlot: 'reuseSlot',
    Private: 'private',
    FamAndAssem: 'famANDAssem',
    Assembly: 'assembly',
    Family: 'family',
    FamOrAssem: 'famORAssem',
    Public: 'public',
    MemberAccessMask: 'memberAccessMask',
    UnmanagedExport: 'unmanagedExport',
    Static: 'static',
    Final: 'final',
    Virtual: 'virtual',
    HideBySig: 'hideBySig',
    VtableLayoutMask: 'vtableLayoutMask',
    CheckAccessOnOverride: 'checkAccessOnOverride',
    Abstract: 'abstract',
    SpecialName: 'specialName',
    RtSpecialName: 'rtSpecialName',
    PinvokeImpl: 'pinvokeImpl',
    HasSecurity: 'hasSecurity',
    RequireSecObject: 'requireSecObject',
    ReservedMask: 'reservedMask'
} as const;

export type MethodAttributes = typeof MethodAttributes[keyof typeof MethodAttributes];


/**
 * 
 * @export
 * @interface MethodBase
 */
export interface MethodBase {
    /**
     * 
     * @type {MemberTypes}
     * @memberof MethodBase
     */
    'memberType': MemberTypes;
    /**
     * 
     * @type {string}
     * @memberof MethodBase
     */
    'name': string;
    /**
     * 
     * @type {Type}
     * @memberof MethodBase
     */
    'declaringType'?: Type;
    /**
     * 
     * @type {Type}
     * @memberof MethodBase
     */
    'reflectedType'?: Type;
    /**
     * 
     * @type {Module}
     * @memberof MethodBase
     */
    'module': Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof MethodBase
     */
    'customAttributes': Array<CustomAttributeData>;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isCollectible': boolean;
    /**
     * 
     * @type {number}
     * @memberof MethodBase
     */
    'metadataToken': number;
    /**
     * 
     * @type {MethodAttributes}
     * @memberof MethodBase
     */
    'attributes': MethodAttributes;
    /**
     * 
     * @type {MethodImplAttributes}
     * @memberof MethodBase
     */
    'methodImplementationFlags': MethodImplAttributes;
    /**
     * 
     * @type {CallingConventions}
     * @memberof MethodBase
     */
    'callingConvention': CallingConventions;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isAbstract': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isConstructor': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isFinal': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isHideBySig': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isSpecialName': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isStatic': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isVirtual': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isAssembly': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isFamily': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isFamilyAndAssembly': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isFamilyOrAssembly': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isPrivate': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isPublic': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isConstructedGenericMethod': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isGenericMethod': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isGenericMethodDefinition': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'containsGenericParameters': boolean;
    /**
     * 
     * @type {RuntimeMethodHandle}
     * @memberof MethodBase
     */
    'methodHandle': RuntimeMethodHandle;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isSecurityCritical': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isSecuritySafeCritical': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodBase
     */
    'isSecurityTransparent': boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const MethodImplAttributes = {
    Managed: 'managed',
    Native: 'native',
    Optil: 'optil',
    Runtime: 'runtime',
    Unmanaged: 'unmanaged',
    NoInlining: 'noInlining',
    ForwardRef: 'forwardRef',
    Synchronized: 'synchronized',
    NoOptimization: 'noOptimization',
    PreserveSig: 'preserveSig',
    AggressiveInlining: 'aggressiveInlining',
    AggressiveOptimization: 'aggressiveOptimization',
    InternalCall: 'internalCall',
    MaxMethodImplVal: 'maxMethodImplVal'
} as const;

export type MethodImplAttributes = typeof MethodImplAttributes[keyof typeof MethodImplAttributes];


/**
 * 
 * @export
 * @interface MethodInfo
 */
export interface MethodInfo {
    /**
     * 
     * @type {string}
     * @memberof MethodInfo
     */
    'name': string;
    /**
     * 
     * @type {Type}
     * @memberof MethodInfo
     */
    'declaringType'?: Type;
    /**
     * 
     * @type {Type}
     * @memberof MethodInfo
     */
    'reflectedType'?: Type;
    /**
     * 
     * @type {Module}
     * @memberof MethodInfo
     */
    'module': Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof MethodInfo
     */
    'customAttributes': Array<CustomAttributeData>;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isCollectible': boolean;
    /**
     * 
     * @type {number}
     * @memberof MethodInfo
     */
    'metadataToken': number;
    /**
     * 
     * @type {MethodAttributes}
     * @memberof MethodInfo
     */
    'attributes': MethodAttributes;
    /**
     * 
     * @type {MethodImplAttributes}
     * @memberof MethodInfo
     */
    'methodImplementationFlags': MethodImplAttributes;
    /**
     * 
     * @type {CallingConventions}
     * @memberof MethodInfo
     */
    'callingConvention': CallingConventions;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isAbstract': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isConstructor': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isFinal': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isHideBySig': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isSpecialName': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isStatic': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isVirtual': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isAssembly': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isFamily': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isFamilyAndAssembly': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isFamilyOrAssembly': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isPrivate': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isPublic': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isConstructedGenericMethod': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isGenericMethod': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isGenericMethodDefinition': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'containsGenericParameters': boolean;
    /**
     * 
     * @type {RuntimeMethodHandle}
     * @memberof MethodInfo
     */
    'methodHandle': RuntimeMethodHandle;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isSecurityCritical': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isSecuritySafeCritical': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodInfo
     */
    'isSecurityTransparent': boolean;
    /**
     * 
     * @type {MemberTypes}
     * @memberof MethodInfo
     */
    'memberType': MemberTypes;
    /**
     * 
     * @type {ParameterInfo}
     * @memberof MethodInfo
     */
    'returnParameter': ParameterInfo;
    /**
     * 
     * @type {Type}
     * @memberof MethodInfo
     */
    'returnType': Type;
    /**
     * 
     * @type {object}
     * @memberof MethodInfo
     */
    'returnTypeCustomAttributes': object;
}


/**
 * 
 * @export
 * @interface Module
 */
export interface Module {
    /**
     * 
     * @type {Assembly}
     * @memberof Module
     */
    'assembly': Assembly;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    'fullyQualifiedName': string;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof Module
     */
    'mdStreamVersion': number;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    'moduleVersionId': string;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    'scopeName': string;
    /**
     * 
     * @type {ModuleHandle}
     * @memberof Module
     */
    'moduleHandle': ModuleHandle;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof Module
     */
    'customAttributes': Array<CustomAttributeData>;
    /**
     * 
     * @type {number}
     * @memberof Module
     */
    'metadataToken': number;
}
/**
 * 
 * @export
 * @interface ModuleHandle
 */
export interface ModuleHandle {
    /**
     * 
     * @type {number}
     * @memberof ModuleHandle
     */
    'mdStreamVersion': number;
}
/**
 * 
 * @export
 * @interface Month
 */
export interface Month {
    /**
     * 
     * @type {string}
     * @memberof Month
     */
    'referencePeriod'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Month
     */
    'value'?: number | null;
}
/**
 * 
 * @export
 * @interface MonthBudget
 */
export interface MonthBudget {
    /**
     * 
     * @type {string}
     * @memberof MonthBudget
     */
    'referencePeriod'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MonthBudget
     */
    'value'?: number | null;
}
/**
 * 
 * @export
 * @interface MonthsList
 */
export interface MonthsList {
    /**
     * 
     * @type {string}
     * @memberof MonthsList
     */
    'referencePeriod'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MonthsList
     */
    'budget'?: number | null;
}
/**
 * 
 * @export
 * @interface MonthsListObj
 */
export interface MonthsListObj {
    /**
     * 
     * @type {string}
     * @memberof MonthsListObj
     */
    'referencePeriod': string;
    /**
     * 
     * @type {number}
     * @memberof MonthsListObj
     */
    'valueBudget'?: number | null;
}
/**
 * 
 * @export
 * @interface ObjectCodePicklistResponseDto
 */
export interface ObjectCodePicklistResponseDto {
    /**
     * 
     * @type {number}
     * @memberof ObjectCodePicklistResponseDto
     */
    'objectCodeListID': number;
    /**
     * 
     * @type {string}
     * @memberof ObjectCodePicklistResponseDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface ObjectCodePicklistResponseDtoListControllerResponse
 */
export interface ObjectCodePicklistResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ObjectCodePicklistResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ObjectCodePicklistResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ObjectCodePicklistResponseDtoListResponse}
     * @memberof ObjectCodePicklistResponseDtoListControllerResponse
     */
    'responseObject'?: ObjectCodePicklistResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface ObjectCodePicklistResponseDtoListResponse
 */
export interface ObjectCodePicklistResponseDtoListResponse {
    /**
     * 
     * @type {Array<ObjectCodePicklistResponseDto>}
     * @memberof ObjectCodePicklistResponseDtoListResponse
     */
    'value'?: Array<ObjectCodePicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ObjectControllerResponse
 */
export interface ObjectControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ObjectControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ObjectControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ObjectResponse}
     * @memberof ObjectControllerResponse
     */
    'responseObject'?: ObjectResponse;
}
/**
 * 
 * @export
 * @interface ObjectResponse
 */
export interface ObjectResponse {
    /**
     * 
     * @type {any}
     * @memberof ObjectResponse
     */
    'value'?: any | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ParameterAttributes = {
    None: 'none',
    In: 'in',
    Out: 'out',
    Lcid: 'lcid',
    Retval: 'retval',
    Optional: 'optional',
    HasDefault: 'hasDefault',
    HasFieldMarshal: 'hasFieldMarshal',
    Reserved3: 'reserved3',
    Reserved4: 'reserved4',
    ReservedMask: 'reservedMask'
} as const;

export type ParameterAttributes = typeof ParameterAttributes[keyof typeof ParameterAttributes];


/**
 * 
 * @export
 * @interface ParameterInfo
 */
export interface ParameterInfo {
    /**
     * 
     * @type {ParameterAttributes}
     * @memberof ParameterInfo
     */
    'attributes': ParameterAttributes;
    /**
     * 
     * @type {MemberInfo}
     * @memberof ParameterInfo
     */
    'member': MemberInfo;
    /**
     * 
     * @type {string}
     * @memberof ParameterInfo
     */
    'name'?: string | null;
    /**
     * 
     * @type {Type}
     * @memberof ParameterInfo
     */
    'parameterType': Type;
    /**
     * 
     * @type {number}
     * @memberof ParameterInfo
     */
    'position': number;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterInfo
     */
    'isIn': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterInfo
     */
    'isLcid': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterInfo
     */
    'isOptional': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterInfo
     */
    'isOut': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterInfo
     */
    'isRetval': boolean;
    /**
     * 
     * @type {any}
     * @memberof ParameterInfo
     */
    'defaultValue'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof ParameterInfo
     */
    'rawDefaultValue'?: any | null;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterInfo
     */
    'hasDefaultValue': boolean;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof ParameterInfo
     */
    'customAttributes': Array<CustomAttributeData>;
    /**
     * 
     * @type {number}
     * @memberof ParameterInfo
     */
    'metadataToken': number;
}


/**
 * 
 * @export
 * @interface PreconditionFailedException
 */
export interface PreconditionFailedException {
    /**
     * 
     * @type {MethodBase}
     * @memberof PreconditionFailedException
     */
    'targetSite'?: MethodBase;
    /**
     * 
     * @type {string}
     * @memberof PreconditionFailedException
     */
    'message': string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof PreconditionFailedException
     */
    'data': { [key: string]: any; };
    /**
     * 
     * @type {Exception}
     * @memberof PreconditionFailedException
     */
    'innerException'?: Exception;
    /**
     * 
     * @type {string}
     * @memberof PreconditionFailedException
     */
    'helpLink'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PreconditionFailedException
     */
    'source'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PreconditionFailedException
     */
    'hResult': number;
    /**
     * 
     * @type {string}
     * @memberof PreconditionFailedException
     */
    'stackTrace'?: string | null;
    /**
     * 
     * @type {HttpRequestError}
     * @memberof PreconditionFailedException
     */
    'httpRequestError': HttpRequestError;
    /**
     * 
     * @type {HttpStatusCode}
     * @memberof PreconditionFailedException
     */
    'statusCode'?: HttpStatusCode | null;
    /**
     * 
     * @type {number}
     * @memberof PreconditionFailedException
     */
    'status': number;
    /**
     * 
     * @type {SerializableExceptionError}
     * @memberof PreconditionFailedException
     */
    'error': SerializableExceptionError;
}


/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    'orderingValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'teamLeaderFullName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    'hasExternalReview'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'committeeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'sponsorFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    'fastCreation'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'projectStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'projectEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'parentProject'?: string | null;
}
/**
 * 
 * @export
 * @interface ProjectAnnualGoal
 */
export interface ProjectAnnualGoal {
    /**
     * 
     * @type {string}
     * @memberof ProjectAnnualGoal
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectAnnualGoal
     */
    'annualGoalDesription'?: string | null;
}
/**
 * 
 * @export
 * @interface ProjectChild
 */
export interface ProjectChild {
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectChild
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectChild
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectChild
     */
    'orderingValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'teamLeaderFullName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectChild
     */
    'hasExternalReview'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'committeeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'sponsorFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'subProjectStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'subProjectEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'subProjectParentProject'?: string | null;
}
/**
 * 
 * @export
 * @interface ProjectChildRelationship
 */
export interface ProjectChildRelationship {
    /**
     * 
     * @type {string}
     * @memberof ProjectChildRelationship
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectChildRelationship
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectChildRelationship
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChildRelationship
     */
    'highestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChildRelationship
     */
    'lowestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChildRelationship
     */
    'relationshipID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChildRelationship
     */
    'highestNameValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChildRelationship
     */
    'lowestNameValue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectChildRelationship
     */
    'highestNumberValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectChildRelationship
     */
    'lowestNumberValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChildRelationship
     */
    'highestObjectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChildRelationship
     */
    'lowestObjectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface ProjectDashboards
 */
export interface ProjectDashboards {
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboards
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboards
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboards
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboards
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboards
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboards
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboards
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboards
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboards
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboards
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboards
     */
    'timeSemaphore': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboards
     */
    'budgetSemaphore': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboards
     */
    'qualitySemaphore': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboards
     */
    'lastUpdated': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboards
     */
    'parentProjectName'?: string | null;
    /**
     * 
     * @type {Array<User>}
     * @memberof ProjectDashboards
     */
    'users'?: Array<User> | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboards
     */
    'budgetSemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboards
     */
    'budgetSemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboards
     */
    'qualitySemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboards
     */
    'qualitySemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboards
     */
    'timeSemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboards
     */
    'timeSemaphoreDenominator'?: number | null;
}
/**
 * 
 * @export
 * @interface ProjectDashboardsListControllerResponse
 */
export interface ProjectDashboardsListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectDashboardsListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboardsListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ProjectDashboardsListResponse}
     * @memberof ProjectDashboardsListControllerResponse
     */
    'responseObject'?: ProjectDashboardsListResponse;
}
/**
 * 
 * @export
 * @interface ProjectDashboardsListResponse
 */
export interface ProjectDashboardsListResponse {
    /**
     * 
     * @type {Array<ProjectDashboards>}
     * @memberof ProjectDashboardsListResponse
     */
    'value'?: Array<ProjectDashboards> | null;
}
/**
 * 
 * @export
 * @interface ProjectData
 */
export interface ProjectData {
    /**
     * 
     * @type {Array<ProjectResponse>}
     * @memberof ProjectData
     */
    'projectNotRelated'?: Array<ProjectResponse> | null;
    /**
     * 
     * @type {Array<ProjectResponse>}
     * @memberof ProjectData
     */
    'projectRelated'?: Array<ProjectResponse> | null;
}
/**
 * 
 * @export
 * @interface ProjectDeliverableParent
 */
export interface ProjectDeliverableParent {
    /**
     * 
     * @type {string}
     * @memberof ProjectDeliverableParent
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDeliverableParent
     */
    'deliverableID': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDeliverableParent
     */
    'parentDeliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDeliverableParent
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDeliverableParent
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectDeliverableParent
     */
    'budget'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectDeliverableParent
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDeliverableParent
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectDeliverableParent
     */
    'childrenCount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDeliverableParent
     */
    'owner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDeliverableParent
     */
    'ownerFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDeliverableParent
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectDeliverableParent
     */
    'priority'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDeliverableParent
     */
    'rescheduledEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDeliverableParent
     */
    'actualEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDeliverableParent
     */
    'genericResponsible'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectDeliverableParent
     */
    'isGenericResponsabile'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectDeliverableParent
     */
    'hasAccess'?: boolean | null;
    /**
     * 
     * @type {Array<GetProjectDeliverableResponseDto>}
     * @memberof ProjectDeliverableParent
     */
    'children'?: Array<GetProjectDeliverableResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ProjectDeliverableParentListControllerResponse
 */
export interface ProjectDeliverableParentListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectDeliverableParentListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectDeliverableParentListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ProjectDeliverableParentListResponse}
     * @memberof ProjectDeliverableParentListControllerResponse
     */
    'responseObject'?: ProjectDeliverableParentListResponse;
}
/**
 * 
 * @export
 * @interface ProjectDeliverableParentListResponse
 */
export interface ProjectDeliverableParentListResponse {
    /**
     * 
     * @type {Array<ProjectDeliverableParent>}
     * @memberof ProjectDeliverableParentListResponse
     */
    'value'?: Array<ProjectDeliverableParent> | null;
}
/**
 * 
 * @export
 * @interface ProjectDto
 */
export interface ProjectDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'code': string;
    /**
     * 
     * @type {ProjectStatus}
     * @memberof ProjectDto
     */
    'statusId': ProjectStatus;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectDto
     */
    'timeSemaphore': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDto
     */
    'budgetSemaphore': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDto
     */
    'qualitySemaphore': number;
    /**
     * 
     * @type {CommitteeDto}
     * @memberof ProjectDto
     */
    'committee'?: CommitteeDto;
    /**
     * 
     * @type {DivisionDto}
     * @memberof ProjectDto
     */
    'division'?: DivisionDto;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'projectRepositoryLink'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'lastUpdated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'parentProjectName'?: string | null;
    /**
     * 
     * @type {UserDto}
     * @memberof ProjectDto
     */
    'sponsor'?: UserDto;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectDto
     */
    'sponsorOnCall': boolean;
    /**
     * 
     * @type {UserDto}
     * @memberof ProjectDto
     */
    'teamLeader'?: UserDto;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectDto
     */
    'teamLeaderOnCall': boolean;
    /**
     * 
     * @type {number}
     * @memberof ProjectDto
     */
    'budgetSemaphoreNumerator': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDto
     */
    'budgetSemaphoreDenominator': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDto
     */
    'qualitySemaphoreNumerator': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDto
     */
    'qualitySemaphoreDenominator': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDto
     */
    'timeSemaphoreNumerator': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDto
     */
    'timeSemaphoreDenominator': number;
}


/**
 * 
 * @export
 * @interface ProjectLongTermGoal
 */
export interface ProjectLongTermGoal {
    /**
     * 
     * @type {string}
     * @memberof ProjectLongTermGoal
     */
    'longTermGoalDesription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectLongTermGoal
     */
    'longTermGoalID'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProjectParentship = {
    All: 'all',
    ParentProject: 'parentProject',
    ChildProject: 'childProject'
} as const;

export type ProjectParentship = typeof ProjectParentship[keyof typeof ProjectParentship];


/**
 * 
 * @export
 * @interface ProjectResponse
 */
export interface ProjectResponse {
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    'projectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface ProjectRoutineResponseDto
 */
export interface ProjectRoutineResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectRoutineResponseDto
     */
    'projectRoutineID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectRoutineResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectRoutineResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectRoutineResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectRoutineResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectRoutineResponseDto
     */
    'who'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectRoutineResponseDto
     */
    'whoDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectRoutineResponseDto
     */
    'schedule'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectRoutineResponseDto
     */
    'scheduleDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectRoutineResponseDto
     */
    'duration'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectRoutineResponseDto
     */
    'startTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectRoutineResponseDto
     */
    'endTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectRoutineResponseDto
     */
    'integrateInOutlook'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectRoutineResponseDto
     */
    'integrateInTeams'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectRoutineResponseDto
     */
    'outlookEventId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectRoutineResponseDto
     */
    'frequenceType'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectRoutineResponseDto
     */
    'frequence'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectRoutineResponseDto
     */
    'dayOfWeek'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectRoutineResponseDto
     */
    'extraUsers'?: string | null;
}
/**
 * 
 * @export
 * @interface ProjectRoutineResponseDtoListControllerResponse
 */
export interface ProjectRoutineResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectRoutineResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectRoutineResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ProjectRoutineResponseDtoListResponse}
     * @memberof ProjectRoutineResponseDtoListControllerResponse
     */
    'responseObject'?: ProjectRoutineResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface ProjectRoutineResponseDtoListResponse
 */
export interface ProjectRoutineResponseDtoListResponse {
    /**
     * 
     * @type {Array<ProjectRoutineResponseDto>}
     * @memberof ProjectRoutineResponseDtoListResponse
     */
    'value'?: Array<ProjectRoutineResponseDto> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProjectSemaphore = {
    NotSet: 'notSet',
    OnTime: 'onTime',
    Warning: 'warning',
    Critical: 'critical'
} as const;

export type ProjectSemaphore = typeof ProjectSemaphore[keyof typeof ProjectSemaphore];


/**
 * 
 * @export
 * @enum {string}
 */

export const ProjectStatus = {
    Active: 'active',
    Suspended: 'suspended',
    Draft: 'draft',
    Completed: 'completed'
} as const;

export type ProjectStatus = typeof ProjectStatus[keyof typeof ProjectStatus];


/**
 * 
 * @export
 * @interface ProjectSummary
 */
export interface ProjectSummary {
    /**
     * 
     * @type {string}
     * @memberof ProjectSummary
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummary
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummary
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummary
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummary
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummary
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummary
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummary
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummary
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummary
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummary
     */
    'timeSemaphore': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummary
     */
    'budgetSemaphore': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummary
     */
    'qualitySemaphore': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummary
     */
    'lastUpdated': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummary
     */
    'parentProjectName'?: string | null;
    /**
     * 
     * @type {Array<UserProject>}
     * @memberof ProjectSummary
     */
    'users'?: Array<UserProject> | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummary
     */
    'budgetSemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummary
     */
    'budgetSemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummary
     */
    'qualitySemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummary
     */
    'qualitySemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummary
     */
    'timeSemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummary
     */
    'timeSemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummary
     */
    'projectMonthList'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummary
     */
    'teamLeaderFullName'?: string | null;
    /**
     * 
     * @type {Array<ProjectSummaryDto>}
     * @memberof ProjectSummary
     */
    'children'?: Array<ProjectSummaryDto> | null;
}
/**
 * 
 * @export
 * @interface ProjectSummaryDto
 */
export interface ProjectSummaryDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'teamLeaderFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'teamLeaderJobTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'teamLeaderDepartment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'teamLeaderEmailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'teamLeaderProfilePhoto'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'teamLeaderInitials'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryDto
     */
    'teamLeaderOnCall'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'sponsorFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'sponsorJobTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'sponsorDepartment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'sponsorEmailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'sponsorProfilePhoto'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'sponsorInitials'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryDto
     */
    'sponsorOnCall'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'roleTeamLeader'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'roleSponsor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'company'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryDto
     */
    'timeSemaphore'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryDto
     */
    'budgetSemaphore'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryDto
     */
    'qualitySemaphore'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'lastUpdated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'parentProjectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'parentProjectName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryDto
     */
    'budgetSemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryDto
     */
    'budgetSemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryDto
     */
    'qualitySemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryDto
     */
    'qualitySemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryDto
     */
    'timeSemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryDto
     */
    'timeSemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'projectMonthList'?: string | null;
}
/**
 * 
 * @export
 * @interface ProjectSummaryListControllerResponse
 */
export interface ProjectSummaryListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSummaryListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ProjectSummaryListResponse}
     * @memberof ProjectSummaryListControllerResponse
     */
    'responseObject'?: ProjectSummaryListResponse;
}
/**
 * 
 * @export
 * @interface ProjectSummaryListResponse
 */
export interface ProjectSummaryListResponse {
    /**
     * 
     * @type {Array<ProjectSummary>}
     * @memberof ProjectSummaryListResponse
     */
    'value'?: Array<ProjectSummary> | null;
}
/**
 * 
 * @export
 * @interface ProjectUserDto
 */
export interface ProjectUserDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectUserDto
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectUserDto
     */
    'userId': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectUserDto
     */
    'type': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectUserDto
     */
    'onCall': boolean;
    /**
     * 
     * @type {number}
     * @memberof ProjectUserDto
     */
    'hoursOnBudget'?: number | null;
}
/**
 * 
 * @export
 * @interface Projects
 */
export interface Projects {
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Projects
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Projects
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Projects
     */
    'orderingValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'teamLeaderFullName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Projects
     */
    'hasExternalReview'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'committeeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'sponsorFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'projectStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'projectEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'parentProject'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PropertyAttributes = {
    None: 'none',
    SpecialName: 'specialName',
    RtSpecialName: 'rtSpecialName',
    HasDefault: 'hasDefault',
    Reserved2: 'reserved2',
    Reserved3: 'reserved3',
    Reserved4: 'reserved4',
    ReservedMask: 'reservedMask'
} as const;

export type PropertyAttributes = typeof PropertyAttributes[keyof typeof PropertyAttributes];


/**
 * 
 * @export
 * @interface PropertyInfo
 */
export interface PropertyInfo {
    /**
     * 
     * @type {string}
     * @memberof PropertyInfo
     */
    'name': string;
    /**
     * 
     * @type {Type}
     * @memberof PropertyInfo
     */
    'declaringType'?: Type;
    /**
     * 
     * @type {Type}
     * @memberof PropertyInfo
     */
    'reflectedType'?: Type;
    /**
     * 
     * @type {Module}
     * @memberof PropertyInfo
     */
    'module': Module;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof PropertyInfo
     */
    'customAttributes': Array<CustomAttributeData>;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyInfo
     */
    'isCollectible': boolean;
    /**
     * 
     * @type {number}
     * @memberof PropertyInfo
     */
    'metadataToken': number;
    /**
     * 
     * @type {MemberTypes}
     * @memberof PropertyInfo
     */
    'memberType': MemberTypes;
    /**
     * 
     * @type {Type}
     * @memberof PropertyInfo
     */
    'propertyType': Type;
    /**
     * 
     * @type {PropertyAttributes}
     * @memberof PropertyInfo
     */
    'attributes': PropertyAttributes;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyInfo
     */
    'isSpecialName': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyInfo
     */
    'canRead': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyInfo
     */
    'canWrite': boolean;
    /**
     * 
     * @type {MethodInfo}
     * @memberof PropertyInfo
     */
    'getMethod'?: MethodInfo;
    /**
     * 
     * @type {MethodInfo}
     * @memberof PropertyInfo
     */
    'setMethod'?: MethodInfo;
}


/**
 * 
 * @export
 * @interface QueryProjectFilters
 */
export interface QueryProjectFilters {
    /**
     * 
     * @type {Array<string>}
     * @memberof QueryProjectFilters
     */
    'xMatrixIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof QueryProjectFilters
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof QueryProjectFilters
     */
    'sponsorIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof QueryProjectFilters
     */
    'teamLeaderIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<ProjectStatus>}
     * @memberof QueryProjectFilters
     */
    'projectStatuses'?: Array<ProjectStatus> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof QueryProjectFilters
     */
    'projectCodes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof QueryProjectFilters
     */
    'divisionIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof QueryProjectFilters
     */
    'commiteeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<ProjectSemaphore>}
     * @memberof QueryProjectFilters
     */
    'comboSemaphores'?: Array<ProjectSemaphore> | null;
    /**
     * 
     * @type {Array<ProjectSemaphore>}
     * @memberof QueryProjectFilters
     */
    'timeSemaphores'?: Array<ProjectSemaphore> | null;
    /**
     * 
     * @type {Array<ProjectSemaphore>}
     * @memberof QueryProjectFilters
     */
    'budgetSemaphores'?: Array<ProjectSemaphore> | null;
    /**
     * 
     * @type {Array<ProjectSemaphore>}
     * @memberof QueryProjectFilters
     */
    'qualitySemaphores'?: Array<ProjectSemaphore> | null;
    /**
     * 
     * @type {ProjectParentship}
     * @memberof QueryProjectFilters
     */
    'projectParentship': ProjectParentship;
    /**
     * 
     * @type {boolean}
     * @memberof QueryProjectFilters
     */
    'selectNotRelatedProjects': boolean;
}


/**
 * 
 * @export
 * @interface RuntimeFieldHandle
 */
export interface RuntimeFieldHandle {
    /**
     * 
     * @type {object}
     * @memberof RuntimeFieldHandle
     */
    'value': object;
}
/**
 * 
 * @export
 * @interface RuntimeMethodHandle
 */
export interface RuntimeMethodHandle {
    /**
     * 
     * @type {object}
     * @memberof RuntimeMethodHandle
     */
    'value': object;
}
/**
 * 
 * @export
 * @interface RuntimeTypeHandle
 */
export interface RuntimeTypeHandle {
    /**
     * 
     * @type {object}
     * @memberof RuntimeTypeHandle
     */
    'value': object;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SecurityRuleSet = {
    None: 'none',
    Level1: 'level1',
    Level2: 'level2'
} as const;

export type SecurityRuleSet = typeof SecurityRuleSet[keyof typeof SecurityRuleSet];


/**
 * 
 * @export
 * @enum {string}
 */

export const SerializableExceptionError = {
    MemberHasOpenActivities: 'memberHasOpenActivities',
    MemberHasOpenDeliverables: 'memberHasOpenDeliverables'
} as const;

export type SerializableExceptionError = typeof SerializableExceptionError[keyof typeof SerializableExceptionError];


/**
 * 
 * @export
 * @interface StatusPicklistResponseDto
 */
export interface StatusPicklistResponseDto {
    /**
     * 
     * @type {number}
     * @memberof StatusPicklistResponseDto
     */
    'statusID': number;
    /**
     * 
     * @type {string}
     * @memberof StatusPicklistResponseDto
     */
    'statusDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface StatusPicklistResponseDtoListControllerResponse
 */
export interface StatusPicklistResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof StatusPicklistResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof StatusPicklistResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {StatusPicklistResponseDtoListResponse}
     * @memberof StatusPicklistResponseDtoListControllerResponse
     */
    'responseObject'?: StatusPicklistResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface StatusPicklistResponseDtoListResponse
 */
export interface StatusPicklistResponseDtoListResponse {
    /**
     * 
     * @type {Array<StatusPicklistResponseDto>}
     * @memberof StatusPicklistResponseDtoListResponse
     */
    'value'?: Array<StatusPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface StatusResponnse
 */
export interface StatusResponnse {
    /**
     * 
     * @type {number}
     * @memberof StatusResponnse
     */
    'projectStatus': number;
    /**
     * 
     * @type {string}
     * @memberof StatusResponnse
     */
    'projectStatusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StatusResponnse
     */
    'projectNumber'?: number | null;
}
/**
 * 
 * @export
 * @interface StructLayoutAttribute
 */
export interface StructLayoutAttribute {
    /**
     * 
     * @type {any}
     * @memberof StructLayoutAttribute
     */
    'typeId': any;
    /**
     * 
     * @type {LayoutKind}
     * @memberof StructLayoutAttribute
     */
    'value': LayoutKind;
}


/**
 * 
 * @export
 * @interface SwapTeamMembersActivitiesRequest
 */
export interface SwapTeamMembersActivitiesRequest {
    /**
     * 
     * @type {string}
     * @memberof SwapTeamMembersActivitiesRequest
     */
    'projectID': string;
    /**
     * 
     * @type {string}
     * @memberof SwapTeamMembersActivitiesRequest
     */
    'teamMemberIn': string;
    /**
     * 
     * @type {string}
     * @memberof SwapTeamMembersActivitiesRequest
     */
    'teamMemberOut': string;
}
/**
 * 
 * @export
 * @interface Type
 */
export interface Type {
    /**
     * 
     * @type {string}
     * @memberof Type
     */
    'name': string;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof Type
     */
    'customAttributes': Array<CustomAttributeData>;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isCollectible': boolean;
    /**
     * 
     * @type {number}
     * @memberof Type
     */
    'metadataToken': number;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isInterface': boolean;
    /**
     * 
     * @type {MemberTypes}
     * @memberof Type
     */
    'memberType': MemberTypes;
    /**
     * 
     * @type {string}
     * @memberof Type
     */
    'namespace'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Type
     */
    'assemblyQualifiedName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Type
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {Assembly}
     * @memberof Type
     */
    'assembly': Assembly;
    /**
     * 
     * @type {Module}
     * @memberof Type
     */
    'module': Module;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isNested': boolean;
    /**
     * 
     * @type {Type}
     * @memberof Type
     */
    'declaringType'?: Type;
    /**
     * 
     * @type {MethodBase}
     * @memberof Type
     */
    'declaringMethod'?: MethodBase;
    /**
     * 
     * @type {Type}
     * @memberof Type
     */
    'reflectedType'?: Type;
    /**
     * 
     * @type {Type}
     * @memberof Type
     */
    'underlyingSystemType': Type;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isTypeDefinition': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isArray': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isByRef': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isPointer': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isConstructedGenericType': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isGenericParameter': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isGenericTypeParameter': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isGenericMethodParameter': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isGenericType': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isGenericTypeDefinition': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isSZArray': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isVariableBoundArray': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isByRefLike': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isFunctionPointer': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isUnmanagedFunctionPointer': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'hasElementType': boolean;
    /**
     * 
     * @type {Array<Type>}
     * @memberof Type
     */
    'genericTypeArguments': Array<Type>;
    /**
     * 
     * @type {number}
     * @memberof Type
     */
    'genericParameterPosition': number;
    /**
     * 
     * @type {GenericParameterAttributes}
     * @memberof Type
     */
    'genericParameterAttributes': GenericParameterAttributes;
    /**
     * 
     * @type {TypeAttributes}
     * @memberof Type
     */
    'attributes': TypeAttributes;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isAbstract': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isImport': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isSealed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isSpecialName': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isClass': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isNestedAssembly': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isNestedFamANDAssem': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isNestedFamily': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isNestedFamORAssem': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isNestedPrivate': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isNestedPublic': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isNotPublic': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isPublic': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isAutoLayout': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isExplicitLayout': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isLayoutSequential': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isAnsiClass': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isAutoClass': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isUnicodeClass': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isCOMObject': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isContextful': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isEnum': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isMarshalByRef': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isPrimitive': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isValueType': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isSignatureType': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isSecurityCritical': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isSecuritySafeCritical': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isSecurityTransparent': boolean;
    /**
     * 
     * @type {StructLayoutAttribute}
     * @memberof Type
     */
    'structLayoutAttribute'?: StructLayoutAttribute;
    /**
     * 
     * @type {ConstructorInfo}
     * @memberof Type
     */
    'typeInitializer'?: ConstructorInfo;
    /**
     * 
     * @type {RuntimeTypeHandle}
     * @memberof Type
     */
    'typeHandle': RuntimeTypeHandle;
    /**
     * 
     * @type {string}
     * @memberof Type
     */
    'guid': string;
    /**
     * 
     * @type {Type}
     * @memberof Type
     */
    'baseType'?: Type;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     * @deprecated
     */
    'isSerializable': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'containsGenericParameters': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Type
     */
    'isVisible': boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const TypeAttributes = {
    Class: 'class',
    Public: 'public',
    NestedPublic: 'nestedPublic',
    NestedPrivate: 'nestedPrivate',
    NestedFamily: 'nestedFamily',
    NestedAssembly: 'nestedAssembly',
    NestedFamAndAssem: 'nestedFamANDAssem',
    NestedFamOrAssem: 'nestedFamORAssem',
    SequentialLayout: 'sequentialLayout',
    ExplicitLayout: 'explicitLayout',
    LayoutMask: 'layoutMask',
    ClassSemanticsMask: 'classSemanticsMask',
    Abstract: 'abstract',
    Sealed: 'sealed',
    SpecialName: 'specialName',
    RtSpecialName: 'rtSpecialName',
    Import: 'import',
    Serializable: 'serializable',
    WindowsRuntime: 'windowsRuntime',
    UnicodeClass: 'unicodeClass',
    AutoClass: 'autoClass',
    CustomFormatClass: 'customFormatClass',
    HasSecurity: 'hasSecurity',
    ReservedMask: 'reservedMask',
    BeforeFieldInit: 'beforeFieldInit',
    CustomFormatMask: 'customFormatMask'
} as const;

export type TypeAttributes = typeof TypeAttributes[keyof typeof TypeAttributes];


/**
 * 
 * @export
 * @interface TypeInfo
 */
export interface TypeInfo {
    /**
     * 
     * @type {string}
     * @memberof TypeInfo
     */
    'name': string;
    /**
     * 
     * @type {Array<CustomAttributeData>}
     * @memberof TypeInfo
     */
    'customAttributes': Array<CustomAttributeData>;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isCollectible': boolean;
    /**
     * 
     * @type {number}
     * @memberof TypeInfo
     */
    'metadataToken': number;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isInterface': boolean;
    /**
     * 
     * @type {MemberTypes}
     * @memberof TypeInfo
     */
    'memberType': MemberTypes;
    /**
     * 
     * @type {string}
     * @memberof TypeInfo
     */
    'namespace'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeInfo
     */
    'assemblyQualifiedName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeInfo
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {Assembly}
     * @memberof TypeInfo
     */
    'assembly': Assembly;
    /**
     * 
     * @type {Module}
     * @memberof TypeInfo
     */
    'module': Module;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isNested': boolean;
    /**
     * 
     * @type {Type}
     * @memberof TypeInfo
     */
    'declaringType'?: Type;
    /**
     * 
     * @type {MethodBase}
     * @memberof TypeInfo
     */
    'declaringMethod'?: MethodBase;
    /**
     * 
     * @type {Type}
     * @memberof TypeInfo
     */
    'reflectedType'?: Type;
    /**
     * 
     * @type {Type}
     * @memberof TypeInfo
     */
    'underlyingSystemType': Type;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isTypeDefinition': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isArray': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isByRef': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isPointer': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isConstructedGenericType': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isGenericParameter': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isGenericTypeParameter': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isGenericMethodParameter': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isGenericType': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isGenericTypeDefinition': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isSZArray': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isVariableBoundArray': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isByRefLike': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isFunctionPointer': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isUnmanagedFunctionPointer': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'hasElementType': boolean;
    /**
     * 
     * @type {Array<Type>}
     * @memberof TypeInfo
     */
    'genericTypeArguments': Array<Type>;
    /**
     * 
     * @type {number}
     * @memberof TypeInfo
     */
    'genericParameterPosition': number;
    /**
     * 
     * @type {GenericParameterAttributes}
     * @memberof TypeInfo
     */
    'genericParameterAttributes': GenericParameterAttributes;
    /**
     * 
     * @type {TypeAttributes}
     * @memberof TypeInfo
     */
    'attributes': TypeAttributes;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isAbstract': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isImport': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isSealed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isSpecialName': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isClass': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isNestedAssembly': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isNestedFamANDAssem': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isNestedFamily': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isNestedFamORAssem': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isNestedPrivate': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isNestedPublic': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isNotPublic': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isPublic': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isAutoLayout': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isExplicitLayout': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isLayoutSequential': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isAnsiClass': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isAutoClass': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isUnicodeClass': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isCOMObject': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isContextful': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isEnum': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isMarshalByRef': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isPrimitive': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isValueType': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isSignatureType': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isSecurityCritical': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isSecuritySafeCritical': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isSecurityTransparent': boolean;
    /**
     * 
     * @type {StructLayoutAttribute}
     * @memberof TypeInfo
     */
    'structLayoutAttribute'?: StructLayoutAttribute;
    /**
     * 
     * @type {ConstructorInfo}
     * @memberof TypeInfo
     */
    'typeInitializer'?: ConstructorInfo;
    /**
     * 
     * @type {RuntimeTypeHandle}
     * @memberof TypeInfo
     */
    'typeHandle': RuntimeTypeHandle;
    /**
     * 
     * @type {string}
     * @memberof TypeInfo
     */
    'guid': string;
    /**
     * 
     * @type {Type}
     * @memberof TypeInfo
     */
    'baseType'?: Type;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     * @deprecated
     */
    'isSerializable': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'containsGenericParameters': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeInfo
     */
    'isVisible': boolean;
    /**
     * 
     * @type {Array<Type>}
     * @memberof TypeInfo
     */
    'genericTypeParameters': Array<Type>;
    /**
     * 
     * @type {Array<ConstructorInfo>}
     * @memberof TypeInfo
     */
    'declaredConstructors': Array<ConstructorInfo>;
    /**
     * 
     * @type {Array<EventInfo>}
     * @memberof TypeInfo
     */
    'declaredEvents': Array<EventInfo>;
    /**
     * 
     * @type {Array<FieldInfo>}
     * @memberof TypeInfo
     */
    'declaredFields': Array<FieldInfo>;
    /**
     * 
     * @type {Array<MemberInfo>}
     * @memberof TypeInfo
     */
    'declaredMembers': Array<MemberInfo>;
    /**
     * 
     * @type {Array<MethodInfo>}
     * @memberof TypeInfo
     */
    'declaredMethods': Array<MethodInfo>;
    /**
     * 
     * @type {Array<TypeInfo>}
     * @memberof TypeInfo
     */
    'declaredNestedTypes': Array<TypeInfo>;
    /**
     * 
     * @type {Array<PropertyInfo>}
     * @memberof TypeInfo
     */
    'declaredProperties': Array<PropertyInfo>;
    /**
     * 
     * @type {Array<Type>}
     * @memberof TypeInfo
     */
    'implementedInterfaces': Array<Type>;
}


/**
 * 
 * @export
 * @interface UpdateAlertConfigurationRequestDto
 */
export interface UpdateAlertConfigurationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertConfigurationRequestDto
     */
    'alertID': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertConfigurationRequestDto
     */
    'companyID': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAlertConfigurationRequestDto
     */
    'alertOn': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAlertConfigurationRequestDto
     */
    'allInOne': boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateAlertConfigurationRequestDto
     */
    'reminderInDaysFrequence': number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAlertConfigurationRequestDto
     */
    'sendViaMail': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAlertConfigurationRequestDto
     */
    'sendViaNotificationBell': boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateAlertConfigurationRequestDto
     */
    'periodo'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertConfigurationRequestDto
     */
    'lastEmailSent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertConfigurationRequestDto
     */
    'lastAlertSent'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateAnnualGoalKPIRelationShipRequestDto
 */
export interface UpdateAnnualGoalKPIRelationShipRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualGoalKPIRelationShipRequestDto
     */
    'annualGoalID': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualGoalKPIRelationShipRequestDto
     */
    'kpiid': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAnnualGoalKPIRelationShipRequestDto
     */
    'correlationCoefficient': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualGoalKPIRelationShipRequestDto
     */
    'xMatrixID': string;
}
/**
 * 
 * @export
 * @interface UpdateAnnualGoalKPIRelationShipResponseDto
 */
export interface UpdateAnnualGoalKPIRelationShipResponseDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualGoalKPIRelationShipResponseDto
     */
    'kpiName'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateAnnualGoalKPIRelationShipResponseDtoControllerResponse
 */
export interface UpdateAnnualGoalKPIRelationShipResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAnnualGoalKPIRelationShipResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualGoalKPIRelationShipResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {UpdateAnnualGoalKPIRelationShipResponseDtoResponse}
     * @memberof UpdateAnnualGoalKPIRelationShipResponseDtoControllerResponse
     */
    'responseObject'?: UpdateAnnualGoalKPIRelationShipResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface UpdateAnnualGoalKPIRelationShipResponseDtoResponse
 */
export interface UpdateAnnualGoalKPIRelationShipResponseDtoResponse {
    /**
     * 
     * @type {UpdateAnnualGoalKPIRelationShipResponseDto}
     * @memberof UpdateAnnualGoalKPIRelationShipResponseDtoResponse
     */
    'value'?: UpdateAnnualGoalKPIRelationShipResponseDto;
}
/**
 * 
 * @export
 * @interface UpdateAnnualGoalRequestDto
 */
export interface UpdateAnnualGoalRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualGoalRequestDto
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualGoalRequestDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAnnualGoalRequestDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualGoalRequestDto
     */
    'referencePeriod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualGoalRequestDto
     */
    'owner'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateAnnualGoalResponse
 */
export interface UpdateAnnualGoalResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualGoalResponse
     */
    'ownerFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualGoalResponse
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualGoalResponse
     */
    'longTermGoalID'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateAnnualGoalResponseListControllerResponse
 */
export interface UpdateAnnualGoalResponseListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAnnualGoalResponseListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualGoalResponseListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {UpdateAnnualGoalResponseListResponse}
     * @memberof UpdateAnnualGoalResponseListControllerResponse
     */
    'responseObject'?: UpdateAnnualGoalResponseListResponse;
}
/**
 * 
 * @export
 * @interface UpdateAnnualGoalResponseListResponse
 */
export interface UpdateAnnualGoalResponseListResponse {
    /**
     * 
     * @type {Array<UpdateAnnualGoalResponse>}
     * @memberof UpdateAnnualGoalResponseListResponse
     */
    'value'?: Array<UpdateAnnualGoalResponse> | null;
}
/**
 * 
 * @export
 * @interface UpdateAnnualGoalXRequestDto
 */
export interface UpdateAnnualGoalXRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualGoalXRequestDto
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualGoalXRequestDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAnnualGoalXRequestDto
     */
    'status'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateAnnualOrLongTermGoalRequestDto
 */
export interface UpdateAnnualOrLongTermGoalRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualOrLongTermGoalRequestDto
     */
    'itemID': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualOrLongTermGoalRequestDto
     */
    'nameOrNote'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAnnualOrLongTermGoalRequestDto
     */
    'updateCase': number;
}
/**
 * 
 * @export
 * @interface UpdateCommentForKPIRequestDto
 */
export interface UpdateCommentForKPIRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCommentForKPIRequestDto
     */
    'kpiDetailID': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCommentForKPIRequestDto
     */
    'objectTypeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCommentForKPIRequestDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCommentForKPIRequestDto
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateCommitteeCommentRequestDto
 */
export interface UpdateCommitteeCommentRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCommitteeCommentRequestDto
     */
    'committeeID': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCommitteeCommentRequestDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCommitteeCommentRequestDto
     */
    'commentTitle'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateCommitteeRequest
 */
export interface UpdateCommitteeRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCommitteeRequest
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateCommitteeRequest
     */
    'participants'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateCommitteeRequest
     */
    'auditors'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCommitteeRequest
     */
    'repositoryLink'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCommitteeRequest
     */
    'activitiesDuration': number;
    /**
     * 
     * @type {{ [key: string]: boolean | null; }}
     * @memberof UpdateCommitteeRequest
     */
    'members'?: { [key: string]: boolean | null; } | null;
}
/**
 * 
 * @export
 * @interface UpdateCompanyRequestDto
 */
export interface UpdateCompanyRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyRequestDto
     */
    'companyID': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyRequestDto
     */
    'activationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyRequestDto
     */
    'deactivationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyRequestDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyRequestDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanyRequestDto
     */
    'licenseType'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanyRequestDto
     */
    'deactivateLicense': boolean;
}
/**
 * 
 * @export
 * @interface UpdateDeliverableRequestDto
 */
export interface UpdateDeliverableRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableRequestDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableRequestDto
     */
    'parentDeliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableRequestDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableRequestDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateDeliverableRequestDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableRequestDto
     */
    'tag'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateDeliverableRequestDto
     */
    'budget'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableRequestDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateDeliverableRequestDto
     */
    'priority'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableRequestDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableRequestDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableRequestDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableRequestDto
     */
    'rescheduledEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableRequestDto
     */
    'actualEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableRequestDto
     */
    'genericResponsible'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDeliverableRequestDto
     */
    'isGenericResponsabile'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableRequestDto
     */
    'ownerName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDeliverableRequestDto
     */
    'updateParentEndDate'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDeliverableRequestDto
     */
    'updateParentStartDate'?: boolean | null;
}
/**
 * 
 * @export
 * @interface UpdateDeliverableResponse
 */
export interface UpdateDeliverableResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableResponse
     */
    'fullName'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateDeliverableResponseControllerResponse
 */
export interface UpdateDeliverableResponseControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDeliverableResponseControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableResponseControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {UpdateDeliverableResponseResponse}
     * @memberof UpdateDeliverableResponseControllerResponse
     */
    'responseObject'?: UpdateDeliverableResponseResponse;
}
/**
 * 
 * @export
 * @interface UpdateDeliverableResponseResponse
 */
export interface UpdateDeliverableResponseResponse {
    /**
     * 
     * @type {UpdateDeliverableResponse}
     * @memberof UpdateDeliverableResponseResponse
     */
    'value'?: UpdateDeliverableResponse;
}
/**
 * 
 * @export
 * @interface UpdateFeedActivityRequestDto
 */
export interface UpdateFeedActivityRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedActivityRequestDto
     */
    'activityID': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedActivityRequestDto
     */
    'activityType': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedActivityRequestDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedActivityRequestDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedActivityRequestDto
     */
    'priority': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedActivityRequestDto
     */
    'documentURL'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedActivityRequestDto
     */
    'scheduledStart'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedActivityRequestDto
     */
    'scheduledEnd'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedActivityRequestDto
     */
    'startTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedActivityRequestDto
     */
    'endTime'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedActivityRequestDto
     */
    'duration'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFeedActivityRequestDto
     */
    'integrateInOutlook'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFeedActivityRequestDto
     */
    'integrateInTeams'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedActivityRequestDto
     */
    'outlookEventId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedActivityRequestDto
     */
    'timezone'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateGlobalKPIBowlingChartImportRequestDto
 */
export interface UpdateGlobalKPIBowlingChartImportRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateGlobalKPIBowlingChartImportRequestDto
     */
    'kpiDetailID': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateGlobalKPIBowlingChartImportRequestDto
     */
    'actual'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateGlobalKPIBowlingChartImportRequestDto
     */
    'unitOfMeasure'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateGlobalKPIBowlingChartImportRequestDto
     */
    'referencePeriod': string;
}
/**
 * 
 * @export
 * @interface UpdateGlobalKPIBowlingChartRequestDto
 */
export interface UpdateGlobalKPIBowlingChartRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateGlobalKPIBowlingChartRequestDto
     */
    'kpiDetailID'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateGlobalKPIBowlingChartRequestDto
     */
    'tableData'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateGlobalKPIBowlingChartRequestDto
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateGlobalKPIBowlingChartRequestDto
     */
    'finalTarget'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateGlobalKPIBowlingChartRequestDto
     */
    'showInA3'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateGlobalKPIBowlingChartRequestDto
     */
    'freezeTargetValues'?: boolean | null;
}
/**
 * 
 * @export
 * @interface UpdateGoalCategoryRequestDto
 */
export interface UpdateGoalCategoryRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateGoalCategoryRequestDto
     */
    'goalCategoryID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateGoalCategoryRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateGoalCategoryRequestDto
     */
    'goalCategoryIconID'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateGoalGroundActA3RequestDto
 */
export interface UpdateGoalGroundActA3RequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateGoalGroundActA3RequestDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateGoalGroundActA3RequestDto
     */
    'projectGoal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateGoalGroundActA3RequestDto
     */
    'groundToAct'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateGoalKPIRelationShipRequestDto
 */
export interface UpdateGoalKPIRelationShipRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateGoalKPIRelationShipRequestDto
     */
    'longTermGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateGoalKPIRelationShipRequestDto
     */
    'kpiid'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateGoalKPIRelationShipRequestDto
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateGoalKPIRelationShipRequestDto
     */
    'xMatrixID': string;
}
/**
 * 
 * @export
 * @interface UpdateGoalProjectRelationShipDto
 */
export interface UpdateGoalProjectRelationShipDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateGoalProjectRelationShipDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateGoalProjectRelationShipDto
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateGoalProjectRelationShipDto
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateGoalProjectRelationShipDto
     */
    'xMatrixID': string;
}
/**
 * 
 * @export
 * @interface UpdateImplementationSectionA3RequestDto
 */
export interface UpdateImplementationSectionA3RequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateImplementationSectionA3RequestDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateImplementationSectionA3RequestDto
     */
    'obtainedBenefit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateImplementationSectionA3RequestDto
     */
    'comment'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateKPIAnnualGoalBowlingChartRequestDto
 */
export interface UpdateKPIAnnualGoalBowlingChartRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateKPIAnnualGoalBowlingChartRequestDto
     */
    'annualGoalKPIRelationShipID': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateKPIAnnualGoalBowlingChartRequestDto
     */
    'changedList'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateKPIAnnualGoalBowlingChartRequestDto
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateKPIAnnualGoalBowlingChartRequestDto
     */
    'finalTarget'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateKPIAnnualGoalBowlingChartRequestDto
     */
    'showInA3'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateKPIAnnualGoalBowlingChartRequestDto
     */
    'freezeTargetValues'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateKPIAnnualGoalBowlingChartRequestDto
     */
    'responsibleID'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateKPIBowlingChartRequestDto
 */
export interface UpdateKPIBowlingChartRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateKPIBowlingChartRequestDto
     */
    'kpiProjectRelationShipID'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateKPIBowlingChartRequestDto
     */
    'tableData'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateKPIBowlingChartRequestDto
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateKPIBowlingChartRequestDto
     */
    'finalTarget'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateKPIBowlingChartRequestDto
     */
    'showInA3'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateKPIBowlingChartRequestDto
     */
    'freezeTargetValues'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateKPIBowlingChartRequestDto
     */
    'responsibleID'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateKPIDetailRequestDto
 */
export interface UpdateKPIDetailRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateKPIDetailRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateKPIDetailRequestDto
     */
    'unitOfMeasure'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateKPIDetailRequestDto
     */
    'additionalNote'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateKPIDetailRequestDto
     */
    'calculationType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateKPIDetailRequestDto
     */
    'kpiDetailID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateKPIDetailRequestDto
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateKPIDetailRequestDto
     */
    'actualYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateKPIDetailRequestDto
     */
    'type'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateKPIDetailRequestDto
     */
    'parentType'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateKPIDetailRequestDto
     */
    'isRollupKPI'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateKPIDetailRequestDto
     */
    'responsibleID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateKPIDetailRequestDto
     */
    'isVisible'?: boolean | null;
}
/**
 * 
 * @export
 * @interface UpdateKPIProjectRelationShipRequestDto
 */
export interface UpdateKPIProjectRelationShipRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateKPIProjectRelationShipRequestDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateKPIProjectRelationShipRequestDto
     */
    'kpiid'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateKPIProjectRelationShipRequestDto
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateKPIProjectRelationShipRequestDto
     */
    'xMatrixID': string;
}
/**
 * 
 * @export
 * @interface UpdateLongTermAnnualGoalRelationShipDto
 */
export interface UpdateLongTermAnnualGoalRelationShipDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateLongTermAnnualGoalRelationShipDto
     */
    'longTermGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLongTermAnnualGoalRelationShipDto
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateLongTermAnnualGoalRelationShipDto
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLongTermAnnualGoalRelationShipDto
     */
    'xMatrixID': string;
}
/**
 * 
 * @export
 * @interface UpdateLongTermGoalChecklistRequestDto
 */
export interface UpdateLongTermGoalChecklistRequestDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLongTermGoalChecklistRequestDto
     */
    'miglioramentoSignificativo'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLongTermGoalChecklistRequestDto
     */
    'raggiungimentoVision'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLongTermGoalChecklistRequestDto
     */
    'strategiaSostenibile'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLongTermGoalChecklistRequestDto
     */
    'innovazioneBusiness'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLongTermGoalChecklistRequestDto
     */
    'vincoliNormative'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLongTermGoalChecklistRequestDto
     */
    'xMatrixID': string;
}
/**
 * 
 * @export
 * @interface UpdateLongTermGoalRequestDto
 */
export interface UpdateLongTermGoalRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateLongTermGoalRequestDto
     */
    'longTermGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLongTermGoalRequestDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLongTermGoalRequestDto
     */
    'goalTypeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLongTermGoalRequestDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateLongTermGoalRequestDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLongTermGoalRequestDto
     */
    'isRelatedVMV'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateLongTermGoalRequestDto
     */
    'goalCategories'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UpdateLongTermGoalResponse
 */
export interface UpdateLongTermGoalResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateLongTermGoalResponse
     */
    'sponsorFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLongTermGoalResponse
     */
    'status'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateLongTermGoalResponse
     */
    'annualGoalID'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UpdateLongTermGoalResponseControllerResponse
 */
export interface UpdateLongTermGoalResponseControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLongTermGoalResponseControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateLongTermGoalResponseControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {UpdateLongTermGoalResponseResponse}
     * @memberof UpdateLongTermGoalResponseControllerResponse
     */
    'responseObject'?: UpdateLongTermGoalResponseResponse;
}
/**
 * 
 * @export
 * @interface UpdateLongTermGoalResponseResponse
 */
export interface UpdateLongTermGoalResponseResponse {
    /**
     * 
     * @type {UpdateLongTermGoalResponse}
     * @memberof UpdateLongTermGoalResponseResponse
     */
    'value'?: UpdateLongTermGoalResponse;
}
/**
 * 
 * @export
 * @interface UpdateLongTermGoalXRequestDto
 */
export interface UpdateLongTermGoalXRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateLongTermGoalXRequestDto
     */
    'longTermGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLongTermGoalXRequestDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateLongTermGoalXRequestDto
     */
    'status'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateMacroProjectRequest
 */
export interface UpdateMacroProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateMacroProjectRequest
     */
    'macroProjectID': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMacroProjectRequest
     */
    'macroProjectCode': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMacroProjectRequest
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateMacroProjectRequest
     */
    'isDeleted': boolean;
}
/**
 * 
 * @export
 * @interface UpdateMyActivityRequestDto
 */
export interface UpdateMyActivityRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateMyActivityRequestDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyActivityRequestDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyActivityRequestDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyActivityRequestDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyActivityRequestDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyActivityRequestDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyActivityRequestDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateMyActivityRequestDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyActivityRequestDto
     */
    'ownerID'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateMyActivityResponseDto
 */
export interface UpdateMyActivityResponseDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateMyActivityResponseDto
     */
    'activityID'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateMyActivityResponseDtoControllerResponse
 */
export interface UpdateMyActivityResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateMyActivityResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyActivityResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {UpdateMyActivityResponseDtoResponse}
     * @memberof UpdateMyActivityResponseDtoControllerResponse
     */
    'responseObject'?: UpdateMyActivityResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface UpdateMyActivityResponseDtoResponse
 */
export interface UpdateMyActivityResponseDtoResponse {
    /**
     * 
     * @type {UpdateMyActivityResponseDto}
     * @memberof UpdateMyActivityResponseDtoResponse
     */
    'value'?: UpdateMyActivityResponseDto;
}
/**
 * 
 * @export
 * @interface UpdateNextStepsSectionA3RequestDto
 */
export interface UpdateNextStepsSectionA3RequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateNextStepsSectionA3RequestDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateNextStepsSectionA3RequestDto
     */
    'hanseiLearnedLessons'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateNextStepsSectionA3RequestDto
     */
    'comment'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdatePlaningProjectRequest
 */
export interface UpdatePlaningProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePlaningProjectRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePlaningProjectRequest
     */
    'type'?: string | null;
    /**
     * 
     * @type {Array<MonthBudget>}
     * @memberof UpdatePlaningProjectRequest
     */
    'months'?: Array<MonthBudget> | null;
}
/**
 * 
 * @export
 * @interface UpdatePrecautionRequestModel
 */
export interface UpdatePrecautionRequestModel {
    /**
     * 
     * @type {string}
     * @memberof UpdatePrecautionRequestModel
     */
    'precautionDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePrecautionRequestModel
     */
    'precautionID'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateProjectCostDetailRequestDto
 */
export interface UpdateProjectCostDetailRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectCostDetailRequestDto
     */
    'projectCostDetailID': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectCostDetailRequestDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectCostDetailRequestDto
     */
    'type'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectCostDetailRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProjectCostDetailRequestDto
     */
    'deliverableList'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UpdateProjectCostRequest
 */
export interface UpdateProjectCostRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectCostRequest
     */
    'projectCostDetailID': string;
    /**
     * 
     * @type {Array<MonthsListObj>}
     * @memberof UpdateProjectCostRequest
     */
    'months'?: Array<MonthsListObj> | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectCostRequest
     */
    'type': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProjectCostRequest
     */
    'deliverableList'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UpdateProjectDeliverableRequestDto
 */
export interface UpdateProjectDeliverableRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDeliverableRequestDto
     */
    'parentDeliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDeliverableRequestDto
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDeliverableRequestDto
     */
    'startDate': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectDeliverableRequestDto
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDeliverableRequestDto
     */
    'tag'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectDeliverableRequestDto
     */
    'budget'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDeliverableRequestDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDeliverableRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectDeliverableRequestDto
     */
    'priority'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDeliverableRequestDto
     */
    'owner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDeliverableRequestDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDeliverableRequestDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDeliverableRequestDto
     */
    'rescheduledEndDate': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDeliverableRequestDto
     */
    'actualEndDate': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDeliverableRequestDto
     */
    'projectID'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateProjectFieldsRequestDto
 */
export interface UpdateProjectFieldsRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectFieldsRequestDto
     */
    'projectID': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectFieldsRequestDto
     */
    'projectFieldName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectFieldsRequestDto
     */
    'projectFieldValue'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateProjectRequestDto
 */
export interface UpdateProjectRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'projectID': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectRequestDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProjectRequestDto
     */
    'hasExternalReview': boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectRequestDto
     */
    'budget'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'projectGoal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'groundtoAct'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'nextStepsNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'obtainedBenefit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'finishedActivitiesNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'hanseiLearnedLessons'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'parentProjectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProjectRequestDto
     */
    'fastCreation'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectRequestDto
     */
    'masterplanDuration'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateProjectRoutineRequestDto
 */
export interface UpdateProjectRoutineRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'schedule'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'duration'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'who'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'projectRoutineID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'startTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'endTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'projectID': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'integrateInOutlook'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'integrateInTeams'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'outlookEventId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'frequenceType'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'frequence'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'dayOfWeek'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'extraUsers'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'timezone'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateProjectSecondaryProjectRelationShipRequestDto
 */
export interface UpdateProjectSecondaryProjectRelationShipRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectSecondaryProjectRelationShipRequestDto
     */
    'projectID': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectSecondaryProjectRelationShipRequestDto
     */
    'secondaryProjectID': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectSecondaryProjectRelationShipRequestDto
     */
    'correlationCoefficient': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectSecondaryProjectRelationShipRequestDto
     */
    'xMatrixID': string;
}
/**
 * 
 * @export
 * @interface UpdateProjectSecondaryProjectRelationShipResponse
 */
export interface UpdateProjectSecondaryProjectRelationShipResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectSecondaryProjectRelationShipResponse
     */
    'projectName'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateProjectSecondaryProjectRelationShipResponseControllerResponse
 */
export interface UpdateProjectSecondaryProjectRelationShipResponseControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProjectSecondaryProjectRelationShipResponseControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectSecondaryProjectRelationShipResponseControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {UpdateProjectSecondaryProjectRelationShipResponseResponse}
     * @memberof UpdateProjectSecondaryProjectRelationShipResponseControllerResponse
     */
    'responseObject'?: UpdateProjectSecondaryProjectRelationShipResponseResponse;
}
/**
 * 
 * @export
 * @interface UpdateProjectSecondaryProjectRelationShipResponseResponse
 */
export interface UpdateProjectSecondaryProjectRelationShipResponseResponse {
    /**
     * 
     * @type {UpdateProjectSecondaryProjectRelationShipResponse}
     * @memberof UpdateProjectSecondaryProjectRelationShipResponseResponse
     */
    'value'?: UpdateProjectSecondaryProjectRelationShipResponse;
}
/**
 * 
 * @export
 * @interface UpdateProjectTotalCostRequest
 */
export interface UpdateProjectTotalCostRequest {
    /**
     * 
     * @type {Array<MonthsList>}
     * @memberof UpdateProjectTotalCostRequest
     */
    'months'?: Array<MonthsList> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectTotalCostRequest
     */
    'projectID': string;
}
/**
 * 
 * @export
 * @interface UpdateProjectUserRelationShipRequestDto
 */
export interface UpdateProjectUserRelationShipRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectUserRelationShipRequestDto
     */
    'projectID': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectUserRelationShipRequestDto
     */
    'userID': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectUserRelationShipRequestDto
     */
    'correlationCoefficient': number;
}
/**
 * 
 * @export
 * @interface UpdateProjectXMatrixRequestDto
 */
export interface UpdateProjectXMatrixRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectXMatrixRequestDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectXMatrixRequestDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectXMatrixRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectXMatrixRequestDto
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectXMatrixRequestDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectXMatrixRequestDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProjectXMatrixRequestDto
     */
    'hasExternalReview'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectXMatrixRequestDto
     */
    'note'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectXMatrixRequestDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectXMatrixRequestDto
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectXMatrixRequestDto
     */
    'xMatrixID': string;
}
/**
 * 
 * @export
 * @interface UpdateRiskRequestDto
 */
export interface UpdateRiskRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateRiskRequestDto
     */
    'riskID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateRiskRequestDto
     */
    'riskDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateRiskRequestDto
     */
    'riskComment'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateRiskRequestDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateRiskRequestDto
     */
    'severity'?: number | null;
    /**
     * 
     * @type {Array<UpdatePrecautionRequestModel>}
     * @memberof UpdateRiskRequestDto
     */
    'precautions'?: Array<UpdatePrecautionRequestModel> | null;
}
/**
 * 
 * @export
 * @interface UpdateSecondLevelMacroProjectsRelationshipRequestDto
 */
export interface UpdateSecondLevelMacroProjectsRelationshipRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateSecondLevelMacroProjectsRelationshipRequestDto
     */
    'secondLevelXmatrixID': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSecondLevelMacroProjectsRelationshipRequestDto
     */
    'macroProjectID': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateSecondLevelMacroProjectsRelationshipRequestDto
     */
    'correlationCoefficient': number;
}
/**
 * 
 * @export
 * @interface UpdateSpecificAnnualGoalKPIBowlingRequestDto
 */
export interface UpdateSpecificAnnualGoalKPIBowlingRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateSpecificAnnualGoalKPIBowlingRequestDto
     */
    'annualGoalKPIRelationshipId': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateSpecificAnnualGoalKPIBowlingRequestDto
     */
    'actual'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateSpecificAnnualGoalKPIBowlingRequestDto
     */
    'unitOfMeasure'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateSpecificAnnualGoalKPIBowlingRequestDto
     */
    'referencePeriod': string;
}
/**
 * 
 * @export
 * @interface UpdateSpecificKPIBowlingChartImportRequestDto
 */
export interface UpdateSpecificKPIBowlingChartImportRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateSpecificKPIBowlingChartImportRequestDto
     */
    'kpiProjectRelationShipID': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateSpecificKPIBowlingChartImportRequestDto
     */
    'actual'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateSpecificKPIBowlingChartImportRequestDto
     */
    'unitOfMeasure'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateSpecificKPIBowlingChartImportRequestDto
     */
    'referencePeriod': string;
}
/**
 * 
 * @export
 * @interface UpdateTeamMembersRequest
 */
export interface UpdateTeamMembersRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateTeamMembersRequest
     */
    'projectID': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateTeamMembersRequest
     */
    'userIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UpdateUserCompanyRequest
 */
export interface UpdateUserCompanyRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCompanyRequest
     */
    'userID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCompanyRequest
     */
    'companyID'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateUserPlanning
 */
export interface UpdateUserPlanning {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPlanning
     */
    'userID': string;
    /**
     * 
     * @type {Array<Month>}
     * @memberof UpdateUserPlanning
     */
    'months'?: Array<Month> | null;
}
/**
 * 
 * @export
 * @interface UpdateUserTimePlanning
 */
export interface UpdateUserTimePlanning {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserTimePlanning
     */
    'userID': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserTimePlanning
     */
    'projectID': string;
    /**
     * 
     * @type {Array<Month>}
     * @memberof UpdateUserTimePlanning
     */
    'months'?: Array<Month> | null;
}
/**
 * 
 * @export
 * @interface UpdateXMatrixByIDRequestDto
 */
export interface UpdateXMatrixByIDRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateXMatrixByIDRequestDto
     */
    'xMatrixID': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateXMatrixByIDRequestDto
     */
    'projectIDList'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateXMatrixByIDRequestDto
     */
    'annualGoalIDList'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateXMatrixByIDRequestDto
     */
    'longTermGoalIDList'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateXMatrixByIDRequestDto
     */
    'xMatrixCycleID': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateXMatrixByIDRequestDto
     */
    'xMatrixName'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateXMatrixCycleRequestDto
 */
export interface UpdateXMatrixCycleRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateXMatrixCycleRequestDto
     */
    'xMatrixCycleID': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateXMatrixCycleRequestDto
     */
    'companyID': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateXMatrixCycleRequestDto
     */
    'isDeleted': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateXMatrixCycleRequestDto
     */
    'cycleName'?: string | null;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'jobTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'department'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'emailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'profilePhoto'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'initials'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'role'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'company'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'onCall'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'userID'?: string | null;
}
/**
 * 
 * @export
 * @interface UserCompaniesResponseDto
 */
export interface UserCompaniesResponseDto {
    /**
     * 
     * @type {string}
     * @memberof UserCompaniesResponseDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCompaniesResponseDto
     */
    'companyID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCompaniesResponseDto
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCompaniesResponseDto
     */
    'companyShortName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCompaniesResponseDto
     */
    'lastLoggedInCompany'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserCompaniesResponseDto
     */
    'isLastLoggedInCompany'?: number | null;
}
/**
 * 
 * @export
 * @interface UserDetailsRespose
 */
export interface UserDetailsRespose {
    /**
     * 
     * @type {string}
     * @memberof UserDetailsRespose
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsRespose
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsRespose
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsRespose
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsRespose
     */
    'profilePhoto'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsRespose
     */
    'role'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsRespose
     */
    'roleCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsRespose
     */
    'roleId': string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsRespose
     */
    'userInitials'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserDetailsRespose
     */
    'isLastLoggedInCompany': number;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsRespose
     */
    'emailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsRespose
     */
    'lastLoggedInCompanyID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsRespose
     */
    'lastLoggedInCompanyName'?: string | null;
    /**
     * 
     * @type {Array<UserCompaniesResponseDto>}
     * @memberof UserDetailsRespose
     */
    'userCompanies'?: Array<UserCompaniesResponseDto> | null;
}
/**
 * 
 * @export
 * @interface UserDetailsResposeControllerResponse
 */
export interface UserDetailsResposeControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UserDetailsResposeControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsResposeControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {UserDetailsResposeResponse}
     * @memberof UserDetailsResposeControllerResponse
     */
    'responseObject'?: UserDetailsResposeResponse;
}
/**
 * 
 * @export
 * @interface UserDetailsResposeResponse
 */
export interface UserDetailsResposeResponse {
    /**
     * 
     * @type {UserDetailsRespose}
     * @memberof UserDetailsResposeResponse
     */
    'value'?: UserDetailsRespose;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'jobTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'departmentName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'emailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'profilePhoto'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'initials'?: string | null;
}
/**
 * 
 * @export
 * @interface UserInfoResponseDto
 */
export interface UserInfoResponseDto {
    /**
     * 
     * @type {string}
     * @memberof UserInfoResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfoResponseDto
     */
    'profilePhoto'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfoResponseDto
     */
    'emailAddress'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserInfoResponseDto
     */
    'onCall'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfoResponseDto
     */
    'role'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfoResponseDto
     */
    'roleID'?: string | null;
}
/**
 * 
 * @export
 * @interface UserInfoResponseDtoListControllerResponse
 */
export interface UserInfoResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UserInfoResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserInfoResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {UserInfoResponseDtoListResponse}
     * @memberof UserInfoResponseDtoListControllerResponse
     */
    'responseObject'?: UserInfoResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface UserInfoResponseDtoListResponse
 */
export interface UserInfoResponseDtoListResponse {
    /**
     * 
     * @type {Array<UserInfoResponseDto>}
     * @memberof UserInfoResponseDtoListResponse
     */
    'value'?: Array<UserInfoResponseDto> | null;
}
/**
 * 
 * @export
 * @interface UserInvitationRequestDto
 */
export interface UserInvitationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UserInvitationRequestDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInvitationRequestDto
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInvitationRequestDto
     */
    'roleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInvitationRequestDto
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInvitationRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInvitationRequestDto
     */
    'surname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInvitationRequestDto
     */
    'userType'?: string | null;
}
/**
 * 
 * @export
 * @interface UserListPageResponseDto
 */
export interface UserListPageResponseDto {
    /**
     * 
     * @type {string}
     * @memberof UserListPageResponseDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserListPageResponseDto
     */
    'type'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserListPageResponseDto
     */
    'userType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListPageResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListPageResponseDto
     */
    'jobTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListPageResponseDto
     */
    'department'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListPageResponseDto
     */
    'emailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListPageResponseDto
     */
    'profilePhoto'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListPageResponseDto
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListPageResponseDto
     */
    'userInitials'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListPageResponseDto
     */
    'role'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserListPageResponseDto
     */
    'availability'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserListPageResponseDto
     */
    'dailyRate'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserListPageResponseDto
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListPageResponseDto
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListPageResponseDto
     */
    'roleCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserListPageResponseDto
     */
    'isDisabled'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserListPageResponseDto
     */
    'isDeleted': boolean;
}
/**
 * 
 * @export
 * @interface UserListPageResponseDtoListControllerResponse
 */
export interface UserListPageResponseDtoListControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UserListPageResponseDtoListControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserListPageResponseDtoListControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {UserListPageResponseDtoListResponse}
     * @memberof UserListPageResponseDtoListControllerResponse
     */
    'responseObject'?: UserListPageResponseDtoListResponse;
}
/**
 * 
 * @export
 * @interface UserListPageResponseDtoListResponse
 */
export interface UserListPageResponseDtoListResponse {
    /**
     * 
     * @type {Array<UserListPageResponseDto>}
     * @memberof UserListPageResponseDtoListResponse
     */
    'value'?: Array<UserListPageResponseDto> | null;
}
/**
 * 
 * @export
 * @interface UserProfileResponseDto
 */
export interface UserProfileResponseDto {
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDto
     */
    'jobTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDto
     */
    'department'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDto
     */
    'emailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDto
     */
    'profilePhoto'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDto
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDto
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDto
     */
    'userInitials'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDto
     */
    'role'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDto
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDto
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserProfileResponseDto
     */
    'dailyRate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserProfileResponseDto
     */
    'availability'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDto
     */
    'surname'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserProfileResponseDto
     */
    'type'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDto
     */
    'roleID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileResponseDto
     */
    'isDisabled'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileResponseDto
     */
    'isDeleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface UserProfileResponseDtoControllerResponse
 */
export interface UserProfileResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {UserProfileResponseDtoResponse}
     * @memberof UserProfileResponseDtoControllerResponse
     */
    'responseObject'?: UserProfileResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface UserProfileResponseDtoResponse
 */
export interface UserProfileResponseDtoResponse {
    /**
     * 
     * @type {UserProfileResponseDto}
     * @memberof UserProfileResponseDtoResponse
     */
    'value'?: UserProfileResponseDto;
}
/**
 * 
 * @export
 * @interface UserProject
 */
export interface UserProject {
    /**
     * 
     * @type {string}
     * @memberof UserProject
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProject
     */
    'jobTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProject
     */
    'department'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProject
     */
    'emailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProject
     */
    'profilePhoto'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProject
     */
    'initials'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProject
     */
    'role'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProject
     */
    'company'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserProject
     */
    'onCall'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserProject
     */
    'userID'?: string | null;
}
/**
 * 
 * @export
 * @interface UserProjectRelationship
 */
export interface UserProjectRelationship {
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationship
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserProjectRelationship
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserProjectRelationship
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationship
     */
    'highestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationship
     */
    'lowestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationship
     */
    'relationshipID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationship
     */
    'highestNameValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationship
     */
    'lowestNameValue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserProjectRelationship
     */
    'highestNumberValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserProjectRelationship
     */
    'lowestNumberValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationship
     */
    'highestObjectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationship
     */
    'lowestObjectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface UserProjectRelationships
 */
export interface UserProjectRelationships {
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationships
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserProjectRelationships
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserProjectRelationships
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationships
     */
    'highestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationships
     */
    'lowestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationships
     */
    'relationshipID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationships
     */
    'highestNameValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationships
     */
    'lowestNameValue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserProjectRelationships
     */
    'highestNumberValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserProjectRelationships
     */
    'lowestNumberValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationships
     */
    'highestObjectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationships
     */
    'lowestObjectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface Users
 */
export interface Users {
    /**
     * 
     * @type {string}
     * @memberof Users
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Users
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Users
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Users
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Users
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Users
     */
    'orderingValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Users
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Users
     */
    'userID'?: string | null;
}
/**
 * 
 * @export
 * @interface UsersObject
 */
export interface UsersObject {
    /**
     * 
     * @type {string}
     * @memberof UsersObject
     */
    'userID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UsersObject
     */
    'participationTypeMask'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UsersObject
     */
    'email'?: string | null;
}
/**
 * 
 * @export
 * @interface XMatrixDataByIDResponseDto
 */
export interface XMatrixDataByIDResponseDto {
    /**
     * 
     * @type {string}
     * @memberof XMatrixDataByIDResponseDto
     */
    'xMatrixName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XMatrixDataByIDResponseDto
     */
    'cycleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XMatrixDataByIDResponseDto
     */
    'xMatrixCycleID'?: string | null;
    /**
     * 
     * @type {ProjectData}
     * @memberof XMatrixDataByIDResponseDto
     */
    'projectData'?: ProjectData;
    /**
     * 
     * @type {AnnualObjData}
     * @memberof XMatrixDataByIDResponseDto
     */
    'annualObjData'?: AnnualObjData;
    /**
     * 
     * @type {LongTermGoalData}
     * @memberof XMatrixDataByIDResponseDto
     */
    'longTermGoalData'?: LongTermGoalData;
}
/**
 * 
 * @export
 * @interface XMatrixDataByIDResponseDtoControllerResponse
 */
export interface XMatrixDataByIDResponseDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof XMatrixDataByIDResponseDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof XMatrixDataByIDResponseDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {XMatrixDataByIDResponseDtoResponse}
     * @memberof XMatrixDataByIDResponseDtoControllerResponse
     */
    'responseObject'?: XMatrixDataByIDResponseDtoResponse;
}
/**
 * 
 * @export
 * @interface XMatrixDataByIDResponseDtoResponse
 */
export interface XMatrixDataByIDResponseDtoResponse {
    /**
     * 
     * @type {XMatrixDataByIDResponseDto}
     * @memberof XMatrixDataByIDResponseDtoResponse
     */
    'value'?: XMatrixDataByIDResponseDto;
}
/**
 * 
 * @export
 * @interface XMatrixDto
 */
export interface XMatrixDto {
    /**
     * 
     * @type {Array<Project>}
     * @memberof XMatrixDto
     */
    'projects'?: Array<Project> | null;
    /**
     * 
     * @type {Array<LongTermGoal>}
     * @memberof XMatrixDto
     */
    'longTermGoals'?: Array<LongTermGoal> | null;
    /**
     * 
     * @type {Array<AnnualGoal>}
     * @memberof XMatrixDto
     */
    'annualGoals'?: Array<AnnualGoal> | null;
    /**
     * 
     * @type {Array<KPI>}
     * @memberof XMatrixDto
     */
    'kpIs'?: Array<KPI> | null;
    /**
     * 
     * @type {Array<KPIProjectRelationship>}
     * @memberof XMatrixDto
     */
    'kpiProjectRelationships'?: Array<KPIProjectRelationship> | null;
    /**
     * 
     * @type {Array<GoalProjectRelationship>}
     * @memberof XMatrixDto
     */
    'goalProjectRelationship'?: Array<GoalProjectRelationship> | null;
    /**
     * 
     * @type {Array<LongTermAnnualGoalRelationship>}
     * @memberof XMatrixDto
     */
    'longTermAnnualGoalRelationships'?: Array<LongTermAnnualGoalRelationship> | null;
    /**
     * 
     * @type {Array<GoalKPIRelationship>}
     * @memberof XMatrixDto
     */
    'goalKPIRelationship'?: Array<GoalKPIRelationship> | null;
    /**
     * 
     * @type {Array<Users>}
     * @memberof XMatrixDto
     */
    'users'?: Array<Users> | null;
    /**
     * 
     * @type {Array<UserProjectRelationship>}
     * @memberof XMatrixDto
     */
    'userProjectRelationship'?: Array<UserProjectRelationship> | null;
    /**
     * 
     * @type {Array<AnnualGoalKPIRelationship>}
     * @memberof XMatrixDto
     */
    'annualGoalKPIRelationship'?: Array<AnnualGoalKPIRelationship> | null;
    /**
     * 
     * @type {Array<ProjectChildRelationship>}
     * @memberof XMatrixDto
     */
    'projectChildProjectRelationship'?: Array<ProjectChildRelationship> | null;
    /**
     * 
     * @type {Array<ProjectChild>}
     * @memberof XMatrixDto
     */
    'projectChild'?: Array<ProjectChild> | null;
}
/**
 * 
 * @export
 * @interface XMatrixDtoControllerResponse
 */
export interface XMatrixDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof XMatrixDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof XMatrixDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {XMatrixDtoResponse}
     * @memberof XMatrixDtoControllerResponse
     */
    'responseObject'?: XMatrixDtoResponse;
}
/**
 * 
 * @export
 * @interface XMatrixDtoResponse
 */
export interface XMatrixDtoResponse {
    /**
     * 
     * @type {XMatrixDto}
     * @memberof XMatrixDtoResponse
     */
    'value'?: XMatrixDto;
}
/**
 * 
 * @export
 * @interface XMatrixFilters
 */
export interface XMatrixFilters {
    /**
     * 
     * @type {Array<string>}
     * @memberof XMatrixFilters
     */
    'annualGoalId'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof XMatrixFilters
     */
    'longTermGoalId'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof XMatrixFilters
     */
    'sponsorId'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof XMatrixFilters
     */
    'teamLeaderId'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof XMatrixFilters
     */
    'divisionId'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof XMatrixFilters
     */
    'committeeId'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof XMatrixFilters
     */
    'projectID'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XMatrixFilters
     */
    'includeSubProject': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof XMatrixFilters
     */
    'showAllUsers': boolean;
    /**
     * 
     * @type {string}
     * @memberof XMatrixFilters
     */
    'xMatrixID'?: string | null;
}
/**
 * 
 * @export
 * @interface XMatrixSecondLevelDto
 */
export interface XMatrixSecondLevelDto {
    /**
     * 
     * @type {Array<Projects>}
     * @memberof XMatrixSecondLevelDto
     */
    'projects'?: Array<Projects> | null;
    /**
     * 
     * @type {Array<LongTermGoals>}
     * @memberof XMatrixSecondLevelDto
     */
    'longTermGoals'?: Array<LongTermGoals> | null;
    /**
     * 
     * @type {Array<AnnualGoals>}
     * @memberof XMatrixSecondLevelDto
     */
    'annualGoals'?: Array<AnnualGoals> | null;
    /**
     * 
     * @type {Array<KPIs>}
     * @memberof XMatrixSecondLevelDto
     */
    'kpIs'?: Array<KPIs> | null;
    /**
     * 
     * @type {Array<KPIProjectRelationships>}
     * @memberof XMatrixSecondLevelDto
     */
    'kpiProjectRelationships'?: Array<KPIProjectRelationships> | null;
    /**
     * 
     * @type {Array<GoalProjectRelationships>}
     * @memberof XMatrixSecondLevelDto
     */
    'goalProjectRelationship'?: Array<GoalProjectRelationships> | null;
    /**
     * 
     * @type {Array<Users>}
     * @memberof XMatrixSecondLevelDto
     */
    'users'?: Array<Users> | null;
    /**
     * 
     * @type {Array<UserProjectRelationships>}
     * @memberof XMatrixSecondLevelDto
     */
    'userProjectRelationship'?: Array<UserProjectRelationships> | null;
    /**
     * 
     * @type {Array<AnnualGoalKPIRelationships>}
     * @memberof XMatrixSecondLevelDto
     */
    'annualGoalKPIRelationship'?: Array<AnnualGoalKPIRelationships> | null;
    /**
     * 
     * @type {Array<ProjectChildRelationship>}
     * @memberof XMatrixSecondLevelDto
     */
    'projectChildProjectRelationship'?: Array<ProjectChildRelationship> | null;
    /**
     * 
     * @type {Array<ProjectChild>}
     * @memberof XMatrixSecondLevelDto
     */
    'projectChild'?: Array<ProjectChild> | null;
}
/**
 * 
 * @export
 * @interface XMatrixSecondLevelDtoControllerResponse
 */
export interface XMatrixSecondLevelDtoControllerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof XMatrixSecondLevelDtoControllerResponse
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof XMatrixSecondLevelDtoControllerResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {XMatrixSecondLevelDtoResponse}
     * @memberof XMatrixSecondLevelDtoControllerResponse
     */
    'responseObject'?: XMatrixSecondLevelDtoResponse;
}
/**
 * 
 * @export
 * @interface XMatrixSecondLevelDtoResponse
 */
export interface XMatrixSecondLevelDtoResponse {
    /**
     * 
     * @type {XMatrixSecondLevelDto}
     * @memberof XMatrixSecondLevelDtoResponse
     */
    'value'?: XMatrixSecondLevelDto;
}
/**
 * 
 * @export
 * @interface XMatrixSecondLevelFilters
 */
export interface XMatrixSecondLevelFilters {
    /**
     * 
     * @type {Array<string>}
     * @memberof XMatrixSecondLevelFilters
     */
    'annualGoalID'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof XMatrixSecondLevelFilters
     */
    'teamLeaderID'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof XMatrixSecondLevelFilters
     */
    'sponsorID'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof XMatrixSecondLevelFilters
     */
    'committeeID'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof XMatrixSecondLevelFilters
     */
    'projectID'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof XMatrixSecondLevelFilters
     */
    'secondaryProject'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XMatrixSecondLevelFilters
     */
    'showAllUsers': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof XMatrixSecondLevelFilters
     */
    'includeSubProject': boolean;
    /**
     * 
     * @type {string}
     * @memberof XMatrixSecondLevelFilters
     */
    'xMatrixID'?: string | null;
}

/**
 * A3PageApi - axios parameter creator
 * @export
 */
export const A3PageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string | null} [kpiDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetFollowUpSectionA3: async (projectID?: string | null, kpiDetailID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/A3Page/GetFollowUpSectionA3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }

            if (kpiDetailID !== undefined) {
                localVarQueryParameter['kpiDetailID'] = kpiDetailID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetImplementationSectionA3: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/A3Page/GetImplementationSectionA3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetKPIPicklistA3: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/A3Page/GetKPIPicklistA3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {number} [masterPlanDuration] 
         * @param {number | null} [masterPlanStart] 
         * @param {boolean} [deliverable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetMasterPlanDeliverable: async (projectID?: string, masterPlanDuration?: number, masterPlanStart?: number | null, deliverable?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/A3Page/GetMasterPlanDeliverable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }

            if (masterPlanDuration !== undefined) {
                localVarQueryParameter['MasterPlanDuration'] = masterPlanDuration;
            }

            if (masterPlanStart !== undefined) {
                localVarQueryParameter['MasterPlanStart'] = masterPlanStart;
            }

            if (deliverable !== undefined) {
                localVarQueryParameter['Deliverable'] = deliverable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string} [year] 
         * @param {boolean} [addOneYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetMasterplanParentDeliverable: async (projectID?: string | null, year?: string, addOneYear?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/A3Page/GetMasterplanParentDeliverable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }

            if (addOneYear !== undefined) {
                localVarQueryParameter['AddOneYear'] = addOneYear;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {number} [masterplanDuration] 
         * @param {number | null} [masterplanStart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetMasterplanParentDeliverableTest: async (projectID?: string, masterplanDuration?: number, masterplanStart?: number | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/A3Page/GetMasterplanParentDeliverableTest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }

            if (masterplanDuration !== undefined) {
                localVarQueryParameter['MasterplanDuration'] = masterplanDuration;
            }

            if (masterplanStart !== undefined) {
                localVarQueryParameter['MasterplanStart'] = masterplanStart;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetMembersListA3: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/A3Page/GetMembersListA3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetNextStepsSectionA3: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/A3Page/GetNextStepsSectionA3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {number | null} [implementationDaysOfNotice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetProjectCompletedActivitiesA3: async (projectID?: string | null, implementationDaysOfNotice?: number | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/A3Page/GetProjectCompletedActivitiesA3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }

            if (implementationDaysOfNotice !== undefined) {
                localVarQueryParameter['implementationDaysOfNotice'] = implementationDaysOfNotice;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {number | null} [nextStepDaysOfNotice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetProjectDeliverableNextStepA3: async (projectID?: string | null, nextStepDaysOfNotice?: number | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/A3Page/GetProjectDeliverableNextStepA3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }

            if (nextStepDaysOfNotice !== undefined) {
                localVarQueryParameter['nextStepDaysOfNotice'] = nextStepDaysOfNotice;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetProjectHeaderA3: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/A3Page/GetProjectHeaderA3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetRiskPrecautionA3: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/A3Page/GetRiskPrecautionA3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGoalGroundtoActA3: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/A3Page/GoalGroundtoActA3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateGoalGroundActA3RequestDto} [updateGoalGroundActA3RequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageUpdateGoalGroundtoActA3: async (updateGoalGroundActA3RequestDto?: UpdateGoalGroundActA3RequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/A3Page/UpdateGoalGroundtoActA3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGoalGroundActA3RequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateImplementationSectionA3RequestDto} [updateImplementationSectionA3RequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageUpdateImplementationSectionA3: async (updateImplementationSectionA3RequestDto?: UpdateImplementationSectionA3RequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/A3Page/UpdateImplementationSectionA3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateImplementationSectionA3RequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateNextStepsSectionA3RequestDto} [updateNextStepsSectionA3RequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageUpdateNextStepsSectionA3: async (updateNextStepsSectionA3RequestDto?: UpdateNextStepsSectionA3RequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/A3Page/UpdateNextStepsSectionA3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNextStepsSectionA3RequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * A3PageApi - functional programming interface
 * @export
 */
export const A3PageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = A3PageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string | null} [kpiDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageGetFollowUpSectionA3(projectID?: string | null, kpiDetailID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFollowUpSectionA3ResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageGetFollowUpSectionA3(projectID, kpiDetailID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageGetFollowUpSectionA3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageGetImplementationSectionA3(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetImplementationSectionA3ResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageGetImplementationSectionA3(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageGetImplementationSectionA3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageGetKPIPicklistA3(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetKPIPicklistA3ResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageGetKPIPicklistA3(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageGetKPIPicklistA3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {number} [masterPlanDuration] 
         * @param {number | null} [masterPlanStart] 
         * @param {boolean} [deliverable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageGetMasterPlanDeliverable(projectID?: string, masterPlanDuration?: number, masterPlanStart?: number | null, deliverable?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMasterPlanDeliverableResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageGetMasterPlanDeliverable(projectID, masterPlanDuration, masterPlanStart, deliverable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageGetMasterPlanDeliverable']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string} [year] 
         * @param {boolean} [addOneYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageGetMasterplanParentDeliverable(projectID?: string | null, year?: string, addOneYear?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMasterplanParentDeliverableResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageGetMasterplanParentDeliverable(projectID, year, addOneYear, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageGetMasterplanParentDeliverable']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {number} [masterplanDuration] 
         * @param {number | null} [masterplanStart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageGetMasterplanParentDeliverableTest(projectID?: string, masterplanDuration?: number, masterplanStart?: number | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMasterplanParentDeliverableTestResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageGetMasterplanParentDeliverableTest(projectID, masterplanDuration, masterplanStart, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageGetMasterplanParentDeliverableTest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageGetMembersListA3(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMembersListA3ResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageGetMembersListA3(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageGetMembersListA3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageGetNextStepsSectionA3(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNextStepsSectionA3ResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageGetNextStepsSectionA3(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageGetNextStepsSectionA3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {number | null} [implementationDaysOfNotice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageGetProjectCompletedActivitiesA3(projectID?: string | null, implementationDaysOfNotice?: number | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectCompletedActivitiesA3ResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageGetProjectCompletedActivitiesA3(projectID, implementationDaysOfNotice, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageGetProjectCompletedActivitiesA3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {number | null} [nextStepDaysOfNotice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageGetProjectDeliverableNextStepA3(projectID?: string | null, nextStepDaysOfNotice?: number | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectDeliverableNextStepA3ResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageGetProjectDeliverableNextStepA3(projectID, nextStepDaysOfNotice, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageGetProjectDeliverableNextStepA3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageGetProjectHeaderA3(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectHeaderA3ResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageGetProjectHeaderA3(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageGetProjectHeaderA3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageGetRiskPrecautionA3(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRiskPrecautionA3ResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageGetRiskPrecautionA3(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageGetRiskPrecautionA3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageGoalGroundtoActA3(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGoalGroundtoActA3ResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageGoalGroundtoActA3(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageGoalGroundtoActA3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateGoalGroundActA3RequestDto} [updateGoalGroundActA3RequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageUpdateGoalGroundtoActA3(updateGoalGroundActA3RequestDto?: UpdateGoalGroundActA3RequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageUpdateGoalGroundtoActA3(updateGoalGroundActA3RequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageUpdateGoalGroundtoActA3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateImplementationSectionA3RequestDto} [updateImplementationSectionA3RequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageUpdateImplementationSectionA3(updateImplementationSectionA3RequestDto?: UpdateImplementationSectionA3RequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageUpdateImplementationSectionA3(updateImplementationSectionA3RequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageUpdateImplementationSectionA3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateNextStepsSectionA3RequestDto} [updateNextStepsSectionA3RequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageUpdateNextStepsSectionA3(updateNextStepsSectionA3RequestDto?: UpdateNextStepsSectionA3RequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageUpdateNextStepsSectionA3(updateNextStepsSectionA3RequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageUpdateNextStepsSectionA3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * A3PageApi - factory interface
 * @export
 */
export const A3PageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = A3PageApiFp(configuration)
    return {
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string | null} [kpiDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetFollowUpSectionA3(projectID?: string | null, kpiDetailID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<GetFollowUpSectionA3ResponseDtoListControllerResponse> {
            return localVarFp.a3PageGetFollowUpSectionA3(projectID, kpiDetailID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetImplementationSectionA3(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<GetImplementationSectionA3ResponseDtoControllerResponse> {
            return localVarFp.a3PageGetImplementationSectionA3(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetKPIPicklistA3(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<GetKPIPicklistA3ResponseDtoListControllerResponse> {
            return localVarFp.a3PageGetKPIPicklistA3(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {number} [masterPlanDuration] 
         * @param {number | null} [masterPlanStart] 
         * @param {boolean} [deliverable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetMasterPlanDeliverable(projectID?: string, masterPlanDuration?: number, masterPlanStart?: number | null, deliverable?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<GetMasterPlanDeliverableResponseDtoListControllerResponse> {
            return localVarFp.a3PageGetMasterPlanDeliverable(projectID, masterPlanDuration, masterPlanStart, deliverable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string} [year] 
         * @param {boolean} [addOneYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetMasterplanParentDeliverable(projectID?: string | null, year?: string, addOneYear?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<GetMasterplanParentDeliverableResponseDtoListControllerResponse> {
            return localVarFp.a3PageGetMasterplanParentDeliverable(projectID, year, addOneYear, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {number} [masterplanDuration] 
         * @param {number | null} [masterplanStart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetMasterplanParentDeliverableTest(projectID?: string, masterplanDuration?: number, masterplanStart?: number | null, options?: RawAxiosRequestConfig): AxiosPromise<GetMasterplanParentDeliverableTestResponseDtoListControllerResponse> {
            return localVarFp.a3PageGetMasterplanParentDeliverableTest(projectID, masterplanDuration, masterplanStart, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetMembersListA3(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<GetMembersListA3ResponseDtoListControllerResponse> {
            return localVarFp.a3PageGetMembersListA3(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetNextStepsSectionA3(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<GetNextStepsSectionA3ResponseDtoControllerResponse> {
            return localVarFp.a3PageGetNextStepsSectionA3(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {number | null} [implementationDaysOfNotice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetProjectCompletedActivitiesA3(projectID?: string | null, implementationDaysOfNotice?: number | null, options?: RawAxiosRequestConfig): AxiosPromise<GetProjectCompletedActivitiesA3ResponseDtoListControllerResponse> {
            return localVarFp.a3PageGetProjectCompletedActivitiesA3(projectID, implementationDaysOfNotice, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {number | null} [nextStepDaysOfNotice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetProjectDeliverableNextStepA3(projectID?: string | null, nextStepDaysOfNotice?: number | null, options?: RawAxiosRequestConfig): AxiosPromise<GetProjectDeliverableNextStepA3ResponseDtoListControllerResponse> {
            return localVarFp.a3PageGetProjectDeliverableNextStepA3(projectID, nextStepDaysOfNotice, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetProjectHeaderA3(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<GetProjectHeaderA3ResponseDtoControllerResponse> {
            return localVarFp.a3PageGetProjectHeaderA3(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetRiskPrecautionA3(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<GetRiskPrecautionA3ResponseDtoListControllerResponse> {
            return localVarFp.a3PageGetRiskPrecautionA3(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGoalGroundtoActA3(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<GetGoalGroundtoActA3ResponseDtoControllerResponse> {
            return localVarFp.a3PageGoalGroundtoActA3(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateGoalGroundActA3RequestDto} [updateGoalGroundActA3RequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageUpdateGoalGroundtoActA3(updateGoalGroundActA3RequestDto?: UpdateGoalGroundActA3RequestDto, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.a3PageUpdateGoalGroundtoActA3(updateGoalGroundActA3RequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateImplementationSectionA3RequestDto} [updateImplementationSectionA3RequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageUpdateImplementationSectionA3(updateImplementationSectionA3RequestDto?: UpdateImplementationSectionA3RequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.a3PageUpdateImplementationSectionA3(updateImplementationSectionA3RequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateNextStepsSectionA3RequestDto} [updateNextStepsSectionA3RequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageUpdateNextStepsSectionA3(updateNextStepsSectionA3RequestDto?: UpdateNextStepsSectionA3RequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.a3PageUpdateNextStepsSectionA3(updateNextStepsSectionA3RequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * A3PageApi - object-oriented interface
 * @export
 * @class A3PageApi
 * @extends {BaseAPI}
 */
export class A3PageApi extends BaseAPI {
    /**
     * 
     * @param {string | null} [projectID] 
     * @param {string | null} [kpiDetailID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageGetFollowUpSectionA3(projectID?: string | null, kpiDetailID?: string | null, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageGetFollowUpSectionA3(projectID, kpiDetailID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageGetImplementationSectionA3(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageGetImplementationSectionA3(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageGetKPIPicklistA3(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageGetKPIPicklistA3(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {number} [masterPlanDuration] 
     * @param {number | null} [masterPlanStart] 
     * @param {boolean} [deliverable] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageGetMasterPlanDeliverable(projectID?: string, masterPlanDuration?: number, masterPlanStart?: number | null, deliverable?: boolean, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageGetMasterPlanDeliverable(projectID, masterPlanDuration, masterPlanStart, deliverable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {string} [year] 
     * @param {boolean} [addOneYear] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageGetMasterplanParentDeliverable(projectID?: string | null, year?: string, addOneYear?: boolean, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageGetMasterplanParentDeliverable(projectID, year, addOneYear, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {number} [masterplanDuration] 
     * @param {number | null} [masterplanStart] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageGetMasterplanParentDeliverableTest(projectID?: string, masterplanDuration?: number, masterplanStart?: number | null, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageGetMasterplanParentDeliverableTest(projectID, masterplanDuration, masterplanStart, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageGetMembersListA3(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageGetMembersListA3(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageGetNextStepsSectionA3(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageGetNextStepsSectionA3(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {number | null} [implementationDaysOfNotice] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageGetProjectCompletedActivitiesA3(projectID?: string | null, implementationDaysOfNotice?: number | null, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageGetProjectCompletedActivitiesA3(projectID, implementationDaysOfNotice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {number | null} [nextStepDaysOfNotice] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageGetProjectDeliverableNextStepA3(projectID?: string | null, nextStepDaysOfNotice?: number | null, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageGetProjectDeliverableNextStepA3(projectID, nextStepDaysOfNotice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageGetProjectHeaderA3(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageGetProjectHeaderA3(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageGetRiskPrecautionA3(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageGetRiskPrecautionA3(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageGoalGroundtoActA3(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageGoalGroundtoActA3(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateGoalGroundActA3RequestDto} [updateGoalGroundActA3RequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageUpdateGoalGroundtoActA3(updateGoalGroundActA3RequestDto?: UpdateGoalGroundActA3RequestDto, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageUpdateGoalGroundtoActA3(updateGoalGroundActA3RequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateImplementationSectionA3RequestDto} [updateImplementationSectionA3RequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageUpdateImplementationSectionA3(updateImplementationSectionA3RequestDto?: UpdateImplementationSectionA3RequestDto, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageUpdateImplementationSectionA3(updateImplementationSectionA3RequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateNextStepsSectionA3RequestDto} [updateNextStepsSectionA3RequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageUpdateNextStepsSectionA3(updateNextStepsSectionA3RequestDto?: UpdateNextStepsSectionA3RequestDto, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageUpdateNextStepsSectionA3(updateNextStepsSectionA3RequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ActivityApi - axios parameter creator
 * @export
 */
export const ActivityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityDeleteMyActivity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('activityDeleteMyActivity', 'id', id)
            const localVarPath = `/api/Activity/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [activityID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityGetMyActivity: async (activityID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Activity/GetMyActivity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (activityID !== undefined) {
                localVarQueryParameter['activityID'] = activityID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [deliverableID] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityGetMyActivityDeliverableForm: async (deliverableID?: string, startDate?: string, endDate?: string, languageCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Activity/GetMyActivityDeliverableForm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (deliverableID !== undefined) {
                localVarQueryParameter['DeliverableID'] = deliverableID;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['StartDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['EndDate'] = endDate;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityGetMyActivityMenuForm: async (startDate?: string, endDate?: string, languageCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Activity/GetMyActivityMenuForm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['StartDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['EndDate'] = endDate;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityGetMyActivityMenuFormPerUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Activity/GetMyActivityMenuFormPerUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [languageCode] 
         * @param {string} [deliverableID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityGetMyActivityProjectForm: async (projectID?: string, startDate?: string, endDate?: string, languageCode?: string, deliverableID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Activity/GetMyActivityProjectForm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['StartDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['EndDate'] = endDate;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }

            if (deliverableID !== undefined) {
                localVarQueryParameter['DeliverableID'] = deliverableID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityGetTeamLeaderActivityMenuForm: async (startDate?: string, endDate?: string, languageCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Activity/GetTeamLeaderActivityMenuForm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['StartDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['EndDate'] = endDate;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<InsertActivityPlanningImportRequestDto>} [insertActivityPlanningImportRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityInsertActivityPlanningImport: async (insertActivityPlanningImportRequestDto?: Array<InsertActivityPlanningImportRequestDto>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Activity/InsertActivityPlanningImport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertActivityPlanningImportRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertMyActivityRequestDto} [insertMyActivityRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityInsertMyActivity: async (insertMyActivityRequestDto?: InsertMyActivityRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Activity/InsertMyActivity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertMyActivityRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateMyActivityRequestDto} [updateMyActivityRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityUpdateMyActivity: async (updateMyActivityRequestDto?: UpdateMyActivityRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Activity/UpdateMyActivity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMyActivityRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityApi - functional programming interface
 * @export
 */
export const ActivityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityDeleteMyActivity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityDeleteMyActivity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.activityDeleteMyActivity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [activityID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityGetMyActivity(activityID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMyAcivityResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityGetMyActivity(activityID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.activityGetMyActivity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [deliverableID] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityGetMyActivityDeliverableForm(deliverableID?: string, startDate?: string, endDate?: string, languageCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMyActivityDeliverableFormResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityGetMyActivityDeliverableForm(deliverableID, startDate, endDate, languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.activityGetMyActivityDeliverableForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityGetMyActivityMenuForm(startDate?: string, endDate?: string, languageCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMyActivityMenuFormResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityGetMyActivityMenuForm(startDate, endDate, languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.activityGetMyActivityMenuForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityGetMyActivityMenuFormPerUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMyActivityMenuFormPerUserResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityGetMyActivityMenuFormPerUser(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.activityGetMyActivityMenuFormPerUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [languageCode] 
         * @param {string} [deliverableID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityGetMyActivityProjectForm(projectID?: string, startDate?: string, endDate?: string, languageCode?: string, deliverableID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMyActivityProjectFormrResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityGetMyActivityProjectForm(projectID, startDate, endDate, languageCode, deliverableID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.activityGetMyActivityProjectForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityGetTeamLeaderActivityMenuForm(startDate?: string, endDate?: string, languageCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTeamLeaderActivityMenuFormResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityGetTeamLeaderActivityMenuForm(startDate, endDate, languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.activityGetTeamLeaderActivityMenuForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<InsertActivityPlanningImportRequestDto>} [insertActivityPlanningImportRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityInsertActivityPlanningImport(insertActivityPlanningImportRequestDto?: Array<InsertActivityPlanningImportRequestDto>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityInsertActivityPlanningImport(insertActivityPlanningImportRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.activityInsertActivityPlanningImport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertMyActivityRequestDto} [insertMyActivityRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityInsertMyActivity(insertMyActivityRequestDto?: InsertMyActivityRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsertMyActivityResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityInsertMyActivity(insertMyActivityRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.activityInsertMyActivity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateMyActivityRequestDto} [updateMyActivityRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityUpdateMyActivity(updateMyActivityRequestDto?: UpdateMyActivityRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateMyActivityResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityUpdateMyActivity(updateMyActivityRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.activityUpdateMyActivity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ActivityApi - factory interface
 * @export
 */
export const ActivityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivityApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityDeleteMyActivity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.activityDeleteMyActivity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [activityID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityGetMyActivity(activityID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<GetMyAcivityResponseDtoControllerResponse> {
            return localVarFp.activityGetMyActivity(activityID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [deliverableID] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityGetMyActivityDeliverableForm(deliverableID?: string, startDate?: string, endDate?: string, languageCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetMyActivityDeliverableFormResponseDtoListControllerResponse> {
            return localVarFp.activityGetMyActivityDeliverableForm(deliverableID, startDate, endDate, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityGetMyActivityMenuForm(startDate?: string, endDate?: string, languageCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetMyActivityMenuFormResponseDtoListControllerResponse> {
            return localVarFp.activityGetMyActivityMenuForm(startDate, endDate, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityGetMyActivityMenuFormPerUser(options?: RawAxiosRequestConfig): AxiosPromise<GetMyActivityMenuFormPerUserResponseDtoListControllerResponse> {
            return localVarFp.activityGetMyActivityMenuFormPerUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [languageCode] 
         * @param {string} [deliverableID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityGetMyActivityProjectForm(projectID?: string, startDate?: string, endDate?: string, languageCode?: string, deliverableID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetMyActivityProjectFormrResponseDtoListControllerResponse> {
            return localVarFp.activityGetMyActivityProjectForm(projectID, startDate, endDate, languageCode, deliverableID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityGetTeamLeaderActivityMenuForm(startDate?: string, endDate?: string, languageCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetTeamLeaderActivityMenuFormResponseDtoListControllerResponse> {
            return localVarFp.activityGetTeamLeaderActivityMenuForm(startDate, endDate, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<InsertActivityPlanningImportRequestDto>} [insertActivityPlanningImportRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityInsertActivityPlanningImport(insertActivityPlanningImportRequestDto?: Array<InsertActivityPlanningImportRequestDto>, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.activityInsertActivityPlanningImport(insertActivityPlanningImportRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertMyActivityRequestDto} [insertMyActivityRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityInsertMyActivity(insertMyActivityRequestDto?: InsertMyActivityRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<InsertMyActivityResponseDtoControllerResponse> {
            return localVarFp.activityInsertMyActivity(insertMyActivityRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateMyActivityRequestDto} [updateMyActivityRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityUpdateMyActivity(updateMyActivityRequestDto?: UpdateMyActivityRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<UpdateMyActivityResponseDtoControllerResponse> {
            return localVarFp.activityUpdateMyActivity(updateMyActivityRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActivityApi - object-oriented interface
 * @export
 * @class ActivityApi
 * @extends {BaseAPI}
 */
export class ActivityApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityDeleteMyActivity(id: string, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).activityDeleteMyActivity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [activityID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityGetMyActivity(activityID?: string | null, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).activityGetMyActivity(activityID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [deliverableID] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityGetMyActivityDeliverableForm(deliverableID?: string, startDate?: string, endDate?: string, languageCode?: string, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).activityGetMyActivityDeliverableForm(deliverableID, startDate, endDate, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityGetMyActivityMenuForm(startDate?: string, endDate?: string, languageCode?: string, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).activityGetMyActivityMenuForm(startDate, endDate, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityGetMyActivityMenuFormPerUser(options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).activityGetMyActivityMenuFormPerUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {string} [languageCode] 
     * @param {string} [deliverableID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityGetMyActivityProjectForm(projectID?: string, startDate?: string, endDate?: string, languageCode?: string, deliverableID?: string, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).activityGetMyActivityProjectForm(projectID, startDate, endDate, languageCode, deliverableID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityGetTeamLeaderActivityMenuForm(startDate?: string, endDate?: string, languageCode?: string, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).activityGetTeamLeaderActivityMenuForm(startDate, endDate, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<InsertActivityPlanningImportRequestDto>} [insertActivityPlanningImportRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityInsertActivityPlanningImport(insertActivityPlanningImportRequestDto?: Array<InsertActivityPlanningImportRequestDto>, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).activityInsertActivityPlanningImport(insertActivityPlanningImportRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertMyActivityRequestDto} [insertMyActivityRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityInsertMyActivity(insertMyActivityRequestDto?: InsertMyActivityRequestDto, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).activityInsertMyActivity(insertMyActivityRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateMyActivityRequestDto} [updateMyActivityRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityUpdateMyActivity(updateMyActivityRequestDto?: UpdateMyActivityRequestDto, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).activityUpdateMyActivity(updateMyActivityRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AnnualGoalApi - axios parameter creator
 * @export
 */
export const AnnualGoalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalDeleteAnnuaGoal: async (annualGoalID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AnnualGoal/DeleteAnnuaGoal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (annualGoalID !== undefined) {
                localVarQueryParameter['AnnualGoalID'] = annualGoalID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalGetAnnualGoalById: async (annualGoalID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AnnualGoal/GetAnnualGoalById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (annualGoalID !== undefined) {
                localVarQueryParameter['annualGoalID'] = annualGoalID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [hasAnnualGoalRelation] 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalGetAnnualGoals: async (hasAnnualGoalRelation?: boolean, xMatrixID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AnnualGoal/GetAnnualGoal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (hasAnnualGoalRelation !== undefined) {
                localVarQueryParameter['hasAnnualGoalRelation'] = hasAnnualGoalRelation;
            }

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {string} [xMatrix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalGetAnualGoalDetails: async (annualGoalID?: string, xMatrix?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AnnualGoal/GetAnualGoalDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (annualGoalID !== undefined) {
                localVarQueryParameter['annualGoalID'] = annualGoalID;
            }

            if (xMatrix !== undefined) {
                localVarQueryParameter['xMatrix'] = xMatrix;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalGetAnualGoalPicklist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AnnualGoal/GetAnualGoalPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertAnnualGoalRequestDto} [insertAnnualGoalRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalInsertAnnualGoal: async (insertAnnualGoalRequestDto?: InsertAnnualGoalRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AnnualGoal/InsertAnnualGoal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertAnnualGoalRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertAnnualGoalXRequestDto} [insertAnnualGoalXRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalInsertAnnualGoalXMatrix: async (insertAnnualGoalXRequestDto?: InsertAnnualGoalXRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AnnualGoal/InsertAnnualGoalXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertAnnualGoalXRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAnnualGoalRequestDto} [updateAnnualGoalRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalUpdateAnnualGoal: async (updateAnnualGoalRequestDto?: UpdateAnnualGoalRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AnnualGoal/UpdateAnnualGoal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAnnualGoalRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAnnualGoalXRequestDto} [updateAnnualGoalXRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalUpdateAnnualGoalXMatrix: async (updateAnnualGoalXRequestDto?: UpdateAnnualGoalXRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AnnualGoal/UpdateAnnualGoalXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAnnualGoalXRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAnnualOrLongTermGoalRequestDto} [updateAnnualOrLongTermGoalRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalUpdateAnnualOrLongTermGoal: async (updateAnnualOrLongTermGoalRequestDto?: UpdateAnnualOrLongTermGoalRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AnnualGoal/UpdateAnnualOrLongTermGoal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAnnualOrLongTermGoalRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnualGoalApi - functional programming interface
 * @export
 */
export const AnnualGoalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnualGoalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annualGoalDeleteAnnuaGoal(annualGoalID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annualGoalDeleteAnnuaGoal(annualGoalID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnnualGoalApi.annualGoalDeleteAnnuaGoal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annualGoalGetAnnualGoalById(annualGoalID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAnnualGoalDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annualGoalGetAnnualGoalById(annualGoalID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnnualGoalApi.annualGoalGetAnnualGoalById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} [hasAnnualGoalRelation] 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annualGoalGetAnnualGoals(hasAnnualGoalRelation?: boolean, xMatrixID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAnnualGoalDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annualGoalGetAnnualGoals(hasAnnualGoalRelation, xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnnualGoalApi.annualGoalGetAnnualGoals']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {string} [xMatrix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annualGoalGetAnualGoalDetails(annualGoalID?: string, xMatrix?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAnualGoalDetailsDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annualGoalGetAnualGoalDetails(annualGoalID, xMatrix, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnnualGoalApi.annualGoalGetAnualGoalDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annualGoalGetAnualGoalPicklist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnualGoalPicklistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annualGoalGetAnualGoalPicklist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnnualGoalApi.annualGoalGetAnualGoalPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertAnnualGoalRequestDto} [insertAnnualGoalRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annualGoalInsertAnnualGoal(insertAnnualGoalRequestDto?: InsertAnnualGoalRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annualGoalInsertAnnualGoal(insertAnnualGoalRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnnualGoalApi.annualGoalInsertAnnualGoal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertAnnualGoalXRequestDto} [insertAnnualGoalXRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annualGoalInsertAnnualGoalXMatrix(insertAnnualGoalXRequestDto?: InsertAnnualGoalXRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annualGoalInsertAnnualGoalXMatrix(insertAnnualGoalXRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnnualGoalApi.annualGoalInsertAnnualGoalXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateAnnualGoalRequestDto} [updateAnnualGoalRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annualGoalUpdateAnnualGoal(updateAnnualGoalRequestDto?: UpdateAnnualGoalRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateAnnualGoalResponseListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annualGoalUpdateAnnualGoal(updateAnnualGoalRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnnualGoalApi.annualGoalUpdateAnnualGoal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateAnnualGoalXRequestDto} [updateAnnualGoalXRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annualGoalUpdateAnnualGoalXMatrix(updateAnnualGoalXRequestDto?: UpdateAnnualGoalXRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annualGoalUpdateAnnualGoalXMatrix(updateAnnualGoalXRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnnualGoalApi.annualGoalUpdateAnnualGoalXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateAnnualOrLongTermGoalRequestDto} [updateAnnualOrLongTermGoalRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annualGoalUpdateAnnualOrLongTermGoal(updateAnnualOrLongTermGoalRequestDto?: UpdateAnnualOrLongTermGoalRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annualGoalUpdateAnnualOrLongTermGoal(updateAnnualOrLongTermGoalRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnnualGoalApi.annualGoalUpdateAnnualOrLongTermGoal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AnnualGoalApi - factory interface
 * @export
 */
export const AnnualGoalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnualGoalApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalDeleteAnnuaGoal(annualGoalID?: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.annualGoalDeleteAnnuaGoal(annualGoalID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalGetAnnualGoalById(annualGoalID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetAnnualGoalDtoControllerResponse> {
            return localVarFp.annualGoalGetAnnualGoalById(annualGoalID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [hasAnnualGoalRelation] 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalGetAnnualGoals(hasAnnualGoalRelation?: boolean, xMatrixID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<GetAnnualGoalDtoListControllerResponse> {
            return localVarFp.annualGoalGetAnnualGoals(hasAnnualGoalRelation, xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {string} [xMatrix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalGetAnualGoalDetails(annualGoalID?: string, xMatrix?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetAnualGoalDetailsDtoControllerResponse> {
            return localVarFp.annualGoalGetAnualGoalDetails(annualGoalID, xMatrix, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalGetAnualGoalPicklist(options?: RawAxiosRequestConfig): AxiosPromise<AnnualGoalPicklistResponseDtoListControllerResponse> {
            return localVarFp.annualGoalGetAnualGoalPicklist(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertAnnualGoalRequestDto} [insertAnnualGoalRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalInsertAnnualGoal(insertAnnualGoalRequestDto?: InsertAnnualGoalRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.annualGoalInsertAnnualGoal(insertAnnualGoalRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertAnnualGoalXRequestDto} [insertAnnualGoalXRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalInsertAnnualGoalXMatrix(insertAnnualGoalXRequestDto?: InsertAnnualGoalXRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.annualGoalInsertAnnualGoalXMatrix(insertAnnualGoalXRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAnnualGoalRequestDto} [updateAnnualGoalRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalUpdateAnnualGoal(updateAnnualGoalRequestDto?: UpdateAnnualGoalRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<UpdateAnnualGoalResponseListControllerResponse> {
            return localVarFp.annualGoalUpdateAnnualGoal(updateAnnualGoalRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAnnualGoalXRequestDto} [updateAnnualGoalXRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalUpdateAnnualGoalXMatrix(updateAnnualGoalXRequestDto?: UpdateAnnualGoalXRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.annualGoalUpdateAnnualGoalXMatrix(updateAnnualGoalXRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAnnualOrLongTermGoalRequestDto} [updateAnnualOrLongTermGoalRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalUpdateAnnualOrLongTermGoal(updateAnnualOrLongTermGoalRequestDto?: UpdateAnnualOrLongTermGoalRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.annualGoalUpdateAnnualOrLongTermGoal(updateAnnualOrLongTermGoalRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnnualGoalApi - object-oriented interface
 * @export
 * @class AnnualGoalApi
 * @extends {BaseAPI}
 */
export class AnnualGoalApi extends BaseAPI {
    /**
     * 
     * @param {string} [annualGoalID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnualGoalApi
     */
    public annualGoalDeleteAnnuaGoal(annualGoalID?: string, options?: RawAxiosRequestConfig) {
        return AnnualGoalApiFp(this.configuration).annualGoalDeleteAnnuaGoal(annualGoalID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [annualGoalID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnualGoalApi
     */
    public annualGoalGetAnnualGoalById(annualGoalID?: string, options?: RawAxiosRequestConfig) {
        return AnnualGoalApiFp(this.configuration).annualGoalGetAnnualGoalById(annualGoalID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [hasAnnualGoalRelation] 
     * @param {string | null} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnualGoalApi
     */
    public annualGoalGetAnnualGoals(hasAnnualGoalRelation?: boolean, xMatrixID?: string | null, options?: RawAxiosRequestConfig) {
        return AnnualGoalApiFp(this.configuration).annualGoalGetAnnualGoals(hasAnnualGoalRelation, xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [annualGoalID] 
     * @param {string} [xMatrix] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnualGoalApi
     */
    public annualGoalGetAnualGoalDetails(annualGoalID?: string, xMatrix?: string, options?: RawAxiosRequestConfig) {
        return AnnualGoalApiFp(this.configuration).annualGoalGetAnualGoalDetails(annualGoalID, xMatrix, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnualGoalApi
     */
    public annualGoalGetAnualGoalPicklist(options?: RawAxiosRequestConfig) {
        return AnnualGoalApiFp(this.configuration).annualGoalGetAnualGoalPicklist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertAnnualGoalRequestDto} [insertAnnualGoalRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnualGoalApi
     */
    public annualGoalInsertAnnualGoal(insertAnnualGoalRequestDto?: InsertAnnualGoalRequestDto, options?: RawAxiosRequestConfig) {
        return AnnualGoalApiFp(this.configuration).annualGoalInsertAnnualGoal(insertAnnualGoalRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertAnnualGoalXRequestDto} [insertAnnualGoalXRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnualGoalApi
     */
    public annualGoalInsertAnnualGoalXMatrix(insertAnnualGoalXRequestDto?: InsertAnnualGoalXRequestDto, options?: RawAxiosRequestConfig) {
        return AnnualGoalApiFp(this.configuration).annualGoalInsertAnnualGoalXMatrix(insertAnnualGoalXRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateAnnualGoalRequestDto} [updateAnnualGoalRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnualGoalApi
     */
    public annualGoalUpdateAnnualGoal(updateAnnualGoalRequestDto?: UpdateAnnualGoalRequestDto, options?: RawAxiosRequestConfig) {
        return AnnualGoalApiFp(this.configuration).annualGoalUpdateAnnualGoal(updateAnnualGoalRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateAnnualGoalXRequestDto} [updateAnnualGoalXRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnualGoalApi
     */
    public annualGoalUpdateAnnualGoalXMatrix(updateAnnualGoalXRequestDto?: UpdateAnnualGoalXRequestDto, options?: RawAxiosRequestConfig) {
        return AnnualGoalApiFp(this.configuration).annualGoalUpdateAnnualGoalXMatrix(updateAnnualGoalXRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateAnnualOrLongTermGoalRequestDto} [updateAnnualOrLongTermGoalRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnualGoalApi
     */
    public annualGoalUpdateAnnualOrLongTermGoal(updateAnnualOrLongTermGoalRequestDto?: UpdateAnnualOrLongTermGoalRequestDto, options?: RawAxiosRequestConfig) {
        return AnnualGoalApiFp(this.configuration).annualGoalUpdateAnnualOrLongTermGoal(updateAnnualOrLongTermGoalRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CommitteeApi - axios parameter creator
 * @export
 */
export const CommitteeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCommitteeRequest} [createCommitteeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        committeeCreate: async (createCommitteeRequest?: CreateCommitteeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/committees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCommitteeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        committeeDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('committeeDelete', 'id', id)
            const localVarPath = `/api/committees/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        committeeGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('committeeGet', 'id', id)
            const localVarPath = `/api/committees/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        committeeList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/committees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCommitteeRequest} [updateCommitteeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        committeeUpdate: async (id: string, updateCommitteeRequest?: UpdateCommitteeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('committeeUpdate', 'id', id)
            const localVarPath = `/api/committees/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCommitteeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommitteeApi - functional programming interface
 * @export
 */
export const CommitteeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommitteeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateCommitteeRequest} [createCommitteeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async committeeCreate(createCommitteeRequest?: CreateCommitteeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommitteeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.committeeCreate(createCommitteeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitteeApi.committeeCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async committeeDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.committeeDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitteeApi.committeeDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async committeeGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommitteeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.committeeGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitteeApi.committeeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async committeeList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CommitteeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.committeeList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitteeApi.committeeList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCommitteeRequest} [updateCommitteeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async committeeUpdate(id: string, updateCommitteeRequest?: UpdateCommitteeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommitteeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.committeeUpdate(id, updateCommitteeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitteeApi.committeeUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CommitteeApi - factory interface
 * @export
 */
export const CommitteeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommitteeApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateCommitteeRequest} [createCommitteeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        committeeCreate(createCommitteeRequest?: CreateCommitteeRequest, options?: RawAxiosRequestConfig): AxiosPromise<CommitteeDto> {
            return localVarFp.committeeCreate(createCommitteeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        committeeDelete(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.committeeDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        committeeGet(id: string, options?: RawAxiosRequestConfig): AxiosPromise<CommitteeDto> {
            return localVarFp.committeeGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        committeeList(options?: RawAxiosRequestConfig): AxiosPromise<Array<CommitteeDto>> {
            return localVarFp.committeeList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCommitteeRequest} [updateCommitteeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        committeeUpdate(id: string, updateCommitteeRequest?: UpdateCommitteeRequest, options?: RawAxiosRequestConfig): AxiosPromise<CommitteeDto> {
            return localVarFp.committeeUpdate(id, updateCommitteeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommitteeApi - object-oriented interface
 * @export
 * @class CommitteeApi
 * @extends {BaseAPI}
 */
export class CommitteeApi extends BaseAPI {
    /**
     * 
     * @param {CreateCommitteeRequest} [createCommitteeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitteeApi
     */
    public committeeCreate(createCommitteeRequest?: CreateCommitteeRequest, options?: RawAxiosRequestConfig) {
        return CommitteeApiFp(this.configuration).committeeCreate(createCommitteeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitteeApi
     */
    public committeeDelete(id: string, options?: RawAxiosRequestConfig) {
        return CommitteeApiFp(this.configuration).committeeDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitteeApi
     */
    public committeeGet(id: string, options?: RawAxiosRequestConfig) {
        return CommitteeApiFp(this.configuration).committeeGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitteeApi
     */
    public committeeList(options?: RawAxiosRequestConfig) {
        return CommitteeApiFp(this.configuration).committeeList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateCommitteeRequest} [updateCommitteeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitteeApi
     */
    public committeeUpdate(id: string, updateCommitteeRequest?: UpdateCommitteeRequest, options?: RawAxiosRequestConfig) {
        return CommitteeApiFp(this.configuration).committeeUpdate(id, updateCommitteeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CommonApi - axios parameter creator
 * @export
 */
export const CommonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [childID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonAddCommitteeChildRelation: async (committeeID?: string, childID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/AddCommitteeChildRelation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (committeeID !== undefined) {
                localVarQueryParameter['CommitteeID'] = committeeID;
            }

            if (childID !== undefined) {
                localVarQueryParameter['ChildID'] = childID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddRemoveCommitteeIDProjectRequestDto} [addRemoveCommitteeIDProjectRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonAddRemoveCommitteeIDProject: async (addRemoveCommitteeIDProjectRequestDto?: AddRemoveCommitteeIDProjectRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/AddRemoveCommitteeIDProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addRemoveCommitteeIDProjectRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CheckObjectSecurityUserRequestDto} [checkObjectSecurityUserRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonCheckObjectSecurityUser: async (checkObjectSecurityUserRequestDto?: CheckObjectSecurityUserRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/CheckObjectSecurityUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkObjectSecurityUserRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [agendaID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonDeleteAgenda: async (agendaID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/DeleteAgenda`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (agendaID !== undefined) {
                localVarQueryParameter['AgendaID'] = agendaID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [childID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonDeleteCommitteeChild: async (committeeID?: string, childID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/DeleteCommitteeChild`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (committeeID !== undefined) {
                localVarQueryParameter['CommitteeID'] = committeeID;
            }

            if (childID !== undefined) {
                localVarQueryParameter['ChildID'] = childID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sessionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonDeleteCommitteeSession: async (sessionID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/DeleteCommitteeSession`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sessionID !== undefined) {
                localVarQueryParameter['SessionID'] = sessionID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [committeeID] 
         * @param {string | null} [sessionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetAgenda: async (committeeID?: string | null, sessionID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetAgenda`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (committeeID !== undefined) {
                localVarQueryParameter['CommitteeID'] = committeeID;
            }

            if (sessionID !== undefined) {
                localVarQueryParameter['SessionID'] = sessionID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetAgendaCalendar: async (committeeID?: string, month?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetAgendaCalendar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (committeeID !== undefined) {
                localVarQueryParameter['CommitteeID'] = committeeID;
            }

            if (month !== undefined) {
                localVarQueryParameter['Month'] = (month as any instanceof Date) ?
                    (month as any).toISOString() :
                    month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetAvailableChildCommittees: async (committeeID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetAvailableChildCommittees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (committeeID !== undefined) {
                localVarQueryParameter['committeeID'] = committeeID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [commiteeId] 
         * @param {boolean} [isRevisori] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetAvailableCommitteeMembers: async (commiteeId?: string, isRevisori?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetAvailableCommitteeMembers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (commiteeId !== undefined) {
                localVarQueryParameter['commiteeId'] = commiteeId;
            }

            if (isRevisori !== undefined) {
                localVarQueryParameter['isRevisori'] = isRevisori;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetChildCommittee: async (committeeID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetChildCommittee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (committeeID !== undefined) {
                localVarQueryParameter['committeeID'] = committeeID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [languageCode] 
         * @param {boolean} [closedOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeActivities: async (committeeID?: string, languageCode?: string, closedOnly?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetCommitteeActivities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (committeeID !== undefined) {
                localVarQueryParameter['committeeID'] = committeeID;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }

            if (closedOnly !== undefined) {
                localVarQueryParameter['closedOnly'] = closedOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string | null} [sessionID] 
         * @param {string | null} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeAvailableProjects: async (committeeID?: string, sessionID?: string | null, xmatrixID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetCommitteeAvailableProjects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (committeeID !== undefined) {
                localVarQueryParameter['committeeID'] = committeeID;
            }

            if (sessionID !== undefined) {
                localVarQueryParameter['SessionID'] = sessionID;
            }

            if (xmatrixID !== undefined) {
                localVarQueryParameter['XmatrixID'] = xmatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteePicklist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetCommitteePicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeProjects: async (committeeID?: string, xmatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetCommitteeProjects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (committeeID !== undefined) {
                localVarQueryParameter['committeeID'] = committeeID;
            }

            if (xmatrixID !== undefined) {
                localVarQueryParameter['XmatrixID'] = xmatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeSessions: async (committeeID?: string, xMatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetCommitteeSessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (committeeID !== undefined) {
                localVarQueryParameter['committeeID'] = committeeID;
            }

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetCommitteeSintesiListRequest} [getCommitteeSintesiListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeSintesiList: async (getCommitteeSintesiListRequest?: GetCommitteeSintesiListRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetCommitteeSintesiList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getCommitteeSintesiListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeSummary: async (committeeID?: string, xMatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetCommitteeSummary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (committeeID !== undefined) {
                localVarQueryParameter['CommitteeID'] = committeeID;
            }

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeSummaryPerStatus: async (committeeID?: string, languageCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetCommitteeSummaryPerStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (committeeID !== undefined) {
                localVarQueryParameter['CommitteeID'] = committeeID;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeSummaryPerTime: async (committeeID?: string, languageCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetCommitteeSummaryPerTime`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (committeeID !== undefined) {
                localVarQueryParameter['CommitteeID'] = committeeID;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetKPIAnnualGoalPicklist: async (annualGoalID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetKPIAnnualGoalPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (annualGoalID !== undefined) {
                localVarQueryParameter['annualGoalID'] = annualGoalID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetNewDivisionSponsorPicklist: async (divisionID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetNewDivisionSponsorPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (divisionID !== undefined) {
                localVarQueryParameter['divisionID'] = divisionID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetNewDivisionTeamLeaderPicklist: async (divisionID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetNewDivisionTeamLeaderPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (divisionID !== undefined) {
                localVarQueryParameter['divisionID'] = divisionID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xmatrixID] 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetNewDivisionXMatrixSecondaryProjectPicklist: async (xmatrixID?: string, divisionID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetNewDivisionXMatrixSecondaryProjectPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xmatrixID !== undefined) {
                localVarQueryParameter['xmatrixID'] = xmatrixID;
            }

            if (divisionID !== undefined) {
                localVarQueryParameter['divisionID'] = divisionID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetNoCommitteeProject: async (xMatrixID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetNoCommitteeProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [objectCode] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetObjectCodePicklist: async (objectCode?: string, languageCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetObjectCodePicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (objectCode !== undefined) {
                localVarQueryParameter['objectCode'] = objectCode;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [objectCode] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetStatusPicklist: async (objectCode?: string, languageCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetStatusPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (objectCode !== undefined) {
                localVarQueryParameter['objectCode'] = objectCode;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetUserSelect: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetUserSelect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertUpdateAgendaRequestDto} [insertUpdateAgendaRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonInsertUpdateAgenda: async (insertUpdateAgendaRequestDto?: InsertUpdateAgendaRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/InsertUpdateAgenda`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertUpdateAgendaRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertUpdateCommitteeSessionRequestDto} [insertUpdateCommitteeSessionRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonInsertUpdateCommitteeSession: async (insertUpdateCommitteeSessionRequestDto?: InsertUpdateCommitteeSessionRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/InsertUpdateCommitteeSession`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertUpdateCommitteeSessionRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [sessionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonSendEmail: async (committeeID?: string, sessionID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/SendEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (committeeID !== undefined) {
                localVarQueryParameter['CommitteeID'] = committeeID;
            }

            if (sessionID !== undefined) {
                localVarQueryParameter['SessionID'] = sessionID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateCommitteeCommentRequestDto} [updateCommitteeCommentRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonUpdateCommitteeComment: async (updateCommitteeCommentRequestDto?: UpdateCommitteeCommentRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/UpdateCommitteeComment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCommitteeCommentRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommonApi - functional programming interface
 * @export
 */
export const CommonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommonApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [childID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonAddCommitteeChildRelation(committeeID?: string, childID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonAddCommitteeChildRelation(committeeID, childID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonAddCommitteeChildRelation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AddRemoveCommitteeIDProjectRequestDto} [addRemoveCommitteeIDProjectRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonAddRemoveCommitteeIDProject(addRemoveCommitteeIDProjectRequestDto?: AddRemoveCommitteeIDProjectRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonAddRemoveCommitteeIDProject(addRemoveCommitteeIDProjectRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonAddRemoveCommitteeIDProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CheckObjectSecurityUserRequestDto} [checkObjectSecurityUserRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonCheckObjectSecurityUser(checkObjectSecurityUserRequestDto?: CheckObjectSecurityUserRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckObjectSecurityUserResponseControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonCheckObjectSecurityUser(checkObjectSecurityUserRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonCheckObjectSecurityUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [agendaID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonDeleteAgenda(agendaID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonDeleteAgenda(agendaID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonDeleteAgenda']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [childID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonDeleteCommitteeChild(committeeID?: string, childID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonDeleteCommitteeChild(committeeID, childID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonDeleteCommitteeChild']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [sessionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonDeleteCommitteeSession(sessionID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonDeleteCommitteeSession(sessionID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonDeleteCommitteeSession']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [committeeID] 
         * @param {string | null} [sessionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetAgenda(committeeID?: string | null, sessionID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAgendaResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetAgenda(committeeID, sessionID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetAgenda']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetAgendaCalendar(committeeID?: string, month?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAgendaCalendarResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetAgendaCalendar(committeeID, month, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetAgendaCalendar']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetAvailableChildCommittees(committeeID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAvailableChildCommitteesResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetAvailableChildCommittees(committeeID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetAvailableChildCommittees']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [commiteeId] 
         * @param {boolean} [isRevisori] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetAvailableCommitteeMembers(commiteeId?: string, isRevisori?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommitteMembersListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetAvailableCommitteeMembers(commiteeId, isRevisori, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetAvailableCommitteeMembers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetChildCommittee(committeeID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChildCommitteeResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetChildCommittee(committeeID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetChildCommittee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [languageCode] 
         * @param {boolean} [closedOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetCommitteeActivities(committeeID?: string, languageCode?: string, closedOnly?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommitteeActivitiesResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetCommitteeActivities(committeeID, languageCode, closedOnly, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetCommitteeActivities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string | null} [sessionID] 
         * @param {string | null} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetCommitteeAvailableProjects(committeeID?: string, sessionID?: string | null, xmatrixID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommitteeAvailableProjectsResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetCommitteeAvailableProjects(committeeID, sessionID, xmatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetCommitteeAvailableProjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetCommitteePicklist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommitteePicklistDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetCommitteePicklist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetCommitteePicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetCommitteeProjects(committeeID?: string, xmatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommitteeProjectsResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetCommitteeProjects(committeeID, xmatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetCommitteeProjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetCommitteeSessions(committeeID?: string, xMatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommitteeSessionsResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetCommitteeSessions(committeeID, xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetCommitteeSessions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetCommitteeSintesiListRequest} [getCommitteeSintesiListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetCommitteeSintesiList(getCommitteeSintesiListRequest?: GetCommitteeSintesiListRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSintesiComitatiResponseListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetCommitteeSintesiList(getCommitteeSintesiListRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetCommitteeSintesiList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetCommitteeSummary(committeeID?: string, xMatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommitteeSummaryResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetCommitteeSummary(committeeID, xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetCommitteeSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetCommitteeSummaryPerStatus(committeeID?: string, languageCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommitteeSummaryPerStatusResponseListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetCommitteeSummaryPerStatus(committeeID, languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetCommitteeSummaryPerStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetCommitteeSummaryPerTime(committeeID?: string, languageCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommitteeSummaryPerTimeResponseListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetCommitteeSummaryPerTime(committeeID, languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetCommitteeSummaryPerTime']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetKPIAnnualGoalPicklist(annualGoalID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetKPIAnnualGoalPicklistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetKPIAnnualGoalPicklist(annualGoalID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetKPIAnnualGoalPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetNewDivisionSponsorPicklist(divisionID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNewDivisionSponsorPicklistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetNewDivisionSponsorPicklist(divisionID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetNewDivisionSponsorPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetNewDivisionTeamLeaderPicklist(divisionID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNewDivisionTeamLeaderPicklistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetNewDivisionTeamLeaderPicklist(divisionID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetNewDivisionTeamLeaderPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xmatrixID] 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetNewDivisionXMatrixSecondaryProjectPicklist(xmatrixID?: string, divisionID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNewDivisionXMatrixSecondaryProjectPicklistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetNewDivisionXMatrixSecondaryProjectPicklist(xmatrixID, divisionID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetNewDivisionXMatrixSecondaryProjectPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetNoCommitteeProject(xMatrixID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNoCommitteeProjectsResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetNoCommitteeProject(xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetNoCommitteeProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [objectCode] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetObjectCodePicklist(objectCode?: string, languageCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectCodePicklistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetObjectCodePicklist(objectCode, languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetObjectCodePicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [objectCode] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetStatusPicklist(objectCode?: string, languageCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusPicklistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetStatusPicklist(objectCode, languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetStatusPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetUserSelect(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserSelectResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetUserSelect(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetUserSelect']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertUpdateAgendaRequestDto} [insertUpdateAgendaRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonInsertUpdateAgenda(insertUpdateAgendaRequestDto?: InsertUpdateAgendaRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonInsertUpdateAgenda(insertUpdateAgendaRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonInsertUpdateAgenda']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertUpdateCommitteeSessionRequestDto} [insertUpdateCommitteeSessionRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonInsertUpdateCommitteeSession(insertUpdateCommitteeSessionRequestDto?: InsertUpdateCommitteeSessionRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsertUpdateCommitteeSessionResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonInsertUpdateCommitteeSession(insertUpdateCommitteeSessionRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonInsertUpdateCommitteeSession']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [sessionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonSendEmail(committeeID?: string, sessionID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonSendEmail(committeeID, sessionID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonSendEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateCommitteeCommentRequestDto} [updateCommitteeCommentRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonUpdateCommitteeComment(updateCommitteeCommentRequestDto?: UpdateCommitteeCommentRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonUpdateCommitteeComment(updateCommitteeCommentRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonUpdateCommitteeComment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CommonApi - factory interface
 * @export
 */
export const CommonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommonApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [childID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonAddCommitteeChildRelation(committeeID?: string, childID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.commonAddCommitteeChildRelation(committeeID, childID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddRemoveCommitteeIDProjectRequestDto} [addRemoveCommitteeIDProjectRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonAddRemoveCommitteeIDProject(addRemoveCommitteeIDProjectRequestDto?: AddRemoveCommitteeIDProjectRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.commonAddRemoveCommitteeIDProject(addRemoveCommitteeIDProjectRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CheckObjectSecurityUserRequestDto} [checkObjectSecurityUserRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonCheckObjectSecurityUser(checkObjectSecurityUserRequestDto?: CheckObjectSecurityUserRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<CheckObjectSecurityUserResponseControllerResponse> {
            return localVarFp.commonCheckObjectSecurityUser(checkObjectSecurityUserRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [agendaID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonDeleteAgenda(agendaID?: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.commonDeleteAgenda(agendaID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [childID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonDeleteCommitteeChild(committeeID?: string, childID?: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.commonDeleteCommitteeChild(committeeID, childID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [sessionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonDeleteCommitteeSession(sessionID?: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.commonDeleteCommitteeSession(sessionID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [committeeID] 
         * @param {string | null} [sessionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetAgenda(committeeID?: string | null, sessionID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<GetAgendaResponseDtoControllerResponse> {
            return localVarFp.commonGetAgenda(committeeID, sessionID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetAgendaCalendar(committeeID?: string, month?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetAgendaCalendarResponseDtoListControllerResponse> {
            return localVarFp.commonGetAgendaCalendar(committeeID, month, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetAvailableChildCommittees(committeeID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetAvailableChildCommitteesResponseDtoListControllerResponse> {
            return localVarFp.commonGetAvailableChildCommittees(committeeID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [commiteeId] 
         * @param {boolean} [isRevisori] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetAvailableCommitteeMembers(commiteeId?: string, isRevisori?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<CommitteMembersListControllerResponse> {
            return localVarFp.commonGetAvailableCommitteeMembers(commiteeId, isRevisori, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetChildCommittee(committeeID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ChildCommitteeResponseDtoListControllerResponse> {
            return localVarFp.commonGetChildCommittee(committeeID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [languageCode] 
         * @param {boolean} [closedOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeActivities(committeeID?: string, languageCode?: string, closedOnly?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<GetCommitteeActivitiesResponseDtoListControllerResponse> {
            return localVarFp.commonGetCommitteeActivities(committeeID, languageCode, closedOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string | null} [sessionID] 
         * @param {string | null} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeAvailableProjects(committeeID?: string, sessionID?: string | null, xmatrixID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<GetCommitteeAvailableProjectsResponseDtoListControllerResponse> {
            return localVarFp.commonGetCommitteeAvailableProjects(committeeID, sessionID, xmatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteePicklist(options?: RawAxiosRequestConfig): AxiosPromise<GetCommitteePicklistDtoListControllerResponse> {
            return localVarFp.commonGetCommitteePicklist(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeProjects(committeeID?: string, xmatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetCommitteeProjectsResponseDtoListControllerResponse> {
            return localVarFp.commonGetCommitteeProjects(committeeID, xmatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeSessions(committeeID?: string, xMatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetCommitteeSessionsResponseDtoListControllerResponse> {
            return localVarFp.commonGetCommitteeSessions(committeeID, xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetCommitteeSintesiListRequest} [getCommitteeSintesiListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeSintesiList(getCommitteeSintesiListRequest?: GetCommitteeSintesiListRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetSintesiComitatiResponseListControllerResponse> {
            return localVarFp.commonGetCommitteeSintesiList(getCommitteeSintesiListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeSummary(committeeID?: string, xMatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetCommitteeSummaryResponseDtoControllerResponse> {
            return localVarFp.commonGetCommitteeSummary(committeeID, xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeSummaryPerStatus(committeeID?: string, languageCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetCommitteeSummaryPerStatusResponseListControllerResponse> {
            return localVarFp.commonGetCommitteeSummaryPerStatus(committeeID, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeSummaryPerTime(committeeID?: string, languageCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetCommitteeSummaryPerTimeResponseListControllerResponse> {
            return localVarFp.commonGetCommitteeSummaryPerTime(committeeID, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetKPIAnnualGoalPicklist(annualGoalID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetKPIAnnualGoalPicklistResponseDtoListControllerResponse> {
            return localVarFp.commonGetKPIAnnualGoalPicklist(annualGoalID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetNewDivisionSponsorPicklist(divisionID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetNewDivisionSponsorPicklistResponseDtoListControllerResponse> {
            return localVarFp.commonGetNewDivisionSponsorPicklist(divisionID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetNewDivisionTeamLeaderPicklist(divisionID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetNewDivisionTeamLeaderPicklistResponseDtoListControllerResponse> {
            return localVarFp.commonGetNewDivisionTeamLeaderPicklist(divisionID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xmatrixID] 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetNewDivisionXMatrixSecondaryProjectPicklist(xmatrixID?: string, divisionID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetNewDivisionXMatrixSecondaryProjectPicklistResponseDtoListControllerResponse> {
            return localVarFp.commonGetNewDivisionXMatrixSecondaryProjectPicklist(xmatrixID, divisionID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetNoCommitteeProject(xMatrixID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<GetNoCommitteeProjectsResponseDtoListControllerResponse> {
            return localVarFp.commonGetNoCommitteeProject(xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [objectCode] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetObjectCodePicklist(objectCode?: string, languageCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<ObjectCodePicklistResponseDtoListControllerResponse> {
            return localVarFp.commonGetObjectCodePicklist(objectCode, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [objectCode] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetStatusPicklist(objectCode?: string, languageCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<StatusPicklistResponseDtoListControllerResponse> {
            return localVarFp.commonGetStatusPicklist(objectCode, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetUserSelect(options?: RawAxiosRequestConfig): AxiosPromise<GetUserSelectResponseDtoListControllerResponse> {
            return localVarFp.commonGetUserSelect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertUpdateAgendaRequestDto} [insertUpdateAgendaRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonInsertUpdateAgenda(insertUpdateAgendaRequestDto?: InsertUpdateAgendaRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.commonInsertUpdateAgenda(insertUpdateAgendaRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertUpdateCommitteeSessionRequestDto} [insertUpdateCommitteeSessionRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonInsertUpdateCommitteeSession(insertUpdateCommitteeSessionRequestDto?: InsertUpdateCommitteeSessionRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<InsertUpdateCommitteeSessionResponseDtoControllerResponse> {
            return localVarFp.commonInsertUpdateCommitteeSession(insertUpdateCommitteeSessionRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [sessionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonSendEmail(committeeID?: string, sessionID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.commonSendEmail(committeeID, sessionID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateCommitteeCommentRequestDto} [updateCommitteeCommentRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonUpdateCommitteeComment(updateCommitteeCommentRequestDto?: UpdateCommitteeCommentRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.commonUpdateCommitteeComment(updateCommitteeCommentRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommonApi - object-oriented interface
 * @export
 * @class CommonApi
 * @extends {BaseAPI}
 */
export class CommonApi extends BaseAPI {
    /**
     * 
     * @param {string} [committeeID] 
     * @param {string} [childID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonAddCommitteeChildRelation(committeeID?: string, childID?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonAddCommitteeChildRelation(committeeID, childID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddRemoveCommitteeIDProjectRequestDto} [addRemoveCommitteeIDProjectRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonAddRemoveCommitteeIDProject(addRemoveCommitteeIDProjectRequestDto?: AddRemoveCommitteeIDProjectRequestDto, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonAddRemoveCommitteeIDProject(addRemoveCommitteeIDProjectRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CheckObjectSecurityUserRequestDto} [checkObjectSecurityUserRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonCheckObjectSecurityUser(checkObjectSecurityUserRequestDto?: CheckObjectSecurityUserRequestDto, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonCheckObjectSecurityUser(checkObjectSecurityUserRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [agendaID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonDeleteAgenda(agendaID?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonDeleteAgenda(agendaID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [committeeID] 
     * @param {string} [childID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonDeleteCommitteeChild(committeeID?: string, childID?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonDeleteCommitteeChild(committeeID, childID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [sessionID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonDeleteCommitteeSession(sessionID?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonDeleteCommitteeSession(sessionID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [committeeID] 
     * @param {string | null} [sessionID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetAgenda(committeeID?: string | null, sessionID?: string | null, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetAgenda(committeeID, sessionID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [committeeID] 
     * @param {string} [month] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetAgendaCalendar(committeeID?: string, month?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetAgendaCalendar(committeeID, month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [committeeID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetAvailableChildCommittees(committeeID?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetAvailableChildCommittees(committeeID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [commiteeId] 
     * @param {boolean} [isRevisori] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetAvailableCommitteeMembers(commiteeId?: string, isRevisori?: boolean, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetAvailableCommitteeMembers(commiteeId, isRevisori, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [committeeID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetChildCommittee(committeeID?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetChildCommittee(committeeID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [committeeID] 
     * @param {string} [languageCode] 
     * @param {boolean} [closedOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetCommitteeActivities(committeeID?: string, languageCode?: string, closedOnly?: boolean, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetCommitteeActivities(committeeID, languageCode, closedOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [committeeID] 
     * @param {string | null} [sessionID] 
     * @param {string | null} [xmatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetCommitteeAvailableProjects(committeeID?: string, sessionID?: string | null, xmatrixID?: string | null, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetCommitteeAvailableProjects(committeeID, sessionID, xmatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetCommitteePicklist(options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetCommitteePicklist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [committeeID] 
     * @param {string} [xmatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetCommitteeProjects(committeeID?: string, xmatrixID?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetCommitteeProjects(committeeID, xmatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [committeeID] 
     * @param {string} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetCommitteeSessions(committeeID?: string, xMatrixID?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetCommitteeSessions(committeeID, xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetCommitteeSintesiListRequest} [getCommitteeSintesiListRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetCommitteeSintesiList(getCommitteeSintesiListRequest?: GetCommitteeSintesiListRequest, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetCommitteeSintesiList(getCommitteeSintesiListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [committeeID] 
     * @param {string} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetCommitteeSummary(committeeID?: string, xMatrixID?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetCommitteeSummary(committeeID, xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [committeeID] 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetCommitteeSummaryPerStatus(committeeID?: string, languageCode?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetCommitteeSummaryPerStatus(committeeID, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [committeeID] 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetCommitteeSummaryPerTime(committeeID?: string, languageCode?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetCommitteeSummaryPerTime(committeeID, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [annualGoalID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetKPIAnnualGoalPicklist(annualGoalID?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetKPIAnnualGoalPicklist(annualGoalID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [divisionID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetNewDivisionSponsorPicklist(divisionID?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetNewDivisionSponsorPicklist(divisionID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [divisionID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetNewDivisionTeamLeaderPicklist(divisionID?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetNewDivisionTeamLeaderPicklist(divisionID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xmatrixID] 
     * @param {string} [divisionID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetNewDivisionXMatrixSecondaryProjectPicklist(xmatrixID?: string, divisionID?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetNewDivisionXMatrixSecondaryProjectPicklist(xmatrixID, divisionID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetNoCommitteeProject(xMatrixID?: string | null, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetNoCommitteeProject(xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [objectCode] 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetObjectCodePicklist(objectCode?: string, languageCode?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetObjectCodePicklist(objectCode, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [objectCode] 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetStatusPicklist(objectCode?: string, languageCode?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetStatusPicklist(objectCode, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetUserSelect(options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetUserSelect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertUpdateAgendaRequestDto} [insertUpdateAgendaRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonInsertUpdateAgenda(insertUpdateAgendaRequestDto?: InsertUpdateAgendaRequestDto, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonInsertUpdateAgenda(insertUpdateAgendaRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertUpdateCommitteeSessionRequestDto} [insertUpdateCommitteeSessionRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonInsertUpdateCommitteeSession(insertUpdateCommitteeSessionRequestDto?: InsertUpdateCommitteeSessionRequestDto, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonInsertUpdateCommitteeSession(insertUpdateCommitteeSessionRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [committeeID] 
     * @param {string} [sessionID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonSendEmail(committeeID?: string, sessionID?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonSendEmail(committeeID, sessionID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateCommitteeCommentRequestDto} [updateCommitteeCommentRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonUpdateCommitteeComment(updateCommitteeCommentRequestDto?: UpdateCommitteeCommentRequestDto, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonUpdateCommitteeComment(updateCommitteeCommentRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [companyID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyGetAlertConfiguration: async (companyID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Company/GetAlertConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyID !== undefined) {
                localVarQueryParameter['CompanyID'] = companyID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertAlertConfigurationRequestDto} [insertAlertConfigurationRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyInsertAlertConfiguration: async (insertAlertConfigurationRequestDto?: InsertAlertConfigurationRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Company/InsertAlertConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertAlertConfigurationRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyInsertNextYearUserActivityPlanning: async (year?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Company/InsertNextYearUserActivityPlanning`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAlertConfigurationRequestDto} [updateAlertConfigurationRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyUpdateAlertConfiguration: async (updateAlertConfigurationRequestDto?: UpdateAlertConfigurationRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Company/UpdateAlertConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAlertConfigurationRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [mission] 
         * @param {string} [vision] 
         * @param {File} [logo] 
         * @param {string} [values] 
         * @param {number | null} [timeAlertEdge] 
         * @param {number | null} [budgetAlertEdge] 
         * @param {number | null} [qualityAlertEdge] 
         * @param {string} [companyName] 
         * @param {string} [currency] 
         * @param {number} [budgetAlertSelection] 
         * @param {boolean | null} [sendNotification] 
         * @param {number | null} [daysOfNotice] 
         * @param {number | null} [noticeStatus] 
         * @param {string} [redirectLink] 
         * @param {string} [supportMail] 
         * @param {number} [nextStepsDaysOfNotice] 
         * @param {number} [implementationDaysOfNotice] 
         * @param {number} [kpiTolerance] 
         * @param {boolean} [startFromCurrentMonth] 
         * @param {boolean} [resetGlobalKpi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyUpdateCompanyProfile: async (mission?: string, vision?: string, logo?: File, values?: string, timeAlertEdge?: number | null, budgetAlertEdge?: number | null, qualityAlertEdge?: number | null, companyName?: string, currency?: string, budgetAlertSelection?: number, sendNotification?: boolean | null, daysOfNotice?: number | null, noticeStatus?: number | null, redirectLink?: string, supportMail?: string, nextStepsDaysOfNotice?: number, implementationDaysOfNotice?: number, kpiTolerance?: number, startFromCurrentMonth?: boolean, resetGlobalKpi?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Company/UpdateCompanyProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (mission !== undefined) { 
                localVarFormParams.append('Mission', mission as any);
            }
    
            if (vision !== undefined) { 
                localVarFormParams.append('Vision', vision as any);
            }
    
            if (logo !== undefined) { 
                localVarFormParams.append('Logo', logo as any);
            }
    
            if (values !== undefined) { 
                localVarFormParams.append('Values', values as any);
            }
    
            if (timeAlertEdge !== undefined) { 
                localVarFormParams.append('TimeAlertEdge', timeAlertEdge as any);
            }
    
            if (budgetAlertEdge !== undefined) { 
                localVarFormParams.append('BudgetAlertEdge', budgetAlertEdge as any);
            }
    
            if (qualityAlertEdge !== undefined) { 
                localVarFormParams.append('QualityAlertEdge', qualityAlertEdge as any);
            }
    
            if (companyName !== undefined) { 
                localVarFormParams.append('CompanyName', companyName as any);
            }
    
            if (currency !== undefined) { 
                localVarFormParams.append('Currency', currency as any);
            }
    
            if (budgetAlertSelection !== undefined) { 
                localVarFormParams.append('BudgetAlertSelection', budgetAlertSelection as any);
            }
    
            if (sendNotification !== undefined) { 
                localVarFormParams.append('SendNotification', String(sendNotification) as any);
            }
    
            if (daysOfNotice !== undefined) { 
                localVarFormParams.append('DaysOfNotice', daysOfNotice as any);
            }
    
            if (noticeStatus !== undefined) { 
                localVarFormParams.append('NoticeStatus', noticeStatus as any);
            }
    
            if (redirectLink !== undefined) { 
                localVarFormParams.append('RedirectLink', redirectLink as any);
            }
    
            if (supportMail !== undefined) { 
                localVarFormParams.append('SupportMail', supportMail as any);
            }
    
            if (nextStepsDaysOfNotice !== undefined) { 
                localVarFormParams.append('NextStepsDaysOfNotice', nextStepsDaysOfNotice as any);
            }
    
            if (implementationDaysOfNotice !== undefined) { 
                localVarFormParams.append('ImplementationDaysOfNotice', implementationDaysOfNotice as any);
            }
    
            if (kpiTolerance !== undefined) { 
                localVarFormParams.append('KpiTolerance', kpiTolerance as any);
            }
    
            if (startFromCurrentMonth !== undefined) { 
                localVarFormParams.append('StartFromCurrentMonth', String(startFromCurrentMonth) as any);
            }
    
            if (resetGlobalKpi !== undefined) { 
                localVarFormParams.append('ResetGlobalKpi', String(resetGlobalKpi) as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [companyID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyGetAlertConfiguration(companyID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlertConfigurationResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyGetAlertConfiguration(companyID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.companyGetAlertConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertAlertConfigurationRequestDto} [insertAlertConfigurationRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyInsertAlertConfiguration(insertAlertConfigurationRequestDto?: InsertAlertConfigurationRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsertAlertConfigurationResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyInsertAlertConfiguration(insertAlertConfigurationRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.companyInsertAlertConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyInsertNextYearUserActivityPlanning(year?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyInsertNextYearUserActivityPlanning(year, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.companyInsertNextYearUserActivityPlanning']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateAlertConfigurationRequestDto} [updateAlertConfigurationRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyUpdateAlertConfiguration(updateAlertConfigurationRequestDto?: UpdateAlertConfigurationRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyUpdateAlertConfiguration(updateAlertConfigurationRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.companyUpdateAlertConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [mission] 
         * @param {string} [vision] 
         * @param {File} [logo] 
         * @param {string} [values] 
         * @param {number | null} [timeAlertEdge] 
         * @param {number | null} [budgetAlertEdge] 
         * @param {number | null} [qualityAlertEdge] 
         * @param {string} [companyName] 
         * @param {string} [currency] 
         * @param {number} [budgetAlertSelection] 
         * @param {boolean | null} [sendNotification] 
         * @param {number | null} [daysOfNotice] 
         * @param {number | null} [noticeStatus] 
         * @param {string} [redirectLink] 
         * @param {string} [supportMail] 
         * @param {number} [nextStepsDaysOfNotice] 
         * @param {number} [implementationDaysOfNotice] 
         * @param {number} [kpiTolerance] 
         * @param {boolean} [startFromCurrentMonth] 
         * @param {boolean} [resetGlobalKpi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyUpdateCompanyProfile(mission?: string, vision?: string, logo?: File, values?: string, timeAlertEdge?: number | null, budgetAlertEdge?: number | null, qualityAlertEdge?: number | null, companyName?: string, currency?: string, budgetAlertSelection?: number, sendNotification?: boolean | null, daysOfNotice?: number | null, noticeStatus?: number | null, redirectLink?: string, supportMail?: string, nextStepsDaysOfNotice?: number, implementationDaysOfNotice?: number, kpiTolerance?: number, startFromCurrentMonth?: boolean, resetGlobalKpi?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyUpdateCompanyProfile(mission, vision, logo, values, timeAlertEdge, budgetAlertEdge, qualityAlertEdge, companyName, currency, budgetAlertSelection, sendNotification, daysOfNotice, noticeStatus, redirectLink, supportMail, nextStepsDaysOfNotice, implementationDaysOfNotice, kpiTolerance, startFromCurrentMonth, resetGlobalKpi, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.companyUpdateCompanyProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [companyID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyGetAlertConfiguration(companyID?: string, options?: RawAxiosRequestConfig): AxiosPromise<AlertConfigurationResponseDtoControllerResponse> {
            return localVarFp.companyGetAlertConfiguration(companyID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertAlertConfigurationRequestDto} [insertAlertConfigurationRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyInsertAlertConfiguration(insertAlertConfigurationRequestDto?: InsertAlertConfigurationRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<InsertAlertConfigurationResponseDtoControllerResponse> {
            return localVarFp.companyInsertAlertConfiguration(insertAlertConfigurationRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyInsertNextYearUserActivityPlanning(year?: string, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.companyInsertNextYearUserActivityPlanning(year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAlertConfigurationRequestDto} [updateAlertConfigurationRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyUpdateAlertConfiguration(updateAlertConfigurationRequestDto?: UpdateAlertConfigurationRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.companyUpdateAlertConfiguration(updateAlertConfigurationRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [mission] 
         * @param {string} [vision] 
         * @param {File} [logo] 
         * @param {string} [values] 
         * @param {number | null} [timeAlertEdge] 
         * @param {number | null} [budgetAlertEdge] 
         * @param {number | null} [qualityAlertEdge] 
         * @param {string} [companyName] 
         * @param {string} [currency] 
         * @param {number} [budgetAlertSelection] 
         * @param {boolean | null} [sendNotification] 
         * @param {number | null} [daysOfNotice] 
         * @param {number | null} [noticeStatus] 
         * @param {string} [redirectLink] 
         * @param {string} [supportMail] 
         * @param {number} [nextStepsDaysOfNotice] 
         * @param {number} [implementationDaysOfNotice] 
         * @param {number} [kpiTolerance] 
         * @param {boolean} [startFromCurrentMonth] 
         * @param {boolean} [resetGlobalKpi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyUpdateCompanyProfile(mission?: string, vision?: string, logo?: File, values?: string, timeAlertEdge?: number | null, budgetAlertEdge?: number | null, qualityAlertEdge?: number | null, companyName?: string, currency?: string, budgetAlertSelection?: number, sendNotification?: boolean | null, daysOfNotice?: number | null, noticeStatus?: number | null, redirectLink?: string, supportMail?: string, nextStepsDaysOfNotice?: number, implementationDaysOfNotice?: number, kpiTolerance?: number, startFromCurrentMonth?: boolean, resetGlobalKpi?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.companyUpdateCompanyProfile(mission, vision, logo, values, timeAlertEdge, budgetAlertEdge, qualityAlertEdge, companyName, currency, budgetAlertSelection, sendNotification, daysOfNotice, noticeStatus, redirectLink, supportMail, nextStepsDaysOfNotice, implementationDaysOfNotice, kpiTolerance, startFromCurrentMonth, resetGlobalKpi, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * 
     * @param {string} [companyID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyGetAlertConfiguration(companyID?: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyGetAlertConfiguration(companyID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertAlertConfigurationRequestDto} [insertAlertConfigurationRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyInsertAlertConfiguration(insertAlertConfigurationRequestDto?: InsertAlertConfigurationRequestDto, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyInsertAlertConfiguration(insertAlertConfigurationRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyInsertNextYearUserActivityPlanning(year?: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyInsertNextYearUserActivityPlanning(year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateAlertConfigurationRequestDto} [updateAlertConfigurationRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyUpdateAlertConfiguration(updateAlertConfigurationRequestDto?: UpdateAlertConfigurationRequestDto, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyUpdateAlertConfiguration(updateAlertConfigurationRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [mission] 
     * @param {string} [vision] 
     * @param {File} [logo] 
     * @param {string} [values] 
     * @param {number | null} [timeAlertEdge] 
     * @param {number | null} [budgetAlertEdge] 
     * @param {number | null} [qualityAlertEdge] 
     * @param {string} [companyName] 
     * @param {string} [currency] 
     * @param {number} [budgetAlertSelection] 
     * @param {boolean | null} [sendNotification] 
     * @param {number | null} [daysOfNotice] 
     * @param {number | null} [noticeStatus] 
     * @param {string} [redirectLink] 
     * @param {string} [supportMail] 
     * @param {number} [nextStepsDaysOfNotice] 
     * @param {number} [implementationDaysOfNotice] 
     * @param {number} [kpiTolerance] 
     * @param {boolean} [startFromCurrentMonth] 
     * @param {boolean} [resetGlobalKpi] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyUpdateCompanyProfile(mission?: string, vision?: string, logo?: File, values?: string, timeAlertEdge?: number | null, budgetAlertEdge?: number | null, qualityAlertEdge?: number | null, companyName?: string, currency?: string, budgetAlertSelection?: number, sendNotification?: boolean | null, daysOfNotice?: number | null, noticeStatus?: number | null, redirectLink?: string, supportMail?: string, nextStepsDaysOfNotice?: number, implementationDaysOfNotice?: number, kpiTolerance?: number, startFromCurrentMonth?: boolean, resetGlobalKpi?: boolean, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyUpdateCompanyProfile(mission, vision, logo, values, timeAlertEdge, budgetAlertEdge, qualityAlertEdge, companyName, currency, budgetAlertSelection, sendNotification, daysOfNotice, noticeStatus, redirectLink, supportMail, nextStepsDaysOfNotice, implementationDaysOfNotice, kpiTolerance, startFromCurrentMonth, resetGlobalKpi, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DeliverableApi - axios parameter creator
 * @export
 */
export const DeliverableApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableDeleteDeliverable: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deliverableDeleteDeliverable', 'id', id)
            const localVarPath = `/api/Deliverable/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FastCloseDeliverableRequestDto} [fastCloseDeliverableRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableFastCloseDeliverable: async (fastCloseDeliverableRequestDto?: FastCloseDeliverableRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Deliverable/FastCloseDeliverable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fastCloseDeliverableRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [deliverableID] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableGetChildDeliverable: async (deliverableID?: string, languageCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Deliverable/GetChildDeliverable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (deliverableID !== undefined) {
                localVarQueryParameter['DeliverableID'] = deliverableID;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [deliverableID] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableGetDeliverableByID: async (deliverableID?: string, languageCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Deliverable/GetDeliverable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (deliverableID !== undefined) {
                localVarQueryParameter['DeliverableID'] = deliverableID;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableGetDeliverableListPage: async (languageCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Deliverable/GetDeliverableListPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableGetDeliverablePicklist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Deliverable/GetDeliverablePicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [deliverableID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableGetProjectDeliverableTeam: async (deliverableID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Deliverable/GetProjectDeliverableTeam`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (deliverableID !== undefined) {
                localVarQueryParameter['DeliverableID'] = deliverableID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertDeliverableRequestDto} [insertDeliverableRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableInsertDeliverable: async (insertDeliverableRequestDto?: InsertDeliverableRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Deliverable/InsertDeliverable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertDeliverableRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDeliverableRequestDto} [updateDeliverableRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableUpdateDeliverable: async (updateDeliverableRequestDto?: UpdateDeliverableRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Deliverable/UpdateDeliverable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeliverableRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeliverableApi - functional programming interface
 * @export
 */
export const DeliverableApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeliverableApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliverableDeleteDeliverable(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliverableDeleteDeliverable(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliverableApi.deliverableDeleteDeliverable']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FastCloseDeliverableRequestDto} [fastCloseDeliverableRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliverableFastCloseDeliverable(fastCloseDeliverableRequestDto?: FastCloseDeliverableRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FastCloseDeliverableResponseControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliverableFastCloseDeliverable(fastCloseDeliverableRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliverableApi.deliverableFastCloseDeliverable']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [deliverableID] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliverableGetChildDeliverable(deliverableID?: string, languageCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetChildDeliverableResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliverableGetChildDeliverable(deliverableID, languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliverableApi.deliverableGetChildDeliverable']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [deliverableID] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliverableGetDeliverableByID(deliverableID?: string, languageCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDeliverableResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliverableGetDeliverableByID(deliverableID, languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliverableApi.deliverableGetDeliverableByID']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliverableGetDeliverableListPage(languageCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDeliverableListPageResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliverableGetDeliverableListPage(languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliverableApi.deliverableGetDeliverableListPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliverableGetDeliverablePicklist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDeliverablePicklistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliverableGetDeliverablePicklist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliverableApi.deliverableGetDeliverablePicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [deliverableID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliverableGetProjectDeliverableTeam(deliverableID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectDeliverableTeamResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliverableGetProjectDeliverableTeam(deliverableID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliverableApi.deliverableGetProjectDeliverableTeam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertDeliverableRequestDto} [insertDeliverableRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliverableInsertDeliverable(insertDeliverableRequestDto?: InsertDeliverableRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliverableInsertDeliverable(insertDeliverableRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliverableApi.deliverableInsertDeliverable']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateDeliverableRequestDto} [updateDeliverableRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliverableUpdateDeliverable(updateDeliverableRequestDto?: UpdateDeliverableRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateDeliverableResponseControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliverableUpdateDeliverable(updateDeliverableRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliverableApi.deliverableUpdateDeliverable']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DeliverableApi - factory interface
 * @export
 */
export const DeliverableApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeliverableApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableDeleteDeliverable(id: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.deliverableDeleteDeliverable(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FastCloseDeliverableRequestDto} [fastCloseDeliverableRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableFastCloseDeliverable(fastCloseDeliverableRequestDto?: FastCloseDeliverableRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<FastCloseDeliverableResponseControllerResponse> {
            return localVarFp.deliverableFastCloseDeliverable(fastCloseDeliverableRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [deliverableID] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableGetChildDeliverable(deliverableID?: string, languageCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetChildDeliverableResponseDtoListControllerResponse> {
            return localVarFp.deliverableGetChildDeliverable(deliverableID, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [deliverableID] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableGetDeliverableByID(deliverableID?: string, languageCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetDeliverableResponseDtoControllerResponse> {
            return localVarFp.deliverableGetDeliverableByID(deliverableID, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableGetDeliverableListPage(languageCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetDeliverableListPageResponseDtoListControllerResponse> {
            return localVarFp.deliverableGetDeliverableListPage(languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableGetDeliverablePicklist(options?: RawAxiosRequestConfig): AxiosPromise<GetDeliverablePicklistResponseDtoListControllerResponse> {
            return localVarFp.deliverableGetDeliverablePicklist(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [deliverableID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableGetProjectDeliverableTeam(deliverableID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetProjectDeliverableTeamResponseDtoListControllerResponse> {
            return localVarFp.deliverableGetProjectDeliverableTeam(deliverableID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertDeliverableRequestDto} [insertDeliverableRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableInsertDeliverable(insertDeliverableRequestDto?: InsertDeliverableRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.deliverableInsertDeliverable(insertDeliverableRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDeliverableRequestDto} [updateDeliverableRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableUpdateDeliverable(updateDeliverableRequestDto?: UpdateDeliverableRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<UpdateDeliverableResponseControllerResponse> {
            return localVarFp.deliverableUpdateDeliverable(updateDeliverableRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeliverableApi - object-oriented interface
 * @export
 * @class DeliverableApi
 * @extends {BaseAPI}
 */
export class DeliverableApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliverableApi
     */
    public deliverableDeleteDeliverable(id: string, options?: RawAxiosRequestConfig) {
        return DeliverableApiFp(this.configuration).deliverableDeleteDeliverable(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FastCloseDeliverableRequestDto} [fastCloseDeliverableRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliverableApi
     */
    public deliverableFastCloseDeliverable(fastCloseDeliverableRequestDto?: FastCloseDeliverableRequestDto, options?: RawAxiosRequestConfig) {
        return DeliverableApiFp(this.configuration).deliverableFastCloseDeliverable(fastCloseDeliverableRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [deliverableID] 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliverableApi
     */
    public deliverableGetChildDeliverable(deliverableID?: string, languageCode?: string, options?: RawAxiosRequestConfig) {
        return DeliverableApiFp(this.configuration).deliverableGetChildDeliverable(deliverableID, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [deliverableID] 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliverableApi
     */
    public deliverableGetDeliverableByID(deliverableID?: string, languageCode?: string, options?: RawAxiosRequestConfig) {
        return DeliverableApiFp(this.configuration).deliverableGetDeliverableByID(deliverableID, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliverableApi
     */
    public deliverableGetDeliverableListPage(languageCode?: string, options?: RawAxiosRequestConfig) {
        return DeliverableApiFp(this.configuration).deliverableGetDeliverableListPage(languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliverableApi
     */
    public deliverableGetDeliverablePicklist(options?: RawAxiosRequestConfig) {
        return DeliverableApiFp(this.configuration).deliverableGetDeliverablePicklist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [deliverableID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliverableApi
     */
    public deliverableGetProjectDeliverableTeam(deliverableID?: string, options?: RawAxiosRequestConfig) {
        return DeliverableApiFp(this.configuration).deliverableGetProjectDeliverableTeam(deliverableID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertDeliverableRequestDto} [insertDeliverableRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliverableApi
     */
    public deliverableInsertDeliverable(insertDeliverableRequestDto?: InsertDeliverableRequestDto, options?: RawAxiosRequestConfig) {
        return DeliverableApiFp(this.configuration).deliverableInsertDeliverable(insertDeliverableRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDeliverableRequestDto} [updateDeliverableRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliverableApi
     */
    public deliverableUpdateDeliverable(updateDeliverableRequestDto?: UpdateDeliverableRequestDto, options?: RawAxiosRequestConfig) {
        return DeliverableApiFp(this.configuration).deliverableUpdateDeliverable(updateDeliverableRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DivisionApi - axios parameter creator
 * @export
 */
export const DivisionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} divisionName 
         * @param {string | null} [ownerId] 
         * @param {File} [logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        divisionCreate: async (divisionName: string, ownerId?: string | null, logo?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'divisionName' is not null or undefined
            assertParamExists('divisionCreate', 'divisionName', divisionName)
            const localVarPath = `/api/divisions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (divisionName !== undefined) { 
                localVarFormParams.append('DivisionName', divisionName as any);
            }
    
            if (ownerId !== undefined) { 
                localVarFormParams.append('OwnerId', ownerId as any);
            }
    
            if (logo !== undefined) { 
                localVarFormParams.append('Logo', logo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        divisionDeleteDivision: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('divisionDeleteDivision', 'id', id)
            const localVarPath = `/api/divisions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        divisionGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('divisionGet', 'id', id)
            const localVarPath = `/api/divisions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        divisionList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/divisions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} divisionName 
         * @param {string | null} [ownerId] 
         * @param {File} [logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        divisionUpdate: async (id: string, divisionName: string, ownerId?: string | null, logo?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('divisionUpdate', 'id', id)
            // verify required parameter 'divisionName' is not null or undefined
            assertParamExists('divisionUpdate', 'divisionName', divisionName)
            const localVarPath = `/api/divisions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (divisionName !== undefined) { 
                localVarFormParams.append('DivisionName', divisionName as any);
            }
    
            if (ownerId !== undefined) { 
                localVarFormParams.append('OwnerId', ownerId as any);
            }
    
            if (logo !== undefined) { 
                localVarFormParams.append('Logo', logo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DivisionApi - functional programming interface
 * @export
 */
export const DivisionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DivisionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} divisionName 
         * @param {string | null} [ownerId] 
         * @param {File} [logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async divisionCreate(divisionName: string, ownerId?: string | null, logo?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DivisionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.divisionCreate(divisionName, ownerId, logo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DivisionApi.divisionCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async divisionDeleteDivision(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.divisionDeleteDivision(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DivisionApi.divisionDeleteDivision']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async divisionGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DivisionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.divisionGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DivisionApi.divisionGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async divisionList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DivisionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.divisionList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DivisionApi.divisionList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} divisionName 
         * @param {string | null} [ownerId] 
         * @param {File} [logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async divisionUpdate(id: string, divisionName: string, ownerId?: string | null, logo?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DivisionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.divisionUpdate(id, divisionName, ownerId, logo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DivisionApi.divisionUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DivisionApi - factory interface
 * @export
 */
export const DivisionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DivisionApiFp(configuration)
    return {
        /**
         * 
         * @param {string} divisionName 
         * @param {string | null} [ownerId] 
         * @param {File} [logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        divisionCreate(divisionName: string, ownerId?: string | null, logo?: File, options?: RawAxiosRequestConfig): AxiosPromise<DivisionDto> {
            return localVarFp.divisionCreate(divisionName, ownerId, logo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        divisionDeleteDivision(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.divisionDeleteDivision(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        divisionGet(id: string, options?: RawAxiosRequestConfig): AxiosPromise<DivisionDto> {
            return localVarFp.divisionGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        divisionList(options?: RawAxiosRequestConfig): AxiosPromise<Array<DivisionDto>> {
            return localVarFp.divisionList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} divisionName 
         * @param {string | null} [ownerId] 
         * @param {File} [logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        divisionUpdate(id: string, divisionName: string, ownerId?: string | null, logo?: File, options?: RawAxiosRequestConfig): AxiosPromise<DivisionDto> {
            return localVarFp.divisionUpdate(id, divisionName, ownerId, logo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DivisionApi - object-oriented interface
 * @export
 * @class DivisionApi
 * @extends {BaseAPI}
 */
export class DivisionApi extends BaseAPI {
    /**
     * 
     * @param {string} divisionName 
     * @param {string | null} [ownerId] 
     * @param {File} [logo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DivisionApi
     */
    public divisionCreate(divisionName: string, ownerId?: string | null, logo?: File, options?: RawAxiosRequestConfig) {
        return DivisionApiFp(this.configuration).divisionCreate(divisionName, ownerId, logo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DivisionApi
     */
    public divisionDeleteDivision(id: string, options?: RawAxiosRequestConfig) {
        return DivisionApiFp(this.configuration).divisionDeleteDivision(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DivisionApi
     */
    public divisionGet(id: string, options?: RawAxiosRequestConfig) {
        return DivisionApiFp(this.configuration).divisionGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DivisionApi
     */
    public divisionList(options?: RawAxiosRequestConfig) {
        return DivisionApiFp(this.configuration).divisionList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} divisionName 
     * @param {string | null} [ownerId] 
     * @param {File} [logo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DivisionApi
     */
    public divisionUpdate(id: string, divisionName: string, ownerId?: string | null, logo?: File, options?: RawAxiosRequestConfig) {
        return DivisionApiFp(this.configuration).divisionUpdate(id, divisionName, ownerId, logo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FeedApi - axios parameter creator
 * @export
 */
export const FeedApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [activityID] 
         * @param {number} [activityType] 
         * @param {string} [outlookEventId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedDeleteActivityFeed: async (activityID?: string, activityType?: number, outlookEventId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Feed/DeleteActivityFeed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (activityID !== undefined) {
                localVarQueryParameter['ActivityID'] = activityID;
            }

            if (activityType !== undefined) {
                localVarQueryParameter['ActivityType'] = activityType;
            }

            if (outlookEventId !== undefined) {
                localVarQueryParameter['OutlookEventId'] = outlookEventId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [activityID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedGetFeedActivity: async (activityID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Feed/GetFeedActivity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (activityID !== undefined) {
                localVarQueryParameter['ActivityID'] = activityID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [regardingObjectID] 
         * @param {string} [userID] 
         * @param {string} [isProject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedGetFeedActivityList: async (regardingObjectID?: string, userID?: string, isProject?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Feed/GetFeedActivityList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (regardingObjectID !== undefined) {
                localVarQueryParameter['RegardingObjectID'] = regardingObjectID;
            }

            if (userID !== undefined) {
                localVarQueryParameter['UserID'] = userID;
            }

            if (isProject !== undefined) {
                localVarQueryParameter['IsProject'] = isProject;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedGetFeedMonthlyAlertByUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Feed/GetFeedMonthlyAlertByUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [regardingObjectID] 
         * @param {string} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedGetProjectFeedActivityList: async (regardingObjectID?: string, userID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Feed/GetProjectFeedActivityList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (regardingObjectID !== undefined) {
                localVarQueryParameter['RegardingObjectID'] = regardingObjectID;
            }

            if (userID !== undefined) {
                localVarQueryParameter['UserID'] = userID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertFeedActivityLikeRequestDto} [insertFeedActivityLikeRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedInsertFeedActivityLike: async (insertFeedActivityLikeRequestDto?: InsertFeedActivityLikeRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Feed/InsertFeedActivityLike`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertFeedActivityLikeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertFeedCommentRequestDto} [insertFeedCommentRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedInsertFeedComment: async (insertFeedCommentRequestDto?: InsertFeedCommentRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Feed/InsertFeedComment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertFeedCommentRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertFeedFileRequestDto} [insertFeedFileRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedInsertFeedFile: async (insertFeedFileRequestDto?: InsertFeedFileRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Feed/InsertFeedFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertFeedFileRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertFeedMeetingRequestDto} [insertFeedMeetingRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedInsertFeedMeeting: async (insertFeedMeetingRequestDto?: InsertFeedMeetingRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Feed/InsertFeedMeeting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertFeedMeetingRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertFeedNotificationRequestDto} [insertFeedNotificationRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedInsertFeedNotification: async (insertFeedNotificationRequestDto?: InsertFeedNotificationRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Feed/InsertFeedNotification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertFeedNotificationRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<InsertMeetingParticipantRequestDto>} [insertMeetingParticipantRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedInsertMeetingParticipant: async (insertMeetingParticipantRequestDto?: Array<InsertMeetingParticipantRequestDto>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Feed/InsertMeetingParticipant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertMeetingParticipantRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateFeedActivityRequestDto} [updateFeedActivityRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedUpdateFeedActivity: async (updateFeedActivityRequestDto?: UpdateFeedActivityRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Feed/UpdateFeedActivity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFeedActivityRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedUpdateMonthlyAlertFeed: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Feed/UpdateMonthlyAlertFeed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedApi - functional programming interface
 * @export
 */
export const FeedApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeedApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [activityID] 
         * @param {number} [activityType] 
         * @param {string} [outlookEventId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedDeleteActivityFeed(activityID?: string, activityType?: number, outlookEventId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedDeleteActivityFeed(activityID, activityType, outlookEventId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedApi.feedDeleteActivityFeed']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [activityID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedGetFeedActivity(activityID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFeedActivityResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedGetFeedActivity(activityID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedApi.feedGetFeedActivity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [regardingObjectID] 
         * @param {string} [userID] 
         * @param {string} [isProject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedGetFeedActivityList(regardingObjectID?: string, userID?: string, isProject?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFeedActivityListResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedGetFeedActivityList(regardingObjectID, userID, isProject, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedApi.feedGetFeedActivityList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedGetFeedMonthlyAlertByUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFeedMonthlyAlertByUserResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedGetFeedMonthlyAlertByUser(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedApi.feedGetFeedMonthlyAlertByUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [regardingObjectID] 
         * @param {string} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedGetProjectFeedActivityList(regardingObjectID?: string, userID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectFeedActivityListResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedGetProjectFeedActivityList(regardingObjectID, userID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedApi.feedGetProjectFeedActivityList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertFeedActivityLikeRequestDto} [insertFeedActivityLikeRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedInsertFeedActivityLike(insertFeedActivityLikeRequestDto?: InsertFeedActivityLikeRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedInsertFeedActivityLike(insertFeedActivityLikeRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedApi.feedInsertFeedActivityLike']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertFeedCommentRequestDto} [insertFeedCommentRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedInsertFeedComment(insertFeedCommentRequestDto?: InsertFeedCommentRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedInsertFeedComment(insertFeedCommentRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedApi.feedInsertFeedComment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertFeedFileRequestDto} [insertFeedFileRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedInsertFeedFile(insertFeedFileRequestDto?: InsertFeedFileRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedInsertFeedFile(insertFeedFileRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedApi.feedInsertFeedFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertFeedMeetingRequestDto} [insertFeedMeetingRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedInsertFeedMeeting(insertFeedMeetingRequestDto?: InsertFeedMeetingRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedInsertFeedMeeting(insertFeedMeetingRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedApi.feedInsertFeedMeeting']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertFeedNotificationRequestDto} [insertFeedNotificationRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedInsertFeedNotification(insertFeedNotificationRequestDto?: InsertFeedNotificationRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedInsertFeedNotification(insertFeedNotificationRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedApi.feedInsertFeedNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<InsertMeetingParticipantRequestDto>} [insertMeetingParticipantRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedInsertMeetingParticipant(insertMeetingParticipantRequestDto?: Array<InsertMeetingParticipantRequestDto>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedInsertMeetingParticipant(insertMeetingParticipantRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedApi.feedInsertMeetingParticipant']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateFeedActivityRequestDto} [updateFeedActivityRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedUpdateFeedActivity(updateFeedActivityRequestDto?: UpdateFeedActivityRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedUpdateFeedActivity(updateFeedActivityRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedApi.feedUpdateFeedActivity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedUpdateMonthlyAlertFeed(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedUpdateMonthlyAlertFeed(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedApi.feedUpdateMonthlyAlertFeed']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FeedApi - factory interface
 * @export
 */
export const FeedApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeedApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [activityID] 
         * @param {number} [activityType] 
         * @param {string} [outlookEventId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedDeleteActivityFeed(activityID?: string, activityType?: number, outlookEventId?: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.feedDeleteActivityFeed(activityID, activityType, outlookEventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [activityID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedGetFeedActivity(activityID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<GetFeedActivityResponseDtoListControllerResponse> {
            return localVarFp.feedGetFeedActivity(activityID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [regardingObjectID] 
         * @param {string} [userID] 
         * @param {string} [isProject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedGetFeedActivityList(regardingObjectID?: string, userID?: string, isProject?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetFeedActivityListResponseDtoListControllerResponse> {
            return localVarFp.feedGetFeedActivityList(regardingObjectID, userID, isProject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedGetFeedMonthlyAlertByUser(options?: RawAxiosRequestConfig): AxiosPromise<GetFeedMonthlyAlertByUserResponseDtoListControllerResponse> {
            return localVarFp.feedGetFeedMonthlyAlertByUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [regardingObjectID] 
         * @param {string} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedGetProjectFeedActivityList(regardingObjectID?: string, userID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetProjectFeedActivityListResponseDtoListControllerResponse> {
            return localVarFp.feedGetProjectFeedActivityList(regardingObjectID, userID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertFeedActivityLikeRequestDto} [insertFeedActivityLikeRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedInsertFeedActivityLike(insertFeedActivityLikeRequestDto?: InsertFeedActivityLikeRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.feedInsertFeedActivityLike(insertFeedActivityLikeRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertFeedCommentRequestDto} [insertFeedCommentRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedInsertFeedComment(insertFeedCommentRequestDto?: InsertFeedCommentRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.feedInsertFeedComment(insertFeedCommentRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertFeedFileRequestDto} [insertFeedFileRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedInsertFeedFile(insertFeedFileRequestDto?: InsertFeedFileRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.feedInsertFeedFile(insertFeedFileRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertFeedMeetingRequestDto} [insertFeedMeetingRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedInsertFeedMeeting(insertFeedMeetingRequestDto?: InsertFeedMeetingRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.feedInsertFeedMeeting(insertFeedMeetingRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertFeedNotificationRequestDto} [insertFeedNotificationRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedInsertFeedNotification(insertFeedNotificationRequestDto?: InsertFeedNotificationRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.feedInsertFeedNotification(insertFeedNotificationRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<InsertMeetingParticipantRequestDto>} [insertMeetingParticipantRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedInsertMeetingParticipant(insertMeetingParticipantRequestDto?: Array<InsertMeetingParticipantRequestDto>, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.feedInsertMeetingParticipant(insertMeetingParticipantRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateFeedActivityRequestDto} [updateFeedActivityRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedUpdateFeedActivity(updateFeedActivityRequestDto?: UpdateFeedActivityRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.feedUpdateFeedActivity(updateFeedActivityRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedUpdateMonthlyAlertFeed(options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.feedUpdateMonthlyAlertFeed(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeedApi - object-oriented interface
 * @export
 * @class FeedApi
 * @extends {BaseAPI}
 */
export class FeedApi extends BaseAPI {
    /**
     * 
     * @param {string} [activityID] 
     * @param {number} [activityType] 
     * @param {string} [outlookEventId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedDeleteActivityFeed(activityID?: string, activityType?: number, outlookEventId?: string, options?: RawAxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedDeleteActivityFeed(activityID, activityType, outlookEventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [activityID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedGetFeedActivity(activityID?: string | null, options?: RawAxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedGetFeedActivity(activityID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [regardingObjectID] 
     * @param {string} [userID] 
     * @param {string} [isProject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedGetFeedActivityList(regardingObjectID?: string, userID?: string, isProject?: string, options?: RawAxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedGetFeedActivityList(regardingObjectID, userID, isProject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedGetFeedMonthlyAlertByUser(options?: RawAxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedGetFeedMonthlyAlertByUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [regardingObjectID] 
     * @param {string} [userID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedGetProjectFeedActivityList(regardingObjectID?: string, userID?: string, options?: RawAxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedGetProjectFeedActivityList(regardingObjectID, userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertFeedActivityLikeRequestDto} [insertFeedActivityLikeRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedInsertFeedActivityLike(insertFeedActivityLikeRequestDto?: InsertFeedActivityLikeRequestDto, options?: RawAxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedInsertFeedActivityLike(insertFeedActivityLikeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertFeedCommentRequestDto} [insertFeedCommentRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedInsertFeedComment(insertFeedCommentRequestDto?: InsertFeedCommentRequestDto, options?: RawAxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedInsertFeedComment(insertFeedCommentRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertFeedFileRequestDto} [insertFeedFileRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedInsertFeedFile(insertFeedFileRequestDto?: InsertFeedFileRequestDto, options?: RawAxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedInsertFeedFile(insertFeedFileRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertFeedMeetingRequestDto} [insertFeedMeetingRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedInsertFeedMeeting(insertFeedMeetingRequestDto?: InsertFeedMeetingRequestDto, options?: RawAxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedInsertFeedMeeting(insertFeedMeetingRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertFeedNotificationRequestDto} [insertFeedNotificationRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedInsertFeedNotification(insertFeedNotificationRequestDto?: InsertFeedNotificationRequestDto, options?: RawAxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedInsertFeedNotification(insertFeedNotificationRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<InsertMeetingParticipantRequestDto>} [insertMeetingParticipantRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedInsertMeetingParticipant(insertMeetingParticipantRequestDto?: Array<InsertMeetingParticipantRequestDto>, options?: RawAxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedInsertMeetingParticipant(insertMeetingParticipantRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateFeedActivityRequestDto} [updateFeedActivityRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedUpdateFeedActivity(updateFeedActivityRequestDto?: UpdateFeedActivityRequestDto, options?: RawAxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedUpdateFeedActivity(updateFeedActivityRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedUpdateMonthlyAlertFeed(options?: RawAxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedUpdateMonthlyAlertFeed(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GeneralApi - axios parameter creator
 * @export
 */
export const GeneralApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalGetCompany: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/General/GetCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalGetCompanyByID: async (companyId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/General/CompanyByID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalGetCompanyListByUserID: async (userID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/General/GetCompanyList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userID !== undefined) {
                localVarQueryParameter['userID'] = userID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalGetConnectionModel: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/General/GetConnectionString`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalGetMenuItem: async (languageCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/General/GetMenuItem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminInsertCompanyRequestDto} [adminInsertCompanyRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalInsertCompany: async (adminInsertCompanyRequestDto?: AdminInsertCompanyRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/General/AdminInsertCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminInsertCompanyRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateCompanyRequestDto} [updateCompanyRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalUpdateCompany: async (updateCompanyRequestDto?: UpdateCompanyRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/General/UpdateCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserCompanyRequest} [updateUserCompanyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalUpdateUserCompany: async (updateUserCompanyRequest?: UpdateUserCompanyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/General/UpdateUserCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserCompanyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeneralApi - functional programming interface
 * @export
 */
export const GeneralApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeneralApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generalGetCompany(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generalGetCompany(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralApi.generalGetCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generalGetCompanyByID(companyId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyByIdResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generalGetCompanyByID(companyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralApi.generalGetCompanyByID']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generalGetCompanyListByUserID(userID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCompanyListResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generalGetCompanyListByUserID(userID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralApi.generalGetCompanyListByUserID']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generalGetConnectionModel(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDetailsResposeControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generalGetConnectionModel(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralApi.generalGetConnectionModel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generalGetMenuItem(languageCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMenuItemResponseListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generalGetMenuItem(languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralApi.generalGetMenuItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminInsertCompanyRequestDto} [adminInsertCompanyRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generalInsertCompany(adminInsertCompanyRequestDto?: AdminInsertCompanyRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generalInsertCompany(adminInsertCompanyRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralApi.generalInsertCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateCompanyRequestDto} [updateCompanyRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generalUpdateCompany(updateCompanyRequestDto?: UpdateCompanyRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generalUpdateCompany(updateCompanyRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralApi.generalUpdateCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateUserCompanyRequest} [updateUserCompanyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generalUpdateUserCompany(updateUserCompanyRequest?: UpdateUserCompanyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDetailsResposeControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generalUpdateUserCompany(updateUserCompanyRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralApi.generalUpdateUserCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GeneralApi - factory interface
 * @export
 */
export const GeneralApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeneralApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalGetCompany(options?: RawAxiosRequestConfig): AxiosPromise<CompanyResponseDtoControllerResponse> {
            return localVarFp.generalGetCompany(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalGetCompanyByID(companyId?: string, options?: RawAxiosRequestConfig): AxiosPromise<CompanyByIdResponseDtoControllerResponse> {
            return localVarFp.generalGetCompanyByID(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalGetCompanyListByUserID(userID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetCompanyListResponseDtoListControllerResponse> {
            return localVarFp.generalGetCompanyListByUserID(userID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalGetConnectionModel(options?: RawAxiosRequestConfig): AxiosPromise<UserDetailsResposeControllerResponse> {
            return localVarFp.generalGetConnectionModel(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalGetMenuItem(languageCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetMenuItemResponseListControllerResponse> {
            return localVarFp.generalGetMenuItem(languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminInsertCompanyRequestDto} [adminInsertCompanyRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalInsertCompany(adminInsertCompanyRequestDto?: AdminInsertCompanyRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.generalInsertCompany(adminInsertCompanyRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateCompanyRequestDto} [updateCompanyRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalUpdateCompany(updateCompanyRequestDto?: UpdateCompanyRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.generalUpdateCompany(updateCompanyRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserCompanyRequest} [updateUserCompanyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalUpdateUserCompany(updateUserCompanyRequest?: UpdateUserCompanyRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDetailsResposeControllerResponse> {
            return localVarFp.generalUpdateUserCompany(updateUserCompanyRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GeneralApi - object-oriented interface
 * @export
 * @class GeneralApi
 * @extends {BaseAPI}
 */
export class GeneralApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public generalGetCompany(options?: RawAxiosRequestConfig) {
        return GeneralApiFp(this.configuration).generalGetCompany(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public generalGetCompanyByID(companyId?: string, options?: RawAxiosRequestConfig) {
        return GeneralApiFp(this.configuration).generalGetCompanyByID(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public generalGetCompanyListByUserID(userID?: string, options?: RawAxiosRequestConfig) {
        return GeneralApiFp(this.configuration).generalGetCompanyListByUserID(userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public generalGetConnectionModel(options?: RawAxiosRequestConfig) {
        return GeneralApiFp(this.configuration).generalGetConnectionModel(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public generalGetMenuItem(languageCode?: string, options?: RawAxiosRequestConfig) {
        return GeneralApiFp(this.configuration).generalGetMenuItem(languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminInsertCompanyRequestDto} [adminInsertCompanyRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public generalInsertCompany(adminInsertCompanyRequestDto?: AdminInsertCompanyRequestDto, options?: RawAxiosRequestConfig) {
        return GeneralApiFp(this.configuration).generalInsertCompany(adminInsertCompanyRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateCompanyRequestDto} [updateCompanyRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public generalUpdateCompany(updateCompanyRequestDto?: UpdateCompanyRequestDto, options?: RawAxiosRequestConfig) {
        return GeneralApiFp(this.configuration).generalUpdateCompany(updateCompanyRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateUserCompanyRequest} [updateUserCompanyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public generalUpdateUserCompany(updateUserCompanyRequest?: UpdateUserCompanyRequest, options?: RawAxiosRequestConfig) {
        return GeneralApiFp(this.configuration).generalUpdateUserCompany(updateUserCompanyRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * KPIApi - axios parameter creator
 * @export
 */
export const KPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPICheckDuplicateKPI: async (name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/CheckDuplicateKPI`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [iD] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPICheckKpiTypePlanningStartDate: async (iD?: string, type?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/CheckKpiTypePlanningStartDate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (iD !== undefined) {
                localVarQueryParameter['ID'] = iD;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [annualGoalKPIRelationShipID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIDeleteAnnualGoalKPIRelationshipBowlingChart: async (annualGoalKPIRelationShipID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/DeleteAnnualGoalKPIRelationshipBowlingChart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (annualGoalKPIRelationShipID !== undefined) {
                localVarQueryParameter['annualGoalKPIRelationShipID'] = annualGoalKPIRelationShipID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIDeleteKPI: async (kPIDetailID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/DeleteKPI`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (kPIDetailID !== undefined) {
                localVarQueryParameter['KPIDetailID'] = kPIDetailID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIDeleteUserFavouriteKPI: async (kPIDetailID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/DeleteUserFavouriteKPI`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (kPIDetailID !== undefined) {
                localVarQueryParameter['KPIDetailID'] = kPIDetailID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {number} [year] 
         * @param {string | null} [xMatrixID] 
         * @param {string | null} [projectID] 
         * @param {string | null} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIDetailsKPI: async (kPIDetailID?: string, year?: number, xMatrixID?: string | null, projectID?: string | null, annualGoalID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/DetailsKPI`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (kPIDetailID !== undefined) {
                localVarQueryParameter['KPIDetailID'] = kPIDetailID;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }

            if (annualGoalID !== undefined) {
                localVarQueryParameter['AnnualGoalID'] = annualGoalID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetAnnualGoalByKPI: async (kPIDetailID?: string, xMatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetAnnualGoalByKPI`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (kPIDetailID !== undefined) {
                localVarQueryParameter['KPIDetailID'] = kPIDetailID;
            }

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetAnnualGoalKPIBowlingChartListPage: async (annualGoalID?: string, year?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetAnnualGoalKPIBowlingChartListPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (annualGoalID !== undefined) {
                localVarQueryParameter['AnnualGoalID'] = annualGoalID;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {string | null} [projectAnnualGoalID] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetCommentForKPI: async (kPIDetailID?: string, projectAnnualGoalID?: string | null, type?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetCommentForKPI`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (kPIDetailID !== undefined) {
                localVarQueryParameter['KPIDetailID'] = kPIDetailID;
            }

            if (projectAnnualGoalID !== undefined) {
                localVarQueryParameter['ProjectAnnualGoalID'] = projectAnnualGoalID;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetGlobalKPIBowlingChartExport: async (kPIDetailID?: string, year?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetGlobalKPIBowlingChartExport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (kPIDetailID !== undefined) {
                localVarQueryParameter['KPIDetailID'] = kPIDetailID;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIAnnualGoalBowlingChart: async (id?: string, year?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPIAnnualGoalBowlingChart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIBowlingChartListPage: async (projectID?: string, year?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPIBowlingChartListPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {string} [xMatrix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIByAnnualGoal: async (annualGoalID?: string, xMatrix?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPIByAnnualGoal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (annualGoalID !== undefined) {
                localVarQueryParameter['annualGoalID'] = annualGoalID;
            }

            if (xMatrix !== undefined) {
                localVarQueryParameter['xMatrix'] = xMatrix;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIById: async (id?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPIById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIByProject: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPIByProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [parentKPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIChildPicklist: async (parentKPIDetailID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPIChildPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (parentKPIDetailID !== undefined) {
                localVarQueryParameter['ParentKPIDetailID'] = parentKPIDetailID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [month] 
         * @param {string} [annualGoalID] 
         * @param {string | null} [xMatrixID] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIDashboardSintesiByAnnualGoal: async (month?: number, annualGoalID?: string, xMatrixID?: string | null, year?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPIDashboardSintesiByAnnualGoal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (month !== undefined) {
                localVarQueryParameter['Month'] = month;
            }

            if (annualGoalID !== undefined) {
                localVarQueryParameter['AnnualGoalID'] = annualGoalID;
            }

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [month] 
         * @param {string} [projectID] 
         * @param {string | null} [xMatrixID] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIDashboardSintesiByProject: async (month?: number, projectID?: string, xMatrixID?: string | null, year?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPIDashboardSintesiByProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (month !== undefined) {
                localVarQueryParameter['Month'] = month;
            }

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIDetail: async (kPIDetailID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPIDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (kPIDetailID !== undefined) {
                localVarQueryParameter['KPIDetailID'] = kPIDetailID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIDetails: async (id?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPIDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {string} [searchType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIGerarchicoInfo: async (kPIDetailID?: string, searchType?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPIGerarchicoInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (kPIDetailID !== undefined) {
                localVarQueryParameter['KPIDetailID'] = kPIDetailID;
            }

            if (searchType !== undefined) {
                localVarQueryParameter['searchType'] = searchType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [month] 
         * @param {string} [xMatrixID] 
         * @param {string} [annualGoalID] 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIGraphic: async (month?: number, xMatrixID?: string, annualGoalID?: string, projectID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPIGraphic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (month !== undefined) {
                localVarQueryParameter['Month'] = month;
            }

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }

            if (annualGoalID !== undefined) {
                localVarQueryParameter['AnnualGoalID'] = annualGoalID;
            }

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPIList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string | null} [annualGoalID] 
         * @param {number} [year] 
         * @param {string} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIPlanningByID: async (projectID?: string | null, annualGoalID?: string | null, year?: number, kPIDetailID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPIPlanningByID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }

            if (annualGoalID !== undefined) {
                localVarQueryParameter['AnnualGoalID'] = annualGoalID;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }

            if (kPIDetailID !== undefined) {
                localVarQueryParameter['KPIDetailID'] = kPIDetailID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPISectionA3: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPISectionA3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIs: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPIs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetLongTermGoalByKPI: async (kPIDetailID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetLongTermGoalByKPI`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (kPIDetailID !== undefined) {
                localVarQueryParameter['KPIDetailID'] = kPIDetailID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [parentKPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetRollupKPIChild: async (parentKPIDetailID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetRollupKPIChild`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (parentKPIDetailID !== undefined) {
                localVarQueryParameter['ParentKPIDetailID'] = parentKPIDetailID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [annualGoalKPIRelationShipID] 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetSpecificAnnualGoalKPIBowlingChartExport: async (annualGoalKPIRelationShipID?: string, year?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetSpecificAnnualGoalKPIBowlingChartExport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (annualGoalKPIRelationShipID !== undefined) {
                localVarQueryParameter['AnnualGoalKPIRelationShipID'] = annualGoalKPIRelationShipID;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [kPIProjectRelationShipID] 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetSpecificKPIBowlingChartExport: async (kPIProjectRelationShipID?: string, projectID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetSpecificKPIBowlingChartExport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (kPIProjectRelationShipID !== undefined) {
                localVarQueryParameter['KPIProjectRelationShipID'] = kPIProjectRelationShipID;
            }

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {string} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIInsertKPIAnnualGoal: async (kPIDetailID?: string, annualGoalID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/InsertKPIAnnualGoal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (kPIDetailID !== undefined) {
                localVarQueryParameter['KPIDetailID'] = kPIDetailID;
            }

            if (annualGoalID !== undefined) {
                localVarQueryParameter['AnnualGoalID'] = annualGoalID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [parentKPIDetailID] 
         * @param {string} [childKPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIInsertKPIChild: async (parentKPIDetailID?: string, childKPIDetailID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/InsertKPIChild`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (parentKPIDetailID !== undefined) {
                localVarQueryParameter['ParentKPIDetailID'] = parentKPIDetailID;
            }

            if (childKPIDetailID !== undefined) {
                localVarQueryParameter['ChildKPIDetailID'] = childKPIDetailID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertKPIDetailRequestDto} [insertKPIDetailRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIInsertKPIDetail: async (insertKPIDetailRequestDto?: InsertKPIDetailRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/InsertKPIDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertKPIDetailRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertUserFavouriteKPIRequest} [insertUserFavouriteKPIRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIInsertUserFavouriteKPI: async (insertUserFavouriteKPIRequest?: InsertUserFavouriteKPIRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/InsertUserFavouriteKPI`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertUserFavouriteKPIRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {string} [annualGoalID] 
         * @param {string} [kPIDetailID] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIKPIDetailsGraficoA3: async (projectID?: string, annualGoalID?: string, kPIDetailID?: string, year?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/KPIDetailsGraficoA3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }

            if (annualGoalID !== undefined) {
                localVarQueryParameter['AnnualGoalID'] = annualGoalID;
            }

            if (kPIDetailID !== undefined) {
                localVarQueryParameter['KPIDetailID'] = kPIDetailID;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {number} [year] 
         * @param {string | null} [xMatrixID] 
         * @param {string | null} [projectID] 
         * @param {string | null} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIKPIDetailsPastYears: async (kPIDetailID?: string, year?: number, xMatrixID?: string | null, projectID?: string | null, annualGoalID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/KPIDetailsPastYears`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (kPIDetailID !== undefined) {
                localVarQueryParameter['KPIDetailID'] = kPIDetailID;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }

            if (annualGoalID !== undefined) {
                localVarQueryParameter['AnnualGoalID'] = annualGoalID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIRemoveKpiChildParentRelations: async (kPIDetailID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/RemoveKpiChildParentRelations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (kPIDetailID !== undefined) {
                localVarQueryParameter['KPIDetailID'] = kPIDetailID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateCommentForKPIRequestDto} [updateCommentForKPIRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIUpdateCommentForKPI: async (updateCommentForKPIRequestDto?: UpdateCommentForKPIRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/UpdateCommentForKPI`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCommentForKPIRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<UpdateGlobalKPIBowlingChartImportRequestDto>} [updateGlobalKPIBowlingChartImportRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIUpdateGlobalKPIBowlingChartImport: async (updateGlobalKPIBowlingChartImportRequestDto?: Array<UpdateGlobalKPIBowlingChartImportRequestDto>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/UpdateGlobalKPIBowlingChartImport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGlobalKPIBowlingChartImportRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateKPIAnnualGoalBowlingChartRequestDto} [updateKPIAnnualGoalBowlingChartRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIUpdateKPIAnnualGoalBowlingChart: async (updateKPIAnnualGoalBowlingChartRequestDto?: UpdateKPIAnnualGoalBowlingChartRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/UpdateKPIAnnualGoalBowlingChart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateKPIAnnualGoalBowlingChartRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateKPIDetailRequestDto} [updateKPIDetailRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIUpdateKPIDetail: async (updateKPIDetailRequestDto?: UpdateKPIDetailRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/UpdateKPIDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateKPIDetailRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<UpdateSpecificAnnualGoalKPIBowlingRequestDto>} [updateSpecificAnnualGoalKPIBowlingRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIUpdateSpecificAnnualGoalKPIBowlingChartImport: async (updateSpecificAnnualGoalKPIBowlingRequestDto?: Array<UpdateSpecificAnnualGoalKPIBowlingRequestDto>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/UpdateSpecificAnnualGoalKPIBowlingChartImport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSpecificAnnualGoalKPIBowlingRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<UpdateSpecificKPIBowlingChartImportRequestDto>} [updateSpecificKPIBowlingChartImportRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIUpdateSpecificKPIBowlingChartImport: async (updateSpecificKPIBowlingChartImportRequestDto?: Array<UpdateSpecificKPIBowlingChartImportRequestDto>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/UpdateSpecificKPIBowlingChartImport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSpecificKPIBowlingChartImportRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KPIApi - functional programming interface
 * @export
 */
export const KPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPICheckDuplicateKPI(name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPICheckDuplicateKPI(name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPICheckDuplicateKPI']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [iD] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPICheckKpiTypePlanningStartDate(iD?: string, type?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckKpiTypePlanningStartDateResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPICheckKpiTypePlanningStartDate(iD, type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPICheckKpiTypePlanningStartDate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [annualGoalKPIRelationShipID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIDeleteAnnualGoalKPIRelationshipBowlingChart(annualGoalKPIRelationShipID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIDeleteAnnualGoalKPIRelationshipBowlingChart(annualGoalKPIRelationShipID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIDeleteAnnualGoalKPIRelationshipBowlingChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIDeleteKPI(kPIDetailID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIDeleteKPI(kPIDetailID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIDeleteKPI']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIDeleteUserFavouriteKPI(kPIDetailID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIDeleteUserFavouriteKPI(kPIDetailID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIDeleteUserFavouriteKPI']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {number} [year] 
         * @param {string | null} [xMatrixID] 
         * @param {string | null} [projectID] 
         * @param {string | null} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIDetailsKPI(kPIDetailID?: string, year?: number, xMatrixID?: string | null, projectID?: string | null, annualGoalID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailsKPIResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIDetailsKPI(kPIDetailID, year, xMatrixID, projectID, annualGoalID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIDetailsKPI']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetAnnualGoalByKPI(kPIDetailID?: string, xMatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAnnualGoalByKPIResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetAnnualGoalByKPI(kPIDetailID, xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetAnnualGoalByKPI']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetAnnualGoalKPIBowlingChartListPage(annualGoalID?: string, year?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnualGoalKPIBowlingListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetAnnualGoalKPIBowlingChartListPage(annualGoalID, year, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetAnnualGoalKPIBowlingChartListPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {string | null} [projectAnnualGoalID] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetCommentForKPI(kPIDetailID?: string, projectAnnualGoalID?: string | null, type?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetKPICommentResponseControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetCommentForKPI(kPIDetailID, projectAnnualGoalID, type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetCommentForKPI']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetGlobalKPIBowlingChartExport(kPIDetailID?: string, year?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpandoObjectListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetGlobalKPIBowlingChartExport(kPIDetailID, year, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetGlobalKPIBowlingChartExport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPIAnnualGoalBowlingChart(id?: string, year?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpandoObjectListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPIAnnualGoalBowlingChart(id, year, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPIAnnualGoalBowlingChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPIBowlingChartListPage(projectID?: string, year?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KPIBowlingChartResponseListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPIBowlingChartListPage(projectID, year, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPIBowlingChartListPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {string} [xMatrix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPIByAnnualGoal(annualGoalID?: string, xMatrix?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetKPIByAnnualGoalResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPIByAnnualGoal(annualGoalID, xMatrix, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPIByAnnualGoal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPIById(id?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetKPIListPageDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPIById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPIById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPIByProject(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetKPIByProjectResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPIByProject(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPIByProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [parentKPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPIChildPicklist(parentKPIDetailID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetKPIChildPicklistResponseListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPIChildPicklist(parentKPIDetailID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPIChildPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [month] 
         * @param {string} [annualGoalID] 
         * @param {string | null} [xMatrixID] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPIDashboardSintesiByAnnualGoal(month?: number, annualGoalID?: string, xMatrixID?: string | null, year?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetKPIDashboardSintesiByAnnualGoalResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPIDashboardSintesiByAnnualGoal(month, annualGoalID, xMatrixID, year, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPIDashboardSintesiByAnnualGoal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [month] 
         * @param {string} [projectID] 
         * @param {string | null} [xMatrixID] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPIDashboardSintesiByProject(month?: number, projectID?: string, xMatrixID?: string | null, year?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetKPIDashboardSintesiByProjectResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPIDashboardSintesiByProject(month, projectID, xMatrixID, year, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPIDashboardSintesiByProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPIDetail(kPIDetailID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetKPIsDetailDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPIDetail(kPIDetailID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPIDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPIDetails(id?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetKPIDetailDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPIDetails(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPIDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {string} [searchType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPIGerarchicoInfo(kPIDetailID?: string, searchType?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetKPIGerarchicoInfoDTOListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPIGerarchicoInfo(kPIDetailID, searchType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPIGerarchicoInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [month] 
         * @param {string} [xMatrixID] 
         * @param {string} [annualGoalID] 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPIGraphic(month?: number, xMatrixID?: string, annualGoalID?: string, projectID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetKPIGraphicResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPIGraphic(month, xMatrixID, annualGoalID, projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPIGraphic']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPIList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetKPIListDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPIList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPIList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string | null} [annualGoalID] 
         * @param {number} [year] 
         * @param {string} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPIPlanningByID(projectID?: string | null, annualGoalID?: string | null, year?: number, kPIDetailID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KPIBowlingChartResponseListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPIPlanningByID(projectID, annualGoalID, year, kPIDetailID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPIPlanningByID']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPISectionA3(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetKPISectionA3ResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPISectionA3(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPISectionA3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPIs(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetKPIListDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPIs(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPIs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetLongTermGoalByKPI(kPIDetailID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLongTermGoalByKPIResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetLongTermGoalByKPI(kPIDetailID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetLongTermGoalByKPI']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [parentKPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetRollupKPIChild(parentKPIDetailID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRollupKPIChildResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetRollupKPIChild(parentKPIDetailID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetRollupKPIChild']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [annualGoalKPIRelationShipID] 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetSpecificAnnualGoalKPIBowlingChartExport(annualGoalKPIRelationShipID?: string, year?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpandoObjectListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetSpecificAnnualGoalKPIBowlingChartExport(annualGoalKPIRelationShipID, year, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetSpecificAnnualGoalKPIBowlingChartExport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [kPIProjectRelationShipID] 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetSpecificKPIBowlingChartExport(kPIProjectRelationShipID?: string, projectID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpandoObjectListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetSpecificKPIBowlingChartExport(kPIProjectRelationShipID, projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetSpecificKPIBowlingChartExport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {string} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIInsertKPIAnnualGoal(kPIDetailID?: string, annualGoalID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIInsertKPIAnnualGoal(kPIDetailID, annualGoalID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIInsertKPIAnnualGoal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [parentKPIDetailID] 
         * @param {string} [childKPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIInsertKPIChild(parentKPIDetailID?: string, childKPIDetailID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIInsertKPIChild(parentKPIDetailID, childKPIDetailID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIInsertKPIChild']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertKPIDetailRequestDto} [insertKPIDetailRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIInsertKPIDetail(insertKPIDetailRequestDto?: InsertKPIDetailRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsertKPIDetailResponseControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIInsertKPIDetail(insertKPIDetailRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIInsertKPIDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertUserFavouriteKPIRequest} [insertUserFavouriteKPIRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIInsertUserFavouriteKPI(insertUserFavouriteKPIRequest?: InsertUserFavouriteKPIRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIInsertUserFavouriteKPI(insertUserFavouriteKPIRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIInsertUserFavouriteKPI']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {string} [annualGoalID] 
         * @param {string} [kPIDetailID] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIKPIDetailsGraficoA3(projectID?: string, annualGoalID?: string, kPIDetailID?: string, year?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KPIDetailsGraficoA3ResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIKPIDetailsGraficoA3(projectID, annualGoalID, kPIDetailID, year, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIKPIDetailsGraficoA3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {number} [year] 
         * @param {string | null} [xMatrixID] 
         * @param {string | null} [projectID] 
         * @param {string | null} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIKPIDetailsPastYears(kPIDetailID?: string, year?: number, xMatrixID?: string | null, projectID?: string | null, annualGoalID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KPIDetailsPastYearsResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIKPIDetailsPastYears(kPIDetailID, year, xMatrixID, projectID, annualGoalID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIKPIDetailsPastYears']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIRemoveKpiChildParentRelations(kPIDetailID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIRemoveKpiChildParentRelations(kPIDetailID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIRemoveKpiChildParentRelations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateCommentForKPIRequestDto} [updateCommentForKPIRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIUpdateCommentForKPI(updateCommentForKPIRequestDto?: UpdateCommentForKPIRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIUpdateCommentForKPI(updateCommentForKPIRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIUpdateCommentForKPI']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<UpdateGlobalKPIBowlingChartImportRequestDto>} [updateGlobalKPIBowlingChartImportRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIUpdateGlobalKPIBowlingChartImport(updateGlobalKPIBowlingChartImportRequestDto?: Array<UpdateGlobalKPIBowlingChartImportRequestDto>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIUpdateGlobalKPIBowlingChartImport(updateGlobalKPIBowlingChartImportRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIUpdateGlobalKPIBowlingChartImport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateKPIAnnualGoalBowlingChartRequestDto} [updateKPIAnnualGoalBowlingChartRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIUpdateKPIAnnualGoalBowlingChart(updateKPIAnnualGoalBowlingChartRequestDto?: UpdateKPIAnnualGoalBowlingChartRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIUpdateKPIAnnualGoalBowlingChart(updateKPIAnnualGoalBowlingChartRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIUpdateKPIAnnualGoalBowlingChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateKPIDetailRequestDto} [updateKPIDetailRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIUpdateKPIDetail(updateKPIDetailRequestDto?: UpdateKPIDetailRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KPIDetailsDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIUpdateKPIDetail(updateKPIDetailRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIUpdateKPIDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<UpdateSpecificAnnualGoalKPIBowlingRequestDto>} [updateSpecificAnnualGoalKPIBowlingRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIUpdateSpecificAnnualGoalKPIBowlingChartImport(updateSpecificAnnualGoalKPIBowlingRequestDto?: Array<UpdateSpecificAnnualGoalKPIBowlingRequestDto>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIUpdateSpecificAnnualGoalKPIBowlingChartImport(updateSpecificAnnualGoalKPIBowlingRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIUpdateSpecificAnnualGoalKPIBowlingChartImport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<UpdateSpecificKPIBowlingChartImportRequestDto>} [updateSpecificKPIBowlingChartImportRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIUpdateSpecificKPIBowlingChartImport(updateSpecificKPIBowlingChartImportRequestDto?: Array<UpdateSpecificKPIBowlingChartImportRequestDto>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIUpdateSpecificKPIBowlingChartImport(updateSpecificKPIBowlingChartImportRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIUpdateSpecificKPIBowlingChartImport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * KPIApi - factory interface
 * @export
 */
export const KPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KPIApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPICheckDuplicateKPI(name?: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.kPICheckDuplicateKPI(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [iD] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPICheckKpiTypePlanningStartDate(iD?: string, type?: string, options?: RawAxiosRequestConfig): AxiosPromise<CheckKpiTypePlanningStartDateResponseDtoControllerResponse> {
            return localVarFp.kPICheckKpiTypePlanningStartDate(iD, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [annualGoalKPIRelationShipID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIDeleteAnnualGoalKPIRelationshipBowlingChart(annualGoalKPIRelationShipID?: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.kPIDeleteAnnualGoalKPIRelationshipBowlingChart(annualGoalKPIRelationShipID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIDeleteKPI(kPIDetailID?: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.kPIDeleteKPI(kPIDetailID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIDeleteUserFavouriteKPI(kPIDetailID?: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.kPIDeleteUserFavouriteKPI(kPIDetailID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {number} [year] 
         * @param {string | null} [xMatrixID] 
         * @param {string | null} [projectID] 
         * @param {string | null} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIDetailsKPI(kPIDetailID?: string, year?: number, xMatrixID?: string | null, projectID?: string | null, annualGoalID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<DetailsKPIResponseDtoListControllerResponse> {
            return localVarFp.kPIDetailsKPI(kPIDetailID, year, xMatrixID, projectID, annualGoalID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetAnnualGoalByKPI(kPIDetailID?: string, xMatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetAnnualGoalByKPIResponseDtoListControllerResponse> {
            return localVarFp.kPIGetAnnualGoalByKPI(kPIDetailID, xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetAnnualGoalKPIBowlingChartListPage(annualGoalID?: string, year?: string, options?: RawAxiosRequestConfig): AxiosPromise<AnnualGoalKPIBowlingListControllerResponse> {
            return localVarFp.kPIGetAnnualGoalKPIBowlingChartListPage(annualGoalID, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {string | null} [projectAnnualGoalID] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetCommentForKPI(kPIDetailID?: string, projectAnnualGoalID?: string | null, type?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetKPICommentResponseControllerResponse> {
            return localVarFp.kPIGetCommentForKPI(kPIDetailID, projectAnnualGoalID, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetGlobalKPIBowlingChartExport(kPIDetailID?: string, year?: string, options?: RawAxiosRequestConfig): AxiosPromise<ExpandoObjectListControllerResponse> {
            return localVarFp.kPIGetGlobalKPIBowlingChartExport(kPIDetailID, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIAnnualGoalBowlingChart(id?: string, year?: string, options?: RawAxiosRequestConfig): AxiosPromise<ExpandoObjectListControllerResponse> {
            return localVarFp.kPIGetKPIAnnualGoalBowlingChart(id, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIBowlingChartListPage(projectID?: string, year?: string, options?: RawAxiosRequestConfig): AxiosPromise<KPIBowlingChartResponseListControllerResponse> {
            return localVarFp.kPIGetKPIBowlingChartListPage(projectID, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {string} [xMatrix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIByAnnualGoal(annualGoalID?: string, xMatrix?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetKPIByAnnualGoalResponseDtoListControllerResponse> {
            return localVarFp.kPIGetKPIByAnnualGoal(annualGoalID, xMatrix, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIById(id?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetKPIListPageDtoControllerResponse> {
            return localVarFp.kPIGetKPIById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIByProject(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<GetKPIByProjectResponseDtoListControllerResponse> {
            return localVarFp.kPIGetKPIByProject(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [parentKPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIChildPicklist(parentKPIDetailID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetKPIChildPicklistResponseListControllerResponse> {
            return localVarFp.kPIGetKPIChildPicklist(parentKPIDetailID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [month] 
         * @param {string} [annualGoalID] 
         * @param {string | null} [xMatrixID] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIDashboardSintesiByAnnualGoal(month?: number, annualGoalID?: string, xMatrixID?: string | null, year?: number, options?: RawAxiosRequestConfig): AxiosPromise<GetKPIDashboardSintesiByAnnualGoalResponseDtoListControllerResponse> {
            return localVarFp.kPIGetKPIDashboardSintesiByAnnualGoal(month, annualGoalID, xMatrixID, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [month] 
         * @param {string} [projectID] 
         * @param {string | null} [xMatrixID] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIDashboardSintesiByProject(month?: number, projectID?: string, xMatrixID?: string | null, year?: number, options?: RawAxiosRequestConfig): AxiosPromise<GetKPIDashboardSintesiByProjectResponseDtoListControllerResponse> {
            return localVarFp.kPIGetKPIDashboardSintesiByProject(month, projectID, xMatrixID, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIDetail(kPIDetailID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetKPIsDetailDtoControllerResponse> {
            return localVarFp.kPIGetKPIDetail(kPIDetailID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIDetails(id?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<GetKPIDetailDtoControllerResponse> {
            return localVarFp.kPIGetKPIDetails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {string} [searchType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIGerarchicoInfo(kPIDetailID?: string, searchType?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetKPIGerarchicoInfoDTOListControllerResponse> {
            return localVarFp.kPIGetKPIGerarchicoInfo(kPIDetailID, searchType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [month] 
         * @param {string} [xMatrixID] 
         * @param {string} [annualGoalID] 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIGraphic(month?: number, xMatrixID?: string, annualGoalID?: string, projectID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetKPIGraphicResponseDtoListControllerResponse> {
            return localVarFp.kPIGetKPIGraphic(month, xMatrixID, annualGoalID, projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIList(options?: RawAxiosRequestConfig): AxiosPromise<GetKPIListDtoListControllerResponse> {
            return localVarFp.kPIGetKPIList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string | null} [annualGoalID] 
         * @param {number} [year] 
         * @param {string} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIPlanningByID(projectID?: string | null, annualGoalID?: string | null, year?: number, kPIDetailID?: string, options?: RawAxiosRequestConfig): AxiosPromise<KPIBowlingChartResponseListControllerResponse> {
            return localVarFp.kPIGetKPIPlanningByID(projectID, annualGoalID, year, kPIDetailID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPISectionA3(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<GetKPISectionA3ResponseDtoListControllerResponse> {
            return localVarFp.kPIGetKPISectionA3(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIs(options?: RawAxiosRequestConfig): AxiosPromise<GetKPIListDtoListControllerResponse> {
            return localVarFp.kPIGetKPIs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetLongTermGoalByKPI(kPIDetailID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<GetLongTermGoalByKPIResponseDtoListControllerResponse> {
            return localVarFp.kPIGetLongTermGoalByKPI(kPIDetailID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [parentKPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetRollupKPIChild(parentKPIDetailID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetRollupKPIChildResponseDtoListControllerResponse> {
            return localVarFp.kPIGetRollupKPIChild(parentKPIDetailID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [annualGoalKPIRelationShipID] 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetSpecificAnnualGoalKPIBowlingChartExport(annualGoalKPIRelationShipID?: string, year?: string, options?: RawAxiosRequestConfig): AxiosPromise<ExpandoObjectListControllerResponse> {
            return localVarFp.kPIGetSpecificAnnualGoalKPIBowlingChartExport(annualGoalKPIRelationShipID, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [kPIProjectRelationShipID] 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetSpecificKPIBowlingChartExport(kPIProjectRelationShipID?: string, projectID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ExpandoObjectListControllerResponse> {
            return localVarFp.kPIGetSpecificKPIBowlingChartExport(kPIProjectRelationShipID, projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {string} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIInsertKPIAnnualGoal(kPIDetailID?: string, annualGoalID?: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.kPIInsertKPIAnnualGoal(kPIDetailID, annualGoalID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [parentKPIDetailID] 
         * @param {string} [childKPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIInsertKPIChild(parentKPIDetailID?: string, childKPIDetailID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.kPIInsertKPIChild(parentKPIDetailID, childKPIDetailID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertKPIDetailRequestDto} [insertKPIDetailRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIInsertKPIDetail(insertKPIDetailRequestDto?: InsertKPIDetailRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<InsertKPIDetailResponseControllerResponse> {
            return localVarFp.kPIInsertKPIDetail(insertKPIDetailRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertUserFavouriteKPIRequest} [insertUserFavouriteKPIRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIInsertUserFavouriteKPI(insertUserFavouriteKPIRequest?: InsertUserFavouriteKPIRequest, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.kPIInsertUserFavouriteKPI(insertUserFavouriteKPIRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {string} [annualGoalID] 
         * @param {string} [kPIDetailID] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIKPIDetailsGraficoA3(projectID?: string, annualGoalID?: string, kPIDetailID?: string, year?: number, options?: RawAxiosRequestConfig): AxiosPromise<KPIDetailsGraficoA3ResponseDtoListControllerResponse> {
            return localVarFp.kPIKPIDetailsGraficoA3(projectID, annualGoalID, kPIDetailID, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {number} [year] 
         * @param {string | null} [xMatrixID] 
         * @param {string | null} [projectID] 
         * @param {string | null} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIKPIDetailsPastYears(kPIDetailID?: string, year?: number, xMatrixID?: string | null, projectID?: string | null, annualGoalID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<KPIDetailsPastYearsResponseDtoListControllerResponse> {
            return localVarFp.kPIKPIDetailsPastYears(kPIDetailID, year, xMatrixID, projectID, annualGoalID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIRemoveKpiChildParentRelations(kPIDetailID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.kPIRemoveKpiChildParentRelations(kPIDetailID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateCommentForKPIRequestDto} [updateCommentForKPIRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIUpdateCommentForKPI(updateCommentForKPIRequestDto?: UpdateCommentForKPIRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.kPIUpdateCommentForKPI(updateCommentForKPIRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<UpdateGlobalKPIBowlingChartImportRequestDto>} [updateGlobalKPIBowlingChartImportRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIUpdateGlobalKPIBowlingChartImport(updateGlobalKPIBowlingChartImportRequestDto?: Array<UpdateGlobalKPIBowlingChartImportRequestDto>, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.kPIUpdateGlobalKPIBowlingChartImport(updateGlobalKPIBowlingChartImportRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateKPIAnnualGoalBowlingChartRequestDto} [updateKPIAnnualGoalBowlingChartRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIUpdateKPIAnnualGoalBowlingChart(updateKPIAnnualGoalBowlingChartRequestDto?: UpdateKPIAnnualGoalBowlingChartRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.kPIUpdateKPIAnnualGoalBowlingChart(updateKPIAnnualGoalBowlingChartRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateKPIDetailRequestDto} [updateKPIDetailRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIUpdateKPIDetail(updateKPIDetailRequestDto?: UpdateKPIDetailRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<KPIDetailsDtoListControllerResponse> {
            return localVarFp.kPIUpdateKPIDetail(updateKPIDetailRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<UpdateSpecificAnnualGoalKPIBowlingRequestDto>} [updateSpecificAnnualGoalKPIBowlingRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIUpdateSpecificAnnualGoalKPIBowlingChartImport(updateSpecificAnnualGoalKPIBowlingRequestDto?: Array<UpdateSpecificAnnualGoalKPIBowlingRequestDto>, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.kPIUpdateSpecificAnnualGoalKPIBowlingChartImport(updateSpecificAnnualGoalKPIBowlingRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<UpdateSpecificKPIBowlingChartImportRequestDto>} [updateSpecificKPIBowlingChartImportRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIUpdateSpecificKPIBowlingChartImport(updateSpecificKPIBowlingChartImportRequestDto?: Array<UpdateSpecificKPIBowlingChartImportRequestDto>, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.kPIUpdateSpecificKPIBowlingChartImport(updateSpecificKPIBowlingChartImportRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KPIApi - object-oriented interface
 * @export
 * @class KPIApi
 * @extends {BaseAPI}
 */
export class KPIApi extends BaseAPI {
    /**
     * 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPICheckDuplicateKPI(name?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPICheckDuplicateKPI(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [iD] 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPICheckKpiTypePlanningStartDate(iD?: string, type?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPICheckKpiTypePlanningStartDate(iD, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [annualGoalKPIRelationShipID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIDeleteAnnualGoalKPIRelationshipBowlingChart(annualGoalKPIRelationShipID?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIDeleteAnnualGoalKPIRelationshipBowlingChart(annualGoalKPIRelationShipID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [kPIDetailID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIDeleteKPI(kPIDetailID?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIDeleteKPI(kPIDetailID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [kPIDetailID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIDeleteUserFavouriteKPI(kPIDetailID?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIDeleteUserFavouriteKPI(kPIDetailID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [kPIDetailID] 
     * @param {number} [year] 
     * @param {string | null} [xMatrixID] 
     * @param {string | null} [projectID] 
     * @param {string | null} [annualGoalID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIDetailsKPI(kPIDetailID?: string, year?: number, xMatrixID?: string | null, projectID?: string | null, annualGoalID?: string | null, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIDetailsKPI(kPIDetailID, year, xMatrixID, projectID, annualGoalID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [kPIDetailID] 
     * @param {string} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetAnnualGoalByKPI(kPIDetailID?: string, xMatrixID?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetAnnualGoalByKPI(kPIDetailID, xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [annualGoalID] 
     * @param {string} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetAnnualGoalKPIBowlingChartListPage(annualGoalID?: string, year?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetAnnualGoalKPIBowlingChartListPage(annualGoalID, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [kPIDetailID] 
     * @param {string | null} [projectAnnualGoalID] 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetCommentForKPI(kPIDetailID?: string, projectAnnualGoalID?: string | null, type?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetCommentForKPI(kPIDetailID, projectAnnualGoalID, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [kPIDetailID] 
     * @param {string} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetGlobalKPIBowlingChartExport(kPIDetailID?: string, year?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetGlobalKPIBowlingChartExport(kPIDetailID, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPIAnnualGoalBowlingChart(id?: string, year?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPIAnnualGoalBowlingChart(id, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {string} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPIBowlingChartListPage(projectID?: string, year?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPIBowlingChartListPage(projectID, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [annualGoalID] 
     * @param {string} [xMatrix] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPIByAnnualGoal(annualGoalID?: string, xMatrix?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPIByAnnualGoal(annualGoalID, xMatrix, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPIById(id?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPIById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPIByProject(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPIByProject(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [parentKPIDetailID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPIChildPicklist(parentKPIDetailID?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPIChildPicklist(parentKPIDetailID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [month] 
     * @param {string} [annualGoalID] 
     * @param {string | null} [xMatrixID] 
     * @param {number} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPIDashboardSintesiByAnnualGoal(month?: number, annualGoalID?: string, xMatrixID?: string | null, year?: number, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPIDashboardSintesiByAnnualGoal(month, annualGoalID, xMatrixID, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [month] 
     * @param {string} [projectID] 
     * @param {string | null} [xMatrixID] 
     * @param {number} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPIDashboardSintesiByProject(month?: number, projectID?: string, xMatrixID?: string | null, year?: number, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPIDashboardSintesiByProject(month, projectID, xMatrixID, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [kPIDetailID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPIDetail(kPIDetailID?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPIDetail(kPIDetailID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPIDetails(id?: string | null, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPIDetails(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [kPIDetailID] 
     * @param {string} [searchType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPIGerarchicoInfo(kPIDetailID?: string, searchType?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPIGerarchicoInfo(kPIDetailID, searchType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [month] 
     * @param {string} [xMatrixID] 
     * @param {string} [annualGoalID] 
     * @param {string} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPIGraphic(month?: number, xMatrixID?: string, annualGoalID?: string, projectID?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPIGraphic(month, xMatrixID, annualGoalID, projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPIList(options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPIList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {string | null} [annualGoalID] 
     * @param {number} [year] 
     * @param {string} [kPIDetailID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPIPlanningByID(projectID?: string | null, annualGoalID?: string | null, year?: number, kPIDetailID?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPIPlanningByID(projectID, annualGoalID, year, kPIDetailID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPISectionA3(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPISectionA3(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPIs(options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPIs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [kPIDetailID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetLongTermGoalByKPI(kPIDetailID?: string | null, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetLongTermGoalByKPI(kPIDetailID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [parentKPIDetailID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetRollupKPIChild(parentKPIDetailID?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetRollupKPIChild(parentKPIDetailID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [annualGoalKPIRelationShipID] 
     * @param {string} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetSpecificAnnualGoalKPIBowlingChartExport(annualGoalKPIRelationShipID?: string, year?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetSpecificAnnualGoalKPIBowlingChartExport(annualGoalKPIRelationShipID, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [kPIProjectRelationShipID] 
     * @param {string} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetSpecificKPIBowlingChartExport(kPIProjectRelationShipID?: string, projectID?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetSpecificKPIBowlingChartExport(kPIProjectRelationShipID, projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [kPIDetailID] 
     * @param {string} [annualGoalID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIInsertKPIAnnualGoal(kPIDetailID?: string, annualGoalID?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIInsertKPIAnnualGoal(kPIDetailID, annualGoalID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [parentKPIDetailID] 
     * @param {string} [childKPIDetailID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIInsertKPIChild(parentKPIDetailID?: string, childKPIDetailID?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIInsertKPIChild(parentKPIDetailID, childKPIDetailID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertKPIDetailRequestDto} [insertKPIDetailRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIInsertKPIDetail(insertKPIDetailRequestDto?: InsertKPIDetailRequestDto, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIInsertKPIDetail(insertKPIDetailRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertUserFavouriteKPIRequest} [insertUserFavouriteKPIRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIInsertUserFavouriteKPI(insertUserFavouriteKPIRequest?: InsertUserFavouriteKPIRequest, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIInsertUserFavouriteKPI(insertUserFavouriteKPIRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {string} [annualGoalID] 
     * @param {string} [kPIDetailID] 
     * @param {number} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIKPIDetailsGraficoA3(projectID?: string, annualGoalID?: string, kPIDetailID?: string, year?: number, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIKPIDetailsGraficoA3(projectID, annualGoalID, kPIDetailID, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [kPIDetailID] 
     * @param {number} [year] 
     * @param {string | null} [xMatrixID] 
     * @param {string | null} [projectID] 
     * @param {string | null} [annualGoalID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIKPIDetailsPastYears(kPIDetailID?: string, year?: number, xMatrixID?: string | null, projectID?: string | null, annualGoalID?: string | null, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIKPIDetailsPastYears(kPIDetailID, year, xMatrixID, projectID, annualGoalID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [kPIDetailID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIRemoveKpiChildParentRelations(kPIDetailID?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIRemoveKpiChildParentRelations(kPIDetailID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateCommentForKPIRequestDto} [updateCommentForKPIRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIUpdateCommentForKPI(updateCommentForKPIRequestDto?: UpdateCommentForKPIRequestDto, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIUpdateCommentForKPI(updateCommentForKPIRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<UpdateGlobalKPIBowlingChartImportRequestDto>} [updateGlobalKPIBowlingChartImportRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIUpdateGlobalKPIBowlingChartImport(updateGlobalKPIBowlingChartImportRequestDto?: Array<UpdateGlobalKPIBowlingChartImportRequestDto>, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIUpdateGlobalKPIBowlingChartImport(updateGlobalKPIBowlingChartImportRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateKPIAnnualGoalBowlingChartRequestDto} [updateKPIAnnualGoalBowlingChartRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIUpdateKPIAnnualGoalBowlingChart(updateKPIAnnualGoalBowlingChartRequestDto?: UpdateKPIAnnualGoalBowlingChartRequestDto, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIUpdateKPIAnnualGoalBowlingChart(updateKPIAnnualGoalBowlingChartRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateKPIDetailRequestDto} [updateKPIDetailRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIUpdateKPIDetail(updateKPIDetailRequestDto?: UpdateKPIDetailRequestDto, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIUpdateKPIDetail(updateKPIDetailRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<UpdateSpecificAnnualGoalKPIBowlingRequestDto>} [updateSpecificAnnualGoalKPIBowlingRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIUpdateSpecificAnnualGoalKPIBowlingChartImport(updateSpecificAnnualGoalKPIBowlingRequestDto?: Array<UpdateSpecificAnnualGoalKPIBowlingRequestDto>, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIUpdateSpecificAnnualGoalKPIBowlingChartImport(updateSpecificAnnualGoalKPIBowlingRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<UpdateSpecificKPIBowlingChartImportRequestDto>} [updateSpecificKPIBowlingChartImportRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIUpdateSpecificKPIBowlingChartImport(updateSpecificKPIBowlingChartImportRequestDto?: Array<UpdateSpecificKPIBowlingChartImportRequestDto>, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIUpdateSpecificKPIBowlingChartImport(updateSpecificKPIBowlingChartImportRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LanguageApi - axios parameter creator
 * @export
 */
export const LanguageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languageGetCalculationTypesByLanguageCode: async (languageCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Language/GetCalculationTypesByLanguageCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languageGetLicenseTypesByLanguageCode: async (languageCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Language/GetLicenseTypesByLanguageCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languageGetLongTermGoalTypeByLanguageCode: async (languageCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Language/GetLongTermGoalTypeByLanguageCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languageGetStatusTypeByLanguageCode: async (languageCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Language/GetStatusTypeByLanguageCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languageGetSummaryFeedTypesByLanguageCode: async (languageCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Language/GetSummaryFeedTypesByLanguageCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LanguageApi - functional programming interface
 * @export
 */
export const LanguageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LanguageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async languageGetCalculationTypesByLanguageCode(languageCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCalculationTypesByLanguageCodeResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.languageGetCalculationTypesByLanguageCode(languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LanguageApi.languageGetCalculationTypesByLanguageCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async languageGetLicenseTypesByLanguageCode(languageCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLicenseTypesByLanguageCodeResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.languageGetLicenseTypesByLanguageCode(languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LanguageApi.languageGetLicenseTypesByLanguageCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async languageGetLongTermGoalTypeByLanguageCode(languageCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLongTermGoalTypeByLanguageCodeResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.languageGetLongTermGoalTypeByLanguageCode(languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LanguageApi.languageGetLongTermGoalTypeByLanguageCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async languageGetStatusTypeByLanguageCode(languageCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStatusTypeByLanguageCodeResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.languageGetStatusTypeByLanguageCode(languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LanguageApi.languageGetStatusTypeByLanguageCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async languageGetSummaryFeedTypesByLanguageCode(languageCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSummaryFeedTypesByLanguageCodeResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.languageGetSummaryFeedTypesByLanguageCode(languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LanguageApi.languageGetSummaryFeedTypesByLanguageCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LanguageApi - factory interface
 * @export
 */
export const LanguageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LanguageApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languageGetCalculationTypesByLanguageCode(languageCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetCalculationTypesByLanguageCodeResponseDtoListControllerResponse> {
            return localVarFp.languageGetCalculationTypesByLanguageCode(languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languageGetLicenseTypesByLanguageCode(languageCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetLicenseTypesByLanguageCodeResponseDtoListControllerResponse> {
            return localVarFp.languageGetLicenseTypesByLanguageCode(languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languageGetLongTermGoalTypeByLanguageCode(languageCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetLongTermGoalTypeByLanguageCodeResponseDtoListControllerResponse> {
            return localVarFp.languageGetLongTermGoalTypeByLanguageCode(languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languageGetStatusTypeByLanguageCode(languageCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetStatusTypeByLanguageCodeResponseDtoListControllerResponse> {
            return localVarFp.languageGetStatusTypeByLanguageCode(languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languageGetSummaryFeedTypesByLanguageCode(languageCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetSummaryFeedTypesByLanguageCodeResponseDtoListControllerResponse> {
            return localVarFp.languageGetSummaryFeedTypesByLanguageCode(languageCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LanguageApi - object-oriented interface
 * @export
 * @class LanguageApi
 * @extends {BaseAPI}
 */
export class LanguageApi extends BaseAPI {
    /**
     * 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public languageGetCalculationTypesByLanguageCode(languageCode?: string, options?: RawAxiosRequestConfig) {
        return LanguageApiFp(this.configuration).languageGetCalculationTypesByLanguageCode(languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public languageGetLicenseTypesByLanguageCode(languageCode?: string, options?: RawAxiosRequestConfig) {
        return LanguageApiFp(this.configuration).languageGetLicenseTypesByLanguageCode(languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public languageGetLongTermGoalTypeByLanguageCode(languageCode?: string, options?: RawAxiosRequestConfig) {
        return LanguageApiFp(this.configuration).languageGetLongTermGoalTypeByLanguageCode(languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public languageGetStatusTypeByLanguageCode(languageCode?: string, options?: RawAxiosRequestConfig) {
        return LanguageApiFp(this.configuration).languageGetStatusTypeByLanguageCode(languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public languageGetSummaryFeedTypesByLanguageCode(languageCode?: string, options?: RawAxiosRequestConfig) {
        return LanguageApiFp(this.configuration).languageGetSummaryFeedTypesByLanguageCode(languageCode, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LongTermGoalApi - axios parameter creator
 * @export
 */
export const LongTermGoalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalCategoryIconlist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/LongTermGoal/CategoryIconlist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [longTermGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalDeleteLongTermGoal: async (longTermGoalID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/LongTermGoal/DeleteLongTermGoal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (longTermGoalID !== undefined) {
                localVarQueryParameter['LongTermGoalID'] = longTermGoalID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [goalCategoryID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalGetGoalCategoryByID: async (goalCategoryID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/LongTermGoal/GetGoalCategoryByID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (goalCategoryID !== undefined) {
                localVarQueryParameter['goalCategoryID'] = goalCategoryID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalGetGoalCategoryList: async (languageCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/LongTermGoal/GetGoalCategoryList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalGetGoalTypePicklist: async (languageCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/LongTermGoal/GetGoalTypePicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [annualGoal] 
         * @param {Array<string>} [longTermGoal] 
         * @param {Array<string>} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalGetKPIPicklist: async (annualGoal?: Array<string>, longTermGoal?: Array<string>, projectID?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/LongTermGoal/GetKPIPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (annualGoal) {
                localVarQueryParameter['AnnualGoal'] = annualGoal;
            }

            if (longTermGoal) {
                localVarQueryParameter['LongTermGoal'] = longTermGoal;
            }

            if (projectID) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [xMatrix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalGetLongTermGoalById: async (id?: string, xMatrix?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/LongTermGoal/GetLongTermGoalById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (xMatrix !== undefined) {
                localVarQueryParameter['xMatrix'] = xMatrix;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [xMatrixIDReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalGetLongTermGoals: async (xMatrixIDReq?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/LongTermGoal/GetLongTermGoals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xMatrixIDReq !== undefined) {
                localVarQueryParameter['XMatrixIDReq'] = xMatrixIDReq;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertGoalCategoryRequestDto} [insertGoalCategoryRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalInsertGoalCategory: async (insertGoalCategoryRequestDto?: InsertGoalCategoryRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/LongTermGoal/InsertGoalCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertGoalCategoryRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertLongTermGoalRequestDto} [insertLongTermGoalRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalInsertLongTermGoal: async (insertLongTermGoalRequestDto?: InsertLongTermGoalRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/LongTermGoal/InsertLongTermGoal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertLongTermGoalRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertLongTermGoalXRequestDto} [insertLongTermGoalXRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalInsertLongTermGoalXMatrix: async (insertLongTermGoalXRequestDto?: InsertLongTermGoalXRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/LongTermGoal/InsertLongTermGoalXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertLongTermGoalXRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateGoalCategoryRequestDto} [updateGoalCategoryRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalUpdateGoalCategory: async (updateGoalCategoryRequestDto?: UpdateGoalCategoryRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/LongTermGoal/UpdateGoalCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGoalCategoryRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateLongTermGoalRequestDto} [updateLongTermGoalRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalUpdateLongTermGoal: async (updateLongTermGoalRequestDto?: UpdateLongTermGoalRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/LongTermGoal/UpdateLongTermGoal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLongTermGoalRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateLongTermGoalXRequestDto} [updateLongTermGoalXRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalUpdateLongTermGoalXMatrix: async (updateLongTermGoalXRequestDto?: UpdateLongTermGoalXRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/LongTermGoal/UpdateLongTermGoalXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLongTermGoalXRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LongTermGoalApi - functional programming interface
 * @export
 */
export const LongTermGoalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LongTermGoalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async longTermGoalCategoryIconlist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GoalCategoryIconlistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.longTermGoalCategoryIconlist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LongTermGoalApi.longTermGoalCategoryIconlist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [longTermGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async longTermGoalDeleteLongTermGoal(longTermGoalID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.longTermGoalDeleteLongTermGoal(longTermGoalID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LongTermGoalApi.longTermGoalDeleteLongTermGoal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [goalCategoryID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async longTermGoalGetGoalCategoryByID(goalCategoryID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGoalCategoryByIDResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.longTermGoalGetGoalCategoryByID(goalCategoryID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LongTermGoalApi.longTermGoalGetGoalCategoryByID']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async longTermGoalGetGoalCategoryList(languageCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGoalCategoryResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.longTermGoalGetGoalCategoryList(languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LongTermGoalApi.longTermGoalGetGoalCategoryList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async longTermGoalGetGoalTypePicklist(languageCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGoalTypePicklistDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.longTermGoalGetGoalTypePicklist(languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LongTermGoalApi.longTermGoalGetGoalTypePicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} [annualGoal] 
         * @param {Array<string>} [longTermGoal] 
         * @param {Array<string>} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async longTermGoalGetKPIPicklist(annualGoal?: Array<string>, longTermGoal?: Array<string>, projectID?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KPIPicklistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.longTermGoalGetKPIPicklist(annualGoal, longTermGoal, projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LongTermGoalApi.longTermGoalGetKPIPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [xMatrix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async longTermGoalGetLongTermGoalById(id?: string, xMatrix?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LongTermObjectiveByIdResponseControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.longTermGoalGetLongTermGoalById(id, xMatrix, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LongTermGoalApi.longTermGoalGetLongTermGoalById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [xMatrixIDReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async longTermGoalGetLongTermGoals(xMatrixIDReq?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LongTermObjectiveDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.longTermGoalGetLongTermGoals(xMatrixIDReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LongTermGoalApi.longTermGoalGetLongTermGoals']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertGoalCategoryRequestDto} [insertGoalCategoryRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async longTermGoalInsertGoalCategory(insertGoalCategoryRequestDto?: InsertGoalCategoryRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.longTermGoalInsertGoalCategory(insertGoalCategoryRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LongTermGoalApi.longTermGoalInsertGoalCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertLongTermGoalRequestDto} [insertLongTermGoalRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async longTermGoalInsertLongTermGoal(insertLongTermGoalRequestDto?: InsertLongTermGoalRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsertLongTermGoalResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.longTermGoalInsertLongTermGoal(insertLongTermGoalRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LongTermGoalApi.longTermGoalInsertLongTermGoal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertLongTermGoalXRequestDto} [insertLongTermGoalXRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async longTermGoalInsertLongTermGoalXMatrix(insertLongTermGoalXRequestDto?: InsertLongTermGoalXRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.longTermGoalInsertLongTermGoalXMatrix(insertLongTermGoalXRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LongTermGoalApi.longTermGoalInsertLongTermGoalXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateGoalCategoryRequestDto} [updateGoalCategoryRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async longTermGoalUpdateGoalCategory(updateGoalCategoryRequestDto?: UpdateGoalCategoryRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.longTermGoalUpdateGoalCategory(updateGoalCategoryRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LongTermGoalApi.longTermGoalUpdateGoalCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateLongTermGoalRequestDto} [updateLongTermGoalRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async longTermGoalUpdateLongTermGoal(updateLongTermGoalRequestDto?: UpdateLongTermGoalRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateLongTermGoalResponseControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.longTermGoalUpdateLongTermGoal(updateLongTermGoalRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LongTermGoalApi.longTermGoalUpdateLongTermGoal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateLongTermGoalXRequestDto} [updateLongTermGoalXRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async longTermGoalUpdateLongTermGoalXMatrix(updateLongTermGoalXRequestDto?: UpdateLongTermGoalXRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.longTermGoalUpdateLongTermGoalXMatrix(updateLongTermGoalXRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LongTermGoalApi.longTermGoalUpdateLongTermGoalXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LongTermGoalApi - factory interface
 * @export
 */
export const LongTermGoalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LongTermGoalApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalCategoryIconlist(options?: RawAxiosRequestConfig): AxiosPromise<GoalCategoryIconlistResponseDtoListControllerResponse> {
            return localVarFp.longTermGoalCategoryIconlist(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [longTermGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalDeleteLongTermGoal(longTermGoalID?: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.longTermGoalDeleteLongTermGoal(longTermGoalID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [goalCategoryID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalGetGoalCategoryByID(goalCategoryID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetGoalCategoryByIDResponseDtoControllerResponse> {
            return localVarFp.longTermGoalGetGoalCategoryByID(goalCategoryID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalGetGoalCategoryList(languageCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetGoalCategoryResponseDtoListControllerResponse> {
            return localVarFp.longTermGoalGetGoalCategoryList(languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalGetGoalTypePicklist(languageCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetGoalTypePicklistDtoListControllerResponse> {
            return localVarFp.longTermGoalGetGoalTypePicklist(languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [annualGoal] 
         * @param {Array<string>} [longTermGoal] 
         * @param {Array<string>} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalGetKPIPicklist(annualGoal?: Array<string>, longTermGoal?: Array<string>, projectID?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<KPIPicklistResponseDtoListControllerResponse> {
            return localVarFp.longTermGoalGetKPIPicklist(annualGoal, longTermGoal, projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [xMatrix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalGetLongTermGoalById(id?: string, xMatrix?: string, options?: RawAxiosRequestConfig): AxiosPromise<LongTermObjectiveByIdResponseControllerResponse> {
            return localVarFp.longTermGoalGetLongTermGoalById(id, xMatrix, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [xMatrixIDReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalGetLongTermGoals(xMatrixIDReq?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<LongTermObjectiveDtoListControllerResponse> {
            return localVarFp.longTermGoalGetLongTermGoals(xMatrixIDReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertGoalCategoryRequestDto} [insertGoalCategoryRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalInsertGoalCategory(insertGoalCategoryRequestDto?: InsertGoalCategoryRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.longTermGoalInsertGoalCategory(insertGoalCategoryRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertLongTermGoalRequestDto} [insertLongTermGoalRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalInsertLongTermGoal(insertLongTermGoalRequestDto?: InsertLongTermGoalRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<InsertLongTermGoalResponseDtoControllerResponse> {
            return localVarFp.longTermGoalInsertLongTermGoal(insertLongTermGoalRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertLongTermGoalXRequestDto} [insertLongTermGoalXRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalInsertLongTermGoalXMatrix(insertLongTermGoalXRequestDto?: InsertLongTermGoalXRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.longTermGoalInsertLongTermGoalXMatrix(insertLongTermGoalXRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateGoalCategoryRequestDto} [updateGoalCategoryRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalUpdateGoalCategory(updateGoalCategoryRequestDto?: UpdateGoalCategoryRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.longTermGoalUpdateGoalCategory(updateGoalCategoryRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateLongTermGoalRequestDto} [updateLongTermGoalRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalUpdateLongTermGoal(updateLongTermGoalRequestDto?: UpdateLongTermGoalRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<UpdateLongTermGoalResponseControllerResponse> {
            return localVarFp.longTermGoalUpdateLongTermGoal(updateLongTermGoalRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateLongTermGoalXRequestDto} [updateLongTermGoalXRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalUpdateLongTermGoalXMatrix(updateLongTermGoalXRequestDto?: UpdateLongTermGoalXRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.longTermGoalUpdateLongTermGoalXMatrix(updateLongTermGoalXRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LongTermGoalApi - object-oriented interface
 * @export
 * @class LongTermGoalApi
 * @extends {BaseAPI}
 */
export class LongTermGoalApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LongTermGoalApi
     */
    public longTermGoalCategoryIconlist(options?: RawAxiosRequestConfig) {
        return LongTermGoalApiFp(this.configuration).longTermGoalCategoryIconlist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [longTermGoalID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LongTermGoalApi
     */
    public longTermGoalDeleteLongTermGoal(longTermGoalID?: string, options?: RawAxiosRequestConfig) {
        return LongTermGoalApiFp(this.configuration).longTermGoalDeleteLongTermGoal(longTermGoalID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [goalCategoryID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LongTermGoalApi
     */
    public longTermGoalGetGoalCategoryByID(goalCategoryID?: string, options?: RawAxiosRequestConfig) {
        return LongTermGoalApiFp(this.configuration).longTermGoalGetGoalCategoryByID(goalCategoryID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LongTermGoalApi
     */
    public longTermGoalGetGoalCategoryList(languageCode?: string, options?: RawAxiosRequestConfig) {
        return LongTermGoalApiFp(this.configuration).longTermGoalGetGoalCategoryList(languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LongTermGoalApi
     */
    public longTermGoalGetGoalTypePicklist(languageCode?: string, options?: RawAxiosRequestConfig) {
        return LongTermGoalApiFp(this.configuration).longTermGoalGetGoalTypePicklist(languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [annualGoal] 
     * @param {Array<string>} [longTermGoal] 
     * @param {Array<string>} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LongTermGoalApi
     */
    public longTermGoalGetKPIPicklist(annualGoal?: Array<string>, longTermGoal?: Array<string>, projectID?: Array<string>, options?: RawAxiosRequestConfig) {
        return LongTermGoalApiFp(this.configuration).longTermGoalGetKPIPicklist(annualGoal, longTermGoal, projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [xMatrix] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LongTermGoalApi
     */
    public longTermGoalGetLongTermGoalById(id?: string, xMatrix?: string, options?: RawAxiosRequestConfig) {
        return LongTermGoalApiFp(this.configuration).longTermGoalGetLongTermGoalById(id, xMatrix, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [xMatrixIDReq] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LongTermGoalApi
     */
    public longTermGoalGetLongTermGoals(xMatrixIDReq?: string | null, options?: RawAxiosRequestConfig) {
        return LongTermGoalApiFp(this.configuration).longTermGoalGetLongTermGoals(xMatrixIDReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertGoalCategoryRequestDto} [insertGoalCategoryRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LongTermGoalApi
     */
    public longTermGoalInsertGoalCategory(insertGoalCategoryRequestDto?: InsertGoalCategoryRequestDto, options?: RawAxiosRequestConfig) {
        return LongTermGoalApiFp(this.configuration).longTermGoalInsertGoalCategory(insertGoalCategoryRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertLongTermGoalRequestDto} [insertLongTermGoalRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LongTermGoalApi
     */
    public longTermGoalInsertLongTermGoal(insertLongTermGoalRequestDto?: InsertLongTermGoalRequestDto, options?: RawAxiosRequestConfig) {
        return LongTermGoalApiFp(this.configuration).longTermGoalInsertLongTermGoal(insertLongTermGoalRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertLongTermGoalXRequestDto} [insertLongTermGoalXRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LongTermGoalApi
     */
    public longTermGoalInsertLongTermGoalXMatrix(insertLongTermGoalXRequestDto?: InsertLongTermGoalXRequestDto, options?: RawAxiosRequestConfig) {
        return LongTermGoalApiFp(this.configuration).longTermGoalInsertLongTermGoalXMatrix(insertLongTermGoalXRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateGoalCategoryRequestDto} [updateGoalCategoryRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LongTermGoalApi
     */
    public longTermGoalUpdateGoalCategory(updateGoalCategoryRequestDto?: UpdateGoalCategoryRequestDto, options?: RawAxiosRequestConfig) {
        return LongTermGoalApiFp(this.configuration).longTermGoalUpdateGoalCategory(updateGoalCategoryRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateLongTermGoalRequestDto} [updateLongTermGoalRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LongTermGoalApi
     */
    public longTermGoalUpdateLongTermGoal(updateLongTermGoalRequestDto?: UpdateLongTermGoalRequestDto, options?: RawAxiosRequestConfig) {
        return LongTermGoalApiFp(this.configuration).longTermGoalUpdateLongTermGoal(updateLongTermGoalRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateLongTermGoalXRequestDto} [updateLongTermGoalXRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LongTermGoalApi
     */
    public longTermGoalUpdateLongTermGoalXMatrix(updateLongTermGoalXRequestDto?: UpdateLongTermGoalXRequestDto, options?: RawAxiosRequestConfig) {
        return LongTermGoalApiFp(this.configuration).longTermGoalUpdateLongTermGoalXMatrix(updateLongTermGoalXRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PersonalDashboardApi - axios parameter creator
 * @export
 */
export const PersonalDashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetActivitySummaryperStatus: async (languageCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PersonalDashboard/GetActivitySummaryperStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetActivitySummaryperTime: async (languageCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PersonalDashboard/GetActivitySummaryperTime`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetDeliverableSummaryperStatus: async (languageCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PersonalDashboard/GetDeliverableSummaryperStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetDeliverableSummaryperTime: async (languageCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PersonalDashboard/GetDeliverableSummaryperTime`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetFeedSummary: async (userID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PersonalDashboard/GetFeedSummary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userID !== undefined) {
                localVarQueryParameter['UserID'] = userID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetFilterbyXMatrixRequest} [getFilterbyXMatrixRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetFilterbyXMatrix: async (getFilterbyXMatrixRequest?: GetFilterbyXMatrixRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PersonalDashboard/GetFilterbyXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getFilterbyXMatrixRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetKPISummary: async (xmatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PersonalDashboard/GetKPISummary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xmatrixID !== undefined) {
                localVarQueryParameter['xmatrixID'] = xmatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetPersonalDashboardPhases: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PersonalDashboard/GetPersonalDashboardPhases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetResourceWorkloadSummaryRequest} [getResourceWorkloadSummaryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetResourceWorkloadSummary: async (getResourceWorkloadSummaryRequest?: GetResourceWorkloadSummaryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PersonalDashboard/GetResourceWorkloadSummary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getResourceWorkloadSummaryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetTeamLeaderPicklistPersonalDashboard: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PersonalDashboard/GetTeamLeaderPicklistPersonalDashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetUserSelectPersonalDashboard: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PersonalDashboard/GetUserSelectPersonalDashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetXMatrixSummary: async (xmatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PersonalDashboard/GetXMatrixSummary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xmatrixID !== undefined) {
                localVarQueryParameter['xmatrixID'] = xmatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PersonalDashboardApi - functional programming interface
 * @export
 */
export const PersonalDashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PersonalDashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personalDashboardGetActivitySummaryperStatus(languageCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetActivitySummaryperStatusResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personalDashboardGetActivitySummaryperStatus(languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalDashboardApi.personalDashboardGetActivitySummaryperStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personalDashboardGetActivitySummaryperTime(languageCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetActivitySummaryperTimeResponseListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personalDashboardGetActivitySummaryperTime(languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalDashboardApi.personalDashboardGetActivitySummaryperTime']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personalDashboardGetDeliverableSummaryperStatus(languageCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDeliverableSummaryperStatusResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personalDashboardGetDeliverableSummaryperStatus(languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalDashboardApi.personalDashboardGetDeliverableSummaryperStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personalDashboardGetDeliverableSummaryperTime(languageCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDeliverableSummaryperTimeResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personalDashboardGetDeliverableSummaryperTime(languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalDashboardApi.personalDashboardGetDeliverableSummaryperTime']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personalDashboardGetFeedSummary(userID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFeedSummaryResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personalDashboardGetFeedSummary(userID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalDashboardApi.personalDashboardGetFeedSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetFilterbyXMatrixRequest} [getFilterbyXMatrixRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personalDashboardGetFilterbyXMatrix(getFilterbyXMatrixRequest?: GetFilterbyXMatrixRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilterbyXMatrixResponseListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personalDashboardGetFilterbyXMatrix(getFilterbyXMatrixRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalDashboardApi.personalDashboardGetFilterbyXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personalDashboardGetKPISummary(xmatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetKPISummaryResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personalDashboardGetKPISummary(xmatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalDashboardApi.personalDashboardGetKPISummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personalDashboardGetPersonalDashboardPhases(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliverableDtoIListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personalDashboardGetPersonalDashboardPhases(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalDashboardApi.personalDashboardGetPersonalDashboardPhases']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetResourceWorkloadSummaryRequest} [getResourceWorkloadSummaryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personalDashboardGetResourceWorkloadSummary(getResourceWorkloadSummaryRequest?: GetResourceWorkloadSummaryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetResourceWorkloadSummaryResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personalDashboardGetResourceWorkloadSummary(getResourceWorkloadSummaryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalDashboardApi.personalDashboardGetResourceWorkloadSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personalDashboardGetTeamLeaderPicklistPersonalDashboard(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserSelectResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personalDashboardGetTeamLeaderPicklistPersonalDashboard(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalDashboardApi.personalDashboardGetTeamLeaderPicklistPersonalDashboard']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personalDashboardGetUserSelectPersonalDashboard(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserSelectResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personalDashboardGetUserSelectPersonalDashboard(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalDashboardApi.personalDashboardGetUserSelectPersonalDashboard']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personalDashboardGetXMatrixSummary(xmatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetXMatrixSummaryResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personalDashboardGetXMatrixSummary(xmatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalDashboardApi.personalDashboardGetXMatrixSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PersonalDashboardApi - factory interface
 * @export
 */
export const PersonalDashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PersonalDashboardApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetActivitySummaryperStatus(languageCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetActivitySummaryperStatusResponseDtoListControllerResponse> {
            return localVarFp.personalDashboardGetActivitySummaryperStatus(languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetActivitySummaryperTime(languageCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetActivitySummaryperTimeResponseListControllerResponse> {
            return localVarFp.personalDashboardGetActivitySummaryperTime(languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetDeliverableSummaryperStatus(languageCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetDeliverableSummaryperStatusResponseDtoListControllerResponse> {
            return localVarFp.personalDashboardGetDeliverableSummaryperStatus(languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetDeliverableSummaryperTime(languageCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetDeliverableSummaryperTimeResponseDtoListControllerResponse> {
            return localVarFp.personalDashboardGetDeliverableSummaryperTime(languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetFeedSummary(userID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<GetFeedSummaryResponseDtoListControllerResponse> {
            return localVarFp.personalDashboardGetFeedSummary(userID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetFilterbyXMatrixRequest} [getFilterbyXMatrixRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetFilterbyXMatrix(getFilterbyXMatrixRequest?: GetFilterbyXMatrixRequest, options?: RawAxiosRequestConfig): AxiosPromise<FilterbyXMatrixResponseListControllerResponse> {
            return localVarFp.personalDashboardGetFilterbyXMatrix(getFilterbyXMatrixRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetKPISummary(xmatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetKPISummaryResponseDtoListControllerResponse> {
            return localVarFp.personalDashboardGetKPISummary(xmatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetPersonalDashboardPhases(options?: RawAxiosRequestConfig): AxiosPromise<DeliverableDtoIListControllerResponse> {
            return localVarFp.personalDashboardGetPersonalDashboardPhases(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetResourceWorkloadSummaryRequest} [getResourceWorkloadSummaryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetResourceWorkloadSummary(getResourceWorkloadSummaryRequest?: GetResourceWorkloadSummaryRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetResourceWorkloadSummaryResponseDtoListControllerResponse> {
            return localVarFp.personalDashboardGetResourceWorkloadSummary(getResourceWorkloadSummaryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetTeamLeaderPicklistPersonalDashboard(options?: RawAxiosRequestConfig): AxiosPromise<GetUserSelectResponseDtoListControllerResponse> {
            return localVarFp.personalDashboardGetTeamLeaderPicklistPersonalDashboard(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetUserSelectPersonalDashboard(options?: RawAxiosRequestConfig): AxiosPromise<GetUserSelectResponseDtoListControllerResponse> {
            return localVarFp.personalDashboardGetUserSelectPersonalDashboard(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetXMatrixSummary(xmatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetXMatrixSummaryResponseDtoControllerResponse> {
            return localVarFp.personalDashboardGetXMatrixSummary(xmatrixID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PersonalDashboardApi - object-oriented interface
 * @export
 * @class PersonalDashboardApi
 * @extends {BaseAPI}
 */
export class PersonalDashboardApi extends BaseAPI {
    /**
     * 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalDashboardApi
     */
    public personalDashboardGetActivitySummaryperStatus(languageCode?: string, options?: RawAxiosRequestConfig) {
        return PersonalDashboardApiFp(this.configuration).personalDashboardGetActivitySummaryperStatus(languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalDashboardApi
     */
    public personalDashboardGetActivitySummaryperTime(languageCode?: string, options?: RawAxiosRequestConfig) {
        return PersonalDashboardApiFp(this.configuration).personalDashboardGetActivitySummaryperTime(languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalDashboardApi
     */
    public personalDashboardGetDeliverableSummaryperStatus(languageCode?: string, options?: RawAxiosRequestConfig) {
        return PersonalDashboardApiFp(this.configuration).personalDashboardGetDeliverableSummaryperStatus(languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalDashboardApi
     */
    public personalDashboardGetDeliverableSummaryperTime(languageCode?: string, options?: RawAxiosRequestConfig) {
        return PersonalDashboardApiFp(this.configuration).personalDashboardGetDeliverableSummaryperTime(languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [userID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalDashboardApi
     */
    public personalDashboardGetFeedSummary(userID?: string | null, options?: RawAxiosRequestConfig) {
        return PersonalDashboardApiFp(this.configuration).personalDashboardGetFeedSummary(userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetFilterbyXMatrixRequest} [getFilterbyXMatrixRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalDashboardApi
     */
    public personalDashboardGetFilterbyXMatrix(getFilterbyXMatrixRequest?: GetFilterbyXMatrixRequest, options?: RawAxiosRequestConfig) {
        return PersonalDashboardApiFp(this.configuration).personalDashboardGetFilterbyXMatrix(getFilterbyXMatrixRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xmatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalDashboardApi
     */
    public personalDashboardGetKPISummary(xmatrixID?: string, options?: RawAxiosRequestConfig) {
        return PersonalDashboardApiFp(this.configuration).personalDashboardGetKPISummary(xmatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalDashboardApi
     */
    public personalDashboardGetPersonalDashboardPhases(options?: RawAxiosRequestConfig) {
        return PersonalDashboardApiFp(this.configuration).personalDashboardGetPersonalDashboardPhases(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetResourceWorkloadSummaryRequest} [getResourceWorkloadSummaryRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalDashboardApi
     */
    public personalDashboardGetResourceWorkloadSummary(getResourceWorkloadSummaryRequest?: GetResourceWorkloadSummaryRequest, options?: RawAxiosRequestConfig) {
        return PersonalDashboardApiFp(this.configuration).personalDashboardGetResourceWorkloadSummary(getResourceWorkloadSummaryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalDashboardApi
     */
    public personalDashboardGetTeamLeaderPicklistPersonalDashboard(options?: RawAxiosRequestConfig) {
        return PersonalDashboardApiFp(this.configuration).personalDashboardGetTeamLeaderPicklistPersonalDashboard(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalDashboardApi
     */
    public personalDashboardGetUserSelectPersonalDashboard(options?: RawAxiosRequestConfig) {
        return PersonalDashboardApiFp(this.configuration).personalDashboardGetUserSelectPersonalDashboard(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xmatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalDashboardApi
     */
    public personalDashboardGetXMatrixSummary(xmatrixID?: string, options?: RawAxiosRequestConfig) {
        return PersonalDashboardApiFp(this.configuration).personalDashboardGetXMatrixSummary(xmatrixID, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PingApi - axios parameter creator
 * @export
 */
export const PingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingPing: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PingApi - functional programming interface
 * @export
 */
export const PingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pingPing(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pingPing(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PingApi.pingPing']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PingApi - factory interface
 * @export
 */
export const PingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PingApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingPing(options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.pingPing(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PingApi - object-oriented interface
 * @export
 * @class PingApi
 * @extends {BaseAPI}
 */
export class PingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PingApi
     */
    public pingPing(options?: RawAxiosRequestConfig) {
        return PingApiFp(this.configuration).pingPing(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [projectCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCheckDuplicateProject: async (projectCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/CheckDuplicateProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectCode !== undefined) {
                localVarQueryParameter['projectCode'] = projectCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateProjectRequestDto} [createProjectRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCreateProject: async (createProjectRequestDto?: CreateProjectRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/CreateProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProjectRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [kPIProjectRelationShipID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeleteKPIRelationshipBowlingChart: async (kPIProjectRelationShipID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/DeleteKPIRelationshipBowlingChart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (kPIProjectRelationShipID !== undefined) {
                localVarQueryParameter['KPIProjectRelationShipID'] = kPIProjectRelationShipID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [precautionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeletePrecaution: async (precautionID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/DeletePrecaution`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (precautionID !== undefined) {
                localVarQueryParameter['PrecautionID'] = precautionID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeleteProject: async (projectID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/DeleteProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectCostDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeleteProjectCost: async (projectCostDetailID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/DeleteProjectCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectCostDetailID !== undefined) {
                localVarQueryParameter['ProjectCostDetailID'] = projectCostDetailID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectRepositoryID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeleteProjectRepository: async (projectRepositoryID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/DeleteProjectRepository`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectRepositoryID !== undefined) {
                localVarQueryParameter['ProjectRepositoryID'] = projectRepositoryID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectRoutineID] 
         * @param {string} [outlookEventId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeleteProjectRoutine: async (projectRoutineID?: string, outlookEventId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/DeleteProjectRoutine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectRoutineID !== undefined) {
                localVarQueryParameter['ProjectRoutineID'] = projectRoutineID;
            }

            if (outlookEventId !== undefined) {
                localVarQueryParameter['OutlookEventId'] = outlookEventId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [riskID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeleteRisk: async (riskID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/DeleteRisk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (riskID !== undefined) {
                localVarQueryParameter['RiskID'] = riskID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetDeliverableProjectPicklist: async (projectID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetDeliverableProjectPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {string} [year] 
         * @param {string} [isRollupKPI] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetGlobalKPIBowlingChart: async (kPIDetailID?: string, year?: string, isRollupKPI?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetGlobalKPIBowlingChart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (kPIDetailID !== undefined) {
                localVarQueryParameter['KPIDetailID'] = kPIDetailID;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }

            if (isRollupKPI !== undefined) {
                localVarQueryParameter['IsRollupKPI'] = isRollupKPI;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetGoalByProject: async (projectID?: string | null, xmatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetGoalByProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }

            if (xmatrixID !== undefined) {
                localVarQueryParameter['xmatrixID'] = xmatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [kpiDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetInsertKPI: async (kpiDetailID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetInsertKPI`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (kpiDetailID !== undefined) {
                localVarQueryParameter['kpiDetailID'] = kpiDetailID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [kPIProjectRelationShipID] 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetKPIBowlingChart: async (kPIProjectRelationShipID?: string, projectID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetKPIBowlingChart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (kPIProjectRelationShipID !== undefined) {
                localVarQueryParameter['KPIProjectRelationShipID'] = kPIProjectRelationShipID;
            }

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetKPIProjectPicklist: async (projectID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetKPIProjectPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [macroProjectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetMacroProject: async (macroProjectID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetMacroProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (macroProjectID !== undefined) {
                localVarQueryParameter['macroProjectID'] = macroProjectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetMonthlyAlertByUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetMonthlyAlertByUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProject: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectChilds: async (projectID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectChilds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectCostChart: async (projectID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectCostChart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectCostDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectCostDetail: async (projectCostDetailID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectCostDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectCostDetailID !== undefined) {
                localVarQueryParameter['ProjectCostDetailID'] = projectCostDetailID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectCreationPicklist: async (projectId?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectCreationPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectId] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectDeliverable: async (projectId?: string, languageCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectDeliverable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {string | null} [deliverableID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectDeliverableParentPicklist: async (projectID?: string, deliverableID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectDeliverableParentPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }

            if (deliverableID !== undefined) {
                localVarQueryParameter['DeliverableID'] = deliverableID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectDeliverablePicklist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectDeliverablePicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [hasProjectRelation] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectListPage: async (hasProjectRelation?: boolean, xMatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectListPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (hasProjectRelation !== undefined) {
                localVarQueryParameter['hasProjectRelation'] = hasProjectRelation;
            }

            if (xMatrixID !== undefined) {
                localVarQueryParameter['xMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectMembers: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectMembers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectMembersList: async (projectID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectMembersList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectPicklist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectRepository: async (projectID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectRepository`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectRoutine: async (projectID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectRoutine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetRiskPrecaution: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetRiskPrecaution`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [secondLevelXmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetSecondLevelMacroProjectsRelationship: async (secondLevelXmatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetSecondLevelMacroProjectsRelationship`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (secondLevelXmatrixID !== undefined) {
                localVarQueryParameter['SecondLevelXmatrixID'] = secondLevelXmatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userID] 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetTeamLeaderProject: async (userID?: string, xmatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetTeamLeaderProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userID !== undefined) {
                localVarQueryParameter['UserID'] = userID;
            }

            if (xmatrixID !== undefined) {
                localVarQueryParameter['xmatrixID'] = xmatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetTotalCostProjectChart: async (projectID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetTotalCostProjectChart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetTotalTeamCostProjectChart: async (projectID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetTotalTeamCostProjectChart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetUserAvailability: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetUserAvailability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetUserProjectCostAccess: async (projectID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetUserProjectCostAccess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertKPIProjectRequestDto} [insertKPIProjectRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertKPIProject: async (insertKPIProjectRequestDto?: InsertKPIProjectRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/InsertKPIProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertKPIProjectRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertMacroProjectRequest} [insertMacroProjectRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertMacroProject: async (insertMacroProjectRequest?: InsertMacroProjectRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/InsertMacroProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertMacroProjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertProjectCostDetailRequestDto} [insertProjectCostDetailRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertProjectCostDetail: async (insertProjectCostDetailRequestDto?: InsertProjectCostDetailRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/InsertProjectCostDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertProjectCostDetailRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertProjectDeliverableRequestDto} [insertProjectDeliverableRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertProjectDeliverable: async (insertProjectDeliverableRequestDto?: InsertProjectDeliverableRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/InsertProjectDeliverable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertProjectDeliverableRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertProjectMemberRequestDto} [insertProjectMemberRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertProjectMember: async (insertProjectMemberRequestDto?: InsertProjectMemberRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/InsertProjectMember`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertProjectMemberRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertProjectRepositoryRequestDto} [insertProjectRepositoryRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertProjectRepository: async (insertProjectRepositoryRequestDto?: InsertProjectRepositoryRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/InsertProjectRepository`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertProjectRepositoryRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertProjectRoutineRequestDto} [insertProjectRoutineRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertProjectRoutine: async (insertProjectRoutineRequestDto?: InsertProjectRoutineRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/InsertProjectRoutine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertProjectRoutineRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertProjectRoutineMeetingRequestDto} [insertProjectRoutineMeetingRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertProjectRoutineMeeting: async (insertProjectRoutineMeetingRequestDto?: InsertProjectRoutineMeetingRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/InsertProjectRoutineMeeting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertProjectRoutineMeetingRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertRiskRequestDto} [insertRiskRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertRisk: async (insertRiskRequestDto?: InsertRiskRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/InsertRisk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertRiskRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertSecondLevelMacroProjectsRelationshipRequestDto} [insertSecondLevelMacroProjectsRelationshipRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertSecondLevelMacroProjectsRelationship: async (insertSecondLevelMacroProjectsRelationshipRequestDto?: InsertSecondLevelMacroProjectsRelationshipRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/InsertSecondLevelMacroProjectsRelationship`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertSecondLevelMacroProjectsRelationshipRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SwapTeamMembersActivitiesRequest} [swapTeamMembersActivitiesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectSwapTeamMembersActivities: async (swapTeamMembersActivitiesRequest?: SwapTeamMembersActivitiesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/SwapTeamMembersActivities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(swapTeamMembersActivitiesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateGlobalKPIBowlingChartRequestDto} [updateGlobalKPIBowlingChartRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateGlobalKPIBowlingChart: async (updateGlobalKPIBowlingChartRequestDto?: UpdateGlobalKPIBowlingChartRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/UpdateGlobalKPIBowlingChart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGlobalKPIBowlingChartRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateKPIBowlingChartRequestDto} [updateKPIBowlingChartRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateKPIBowlingChart: async (updateKPIBowlingChartRequestDto?: UpdateKPIBowlingChartRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/UpdateKPIBowlingChart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateKPIBowlingChartRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateMacroProjectRequest} [updateMacroProjectRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateMacroProject: async (updateMacroProjectRequest?: UpdateMacroProjectRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/UpdateMacroProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMacroProjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateMonthlyAlert: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/UpdateMonthlyAlert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProjectRequestDto} [updateProjectRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProject: async (updateProjectRequestDto?: UpdateProjectRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/UpdateProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProjectCostRequest} [updateProjectCostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectCost: async (updateProjectCostRequest?: UpdateProjectCostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/UpdateProjectCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectCostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProjectCostDetailRequestDto} [updateProjectCostDetailRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectCostDetail: async (updateProjectCostDetailRequestDto?: UpdateProjectCostDetailRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/UpdateProjectCostDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectCostDetailRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProjectDeliverableRequestDto} [updateProjectDeliverableRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectDeliverable: async (updateProjectDeliverableRequestDto?: UpdateProjectDeliverableRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/UpdateProjectDeliverable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectDeliverableRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProjectFieldsRequestDto} [updateProjectFieldsRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectFields: async (updateProjectFieldsRequestDto?: UpdateProjectFieldsRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/UpdateProjectFields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectFieldsRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProjectRoutineRequestDto} [updateProjectRoutineRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectRoutine: async (updateProjectRoutineRequestDto?: UpdateProjectRoutineRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/UpdateProjectRoutine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectRoutineRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProjectTotalCostRequest} [updateProjectTotalCostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectTotalCost: async (updateProjectTotalCostRequest?: UpdateProjectTotalCostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/UpdateProjectTotalCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectTotalCostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateRiskRequestDto} [updateRiskRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateRisk: async (updateRiskRequestDto?: UpdateRiskRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/UpdateRisk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRiskRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateSecondLevelMacroProjectsRelationshipRequestDto} [updateSecondLevelMacroProjectsRelationshipRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateSecondLevelMacroProjectsRelationship: async (updateSecondLevelMacroProjectsRelationshipRequestDto?: UpdateSecondLevelMacroProjectsRelationshipRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/UpdateSecondLevelMacroProjectsRelationship`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSecondLevelMacroProjectsRelationshipRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateTeamMembersRequest} [updateTeamMembersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateTeamMembers: async (updateTeamMembersRequest?: UpdateTeamMembersRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/UpdateTeamMembers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTeamMembersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [projectCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectCheckDuplicateProject(projectCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectCheckDuplicateProject(projectCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectCheckDuplicateProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateProjectRequestDto} [createProjectRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectCreateProject(createProjectRequestDto?: CreateProjectRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectCreateProject(createProjectRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectCreateProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [kPIProjectRelationShipID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDeleteKPIRelationshipBowlingChart(kPIProjectRelationShipID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDeleteKPIRelationshipBowlingChart(kPIProjectRelationShipID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectDeleteKPIRelationshipBowlingChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [precautionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDeletePrecaution(precautionID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDeletePrecaution(precautionID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectDeletePrecaution']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDeleteProject(projectID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDeleteProject(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectDeleteProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectCostDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDeleteProjectCost(projectCostDetailID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDeleteProjectCost(projectCostDetailID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectDeleteProjectCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectRepositoryID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDeleteProjectRepository(projectRepositoryID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDeleteProjectRepository(projectRepositoryID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectDeleteProjectRepository']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectRoutineID] 
         * @param {string} [outlookEventId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDeleteProjectRoutine(projectRoutineID?: string, outlookEventId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDeleteProjectRoutine(projectRoutineID, outlookEventId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectDeleteProjectRoutine']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [riskID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDeleteRisk(riskID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDeleteRisk(riskID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectDeleteRisk']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetDeliverableProjectPicklist(projectID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDeliverableProjectPicklistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetDeliverableProjectPicklist(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetDeliverableProjectPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {string} [year] 
         * @param {string} [isRollupKPI] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetGlobalKPIBowlingChart(kPIDetailID?: string, year?: string, isRollupKPI?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpandoObjectListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetGlobalKPIBowlingChart(kPIDetailID, year, isRollupKPI, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetGlobalKPIBowlingChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetGoalByProject(projectID?: string | null, xmatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GoalByProjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetGoalByProject(projectID, xmatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetGoalByProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [kpiDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetInsertKPI(kpiDetailID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInsertKPIResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetInsertKPI(kpiDetailID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetInsertKPI']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [kPIProjectRelationShipID] 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetKPIBowlingChart(kPIProjectRelationShipID?: string, projectID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpandoObjectListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetKPIBowlingChart(kPIProjectRelationShipID, projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetKPIBowlingChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetKPIProjectPicklist(projectID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetKPIProjectPicklistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetKPIProjectPicklist(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetKPIProjectPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [macroProjectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetMacroProject(macroProjectID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMacroProjectResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetMacroProject(macroProjectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetMacroProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetMonthlyAlertByUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMonthlyAlertByUserResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetMonthlyAlertByUser(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetMonthlyAlertByUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProject(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProject(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectChilds(projectID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectChildsResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectChilds(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetProjectChilds']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectCostChart(projectID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpandoObjectListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectCostChart(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetProjectCostChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectCostDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectCostDetail(projectCostDetailID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectCostDetailResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectCostDetail(projectCostDetailID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetProjectCostDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectCreationPicklist(projectId?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectCreationPicklistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectCreationPicklist(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetProjectCreationPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectId] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectDeliverable(projectId?: string, languageCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDeliverableParentListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectDeliverable(projectId, languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetProjectDeliverable']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {string | null} [deliverableID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectDeliverableParentPicklist(projectID?: string, deliverableID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDeliverableProjectPicklistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectDeliverableParentPicklist(projectID, deliverableID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetProjectDeliverableParentPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectDeliverablePicklist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectDeliverablePicklistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectDeliverablePicklist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetProjectDeliverablePicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} [hasProjectRelation] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectListPage(hasProjectRelation?: boolean, xMatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectListPageResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectListPage(hasProjectRelation, xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetProjectListPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectMembers(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectMembersResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectMembers(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetProjectMembers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectMembersList(projectID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectMembersListResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectMembersList(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetProjectMembersList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectPicklist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectPicklistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectPicklist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetProjectPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectRepository(projectID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectRepositoryListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectRepository(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetProjectRepository']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectRoutine(projectID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectRoutineResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectRoutine(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetProjectRoutine']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetRiskPrecaution(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRiskPrecautionResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetRiskPrecaution(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetRiskPrecaution']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [secondLevelXmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetSecondLevelMacroProjectsRelationship(secondLevelXmatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSecondLevelMacroProjectsRelationshipResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetSecondLevelMacroProjectsRelationship(secondLevelXmatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetSecondLevelMacroProjectsRelationship']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [userID] 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetTeamLeaderProject(userID?: string, xmatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTeamLeaderProjectResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetTeamLeaderProject(userID, xmatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetTeamLeaderProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetTotalCostProjectChart(projectID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpandoObjectListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetTotalCostProjectChart(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetTotalCostProjectChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetTotalTeamCostProjectChart(projectID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpandoObjectListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetTotalTeamCostProjectChart(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetTotalTeamCostProjectChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetUserAvailability(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpandoObjectListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetUserAvailability(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetUserAvailability']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetUserProjectCostAccess(projectID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserProjectCostAccessResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetUserProjectCostAccess(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetUserProjectCostAccess']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertKPIProjectRequestDto} [insertKPIProjectRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectInsertKPIProject(insertKPIProjectRequestDto?: InsertKPIProjectRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectInsertKPIProject(insertKPIProjectRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectInsertKPIProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertMacroProjectRequest} [insertMacroProjectRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectInsertMacroProject(insertMacroProjectRequest?: InsertMacroProjectRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectInsertMacroProject(insertMacroProjectRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectInsertMacroProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertProjectCostDetailRequestDto} [insertProjectCostDetailRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectInsertProjectCostDetail(insertProjectCostDetailRequestDto?: InsertProjectCostDetailRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsertProjectCostDetailResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectInsertProjectCostDetail(insertProjectCostDetailRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectInsertProjectCostDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertProjectDeliverableRequestDto} [insertProjectDeliverableRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectInsertProjectDeliverable(insertProjectDeliverableRequestDto?: InsertProjectDeliverableRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectInsertProjectDeliverable(insertProjectDeliverableRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectInsertProjectDeliverable']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertProjectMemberRequestDto} [insertProjectMemberRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectInsertProjectMember(insertProjectMemberRequestDto?: InsertProjectMemberRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsertProjectMemberResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectInsertProjectMember(insertProjectMemberRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectInsertProjectMember']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertProjectRepositoryRequestDto} [insertProjectRepositoryRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectInsertProjectRepository(insertProjectRepositoryRequestDto?: InsertProjectRepositoryRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectInsertProjectRepository(insertProjectRepositoryRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectInsertProjectRepository']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertProjectRoutineRequestDto} [insertProjectRoutineRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectInsertProjectRoutine(insertProjectRoutineRequestDto?: InsertProjectRoutineRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsertProjectRoutineResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectInsertProjectRoutine(insertProjectRoutineRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectInsertProjectRoutine']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertProjectRoutineMeetingRequestDto} [insertProjectRoutineMeetingRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectInsertProjectRoutineMeeting(insertProjectRoutineMeetingRequestDto?: InsertProjectRoutineMeetingRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectInsertProjectRoutineMeeting(insertProjectRoutineMeetingRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectInsertProjectRoutineMeeting']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertRiskRequestDto} [insertRiskRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectInsertRisk(insertRiskRequestDto?: InsertRiskRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectInsertRisk(insertRiskRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectInsertRisk']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertSecondLevelMacroProjectsRelationshipRequestDto} [insertSecondLevelMacroProjectsRelationshipRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectInsertSecondLevelMacroProjectsRelationship(insertSecondLevelMacroProjectsRelationshipRequestDto?: InsertSecondLevelMacroProjectsRelationshipRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectInsertSecondLevelMacroProjectsRelationship(insertSecondLevelMacroProjectsRelationshipRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectInsertSecondLevelMacroProjectsRelationship']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SwapTeamMembersActivitiesRequest} [swapTeamMembersActivitiesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectSwapTeamMembersActivities(swapTeamMembersActivitiesRequest?: SwapTeamMembersActivitiesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectSwapTeamMembersActivities(swapTeamMembersActivitiesRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectSwapTeamMembersActivities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateGlobalKPIBowlingChartRequestDto} [updateGlobalKPIBowlingChartRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateGlobalKPIBowlingChart(updateGlobalKPIBowlingChartRequestDto?: UpdateGlobalKPIBowlingChartRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateGlobalKPIBowlingChart(updateGlobalKPIBowlingChartRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectUpdateGlobalKPIBowlingChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateKPIBowlingChartRequestDto} [updateKPIBowlingChartRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateKPIBowlingChart(updateKPIBowlingChartRequestDto?: UpdateKPIBowlingChartRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateKPIBowlingChart(updateKPIBowlingChartRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectUpdateKPIBowlingChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateMacroProjectRequest} [updateMacroProjectRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateMacroProject(updateMacroProjectRequest?: UpdateMacroProjectRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateMacroProject(updateMacroProjectRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectUpdateMacroProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateMonthlyAlert(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateMonthlyAlert(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectUpdateMonthlyAlert']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateProjectRequestDto} [updateProjectRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateProject(updateProjectRequestDto?: UpdateProjectRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateProject(updateProjectRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectUpdateProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateProjectCostRequest} [updateProjectCostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateProjectCost(updateProjectCostRequest?: UpdateProjectCostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateProjectCost(updateProjectCostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectUpdateProjectCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateProjectCostDetailRequestDto} [updateProjectCostDetailRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateProjectCostDetail(updateProjectCostDetailRequestDto?: UpdateProjectCostDetailRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateProjectCostDetail(updateProjectCostDetailRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectUpdateProjectCostDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateProjectDeliverableRequestDto} [updateProjectDeliverableRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateProjectDeliverable(updateProjectDeliverableRequestDto?: UpdateProjectDeliverableRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateProjectDeliverable(updateProjectDeliverableRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectUpdateProjectDeliverable']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateProjectFieldsRequestDto} [updateProjectFieldsRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateProjectFields(updateProjectFieldsRequestDto?: UpdateProjectFieldsRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateProjectFields(updateProjectFieldsRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectUpdateProjectFields']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateProjectRoutineRequestDto} [updateProjectRoutineRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateProjectRoutine(updateProjectRoutineRequestDto?: UpdateProjectRoutineRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateProjectRoutine(updateProjectRoutineRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectUpdateProjectRoutine']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateProjectTotalCostRequest} [updateProjectTotalCostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateProjectTotalCost(updateProjectTotalCostRequest?: UpdateProjectTotalCostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateProjectTotalCost(updateProjectTotalCostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectUpdateProjectTotalCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateRiskRequestDto} [updateRiskRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateRisk(updateRiskRequestDto?: UpdateRiskRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateRisk(updateRiskRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectUpdateRisk']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateSecondLevelMacroProjectsRelationshipRequestDto} [updateSecondLevelMacroProjectsRelationshipRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateSecondLevelMacroProjectsRelationship(updateSecondLevelMacroProjectsRelationshipRequestDto?: UpdateSecondLevelMacroProjectsRelationshipRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateSecondLevelMacroProjectsRelationship(updateSecondLevelMacroProjectsRelationshipRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectUpdateSecondLevelMacroProjectsRelationship']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateTeamMembersRequest} [updateTeamMembersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateTeamMembers(updateTeamMembersRequest?: UpdateTeamMembersRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateTeamMembers(updateTeamMembersRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectUpdateTeamMembers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [projectCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCheckDuplicateProject(projectCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.projectCheckDuplicateProject(projectCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateProjectRequestDto} [createProjectRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCreateProject(createProjectRequestDto?: CreateProjectRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.projectCreateProject(createProjectRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [kPIProjectRelationShipID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeleteKPIRelationshipBowlingChart(kPIProjectRelationShipID?: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.projectDeleteKPIRelationshipBowlingChart(kPIProjectRelationShipID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [precautionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeletePrecaution(precautionID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.projectDeletePrecaution(precautionID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeleteProject(projectID?: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.projectDeleteProject(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectCostDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeleteProjectCost(projectCostDetailID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.projectDeleteProjectCost(projectCostDetailID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectRepositoryID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeleteProjectRepository(projectRepositoryID?: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.projectDeleteProjectRepository(projectRepositoryID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectRoutineID] 
         * @param {string} [outlookEventId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeleteProjectRoutine(projectRoutineID?: string, outlookEventId?: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.projectDeleteProjectRoutine(projectRoutineID, outlookEventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [riskID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeleteRisk(riskID?: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.projectDeleteRisk(riskID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetDeliverableProjectPicklist(projectID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetDeliverableProjectPicklistResponseDtoListControllerResponse> {
            return localVarFp.projectGetDeliverableProjectPicklist(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {string} [year] 
         * @param {string} [isRollupKPI] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetGlobalKPIBowlingChart(kPIDetailID?: string, year?: string, isRollupKPI?: string, options?: RawAxiosRequestConfig): AxiosPromise<ExpandoObjectListControllerResponse> {
            return localVarFp.projectGetGlobalKPIBowlingChart(kPIDetailID, year, isRollupKPI, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetGoalByProject(projectID?: string | null, xmatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GoalByProjectControllerResponse> {
            return localVarFp.projectGetGoalByProject(projectID, xmatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [kpiDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetInsertKPI(kpiDetailID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<GetInsertKPIResponseDtoControllerResponse> {
            return localVarFp.projectGetInsertKPI(kpiDetailID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [kPIProjectRelationShipID] 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetKPIBowlingChart(kPIProjectRelationShipID?: string, projectID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ExpandoObjectListControllerResponse> {
            return localVarFp.projectGetKPIBowlingChart(kPIProjectRelationShipID, projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetKPIProjectPicklist(projectID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetKPIProjectPicklistResponseDtoListControllerResponse> {
            return localVarFp.projectGetKPIProjectPicklist(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [macroProjectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetMacroProject(macroProjectID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetMacroProjectResponseDtoControllerResponse> {
            return localVarFp.projectGetMacroProject(macroProjectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetMonthlyAlertByUser(options?: RawAxiosRequestConfig): AxiosPromise<GetMonthlyAlertByUserResponseDtoListControllerResponse> {
            return localVarFp.projectGetMonthlyAlertByUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProject(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<GetProjectResponseDtoControllerResponse> {
            return localVarFp.projectGetProject(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectChilds(projectID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetProjectChildsResponseDtoListControllerResponse> {
            return localVarFp.projectGetProjectChilds(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectCostChart(projectID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ExpandoObjectListControllerResponse> {
            return localVarFp.projectGetProjectCostChart(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectCostDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectCostDetail(projectCostDetailID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetProjectCostDetailResponseDtoControllerResponse> {
            return localVarFp.projectGetProjectCostDetail(projectCostDetailID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectCreationPicklist(projectId?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<GetProjectCreationPicklistResponseDtoListControllerResponse> {
            return localVarFp.projectGetProjectCreationPicklist(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectId] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectDeliverable(projectId?: string, languageCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<ProjectDeliverableParentListControllerResponse> {
            return localVarFp.projectGetProjectDeliverable(projectId, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {string | null} [deliverableID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectDeliverableParentPicklist(projectID?: string, deliverableID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<GetDeliverableProjectPicklistResponseDtoListControllerResponse> {
            return localVarFp.projectGetProjectDeliverableParentPicklist(projectID, deliverableID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectDeliverablePicklist(options?: RawAxiosRequestConfig): AxiosPromise<GetProjectDeliverablePicklistResponseDtoListControllerResponse> {
            return localVarFp.projectGetProjectDeliverablePicklist(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [hasProjectRelation] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectListPage(hasProjectRelation?: boolean, xMatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetProjectListPageResponseDtoListControllerResponse> {
            return localVarFp.projectGetProjectListPage(hasProjectRelation, xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectMembers(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<GetProjectMembersResponseDtoListControllerResponse> {
            return localVarFp.projectGetProjectMembers(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectMembersList(projectID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetProjectMembersListResponseDtoListControllerResponse> {
            return localVarFp.projectGetProjectMembersList(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectPicklist(options?: RawAxiosRequestConfig): AxiosPromise<GetProjectPicklistResponseDtoListControllerResponse> {
            return localVarFp.projectGetProjectPicklist(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectRepository(projectID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetProjectRepositoryListControllerResponse> {
            return localVarFp.projectGetProjectRepository(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectRoutine(projectID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ProjectRoutineResponseDtoListControllerResponse> {
            return localVarFp.projectGetProjectRoutine(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetRiskPrecaution(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<GetRiskPrecautionResponseDtoListControllerResponse> {
            return localVarFp.projectGetRiskPrecaution(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [secondLevelXmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetSecondLevelMacroProjectsRelationship(secondLevelXmatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetSecondLevelMacroProjectsRelationshipResponseDtoListControllerResponse> {
            return localVarFp.projectGetSecondLevelMacroProjectsRelationship(secondLevelXmatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userID] 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetTeamLeaderProject(userID?: string, xmatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetTeamLeaderProjectResponseDtoListControllerResponse> {
            return localVarFp.projectGetTeamLeaderProject(userID, xmatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetTotalCostProjectChart(projectID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ExpandoObjectListControllerResponse> {
            return localVarFp.projectGetTotalCostProjectChart(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetTotalTeamCostProjectChart(projectID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ExpandoObjectListControllerResponse> {
            return localVarFp.projectGetTotalTeamCostProjectChart(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetUserAvailability(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ExpandoObjectListControllerResponse> {
            return localVarFp.projectGetUserAvailability(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetUserProjectCostAccess(projectID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetUserProjectCostAccessResponseDtoControllerResponse> {
            return localVarFp.projectGetUserProjectCostAccess(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertKPIProjectRequestDto} [insertKPIProjectRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertKPIProject(insertKPIProjectRequestDto?: InsertKPIProjectRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.projectInsertKPIProject(insertKPIProjectRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertMacroProjectRequest} [insertMacroProjectRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertMacroProject(insertMacroProjectRequest?: InsertMacroProjectRequest, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.projectInsertMacroProject(insertMacroProjectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertProjectCostDetailRequestDto} [insertProjectCostDetailRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertProjectCostDetail(insertProjectCostDetailRequestDto?: InsertProjectCostDetailRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<InsertProjectCostDetailResponseDtoControllerResponse> {
            return localVarFp.projectInsertProjectCostDetail(insertProjectCostDetailRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertProjectDeliverableRequestDto} [insertProjectDeliverableRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertProjectDeliverable(insertProjectDeliverableRequestDto?: InsertProjectDeliverableRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.projectInsertProjectDeliverable(insertProjectDeliverableRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertProjectMemberRequestDto} [insertProjectMemberRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertProjectMember(insertProjectMemberRequestDto?: InsertProjectMemberRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<InsertProjectMemberResponseDtoControllerResponse> {
            return localVarFp.projectInsertProjectMember(insertProjectMemberRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertProjectRepositoryRequestDto} [insertProjectRepositoryRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertProjectRepository(insertProjectRepositoryRequestDto?: InsertProjectRepositoryRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.projectInsertProjectRepository(insertProjectRepositoryRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertProjectRoutineRequestDto} [insertProjectRoutineRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertProjectRoutine(insertProjectRoutineRequestDto?: InsertProjectRoutineRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<InsertProjectRoutineResponseDtoControllerResponse> {
            return localVarFp.projectInsertProjectRoutine(insertProjectRoutineRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertProjectRoutineMeetingRequestDto} [insertProjectRoutineMeetingRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertProjectRoutineMeeting(insertProjectRoutineMeetingRequestDto?: InsertProjectRoutineMeetingRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.projectInsertProjectRoutineMeeting(insertProjectRoutineMeetingRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertRiskRequestDto} [insertRiskRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertRisk(insertRiskRequestDto?: InsertRiskRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.projectInsertRisk(insertRiskRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertSecondLevelMacroProjectsRelationshipRequestDto} [insertSecondLevelMacroProjectsRelationshipRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertSecondLevelMacroProjectsRelationship(insertSecondLevelMacroProjectsRelationshipRequestDto?: InsertSecondLevelMacroProjectsRelationshipRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.projectInsertSecondLevelMacroProjectsRelationship(insertSecondLevelMacroProjectsRelationshipRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SwapTeamMembersActivitiesRequest} [swapTeamMembersActivitiesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectSwapTeamMembersActivities(swapTeamMembersActivitiesRequest?: SwapTeamMembersActivitiesRequest, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.projectSwapTeamMembersActivities(swapTeamMembersActivitiesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateGlobalKPIBowlingChartRequestDto} [updateGlobalKPIBowlingChartRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateGlobalKPIBowlingChart(updateGlobalKPIBowlingChartRequestDto?: UpdateGlobalKPIBowlingChartRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.projectUpdateGlobalKPIBowlingChart(updateGlobalKPIBowlingChartRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateKPIBowlingChartRequestDto} [updateKPIBowlingChartRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateKPIBowlingChart(updateKPIBowlingChartRequestDto?: UpdateKPIBowlingChartRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.projectUpdateKPIBowlingChart(updateKPIBowlingChartRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateMacroProjectRequest} [updateMacroProjectRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateMacroProject(updateMacroProjectRequest?: UpdateMacroProjectRequest, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.projectUpdateMacroProject(updateMacroProjectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateMonthlyAlert(options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.projectUpdateMonthlyAlert(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateProjectRequestDto} [updateProjectRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProject(updateProjectRequestDto?: UpdateProjectRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.projectUpdateProject(updateProjectRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateProjectCostRequest} [updateProjectCostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectCost(updateProjectCostRequest?: UpdateProjectCostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.projectUpdateProjectCost(updateProjectCostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateProjectCostDetailRequestDto} [updateProjectCostDetailRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectCostDetail(updateProjectCostDetailRequestDto?: UpdateProjectCostDetailRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.projectUpdateProjectCostDetail(updateProjectCostDetailRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateProjectDeliverableRequestDto} [updateProjectDeliverableRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectDeliverable(updateProjectDeliverableRequestDto?: UpdateProjectDeliverableRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.projectUpdateProjectDeliverable(updateProjectDeliverableRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateProjectFieldsRequestDto} [updateProjectFieldsRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectFields(updateProjectFieldsRequestDto?: UpdateProjectFieldsRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.projectUpdateProjectFields(updateProjectFieldsRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateProjectRoutineRequestDto} [updateProjectRoutineRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectRoutine(updateProjectRoutineRequestDto?: UpdateProjectRoutineRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.projectUpdateProjectRoutine(updateProjectRoutineRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateProjectTotalCostRequest} [updateProjectTotalCostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectTotalCost(updateProjectTotalCostRequest?: UpdateProjectTotalCostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.projectUpdateProjectTotalCost(updateProjectTotalCostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateRiskRequestDto} [updateRiskRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateRisk(updateRiskRequestDto?: UpdateRiskRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.projectUpdateRisk(updateRiskRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateSecondLevelMacroProjectsRelationshipRequestDto} [updateSecondLevelMacroProjectsRelationshipRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateSecondLevelMacroProjectsRelationship(updateSecondLevelMacroProjectsRelationshipRequestDto?: UpdateSecondLevelMacroProjectsRelationshipRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.projectUpdateSecondLevelMacroProjectsRelationship(updateSecondLevelMacroProjectsRelationshipRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateTeamMembersRequest} [updateTeamMembersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateTeamMembers(updateTeamMembersRequest?: UpdateTeamMembersRequest, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.projectUpdateTeamMembers(updateTeamMembersRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI {
    /**
     * 
     * @param {string} [projectCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectCheckDuplicateProject(projectCode?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectCheckDuplicateProject(projectCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateProjectRequestDto} [createProjectRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectCreateProject(createProjectRequestDto?: CreateProjectRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectCreateProject(createProjectRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [kPIProjectRelationShipID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectDeleteKPIRelationshipBowlingChart(kPIProjectRelationShipID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectDeleteKPIRelationshipBowlingChart(kPIProjectRelationShipID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [precautionID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectDeletePrecaution(precautionID?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectDeletePrecaution(precautionID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectDeleteProject(projectID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectDeleteProject(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectCostDetailID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectDeleteProjectCost(projectCostDetailID?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectDeleteProjectCost(projectCostDetailID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectRepositoryID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectDeleteProjectRepository(projectRepositoryID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectDeleteProjectRepository(projectRepositoryID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectRoutineID] 
     * @param {string} [outlookEventId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectDeleteProjectRoutine(projectRoutineID?: string, outlookEventId?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectDeleteProjectRoutine(projectRoutineID, outlookEventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [riskID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectDeleteRisk(riskID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectDeleteRisk(riskID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetDeliverableProjectPicklist(projectID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetDeliverableProjectPicklist(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [kPIDetailID] 
     * @param {string} [year] 
     * @param {string} [isRollupKPI] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetGlobalKPIBowlingChart(kPIDetailID?: string, year?: string, isRollupKPI?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetGlobalKPIBowlingChart(kPIDetailID, year, isRollupKPI, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {string} [xmatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetGoalByProject(projectID?: string | null, xmatrixID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetGoalByProject(projectID, xmatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [kpiDetailID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetInsertKPI(kpiDetailID?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetInsertKPI(kpiDetailID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [kPIProjectRelationShipID] 
     * @param {string} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetKPIBowlingChart(kPIProjectRelationShipID?: string, projectID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetKPIBowlingChart(kPIProjectRelationShipID, projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetKPIProjectPicklist(projectID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetKPIProjectPicklist(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [macroProjectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetMacroProject(macroProjectID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetMacroProject(macroProjectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetMonthlyAlertByUser(options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetMonthlyAlertByUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProject(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProject(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProjectChilds(projectID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProjectChilds(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProjectCostChart(projectID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProjectCostChart(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectCostDetailID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProjectCostDetail(projectCostDetailID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProjectCostDetail(projectCostDetailID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProjectCreationPicklist(projectId?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProjectCreationPicklist(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectId] 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProjectDeliverable(projectId?: string, languageCode?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProjectDeliverable(projectId, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {string | null} [deliverableID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProjectDeliverableParentPicklist(projectID?: string, deliverableID?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProjectDeliverableParentPicklist(projectID, deliverableID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProjectDeliverablePicklist(options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProjectDeliverablePicklist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [hasProjectRelation] 
     * @param {string} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProjectListPage(hasProjectRelation?: boolean, xMatrixID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProjectListPage(hasProjectRelation, xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProjectMembers(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProjectMembers(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProjectMembersList(projectID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProjectMembersList(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProjectPicklist(options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProjectPicklist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProjectRepository(projectID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProjectRepository(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProjectRoutine(projectID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProjectRoutine(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetRiskPrecaution(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetRiskPrecaution(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [secondLevelXmatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetSecondLevelMacroProjectsRelationship(secondLevelXmatrixID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetSecondLevelMacroProjectsRelationship(secondLevelXmatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userID] 
     * @param {string} [xmatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetTeamLeaderProject(userID?: string, xmatrixID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetTeamLeaderProject(userID, xmatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetTotalCostProjectChart(projectID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetTotalCostProjectChart(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetTotalTeamCostProjectChart(projectID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetTotalTeamCostProjectChart(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetUserAvailability(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetUserAvailability(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetUserProjectCostAccess(projectID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetUserProjectCostAccess(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertKPIProjectRequestDto} [insertKPIProjectRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectInsertKPIProject(insertKPIProjectRequestDto?: InsertKPIProjectRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectInsertKPIProject(insertKPIProjectRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertMacroProjectRequest} [insertMacroProjectRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectInsertMacroProject(insertMacroProjectRequest?: InsertMacroProjectRequest, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectInsertMacroProject(insertMacroProjectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertProjectCostDetailRequestDto} [insertProjectCostDetailRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectInsertProjectCostDetail(insertProjectCostDetailRequestDto?: InsertProjectCostDetailRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectInsertProjectCostDetail(insertProjectCostDetailRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertProjectDeliverableRequestDto} [insertProjectDeliverableRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectInsertProjectDeliverable(insertProjectDeliverableRequestDto?: InsertProjectDeliverableRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectInsertProjectDeliverable(insertProjectDeliverableRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertProjectMemberRequestDto} [insertProjectMemberRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectInsertProjectMember(insertProjectMemberRequestDto?: InsertProjectMemberRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectInsertProjectMember(insertProjectMemberRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertProjectRepositoryRequestDto} [insertProjectRepositoryRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectInsertProjectRepository(insertProjectRepositoryRequestDto?: InsertProjectRepositoryRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectInsertProjectRepository(insertProjectRepositoryRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertProjectRoutineRequestDto} [insertProjectRoutineRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectInsertProjectRoutine(insertProjectRoutineRequestDto?: InsertProjectRoutineRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectInsertProjectRoutine(insertProjectRoutineRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertProjectRoutineMeetingRequestDto} [insertProjectRoutineMeetingRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectInsertProjectRoutineMeeting(insertProjectRoutineMeetingRequestDto?: InsertProjectRoutineMeetingRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectInsertProjectRoutineMeeting(insertProjectRoutineMeetingRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertRiskRequestDto} [insertRiskRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectInsertRisk(insertRiskRequestDto?: InsertRiskRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectInsertRisk(insertRiskRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertSecondLevelMacroProjectsRelationshipRequestDto} [insertSecondLevelMacroProjectsRelationshipRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectInsertSecondLevelMacroProjectsRelationship(insertSecondLevelMacroProjectsRelationshipRequestDto?: InsertSecondLevelMacroProjectsRelationshipRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectInsertSecondLevelMacroProjectsRelationship(insertSecondLevelMacroProjectsRelationshipRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SwapTeamMembersActivitiesRequest} [swapTeamMembersActivitiesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectSwapTeamMembersActivities(swapTeamMembersActivitiesRequest?: SwapTeamMembersActivitiesRequest, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectSwapTeamMembersActivities(swapTeamMembersActivitiesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateGlobalKPIBowlingChartRequestDto} [updateGlobalKPIBowlingChartRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateGlobalKPIBowlingChart(updateGlobalKPIBowlingChartRequestDto?: UpdateGlobalKPIBowlingChartRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateGlobalKPIBowlingChart(updateGlobalKPIBowlingChartRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateKPIBowlingChartRequestDto} [updateKPIBowlingChartRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateKPIBowlingChart(updateKPIBowlingChartRequestDto?: UpdateKPIBowlingChartRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateKPIBowlingChart(updateKPIBowlingChartRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateMacroProjectRequest} [updateMacroProjectRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateMacroProject(updateMacroProjectRequest?: UpdateMacroProjectRequest, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateMacroProject(updateMacroProjectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateMonthlyAlert(options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateMonthlyAlert(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateProjectRequestDto} [updateProjectRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateProject(updateProjectRequestDto?: UpdateProjectRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateProject(updateProjectRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateProjectCostRequest} [updateProjectCostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateProjectCost(updateProjectCostRequest?: UpdateProjectCostRequest, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateProjectCost(updateProjectCostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateProjectCostDetailRequestDto} [updateProjectCostDetailRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateProjectCostDetail(updateProjectCostDetailRequestDto?: UpdateProjectCostDetailRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateProjectCostDetail(updateProjectCostDetailRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateProjectDeliverableRequestDto} [updateProjectDeliverableRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateProjectDeliverable(updateProjectDeliverableRequestDto?: UpdateProjectDeliverableRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateProjectDeliverable(updateProjectDeliverableRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateProjectFieldsRequestDto} [updateProjectFieldsRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateProjectFields(updateProjectFieldsRequestDto?: UpdateProjectFieldsRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateProjectFields(updateProjectFieldsRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateProjectRoutineRequestDto} [updateProjectRoutineRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateProjectRoutine(updateProjectRoutineRequestDto?: UpdateProjectRoutineRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateProjectRoutine(updateProjectRoutineRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateProjectTotalCostRequest} [updateProjectTotalCostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateProjectTotalCost(updateProjectTotalCostRequest?: UpdateProjectTotalCostRequest, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateProjectTotalCost(updateProjectTotalCostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateRiskRequestDto} [updateRiskRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateRisk(updateRiskRequestDto?: UpdateRiskRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateRisk(updateRiskRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateSecondLevelMacroProjectsRelationshipRequestDto} [updateSecondLevelMacroProjectsRelationshipRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateSecondLevelMacroProjectsRelationship(updateSecondLevelMacroProjectsRelationshipRequestDto?: UpdateSecondLevelMacroProjectsRelationshipRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateSecondLevelMacroProjectsRelationship(updateSecondLevelMacroProjectsRelationshipRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateTeamMembersRequest} [updateTeamMembersRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateTeamMembers(updateTeamMembersRequest?: UpdateTeamMembersRequest, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateTeamMembers(updateTeamMembersRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProjectDashboardApi - axios parameter creator
 * @export
 */
export const ProjectDashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardGetDivisionPicklist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProjectDashboard/GetDivisionPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardGetParentProjectPicklist: async (xmatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProjectDashboard/GetParentProjectPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xmatrixID !== undefined) {
                localVarQueryParameter['XmatrixID'] = xmatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardGetProjectCodes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProjectDashboard/GetProjectCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [division] 
         * @param {string} [committie] 
         * @param {number} [status] 
         * @param {string} [teamLider] 
         * @param {string} [sponsor] 
         * @param {number} [timeSemaphore] 
         * @param {number} [budgetSemaphore] 
         * @param {number} [qualitySemaphore] 
         * @param {boolean} [hasParentProject] 
         * @param {string} [project] 
         * @param {string} [parentProject] 
         * @param {number | null} [filterCriteria] 
         * @param {string} [userID] 
         * @param {number} [comboSemaphore] 
         * @param {string} [projectCode] 
         * @param {string | null} [xmatrixID] 
         * @param {boolean} [selectNotRelatedProjects] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardGetProjectDashboards: async (division?: string, committie?: string, status?: number, teamLider?: string, sponsor?: string, timeSemaphore?: number, budgetSemaphore?: number, qualitySemaphore?: number, hasParentProject?: boolean, project?: string, parentProject?: string, filterCriteria?: number | null, userID?: string, comboSemaphore?: number, projectCode?: string, xmatrixID?: string | null, selectNotRelatedProjects?: boolean, languageCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProjectDashboard/GetProjectDashboards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (division !== undefined) {
                localVarQueryParameter['division'] = division;
            }

            if (committie !== undefined) {
                localVarQueryParameter['committie'] = committie;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (teamLider !== undefined) {
                localVarQueryParameter['teamLider'] = teamLider;
            }

            if (sponsor !== undefined) {
                localVarQueryParameter['sponsor'] = sponsor;
            }

            if (timeSemaphore !== undefined) {
                localVarQueryParameter['timeSemaphore'] = timeSemaphore;
            }

            if (budgetSemaphore !== undefined) {
                localVarQueryParameter['budgetSemaphore'] = budgetSemaphore;
            }

            if (qualitySemaphore !== undefined) {
                localVarQueryParameter['qualitySemaphore'] = qualitySemaphore;
            }

            if (hasParentProject !== undefined) {
                localVarQueryParameter['hasParentProject'] = hasParentProject;
            }

            if (project !== undefined) {
                localVarQueryParameter['Project'] = project;
            }

            if (parentProject !== undefined) {
                localVarQueryParameter['ParentProject'] = parentProject;
            }

            if (filterCriteria !== undefined) {
                localVarQueryParameter['FilterCriteria'] = filterCriteria;
            }

            if (userID !== undefined) {
                localVarQueryParameter['UserID'] = userID;
            }

            if (comboSemaphore !== undefined) {
                localVarQueryParameter['ComboSemaphore'] = comboSemaphore;
            }

            if (projectCode !== undefined) {
                localVarQueryParameter['ProjectCode'] = projectCode;
            }

            if (xmatrixID !== undefined) {
                localVarQueryParameter['XmatrixID'] = xmatrixID;
            }

            if (selectNotRelatedProjects !== undefined) {
                localVarQueryParameter['SelectNotRelatedProjects'] = selectNotRelatedProjects;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardGetProjectStatusPicklist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProjectDashboard/GetProjectStatusPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardGetSemaphorePicklist: async (languageCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProjectDashboard/GetSemaphorePicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardGetTeamLeaderPicklist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProjectDashboard/GetTeamLeaderPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xmatrixID] 
         * @param {string | null} [teamLider] 
         * @param {number | null} [status] 
         * @param {string | null} [division] 
         * @param {string | null} [sponsor] 
         * @param {string | null} [committie] 
         * @param {string} [projectCode] 
         * @param {string | null} [userID] 
         * @param {boolean | null} [selectNotRelatedProjects] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardProjectSummary: async (xmatrixID?: string, teamLider?: string | null, status?: number | null, division?: string | null, sponsor?: string | null, committie?: string | null, projectCode?: string, userID?: string | null, selectNotRelatedProjects?: boolean | null, languageCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProjectDashboard/GetProjectSummary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xmatrixID !== undefined) {
                localVarQueryParameter['xmatrixID'] = xmatrixID;
            }

            if (teamLider !== undefined) {
                localVarQueryParameter['teamLider'] = teamLider;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (division !== undefined) {
                localVarQueryParameter['division'] = division;
            }

            if (sponsor !== undefined) {
                localVarQueryParameter['sponsor'] = sponsor;
            }

            if (committie !== undefined) {
                localVarQueryParameter['committie'] = committie;
            }

            if (projectCode !== undefined) {
                localVarQueryParameter['projectCode'] = projectCode;
            }

            if (userID !== undefined) {
                localVarQueryParameter['UserID'] = userID;
            }

            if (selectNotRelatedProjects !== undefined) {
                localVarQueryParameter['selectNotRelatedProjects'] = selectNotRelatedProjects;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {QueryProjectFilters} [queryProjectFilters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardQueryProjects: async (queryProjectFilters?: QueryProjectFilters, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProjectDashboard/QueryProjects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(queryProjectFilters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectDashboardApi - functional programming interface
 * @export
 */
export const ProjectDashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectDashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDashboardGetDivisionPicklist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDivisionPicklistDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDashboardGetDivisionPicklist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectDashboardApi.projectDashboardGetDivisionPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDashboardGetParentProjectPicklist(xmatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetParentProjectPicklistDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDashboardGetParentProjectPicklist(xmatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectDashboardApi.projectDashboardGetParentProjectPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDashboardGetProjectCodes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectCodesResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDashboardGetProjectCodes(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectDashboardApi.projectDashboardGetProjectCodes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [division] 
         * @param {string} [committie] 
         * @param {number} [status] 
         * @param {string} [teamLider] 
         * @param {string} [sponsor] 
         * @param {number} [timeSemaphore] 
         * @param {number} [budgetSemaphore] 
         * @param {number} [qualitySemaphore] 
         * @param {boolean} [hasParentProject] 
         * @param {string} [project] 
         * @param {string} [parentProject] 
         * @param {number | null} [filterCriteria] 
         * @param {string} [userID] 
         * @param {number} [comboSemaphore] 
         * @param {string} [projectCode] 
         * @param {string | null} [xmatrixID] 
         * @param {boolean} [selectNotRelatedProjects] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDashboardGetProjectDashboards(division?: string, committie?: string, status?: number, teamLider?: string, sponsor?: string, timeSemaphore?: number, budgetSemaphore?: number, qualitySemaphore?: number, hasParentProject?: boolean, project?: string, parentProject?: string, filterCriteria?: number | null, userID?: string, comboSemaphore?: number, projectCode?: string, xmatrixID?: string | null, selectNotRelatedProjects?: boolean, languageCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDashboardsListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDashboardGetProjectDashboards(division, committie, status, teamLider, sponsor, timeSemaphore, budgetSemaphore, qualitySemaphore, hasParentProject, project, parentProject, filterCriteria, userID, comboSemaphore, projectCode, xmatrixID, selectNotRelatedProjects, languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectDashboardApi.projectDashboardGetProjectDashboards']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDashboardGetProjectStatusPicklist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectStatusPicklistDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDashboardGetProjectStatusPicklist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectDashboardApi.projectDashboardGetProjectStatusPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDashboardGetSemaphorePicklist(languageCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSemaphorePicklistDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDashboardGetSemaphorePicklist(languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectDashboardApi.projectDashboardGetSemaphorePicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDashboardGetTeamLeaderPicklist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTeamLeaderPicklistDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDashboardGetTeamLeaderPicklist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectDashboardApi.projectDashboardGetTeamLeaderPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xmatrixID] 
         * @param {string | null} [teamLider] 
         * @param {number | null} [status] 
         * @param {string | null} [division] 
         * @param {string | null} [sponsor] 
         * @param {string | null} [committie] 
         * @param {string} [projectCode] 
         * @param {string | null} [userID] 
         * @param {boolean | null} [selectNotRelatedProjects] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDashboardProjectSummary(xmatrixID?: string, teamLider?: string | null, status?: number | null, division?: string | null, sponsor?: string | null, committie?: string | null, projectCode?: string, userID?: string | null, selectNotRelatedProjects?: boolean | null, languageCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectSummaryListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDashboardProjectSummary(xmatrixID, teamLider, status, division, sponsor, committie, projectCode, userID, selectNotRelatedProjects, languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectDashboardApi.projectDashboardProjectSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {QueryProjectFilters} [queryProjectFilters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDashboardQueryProjects(queryProjectFilters?: QueryProjectFilters, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDashboardQueryProjects(queryProjectFilters, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectDashboardApi.projectDashboardQueryProjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProjectDashboardApi - factory interface
 * @export
 */
export const ProjectDashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectDashboardApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardGetDivisionPicklist(options?: RawAxiosRequestConfig): AxiosPromise<GetDivisionPicklistDtoListControllerResponse> {
            return localVarFp.projectDashboardGetDivisionPicklist(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardGetParentProjectPicklist(xmatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetParentProjectPicklistDtoListControllerResponse> {
            return localVarFp.projectDashboardGetParentProjectPicklist(xmatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardGetProjectCodes(options?: RawAxiosRequestConfig): AxiosPromise<GetProjectCodesResponseDtoListControllerResponse> {
            return localVarFp.projectDashboardGetProjectCodes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [division] 
         * @param {string} [committie] 
         * @param {number} [status] 
         * @param {string} [teamLider] 
         * @param {string} [sponsor] 
         * @param {number} [timeSemaphore] 
         * @param {number} [budgetSemaphore] 
         * @param {number} [qualitySemaphore] 
         * @param {boolean} [hasParentProject] 
         * @param {string} [project] 
         * @param {string} [parentProject] 
         * @param {number | null} [filterCriteria] 
         * @param {string} [userID] 
         * @param {number} [comboSemaphore] 
         * @param {string} [projectCode] 
         * @param {string | null} [xmatrixID] 
         * @param {boolean} [selectNotRelatedProjects] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardGetProjectDashboards(division?: string, committie?: string, status?: number, teamLider?: string, sponsor?: string, timeSemaphore?: number, budgetSemaphore?: number, qualitySemaphore?: number, hasParentProject?: boolean, project?: string, parentProject?: string, filterCriteria?: number | null, userID?: string, comboSemaphore?: number, projectCode?: string, xmatrixID?: string | null, selectNotRelatedProjects?: boolean, languageCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<ProjectDashboardsListControllerResponse> {
            return localVarFp.projectDashboardGetProjectDashboards(division, committie, status, teamLider, sponsor, timeSemaphore, budgetSemaphore, qualitySemaphore, hasParentProject, project, parentProject, filterCriteria, userID, comboSemaphore, projectCode, xmatrixID, selectNotRelatedProjects, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardGetProjectStatusPicklist(options?: RawAxiosRequestConfig): AxiosPromise<GetProjectStatusPicklistDtoListControllerResponse> {
            return localVarFp.projectDashboardGetProjectStatusPicklist(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardGetSemaphorePicklist(languageCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetSemaphorePicklistDtoListControllerResponse> {
            return localVarFp.projectDashboardGetSemaphorePicklist(languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardGetTeamLeaderPicklist(options?: RawAxiosRequestConfig): AxiosPromise<GetTeamLeaderPicklistDtoListControllerResponse> {
            return localVarFp.projectDashboardGetTeamLeaderPicklist(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xmatrixID] 
         * @param {string | null} [teamLider] 
         * @param {number | null} [status] 
         * @param {string | null} [division] 
         * @param {string | null} [sponsor] 
         * @param {string | null} [committie] 
         * @param {string} [projectCode] 
         * @param {string | null} [userID] 
         * @param {boolean | null} [selectNotRelatedProjects] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardProjectSummary(xmatrixID?: string, teamLider?: string | null, status?: number | null, division?: string | null, sponsor?: string | null, committie?: string | null, projectCode?: string, userID?: string | null, selectNotRelatedProjects?: boolean | null, languageCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<ProjectSummaryListControllerResponse> {
            return localVarFp.projectDashboardProjectSummary(xmatrixID, teamLider, status, division, sponsor, committie, projectCode, userID, selectNotRelatedProjects, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {QueryProjectFilters} [queryProjectFilters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardQueryProjects(queryProjectFilters?: QueryProjectFilters, options?: RawAxiosRequestConfig): AxiosPromise<Array<ProjectDto>> {
            return localVarFp.projectDashboardQueryProjects(queryProjectFilters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectDashboardApi - object-oriented interface
 * @export
 * @class ProjectDashboardApi
 * @extends {BaseAPI}
 */
export class ProjectDashboardApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectDashboardApi
     */
    public projectDashboardGetDivisionPicklist(options?: RawAxiosRequestConfig) {
        return ProjectDashboardApiFp(this.configuration).projectDashboardGetDivisionPicklist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xmatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectDashboardApi
     */
    public projectDashboardGetParentProjectPicklist(xmatrixID?: string, options?: RawAxiosRequestConfig) {
        return ProjectDashboardApiFp(this.configuration).projectDashboardGetParentProjectPicklist(xmatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectDashboardApi
     */
    public projectDashboardGetProjectCodes(options?: RawAxiosRequestConfig) {
        return ProjectDashboardApiFp(this.configuration).projectDashboardGetProjectCodes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [division] 
     * @param {string} [committie] 
     * @param {number} [status] 
     * @param {string} [teamLider] 
     * @param {string} [sponsor] 
     * @param {number} [timeSemaphore] 
     * @param {number} [budgetSemaphore] 
     * @param {number} [qualitySemaphore] 
     * @param {boolean} [hasParentProject] 
     * @param {string} [project] 
     * @param {string} [parentProject] 
     * @param {number | null} [filterCriteria] 
     * @param {string} [userID] 
     * @param {number} [comboSemaphore] 
     * @param {string} [projectCode] 
     * @param {string | null} [xmatrixID] 
     * @param {boolean} [selectNotRelatedProjects] 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectDashboardApi
     */
    public projectDashboardGetProjectDashboards(division?: string, committie?: string, status?: number, teamLider?: string, sponsor?: string, timeSemaphore?: number, budgetSemaphore?: number, qualitySemaphore?: number, hasParentProject?: boolean, project?: string, parentProject?: string, filterCriteria?: number | null, userID?: string, comboSemaphore?: number, projectCode?: string, xmatrixID?: string | null, selectNotRelatedProjects?: boolean, languageCode?: string, options?: RawAxiosRequestConfig) {
        return ProjectDashboardApiFp(this.configuration).projectDashboardGetProjectDashboards(division, committie, status, teamLider, sponsor, timeSemaphore, budgetSemaphore, qualitySemaphore, hasParentProject, project, parentProject, filterCriteria, userID, comboSemaphore, projectCode, xmatrixID, selectNotRelatedProjects, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectDashboardApi
     */
    public projectDashboardGetProjectStatusPicklist(options?: RawAxiosRequestConfig) {
        return ProjectDashboardApiFp(this.configuration).projectDashboardGetProjectStatusPicklist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectDashboardApi
     */
    public projectDashboardGetSemaphorePicklist(languageCode?: string, options?: RawAxiosRequestConfig) {
        return ProjectDashboardApiFp(this.configuration).projectDashboardGetSemaphorePicklist(languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectDashboardApi
     */
    public projectDashboardGetTeamLeaderPicklist(options?: RawAxiosRequestConfig) {
        return ProjectDashboardApiFp(this.configuration).projectDashboardGetTeamLeaderPicklist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xmatrixID] 
     * @param {string | null} [teamLider] 
     * @param {number | null} [status] 
     * @param {string | null} [division] 
     * @param {string | null} [sponsor] 
     * @param {string | null} [committie] 
     * @param {string} [projectCode] 
     * @param {string | null} [userID] 
     * @param {boolean | null} [selectNotRelatedProjects] 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectDashboardApi
     */
    public projectDashboardProjectSummary(xmatrixID?: string, teamLider?: string | null, status?: number | null, division?: string | null, sponsor?: string | null, committie?: string | null, projectCode?: string, userID?: string | null, selectNotRelatedProjects?: boolean | null, languageCode?: string, options?: RawAxiosRequestConfig) {
        return ProjectDashboardApiFp(this.configuration).projectDashboardProjectSummary(xmatrixID, teamLider, status, division, sponsor, committie, projectCode, userID, selectNotRelatedProjects, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {QueryProjectFilters} [queryProjectFilters] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectDashboardApi
     */
    public projectDashboardQueryProjects(queryProjectFilters?: QueryProjectFilters, options?: RawAxiosRequestConfig) {
        return ProjectDashboardApiFp(this.configuration).projectDashboardQueryProjects(queryProjectFilters, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProjectUserApi - axios parameter creator
 * @export
 */
export const ProjectUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {string} userId 
         * @param {boolean} onCall 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUserOnCall: async (projectId: string, userId: string, onCall: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectUserOnCall', 'projectId', projectId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('projectUserOnCall', 'userId', userId)
            // verify required parameter 'onCall' is not null or undefined
            assertParamExists('projectUserOnCall', 'onCall', onCall)
            const localVarPath = `/api/projects/{projectId}/users/{userId}/on-call`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (onCall !== undefined) {
                localVarQueryParameter['onCall'] = onCall;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectUserApi - functional programming interface
 * @export
 */
export const ProjectUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {string} userId 
         * @param {boolean} onCall 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUserOnCall(projectId: string, userId: string, onCall: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUserOnCall(projectId, userId, onCall, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectUserApi.projectUserOnCall']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProjectUserApi - factory interface
 * @export
 */
export const ProjectUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectUserApiFp(configuration)
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {string} userId 
         * @param {boolean} onCall 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUserOnCall(projectId: string, userId: string, onCall: boolean, options?: RawAxiosRequestConfig): AxiosPromise<ProjectUserDto> {
            return localVarFp.projectUserOnCall(projectId, userId, onCall, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectUserApi - object-oriented interface
 * @export
 * @class ProjectUserApi
 * @extends {BaseAPI}
 */
export class ProjectUserApi extends BaseAPI {
    /**
     * 
     * @param {string} projectId 
     * @param {string} userId 
     * @param {boolean} onCall 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectUserApi
     */
    public projectUserOnCall(projectId: string, userId: string, onCall: boolean, options?: RawAxiosRequestConfig) {
        return ProjectUserApiFp(this.configuration).projectUserOnCall(projectId, userId, onCall, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDeleteUserFromCompany: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userDeleteUserFromCompany', 'id', id)
            const localVarPath = `/api/User/{id}/delete-from-company`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userID] 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetFullProfile: async (userID?: string, year?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetFullProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userID !== undefined) {
                localVarQueryParameter['userID'] = userID;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetRolePicklist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetRolePicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetTotalActiveUsersByRole: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetTotalActiveUsersByRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUserCommittee: async (userID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUserCommittee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userID !== undefined) {
                localVarQueryParameter['userID'] = userID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [userIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUserInfo: async (userIds?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUserInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userIds) {
                localVarQueryParameter['UserIds'] = userIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUserListPageList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUserListPageList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUserProfile: async (year?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUserProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userID] 
         * @param {string} [xmatrixID] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUserProject: async (userID?: string, xmatrixID?: string, languageCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUserProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userID !== undefined) {
                localVarQueryParameter['userID'] = userID;
            }

            if (xmatrixID !== undefined) {
                localVarQueryParameter['xmatrixID'] = xmatrixID;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertSystemUserRequestDto} [insertSystemUserRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInsertSystemUser: async (insertSystemUserRequestDto?: InsertSystemUserRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/InsertSystemUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertSystemUserRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<UserInvitationRequestDto>} [userInvitationRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInviteUser: async (userInvitationRequestDto?: Array<UserInvitationRequestDto>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/InviteUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userInvitationRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [userId] 
         * @param {number | null} [type] 
         * @param {string} [jobTitle] 
         * @param {number | null} [availability] 
         * @param {string} [departament] 
         * @param {number | null} [dailyRate] 
         * @param {string} [emailAddress] 
         * @param {File} [photo] 
         * @param {string | null} [divisionID] 
         * @param {string} [phoneNumber] 
         * @param {string} [firstName] 
         * @param {string} [surname] 
         * @param {string | null} [roleID] 
         * @param {boolean | null} [isDisabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdateUser: async (userId?: string | null, type?: number | null, jobTitle?: string, availability?: number | null, departament?: string, dailyRate?: number | null, emailAddress?: string, photo?: File, divisionID?: string | null, phoneNumber?: string, firstName?: string, surname?: string, roleID?: string | null, isDisabled?: boolean | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/UpdateUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (userId !== undefined) { 
                localVarFormParams.append('UserId', userId as any);
            }
    
            if (type !== undefined) { 
                localVarFormParams.append('Type', type as any);
            }
    
            if (jobTitle !== undefined) { 
                localVarFormParams.append('JobTitle', jobTitle as any);
            }
    
            if (availability !== undefined) { 
                localVarFormParams.append('Availability', availability as any);
            }
    
            if (departament !== undefined) { 
                localVarFormParams.append('Departament', departament as any);
            }
    
            if (dailyRate !== undefined) { 
                localVarFormParams.append('DailyRate', dailyRate as any);
            }
    
            if (emailAddress !== undefined) { 
                localVarFormParams.append('EmailAddress', emailAddress as any);
            }
    
            if (photo !== undefined) { 
                localVarFormParams.append('Photo', photo as any);
            }
    
            if (divisionID !== undefined) { 
                localVarFormParams.append('DivisionID', divisionID as any);
            }
    
            if (phoneNumber !== undefined) { 
                localVarFormParams.append('PhoneNumber', phoneNumber as any);
            }
    
            if (firstName !== undefined) { 
                localVarFormParams.append('FirstName', firstName as any);
            }
    
            if (surname !== undefined) { 
                localVarFormParams.append('Surname', surname as any);
            }
    
            if (roleID !== undefined) { 
                localVarFormParams.append('RoleID', roleID as any);
            }
    
            if (isDisabled !== undefined) { 
                localVarFormParams.append('IsDisabled', String(isDisabled) as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {File} [photo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdateUserProfile: async (userId?: string, photo?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/UpdateUserProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (userId !== undefined) { 
                localVarFormParams.append('UserId', userId as any);
            }
    
            if (photo !== undefined) { 
                localVarFormParams.append('Photo', photo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userDeleteUserFromCompany(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userDeleteUserFromCompany(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userDeleteUserFromCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [userID] 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetFullProfile(userID?: string, year?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFullProfileDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetFullProfile(userID, year, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userGetFullProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetRolePicklist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRolePicklistDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetRolePicklist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userGetRolePicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetTotalActiveUsersByRole(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTotalActiveUsersByRoleResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetTotalActiveUsersByRole(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userGetTotalActiveUsersByRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetUserCommittee(userID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserCommitteeDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetUserCommittee(userID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userGetUserCommittee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} [userIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetUserInfo(userIds?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfoResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetUserInfo(userIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userGetUserInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetUserListPageList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListPageResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetUserListPageList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userGetUserListPageList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetUserProfile(year?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetUserProfile(year, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userGetUserProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [userID] 
         * @param {string} [xmatrixID] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetUserProject(userID?: string, xmatrixID?: string, languageCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserProjectResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetUserProject(userID, xmatrixID, languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userGetUserProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertSystemUserRequestDto} [insertSystemUserRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userInsertSystemUser(insertSystemUserRequestDto?: InsertSystemUserRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userInsertSystemUser(insertSystemUserRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userInsertSystemUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<UserInvitationRequestDto>} [userInvitationRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userInviteUser(userInvitationRequestDto?: Array<UserInvitationRequestDto>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userInviteUser(userInvitationRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userInviteUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [userId] 
         * @param {number | null} [type] 
         * @param {string} [jobTitle] 
         * @param {number | null} [availability] 
         * @param {string} [departament] 
         * @param {number | null} [dailyRate] 
         * @param {string} [emailAddress] 
         * @param {File} [photo] 
         * @param {string | null} [divisionID] 
         * @param {string} [phoneNumber] 
         * @param {string} [firstName] 
         * @param {string} [surname] 
         * @param {string | null} [roleID] 
         * @param {boolean | null} [isDisabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdateUser(userId?: string | null, type?: number | null, jobTitle?: string, availability?: number | null, departament?: string, dailyRate?: number | null, emailAddress?: string, photo?: File, divisionID?: string | null, phoneNumber?: string, firstName?: string, surname?: string, roleID?: string | null, isDisabled?: boolean | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUpdateUser(userId, type, jobTitle, availability, departament, dailyRate, emailAddress, photo, divisionID, phoneNumber, firstName, surname, roleID, isDisabled, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userUpdateUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {File} [photo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdateUserProfile(userId?: string, photo?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUpdateUserProfile(userId, photo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userUpdateUserProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDeleteUserFromCompany(id: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.userDeleteUserFromCompany(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userID] 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetFullProfile(userID?: string, year?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetFullProfileDtoControllerResponse> {
            return localVarFp.userGetFullProfile(userID, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetRolePicklist(options?: RawAxiosRequestConfig): AxiosPromise<GetRolePicklistDtoListControllerResponse> {
            return localVarFp.userGetRolePicklist(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetTotalActiveUsersByRole(options?: RawAxiosRequestConfig): AxiosPromise<GetTotalActiveUsersByRoleResponseDtoListControllerResponse> {
            return localVarFp.userGetTotalActiveUsersByRole(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUserCommittee(userID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetUserCommitteeDtoListControllerResponse> {
            return localVarFp.userGetUserCommittee(userID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [userIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUserInfo(userIds?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<UserInfoResponseDtoListControllerResponse> {
            return localVarFp.userGetUserInfo(userIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUserListPageList(options?: RawAxiosRequestConfig): AxiosPromise<UserListPageResponseDtoListControllerResponse> {
            return localVarFp.userGetUserListPageList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUserProfile(year?: string, options?: RawAxiosRequestConfig): AxiosPromise<UserProfileResponseDtoControllerResponse> {
            return localVarFp.userGetUserProfile(year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userID] 
         * @param {string} [xmatrixID] 
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUserProject(userID?: string, xmatrixID?: string, languageCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetUserProjectResponseDtoListControllerResponse> {
            return localVarFp.userGetUserProject(userID, xmatrixID, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertSystemUserRequestDto} [insertSystemUserRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInsertSystemUser(insertSystemUserRequestDto?: InsertSystemUserRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.userInsertSystemUser(insertSystemUserRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<UserInvitationRequestDto>} [userInvitationRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInviteUser(userInvitationRequestDto?: Array<UserInvitationRequestDto>, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.userInviteUser(userInvitationRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [userId] 
         * @param {number | null} [type] 
         * @param {string} [jobTitle] 
         * @param {number | null} [availability] 
         * @param {string} [departament] 
         * @param {number | null} [dailyRate] 
         * @param {string} [emailAddress] 
         * @param {File} [photo] 
         * @param {string | null} [divisionID] 
         * @param {string} [phoneNumber] 
         * @param {string} [firstName] 
         * @param {string} [surname] 
         * @param {string | null} [roleID] 
         * @param {boolean | null} [isDisabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdateUser(userId?: string | null, type?: number | null, jobTitle?: string, availability?: number | null, departament?: string, dailyRate?: number | null, emailAddress?: string, photo?: File, divisionID?: string | null, phoneNumber?: string, firstName?: string, surname?: string, roleID?: string | null, isDisabled?: boolean | null, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.userUpdateUser(userId, type, jobTitle, availability, departament, dailyRate, emailAddress, photo, divisionID, phoneNumber, firstName, surname, roleID, isDisabled, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {File} [photo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdateUserProfile(userId?: string, photo?: File, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.userUpdateUserProfile(userId, photo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userDeleteUserFromCompany(id: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userDeleteUserFromCompany(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userID] 
     * @param {string} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetFullProfile(userID?: string, year?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetFullProfile(userID, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetRolePicklist(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetRolePicklist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetTotalActiveUsersByRole(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetTotalActiveUsersByRole(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetUserCommittee(userID?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetUserCommittee(userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [userIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetUserInfo(userIds?: Array<string>, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetUserInfo(userIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetUserListPageList(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetUserListPageList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetUserProfile(year?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetUserProfile(year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userID] 
     * @param {string} [xmatrixID] 
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetUserProject(userID?: string, xmatrixID?: string, languageCode?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetUserProject(userID, xmatrixID, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertSystemUserRequestDto} [insertSystemUserRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userInsertSystemUser(insertSystemUserRequestDto?: InsertSystemUserRequestDto, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userInsertSystemUser(insertSystemUserRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<UserInvitationRequestDto>} [userInvitationRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userInviteUser(userInvitationRequestDto?: Array<UserInvitationRequestDto>, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userInviteUser(userInvitationRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [userId] 
     * @param {number | null} [type] 
     * @param {string} [jobTitle] 
     * @param {number | null} [availability] 
     * @param {string} [departament] 
     * @param {number | null} [dailyRate] 
     * @param {string} [emailAddress] 
     * @param {File} [photo] 
     * @param {string | null} [divisionID] 
     * @param {string} [phoneNumber] 
     * @param {string} [firstName] 
     * @param {string} [surname] 
     * @param {string | null} [roleID] 
     * @param {boolean | null} [isDisabled] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUpdateUser(userId?: string | null, type?: number | null, jobTitle?: string, availability?: number | null, departament?: string, dailyRate?: number | null, emailAddress?: string, photo?: File, divisionID?: string | null, phoneNumber?: string, firstName?: string, surname?: string, roleID?: string | null, isDisabled?: boolean | null, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userUpdateUser(userId, type, jobTitle, availability, departament, dailyRate, emailAddress, photo, divisionID, phoneNumber, firstName, surname, roleID, isDisabled, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userId] 
     * @param {File} [photo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUpdateUserProfile(userId?: string, photo?: File, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userUpdateUserProfile(userId, photo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WorkloadContollerApi - axios parameter creator
 * @export
 */
export const WorkloadContollerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [year] 
         * @param {string} [userID] 
         * @param {string} [projectID] 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetProjectTimePlanning: async (year?: string, userID?: string, projectID?: string, xmatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WorkloadContoller/GetProjectTimePlanning`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }

            if (userID !== undefined) {
                localVarQueryParameter['UserID'] = userID;
            }

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }

            if (xmatrixID !== undefined) {
                localVarQueryParameter['xmatrixID'] = xmatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetResourceWorkload: async (year?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WorkloadContoller/GetResourceWorkload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetSummaryPlanningProject: async (projectID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WorkloadContoller/GetSummaryPlanningProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetTeamPlanningProject: async (projectID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WorkloadContoller/GetTeamPlanningProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetTotalResourceWorkload: async (year?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WorkloadContoller/GetTotalResourceWorkload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetTotalTeamPlanningProject: async (projectID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WorkloadContoller/GetTotalTeamPlanningProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userID] 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetUserPlanningProject: async (userID?: string, year?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WorkloadContoller/GetUserPlanningProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userID !== undefined) {
                localVarQueryParameter['UserID'] = userID;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [year] 
         * @param {string} [userID] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetUserTimePlanning: async (year?: string, userID?: string, xMatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WorkloadContoller/GetUserTimePlanning`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }

            if (userID !== undefined) {
                localVarQueryParameter['UserID'] = userID;
            }

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdatePlaningProjectRequest} [updatePlaningProjectRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerUpdateTeamPlanningProject: async (updatePlaningProjectRequest?: UpdatePlaningProjectRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WorkloadContoller/UpdatePlanningProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePlaningProjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserPlanning} [updateUserPlanning] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerUpdateUserPlanningProject: async (updateUserPlanning?: UpdateUserPlanning, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WorkloadContoller/UpdateUserPlanningProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserPlanning, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserTimePlanning} [updateUserTimePlanning] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerUpdateUserTimePlanning: async (updateUserTimePlanning?: UpdateUserTimePlanning, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WorkloadContoller/UpdateUserTimePlanning`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserTimePlanning, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkloadContollerApi - functional programming interface
 * @export
 */
export const WorkloadContollerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkloadContollerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [year] 
         * @param {string} [userID] 
         * @param {string} [projectID] 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadContollerGetProjectTimePlanning(year?: string, userID?: string, projectID?: string, xmatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpandoObjectListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadContollerGetProjectTimePlanning(year, userID, projectID, xmatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadContollerApi.workloadContollerGetProjectTimePlanning']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadContollerGetResourceWorkload(year?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpandoObjectListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadContollerGetResourceWorkload(year, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadContollerApi.workloadContollerGetResourceWorkload']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadContollerGetSummaryPlanningProject(projectID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpandoObjectListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadContollerGetSummaryPlanningProject(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadContollerApi.workloadContollerGetSummaryPlanningProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadContollerGetTeamPlanningProject(projectID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpandoObjectListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadContollerGetTeamPlanningProject(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadContollerApi.workloadContollerGetTeamPlanningProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadContollerGetTotalResourceWorkload(year?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpandoObjectListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadContollerGetTotalResourceWorkload(year, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadContollerApi.workloadContollerGetTotalResourceWorkload']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadContollerGetTotalTeamPlanningProject(projectID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpandoObjectListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadContollerGetTotalTeamPlanningProject(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadContollerApi.workloadContollerGetTotalTeamPlanningProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [userID] 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadContollerGetUserPlanningProject(userID?: string, year?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpandoObjectListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadContollerGetUserPlanningProject(userID, year, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadContollerApi.workloadContollerGetUserPlanningProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [year] 
         * @param {string} [userID] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadContollerGetUserTimePlanning(year?: string, userID?: string, xMatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpandoObjectListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadContollerGetUserTimePlanning(year, userID, xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadContollerApi.workloadContollerGetUserTimePlanning']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdatePlaningProjectRequest} [updatePlaningProjectRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadContollerUpdateTeamPlanningProject(updatePlaningProjectRequest?: UpdatePlaningProjectRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadContollerUpdateTeamPlanningProject(updatePlaningProjectRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadContollerApi.workloadContollerUpdateTeamPlanningProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateUserPlanning} [updateUserPlanning] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadContollerUpdateUserPlanningProject(updateUserPlanning?: UpdateUserPlanning, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadContollerUpdateUserPlanningProject(updateUserPlanning, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadContollerApi.workloadContollerUpdateUserPlanningProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateUserTimePlanning} [updateUserTimePlanning] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadContollerUpdateUserTimePlanning(updateUserTimePlanning?: UpdateUserTimePlanning, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadContollerUpdateUserTimePlanning(updateUserTimePlanning, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadContollerApi.workloadContollerUpdateUserTimePlanning']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WorkloadContollerApi - factory interface
 * @export
 */
export const WorkloadContollerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkloadContollerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [year] 
         * @param {string} [userID] 
         * @param {string} [projectID] 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetProjectTimePlanning(year?: string, userID?: string, projectID?: string, xmatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ExpandoObjectListControllerResponse> {
            return localVarFp.workloadContollerGetProjectTimePlanning(year, userID, projectID, xmatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetResourceWorkload(year?: string, options?: RawAxiosRequestConfig): AxiosPromise<ExpandoObjectListControllerResponse> {
            return localVarFp.workloadContollerGetResourceWorkload(year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetSummaryPlanningProject(projectID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ExpandoObjectListControllerResponse> {
            return localVarFp.workloadContollerGetSummaryPlanningProject(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetTeamPlanningProject(projectID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ExpandoObjectListControllerResponse> {
            return localVarFp.workloadContollerGetTeamPlanningProject(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetTotalResourceWorkload(year?: string, options?: RawAxiosRequestConfig): AxiosPromise<ExpandoObjectListControllerResponse> {
            return localVarFp.workloadContollerGetTotalResourceWorkload(year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetTotalTeamPlanningProject(projectID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ExpandoObjectListControllerResponse> {
            return localVarFp.workloadContollerGetTotalTeamPlanningProject(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userID] 
         * @param {string} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetUserPlanningProject(userID?: string, year?: string, options?: RawAxiosRequestConfig): AxiosPromise<ExpandoObjectListControllerResponse> {
            return localVarFp.workloadContollerGetUserPlanningProject(userID, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [year] 
         * @param {string} [userID] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetUserTimePlanning(year?: string, userID?: string, xMatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ExpandoObjectListControllerResponse> {
            return localVarFp.workloadContollerGetUserTimePlanning(year, userID, xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdatePlaningProjectRequest} [updatePlaningProjectRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerUpdateTeamPlanningProject(updatePlaningProjectRequest?: UpdatePlaningProjectRequest, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.workloadContollerUpdateTeamPlanningProject(updatePlaningProjectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserPlanning} [updateUserPlanning] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerUpdateUserPlanningProject(updateUserPlanning?: UpdateUserPlanning, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.workloadContollerUpdateUserPlanningProject(updateUserPlanning, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserTimePlanning} [updateUserTimePlanning] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerUpdateUserTimePlanning(updateUserTimePlanning?: UpdateUserTimePlanning, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.workloadContollerUpdateUserTimePlanning(updateUserTimePlanning, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkloadContollerApi - object-oriented interface
 * @export
 * @class WorkloadContollerApi
 * @extends {BaseAPI}
 */
export class WorkloadContollerApi extends BaseAPI {
    /**
     * 
     * @param {string} [year] 
     * @param {string} [userID] 
     * @param {string} [projectID] 
     * @param {string} [xmatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadContollerApi
     */
    public workloadContollerGetProjectTimePlanning(year?: string, userID?: string, projectID?: string, xmatrixID?: string, options?: RawAxiosRequestConfig) {
        return WorkloadContollerApiFp(this.configuration).workloadContollerGetProjectTimePlanning(year, userID, projectID, xmatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadContollerApi
     */
    public workloadContollerGetResourceWorkload(year?: string, options?: RawAxiosRequestConfig) {
        return WorkloadContollerApiFp(this.configuration).workloadContollerGetResourceWorkload(year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadContollerApi
     */
    public workloadContollerGetSummaryPlanningProject(projectID?: string, options?: RawAxiosRequestConfig) {
        return WorkloadContollerApiFp(this.configuration).workloadContollerGetSummaryPlanningProject(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadContollerApi
     */
    public workloadContollerGetTeamPlanningProject(projectID?: string, options?: RawAxiosRequestConfig) {
        return WorkloadContollerApiFp(this.configuration).workloadContollerGetTeamPlanningProject(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadContollerApi
     */
    public workloadContollerGetTotalResourceWorkload(year?: string, options?: RawAxiosRequestConfig) {
        return WorkloadContollerApiFp(this.configuration).workloadContollerGetTotalResourceWorkload(year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadContollerApi
     */
    public workloadContollerGetTotalTeamPlanningProject(projectID?: string, options?: RawAxiosRequestConfig) {
        return WorkloadContollerApiFp(this.configuration).workloadContollerGetTotalTeamPlanningProject(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userID] 
     * @param {string} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadContollerApi
     */
    public workloadContollerGetUserPlanningProject(userID?: string, year?: string, options?: RawAxiosRequestConfig) {
        return WorkloadContollerApiFp(this.configuration).workloadContollerGetUserPlanningProject(userID, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [year] 
     * @param {string} [userID] 
     * @param {string} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadContollerApi
     */
    public workloadContollerGetUserTimePlanning(year?: string, userID?: string, xMatrixID?: string, options?: RawAxiosRequestConfig) {
        return WorkloadContollerApiFp(this.configuration).workloadContollerGetUserTimePlanning(year, userID, xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdatePlaningProjectRequest} [updatePlaningProjectRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadContollerApi
     */
    public workloadContollerUpdateTeamPlanningProject(updatePlaningProjectRequest?: UpdatePlaningProjectRequest, options?: RawAxiosRequestConfig) {
        return WorkloadContollerApiFp(this.configuration).workloadContollerUpdateTeamPlanningProject(updatePlaningProjectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateUserPlanning} [updateUserPlanning] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadContollerApi
     */
    public workloadContollerUpdateUserPlanningProject(updateUserPlanning?: UpdateUserPlanning, options?: RawAxiosRequestConfig) {
        return WorkloadContollerApiFp(this.configuration).workloadContollerUpdateUserPlanningProject(updateUserPlanning, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateUserTimePlanning} [updateUserTimePlanning] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadContollerApi
     */
    public workloadContollerUpdateUserTimePlanning(updateUserTimePlanning?: UpdateUserTimePlanning, options?: RawAxiosRequestConfig) {
        return WorkloadContollerApiFp(this.configuration).workloadContollerUpdateUserTimePlanning(updateUserTimePlanning, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * XMatrixApi - axios parameter creator
 * @export
 */
export const XMatrixApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {string} [xMatrixCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixDeleteAnnualGoalXMatrixRelation: async (annualGoalID?: string, xMatrixCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/DeleteAnnualGoalXMatrixRelation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (annualGoalID !== undefined) {
                localVarQueryParameter['AnnualGoalID'] = annualGoalID;
            }

            if (xMatrixCode !== undefined) {
                localVarQueryParameter['XMatrixCode'] = xMatrixCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixDeleteDraftXMatrix: async (xMatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/DeleteDraftXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [kPIID] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixDeleteKPIRelationshipXMatrix: async (kPIID?: string, xMatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/DeleteKPIRelationshipXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (kPIID !== undefined) {
                localVarQueryParameter['KPIID'] = kPIID;
            }

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {string} [xMatrixCode] 
         * @param {boolean} [deleteProject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixDeleteProjectXMatrixDraftRelation: async (projectID?: string, xMatrixCode?: string, deleteProject?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/DeleteProjectXMatrixDraftRelation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }

            if (xMatrixCode !== undefined) {
                localVarQueryParameter['XMatrixCode'] = xMatrixCode;
            }

            if (deleteProject !== undefined) {
                localVarQueryParameter['DeleteProject'] = deleteProject;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {string} [xMatrixCode] 
         * @param {boolean} [deleteProject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixDeleteProjectXMatrixRelation: async (projectID?: string, xMatrixCode?: string, deleteProject?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/DeleteProjectXMatrixRelation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }

            if (xMatrixCode !== undefined) {
                localVarQueryParameter['XMatrixCode'] = xMatrixCode;
            }

            if (deleteProject !== undefined) {
                localVarQueryParameter['DeleteProject'] = deleteProject;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {string} [userID] 
         * @param {string} [relation] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixDeleteUserRelationshipXMatrix: async (projectID?: string, userID?: string, relation?: string, xMatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/DeleteUserRelationshipXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }

            if (userID !== undefined) {
                localVarQueryParameter['UserID'] = userID;
            }

            if (relation !== undefined) {
                localVarQueryParameter['Relation'] = relation;
            }

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xMatrixCycleID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixDeleteXMatrixCycle: async (xMatrixCycleID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/DeleteXMatrixCycle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xMatrixCycleID !== undefined) {
                localVarQueryParameter['XMatrixCycleID'] = xMatrixCycleID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetActiveXMatrixInfo: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetActiveXMatrixInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetAnnualGoalPicklistXMatrix: async (xMatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetAnnualGoalPicklistXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetCommitteePicklistXMatrix: async (xMatrixID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetCommitteePicklistXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {XMatrixSecondLevelFilters} [xMatrixSecondLevelFilters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetDivisionDataXMatrix: async (xMatrixSecondLevelFilters?: XMatrixSecondLevelFilters, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetDivisionDataXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(xMatrixSecondLevelFilters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetDivisionPicklistXMatrix: async (xMatrixID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetDivisionPicklistXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetDivisionProjectCreationPicklist: async (xmatrixId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetDivisionProjectCreationPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xmatrixId !== undefined) {
                localVarQueryParameter['xmatrixId'] = xmatrixId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetKPIXMAtrixPicklist: async (xMatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetKPIXMAtrixPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetLongTermGoalChecklist: async (xMatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetLongTermGoalChecklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetLongTermGoalPicklistXMatrix: async (xMatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetLongTermGoalPicklistXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetNewDivisionXMatrixAnnualGoalPicklist: async (xmatrixId?: string, divisionID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetNewDivisionXMatrixAnnualGoalPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xmatrixId !== undefined) {
                localVarQueryParameter['xmatrixId'] = xmatrixId;
            }

            if (divisionID !== undefined) {
                localVarQueryParameter['divisionID'] = divisionID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetNewDivisionXMatrixLongTermGoalPicklist: async (xmatrixId?: string, divisionID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetNewDivisionXMatrixLongTermGoalPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xmatrixId !== undefined) {
                localVarQueryParameter['xmatrixId'] = xmatrixId;
            }

            if (divisionID !== undefined) {
                localVarQueryParameter['divisionID'] = divisionID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetNewDivisionXMatrixProjectPicklist: async (xmatrixId?: string, divisionID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetNewDivisionXMatrixProjectPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xmatrixId !== undefined) {
                localVarQueryParameter['xmatrixId'] = xmatrixId;
            }

            if (divisionID !== undefined) {
                localVarQueryParameter['divisionID'] = divisionID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetNewXmatrixAnnualGoalPicklist: async (xmatrixId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetNewXmatrixAnnualGoalPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xmatrixId !== undefined) {
                localVarQueryParameter['xmatrixId'] = xmatrixId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetNewXmatrixProjectPicklist: async (xmatrixId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetNewXmatrixProjectPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xmatrixId !== undefined) {
                localVarQueryParameter['xmatrixId'] = xmatrixId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetProjectPicklistXMatrix: async (xMatrixID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetProjectPicklistXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetRootXMatrixDivisionList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetRootXMatrixDivisionList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetSponsorPicklist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetSponsorPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {XMatrixFilters} [xMatrixFilters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXMatrix: async (xMatrixFilters?: XMatrixFilters, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(xMatrixFilters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [companyID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXMatrixCycle: async (companyID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetXMatrixCycle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyID !== undefined) {
                localVarQueryParameter['CompanyID'] = companyID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xMatrixCycleID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXMatrixCycleByID: async (xMatrixCycleID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetXMatrixCycleByID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xMatrixCycleID !== undefined) {
                localVarQueryParameter['XMatrixCycleID'] = xMatrixCycleID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXMatrixData: async (xmatrixId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetXMatrixData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xmatrixId !== undefined) {
                localVarQueryParameter['xmatrixId'] = xmatrixId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXMatrixInfo: async (xmatrixId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetXMatrixInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xmatrixId !== undefined) {
                localVarQueryParameter['xmatrixId'] = xmatrixId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXMatrixPicklist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetXMatrixPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXMatrixUsers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetXMatrixUsers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXmatrixDivisionParentProjectPicklist: async (divisionID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetXmatrixDivisionParentProjectPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (divisionID !== undefined) {
                localVarQueryParameter['DivisionID'] = divisionID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXmatrixDivisionPicklist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetXmatrixDivisionPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertXmatrixCycleRequestDto} [insertXmatrixCycleRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixInserXMatrixCycle: async (insertXmatrixCycleRequestDto?: InsertXmatrixCycleRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/InserXMatrixCycle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertXmatrixCycleRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertKPIXMatrixRequestDto} [insertKPIXMatrixRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixInsertKPIMatrix: async (insertKPIXMatrixRequestDto?: InsertKPIXMatrixRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/InsertKPIMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertKPIXMatrixRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertNextYearXMatrixRequestDto} [insertNextYearXMatrixRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixInsertNextYearXMatrix: async (insertNextYearXMatrixRequestDto?: InsertNextYearXMatrixRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/InsertNextYearXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertNextYearXMatrixRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertProjectXMatrixRequestDto} [insertProjectXMatrixRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixInsertProjectXMatrix: async (insertProjectXMatrixRequestDto?: InsertProjectXMatrixRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/InsertProjectXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertProjectXMatrixRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertXMatrixRequestDto} [insertXMatrixRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixInsertXMatrix: async (insertXMatrixRequestDto?: InsertXMatrixRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/InsertXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertXMatrixRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InserXMatrixDivisionRequestDto} [inserXMatrixDivisionRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixInsertXMatrixDivision: async (inserXMatrixDivisionRequestDto?: InserXMatrixDivisionRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/InsertXMatrixDivision`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inserXMatrixDivisionRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixLongTermGoalPicklist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/LongTermGoalPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAnnualGoalKPIRelationShipRequestDto} [updateAnnualGoalKPIRelationShipRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateAnnualGoalKPIRelationShip: async (updateAnnualGoalKPIRelationShipRequestDto?: UpdateAnnualGoalKPIRelationShipRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/UpdateAnnualGoalKPIRelationShip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAnnualGoalKPIRelationShipRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateGoalKPIRelationShipRequestDto} [updateGoalKPIRelationShipRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateGoalKPIRelationShip: async (updateGoalKPIRelationShipRequestDto?: UpdateGoalKPIRelationShipRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/UpdateGoalKPIRelationShip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGoalKPIRelationShipRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateGoalProjectRelationShipDto} [updateGoalProjectRelationShipDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateGoalProjectRelationShip: async (updateGoalProjectRelationShipDto?: UpdateGoalProjectRelationShipDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/UpdateGoalProjectRelationShip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGoalProjectRelationShipDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateKPIProjectRelationShipRequestDto} [updateKPIProjectRelationShipRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateKPIProjectRelationShip: async (updateKPIProjectRelationShipRequestDto?: UpdateKPIProjectRelationShipRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/UpdateKPIProjectRelationShip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateKPIProjectRelationShipRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateLongTermAnnualGoalRelationShipDto} [updateLongTermAnnualGoalRelationShipDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateLongTermAnnualGoalRelationShip: async (updateLongTermAnnualGoalRelationShipDto?: UpdateLongTermAnnualGoalRelationShipDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/UpdateLongTermAnnualGoalRelationShip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLongTermAnnualGoalRelationShipDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateLongTermGoalChecklistRequestDto} [updateLongTermGoalChecklistRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateLongTermGoalChecklist: async (updateLongTermGoalChecklistRequestDto?: UpdateLongTermGoalChecklistRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/UpdateLongTermGoalChecklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLongTermGoalChecklistRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProjectSecondaryProjectRelationShipRequestDto} [updateProjectSecondaryProjectRelationShipRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateProjectSecondaryProjectRelationShip: async (updateProjectSecondaryProjectRelationShipRequestDto?: UpdateProjectSecondaryProjectRelationShipRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/UpdateProjectSecondaryProjectRelationShip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectSecondaryProjectRelationShipRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProjectUserRelationShipRequestDto} [updateProjectUserRelationShipRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateProjectUserRelationShip: async (updateProjectUserRelationShipRequestDto?: UpdateProjectUserRelationShipRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/UpdateProjectUserRelationShip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectUserRelationShipRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProjectXMatrixRequestDto} [updateProjectXMatrixRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateProjectXMatrix: async (updateProjectXMatrixRequestDto?: UpdateProjectXMatrixRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/UpdateProjectXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectXMatrixRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateXMatrixCycleRequestDto} [updateXMatrixCycleRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateXMatrixCycle: async (updateXMatrixCycleRequestDto?: UpdateXMatrixCycleRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/UpdateXMatrixCycle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateXMatrixCycleRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateXMatrixByIDRequestDto} [updateXMatrixByIDRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateXMatrixData: async (updateXMatrixByIDRequestDto?: UpdateXMatrixByIDRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/UpdateXMatrixData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateXMatrixByIDRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateXMatrixStatus: async (xMatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/UpdateXMatrixStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Cookies required

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * XMatrixApi - functional programming interface
 * @export
 */
export const XMatrixApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = XMatrixApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {string} [xMatrixCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixDeleteAnnualGoalXMatrixRelation(annualGoalID?: string, xMatrixCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixDeleteAnnualGoalXMatrixRelation(annualGoalID, xMatrixCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixDeleteAnnualGoalXMatrixRelation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixDeleteDraftXMatrix(xMatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixDeleteDraftXMatrix(xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixDeleteDraftXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [kPIID] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixDeleteKPIRelationshipXMatrix(kPIID?: string, xMatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixDeleteKPIRelationshipXMatrix(kPIID, xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixDeleteKPIRelationshipXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {string} [xMatrixCode] 
         * @param {boolean} [deleteProject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixDeleteProjectXMatrixDraftRelation(projectID?: string, xMatrixCode?: string, deleteProject?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixDeleteProjectXMatrixDraftRelation(projectID, xMatrixCode, deleteProject, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixDeleteProjectXMatrixDraftRelation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {string} [xMatrixCode] 
         * @param {boolean} [deleteProject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixDeleteProjectXMatrixRelation(projectID?: string, xMatrixCode?: string, deleteProject?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixDeleteProjectXMatrixRelation(projectID, xMatrixCode, deleteProject, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixDeleteProjectXMatrixRelation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {string} [userID] 
         * @param {string} [relation] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixDeleteUserRelationshipXMatrix(projectID?: string, userID?: string, relation?: string, xMatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixDeleteUserRelationshipXMatrix(projectID, userID, relation, xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixDeleteUserRelationshipXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xMatrixCycleID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixDeleteXMatrixCycle(xMatrixCycleID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixDeleteXMatrixCycle(xMatrixCycleID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixDeleteXMatrixCycle']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetActiveXMatrixInfo(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetXMatrixInfoResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetActiveXMatrixInfo(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetActiveXMatrixInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetAnnualGoalPicklistXMatrix(xMatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnualGoalPicklistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetAnnualGoalPicklistXMatrix(xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetAnnualGoalPicklistXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetCommitteePicklistXMatrix(xMatrixID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommitteePicklistDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetCommitteePicklistXMatrix(xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetCommitteePicklistXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {XMatrixSecondLevelFilters} [xMatrixSecondLevelFilters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetDivisionDataXMatrix(xMatrixSecondLevelFilters?: XMatrixSecondLevelFilters, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<XMatrixSecondLevelDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetDivisionDataXMatrix(xMatrixSecondLevelFilters, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetDivisionDataXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetDivisionPicklistXMatrix(xMatrixID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDivisionPicklistDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetDivisionPicklistXMatrix(xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetDivisionPicklistXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetDivisionProjectCreationPicklist(xmatrixId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDivisionProjectCreationPicklistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetDivisionProjectCreationPicklist(xmatrixId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetDivisionProjectCreationPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetKPIXMAtrixPicklist(xMatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetKPIXMatrixPicklistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetKPIXMAtrixPicklist(xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetKPIXMAtrixPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetLongTermGoalChecklist(xMatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLongTermGoalChecklistResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetLongTermGoalChecklist(xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetLongTermGoalChecklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetLongTermGoalPicklistXMatrix(xMatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LongTermGoalPicklistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetLongTermGoalPicklistXMatrix(xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetLongTermGoalPicklistXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetNewDivisionXMatrixAnnualGoalPicklist(xmatrixId?: string, divisionID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNewDivisionXMatrixAnnualGoalPicklistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetNewDivisionXMatrixAnnualGoalPicklist(xmatrixId, divisionID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetNewDivisionXMatrixAnnualGoalPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetNewDivisionXMatrixLongTermGoalPicklist(xmatrixId?: string, divisionID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetXMatrixLongTermGoalPicklistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetNewDivisionXMatrixLongTermGoalPicklist(xmatrixId, divisionID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetNewDivisionXMatrixLongTermGoalPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetNewDivisionXMatrixProjectPicklist(xmatrixId?: string, divisionID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNewDivisionXMatrixProjectPicklistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetNewDivisionXMatrixProjectPicklist(xmatrixId, divisionID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetNewDivisionXMatrixProjectPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetNewXmatrixAnnualGoalPicklist(xmatrixId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNewXmatrixAnnualGoalPicklistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetNewXmatrixAnnualGoalPicklist(xmatrixId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetNewXmatrixAnnualGoalPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetNewXmatrixProjectPicklist(xmatrixId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNewXmatrixProjectPicklistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetNewXmatrixProjectPicklist(xmatrixId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetNewXmatrixProjectPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetProjectPicklistXMatrix(xMatrixID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectPicklistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetProjectPicklistXMatrix(xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetProjectPicklistXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetRootXMatrixDivisionList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRootXMatrixDivisionListResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetRootXMatrixDivisionList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetRootXMatrixDivisionList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetSponsorPicklist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSponsorPicklistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetSponsorPicklist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetSponsorPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {XMatrixFilters} [xMatrixFilters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetXMatrix(xMatrixFilters?: XMatrixFilters, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<XMatrixDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetXMatrix(xMatrixFilters, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [companyID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetXMatrixCycle(companyID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetXMatrixCycleResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetXMatrixCycle(companyID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetXMatrixCycle']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xMatrixCycleID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetXMatrixCycleByID(xMatrixCycleID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetXMatrixCycleByIDResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetXMatrixCycleByID(xMatrixCycleID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetXMatrixCycleByID']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetXMatrixData(xmatrixId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<XMatrixDataByIDResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetXMatrixData(xmatrixId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetXMatrixData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetXMatrixInfo(xmatrixId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetXMatrixInfoResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetXMatrixInfo(xmatrixId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetXMatrixInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetXMatrixPicklist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetXMatrixPicklistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetXMatrixPicklist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetXMatrixPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetXMatrixUsers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetXMatrixUsersResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetXMatrixUsers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetXMatrixUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetXmatrixDivisionParentProjectPicklist(divisionID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetXmatrixDivisionParentProjectPicklistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetXmatrixDivisionParentProjectPicklist(divisionID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetXmatrixDivisionParentProjectPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetXmatrixDivisionPicklist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetXmatrixDivisionPicklistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetXmatrixDivisionPicklist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetXmatrixDivisionPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertXmatrixCycleRequestDto} [insertXmatrixCycleRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixInserXMatrixCycle(insertXmatrixCycleRequestDto?: InsertXmatrixCycleRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixInserXMatrixCycle(insertXmatrixCycleRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixInserXMatrixCycle']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertKPIXMatrixRequestDto} [insertKPIXMatrixRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixInsertKPIMatrix(insertKPIXMatrixRequestDto?: InsertKPIXMatrixRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixInsertKPIMatrix(insertKPIXMatrixRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixInsertKPIMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertNextYearXMatrixRequestDto} [insertNextYearXMatrixRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixInsertNextYearXMatrix(insertNextYearXMatrixRequestDto?: InsertNextYearXMatrixRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsertNextYearXMatrixResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixInsertNextYearXMatrix(insertNextYearXMatrixRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixInsertNextYearXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertProjectXMatrixRequestDto} [insertProjectXMatrixRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixInsertProjectXMatrix(insertProjectXMatrixRequestDto?: InsertProjectXMatrixRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsertProjectXMatrixResponseControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixInsertProjectXMatrix(insertProjectXMatrixRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixInsertProjectXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertXMatrixRequestDto} [insertXMatrixRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixInsertXMatrix(insertXMatrixRequestDto?: InsertXMatrixRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsertXMatrixResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixInsertXMatrix(insertXMatrixRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixInsertXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InserXMatrixDivisionRequestDto} [inserXMatrixDivisionRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixInsertXMatrixDivision(inserXMatrixDivisionRequestDto?: InserXMatrixDivisionRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsertXMatrixDivisionResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixInsertXMatrixDivision(inserXMatrixDivisionRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixInsertXMatrixDivision']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixLongTermGoalPicklist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LongTermGoalPicklistResponseDtoListControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixLongTermGoalPicklist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixLongTermGoalPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateAnnualGoalKPIRelationShipRequestDto} [updateAnnualGoalKPIRelationShipRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixUpdateAnnualGoalKPIRelationShip(updateAnnualGoalKPIRelationShipRequestDto?: UpdateAnnualGoalKPIRelationShipRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateAnnualGoalKPIRelationShipResponseDtoControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixUpdateAnnualGoalKPIRelationShip(updateAnnualGoalKPIRelationShipRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixUpdateAnnualGoalKPIRelationShip']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateGoalKPIRelationShipRequestDto} [updateGoalKPIRelationShipRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixUpdateGoalKPIRelationShip(updateGoalKPIRelationShipRequestDto?: UpdateGoalKPIRelationShipRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixUpdateGoalKPIRelationShip(updateGoalKPIRelationShipRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixUpdateGoalKPIRelationShip']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateGoalProjectRelationShipDto} [updateGoalProjectRelationShipDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixUpdateGoalProjectRelationShip(updateGoalProjectRelationShipDto?: UpdateGoalProjectRelationShipDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixUpdateGoalProjectRelationShip(updateGoalProjectRelationShipDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixUpdateGoalProjectRelationShip']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateKPIProjectRelationShipRequestDto} [updateKPIProjectRelationShipRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixUpdateKPIProjectRelationShip(updateKPIProjectRelationShipRequestDto?: UpdateKPIProjectRelationShipRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixUpdateKPIProjectRelationShip(updateKPIProjectRelationShipRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixUpdateKPIProjectRelationShip']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateLongTermAnnualGoalRelationShipDto} [updateLongTermAnnualGoalRelationShipDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixUpdateLongTermAnnualGoalRelationShip(updateLongTermAnnualGoalRelationShipDto?: UpdateLongTermAnnualGoalRelationShipDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixUpdateLongTermAnnualGoalRelationShip(updateLongTermAnnualGoalRelationShipDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixUpdateLongTermAnnualGoalRelationShip']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateLongTermGoalChecklistRequestDto} [updateLongTermGoalChecklistRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixUpdateLongTermGoalChecklist(updateLongTermGoalChecklistRequestDto?: UpdateLongTermGoalChecklistRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixUpdateLongTermGoalChecklist(updateLongTermGoalChecklistRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixUpdateLongTermGoalChecklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateProjectSecondaryProjectRelationShipRequestDto} [updateProjectSecondaryProjectRelationShipRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixUpdateProjectSecondaryProjectRelationShip(updateProjectSecondaryProjectRelationShipRequestDto?: UpdateProjectSecondaryProjectRelationShipRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateProjectSecondaryProjectRelationShipResponseControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixUpdateProjectSecondaryProjectRelationShip(updateProjectSecondaryProjectRelationShipRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixUpdateProjectSecondaryProjectRelationShip']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateProjectUserRelationShipRequestDto} [updateProjectUserRelationShipRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixUpdateProjectUserRelationShip(updateProjectUserRelationShipRequestDto?: UpdateProjectUserRelationShipRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixUpdateProjectUserRelationShip(updateProjectUserRelationShipRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixUpdateProjectUserRelationShip']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateProjectXMatrixRequestDto} [updateProjectXMatrixRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixUpdateProjectXMatrix(updateProjectXMatrixRequestDto?: UpdateProjectXMatrixRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixUpdateProjectXMatrix(updateProjectXMatrixRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixUpdateProjectXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateXMatrixCycleRequestDto} [updateXMatrixCycleRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixUpdateXMatrixCycle(updateXMatrixCycleRequestDto?: UpdateXMatrixCycleRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixUpdateXMatrixCycle(updateXMatrixCycleRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixUpdateXMatrixCycle']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateXMatrixByIDRequestDto} [updateXMatrixByIDRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixUpdateXMatrixData(updateXMatrixByIDRequestDto?: UpdateXMatrixByIDRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixUpdateXMatrixData(updateXMatrixByIDRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixUpdateXMatrixData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixUpdateXMatrixStatus(xMatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectControllerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixUpdateXMatrixStatus(xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixUpdateXMatrixStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * XMatrixApi - factory interface
 * @export
 */
export const XMatrixApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = XMatrixApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {string} [xMatrixCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixDeleteAnnualGoalXMatrixRelation(annualGoalID?: string, xMatrixCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.xMatrixDeleteAnnualGoalXMatrixRelation(annualGoalID, xMatrixCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixDeleteDraftXMatrix(xMatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.xMatrixDeleteDraftXMatrix(xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [kPIID] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixDeleteKPIRelationshipXMatrix(kPIID?: string, xMatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.xMatrixDeleteKPIRelationshipXMatrix(kPIID, xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {string} [xMatrixCode] 
         * @param {boolean} [deleteProject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixDeleteProjectXMatrixDraftRelation(projectID?: string, xMatrixCode?: string, deleteProject?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.xMatrixDeleteProjectXMatrixDraftRelation(projectID, xMatrixCode, deleteProject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {string} [xMatrixCode] 
         * @param {boolean} [deleteProject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixDeleteProjectXMatrixRelation(projectID?: string, xMatrixCode?: string, deleteProject?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.xMatrixDeleteProjectXMatrixRelation(projectID, xMatrixCode, deleteProject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {string} [userID] 
         * @param {string} [relation] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixDeleteUserRelationshipXMatrix(projectID?: string, userID?: string, relation?: string, xMatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.xMatrixDeleteUserRelationshipXMatrix(projectID, userID, relation, xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xMatrixCycleID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixDeleteXMatrixCycle(xMatrixCycleID?: string, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.xMatrixDeleteXMatrixCycle(xMatrixCycleID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetActiveXMatrixInfo(options?: RawAxiosRequestConfig): AxiosPromise<GetXMatrixInfoResponseDtoControllerResponse> {
            return localVarFp.xMatrixGetActiveXMatrixInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetAnnualGoalPicklistXMatrix(xMatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<AnnualGoalPicklistResponseDtoListControllerResponse> {
            return localVarFp.xMatrixGetAnnualGoalPicklistXMatrix(xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetCommitteePicklistXMatrix(xMatrixID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<GetCommitteePicklistDtoListControllerResponse> {
            return localVarFp.xMatrixGetCommitteePicklistXMatrix(xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {XMatrixSecondLevelFilters} [xMatrixSecondLevelFilters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetDivisionDataXMatrix(xMatrixSecondLevelFilters?: XMatrixSecondLevelFilters, options?: RawAxiosRequestConfig): AxiosPromise<XMatrixSecondLevelDtoControllerResponse> {
            return localVarFp.xMatrixGetDivisionDataXMatrix(xMatrixSecondLevelFilters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetDivisionPicklistXMatrix(xMatrixID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<GetDivisionPicklistDtoListControllerResponse> {
            return localVarFp.xMatrixGetDivisionPicklistXMatrix(xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetDivisionProjectCreationPicklist(xmatrixId?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetDivisionProjectCreationPicklistResponseDtoListControllerResponse> {
            return localVarFp.xMatrixGetDivisionProjectCreationPicklist(xmatrixId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetKPIXMAtrixPicklist(xMatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetKPIXMatrixPicklistResponseDtoListControllerResponse> {
            return localVarFp.xMatrixGetKPIXMAtrixPicklist(xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetLongTermGoalChecklist(xMatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetLongTermGoalChecklistResponseDtoControllerResponse> {
            return localVarFp.xMatrixGetLongTermGoalChecklist(xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetLongTermGoalPicklistXMatrix(xMatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<LongTermGoalPicklistResponseDtoListControllerResponse> {
            return localVarFp.xMatrixGetLongTermGoalPicklistXMatrix(xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetNewDivisionXMatrixAnnualGoalPicklist(xmatrixId?: string, divisionID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetNewDivisionXMatrixAnnualGoalPicklistResponseDtoListControllerResponse> {
            return localVarFp.xMatrixGetNewDivisionXMatrixAnnualGoalPicklist(xmatrixId, divisionID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetNewDivisionXMatrixLongTermGoalPicklist(xmatrixId?: string, divisionID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetXMatrixLongTermGoalPicklistResponseDtoListControllerResponse> {
            return localVarFp.xMatrixGetNewDivisionXMatrixLongTermGoalPicklist(xmatrixId, divisionID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetNewDivisionXMatrixProjectPicklist(xmatrixId?: string, divisionID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetNewDivisionXMatrixProjectPicklistResponseDtoListControllerResponse> {
            return localVarFp.xMatrixGetNewDivisionXMatrixProjectPicklist(xmatrixId, divisionID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetNewXmatrixAnnualGoalPicklist(xmatrixId?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetNewXmatrixAnnualGoalPicklistResponseDtoListControllerResponse> {
            return localVarFp.xMatrixGetNewXmatrixAnnualGoalPicklist(xmatrixId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetNewXmatrixProjectPicklist(xmatrixId?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetNewXmatrixProjectPicklistResponseDtoListControllerResponse> {
            return localVarFp.xMatrixGetNewXmatrixProjectPicklist(xmatrixId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetProjectPicklistXMatrix(xMatrixID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<GetProjectPicklistResponseDtoListControllerResponse> {
            return localVarFp.xMatrixGetProjectPicklistXMatrix(xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetRootXMatrixDivisionList(options?: RawAxiosRequestConfig): AxiosPromise<GetRootXMatrixDivisionListResponseDtoListControllerResponse> {
            return localVarFp.xMatrixGetRootXMatrixDivisionList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetSponsorPicklist(options?: RawAxiosRequestConfig): AxiosPromise<GetSponsorPicklistResponseDtoListControllerResponse> {
            return localVarFp.xMatrixGetSponsorPicklist(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {XMatrixFilters} [xMatrixFilters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXMatrix(xMatrixFilters?: XMatrixFilters, options?: RawAxiosRequestConfig): AxiosPromise<XMatrixDtoControllerResponse> {
            return localVarFp.xMatrixGetXMatrix(xMatrixFilters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [companyID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXMatrixCycle(companyID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetXMatrixCycleResponseDtoListControllerResponse> {
            return localVarFp.xMatrixGetXMatrixCycle(companyID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xMatrixCycleID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXMatrixCycleByID(xMatrixCycleID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetXMatrixCycleByIDResponseDtoControllerResponse> {
            return localVarFp.xMatrixGetXMatrixCycleByID(xMatrixCycleID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXMatrixData(xmatrixId?: string, options?: RawAxiosRequestConfig): AxiosPromise<XMatrixDataByIDResponseDtoControllerResponse> {
            return localVarFp.xMatrixGetXMatrixData(xmatrixId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXMatrixInfo(xmatrixId?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetXMatrixInfoResponseDtoControllerResponse> {
            return localVarFp.xMatrixGetXMatrixInfo(xmatrixId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXMatrixPicklist(options?: RawAxiosRequestConfig): AxiosPromise<GetXMatrixPicklistResponseDtoListControllerResponse> {
            return localVarFp.xMatrixGetXMatrixPicklist(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXMatrixUsers(options?: RawAxiosRequestConfig): AxiosPromise<GetXMatrixUsersResponseDtoListControllerResponse> {
            return localVarFp.xMatrixGetXMatrixUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXmatrixDivisionParentProjectPicklist(divisionID?: string, options?: RawAxiosRequestConfig): AxiosPromise<GetXmatrixDivisionParentProjectPicklistResponseDtoListControllerResponse> {
            return localVarFp.xMatrixGetXmatrixDivisionParentProjectPicklist(divisionID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXmatrixDivisionPicklist(options?: RawAxiosRequestConfig): AxiosPromise<GetXmatrixDivisionPicklistResponseDtoListControllerResponse> {
            return localVarFp.xMatrixGetXmatrixDivisionPicklist(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertXmatrixCycleRequestDto} [insertXmatrixCycleRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixInserXMatrixCycle(insertXmatrixCycleRequestDto?: InsertXmatrixCycleRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.xMatrixInserXMatrixCycle(insertXmatrixCycleRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertKPIXMatrixRequestDto} [insertKPIXMatrixRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixInsertKPIMatrix(insertKPIXMatrixRequestDto?: InsertKPIXMatrixRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<BooleanControllerResponse> {
            return localVarFp.xMatrixInsertKPIMatrix(insertKPIXMatrixRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertNextYearXMatrixRequestDto} [insertNextYearXMatrixRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixInsertNextYearXMatrix(insertNextYearXMatrixRequestDto?: InsertNextYearXMatrixRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<InsertNextYearXMatrixResponseDtoControllerResponse> {
            return localVarFp.xMatrixInsertNextYearXMatrix(insertNextYearXMatrixRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertProjectXMatrixRequestDto} [insertProjectXMatrixRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixInsertProjectXMatrix(insertProjectXMatrixRequestDto?: InsertProjectXMatrixRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<InsertProjectXMatrixResponseControllerResponse> {
            return localVarFp.xMatrixInsertProjectXMatrix(insertProjectXMatrixRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertXMatrixRequestDto} [insertXMatrixRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixInsertXMatrix(insertXMatrixRequestDto?: InsertXMatrixRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<InsertXMatrixResponseDtoControllerResponse> {
            return localVarFp.xMatrixInsertXMatrix(insertXMatrixRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InserXMatrixDivisionRequestDto} [inserXMatrixDivisionRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixInsertXMatrixDivision(inserXMatrixDivisionRequestDto?: InserXMatrixDivisionRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<InsertXMatrixDivisionResponseDtoControllerResponse> {
            return localVarFp.xMatrixInsertXMatrixDivision(inserXMatrixDivisionRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixLongTermGoalPicklist(options?: RawAxiosRequestConfig): AxiosPromise<LongTermGoalPicklistResponseDtoListControllerResponse> {
            return localVarFp.xMatrixLongTermGoalPicklist(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAnnualGoalKPIRelationShipRequestDto} [updateAnnualGoalKPIRelationShipRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateAnnualGoalKPIRelationShip(updateAnnualGoalKPIRelationShipRequestDto?: UpdateAnnualGoalKPIRelationShipRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<UpdateAnnualGoalKPIRelationShipResponseDtoControllerResponse> {
            return localVarFp.xMatrixUpdateAnnualGoalKPIRelationShip(updateAnnualGoalKPIRelationShipRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateGoalKPIRelationShipRequestDto} [updateGoalKPIRelationShipRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateGoalKPIRelationShip(updateGoalKPIRelationShipRequestDto?: UpdateGoalKPIRelationShipRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.xMatrixUpdateGoalKPIRelationShip(updateGoalKPIRelationShipRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateGoalProjectRelationShipDto} [updateGoalProjectRelationShipDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateGoalProjectRelationShip(updateGoalProjectRelationShipDto?: UpdateGoalProjectRelationShipDto, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.xMatrixUpdateGoalProjectRelationShip(updateGoalProjectRelationShipDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateKPIProjectRelationShipRequestDto} [updateKPIProjectRelationShipRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateKPIProjectRelationShip(updateKPIProjectRelationShipRequestDto?: UpdateKPIProjectRelationShipRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.xMatrixUpdateKPIProjectRelationShip(updateKPIProjectRelationShipRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateLongTermAnnualGoalRelationShipDto} [updateLongTermAnnualGoalRelationShipDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateLongTermAnnualGoalRelationShip(updateLongTermAnnualGoalRelationShipDto?: UpdateLongTermAnnualGoalRelationShipDto, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.xMatrixUpdateLongTermAnnualGoalRelationShip(updateLongTermAnnualGoalRelationShipDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateLongTermGoalChecklistRequestDto} [updateLongTermGoalChecklistRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateLongTermGoalChecklist(updateLongTermGoalChecklistRequestDto?: UpdateLongTermGoalChecklistRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.xMatrixUpdateLongTermGoalChecklist(updateLongTermGoalChecklistRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateProjectSecondaryProjectRelationShipRequestDto} [updateProjectSecondaryProjectRelationShipRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateProjectSecondaryProjectRelationShip(updateProjectSecondaryProjectRelationShipRequestDto?: UpdateProjectSecondaryProjectRelationShipRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<UpdateProjectSecondaryProjectRelationShipResponseControllerResponse> {
            return localVarFp.xMatrixUpdateProjectSecondaryProjectRelationShip(updateProjectSecondaryProjectRelationShipRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateProjectUserRelationShipRequestDto} [updateProjectUserRelationShipRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateProjectUserRelationShip(updateProjectUserRelationShipRequestDto?: UpdateProjectUserRelationShipRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.xMatrixUpdateProjectUserRelationShip(updateProjectUserRelationShipRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateProjectXMatrixRequestDto} [updateProjectXMatrixRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateProjectXMatrix(updateProjectXMatrixRequestDto?: UpdateProjectXMatrixRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.xMatrixUpdateProjectXMatrix(updateProjectXMatrixRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateXMatrixCycleRequestDto} [updateXMatrixCycleRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateXMatrixCycle(updateXMatrixCycleRequestDto?: UpdateXMatrixCycleRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.xMatrixUpdateXMatrixCycle(updateXMatrixCycleRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateXMatrixByIDRequestDto} [updateXMatrixByIDRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateXMatrixData(updateXMatrixByIDRequestDto?: UpdateXMatrixByIDRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.xMatrixUpdateXMatrixData(updateXMatrixByIDRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateXMatrixStatus(xMatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ObjectControllerResponse> {
            return localVarFp.xMatrixUpdateXMatrixStatus(xMatrixID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * XMatrixApi - object-oriented interface
 * @export
 * @class XMatrixApi
 * @extends {BaseAPI}
 */
export class XMatrixApi extends BaseAPI {
    /**
     * 
     * @param {string} [annualGoalID] 
     * @param {string} [xMatrixCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixDeleteAnnualGoalXMatrixRelation(annualGoalID?: string, xMatrixCode?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixDeleteAnnualGoalXMatrixRelation(annualGoalID, xMatrixCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixDeleteDraftXMatrix(xMatrixID?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixDeleteDraftXMatrix(xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [kPIID] 
     * @param {string} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixDeleteKPIRelationshipXMatrix(kPIID?: string, xMatrixID?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixDeleteKPIRelationshipXMatrix(kPIID, xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {string} [xMatrixCode] 
     * @param {boolean} [deleteProject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixDeleteProjectXMatrixDraftRelation(projectID?: string, xMatrixCode?: string, deleteProject?: boolean, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixDeleteProjectXMatrixDraftRelation(projectID, xMatrixCode, deleteProject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {string} [xMatrixCode] 
     * @param {boolean} [deleteProject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixDeleteProjectXMatrixRelation(projectID?: string, xMatrixCode?: string, deleteProject?: boolean, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixDeleteProjectXMatrixRelation(projectID, xMatrixCode, deleteProject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {string} [userID] 
     * @param {string} [relation] 
     * @param {string} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixDeleteUserRelationshipXMatrix(projectID?: string, userID?: string, relation?: string, xMatrixID?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixDeleteUserRelationshipXMatrix(projectID, userID, relation, xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xMatrixCycleID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixDeleteXMatrixCycle(xMatrixCycleID?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixDeleteXMatrixCycle(xMatrixCycleID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetActiveXMatrixInfo(options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetActiveXMatrixInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetAnnualGoalPicklistXMatrix(xMatrixID?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetAnnualGoalPicklistXMatrix(xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetCommitteePicklistXMatrix(xMatrixID?: string | null, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetCommitteePicklistXMatrix(xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {XMatrixSecondLevelFilters} [xMatrixSecondLevelFilters] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetDivisionDataXMatrix(xMatrixSecondLevelFilters?: XMatrixSecondLevelFilters, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetDivisionDataXMatrix(xMatrixSecondLevelFilters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetDivisionPicklistXMatrix(xMatrixID?: string | null, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetDivisionPicklistXMatrix(xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xmatrixId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetDivisionProjectCreationPicklist(xmatrixId?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetDivisionProjectCreationPicklist(xmatrixId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetKPIXMAtrixPicklist(xMatrixID?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetKPIXMAtrixPicklist(xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetLongTermGoalChecklist(xMatrixID?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetLongTermGoalChecklist(xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetLongTermGoalPicklistXMatrix(xMatrixID?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetLongTermGoalPicklistXMatrix(xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xmatrixId] 
     * @param {string} [divisionID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetNewDivisionXMatrixAnnualGoalPicklist(xmatrixId?: string, divisionID?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetNewDivisionXMatrixAnnualGoalPicklist(xmatrixId, divisionID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xmatrixId] 
     * @param {string} [divisionID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetNewDivisionXMatrixLongTermGoalPicklist(xmatrixId?: string, divisionID?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetNewDivisionXMatrixLongTermGoalPicklist(xmatrixId, divisionID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xmatrixId] 
     * @param {string} [divisionID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetNewDivisionXMatrixProjectPicklist(xmatrixId?: string, divisionID?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetNewDivisionXMatrixProjectPicklist(xmatrixId, divisionID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xmatrixId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetNewXmatrixAnnualGoalPicklist(xmatrixId?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetNewXmatrixAnnualGoalPicklist(xmatrixId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xmatrixId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetNewXmatrixProjectPicklist(xmatrixId?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetNewXmatrixProjectPicklist(xmatrixId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetProjectPicklistXMatrix(xMatrixID?: string | null, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetProjectPicklistXMatrix(xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetRootXMatrixDivisionList(options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetRootXMatrixDivisionList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetSponsorPicklist(options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetSponsorPicklist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {XMatrixFilters} [xMatrixFilters] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetXMatrix(xMatrixFilters?: XMatrixFilters, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetXMatrix(xMatrixFilters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [companyID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetXMatrixCycle(companyID?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetXMatrixCycle(companyID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xMatrixCycleID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetXMatrixCycleByID(xMatrixCycleID?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetXMatrixCycleByID(xMatrixCycleID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xmatrixId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetXMatrixData(xmatrixId?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetXMatrixData(xmatrixId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xmatrixId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetXMatrixInfo(xmatrixId?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetXMatrixInfo(xmatrixId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetXMatrixPicklist(options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetXMatrixPicklist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetXMatrixUsers(options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetXMatrixUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [divisionID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetXmatrixDivisionParentProjectPicklist(divisionID?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetXmatrixDivisionParentProjectPicklist(divisionID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetXmatrixDivisionPicklist(options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetXmatrixDivisionPicklist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertXmatrixCycleRequestDto} [insertXmatrixCycleRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixInserXMatrixCycle(insertXmatrixCycleRequestDto?: InsertXmatrixCycleRequestDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixInserXMatrixCycle(insertXmatrixCycleRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertKPIXMatrixRequestDto} [insertKPIXMatrixRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixInsertKPIMatrix(insertKPIXMatrixRequestDto?: InsertKPIXMatrixRequestDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixInsertKPIMatrix(insertKPIXMatrixRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertNextYearXMatrixRequestDto} [insertNextYearXMatrixRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixInsertNextYearXMatrix(insertNextYearXMatrixRequestDto?: InsertNextYearXMatrixRequestDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixInsertNextYearXMatrix(insertNextYearXMatrixRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertProjectXMatrixRequestDto} [insertProjectXMatrixRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixInsertProjectXMatrix(insertProjectXMatrixRequestDto?: InsertProjectXMatrixRequestDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixInsertProjectXMatrix(insertProjectXMatrixRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertXMatrixRequestDto} [insertXMatrixRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixInsertXMatrix(insertXMatrixRequestDto?: InsertXMatrixRequestDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixInsertXMatrix(insertXMatrixRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InserXMatrixDivisionRequestDto} [inserXMatrixDivisionRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixInsertXMatrixDivision(inserXMatrixDivisionRequestDto?: InserXMatrixDivisionRequestDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixInsertXMatrixDivision(inserXMatrixDivisionRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixLongTermGoalPicklist(options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixLongTermGoalPicklist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateAnnualGoalKPIRelationShipRequestDto} [updateAnnualGoalKPIRelationShipRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixUpdateAnnualGoalKPIRelationShip(updateAnnualGoalKPIRelationShipRequestDto?: UpdateAnnualGoalKPIRelationShipRequestDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixUpdateAnnualGoalKPIRelationShip(updateAnnualGoalKPIRelationShipRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateGoalKPIRelationShipRequestDto} [updateGoalKPIRelationShipRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixUpdateGoalKPIRelationShip(updateGoalKPIRelationShipRequestDto?: UpdateGoalKPIRelationShipRequestDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixUpdateGoalKPIRelationShip(updateGoalKPIRelationShipRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateGoalProjectRelationShipDto} [updateGoalProjectRelationShipDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixUpdateGoalProjectRelationShip(updateGoalProjectRelationShipDto?: UpdateGoalProjectRelationShipDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixUpdateGoalProjectRelationShip(updateGoalProjectRelationShipDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateKPIProjectRelationShipRequestDto} [updateKPIProjectRelationShipRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixUpdateKPIProjectRelationShip(updateKPIProjectRelationShipRequestDto?: UpdateKPIProjectRelationShipRequestDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixUpdateKPIProjectRelationShip(updateKPIProjectRelationShipRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateLongTermAnnualGoalRelationShipDto} [updateLongTermAnnualGoalRelationShipDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixUpdateLongTermAnnualGoalRelationShip(updateLongTermAnnualGoalRelationShipDto?: UpdateLongTermAnnualGoalRelationShipDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixUpdateLongTermAnnualGoalRelationShip(updateLongTermAnnualGoalRelationShipDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateLongTermGoalChecklistRequestDto} [updateLongTermGoalChecklistRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixUpdateLongTermGoalChecklist(updateLongTermGoalChecklistRequestDto?: UpdateLongTermGoalChecklistRequestDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixUpdateLongTermGoalChecklist(updateLongTermGoalChecklistRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateProjectSecondaryProjectRelationShipRequestDto} [updateProjectSecondaryProjectRelationShipRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixUpdateProjectSecondaryProjectRelationShip(updateProjectSecondaryProjectRelationShipRequestDto?: UpdateProjectSecondaryProjectRelationShipRequestDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixUpdateProjectSecondaryProjectRelationShip(updateProjectSecondaryProjectRelationShipRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateProjectUserRelationShipRequestDto} [updateProjectUserRelationShipRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixUpdateProjectUserRelationShip(updateProjectUserRelationShipRequestDto?: UpdateProjectUserRelationShipRequestDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixUpdateProjectUserRelationShip(updateProjectUserRelationShipRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateProjectXMatrixRequestDto} [updateProjectXMatrixRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixUpdateProjectXMatrix(updateProjectXMatrixRequestDto?: UpdateProjectXMatrixRequestDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixUpdateProjectXMatrix(updateProjectXMatrixRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateXMatrixCycleRequestDto} [updateXMatrixCycleRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixUpdateXMatrixCycle(updateXMatrixCycleRequestDto?: UpdateXMatrixCycleRequestDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixUpdateXMatrixCycle(updateXMatrixCycleRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateXMatrixByIDRequestDto} [updateXMatrixByIDRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixUpdateXMatrixData(updateXMatrixByIDRequestDto?: UpdateXMatrixByIDRequestDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixUpdateXMatrixData(updateXMatrixByIDRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixUpdateXMatrixStatus(xMatrixID?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixUpdateXMatrixStatus(xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }
}



