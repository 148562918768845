import { Form } from 'antd';
import { Component, useState } from 'react';
import './projectTimePlanning.scss';

import {
  EditOutlined,
  FilterOutlined,
  MinusSquareOutlined,
  SaveOutlined,
  SearchOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Divider,
  Input,
  InputNumber,
  message,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MatrixService from '../../services/matrix/matrixService';
import ResourceWorkloadService from '../../services/pages/resourceWorkloadServices';
import XmatrixDropDownFilter from '../shared/components/xmatrixDropdownFilter/xmatrixDropdownFilter';
import TableLayout from '../shared/tableLayout';
import { projectTimePlanningColums, workloadColumnNamesValues } from '../shared/utils/constants';
import { capitalizeFirstLetter, numberWithCommas } from '../shared/utils/functions';
import { notifyMessages } from '../shared/utils/notifyMessages';

const { Text } = Typography;
const { Option } = Select;

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
  let isEditable = false;

  if (record) {
    let startDate = record ? moment(record.StartDate, 'YYYY-MM') : '';
    let endDate = record ? moment(record.EndDate, 'YYYY-MM') : '';
    let month = record ? moment(dataIndex, 'YYYY-MM') : '';

    isEditable = month >= startDate && month <= endDate ? false : true;
  }

  const inputNode = (
    <InputNumber
      min={0}
      decimalSeparator=","
      precision="2"
      disabled={isEditable}
    />
  );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTable = (props) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    setSearchText('');
  };

  const isEditing = (record) => record.key === editingKey;

  const isEditableRow = (record) => (record.Name === workloadColumnNamesValues.consuntivo ? true : false);

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key, record) => {
    try {
      const values = await form.getFieldsValue(null, (meta) => meta.touched);
      props.updateRow(values, record);
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const mergeCells = (text, data, key, index) => {
    // Is the data in the previous row the same?
    if (index !== 0 && text === data[index - 1][key]) {
      return 0;
    }
    let rowSpan = 1;
    // Determine whether the next line is equal
    for (let i = index + 1; i < data.length; i++) {
      if (text !== data[i][key]) {
        break;
      }
      rowSpan++;
    }
    return rowSpan;
  };

  const renderActionColumn = (record) => {
    if (isEditableRow(record)) {
      const editable = isEditing(record);
      return editable ? (
        <span>
          {props.loadingIcon && (
            <SyncOutlined
              style={{ color: '#0f87dc', marginRight: 5 }}
              spin
            />
          )}
          <Typography.Link onClick={() => save(record.key, record)}>
            <SaveOutlined style={{ marginRight: 5 }} />
            {props.t('buttons.salva')}
          </Typography.Link>
          <Divider
            type="vertical"
            style={{ borderLeft: '1px solid rgb(183 166 166)' }}
          />
          <Typography.Link onClick={() => cancel()}>{props.t('buttons.chiudi')}</Typography.Link>
        </span>
      ) : (
        <Typography.Link
          disabled={editingKey !== ''}
          onClick={() => edit(record)}>
          <EditOutlined />
        </Typography.Link>
      );
    }
  };

  const renderedColumns = () => {
    let columns = [];

    const tabelData = props.propsData;

    if (tabelData && tabelData.length > 0) {
      let firstRow = tabelData[0];

      for (const col in firstRow) {
        if (col === projectTimePlanningColums.projectName) {
          columns.unshift({
            ...getColumnSearchProps(col),
            dataIndex: col,
            width: '10%',
            fixed: 'left',
            className: 'project',
            // title: 'Name',
            editable: false,
            ellipsis: {
              showTitle: false,
            },
            render: (text, record, index) => {
              let val = (
                <div>
                  <Tooltip title={text !== null ? text : ''}>
                    {' '}
                    {text !== null ? (
                      <Link to={{ pathname: `/progetti/id/${record.ProjectID}` }}> {record.ProjectName} </Link>
                    ) : (
                      ''
                    )}
                  </Tooltip>
                </div>
              );
              const obj = {
                children: val,
                props: {},
              };
              obj.props.rowSpan = mergeCells(text, props.propsData, col, index);
              obj.props.className = 'colNameBB';
              return obj;
            },
          });
        } else if (col === projectTimePlanningColums.fullName) {
          columns.push({
            dataIndex: col,
            width: '8%',
            title: '',
            editable: false,
            ellipsis: {
              showTitle: false,
            },
            render: (text, row, index) => {
              let val = <Tooltip title={text}>{text}</Tooltip>;
              const obj = {
                children: val,
                props: {},
              };
              if (index % 3 === 0) {
                obj.props.rowSpan = 3;
                obj.props.className = 'colNameBB';
              } else {
                obj.props.rowSpan = 0;
              }
              return obj;
            },
          });
        } else if (col === projectTimePlanningColums.name) {
          columns.push({
            dataIndex: col,
            width: '8%',
            title: '',
            editable: false,
            ellipsis: {
              showTitle: false,
            },
            render(text, record) {
              return {
                props: {
                  style: { borderBottom: record.Name === projectTimePlanningColums.budget ? '4px solid #2f4897' : '' },
                },
                // children: (<Tooltip  title={text}><Text>{text}</Text></Tooltip>)
                children: (
                  <Tooltip title={props.t(`budgetTypes.${text}`)}>
                    <Text>{props.t(`budgetTypes.${text}`)}</Text>
                  </Tooltip>
                ),
              };
            },
          });
        } else if (
          col === projectTimePlanningColums.userID ||
          col === projectTimePlanningColums.key ||
          col === projectTimePlanningColums.projectID ||
          col === projectTimePlanningColums.startDate ||
          col === projectTimePlanningColums.endDate
        ) {
          continue;
        } else if (col === projectTimePlanningColums.total) {
          columns.push({
            align: 'right',
            dataIndex: col,
            width: '6%',
            className: 'total',
            // title: col,
            title: `${props.t('general.totale')}`,
            editable: false,
            render(text, record) {
              return {
                props: {
                  style: {
                    borderBottom: record.Name === projectTimePlanningColums.budget ? '4px solid #2f4897' : '',
                    background: parseInt(text) < 0 ? '#ff6b6b' : '',
                  },
                },
                children: (
                  <Text
                    strong
                    style={{ color: parseInt(text) < 0 ? '#000000' : '' }}>
                    {numberWithCommas(text)}
                  </Text>
                ),
              };
            },
          });
        } else {
          let month = moment(col).format('MMM YYYY');
          columns.push({
            align: 'right',
            dataIndex: col,
            title: capitalizeFirstLetter(month),
            editable: true,
            width: '6%',
            render(text, record) {
              return {
                props: {
                  style: {
                    borderBottom: record.Name === projectTimePlanningColums.budget ? '4px solid #2f4897' : '',
                    background: parseInt(text) < 0 ? '#ff6b6b' : '',
                  },
                },
                children: <Text style={{ color: parseInt(text) < 0 ? '#000000' : '' }}>{numberWithCommas(text)}</Text>,
              };
            },
          });
        }
      }

      columns.push({
        title: '',
        dataIndex: 'operation',
        width: '10%',
        className: 'operation',
        render(text, record) {
          return {
            props: {
              style: { borderBottom: record.Name === projectTimePlanningColums.budget ? '4px solid #2f4897' : '' },
            },
            children: renderActionColumn(record),
          };
        },
      });

      return columns;
    }
    return columns;
  };

  const mergedColumns = renderedColumns().map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'number',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form
      form={form}
      component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        size="small"
        dataSource={props.propsData}
        columns={mergedColumns}
        pagination={false}
        scroll={{ x: 'calc(700px + 50%)' }}
        loading={props.loadingData || props.loadingTotal}
      />
    </Form>
  );
};

class ProjectTimePlanning extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
      loadingData: false,
      projectPicklist: null,
      projectTimePlanningData: null,
      selectedYear: moment().format('YYYY'),
      selectedProjectId: null,
      clearFilters: false,
      selectedXmatrixValue: null,
      activeXmatrixInfo: null,
      resetXmatrixFilter: false,
    };
  }

  componentWillMount() {
    this.retrieveComponentData();
  }

  retrieveComponentData = async () => {
    await this.getActiveXmatrix();
    await this.retrieveProjectFilterData();
  };

  async getProjectTimePlanning() {
    const { selectedYear, selectedProjectId, selectedXmatrixValue, activeXmatrixInfo } = this.state;

    let xmatrix = selectedXmatrixValue ? selectedXmatrixValue : activeXmatrixInfo?.xMatrixID;

    const { userData } = this.props;
    let currentYear = moment().format('YYYY');
    let formatedYear = selectedYear ? moment(selectedYear).format('YYYY') : currentYear;
    this.setState({ loadingData: true });

    if (userData !== undefined && userData.hasOwnProperty('userId')) {
      if (xmatrix) {
        await ResourceWorkloadService.getProjectTimePlanning(formatedYear, userData.userId, selectedProjectId, xmatrix)
          .then((response) => response.data)
          .then((resp) => {
            if (resp.success) {
              resp.responseObject.value.map((item, index) => {
                let total = 0;
                for (const col in item) {
                  if (
                    col !== projectTimePlanningColums.projectName &&
                    col !== projectTimePlanningColums.projectID &&
                    col !== projectTimePlanningColums.userID &&
                    col !== projectTimePlanningColums.fullName &&
                    col !== projectTimePlanningColums.name &&
                    col !== projectTimePlanningColums.members &&
                    col !== projectTimePlanningColums.startDate &&
                    col !== projectTimePlanningColums.endDate
                  ) {
                    total += item[col];
                  }
                }
                item['Totale'] = total;
                item['key'] = index.toString();
              });

              this.setState({ projectTimePlanningData: resp.responseObject.value });
              this.setState({ loadingData: false });
            } else {
              message.error(notifyMessages.retrieveFailed);
              this.setState({ loadingData: false });
            }
          })
          .catch((error) => {
            message.error(notifyMessages.retrieveFailed);
            this.setState({ loadingData: false });
          });
      }
    }
  }

  async retrieveProjectFilterData() {
    const { userData } = this.props;
    const { selectedXmatrixValue, activeXmatrixInfo } = this.state;
    let xmatrix = selectedXmatrixValue ? selectedXmatrixValue : activeXmatrixInfo?.xMatrixID;
    if (userData !== undefined && userData.hasOwnProperty('userId')) {
      if (xmatrix) {
        await ResourceWorkloadService.getProjectFilterData(userData.userId, xmatrix)
          .then((response) => response.data)
          .then((resp) => {
            if (resp.success) {
              this.setState({ projectPicklist: resp.responseObject.value });
            } else {
            }
          })
          .catch((error) => {});
      }
    }
  }

  async getActiveXmatrix() {
    this.setState({ resetXmatrixFilter: true });
    await MatrixService.getActiveXmatrix()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          this.setState({ activeXmatrixInfo: respData }, () => {
            this.getProjectTimePlanning();
            this.setState({ resetXmatrixFilter: false });
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  updateProjectTimePlanning = (values, record) => {
    let payload = {};
    let monthsArray = [];

    payload['userID'] = record.UserID;
    payload['projectID'] = record.ProjectID;

    if (values) {
      for (const item in values) {
        monthsArray.push({ referencePeriod: item, value: values[item] });
      }
    } else {
      monthsArray = [];
    }

    payload['months'] = monthsArray;

    this.setState({ loadingButton: true });
    ResourceWorkloadService.updateTimePlanning(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          this.setState({ loadingButton: false });
          this.getProjectTimePlanning();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  onYearChange = (year) => {
    this.setState({ selectedYear: year });
  };

  onProjectChange = (option) => {
    this.setState({ selectedProjectId: option });
  };

  onFilter = () => {
    this.setState({ clearFilters: false }, () => {
      this.getProjectTimePlanning();
    });
  };

  onClearFilter = () => {
    let currentYear = moment().format('YYYY');
    this.setState(
      { selectedYear: currentYear, selectedProjectId: null, selectedXmatrixValue: null, clearFilters: true },
      () => {
        this.setState({ resetXmatrixFilter: true });
        this.retrieveComponentData();
      },
    );
  };

  onXmatrixSelect = (selectedXmatrix) => {
    this.setState({ selectedXmatrixValue: selectedXmatrix.value });
  };

  render() {
    const {
      loadingData,
      loadingButton,
      projectTimePlanningData,
      selectedYear,
      selectedProjectId,
      projectPicklist,
      resetXmatrixFilter,
      activeXmatrixInfo,
    } = this.state;
    let t = this.props.t;

    return (
      <TableLayout title={t('consuntivazionePage.consutivazioneRisorseProgetti')}>
        <TableLayout.Actions>
          <DatePicker
            placeholder={t('general.anno')}
            picker="year"
            value={moment(selectedYear)}
            format="YYYY"
            onChange={(date) => this.onYearChange(date)}
          />

          <Select
            value={selectedProjectId}
            showSearch
            allowClear
            placeholder={t('general.progetto')}
            optionFilterProp="children"
            onChange={(option) => this.onProjectChange(option)}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {projectPicklist &&
              projectPicklist.map((item) => (
                <Option
                  value={item.projectID}
                  key={item.projectID}>
                  {item.name}
                </Option>
              ))}
          </Select>

          {!resetXmatrixFilter && activeXmatrixInfo && (
            <XmatrixDropDownFilter
              onXmatrixSelect={this.onXmatrixSelect}
              activeXmatrixInfo={activeXmatrixInfo}
              t={t}
            />
          )}

          <Tooltip title={t('general.filter')}>
            <Button
              icon={<FilterOutlined />}
              onClick={() => this.onFilter()}
              disabled={!selectedYear && !selectedProjectId}
            />
          </Tooltip>
          <Tooltip title={t('general.clearFilters')}>
            <Button
              icon={<MinusSquareOutlined />}
              onClick={() => this.onClearFilter()}
              disabled={selectedYear || selectedProjectId ? false : true}
            />
          </Tooltip>

          {this.props.changeVisualizationButton}
        </TableLayout.Actions>
        <TableLayout.Content>
          <EditableTable
            propsData={projectTimePlanningData}
            updateRow={this.updateProjectTimePlanning}
            loadingIcon={loadingButton}
            isTotal={false}
            loadingData={loadingData}
            t={t}
          />
        </TableLayout.Content>
      </TableLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(ProjectTimePlanning));
