import { SyncOutlined } from '@ant-design/icons';
import { Col, Form, Popover, Radio, Row } from 'antd';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formItemLayout } from '../../shared/utils/constants';
import '../matrix.scss';

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

interface RelationTableProps {
  rowList: any[];
  colList: any[];
  relationList: any[];
  rowItemId: string;
  colItemId: string;
  rowSelectionIds?: string[];
  colSelectionIds?: string[];
  direction?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
  origin?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
  onUpdateRelation: (value: any, rowId: string, colId: string) => void;
}

const RelationTable: React.FC<RelationTableProps> = ({
  rowList,
  colList,
  relationList,
  rowItemId,
  colItemId,
  rowSelectionIds,
  colSelectionIds,
  direction,
  origin,
  onUpdateRelation,
}) => {
  const [loadingIcon, setLoadingIcon] = useState(false);
  const { t } = useTranslation();

  const onRelationCellForm = (text: string, rowId: string, colId: string) => {
    return (
      <Form
        layout="vertical"
        {...formItemLayout}
        initialValues={{ correlationCoefficient: text }}
        onValuesChange={(value) => onUpdateRelation(value, rowId, colId)}>
        <Row gutter={{ lg: 24 }}>
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}>
            <Form.Item name="correlationCoefficient">
              <Radio.Group>
                <Radio
                  style={radioStyle}
                  key="1"
                  value={1}>
                  {t('xMatrixPage.primaria')}
                </Radio>
                <Radio
                  style={radioStyle}
                  key="2"
                  value={2}>
                  {t('xMatrixPage.secondaria')}
                </Radio>
                <Radio
                  style={radioStyle}
                  key="3"
                  value={3}>
                  {t('xMatrixPage.nessuna')}
                </Radio>
              </Radio.Group>
            </Form.Item>
            {loadingIcon && (
              <SyncOutlined
                style={{ color: '#0f87dc', marginRight: 5, display: 'inherit' }}
                spin
              />
            )}
          </Col>
        </Row>
      </Form>
    );
  };

  const renderCell = (cell: any) => {
    return (
      <Popover
        key={cell.colId + '-' + cell.rowId}
        content={onRelationCellForm(cell.code, cell.rowId, cell.colId)}
        trigger="click">
        <div
          className={classNames(
            'selection-container',
            { 'is-selected': cell.selected },
            { 'is-passing-through': cell.passingThrough },
          )}>
          {cell.code === 1 && (
            <span
              className="relationFull"
              style={{ cursor: 'pointer' }}></span>
          )}
          {cell.code === 2 && (
            <span
              className="relationPartial"
              style={{ cursor: 'pointer' }}></span>
          )}
          {cell.code === 3 && (
            <span
              className="noRelation"
              style={{ cursor: 'pointer' }}></span>
          )}
          {cell.code === 0 && cell.selected && <span></span>}
          {(cell.leftLine || cell.rightLine) && (
            <i
              className={classNames(
                'horizontal-lines',
                { 'has-left-line': cell.leftLine },
                { 'has-right-line': cell.rightLine },
              )}></i>
          )}
          {(cell.topLine || cell.bottomLine) && (
            <i
              className={classNames(
                'vertical-lines',
                { 'has-top-line': cell.topLine },
                { 'has-bottom-line': cell.bottomLine },
              )}></i>
          )}
        </div>
      </Popover>
    );
  };

  const findRelation = (relationList: any[], colId: string, rowId: string) =>
    relationList.find(
      (item) =>
        ((item.highestIDValue === colId && item.lowestIDValue === rowId) ||
          (item.highestIDValue === rowId && item.lowestIDValue === colId)) &&
        item.correlationCoefficient !== 3,
    );

  const cells = (rowList: any[], colList: any[], relationList: any[]) => {
    if (!rowList || rowList.length === 0 || !colList || colList.length === 0) {
      return [];
    }

    let cells = rowList.map((rowItem) =>
      colList.map((colItem) => {
        const colId = colItem[colItemId];
        const rowId = rowItem[rowItemId];
        const relation = findRelation(relationList, colId, rowId);
        return {
          code: relation?.correlationCoefficient ?? 3,
          colId: colId,
          rowId: rowId,
        };
      }),
    );

    const verticalWalkOnCell = (cell: any, topLine: boolean, bottomLine: boolean) => {
      if (colSelectionIds?.includes(cell.colId) && !cell.selected) {
        if (rowSelectionIds?.includes(cell.rowId) && colSelectionIds?.includes(cell.colId)) {
          cell.selected = true;
        } else {
          cell.passingThrough = true;
        }

        cell.bottomLine = bottomLine;
        cell.topLine = topLine;
      }
    };

    const horizontalWalkOnCell = (cell: any, leftLine: boolean, rightLine: boolean) => {
      if (rowSelectionIds?.includes(cell.rowId) && colSelectionIds?.includes(cell.colId)) {
        cell.selected = true;
      } else {
        cell.passingThrough = true;
      }

      cell.rightLine = rightLine;
      cell.leftLine = leftLine;
    };

    if (colSelectionIds?.length) {
      // Vertical lines
      colList.forEach((colItem, colIndex) => {
        if (direction?.startsWith('top')) {
          const lastSelectedRowIndex = rowList.findLastIndex((rowItem) =>
            findRelation(relationList, colItem[colItemId], rowItem[rowItemId]),
          );

          if (lastSelectedRowIndex >= 0) {
            for (let rowIndex = 0; rowIndex <= lastSelectedRowIndex; rowIndex++) {
              const cell = cells[rowIndex][colIndex];
              verticalWalkOnCell(cell, true, rowIndex !== lastSelectedRowIndex);
            }
          }
        } else {
          const firstSelectedRowIndex = rowList.findIndex((rowItem) =>
            findRelation(relationList, colItem[colItemId], rowItem[rowItemId]),
          );

          if (firstSelectedRowIndex >= 0) {
            for (let rowIndex = firstSelectedRowIndex; rowIndex < rowList.length; rowIndex++) {
              const cell = cells[rowIndex][colIndex];
              verticalWalkOnCell(cell, rowIndex !== firstSelectedRowIndex, true);
            }
          }
        }
      });
    }

    if (rowSelectionIds?.length) {
      // Horizontal lines
      rowList.forEach((rowItem, rowIndex) => {
        if (rowSelectionIds?.includes(rowItem[rowItemId])) {
          if (direction?.endsWith('Left')) {
            const lastSelectedColIndex = colList.findLastIndex((colItem) =>
              findRelation(relationList, colItem[colItemId], rowItem[rowItemId]),
            );

            // no matches
            if (lastSelectedColIndex >= 0) {
              for (let colIndex = 0; colIndex <= lastSelectedColIndex; colIndex++) {
                const cell = cells[rowIndex][colIndex];
                horizontalWalkOnCell(cell, true, colIndex !== lastSelectedColIndex);
              }
            }
          } else {
            const firstSelectedColIndex = colList.findIndex((colItem) =>
              findRelation(relationList, colItem[colItemId], rowItem[rowItemId]),
            );

            // no matches
            if (firstSelectedColIndex >= 0) {
              for (let colIndex = firstSelectedColIndex; colIndex < colList.length; colIndex++) {
                const cell = cells[rowIndex][colIndex];
                horizontalWalkOnCell(cell, colIndex !== firstSelectedColIndex, true);
              }
            }
          }
        }
      });
    }

    return cells;
  };

  const unmutableArrayReverse = (array: any[]) => [...array].reverse();

  let cellsData = cells(rowList, colList, relationList);

  if (origin?.startsWith('bottom')) {
    // Revert the matrix columns
    cellsData = cellsData.map((columns) => unmutableArrayReverse(columns));
  }

  if (origin?.endsWith('Right')) {
    // Revert the matrix rows
    cellsData = unmutableArrayReverse(cellsData);
  }

  return (
    <table className="relation-table tw-border-collapse tw-size-full">
      <tbody>
        {cellsData.map((columns, i) => (
          <tr key={i}>
            {columns.map((cell, j) => (
              <td
                className="tw-size-8 tw-p-0"
                key={j}>
                {renderCell(cell)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default RelationTable;
