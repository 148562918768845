import { DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Input, message, Popconfirm, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { GetXMatrixCycleResponseDto } from 'src/connectors/backend';
import MatrixService from '../../services/matrix/matrixService';
import TableLayout from '../shared/tableLayout';
import { notifyMessages } from '../shared/utils/notifyMessages';

const XmatrixCycleListPage: React.FC = () => {
  const { t } = useTranslation();
  const userData = useSelector((state: any) => state.userData.userData);
  const history = useHistory();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [loadingData, setLoadingData] = useState(false);
  const [cyclesList, setCyclesList] = useState<GetXMatrixCycleResponseDto[]>([]);

  useEffect(() => {
    retrieveXmatrixCycleList();
  }, []);

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />
    ),
    onFilter: (value: string, record: any) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
  });

  const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: string) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const retrieveXmatrixCycleList = async () => {
    setLoadingData(true);
    try {
      const response = await MatrixService.getXmatrixCycleList(userData.lastLoggedInCompanyID);
      const data = response.data;
      if (data.success) {
        setCyclesList(data.responseObject?.value || []);
      } else {
        message.error(notifyMessages.retrieveFailed);
      }
    } catch {
      message.error(notifyMessages.retrieveFailed);
    } finally {
      setLoadingData(false);
    }
  };

  const onRemoveCycle = async (id: string) => {
    try {
      const response = await MatrixService.removeXmatrixCycle(id);
      const data = response.data;
      if (data.success) {
        message.success(notifyMessages.deleteSuccess);
        retrieveXmatrixCycleList();
      } else {
        message.error(notifyMessages.deleteFailed);
      }
    } catch {
      message.error(notifyMessages.deleteFailed);
    }
  };

  const onNew = () => {
    history.push('/strategicCycle/new');
  };

  const cycleColumns: any = [
    {
      title: t('xmatrixCyclePage.nomeCycle'),
      dataIndex: 'cycleName',
      sorter: (a: any, b: any) => (a.cycleName || '').localeCompare(b.cycleName || ''),
      showSorterTooltip: [false],
      ...getColumnSearchProps('cycleName'),
    },
    {
      key: 'action',
      width: '50px',
      render: (text: any, record: any) => (
        <Button
          icon={<EditOutlined />}
          href={`/strategicCycle/id/${record.xMatrixCycleID}`}
        />
      ),
    },
    {
      key: 'action2',
      width: '50px',
      render: (_: any, record: any) => (
        <Popconfirm
          title={t('xmatrixCyclePage.rimuoveCycle')}
          icon={<WarningOutlined />}
          okText={t('general.si')}
          cancelText={t('general.no')}
          onConfirm={() => onRemoveCycle(record.xMatrixCycleID)}>
          <Button icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
  ];

  return (
    <div className="tw-p-6">
      <TableLayout title={t('xmatrixCyclePage.xmatrixCycle')}>
        <TableLayout.Actions>
          <Button
            type="primary"
            htmlType="submit"
            onClick={onNew}>
            <PlusOutlined /> {t('buttons.aggiungiNuovo')}
          </Button>
        </TableLayout.Actions>
        <TableLayout.Content>
          <Table
            tableLayout="fixed"
            columns={cycleColumns}
            dataSource={cyclesList}
            size="small"
            rowKey={(obj: any) => obj.xMatrixCycleID}
            loading={loadingData}
            pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
          />
        </TableLayout.Content>
      </TableLayout>
    </div>
  );
};

export default XmatrixCycleListPage;
