import {
  CheckOutlined,
  CloseOutlined,
  EditFilled,
  PlusOutlined,
  SearchOutlined,
  StarFilled,
  StarOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Button, Card, Input, message, Popconfirm, Space, Table, Tag, Tooltip } from 'antd';
import React from 'react';
import { withTranslation } from 'react-i18next';
import KpiService from '../../services/pages/kpiService';
import TableLayout from '../shared/tableLayout';
import { notifyMessages } from '../shared/utils/notifyMessages';
import './kpi.scss';

class KpiListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingData: false,
      loadingButton: false,
      loadingIcon: false,
      searchText: '',
      searchedColumn: '',
      kpiLIstData: null,
    };
  }

  // Init of component after rendering
  componentDidMount() {
    this.retrieveKpiData();
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}>
            Reset
          </Button>
          {}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
  });

  onNew = () => {
    this.props.history.push('/kpi/new');
  };

  uploadPage = () => {
    this.props.history.push('/kpi/upload');
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    this.setState({ searchText: '' });
  };

  // Retrieve KPI data
  async retrieveKpiData() {
    this.setState({ loadingData: true });
    await KpiService.getKpiListData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ kpiLIstData: resp.responseObject.value });
          this.setState({ loadingData: false });
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingData: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingData: false });
      });
  }

  setKpiAsPrefered = (kpiDetailID) => {
    let dataObject = {};
    dataObject['KPIDetailID'] = kpiDetailID;

    this.setState({ loadingIcon: true });
    KpiService.setKpiPrefered(dataObject)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          this.retrieveKpiData();
          this.setState({ loadingIcon: false });
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingIcon: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingIcon: false });
      });
  };

  removeKpiAsPrefered = (kpiDetailID) => {
    this.setState({ loadingIcon: true });
    KpiService.removeKpiPrefered(kpiDetailID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.retrieveKpiData();
          this.setState({ loadingIcon: false });
          message.success(notifyMessages.updateSuccess);
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingIcon: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingIcon: false });
      });
  };

  render() {
    const { kpiLIstData, loadingData, loadingButton, loadingIcon } = this.state;
    let t = this.props.t;

    const columns = [
      {
        // title: `${t('kpiPage.preferita')}`,
        dataIndex: 'isFavourite',
        width: '40px',
        showSorterTooltip: [false],
        render: (text, record, index) => {
          if (record.isFavourite) {
            return (
              <Popconfirm
                placement="topRight"
                title={t('kpiPage.removeKpiPreferita')}
                onConfirm={() => this.removeKpiAsPrefered(record.kpiDetailID)}
                okText={t('general.si')}
                cancelText={t('general.no')}>
                <StarFilled
                  style={{ color: '#f5a645' }}
                  title={t('kpiPage.preferita')}
                />
              </Popconfirm>
            );
          } else {
            return (
              <Popconfirm
                placement="topRight"
                title={t('kpiPage.setKpiPreferita') + `${loadingIcon ? <SyncOutlined spin /> : ''}`}
                onConfirm={() => this.setKpiAsPrefered(record.kpiDetailID)}
                okText={t('general.si')}
                cancelText={t('general.no')}>
                <StarOutlined title={t('kpiPage.preferita')} />
              </Popconfirm>
            );
          }
        },
      },
      {
        title: `${t('general.nome')}`,
        dataIndex: 'name',
        width: '30%',
        sorter: (a, b) => {
          a = a.name || '';
          b = b.name || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('name'),
        ellipsis: {
          showTitle: false,
        },
        render: (title) => <Tooltip title={title}>{title}</Tooltip>,
      },
      {
        title: `${t('general.tipo')}`,
        dataIndex: 'typeDescription',
        sorter: (a, b) => {
          a = a.typeDescription || '';
          b = b.typeDescription || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('typeDescription'),
        ellipsis: {
          showTitle: false,
        },
        render: (title) => <Tag>{title}</Tag>,
      },
      {
        title: `${t('kpiPage.tipoCalcolo')}`,
        dataIndex: 'calculationTypeDescription',
        sorter: (a, b) => {
          a = a.calculationTypeDescription || '';
          b = b.calculationTypeDescription || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('calculationTypeDescription'),
        ellipsis: {
          showTitle: false,
        },
        render: (title) => <Tag>{title}</Tag>,
      },
      {
        title: `${t('kpiPage.cumulatoPuntuale')}`,
        dataIndex: 'parentTypeDescription',
        width: '17%',
        sorter: (a, b) => {
          a = a.parentTypeDescription || '';
          b = b.parentTypeDescription || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('parentTypeDescription'),
        ellipsis: {
          showTitle: false,
        },
        render: (title) => <Tag>{title}</Tag>,
      },
      {
        title: `${t('kpiPage.gerarchicho')}`,
        dataIndex: 'isRollupKPI',
        filters: [
          { text: `${t('general.si')}`, value: true },
          { text: `${t('general.no')}`, value: false },
        ],
        onFilter: (value, record) => record.isRollupKPI === value,
        showSorterTooltip: [false],
        render: (text, record, index) => {
          if (record.isRollupKPI) {
            return <CheckOutlined style={{ color: '#16a34a' }} />;
          } else {
            return;
          }
        },
      },
      {
        title: `${t('kpiPage.isVisible')}`,
        dataIndex: 'isVisible',
        filters: [
          { text: `${t('general.si')}`, value: true },
          { text: `${t('general.no')}`, value: false },
        ],
        onFilter: (value, record) => record.isVisible === value,
        showSorterTooltip: [false],
        render: (text, record, index) => {
          if (record.isVisible) {
            return <CheckOutlined style={{ color: '#16a34a' }} />;
          } else {
            return <CloseOutlined style={{ color: '#7b817c' }} />;
          }
        },
      },
      {
        key: 'action',
        width: '50px',

        render: (text, record) => (
          <Button
            type="dashed"
            icon={<EditFilled />}
            href={`/kpi/id/${record.kpiDetailID}`}></Button>
        ),
      },
    ];

    return (
      <Card>
        <TableLayout title={t('kpiPage.listaKpi')}>
          <TableLayout.Actions>
            <Button
              type="primary"
              htmlType="submit"
              onClick={this.onNew}>
              <PlusOutlined />
              {t('buttons.aggiungiNuovo')}
            </Button>
          </TableLayout.Actions>
          <TableLayout.Content>
            <Table
              className="table-height"
              size="small"
              columns={columns}
              dataSource={kpiLIstData}
              rowKey={(obj) => obj.kpiDetailID}
              scroll={{ x: 'calc(600px + 50%)' }}
              loading={loadingData}
              pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
            />
          </TableLayout.Content>
        </TableLayout>
      </Card>
    );
  }
}

export default withTranslation()(KpiListPage);
