import { Form } from 'antd';
import React from 'react';
import './kpi.scss';

import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SaveOutlined,
  SearchOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tabs,
  Tooltip,
  message,
} from 'antd';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CommonService from '../../services/commonService';
import MatrixService from '../../services/matrix/matrixService';
import KpiService from '../../services/pages/kpiService';
import DeliverableService from '../../services/pages/projectServices/deliverableServices';
import Loader from '../shared/components/loader/loader';
import UserSelectDropDown from '../shared/components/userSelect/userSelect';
import XmatrixDropDownFilter from '../shared/components/xmatrixDropdownFilter/xmatrixDropdownFilter';
import { isUserAdmin, isUserAdminOrSteering } from '../shared/utils/authRolesProvilege/authRolesPrivilege';
import { bowlingChartRowName, kpiCalculationType, kpiType, objectCodes } from '../shared/utils/constants';
import { checkUserIsKpiResponsabile } from '../shared/utils/functions';
import { notifyMessages, requiredFields } from '../shared/utils/notifyMessages';
import KpiValoreFattoNonFattoGlobale from './kpiBowlingChart/kpiValoreFattoNonFattoGlobale';
import KpiValoreMassimoGlobal from './kpiBowlingChart/kpiValoreMassimoGlobal';
import KpiValoreMinimoGlobale from './kpiBowlingChart/kpiValoreMinimoGlobale';
import KpiValorePercentualeGlobale from './kpiBowlingChart/kpiValorePercentualeGlobale';
import NewChildKpiModal from './newChildModalKpi';
import UploadKpiGlobal from './uploadKpiGlobal';

let createBrowserHistory = require('history').createBrowserHistory;
const history = createBrowserHistory({ forceRefresh: true });
const { TextArea } = Input;
const { Option } = Select;

const NormalJumpOffPoint = (props) => {
  return (
    <Form.Item
      label={props.t('general.jumpOffPoint')}
      name="jumpOffPoint"
      initialValue={props.initialValue}
      rules={[
        { required: true, message: requiredFields.required },
        {
          validator: async (_, jumpOffPoint) => {
            if (typeof jumpOffPoint !== 'number') {
              return Promise.reject(new Error(`${props.t('kpiPage.jopNumber')}`));
            }
          },
        },
      ]}>
      <InputNumber
        style={{ width: '100%' }}
        min="0"
        disabled={props.isRollup}
      />
    </Form.Item>
  );
};

const PercentageJumpOffPoint = (props) => {
  return (
    <Form.Item
      label={props.t('general.jumpOffPoint')}
      name="jumpOffPoint"
      initialValue={props.initialValue}
      rules={[
        { required: true, message: requiredFields.required },
        {
          validator: async (_, jumpOffPoint) => {
            if (jumpOffPoint > 100 || jumpOffPoint < 0 || isNaN(jumpOffPoint) || typeof jumpOffPoint !== 'number') {
              return Promise.reject(new Error(`${props.t('kpiPage.jopMax100Number')}`));
            }
          },
        },
      ]}>
      <InputNumber
        style={{ width: '100%' }}
        min={0}
        max={100}
        formatter={(value) => `${value} %`}
        disabled={props.isRollup}
      />
    </Form.Item>
  );
};

const BooleanJumpOffPoint = (props) => {
  return (
    <Form.Item
      label={props.t('general.jumpOffPoint')}
      name="jumpOffPoint"
      valuePropName="checked"
      initialValue={props.initialValue === 0 || props.initialValue === null ? false : true}>
      <Switch
        checkedChildren={props.t('kpiPage.fatto')}
        unCheckedChildren={props.t('kpiPage.nonFatto')}
        disabled={props.isRollup}
      />
    </Form.Item>
  );
};

class EditKpi extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
      currentData: null,
      calculationTypeList: null,
      typeList: null,
      bowlingChartData: null,
      acumulatoPuntuale: null,
      searchText: '',
      searchedColumn: '',
      projectsNumber: 0,
      showChildKpiModal: false,
      childKpiList: null,
      anualGoalList: null,
      loadingAnnualGoal: false,
      activeXmatrixInfo: null,
      selectedXmatrixValue: null,
      loadingPicklist: true,
      kpiYear: null,
    };
    this.child = React.createRef();
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.getKpiData(id);
    this.retrieveCalculationType();
    this.retrieveType();
    this.retrieveAcumulatoPuntuale();
    this.getKpiChildList(id);
    this.retrieveComponentData();
    this.checkKpiValuesYear(id);
  }

  retrieveComponentData = async () => {
    const { id } = this.props.match.params;
    await this.getActiveXmatrix(id);
  };

  async getActiveXmatrix(kpiDetailID) {
    await MatrixService.getActiveXmatrix()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          this.setState({ activeXmatrixInfo: respData }, () => {
            this.getAnualGoalList(kpiDetailID);
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  async checkKpiValuesYear(id) {
    await KpiService.checkKpiYear(id, 'KPI')
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let year = resp.responseObject.value.year.toString();
          this.setState({ kpiYear: year }, () => {});
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveCalculationType() {
    const objectCode = objectCodes.calculationTypes;
    await CommonService.getCalculationTypeList(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ calculationTypeList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveType() {
    const objectCode = objectCodes.kpiType;
    await CommonService.getPicklist(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ typeList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveAcumulatoPuntuale() {
    const objectCode = objectCodes.acumulatoPuntuale;
    await CommonService.getPicklist(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ acumulatoPuntuale: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  getKpiData = (id) => {
    KpiService.getKpiByiD(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          this.setState({ currentData: respData });
          this.setState({ projectsNumber: respData.getProjectByKPI.length });
        } else {
          message.error(notifyMessages.retrieveFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
      });
  };

  getKpiChildList = (id) => {
    KpiService.getKpiChildList(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ childKpiList: resp.responseObject.value });
        } else {
          message.error(notifyMessages.retrieveFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
      });
  };

  updateData = (values, id) => {
    values['kpiDetailID'] = id;
    if (values.jumpOffPoint === true) {
      values.jumpOffPoint = 1;
    }
    if (values.jumpOffPoint === false) {
      values.jumpOffPoint = 0;
    }

    this.setState({ loadingButton: true });
    KpiService.updateKpi(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          this.setState({ loadingButton: false });
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  backFunction = () => {
    history.goBack();
  };

  removeKPI = (id) => {
    KpiService.deleteKPI(id)
      .then((response) => {
        if (response.data.success) {
          this.setState({ currentData: false });
          message.success(notifyMessages.deleteSuccess);
          this.props.history.push('/kpi');
        } else {
          message.error(notifyMessages.deleteFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.deleteFailed);
      });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}>
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)}>Reset</Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    this.setState({ searchText: '' });
  };

  getAnualGoalList = (kpiDetailID) => {
    const { selectedXmatrixValue, activeXmatrixInfo } = this.state;
    let xmatrix = selectedXmatrixValue ? selectedXmatrixValue : activeXmatrixInfo?.xMatrixID;

    if (xmatrix) {
      this.setState({ loadingAnnualGoal: true });
      KpiService.getAnnualGoalList(kpiDetailID, xmatrix)
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            this.setState({ anualGoalList: resp.responseObject.value });
            this.setState({ loadingAnnualGoal: false });
          } else {
          }
        })
        .catch((error) => {});
    }
  };

  updateGlobalKpiBowlingChart = (payload) => {
    this.setState({ loadingButton: true });
    DeliverableService.updateGlobalKpiBowlingChart(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          this.setState({ loadingButton: false });
          this.child.current.getGlobalKpiBowlingChart();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  handleChildKpiModalClose = () => {
    this.setState({ showChildKpiModal: false });
    this.setState({ loadingButton: false });
  };

  toggleChildKpiModalClose = () => {
    this.setState({ showChildKpiModal: true });
  };

  addChildKpi = (values, parentKpiId) => {
    this.setState({ loadingButton: true });
    KpiService.insertChildKpi(parentKpiId, values.KPIDetailID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          this.handleChildKpiModalClose();
          this.getKpiChildList(parentKpiId);
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingButton: false });
      });
  };

  onDataSave = (bowlingChartData, jumpOffPointValue, calculationType, finalTarget, showInA3, definedTarget) => {
    const { currentData } = this.state;
    let payload = {};
    let tableData = [];
    let targetRow = bowlingChartData.filter(function (item) {
      return item.Name === bowlingChartRowName.target;
    })[0];
    let actualRow = bowlingChartData.filter(function (item) {
      return item.Name === bowlingChartRowName.actual;
    })[0];
    let actualYtdRow = bowlingChartData.filter(function (item) {
      return item.Name === bowlingChartRowName.actualYtd;
    })[0];
    let targetYtdRow = bowlingChartData.filter(function (item) {
      return item.Name === bowlingChartRowName.targetYtd;
    })[0];

    let isTargetInvalid = false;
    if (calculationType === kpiCalculationType.valorePercentuale) {
      for (var el in targetRow) {
        if (targetRow.hasOwnProperty(el) && (targetRow[el] > 100 || targetRow[el] < 0)) {
          isTargetInvalid = true;
        }
      }
    }

    let isActualInvalid = false;
    if (calculationType === kpiCalculationType.valorePercentuale) {
      for (var act in actualRow) {
        if (actualRow.hasOwnProperty(act) && (actualRow[act] > 100 || actualRow[act] < 0)) {
          isActualInvalid = true;
        }
      }
    }

    let targetYtdInvalid = false;
    if (calculationType === kpiCalculationType.valorePercentuale) {
      for (var targetYtd in targetYtdRow) {
        if (targetYtdRow.hasOwnProperty(targetYtd) && (targetYtdRow[targetYtd] > 100 || targetYtdRow[targetYtd] < 0)) {
          targetYtdInvalid = true;
        }
      }
    }

    let actualYtdInvalid = false;
    if (calculationType === kpiCalculationType.valorePercentuale) {
      for (var actualYtd in actualYtdRow) {
        if (actualYtdRow.hasOwnProperty(actualYtd) && (actualYtdRow[actualYtd] > 100 || actualYtdRow[actualYtd] < 0)) {
          actualYtdInvalid = true;
        }
      }
    }

    if (targetRow || actualRow) {
      if (!isActualInvalid && !isTargetInvalid && !targetYtdInvalid && !actualYtdInvalid) {
        for (let key in targetRow) {
          if (key !== 'Name') {
            let formatedDate = moment(key).format('YYYY-MM-DD');
            let item = `${formatedDate},${actualRow[key]},${targetRow[key]}`;
            tableData.push(item);
          }
        }
        payload['jumpOffPoint'] = jumpOffPointValue;
        payload['showInA3'] = showInA3;
        payload['finalTarget'] = finalTarget;
        payload['kPIDetailID'] = currentData.getKPIDetail.kpiDetailID;
        payload['tableData'] = tableData;
        payload['freezeTargetValues'] = definedTarget;

        this.updateGlobalKpiBowlingChart(payload);
      }
    }
  };

  onXmatrixSelect = (selectedXmatrix) => {
    const { id } = this.props.match.params;
    this.setState({ selectedXmatrixValue: selectedXmatrix.value }, () => {
      this.getAnualGoalList(id);
    });
  };

  onChildRemove = (kpiDetailID, parentKpiId) => {
    KpiService.onKpiChildRemove(kpiDetailID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.deleteSuccess);
          this.getKpiChildList(parentKpiId);
        } else {
          message.error(notifyMessages.deleteFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.deleteFailed);
      });
  };

  render() {
    const {
      currentData,
      calculationTypeList,
      typeList,
      acumulatoPuntuale,
      loadingButton,
      showChildKpiModal,
      childKpiList,
      anualGoalList,
      loadingAnnualGoal,
      kpiYear,
      activeXmatrixInfo,
    } = this.state;
    let t = this.props.t;
    const { userData, companyData } = this.props;

    let kpiIsGlobale = currentData && currentData.getKPIDetail?.type === kpiType.globale ? true : false;

    const longTermGoalColumns = [
      {
        title: `${t('general.nome')}`,
        dataIndex: 'longTermGoalDesription',
        width: '40%',
        sorter: (a, b) => {
          a = a.longTermGoalDesription || '';
          b = b.longTermGoalDesription || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ellipsis: {
          showTitle: false,
        },
        render: (title) => <Tooltip title={title}>{title}</Tooltip>,
      },

      {
        title: `${t('general.sponsor')}`,
        dataIndex: 'fullName',
        sorter: (a, b) => {
          a = a.fullName || '';
          b = b.fullName || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('fullName'),
      },
      {
        title: `${t('general.relazione')}`,
        align: 'center',
        dataIndex: 'relationship',
        render: (text, record) => {
          if (record.correlationCoefficient === 1) {
            return (
              <Tooltip title={record.correlationDescription}>
                <div className="tw-w-full tw-flex tw-flex-row tw-justify-center">
                  <span className="relationFull"></span>
                </div>
              </Tooltip>
            );
          } else if (record.correlationCoefficient === 2) {
            return (
              <Tooltip title={record.correlationDescription}>
                <div className="tw-w-full tw-flex tw-flex-row tw-justify-center">
                  <span className="relationPartial"></span>
                </div>
              </Tooltip>
            );
          } else {
          }
        },
      },
      {
        key: 'action',
        width: '30px',
        render: (text, record) => (
          <Space>
            <Link
              to={{
                pathname: `/obiettivialungoperiodo/details/id/${record.longTermGoalID}`,
              }}>
              <EditOutlined />
            </Link>
          </Space>
        ),
      },
    ];

    const progettiColumns = [
      {
        title: `${t('general.nome')}`,
        dataIndex: 'name',
        width: '40%',
        sorter: (a, b) => {
          a = a.name || '';
          b = b.name || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ellipsis: {
          showTitle: false,
        },
        render: (title) => <Tooltip title={title}>{title}</Tooltip>,
      },

      {
        title: `${t('proggetiPage.codice')}`,
        dataIndex: 'projectCode',
        sorter: (a, b) => {
          a = a.projectCode || '';
          b = b.projectCode || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('projectCode'),
      },
      {
        key: 'action',
        width: '30px',
        render: (text, record) => (
          <Space>
            <Link
              to={{
                pathname: `/progetti/id/${record.projectID}`,
              }}>
              <EditOutlined />
            </Link>
          </Space>
        ),
      },
    ];

    const childKpiColumns = [
      {
        title: `${t('general.nome')}`,
        dataIndex: 'name',
        sorter: (a, b) => {
          a = a.name || '';
          b = b.name || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ellipsis: {
          showTitle: false,
        },
        ...this.getColumnSearchProps('name'),
        render: (title) => <Tooltip title={title}>{title}</Tooltip>,
      },
      {
        key: 'action',
        width: '30px',
        render: (_, record) => {
          return (
            <Popconfirm
              key="remuve"
              title={t('kpiPage.removeRelation')}
              icon={<WarningOutlined />}
              okText={t('general.si')}
              cancelText={t('general.no')}
              onConfirm={() => this.onChildRemove(record.kpiDetailID, currentData.getKPIDetail.kpiDetailID)}>
              <DeleteOutlined title={t('buttons.rimuovi')} />
            </Popconfirm>
          );
        },
      },
    ];

    const anualGoalColumns = [
      {
        title: `${t('general.nome')}`,
        dataIndex: 'annualGoalDesription',
        ellipsis: {
          showTitle: true,
        },
        sorter: (a, b) => {
          a = a.annualGoalDesription || '';
          b = b.annualGoalDesription || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [true],
        ...this.getColumnSearchProps('annualGoalDesription'),
        render: (title) => <Tooltip title={title}>{title}</Tooltip>,
      },

      {
        key: 'action',
        width: '30px',
        render: (text, record) => (
          <Space>
            <Link
              to={{
                pathname: `/obiettiviannuali/details/id/${record.annualGoalID}`,
              }}>
              <EditOutlined />
            </Link>
          </Space>
        ),
      },
    ];

    return (
      <div className="edit-kpi-wrapper">
        <Loader />
        {currentData && currentData.getKPIDetail && (
          <>
            <div className="site-card-wrapper">
              <h3 className="objective-item">
                {currentData.getKPIDetail.name} - {t('general.kpi')}
              </h3>
            </div>

            {showChildKpiModal && (
              <NewChildKpiModal
                showChildKpiModal={showChildKpiModal}
                handleChildKpiModalClose={this.handleChildKpiModalClose}
                handleChildKpiSave={this.addChildKpi}
                loadingButton={loadingButton}
                parentKpiId={currentData.getKPIDetail.kpiDetailID}
                t={t}
              />
            )}

            <Tabs
              className="tw-px-4"
              defaultActiveKey="1"
              items={[
                {
                  label: <>{t('general.informazioniGenerali')}</>,
                  key: '1',
                  children: (
                    <>
                      <Loader />

                      <Form
                        name="kpi_Details"
                        layout="vertical"
                        onFinish={(values) => this.updateData(values, currentData.getKPIDetail.kpiDetailID)}
                        onValuesChange={() => {
                          this.setState({ fieldsChanged: true });
                        }}>
                        {checkUserIsKpiResponsabile(currentData.getKPIDetail.responsibleID, userData) && (
                          <div className="tw-flex tw-justify-end tw-items-center tw-gap-2">
                            {currentData.getKPIDetail.toBeDeleted === 1 ? (
                              <Form.Item className="!tw-mb-0">
                                <Popconfirm
                                  title={t('kpiPage.deleteKpi')}
                                  onConfirm={() => this.removeKPI(currentData.getKPIDetail.kpiDetailID)}
                                  okText={t('general.si')}
                                  cancelText={t('general.no')}
                                  className="long-term-button remove">
                                  <Button icon={<DeleteOutlined />}>{t('buttons.elimina')}</Button>
                                </Popconfirm>
                              </Form.Item>
                            ) : (
                              <Form.Item className="!tw-mb-0">
                                <Button
                                  type="primary"
                                  icon={<DeleteOutlined />}
                                  disabled={true}
                                  onClick={() => this.removeKPI(currentData.getKPIDetail.kpiDetailID)}>
                                  {t('buttons.elimina')}
                                </Button>
                              </Form.Item>
                            )}
                            <Form.Item className="!tw-mb-0">
                              <Button
                                loading={loadingButton}
                                icon={<SaveOutlined />}
                                disabled={!this.state.fieldsChanged}
                                htmlType="submit">
                                {t('buttons.salva')}
                              </Button>
                            </Form.Item>
                          </div>
                        )}

                        <Row gutter={{ lg: 24 }}>
                          <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 24 }}
                            lg={{ span: 24 }}>
                            <Row gutter={{ lg: 24 }}>
                              <Col
                                className="gutter-row"
                                xs={{ span: 24 }}
                                sm={{ span: 12 }}
                                md={{ span: 8 }}
                                lg={{ span: 8 }}>
                                <Form.Item
                                  className="main-container"
                                  hidden={currentData.getKPIDetail.type === kpiType.specifico}
                                  label={t('kpiPage.gerarchicho')}
                                  name="isRollupKPI"
                                  valuePropName="checked"
                                  initialValue={currentData.getKPIDetail.isRollupKPI}>
                                  <Switch disabled={true} />
                                </Form.Item>
                              </Col>

                              <Col
                                className="gutter-row"
                                xs={{ span: 24 }}
                                sm={{ span: 12 }}
                                md={{ span: 8 }}
                                lg={{ span: 8 }}>
                                <Form.Item
                                  className="main-container"
                                  label={t('kpiPage.isVisible')}
                                  name="isVisible"
                                  valuePropName="checked"
                                  initialValue={currentData.getKPIDetail.isVisible}>
                                  <Switch disabled={!isUserAdmin(userData)} />
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row gutter={{ lg: 24 }}>
                              <Col
                                className="gutter-row"
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={kpiIsGlobale ? { span: 12 } : { span: 24 }}
                                lg={kpiIsGlobale ? { span: 12 } : { span: 24 }}>
                                <Form.Item
                                  className="main-container"
                                  label={t('general.nome')}
                                  name="name"
                                  rules={[{ required: true, message: requiredFields.required }]}
                                  initialValue={currentData.getKPIDetail.name}>
                                  <Input />
                                </Form.Item>
                              </Col>

                              {kpiIsGlobale && (
                                <Col
                                  className="gutter-row"
                                  xs={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  md={{ span: 12 }}
                                  lg={{ span: 12 }}>
                                  <UserSelectDropDown
                                    disabled={!isUserAdminOrSteering(userData)}
                                    allowClear={true}
                                    fieldLabel={t('general.responsabile')}
                                    t={t}
                                    formName={'responsibleID'}
                                    required={false}
                                    initValue={currentData.getKPIDetail.responsibleID}
                                  />
                                  {/* <Form.Item
                                                                            className="main-container"
                                                                            label={t('general.responsabile')}
                                                                            name="responsibleID"
                                                                            initialValue={currentData.getKPIDetail.responsibleID}
                                                                        >
                                                                            <Select
                                                                                showSearch
                                                                                placeholder={t('general.seleziona')}
                                                                                optionFilterProp="children"
                                                                                allowClear
                                                                                filterOption={(input, option) =>
                                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                }
                                                                                style={{ width: "100%" }}
                                                                            >
                                                                                {responsabileUsersList && responsabileUsersList.map(item =>
                                                                                    <Option value={item.userID} key={item.userID}>{item.fullName}</Option>
                                                                                )}
                                                                            </Select>
                                                                        </Form.Item> */}
                                </Col>
                              )}

                              <Col
                                className="gutter-row"
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 24 }}
                                lg={{ span: 8 }}>
                                <Form.Item
                                  className="main-container"
                                  label={t('kpiPage.tipoCalcolo')}
                                  name="calculationType"
                                  hasFeedback
                                  initialValue={currentData.getKPIDetail.calculationType}>
                                  <Select
                                    style={{ width: '100%' }}
                                    placeholder={t('general.seleziona')}
                                    disabled={true}>
                                    {calculationTypeList &&
                                      calculationTypeList.map((item) => (
                                        <Option
                                          value={item.objectCodeListID}
                                          key={item.objectCodeListID}>
                                          {item.description}
                                        </Option>
                                      ))}
                                  </Select>
                                </Form.Item>
                              </Col>

                              {(currentData.getKPIDetail.calculationType === 1 ||
                                currentData.getKPIDetail.calculationType === 2) && (
                                <Col
                                  className="gutter-row"
                                  xs={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  md={{ span: 24 }}
                                  lg={{ span: 8 }}>
                                  <NormalJumpOffPoint
                                    t={t}
                                    initialValue={currentData.getKPIDetail.jumpOffPoint}
                                    isRollup={currentData.getKPIDetail.isRollupKPI}
                                  />
                                </Col>
                              )}

                              {currentData.getKPIDetail.calculationType === 3 && (
                                <Col
                                  className="gutter-row"
                                  xs={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  md={{ span: 24 }}
                                  lg={{ span: 8 }}>
                                  <PercentageJumpOffPoint
                                    t={t}
                                    initialValue={currentData.getKPIDetail.jumpOffPoint}
                                    isRollup={currentData.getKPIDetail.isRollupKPI}
                                  />
                                </Col>
                              )}

                              {currentData.getKPIDetail.calculationType === 4 && (
                                <Col
                                  className="gutter-row"
                                  xs={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  md={{ span: 24 }}
                                  lg={{ span: 8 }}>
                                  <BooleanJumpOffPoint
                                    t={t}
                                    initialValue={currentData.getKPIDetail.jumpOffPoint}
                                    isRollup={currentData.getKPIDetail.isRollupKPI}
                                  />
                                </Col>
                              )}

                              <Col
                                className="gutter-row"
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 24 }}
                                lg={{ span: 8 }}>
                                <Form.Item
                                  className="main-container"
                                  width={'100%'}
                                  label={t('kpiPage.cumulatoPuntuale')}
                                  name="parentType"
                                  hasFeedback
                                  rules={[{ required: true, message: requiredFields.required }]}
                                  initialValue={currentData.getKPIDetail.parentType}>
                                  <Select
                                    disabled={true}
                                    style={{ width: '100%' }}
                                    placeholder={t('general.seleziona')}>
                                    {acumulatoPuntuale &&
                                      acumulatoPuntuale.map((item) => (
                                        <Option
                                          value={item.objectCodeListID}
                                          key={item.objectCodeListID}>
                                          {item.description}
                                        </Option>
                                      ))}
                                  </Select>
                                </Form.Item>
                              </Col>

                              <Col
                                className="gutter-row"
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 24 }}
                                lg={{ span: 8 }}>
                                <Form.Item
                                  className="main-container"
                                  label={t('kpiPage.unitaMisura')}
                                  name="unitOfMeasure"
                                  initialValue={currentData.getKPIDetail.unitOfMeasure}>
                                  <Input />
                                </Form.Item>
                              </Col>

                              <Col
                                className="gutter-row"
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 24 }}
                                lg={{ span: 8 }}>
                                <Form.Item
                                  className="main-container"
                                  label={t('general.actualYtd')}
                                  name="actualYTD"
                                  initialValue={currentData.getKPIDetail.actualYTD}>
                                  <InputNumber
                                    min="0"
                                    style={{ width: '100%' }}
                                    disabled={currentData.getKPIDetail.isRollupKPI}
                                  />
                                </Form.Item>
                              </Col>

                              <Col
                                className="gutter-row"
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 24 }}
                                lg={{ span: 8 }}>
                                <Form.Item
                                  className="main-container"
                                  label={t('general.tipo')}
                                  name="type"
                                  hasFeedback
                                  rules={[{ required: true, message: requiredFields.required }]}
                                  initialValue={currentData.getKPIDetail.type}>
                                  <Select
                                    disabled={true}
                                    style={{ width: '100%' }}
                                    placeholder={t('general.seleziona')}>
                                    {typeList &&
                                      typeList.map((item) => (
                                        <Option
                                          value={item.objectCodeListID}
                                          key={item.objectCodeListID}>
                                          {item.description}
                                        </Option>
                                      ))}
                                  </Select>
                                </Form.Item>
                              </Col>

                              <Col
                                className="gutter-row"
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 24 }}
                                lg={{ span: 24 }}>
                                <Form.Item
                                  className="main-container"
                                  label={t('general.nota')}
                                  name="additionalNote"
                                  initialValue={currentData.getKPIDetail.additionalNote}>
                                  <TextArea rows={4} />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <Row gutter={{ lg: 24 }}>
                          <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 24 }}
                            lg={{ span: 24 }}>
                            <Tabs
                              defaultActiveKey="1"
                              items={[
                                {
                                  label: <>{t('general.obiettiviLungo')}</>,
                                  key: '1',
                                  children: (
                                    <Row gutter={{ lg: 24 }}>
                                      <Col
                                        className="gutter-row"
                                        xs={{ span: 24 }}
                                        sm={{ span: 24 }}
                                        md={{ span: 24 }}
                                        lg={{ span: 24 }}>
                                        <Table
                                          className="table-height"
                                          size="small"
                                          columns={longTermGoalColumns}
                                          dataSource={currentData.getLongTermGoalByKPI}
                                          rowKey={(obj) => obj.longTermGoalID}
                                          scroll={{ x: 'calc(640px + 50%)' }}
                                        />
                                      </Col>
                                    </Row>
                                  ),
                                },
                                {
                                  label: <>{t('general.obbietiviAnuali')}</>,
                                  key: '2',
                                  children: (
                                    <Row gutter={{ lg: 24 }}>
                                      <Col
                                        className="gutter-row"
                                        xs={{ span: 24 }}
                                        sm={{ span: 24 }}
                                        md={{ span: 24 }}
                                        lg={{ span: 24 }}>
                                        <Card
                                          extra={
                                            <>
                                              {activeXmatrixInfo && (
                                                <XmatrixDropDownFilter
                                                  onXmatrixSelect={this.onXmatrixSelect}
                                                  activeXmatrixInfo={activeXmatrixInfo}
                                                  t={t}
                                                />
                                              )}
                                            </>
                                          }>
                                          <Table
                                            className="table-height"
                                            size="small"
                                            columns={anualGoalColumns}
                                            dataSource={anualGoalList}
                                            rowKey={(obj) => obj.annualGoalID}
                                            scroll={{ x: 'calc(640px + 50%)' }}
                                            loading={loadingAnnualGoal}
                                          />
                                        </Card>
                                      </Col>
                                    </Row>
                                  ),
                                },
                                {
                                  label: <>{t('general.progetti')}</>,
                                  key: '3',
                                  children: (
                                    <Row gutter={{ lg: 24 }}>
                                      <Col
                                        className="gutter-row"
                                        xs={{ span: 24 }}
                                        sm={{ span: 24 }}
                                        md={{ span: 24 }}
                                        lg={{ span: 24 }}>
                                        <Table
                                          className="table-height"
                                          size="small"
                                          columns={progettiColumns}
                                          dataSource={currentData.getProjectByKPI}
                                          rowKey={(obj) => obj.projectID}
                                          scroll={{ x: 'calc(640px + 50%)' }}
                                        />
                                      </Col>
                                    </Row>
                                  ),
                                },
                                {
                                  label: <>{currentData.getKPIDetail.isRollupKPI ? t('kpiPage.kpiFigli') : ''}</>,
                                  key: '4',
                                  children: (
                                    <Row gutter={{ lg: 24 }}>
                                      <Col
                                        className="gutter-row"
                                        xs={{ span: 24 }}
                                        sm={{ span: 24 }}
                                        md={{ span: 24 }}
                                        lg={{ span: 24 }}>
                                        <Card
                                          extra={
                                            <Button
                                              onClick={this.toggleChildKpiModalClose}
                                              type="dashed"
                                              icon={<PlusOutlined />}
                                            />
                                          }>
                                          <Table
                                            className="table-height"
                                            size="small"
                                            columns={childKpiColumns}
                                            dataSource={childKpiList}
                                            rowKey={(obj) => obj.kpiDetailID}
                                            scroll={{ x: 'calc(640px + 50%)' }}
                                          />
                                        </Card>
                                      </Col>
                                    </Row>
                                  ),
                                },
                              ]}></Tabs>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  ),
                },
                {
                  label: <>{currentData.getKPIDetail.type === kpiType.globale ? t('kpiPage.valoriKpi') : ''}</>,
                  key: '2',
                  className: 'edit-kpi-modal',
                  children: (
                    <>
                      {currentData.getKPIDetail.type === kpiType.globale && (
                        <>
                          {currentData.getKPIDetail &&
                            currentData.getKPIDetail.calculationType === kpiCalculationType.valoreMassimo && (
                              <KpiValoreMassimoGlobal
                                kpiRowData={currentData.getKPIDetail}
                                projectId={null}
                                onSave={this.onDataSave}
                                loadingButton={loadingButton}
                                calculationType={currentData.getKPIDetail.calculationType}
                                kpiDetailID={currentData.getKPIDetail.kpiDetailID}
                                ref={this.child}
                                t={t}
                                kpiYear={kpiYear}
                                responsabileID={currentData.getKPIDetail.responsabileID}
                                userData={userData}
                                companyData={companyData}
                              />
                            )}

                          {currentData.getKPIDetail &&
                            currentData.getKPIDetail.calculationType === kpiCalculationType.valoreMinimo && (
                              <KpiValoreMinimoGlobale
                                kpiRowData={currentData.getKPIDetail}
                                projectId={null}
                                onSave={this.onDataSave}
                                loadingButton={loadingButton}
                                calculationType={currentData.getKPIDetail.calculationType}
                                kpiDetailID={currentData.getKPIDetail.kpiDetailID}
                                ref={this.child}
                                t={t}
                                kpiYear={kpiYear}
                                responsabileID={currentData.getKPIDetail.responsabileID}
                                userData={userData}
                                companyData={companyData}
                              />
                            )}

                          {currentData.getKPIDetail &&
                            currentData.getKPIDetail.calculationType === kpiCalculationType.valorePercentuale && (
                              <KpiValorePercentualeGlobale
                                kpiRowData={currentData.getKPIDetail}
                                projectId={null}
                                onSave={this.onDataSave}
                                loadingButton={loadingButton}
                                calculationType={currentData.getKPIDetail.calculationType}
                                kpiDetailID={currentData.getKPIDetail.kpiDetailID}
                                ref={this.child}
                                t={t}
                                kpiYear={kpiYear}
                                responsabileID={currentData.getKPIDetail.responsabileID}
                                userData={userData}
                                companyData={companyData}
                              />
                            )}

                          {currentData.getKPIDetail &&
                            currentData.getKPIDetail.calculationType === kpiCalculationType.valoreFatoNonFato && (
                              <KpiValoreFattoNonFattoGlobale
                                kpiRowData={currentData.getKPIDetail}
                                projectId={null}
                                onSave={this.onDataSave}
                                loadingButton={loadingButton}
                                calculationType={currentData.getKPIDetail.calculationType}
                                kpiDetailID={currentData.getKPIDetail.kpiDetailID}
                                ref={this.child}
                                t={t}
                                kpiYear={kpiYear}
                                responsabileID={currentData.getKPIDetail.responsabileID}
                                userData={userData}
                                companyData={companyData}
                              />
                            )}
                        </>
                      )}
                    </>
                  ),
                },
                {
                  label: (
                    <>
                      {currentData.getKPIDetail.type === kpiType.globale && !currentData.getKPIDetail.isRollupKPI
                        ? t('kpiPage.caricaDatiKpi')
                        : ''}
                    </>
                  ),
                  key: '3',
                  children: (
                    <>
                      {checkUserIsKpiResponsabile(currentData.getKPIDetail.responsibleID, userData) && (
                        <>
                          {currentData.getKPIDetail.type === kpiType.globale &&
                            !currentData.getKPIDetail.isRollupKPI && (
                              <UploadKpiGlobal
                                t={t}
                                kpiData={currentData.getKPIDetail}
                              />
                            )}
                        </>
                      )}
                    </>
                  ),
                },
              ]}></Tabs>
          </>
        )}
      </div>
    );
  }
}

// export default (withTranslation()(EditKpi))

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
  companyData: state.companyData.companyData,
});

export default withTranslation()(connect(mapStateToProps)(EditKpi));
